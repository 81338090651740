import { LqdArchiveIcon, LqdButton, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { analysisArchivedChanged } from "../../../board/boardSlice";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onArchiveCurrentAnalysis } from "../../analysisDetailsThunks";
import { AnalysisDetails } from "../../types/AnalysisDetails/Analysis/AnalysisDetails";

type AnalysisDashboardArchiveButtonProps = {
  analysisDetails: AnalysisDetails;
};

export default function AnalysisDashboardArchiveButton(props: AnalysisDashboardArchiveButtonProps) {
  const { analysisDetails } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const archived = analysisDetails.archived;

  const onConfirm = async () => {
    try {
      dispatch(dialogLoaded(true));
      const newValue = !archived;
      await dispatch(onArchiveCurrentAnalysis(newValue));
      dispatch(
        analysisArchivedChanged({
          analysisId: analysisDetails.analysisId,
          archived: newValue,
        })
      );
      dispatch(dialogCalled(null));
      const notification: ToastNotification = {
        message: !archived
          ? "Conexão arquivada com sucesso. Visualize as conexões arquivadas no filtro do kanban."
          : "Conexão desarquivada com sucesso. Visualize as conexões arquivadas no filtro do kanban.",
        type: "success",
      };

      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const onClick = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: onConfirm, title: archived ? "Desarquivar" : "Arquivar" }],
        body: "Você pode visualizar as conexões arquivadas alterando as configurações de filtro do seu kanban.",
        title: `Tem certeza que deseja ${archived ? "desarquivar" : "arquivar"} esta conexão?`,
        type: "default",
      })
    );
  };

  return (
    <>
      <LqdIconButton
        onClick={onClick}
        sx={{ display: { md: "none", sm: "flex" }, flexDirection: "column", ml: 2, p: 1.5, width: "100%" }}
        type="ghostIcon"
      >
        <Stack alignItems="center" direction="row" spacing={1.5} sx={{ width: "100%" }}>
          <LqdArchiveIcon color="rgba(127, 135, 152, 1)" />
          <LqdTypography color="rgba(101, 110, 127, 1)" textstyle="p2Paragraph">
            {archived ? "Desarquivar" : "Arquivar"}
          </LqdTypography>
        </Stack>
      </LqdIconButton>
      <LqdButton
        onClick={onClick}
        startIcon={<LqdArchiveIcon size={20} />}
        sx={{ border: "1px solid rgba(155, 162, 175, 1)", display: { md: "flex", xs: "none" } }}
        type="ghostPrimary"
      >
        {archived ? "Desarquivar" : "Arquivar"}
      </LqdButton>
    </>
  );
}
