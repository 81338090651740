import { Path, StyleSheet, Svg } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  svgIcon: {
    height: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    width: "24px",
  },
});

export default function PDFTableSvgIconOrange() {
  return (
    <Svg style={styles.svgIcon}>
      <Path d="M12 13.7449V10.0049" stroke="rgb(255, 181, 71)" strokeLineCap="round" strokeWidth="1.2" />
      <Path
        d="M11.999 16.75C11.861 16.75 11.749 16.862 11.75 17C11.75 17.138 11.862 17.25 12 17.25C12.138 17.25 12.25 17.138 12.25 17C12.25 16.862 12.138 16.75 11.999 16.75"
        stroke="rgb(255, 181, 71)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M14.0288 4.76419L21.6878 18.1682C22.5778 19.7262 21.4528 21.6652 19.6588 21.6652H4.34076C2.54576 21.6652 1.42076 19.7262 2.31176 18.1682L9.97076 4.76419C10.8678 3.19319 13.1318 3.19319 14.0288 4.76419Z"
        fillRule="evenodd"
        stroke="rgb(255, 181, 71)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
