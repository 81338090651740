import { LqdButton, LqdLink, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import SignUpConcluded from "./SignUpConcluded";

type SignUpFormStep5Props = {
  agreed: boolean;
  email: string;
  onChange: (value: boolean) => void;
  onOpenTermsAndConditions: () => void;
};

export default function SignUpFormStep5(props: SignUpFormStep5Props) {
  const { agreed, email, onChange, onOpenTermsAndConditions } = props;

  const [signUpConcluded, setSignUpConcluded] = useState<boolean>(false);

  return (
    <>
      <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
        Está quase!
      </LqdTypography>
      <LqdTypography sx={{ mb: 2 }} textstyle="p2Paragraph">
        Aceite os Termos e Condições para concluir o seu cadastro.
      </LqdTypography>
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <LqdToggle active={agreed} onChange={(value: boolean) => onChange(value)} />
        <LqdTypography color="secondary" sx={{ display: "inline-block", ml: 2, mr: 1 }}>
          Eu li e concordo com os
        </LqdTypography>
        <LqdLink onClick={onOpenTermsAndConditions} sx={{ fontFamily: "inherit", fontSize: "16px" }} underline="none">
          Termos e Condições
        </LqdLink>
      </Box>
      <LqdButton disabled={!agreed} onClick={() => setSignUpConcluded(true)} sx={{ mt: 3 }}>
        Concluir
      </LqdButton>
      <SignUpConcluded email={email} show={!!signUpConcluded} />
    </>
  );
}
