import { Path, Svg } from "@react-pdf/renderer";

export default function PDFLowestInstallmentPriceSVG() {
  return (
    <Svg height="32" viewBox="0 0 33 32" width="33">
      <Path
        d="M14.1156 27.6916C19.3479 28.8979 24.7465 26.4927 27.349 21.796C29.9515 17.0992 29.1285 11.2467 25.3316 7.44985C21.5348 3.65298 15.6823 2.83 10.9855 5.43249C6.28874 8.03498 3.88363 13.4335 5.08988 18.6659"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M5.05155 27.8874L5.45014 25.7153L3.88683 24.1556C3.75695 24.026 3.71191 23.8339 3.77065 23.6601C3.82938 23.4862 3.9817 23.3608 4.16356 23.3366L6.35248 23.0445L7.35277 21.0757C7.43588 20.9121 7.60466 20.8099 7.78814 20.812C7.97161 20.8141 8.13796 20.9203 8.21724 21.0857L9.17138 23.0772L11.3529 23.4202C11.5342 23.4486 11.6835 23.5776 11.7382 23.7527C11.7929 23.9279 11.7433 24.1189 11.6105 24.2454L10.0113 25.7684L10.3593 27.9491C10.3882 28.1303 10.3118 28.3122 10.1621 28.4183C10.0124 28.5245 9.81542 28.5364 9.65402 28.4491L7.71152 27.3988L5.74501 28.4036C5.58163 28.487 5.38502 28.4705 5.23785 28.361C5.09067 28.2514 5.01845 28.0678 5.05155 27.8874V27.8874Z"
        fillRule="evenodd"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M21.0937 18.5109V15.0727C21.0937 13.9582 20.1902 13.0547 19.0757 13.0547H12.6479C11.5334 13.0547 10.6299 13.9582 10.6299 15.0727V18.5109C10.6299 19.6254 11.5334 20.5289 12.6479 20.5289H19.0757C20.1902 20.5289 21.0937 19.6254 21.0937 18.5109V18.5109Z"
        fillRule="evenodd"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M14.9014 18.6632V17.2637M14.9014 17.2637V14.9311C15.2662 14.9311 17.1674 14.5458 17.1674 15.9974C17.1674 17.449 16.1004 17.2637 14.9014 17.2637Z"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
      />
      <Path
        d="M14.9014 12.9527L15.5617 11.1438C15.6717 10.7294 15.942 10.3757 16.313 10.1608C16.684 9.9459 17.1253 9.8874 17.5395 9.99822L22.5325 11.3383C22.9484 11.4497 23.3029 11.7219 23.5178 12.095C23.7328 12.468 23.7904 12.9113 23.6781 13.3269L22.8568 16.3962C22.683 17.0344 22.0255 17.4115 21.3869 17.2392"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
