import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorDashboard, SimulatorResultTableData } from "@simulatorBuilder/types/SimulatorDashboard";
import { Dispatch } from "react";

/**
 * Fills the table cell row with the chosen variable
 * @param tableColumnName Table column name
 * @param columnIndex Column index
 * @param rowIndex Row index
 * @param tableIndex Table index
 */
export default function defineVariableToTableCell(
  tableColumnName: string,
  columnIndex: number,
  rowIndex: number,
  tableIndex: number,
  tableContent: Array<SimulatorResultTableData>,
  simulatorDashboard: SimulatorDashboard,
  simulator: Simulator,
  dispatch: Dispatch<unknown>
) {
  const table = { ...tableContent[tableIndex] };
  const updatedTableContent = [...tableContent.slice(0, tableIndex), ...tableContent.slice(tableIndex + 1)];
  const tableCell = table.rows[rowIndex]?.rowContent[columnIndex];

  // Se for a primeira coluna (coluna fixa), apenas atualiza o texto da célula
  if (columnIndex === 0) {
    const updatedTableCell = {
      ...tableCell,
      data: {
        ...tableCell.data,
        column: 0,
        label: tableColumnName,
        line: rowIndex,
        name: "",
        type: "textDefault",
        value: "",
      },
    };

    const updatedRows = table.rows.map((row, rIndex) =>
      rIndex === rowIndex
        ? {
            ...row,
            rowContent: row.rowContent.map((cell, cIndex) => (cIndex === columnIndex ? updatedTableCell : cell)),
          }
        : row
    );

    const updatedTable = { ...table, rows: updatedRows };
    const updatedTables = [
      ...updatedTableContent.slice(0, tableIndex),
      updatedTable,
      ...updatedTableContent.slice(tableIndex),
    ];

    const updatedSimulatorDashboard = { ...simulatorDashboard, tables: updatedTables };

    return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
  }

  // Para as outras colunas (que não são a coluna fixa)
  const variable = simulator.variables.find((variable) => variable.name === tableColumnName);
  if (!variable) return;
  if (!tableCell) return;

  const updatedTableCell = {
    ...tableCell,
    data: { ...variable, column: columnIndex, line: rowIndex },
  };

  const updatedRows = table.rows.map((row, rIndex) =>
    rIndex === rowIndex
      ? {
          ...row,
          rowContent: row.rowContent.map((cell, cIndex) => (cIndex === columnIndex ? updatedTableCell : cell)),
        }
      : row
  );

  const updatedTable = { ...table, rows: updatedRows };
  const updatedTables = [
    ...updatedTableContent.slice(0, tableIndex),
    updatedTable,
    ...updatedTableContent.slice(tableIndex),
  ];

  const updatedSimulatorDashboard = { ...simulatorDashboard, tables: updatedTables };

  return dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
}
