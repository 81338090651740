import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { SimulatorParametersRaw } from "../types/SimulatorParametersRaw";

// List Simulator Parameters by Product (Superadmin Endpoint)
export const adminListSimulatorParametersByProduct = (
  productId: string,
  tenantCode: string
): AxiosPromise<Array<SimulatorParametersRaw>> => {
  return get(`/admin/simulation/product?product_id=${productId}&tenant_code=${tenantCode}`);
};
