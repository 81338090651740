import { LqdButton, LqdCircularLoader } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TenantCreateRaw } from "../../types/TenantCreateRaw";
import { TenantResponse } from "../../types/TenantResponse";
import TenantSummaryCards from "./TenantCreationSteps/TenantSummaryCards";

type TenantFormEditSummaryProps = {
  checkedProviders: Array<string>;
  checkedSegments: Array<string>;
  handleSubmit: () => void;
  loading: boolean;
  tenant: TenantCreateRaw | TenantResponse;
};

export default function TenantFormEditSummary(props: TenantFormEditSummaryProps) {
  const { checkedProviders, checkedSegments, handleSubmit, loading, tenant } = props;

  const { t } = useTranslation();

  return (
    <Box sx={{ margin: "auto", mt: 30 }}>
      <TenantSummaryCards checkedProviders={checkedProviders} checkedSegments={checkedSegments} tenant={tenant} />
      <Box sx={{ my: 5, textAlign: "right" }}>
        <LqdButton disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
          ) : (
            t("Confirmar atualização de empresa")
          )}
        </LqdButton>
      </Box>
    </Box>
  );
}
