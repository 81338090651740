import { AxiosPromise } from "axios";
import { post } from "../../common/utils/apiUtils";
import { SignInResponse } from "../types/SignInResponse";

// SuperAdmin special switch account method.
export const adminSwitchAccount = (
  userEmail: string,
  tenantCode: string,
  mfaCode?: string
): AxiosPromise<SignInResponse> => {
  return post("/auth/switch-account", { mfa: mfaCode, tenant_code: tenantCode, username: userEmail });
};
