import { put } from "../../common/utils/apiUtils";
import { SimulatorRaw } from "../types/Simulator";

/** Atualiza os dados de um simulador do Builder de Simuladores, com a validação das chaves.
 * @param simulatorId O ID do simulador.
 * @param simulatorData Os dados do simulador (objeto completo).
 */
export const updateSimulatorValidation = async (simulatorId: string, simulatorData: SimulatorRaw) => {
  return put<SimulatorRaw>(`/simulation/builder/simulators/${simulatorId}/validation`, simulatorData);
};
