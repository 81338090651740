import { LqdTypography } from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { highlightText } from "@common/utils/highlightText";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { ObjectOf } from "../../../../../../common/types/ObjectOf";
import { BuilderSimulation } from "../../../../../types/Simulation/Builder/BuilderSimulation";
import { BuilderSimulationTableData } from "../../../../../types/Simulation/Builder/BuilderSimulationTable";
import { formatCardValueBasedOnType } from "../../../../../utils/analysisDetailsDataFormatter";

type BuilderSimulatorTablesProps = {
  inputSearchData?: SimulatorTesterSearchData;
  simulation: BuilderSimulation;
  type?: "default" | "tester";
};

export default function BuilderSimulatorTables(props: BuilderSimulatorTablesProps) {
  const { inputSearchData, simulation, type } = props;

  const tables = simulation.dashboard?.tables || [];

  const getHighlightedLabel = (label: string, type: string, searchedText?: string) => {
    if (type === "textDefault") return "";
    return searchedText ? highlightText(label, searchedText, "12px") : label;
  };

  return (
    <>
      {tables.map((table, tableIndex) => {
        const groupedData = table.data
          .flat()
          .reduce((acc: ObjectOf<Array<BuilderSimulationTableData>>, curr: BuilderSimulationTableData) => {
            if (!acc[curr.line]) {
              acc[curr.line] = [];
            }
            acc[curr.line][curr.column] = curr;
            return acc;
          }, {});

        return (
          <Box key={tableIndex} sx={{ ":last-child": { mb: 0 }, mb: 5 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {table.column_names.map((column) => (
                    <TableCell
                      key={column.index}
                      sx={{
                        ":last-child": { borderRight: "none" },
                        alignItems: "center",
                        backgroundColor: "rgba(127, 135, 152, 0.04)",
                        borderBottom: "1px solid rgba(212, 215, 220, 1)",
                        borderRight: "1px solid rgba(212, 215, 220, 1)",
                        color: type === "tester" ? "rgba(33, 36, 42, 1)" : "default",
                        fontWeight: type === "tester" ? "normal" : "bold",
                        height: "60px",
                        padding: "0px 16px",
                      }}
                    >
                      {column.name ? capitalizeString(column.name) : ""}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(groupedData).map((row, index) => (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{ ":last-child": { borderRight: "none" }, borderRight: "1px solid rgba(212, 215, 220, 1)" }}
                      >
                        {type === "tester" ? (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ alignItems: "center", display: "flex", gap: 0.5 }}>
                              <LqdTypography color="rgba(75, 75, 75, 1)" textstyle="c1Caption">
                                {getHighlightedLabel(cell.label, cell.type, inputSearchData?.searchedText)}
                              </LqdTypography>
                              <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                                {cell.name}
                              </LqdTypography>
                            </Box>
                            <LqdTypography>
                              {formatCardValueBasedOnType(cell.value, cell.type, cell.label)}
                            </LqdTypography>
                          </Box>
                        ) : (
                          formatCardValueBasedOnType(cell.value, cell.type, cell.label)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        );
      })}
    </>
  );
}
