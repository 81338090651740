import { LqdIconProps } from "../../types/IconProps";

export default function LqdObservationIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M17.3813 4.61792V4.61792C18.2097 5.44684 18.2097 6.79024 17.3813 7.61917L7.39017 17.6103C7.13377 17.8667 6.81253 18.0486 6.46078 18.1365L2.99634 19.0029L3.8627 15.5385C3.95066 15.1867 4.13254 14.8655 4.38892 14.6091L14.3811 4.61792C15.2097 3.78963 16.5527 3.78963 17.3813 4.61792Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.5015 9.49893L12.5002 6.49768"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M21.0036 18.0025L19.9092 19.097C18.7005 20.3054 16.7411 20.3054 15.5324 19.097V19.097C14.3222 17.8912 12.3647 17.8912 11.1545 19.097"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
