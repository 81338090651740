import { LqdButton } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { adminAnalysisListDeletion } from "../../api/DeleteAnalysisList";
import { adminFindAnalysis } from "../../api/FindAnalysis";
import { AnalysisResponseRaw } from "../../types/AnalysisResponseRaw";
import SuperTenantCard from "../SuperTenantCard";
import AnalysisInput from "./AnalysisInput";

export default function AnalysisDeletion() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { currentSelectedTenant } = useAppSelector((state) => state.admin);

  const [analysisIdCounter, setAnalysisIdCounter] = useState<number>(1);
  const [analysisIds, setAnalysisIds] = useState<Array<string>>([]);

  const resetState = () => {
    setAnalysisIdCounter(1);
    setAnalysisIds([]);
  };

  const findAnalysis = async (analysisIdList: Array<string>) => {
    try {
      const { data } = await adminFindAnalysis(analysisIdList, currentSelectedTenant.code);

      return data;
    } catch (error) {
      handleLiquidErrors(error);
    }

    return [];
  };

  const onSubmitAnalysisDeletion = async () => {
    dispatch(dialogCalled({ actions: [], body: "", title: "", type: "loading" }));

    try {
      const analysisList = await findAnalysis(analysisIds);
      const filterUniqueAnalysis = analysisList.filter((analysis, index, self) => {
        const { analysis_id, error } = analysis;
        const indexOfFirstOccurrence = self.findIndex((a) => a.analysis_id === analysis_id && !error);
        return indexOfFirstOccurrence === index;
      });

      dispatch(dialogCalled(null));

      const validAnalysisList = filterUniqueAnalysis.filter((analysis) => !analysis.error);

      if (!validAnalysisList.length) {
        const notification: ToastNotification = {
          message: "Nenhuma conexão encontrada com os IDs fornecidos.",
          type: "error",
        };
        dispatch(toastCalled(notification));
      } else {
        dispatch(
          dialogCalled({
            actions: [
              { title: "Voltar" },
              { onClick: () => onCallConfirmDialog(validAnalysisList), title: "Confirmar exclusão" },
            ],
            body: "Confira abaixo a lista de conexões que serão excluídas:",
            bodySecundary: "Deseja continuar?",
            itemList: analysisList.map((analysis) => ({
              labelOne: "ID da Conexão:",
              labelTwo: analysis.error ? "Essa conexão não foi encontrada." : "",
              valueOne: analysis.analysis_id,
              valueTwo: null,
            })),
            title: "Excluir conexões",
            type: "list",
          })
        );
      }
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const onCallConfirmDialog = (analysisList: Array<AnalysisResponseRaw>) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => onConfirmAnalysisDeletion(analysisList), title: "Confirmar" }],
        body: "Este procedimento é irreversível e resultará na perda do rastreamento dessas conexões.",
        bodySecundary: "Para prosseguir mesmo assim, digite EXCLUIR CONEXÕES no campo abaixo.",
        confirmationString: "EXCLUIR CONEXÕES",
        title: "Exclusão de Conexões",
        type: "confirmation",
      })
    );
  };

  const onConfirmAnalysisDeletion = async (analysisList: Array<AnalysisResponseRaw>) => {
    dispatch(dialogLoaded(true));

    try {
      const validAnalysisIds = analysisList.map((analysis) => analysis.analysis_id);

      if (validAnalysisIds.length > 0) {
        await adminAnalysisListDeletion(validAnalysisIds, currentSelectedTenant.code);

        const notification: ToastNotification = {
          message: "As conexões foram removidas com sucesso.",
          type: "success",
        };
        dispatch(toastCalled(notification));
      }
      resetState();
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const handleChange = (value: string, analysisIndex: number) => {
    if (value === "") {
      const newArray = [...analysisIds];
      newArray.splice(analysisIndex, 1);
      setAnalysisIds(newArray);
      return;
    }

    const newArray = [...analysisIds];
    newArray[analysisIndex] = value;
    setAnalysisIds(newArray);
  };

  const handleDeleteInput = (analysisIndex: number) => {
    const newArray = [...analysisIds];
    newArray.splice(analysisIndex, 1);
    setAnalysisIds(newArray);
    setAnalysisIdCounter(analysisIdCounter - 1);
  };

  const renderInputs = () => {
    const inputs = [];

    for (let analysisIndex = 0; analysisIndex < analysisIdCounter; analysisIndex += 1) {
      inputs.push(
        <AnalysisInput
          analysisIndex={analysisIndex}
          inputValue={analysisIds[analysisIndex] || ""}
          onChangeMethod={(value: string) => handleChange(value, analysisIndex)}
          onRemoveMethod={() => handleDeleteInput(analysisIndex)}
        />
      );
    }

    return inputs;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "20px 50px",
          height: "300px",
          margin: "auto",
          mt: 3,
          width: "90%",
        }}
      >
        {renderInputs()}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "95%" }}>
        <LqdButton
          disabled={!analysisIds.length || analysisIds.length !== analysisIdCounter}
          onClick={onSubmitAnalysisDeletion}
          sx={{ mr: 3 }}
          type="outlineTertiary"
        >
          Excluir todas
        </LqdButton>
        <LqdButton onClick={() => setAnalysisIdCounter(analysisIdCounter + 1)}>Adicionar Campo de Conexão</LqdButton>
      </Box>
    </Box>
  );
}
