import { ObjectOf } from "@/analysis-v3/src/features/common/types/ObjectOf";
import React, { useState } from "react";
import { SelectOption } from "./types/SelectOption";
import LqdCompleteSelect from "./variations/CompleteSelect";
import LqdStandardSelect from "./variations/StandardSelect";

type LqdSelectProps = {
  editButton?: JSX.Element;
  functionKey?: string;
  isOpened?: boolean;
  newOptionButton?: JSX.Element | null;
  onChange: (value: string) => void;
  options: Array<SelectOption>;
  placeholder?: string;
  searchPlaceholder?: string;
  title?: string;
  type?: "complete" | "standard";
  value: number | string;
};

export default function LqdSelect(props: LqdSelectProps) {
  const {
    editButton,
    functionKey,
    isOpened,
    newOptionButton,
    onChange,
    options,
    placeholder,
    searchPlaceholder,
    title,
    type,
    value,
  } = props;

  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(isOpened || false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const handleInputChange = (event: React.SyntheticEvent<Element, Event> | null, newInputValue: string) => {
    if (!isTyping && event?.type === "change") setIsTyping(true);
    setInputValue(newInputValue);
  };

  const handleSelect = (newValue: SelectOption | null) => {
    setIsTyping(false);

    if (newValue) {
      onChange(String(newValue.value));
      setIsOpen(false);
    }
  };

  const selectVariationLookup: ObjectOf<JSX.Element> = {
    complete: (
      <LqdCompleteSelect
        onChange={onChange}
        options={options}
        searchPlaceholder={searchPlaceholder || ""}
        title={title || ""}
        value={value}
      />
    ),
    standard: (
      <LqdStandardSelect
        editButton={editButton}
        functionKey={functionKey}
        handleInputChange={handleInputChange}
        handleSelect={handleSelect}
        inputValue={inputValue}
        isOpen={isOpen}
        isTyping={isTyping}
        newOptionButton={newOptionButton}
        options={options}
        placeholder={placeholder}
        setIsOpen={setIsOpen}
        value={value}
      />
    ),
  };

  return selectVariationLookup[type || "standard"];
}
