import { MenuItem, MenuItemProps } from "@mui/material";

export default function LqdSelectMenuItem(props: MenuItemProps) {
  const { sx } = props;

  return (
    <MenuItem
      {...props}
      sx={{
        "&:hover": { backgroundColor: "rgba(191, 196, 205, 0.28)" },
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: 8,
        minWidth: "157px",
        px: 2,
        py: 1.5,
        ...sx,
      }}
    />
  );
}
