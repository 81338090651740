import { Box } from "@mui/material";
import { useAppSelector } from "../../../../store";
import CreateSegmentStep from "../TemplateConfig/CreateSegmentStep";
import TenantFormStep1 from "./TenantCreationSteps/TenantFormStep1";
import TenantFormStep2 from "./TenantCreationSteps/TenantFormStep2";
import TenantFormStep3 from "./TenantCreationSteps/TenantFormStep3";
import TenantFormStep4 from "./TenantCreationSteps/TenantFormStep4";

type CreateTenantFormProps = {
  checkedProviders: Array<string>;
  checkedSegments: Array<string>;
  name: string;
  onClose: () => void;
  setCheckedProviders: (checkedProviders: Array<string>) => void;
  setCheckedSegments: (checkedSegments: Array<string>) => void;
  setName: (value: string) => void;
};

export default function CreateTenantForm(props: CreateTenantFormProps) {
  const { checkedProviders, checkedSegments, name, onClose, setCheckedProviders, setCheckedSegments, setName } = props;

  const { createTenantModalStep } = useAppSelector((state) => state.admin);

  const renderCurrentStep = () => {
    switch (createTenantModalStep) {
      case 1:
        return <TenantFormStep1 setTenantName={setName} tenantName={name} />;
      case 2:
        return <TenantFormStep2 checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
      case 3:
        return <TenantFormStep3 checkedProviders={checkedProviders} setCheckedProviders={setCheckedProviders} />;
      case 4:
        return (
          <TenantFormStep4
            checkedProviders={checkedProviders}
            checkedSegments={checkedSegments}
            onClose={onClose}
            tenantName={name}
          />
        );
      case 5:
        return <CreateSegmentStep checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
    }
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "80vh",
        justifyContent: "space-between",
        overflow: "hidden",
        px: "15%",
      }}
    >
      {renderCurrentStep()}
    </Box>
  );
}
