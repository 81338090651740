import { Box, Container, LinearProgress, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import LiquidLogo from "./LiquidLogo";

type MainLoadingScreenProps = {
  delay?: number;
  sx?: SxProps;
};

export default function MainLoadingScreen(props: MainLoadingScreenProps) {
  const { delay, sx } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return showLoader ? (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        width: "100vw",
        ...sx,
      }}
    >
      <LiquidLogo />
      <Container maxWidth="xs" sx={{ mt: 5, px: { md: 0, xs: 4 } }}>
        <LinearProgress sx={{ borderRadius: "4px", height: "0.4rem", width: "100%" }} />
      </Container>
    </Box>
  ) : null;
}
