import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, TextField } from "@mui/material";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createUserModalStepNext, editUserFormStepNext } from "../usersSlice";

type UserFormStep1Props = {
  name: string;
  setName: (value: string) => void;
};

export default function UserFormStep1(props: UserFormStep1Props) {
  const dispatch = useAppDispatch();
  const { name, setName } = props;
  const { userToEdit } = useAppSelector((state) => state.users);
  const CHAR_LIMIT = 25;
  const showNameLengthAlert = name.length > CHAR_LIMIT;
  const trimmedName = name.trim();
  const maySubmit =
    trimmedName.length > 2 && !showNameLengthAlert && (userToEdit ? trimmedName !== userToEdit.name : true);

  const handleSubmit = () => {
    dispatch(userToEdit ? editUserFormStepNext() : createUserModalStepNext());
  };

  return (
    <Box
      sx={{
        height: "240px",
        mt: 20,
        pt: { lg: 7, md: 4 },
        px: 5,
        width: { lg: "60vw", md: "80vw" },
      }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        {t("Nome completo")}
      </LqdTypography>
      <TextField
        autoFocus
        className="lqd-user-form-step1-input"
        error={showNameLengthAlert}
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={(event) => setName(event.target.value)}
        onKeyUp={(key) => (key.key === "Enter" ? handleSubmit() : null)}
        placeholder="Digite"
        sx={{
          "& .MuiInput-underline:after": { borderBottomColor: "rgba(33, 36, 42, 1)" },
          "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          width: "100%",
        }}
        value={name}
        variant="standard"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
          {showNameLengthAlert
            ? t(`Você ultrapassou o limite de {{limit}} caracteres. Abrevie para continuar.`, { limit: CHAR_LIMIT })
            : ""}
        </LqdTypography>
        <LqdTypography
          sx={{
            color: showNameLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)",
            display: "block",
            textAlign: "right",
          }}
          textstyle="p2Paragraph"
        >
          {`${name.length}/${CHAR_LIMIT} caracteres`}
        </LqdTypography>
      </Box>
      <Box sx={{ mt: 3, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
