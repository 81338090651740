import { put } from "@common/utils/apiUtils";
import { SignaturePeerData } from "@signature/types/schedule";

export type UpdateMetadataParams = {
  metadata: {
    email: string;
    process_id?: number;
  };
  token: string;
};

export const UpdateMetadata = (params: UpdateMetadataParams) =>
  put<SignaturePeerData>("/sign/token-metadata", params.metadata, { headers: { "peer-token": params.token } });
