import { LqdEllipsisIcon, LqdIconButton } from "@/liquid-components/src";
import { Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { AnalysisDetails } from "../../types/AnalysisDetails/Analysis/AnalysisDetails";
import AnalysisDashboardArchiveButton from "./AnalysisDashboardArchiveButton";
import AnalysisDashboardPDFButtons from "./AnalysisDashboardPDFButtons";

type AnalysisDashboardMobileAnalysisMenuProps = {
  analysisDetails: AnalysisDetails;
};

export default function AnalysisDashboardMobileAnalysisMenu(props: AnalysisDashboardMobileAnalysisMenuProps) {
  const { analysisDetails } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shouldOpenDropdown = Boolean(anchorEl);

  return (
    <>
      <LqdIconButton
        onClick={(event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
        round="true"
        type="outlinePrimary"
      >
        <LqdEllipsisIcon />
      </LqdIconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={shouldOpenDropdown}
        sx={{
          "& .MuiList-root": { padding: 0 },
          "& .MuiPaper-root": {
            backgroundColor: "rgba(249, 249, 250, 1)",
            borderRadius: "12px",
            boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10)",
            width: "260px",
          },
          mt: 0.5,
        }}
      >
        <AnalysisDashboardPDFButtons isMobileView={true} />
        <MenuItem sx={{ margin: 0, padding: 0 }}>
          <AnalysisDashboardArchiveButton analysisDetails={analysisDetails} />
        </MenuItem>
      </Menu>
    </>
  );
}
