import { LqdLink, LqdLockIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";

export default function VideoAskEmbedFooter() {
  const { width } = useWindowSize();

  return (
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
      <Stack direction="row">
        <LqdTypography
          color="rgba(0, 0, 0, 1)"
          sx={{ pr: 1.5 }}
          textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}
        >
          Atalhos úteis:
        </LqdTypography>
        <LqdLink
          href="https://www.ilovepdf.com/pt/comprimir_pdf"
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 1)", fontSize: { md: "16px", xs: "12px" } }}
          target="_blank"
        >
          Comprimir PDF
        </LqdLink>
        <LqdTypography color="rgba(0, 0, 0, 1)" sx={{ px: 1.5 }}>
          -
        </LqdTypography>
        <LqdLink
          color="rgba(0, 0, 0, 1)"
          href="https://www.ilovepdf.com/pt/juntar_pdf"
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 1)", fontSize: { md: "16px", xs: "12px" } }}
          target="_blank"
        >
          Juntar páginas
        </LqdLink>
      </Stack>
      <Stack alignItems="center" direction="row" sx={{ mb: 2, mt: 3 }}>
        <LqdLockIcon color="rgba(155, 162, 175, 1)" size={18} />
        <LqdTypography sx={{ color: "rgba(155, 162, 175, 1)" }} textstyle="c1Caption">
          Ambiente Seguro Liquid AI.
        </LqdTypography>
      </Stack>
    </Box>
  );
}
