import { LqdSelect } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../store";
import { SimulatorResultTable } from "../../../../types/SimulatorDashboard";

type SimulatorResultTableEmptyLineProps = {
  columnIndex: number;
  handleTableChange: (tableColumnName: string, columnIndex: number, rowIndex: number, tableIndex: number) => void;
  lastColumn: boolean;
  rowIndex: number;
  selectedVariable: { data: SimulatorResultTable | null; id: string };
  tableIndex: number;
};

export default function SimulatorResultTableEmptyLine(props: SimulatorResultTableEmptyLineProps) {
  const { columnIndex, handleTableChange, lastColumn, rowIndex, selectedVariable, tableIndex } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const options = simulator.variables.map((variable, index) => ({
    id: variable.id || index.toString(),
    label: variable.label,
    value: variable.name,
  }));

  return (
    <Box
      sx={{
        alignItems: "center",
        borderBottom: "1px solid rgba(212, 215, 220, 1)",
        borderRight: lastColumn ? "none" : "1px solid rgba(212, 215, 220, 1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "290px",
        width: "100%",
      }}
    >
      <LqdSelect
        isOpened={false}
        onChange={(value) => handleTableChange(value, columnIndex, rowIndex, tableIndex)}
        options={options}
        placeholder="Selecione uma variável"
        value={selectedVariable.data ? selectedVariable.data.name : ""}
      />
    </Box>
  );
}
