import { SimulatorFormStepResumeView } from "@simulatorBuilder/types/SimulatorFormStepResumeView";
import { SimulatorFunction } from "@simulatorBuilder/types/SimulatorFunction";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import { Dispatch, SetStateAction } from "react";

export default function blockFunctionClick(
  blocks: Array<SimulatorFunctionBlock>,
  emptyFunction: SimulatorFunction,
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void,
  setBlockId: Dispatch<SetStateAction<number | null>>,
  setBlockName: Dispatch<SetStateAction<string>>,
  setCurrentFunctionBlocksView: Dispatch<SetStateAction<SimulatorFunctionBlocksViews>>,
  setModalView: Dispatch<SetStateAction<SimulatorFormStepResumeView | null>>,
  setIsDuplicating: Dispatch<SetStateAction<boolean>>,
  block?: SimulatorFunctionBlock
) {
  if (!blocks.length) {
    setBlocks([...blocks, { block_id: 0, functions: [emptyFunction], name: "" }]);
    setBlockId(0);
    setBlockName("");
    setCurrentFunctionBlocksView("blockName");
    return setModalView(SimulatorFormStepResumeView.FUNCTIONS);
  }

  if (!block) {
    setBlocks([...blocks, { block_id: blocks[blocks.length - 1].block_id + 1, functions: [emptyFunction], name: "" }]);
    setBlockId(blocks[blocks.length - 1].block_id + 1);
    setBlockName("");
    setCurrentFunctionBlocksView("blockName");
    return setModalView(SimulatorFormStepResumeView.FUNCTIONS);
  }

  if (block) {
    setIsDuplicating(false);
    setBlockName(block.name);
    setBlockId(block.block_id);
    setCurrentFunctionBlocksView("blockName");
    setModalView(SimulatorFormStepResumeView.FUNCTIONS);
  }
}
