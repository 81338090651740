import {
  LqdButton,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdInput,
  LqdTypography,
} from "@/liquid-components/src";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogErrorMessageChanged, dialogFieldValueChanged } from "../../commonSlice";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";

/** Componente de SmallDialog para usos em que é necessário confirmar uma ação, através de um campo de texto.
 * @param actions Lista de ações do Modal.
 * @param body Texto principal do Modal.
 * @param bodySecundary Texto secundário do Modal.
 * @param confirmationString String utilizada no .test() do Regex, para confirmar a ação.
 * @param specialValidation Função de validação do input, nos casos em que não se pode utilizar o Regex (como na recuperação de senha).
 * @param title Título do Modal.
 */
export default function SmallDialogConfirmation() {
  const dispatch = useAppDispatch();

  const { dialogContent, dialogErrorMessage, dialogFieldValue, dialogLoading, dialogShowState } = useAppSelector(
    (state) => state.common
  );

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const handleConfirmClick = (action: SmallDialogAction, actionIndex: number) => {
    if (action.onClick) action.onClick(dialogFieldValue);

    if (actionIndex === 0) {
      dispatch(dialogCalled(null));
    }
  };

  const mayConfirm = () => {
    switch (true) {
      case !!dialogContent.confirmationString:
        return new RegExp(`^${dialogContent.confirmationString}$`, "gi").test(dialogFieldValue as string);
      case dialogContent.specialValidation && dialogContent.specialValidation(dialogFieldValue as string):
        return true;
      case dialogFieldValue && !dialogErrorMessage:
        return true;
      default:
        return false;
    }
  };

  return (
    <LqdDialog onClose={() => handleConfirmClick(dialogContent.actions[0], 0)} open={dialogShowState}>
      <SmallDialogHeader body={dialogContent.body} title={dialogContent.title} />
      {dialogContent.bodySecundary ? (
        <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ my: 1.5 }} textstyle="c1Caption">
          {dialogContent.bodySecundary}
        </LqdTypography>
      ) : null}
      <LqdInput
        boxSize="small"
        className="lqd-dialog-confirmation-input"
        disabled={dialogLoading}
        fullBox
        maskType="string"
        onConfirm={() => handleConfirmClick(dialogContent.actions[1], 1)}
        onConfirmValidation={mayConfirm()}
        placeholderText="Digite aqui"
        setValue={(value) => {
          dispatch(dialogErrorMessageChanged(""));
          dispatch(dialogFieldValueChanged(value));
        }}
        value={dialogFieldValue as string}
      />
      {dialogErrorMessage ? (
        <LqdTypography color="rgba(177, 30, 84, 1)" sx={{ mt: 1 }} textstyle="c1Caption">
          {dialogErrorMessage}
        </LqdTypography>
      ) : null}
      <LqdDialogActions sx={{ mt: 2.5 }}>
        {dialogContent.actions.map((action, index, actions) => {
          return (
            <LqdButton
              className="lqd-dialog-button"
              disabled={index === 0 ? false : dialogLoading || !mayConfirm()}
              key={action.title}
              onClick={() => handleConfirmClick(action, index)}
              type={getButtonType(index, actions)}
            >
              {dialogLoading && actions.length > 1 && index !== 0 ? (
                <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
              ) : (
                action.title
              )}
            </LqdButton>
          );
        })}
      </LqdDialogActions>
    </LqdDialog>
  );
}
