import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterThirteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        13. PROPRIEDADE INTELECTUAL
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        13.1. Todos os direitos sobre o Liquid, bem como marcas, logotipos, componentes, novas atualizações ou
        variantes, todas as demais informações e dados correlatos, bem como suas novas versões, revisões, melhoramentos,
        customizações, trabalhos, relatórios e análises dele decorrentes e/ou novos produtos deles derivados, incluindo,
        mas não se limitando a direitos autorais e intelectuais, know-how, segredos comerciais ou de negócio, são de
        propriedade exclusiva da Liquid, ainda que não registrada.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        13.1.1. O Usuário Decisor poderá realizar sugestões de melhorias à Liquid que, se forem implementadas,
        continuarão sendo de integral propriedade intelectual da Liquid. O Usuário Decisor não terá qualquer direito de
        indenização, salário ou compensação por ter realizado uma sugestão de melhoria.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        13.1.2. O Usuário Decisor se compromete ainda, a se abster de, em seu próprio proveito ou de terceiros, adotar
        quaisquer atos de proteção e preservação dos direitos de propriedade intelectual pertencentes à Liquid, conforme
        item 13.1 acima, devendo, contudo, colaborar para a salvaguarda dos interesses da Liquid em qualquer caso.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        13.1.3. Também em razão do disposto no 13.1 acima, o Usuário se compromete a obter, do seu Pessoal (conforme
        definido no item 14.2 abaixo), todas as autorizações, cessões, transferências e demais documentos necessários
        para garantir os direitos de propriedade intelectual da Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        13.2. O Liquid é protegido por direitos autorais nos termos da legislação em vigor. A Liquid concede ao Usuário
        Decisor apenas o direito simples e intransferível de usar o Liquid adequadamente para seus próprios fins
        comerciais e somente durante o período de vigência desta contratação. Com o seu término, terminará o direito de
        uso do Usuário Decisor. O direito de uso do Usuário Decisor não inclui o direito de cessão, transferência,
        licenciamento, sublicenciamento do Liquid, bem como o direito de disponibilizar o acesso à plataforma e
        consequentemente ao Liquid a quaisquer terceiros, salvo disposição contrária em contrato específico e/ou
        autorização escrita da Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        13.2.1. A utilização indevida ou suspeita de utilização indevida pelo Usuário Decisor, por pelo Usuário Decisor
        ou seu Pessoal (conforme definido abaixo), dos direitos sobre o Liquid, aqui entendida como a utilização ou
        suspeita de utilização em violação contratual ou legal, poderá implicar na suspensão do direito de uso do
        Usuário e quaisquer terceiros envolvidos imediatamente, e sem prévio aviso, assim como na resolução contratual e
        cobrança das perdas e danos sofridos pela Liquid e/ou terceiros.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        13.3. O Usuário Decisor não deverá editar, alterar, fazer engenharia reversa, descompilar ou desmontar o Liquid
        ou partes dele, determinar o código-fonte de e/ou consertar o Liquid contratado de outra forma, bem como criar
        trabalhos derivados do Liquid contratado.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        13.4. A Liquid possui direitos autorais e de propriedade intelectual também sobre os relatórios e análises, não
        podendo tais materiais serem divulgados ou utilizados como parte da operação do Usuário, apresentações ou
        materiais do Usuário Decisor.
      </LqdTypography>
    </>
  );
}
