import { LqdButton, LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { Stack, TextField } from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { automationToDuplicateIdChanged, onDuplicateAutomation, onGetAutomations } from "../automationsSlice";

const CHAR_LIMIT = 25;

export default function DuplicateAutomationModal() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { automations, automationToDuplicateId } = useAppSelector((state) => state.automations);
  const automationToDuplicate = automationToDuplicateId ? automations[automationToDuplicateId] : null;

  const [automationName, setAutomationName] = useState(`Cópia de ${automationToDuplicate?.name}` || "");
  const [loading, setLoading] = useState(false);

  const automationNameLengthError = automationName.length < 3 || automationName.length > CHAR_LIMIT;
  const automationNameExists = useMemo(() => {
    const nameExists = Object.values(automations).some(
      (automation) => automation.name.trim().toLowerCase() === automationName.trim().toLowerCase()
    );
    return nameExists;
  }, [automations, automationName]);

  const automationError = automationNameLengthError || automationNameExists;

  useEffect(() => {
    if (automationToDuplicate) {
      setAutomationName(`Cópia de ${automationToDuplicate.name}`);
    }
  }, [automationToDuplicate]);

  const onClose = () => {
    dispatch(automationToDuplicateIdChanged(null));
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!automationToDuplicateId) {
      throw new Error("Could not get automationToDuplicateId");
    }
    try {
      const notification: ToastNotification = {
        message: "A automação foi duplicada com sucesso.",
        type: "success",
      };

      await dispatch(onDuplicateAutomation({ configurationId: automationToDuplicateId, name: automationName }));
      await dispatch(onGetAutomations());
      dispatch(toastCalled(notification));
      dispatch(automationToDuplicateIdChanged(null));
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao duplicar a automação. Tente novamente mais tarde."));
    } finally {
      setTimeout(() => setLoading(false), 1000);
    }
  };

  const handleAutomationNameError = () => {
    switch (true) {
      case automationName.length > CHAR_LIMIT:
        return t(`Você ultrapassou o limite de {{limit}} caracteres. Abrevie para continuar.`, { limit: CHAR_LIMIT });
      case automationNameExists:
        return t("Já existe uma automação com este nome.");
      default:
        return "";
    }
  };

  return (
    <FullscreenDialog onClose={onClose} open={Boolean(automationToDuplicateId)} title="Duplicar Automação">
      <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
        <Stack alignItems="flex-end">
          <LqdTypography sx={{ mb: 1.5, width: "874px" }} textstyle="h5Headline">
            {t("Qual é o nome da nova automação?")}
          </LqdTypography>
          <TextField
            autoFocus
            inputProps={{
              sx: {
                color: "rgba(33, 36, 42, 1)",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "28px",
                marginBottom: "12px",
                padding: 0,
              },
            }}
            onChange={(event) => setAutomationName(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !automationError && !loading) {
                onSubmit();
              }
            }}
            placeholder={t("Digite")}
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "rgba(33, 36, 42, 1)" },
              "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
              ".MuiInputBase-root": { boxSizing: "border-box", my: 1.5, pl: 2 },
              width: "874px",
            }}
            value={automationName}
            variant="standard"
          />
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1, width: "100%" }}>
            <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
              {handleAutomationNameError()}
            </LqdTypography>
            <LqdTypography
              sx={{
                color: automationNameLengthError ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)",
                display: "block",
                textAlign: "right",
              }}
              textstyle="p2Paragraph"
            >
              {`${automationName.length}/${CHAR_LIMIT} caracteres`}
            </LqdTypography>
          </Stack>
          <LqdButton disabled={automationError || loading} onClick={onSubmit} sx={{ mt: 3 }}>
            {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : t("Continuar")}
          </LqdButton>
        </Stack>
      </Stack>
    </FullscreenDialog>
  );
}
