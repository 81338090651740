import { LqdButton, LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { dialogCalled, dialogErrorMessageChanged, dialogLoaded } from "../../../common/commonSlice";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { validateOpenCreditDocument } from "../../api/OpenCreditDocumentValidation";
import openCreditWelcomeSVG from "../../assets/openCreditWelcome.svg";

type OpenCreditDisagreeStateProps = {
  isLoading: boolean;
  onCancel: () => void;
  onInit: () => void;
  passId: string;
};

export default function OpenCreditDisagreeState(props: OpenCreditDisagreeStateProps) {
  const { isLoading, onCancel, onInit, passId } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { width } = useWindowSize();

  const checkCPFValidation = async (CPFValue: string) => {
    dispatch(dialogLoaded(true));

    try {
      const isCPFValidRaw = await validateOpenCreditDocument(CPFValue, passId);
      const isCPFValid = isCPFValidRaw.data;

      if (isCPFValid.valid) {
        dispatch(dialogCalled(null));
        return onInit();
      } else {
        return dispatch(dialogErrorMessageChanged("CPF incompatível com o documento da autorização. Tente novamente."));
      }
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
    }
  };

  const handleStartValidation = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          { onClick: (CPFValue) => checkCPFValidation(CPFValue! as string), title: "Iniciar validação" },
        ],
        body: "Precisamos validar o seu CPF para continuar.",
        title: "Insira seu CPF",
        type: "confirmation",
      })
    );
  };

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 4 } }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "550px", minWidth: { lg: "540px", xs: 0 } }}>
        <Box>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: { lg: "block", xs: "inline" } }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Conte com a segurança
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(101, 110, 127, 1)", display: "inline", ml: { lg: 0, xs: 1 } }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Liquid
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            .
          </LqdTypography>
        </Box>
        <LqdTypography
          sx={{ color: "rgba(56, 61, 70, 1)", pt: { lg: "30px", xs: 2.5 }, textAlign: "justify" }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          Oferecemos um ambiente seguro para validar sua identidade e compartilhar informações do seu perfil. Tem
          certeza que deseja desistir da validação?
        </LqdTypography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
            my: { lg: 5, xs: 2.5 },
            width: "fit-content",
          }}
        >
          <LqdButton
            buttonsize={width === "xs" ? "medium" : "large"}
            disabled={isLoading}
            onClick={onCancel}
            sx={{ maxWidth: "fit-content", mb: { lg: 0, xs: 1.5 }, minWidth: "245px", mr: { lg: 2.5, xs: 0 } }}
          >
            {isLoading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Não realizar a validação"}
          </LqdButton>
          <LqdButton
            buttonsize={width === "xs" ? "medium" : "large"}
            disabled={isLoading}
            onClick={handleStartValidation}
            type="outlinePrimary"
          >
            Continuar com a validação
          </LqdButton>
        </Box>
        <Box>
          <LqdTypography sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }} textstyle="c1Caption">
            Para mais detalhes, acesse os nossos
          </LqdTypography>
          <LqdTypography
            onClick={() => window.open("https://app.liquidpass.co/open-credit/terms-and-conditions/")}
            sx={{
              ":hover": { color: "rgba(33, 36, 42, 1)", cursor: "pointer" },
              color: "rgba(0, 0, 0, 1)",
              display: "inline",
              ml: 0.5,
              textDecorationLine: "underline",
            }}
            textstyle="c1Caption"
          >
            Termos e Condições de Uso
          </LqdTypography>
          <LqdTypography sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }} textstyle="c1Caption">
            .
          </LqdTypography>
        </Box>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openCreditWelcomeSVG} />
      </Box>
    </Stack>
  );
}
