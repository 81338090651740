import { AxiosResponse } from "axios";
import { get } from "../../common/utils/apiUtils";

interface StartLocalSignatureResponse {
  signature_token: string;
}

export const startLocalSignature = async (token: string): Promise<StartLocalSignatureResponse> => {
  const response: AxiosResponse<StartLocalSignatureResponse> = await get<StartLocalSignatureResponse>(
    `/sign/start-local-signature`,
    {
      headers: { "peer-token": token },
    }
  );
  return response.data;
};
