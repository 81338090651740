import { LqdFunctionIcon, LqdTypography } from "@/liquid-components/src";
import LqdConstantVariableIcon from "@/liquid-components/src/icons/ConstantVariable/ConstantVariable";
import LqdInputVariableIcon from "@/liquid-components/src/icons/InputVariable/InputVariable";
import LqdResultVariableIcon from "@/liquid-components/src/icons/ResultVariable/ResultVariable";
import { highlightText } from "@common/utils/highlightText";
import { normalizeText } from "@common/utils/normalizeText";
import { Stack } from "@mui/material";
import { SimulatorTesterCustomNodeData } from "@simulatorBuilder/types/simulationTester";
import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunction } from "@simulatorBuilder/types/SimulatorFunction";
import { renderVariableValue } from "@simulatorBuilder/utils/renderVariableValue";
import { useAppSelector } from "../../../../../../store";

type SimulatorTesterCustomNodeFunctionsTemplateProps = {
  data: SimulatorTesterCustomNodeData;
  func: SimulatorFunction;
  searchedText: string;
  simulator: Simulator;
  testerResult: SimulatorTesterResultDBResponse | null;
};

/**
 * Um componente funcional React que renderiza um template para exibir funções de nó personalizadas
 * no Diagrama de Fluxo do Testador de Simulador. Ele exibe variáveis e resultados com ícones apropriados
 * e destaques com base no texto de pesquisa.
 *
 * @param {SimulatorTesterCustomNodeFunctionsTemplateProps} props - As propriedades passadas para o componente.
 */
export default function SimulatorTesterCustomNodeFunctionsTemplate(
  props: SimulatorTesterCustomNodeFunctionsTemplateProps
) {
  const { func, searchedText, simulator } = props;

  const { testerResult } = useAppSelector((state) => state.simulatorBuilder);

  const renderIcon = (category: string, displayValue: string) => {
    switch (category) {
      case "input":
        return (
          <LqdInputVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );

      case "constant":
        return (
          <LqdConstantVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );
      case "result":
        return (
          <LqdResultVariableIcon
            color={
              searchedText !== "" && displayValue.includes(searchedText)
                ? "rgba(127, 135, 152, 1)"
                : "rgba(207, 211, 218, 1)"
            }
            size={14}
          />
        );
      default:
        return;
    }
  };

  return (
    <Stack gap={0.8} mb={0.4} mt={0.5}>
      {Object.entries(func.variables).map(([varKey, varValue], i) => {
        let displayValue = simulator.variables
          .filter((v) => v.category !== "default")
          .find((v) => v.name === varValue)?.label;

        let variableCategory = simulator.variables.find((v) => v.name === varValue)?.category || "";

        if (func.function_name === "jump" && varKey === "block_id") {
          variableCategory = simulator.blocks.find((b) => b.block_id === varValue)?.name || "";

          const foundBlock = simulator.blocks.find((bl) => bl.block_id === Number(varValue));

          if (foundBlock) {
            displayValue = foundBlock.name;
          }
        } else if (func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false")) {
          variableCategory = simulator.blocks.find((b) => b.block_id === varValue)?.name || "";

          const foundVariable = simulator.blocks.find((b) => b.block_id === Number(varValue));
          if (foundVariable) {
            displayValue = foundVariable.name;
          }
        }
        if (displayValue) {
          return (
            <Stack
              key={i}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {func.function_name === "jump" && varKey === "block_id" ? (
                <Stack alignItems="center" flexDirection="row" gap={1.25}>
                  <LqdFunctionIcon
                    color={
                      searchedText && normalizeText(displayValue).includes(searchedText)
                        ? "rgba(127, 135, 152, 1)"
                        : "rgba(207, 211, 218, 1)"
                    }
                    size={18}
                  />
                  <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                    {highlightText(displayValue.toString(), searchedText, "12px")}
                  </LqdTypography>
                </Stack>
              ) : null}
              {func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false") ? (
                <Stack alignItems="center" flexDirection="row" gap={1.25}>
                  <LqdFunctionIcon
                    color={
                      searchedText && normalizeText(displayValue).includes(searchedText)
                        ? "rgba(127, 135, 152, 1)"
                        : "rgba(207, 211, 218, 1)"
                    }
                    size={14}
                  />
                  <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                    {highlightText(displayValue.toString(), searchedText, "12px")}
                  </LqdTypography>
                </Stack>
              ) : null}
              {!(func.function_name === "jump" && varKey === "block_id") &&
              !(func.function_name === "jump_conditional" && (varKey === "block_true" || varKey === "block_false")) ? (
                <Stack alignItems="center" flexDirection="row" gap={1.25} width="100%">
                  {renderIcon(variableCategory, displayValue)}
                  <Stack
                    sx={{
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                      {highlightText(displayValue.toString(), searchedText, "12px")}
                    </LqdTypography>
                    {testerResult && testerResult?.result?.results ? (
                      <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ fontWeight: "700" }} textstyle="l2Label">
                        {renderVariableValue({ searchedText, simulator, testerResult, type: "variable", varValue })}
                      </LqdTypography>
                    ) : null}
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          );
        }
      })}
      {Object.values(func.result).length > 0 ? (
        <Stack
          sx={{
            gap: 0.4,
          }}
        >
          {Object.values(func.result).map((result, i) => (
            <Stack
              key={i}
              sx={{
                alignItems: "center",
                flexDirection: "row",
                gap: 1.25,
              }}
            >
              <LqdResultVariableIcon
                color={
                  searchedText && Object.values(func.result).includes(searchedText)
                    ? "rgba(127, 135, 152, 1)"
                    : "rgba(207, 211, 218, 1)"
                }
                size={14}
              />
              <Stack
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 2,
                  justifyContent: "space-between",
                }}
              >
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
                  {highlightText(
                    simulator.variables.find((v) => v.name === result)?.label || result,
                    searchedText,
                    "12px"
                  )}
                </LqdTypography>
                {testerResult && testerResult?.result?.results ? (
                  <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ fontWeight: "700" }} textstyle="l2Label">
                    {renderVariableValue({ result, searchedText, simulator, testerResult, type: "result" })}
                  </LqdTypography>
                ) : null}
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
}
