import { LqdIconProps } from "../../types/IconProps";

export default function LqdLinkIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4779 13.9618L18.6698 11.7699C20.4104 9.98533 20.3927 7.13287 18.6299 5.37012C16.8672 3.60737 14.0147 3.58959 12.2301 5.33023L10.0382 7.52215"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M14.6681 9.33191L9.33191 14.6681"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M7.52215 10.0382L5.33023 12.2301C3.58959 14.0147 3.60737 16.8672 5.37012 18.6299C7.13287 20.3927 9.98533 20.4104 11.7699 18.6698L13.9618 16.4779"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
