import { LqdDownTwoIcon, LqdIconButton, LqdTextField, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Select } from "@mui/material";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { ObjectOf } from "../../../common/types/ObjectOf";

type SimulatorInputProps = {
  decimal?: boolean;
  inputText: string;
  inputValue: string;
  mask?: string;
  onChangeMethod: (value: string) => void;
  options?: Array<string>;
  placeholder?: string;
  required?: boolean;
  select?: boolean;
};

export default function SimulatorInput(props: SimulatorInputProps) {
  const { decimal, inputText, inputValue, mask, onChangeMethod, options, placeholder, required, select } = props;

  const validateInputValue = (value: string) => {
    if (Number(value.split("%")[0]) > 100) {
      return onChangeMethod("100%");
    }

    return onChangeMethod(value);
  };

  const defaultMaskOptions = {
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    decimalLimit: 2,
    decimalSymbol: ".",
    includeThousandsSeparator: true,
    integerLimit: 3,
    prefix: "",
    suffix: "%",
    thousandsSeparatorSymbol: ".",
  };

  const percentageMask = createNumberMask(defaultMaskOptions);

  const renderSimulatorInput = () => {
    const simulatorValue: ObjectOf<string> = {
      IGPM: "IGPM",
      INCC: "INCC",
      IPCA: "IPCA",
      Nenhuma: "Nenhuma",
      "Não Definido": "Não Definido",
      PRICE: "PRICE",
      SAC: "SAC",
      TR: "TR",
    };

    switch (true) {
      case select:
        return (
          <Select
            displayEmpty
            IconComponent={(props) => (
              <LqdIconButton sx={{ mt: -2.5 }} type="ghostPrimary" {...props}>
                <LqdDownTwoIcon />
              </LqdIconButton>
            )}
            MenuProps={{ sx: { ".MuiList-root": { py: 0 }, ".MuiPaper-root": { boxShadow: "none" } } }}
            onChange={(event) => onChangeMethod(event.target.value)}
            sx={{
              "& .MuiSelect-select": { height: "45px", pl: 2 },
              "& :hover::before": { border: "none" },
              alignItems: "flex-end",
              height: "30px",
              mt: 4.5,
              width: "100%",
            }}
            value={simulatorValue[inputValue]}
            variant="standard"
          >
            {options?.map((option) => (
              <MenuItem
                key={option}
                sx={{
                  "&.MuiMenuItem-root": { backgroundColor: "rgba(127, 135, 152, 0.08)" },
                  "&.MuiMenuItem-root:hover": { backgroundColor: "rgba(127, 135, 152, 0.16)" },
                }}
                value={option}
              >
                <Box sx={{ display: "flex", flexDirection: "column", height: "48px", justifyContent: "center" }}>
                  <LqdTypography sx={{ color: "rgba(101, 110, 127, 1)" }}>{option}</LqdTypography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        );
      case decimal:
        return (
          <MaskedInput
            mask={percentageMask}
            onChange={(event) => validateInputValue(event.target.value)}
            placeholder={placeholder}
            render={(ref, props) => (
              <Box sx={{ pt: 1.5 }}>
                <LqdTextField
                  {...props}
                  inputProps={{
                    sx: {
                      color: inputValue.length > 0 ? "rgba(101, 110, 127, 1)" : "rgba(33, 36, 42, 1)",
                      fontSize: "16px",
                    },
                  }}
                  inputRef={ref}
                  placeholder={placeholder}
                  value={inputValue}
                />
              </Box>
            )}
          />
        );
      default:
        return (
          <Box sx={{ pt: 1.5 }}>
            <LqdTextField
              inputProps={{
                sx: { color: "rgba(101, 110, 127, 1)", fontSize: "16px", fontWeight: 400, lineHeight: "24px" },
              }}
              mask={mask}
              onChange={(event) => onChangeMethod(event.target.value)}
              placeholder={placeholder}
              value={inputValue}
            />
          </Box>
        );
    }
  };

  return (
    <Box sx={{ height: "110px", mr: "80px", width: "90%" }}>
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mr: 0.5, width: "fit-content" }} textstyle="p2Paragraph">
          {inputText}
          {required ? (
            <LqdTypography color="rgba(246, 61, 94, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
              *
            </LqdTypography>
          ) : null}
        </LqdTypography>
      </Box>
      {renderSimulatorInput()}
    </Box>
  );
}
