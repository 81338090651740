import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectOf } from "../common/types/ObjectOf";
import { getGroupProducts } from "./api/GetGroupProducts";
import { getTenantProducts } from "./api/GetTenantProducts";
import updateProduct from "./api/UpdateProduct";
import { Product } from "./types/Product";
import { formatProductToRaw, formatRawProduct } from "./utils/formatRawProduct";

export interface ProductState {
  filter: { disabled: boolean };
  groupProducts: ObjectOf<Product>;
  tenantProducts: ObjectOf<Product>;
}

const initialState: ProductState = {
  filter: { disabled: true }, // Provavelmente haverá outros tipos de filtro, por isso esse formato}
  groupProducts: {},
  tenantProducts: {},
};

export const onLoadGroupProducts = createAsyncThunk("products/onLoadGroupProducts", async () => {
  const productsRes = await getGroupProducts();
  const productsRaw = productsRes.data;
  const productWithTargetUsers = productsRaw.map((product) => ({ ...product, target_user: product.target_user || [] }));
  const products = productWithTargetUsers.map(formatRawProduct);

  return products;
});

export const onLoadTenantProducts = createAsyncThunk("products/onLoadTenantProducts", async () => {
  const productsRes = await getTenantProducts();
  const productsRaw = productsRes.data;
  const products = productsRaw.map(formatRawProduct);

  return products;
});

export const onUpdateProduct = createAsyncThunk("products/onUpdateProduct", async (product: Product) => {
  const productRaw = formatProductToRaw(product);

  let updatedProduct = { ...product };

  try {
    const updateProductRes = await updateProduct(product.id, productRaw);
    const updatedProductRaw = updateProductRes.data;
    updatedProduct = formatRawProduct(updatedProductRaw);
  } catch (error) {
    const errorData = error as AxiosError;
    const message = errorData.response?.data.message;

    throw message;
  }

  return updatedProduct;
});

const productsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(onLoadGroupProducts.fulfilled, (state, action) => {
        const products = action.payload;

        state.groupProducts = {};

        products.forEach((product) => {
          state.groupProducts[product.id] = product;
        });
      })
      .addCase(onLoadTenantProducts.fulfilled, (state, action) => {
        const products = action.payload;

        state.tenantProducts = {};

        products.forEach((product) => {
          state.tenantProducts[product.id] = product;
        });
      })
      .addCase(onUpdateProduct.fulfilled, (state, action) => {
        const updatedProduct = action.payload;

        if (state.tenantProducts[updatedProduct.id]) {
          state.tenantProducts[updatedProduct.id] = updatedProduct;
        }

        if (state.groupProducts[updatedProduct.id]) {
          state.groupProducts[updatedProduct.id] = updatedProduct;
        }
      });
  },
  initialState,
  name: "products",
  reducers: {
    productFilterToggled(state, action: PayloadAction<{ name: "disabled"; value: boolean }>) {
      const { name, value } = action.payload;
      state.filter[name] = value;
    },
  },
});

export const { productFilterToggled } = productsSlice.actions;

export default productsSlice.reducer;
