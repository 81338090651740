import { StyleSheet, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";
import { formatValueToCurrency } from "../../../../../../utils/analysisDetailsDataFormatter";
import PDFAllotmentAndBasicSimulatorInfoCard from "../PDFAllotmentAndBasicSimulatorInfoCard";

type PDFAllotmentSimulatorInfoContainerProps = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
});

export default function PDFAllotmentSimulatorInfoContainer(props: PDFAllotmentSimulatorInfoContainerProps) {
  const { highlight } = props;

  return (
    <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
      <PDFAllotmentAndBasicSimulatorInfoCard
        title="Valor da proposta"
        value={formatValueToCurrency(highlight.contract_value, "BRL", false)}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title="Entrada"
        value={formatValueToCurrency(highlight.down_payment, "BRL", false)}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title={`Financiamento\n pretendido`}
        value={formatValueToCurrency(highlight.sec_loan_amount, "BRL", false)}
      />
      <PDFAllotmentAndBasicSimulatorInfoCard
        title={`Composição das\n rendas`}
        value={formatValueToCurrency(highlight.monthly_income, "BRL", false)}
      />
    </View>
  );
}
