import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterSix() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        6. TIPOS DE DADOS E FORMA DE TRATAMENTO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.1. Através da Plataforma ASK Liquid, é possível o Tratamento de quaisquer categorias de Dados Pessoais e Dados
        Pessoais Sensíveis, observadas as responsabilidades estabelecidas no item 11.2.1. desta Política.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.2. Para viabilizar as suas Soluções, a Liquid poderá realizar diversas formas de Tratamento de Dados, dentre
        as quais destacamos, sem limitar: acesso, coleta, estruturação, análise, avaliação, classificação,
        processamento, arquivamento, armazenamento, compartilhamento, disponibilização e comunicação.
      </LqdTypography>
    </>
  );
}
