import { LqdIconProps } from "../../types/IconProps";

export default function LqdClockIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.55697 3.68702C13.148 1.78502 18.411 3.96602 20.313 8.55702C22.215 13.148 20.034 18.411 15.443 20.313C10.852 22.215 5.58897 20.034 3.68697 15.443C1.78597 10.852 3.96597 5.58902 8.55697 3.68702"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.718 7.98499V12.636L15.374 14.865"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
