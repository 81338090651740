import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterFive() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        5. CONDIÇÕES ESPECÍFICAS DA CONTRATAÇÃO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        5.1. Eventuais condições específicas para a contratação do Liquid constam na Proposta Comercial (como, por
        exemplo, prazo de vigência, preço a ser praticado, data e periodicidade dos pagamentos), as quais deverão ser,
        sempre que não conflitarem com estes Termos, interpretadas como parte dos Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        5.1.1. Em caso de conflito entre a Proposta Comercial e os Termos, prevalecerá sempre o disposto nestes Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        5.2. Aplicam-se à relação com os Usuários, além destes Termos e Condições de Uso e quando aplicáveis, as
        licenças de uso específicas da Liquid, inclusive para setup e treinamento.
      </LqdTypography>
    </>
  );
}
