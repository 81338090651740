import { ObjectOf } from "@common/types/ObjectOf";

export const operatorMapping: ObjectOf<string> = {
  diferente: "!=",
  e: "&&",
  igual: "==",
  maior: ">",
  maior_ou_igual: ">=",
  menor: "<",
  menor_ou_igual: "<=",
  ou: "||",
};
