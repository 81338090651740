import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { UserResponseRaw } from "../types/UserResponseRaw";

// List Users By Group List (Superadmin Endpoint)
export const adminListUsersByGroups = (
  groupIdList: Array<string>,
  tenantCode: string
): AxiosPromise<Array<UserResponseRaw>> => {
  return get(`/admin/user/group?group_id_list=${groupIdList}&tenant_code=${tenantCode}`);
};
