export enum TrustpadView {
  AGREEMENT_CHANNEL_EMAIL = "agreementEmail",
  AGREEMENT_CHANNEL_PHONE = "agreementPhone",
  AGREEMENT_PERMISSION = "agreementPermission",
  EDIT_ANALYSIS_USER_DOCUMENT = "editAnalysisUserDocument",
  PENDENCIES_LIST = "pendenciesList",
  SHOW_ANALYSIS_USERS_LIST = "showAnalysisUsersList",
  SOLVE_BANK_RATES_PENDENCIES = "solveBankRatesPendencies",
  SOLVE_BOOLEAN_PENDENCIES = "solveBooleanPendencies",
  SOLVE_CURRENCY_PENDENCIES = "solveCurrencyPendencies",
  SOLVE_DATE_PENDENCIES = "solveDatePendencies",
  SOLVE_INTEGER_PENDENCIES = "solveIntegerPendencies",
  SOLVE_PERCENTAGE_PENDENCIES = "solvePercentagePendencies",
  STEPS = "steps",
  UPLOAD_SIGNATURE_PENDENCIES = "uploadSignaturePendencies",
}
