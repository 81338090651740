export enum AdminPagePanels {
  ADMIN = "admin",
  ANALYSIS_DELETION = "analysisDeletion",
  GROUP_DELETION = "groupDeletion",
  PRODUCT_DELETION = "productDeletion",
  PROJECT_CONCLUSION_DATE = "projectConclusionDate",
  SIMULATOR_CONFIGURATOR = "simulatorBuilder",
  SIMULATOR_PARAMETRIZATION = "simulatorParameters",
  TEMPLATE_CONFIG = "templateConfig",
  TENANT_CONFIG = "tenantConfig",
  TENANT_MANAGEMENT = "tenantManagement",
  USER_DELETION = "userDeletion",
}
