import { LqdButton, LqdIconButton, LqdInput, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { SimulatorFunctionBlocksFormViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksFormViews";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../store";

type SimulatorFunctionBlocksFormNewVariableModalNameProps = {
  handleDeleteVariable: () => void;
  handleGoBack: () => void;
  setCurrentView: (view: SimulatorFunctionBlocksFormViews) => void;
  setVariableLabel: Dispatch<SetStateAction<string>>;
  variableLabel: string;
  variableName: string;
};

const VARIABLE_LABEL_MIN_LENGTH = 1;
const VARIABLE_LABEL_MAX_LENGTH = 35;

export default function SimulatorFunctionBlocksFormNewVariableModalLabel(
  props: SimulatorFunctionBlocksFormNewVariableModalNameProps
) {
  const { handleDeleteVariable, handleGoBack, setCurrentView, setVariableLabel, variableLabel } = props;

  const { selectedVariable, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const variables = simulatorData?.variables;

  const [labelAlreadyExists, setLabelAlreadyExists] = useState(false);

  useEffect(() => {
    if (!variableLabel && selectedVariable?.label) {
      setVariableLabel(selectedVariable.label);
    }
    if (variables) {
      const exists = variables.some(
        (variable) => variable.label === variableLabel && variable.label !== selectedVariable.label
      );
      setLabelAlreadyExists(exists);
    }
  }, [selectedVariable, variableLabel, variables]);

  const variableLabelTooShort = variableLabel.length < VARIABLE_LABEL_MIN_LENGTH;
  const variableLabelTooBig = variableLabel.length > VARIABLE_LABEL_MAX_LENGTH;

  const enableSaveButton = !variableLabelTooShort && !variableLabelTooBig && !labelAlreadyExists;

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2.5 }} textstyle="h5Headline">
          Qual nome será exibido ao usuário?
        </LqdTypography>
        {selectedVariable.id ? (
          <LqdIconButton buttonsize="small" onClick={handleDeleteVariable} type="ghostIcon">
            <LqdTrashIcon />
          </LqdIconButton>
        ) : null}
      </Box>
      <LqdInput
        alertMessage={
          labelAlreadyExists ? "Já existe uma variável com este nome. Por favor, escolha um nome diferente." : ""
        }
        characterLimit={VARIABLE_LABEL_MAX_LENGTH}
        onConfirm={() => setCurrentView("variableType")}
        onConfirmValidation={enableSaveButton}
        placeholderText="Digite o nome de exibição aqui"
        setValue={setVariableLabel}
        showCharacterLimit
        value={variableLabel}
      />
      <Box sx={{ float: "right", mt: 3 }}>
        <LqdButton onClick={handleGoBack} sx={{ mr: 3 }} type="outlineTertiary">
          Voltar
        </LqdButton>
        <LqdButton disabled={!enableSaveButton} onClick={() => setCurrentView("variableValue")} sx={{ float: "right" }}>
          Salvar
        </LqdButton>
      </Box>
    </>
  );
}
