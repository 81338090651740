import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

type LqdFilledBoxProps = {
  children: ReactNode;
  sx?: SxProps;
};

export default function LqdFilledBox(props: LqdFilledBoxProps) {
  const { children, sx } = props;

  return <Box sx={{ backgroundColor: "rgba(239, 241, 249, 1)", ...sx }}>{children}</Box>;
}
