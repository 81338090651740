enum Operator {
  "!=" = "Different than",
  "<" = "Less than",
  "<=" = "Less or equal to",
  "==" = "Equal to",
  ">" = "Greater than",
  ">=" = "Greater or equal to",
  "Different than" = "!=",
  "Equal to" = "==",
  "Greater or equal to" = ">=",
  "Greater than" = ">",
  "in" = "Is in",
  "Is in" = "in",
  "Less or equal to" = "<=",
  "Less than" = "<",
}

export default Operator;
