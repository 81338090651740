import { Box, Stack } from "@mui/material";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";

export default function SimulatorFunctionBlocksFunctionConditionalInputLayout(props: LayoutProps) {
  const {
    currentBlock,
    editVariableButton,
    func,
    newBlockButton,
    newVariableButton,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    variableInputs,
  } = props;

  return (
    <Box>
      <Stack spacing={1} sx={{ ml: 5, mt: 1 }}>
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={false}
          key="a"
          newOption={variableInputs["a"].select === "variables" ? newVariableButton : newBlockButton}
          onChange={(value) => onUpdateFunction("a", value as string)}
          options={renderOptions[variableInputs["a"].select]}
          value={func.variables["a"]}
          variableData={{ key: "a", variable: variableInputs["a"] }}
        />
        <Stack direction="row" spacing={2} sx={{ display: "flex", width: "100%" }}>
          {Object.entries(variableInputs).map(([key, variable]) => {
            if (key === "a") return null;

            return (
              <Box key={key} sx={{ width: "100%" }}>
                <SimulatorFunctionBlocksFunctionVariableInput
                  currentBlock={currentBlock}
                  editVariable={editVariableButton}
                  func={func}
                  isOpen={openSelectComponent}
                  isResult={false}
                  key={key}
                  newOption={variable.select === "variables" ? newVariableButton : newBlockButton}
                  onChange={(value) =>
                    onUpdateFunction(key, func.function_name.includes("jump") ? Number(value) : (value as string))
                  }
                  options={renderOptions[variable.select]}
                  value={func.variables[key]}
                  variableData={{ key, variable }}
                />
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}
