import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterSeventeen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        17. DISPOSIÇÕES GERAIS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.1. Conformidade com as Leis de Combate à Corrupção. As Partes se obrigam, por si e por seu Pessoal, a cumprir
        todas as leis e regulamentos de combate à corrupção e à lavagem de dinheiro.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.2. Integralidade. Os Termos e a Proposta Comercial vinculam as Partes, seus sócios, prepostos, herdeiros e
        sucessores a qualquer título. Esses Termos superam todas as discussões, acordos e entendimentos anteriores,
        escritos e verbais, entre as Partes que forem pertinentes ao Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.3. Boa-fé. As Partes declaram-se agindo de boa-fé, com lealdade e probidade, plenamente capazes civilmente,
        não existindo contra elas quaisquer pendências que implicariam impedimento para levar a bom termo esta
        negociação e para tê-la como válida e eficaz, responsabilizando-se, civil e criminalmente pelas declarações.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.4. Independência das Cláusulas. Toda disposição contida nos presentes Termos deverá ser interpretada de tal
        forma a permitir sua validade e eficácia nos termos da Lei aplicável. Caso qualquer disposição seja declarada
        inválida, nula, anulável ou ineficaz nos termos da Lei aplicável, tal disposição será considerada inválida,
        nula, anulável ou ineficaz na exata medida de sua proibição ou invalidade, sem que os termos remanescentes de
        tal disposição ou o restante dos Termos seja afetado.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.5. Independência das Partes. As Partes declaram e reconhecem que a celebração dos presentes Termos não
        implica o estabelecimento de qualquer vínculo de natureza trabalhista, societária e/ou de dependência econômica
        entre si. A Liquid não responderá por compromissos de qualquer espécie que o Usuário venha a assumir perante
        seus parceiros comerciais, fornecedores ou demais terceiros em razão dos Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.6. Perdas e Danos. As Partes responderão, individualmente, pelas perdas e danos causados à outra Parte,
        obrigando-se ainda a reembolsar a parte inocente por toda e qualquer despesa que venha a onerá-la, bem como
        indenizá-la, por todo e qualquer gasto ou perda resultante de ações ou procedimentos judiciais ou
        extrajudiciais, que venha a parte inocente a sofrer, incluindo honorários advocatícios.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.7. Cessão. Nenhuma das Partes poderá ceder quaisquer de seus direitos ou delegar quaisquer de suas obrigações
        sob a vigência dos Termos sem obter, primeiramente, a anuência por escrito da outra Parte. Fica ressalvada a
        cessão da Liquid a empresas de seu grupo econômico, que poderá ocorrer mediante simples envio de notificação ao
        Usuário.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.8. Obrigações Pós-Contratuais. Os efeitos das obrigações e responsabilidade constituídas durante a vigência
        destes Termos, não serão prejudicadas pelo término ou rescisão da relação contratual, que responderão pelo prazo
        disposto nestes Termos, ou pelo prazo prescricional ou decadencial disposto na Lei aplicável.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.9. Alterações; Renúncias. A tolerância de qualquer das Partes em relação à ausência de cumprimento das
        presentes disposições não será interpretada como uma renúncia à exigência de cumprimento futuro dos Termos ou da
        Proposta Comercial. Qualquer renúncia à efetividade de qualquer disposição destes Termos será considerada válida
        apenas se realizada por escrito e assinada pelas Partes. Os direitos e obrigações previstas nestes Termos são
        cumulativos e não excludentes entre si e em relação àqueles previstos em Lei.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.10. Caso Fortuito e Força Maior. Nenhuma das Partes será responsável perante a outra pelos prejuízos
        resultantes da inobservância total ou parcial do presente Contrato se esta decorrer de caso fortuito ou força
        maior na forma prevista no artigo 393 do Código Civil, ou ainda por atos governamentais que impeçam, restrinjam
        ou dificultem o cumprimento do presente Contrato, devendo neste caso, a Parte atingida comunicar o fato
        imediatamente à outra Parte, informando por escrito a ocorrência e a natureza do evento e descrevendo os efeitos
        danosos causados.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.11. Notificações. Todas as notificações e outras comunicações entre as Partes deverão ser feitas por escrito,
        para os endereços e/ou e-mails constantes na Proposta Comercial e/ou nestes Termos e/ou informados pelas Partes
        de tempos em tempos, com a respectiva comprovação de recebimento (ou envio correto, conforme o caso) para que
        surtam os devidos efeitos. Em caso de alteração no endereço de qualquer das Partes, tal alteração deverá ser
        notificada à outra Parte, sendo consideradas válidas e efetivas as comunicações, notificações e citações
        encaminhadas ao endereço anterior até 5 (cinco) dias após o recebimento da notificação comunicando tal
        alteração.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.12. Lei Aplicável. Os Termos e a Proposta Comercial, para todos os fins de direito, serão interpretados de
        acordo com as disposições da legislação brasileira.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        17.13. Foro. As Partes neste ato elegem o Foro da Comarca de São Paulo, estado de São Paulo, com renúncia
        expressa a qualquer outro, por mais privilegiado que seja, para dirimir quaisquer dúvidas ou conflitos
        decorrentes dos Termos, salvo se de modo diverso exigido por lei.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        São Paulo, 18 de março de 2024.
      </LqdTypography>
    </>
  );
}
