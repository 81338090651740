import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterNine() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        9. RETRIBUIÇÃO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        9.1. Pela utilização do Liquid, o Usuário Decisor pagará à Liquid os valores previstos na Proposta Comercial ou
        na própria plataforma do Liquid (“Retribuição”), na periodicidade e forma ali indicadas.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        9.2. Cada uma das Partes será responsável pelo cumprimento de todas e quaisquer obrigações tributárias
        resultantes da execução do objeto destes Termos, inclusive quanto às eventuais obrigações de retenção de
        tributos pela fonte pagadora, nos termos da legislação em vigor.
      </LqdTypography>
    </>
  );
}
