import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";

type PDFAllotmentSimulatorTableRow2Props = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  allotmentTableApprove: {
    alignItems: "center",
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    marginRight: "20px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  allotmentTableColumn: {
    width: "285px",
  },
  allotmentTableReprove: {
    alignItems: "center",
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    marginRight: "20px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  allotmentTableRow2Container: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    width: "100%",
  },
  allotmentTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "18px",
    textAlign: "left",
  },
  allotmentTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
    textAlign: "left",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function PDFAllotmentSimulatorTableRow2(props: PDFAllotmentSimulatorTableRow2Props) {
  const { highlight } = props;

  const renderLtvApproval = () => {
    switch (highlight.ltv_approval) {
      case true:
        return <Text style={styles.allotmentTableApprove}>LTV enquadrado</Text>;
      case false:
        return <Text style={styles.allotmentTableReprove}>LTV desenquadrado</Text>;
      default:
        return null;
    }
  };

  const renderIncomeApproval = () => {
    switch (highlight.income_approval) {
      case true:
        return <Text style={styles.allotmentTableApprove}>Renda suficiente</Text>;
      case false:
        return <Text style={styles.allotmentTableReprove}>Renda insuficiente</Text>;
      default:
        return null;
    }
  };

  return (
    <View style={styles.allotmentTableRow2Container}>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Prazo de financiamento pretendido</Text>
          <Text style={styles.allotmentTableRowText}>{`${highlight.desired_payment_term} meses`}</Text>
        </View>
      </View>
      {renderLtvApproval()}
      {renderIncomeApproval()}
    </View>
  );
}
