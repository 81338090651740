import { LqdNodeCard } from "@/liquid-components/src";
import { CardHeader, Skeleton } from "@mui/material";

export default function NodeCardSkeleton() {
  return (
    <LqdNodeCard
      sx={{
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        mb: 2,
      }}
    >
      <CardHeader
        avatar={<Skeleton height={48} variant="circular" width={48} />}
        subheader={<Skeleton variant="text" />}
        title={<Skeleton variant="text" />}
      />
    </LqdNodeCard>
  );
}
