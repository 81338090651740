import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import LiquidLogo from "../../../common/components/LiquidLogo";
import SignupConcludedImage from "../../assets/SignupConcludedImage";

type SignUpConcludedProps = {
  email: string;
  show: boolean;
};

export default function SignUpConcluded(props: SignUpConcludedProps) {
  const { email, show } = props;

  const navigate = useNavigate();

  return (
    <FullscreenDialog action="create" currentStep={1} hideHeader onClose={() => {}} open={show} title="">
      <Grid container sx={{ overflowY: "hidden" }}>
        <Grid
          item
          sx={{
            backdropFilter: "blur(10px)",
            background: "rgba(255, 255, 255, 0.6)",
            backgroundBlendMode: "overlay",
            display: "flex",
            height: "96px",
            justifyContent: "space-between",
            left: 0,
            opacity: "0,7",
            position: "absolute",
            pt: { md: 0, xs: 2 },
            px: { md: 5, xs: 3 },
            top: 0,
            width: "100%",
            zIndex: 1,
          }}
          xs={12}
        >
          <Grid
            item
            md={8}
            sx={{ alignItems: "center", display: "flex", justifyContent: { md: "flex-start", xs: "space-between" } }}
            xs={12}
          >
            <Box sx={{ display: "flex", ml: { md: 0, xs: 4 } }}>
              <LiquidLogo />
            </Box>
          </Grid>
        </Grid>
        <Grid item sx={{ height: "100%", mt: "96px" }} xs={12}>
          <Grid item sx={{ alignItems: "center", display: "flex", justifyContent: "center" }} xs={12}>
            <Box
              sx={{
                alignItems: "center",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                pt: 5,
              }}
            >
              <LqdTypography textstyle="h3Headline">Concluído!</LqdTypography>
              <LqdTypography sx={{ boxSizing: "border-box", maxWidth: "390px", pt: 1 }} textstyle="p2Paragraph">
                Assim que seu cadastro for aprovado, você receberá um email de confirmação em {email}
              </LqdTypography>
              <LqdButton onClick={() => navigate("/signin")} sx={{ mb: 15, mt: 5 }}>
                Ok, entendi
              </LqdButton>
              <SignupConcludedImage />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </FullscreenDialog>
  );
}
