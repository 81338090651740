import { PeerData } from "../types/AnalysisDetails/PeerData/PeerData";
import { AnalysisSimulation } from "../types/Simulation/AnalysisSimulation";
import { BuilderSimulation } from "../types/Simulation/Builder/BuilderSimulation";

export const retrieveDashboardOptions = (analysisPeers: Array<PeerData>, simulations: Array<AnalysisSimulation>) => {
  const peerOptions = analysisPeers.map((peer) => ({ label: peer.fullName, type: peer.document }));

  const simulatorOptions = simulations
    .filter((simulation) => simulation.simulation_type)
    .filter(
      (simulation) =>
        Object.values(simulation.simulation).length > 0 &&
        (!("description" in simulation.simulation) || simulation.simulation.description)
    )
    .map((simulation) => ({
      label:
        simulation.simulation_type === "simulator"
          ? (simulation.simulation as BuilderSimulation).name
          : simulation.simulation_type,
      type: simulation.simulation_type,
    }));

  const analysisDashboardOptions = [...simulatorOptions, ...peerOptions];

  return { analysisDashboardOptions, peerOptions, simulatorOptions };
};
