import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import FrontPageLayout from "../../common/components/FrontPageLayout";
import LiquidLogo from "../../common/components/LiquidLogo";
import signInImg from "../assets/signin-default.png";
import SignInForm from "../components/SignInForm";

const contentSizes = { md: "85%", sm: "95%", xs: "90%" };
const contentSizesMaxWidth = { xl: "575px", xs: "525px" };

export default function SignInRoute() {
  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const sessionExpired: string | null = params.get("session_expired");

  return (
    <FrontPageLayout
      leftContent={
        <Stack gap={3} sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}>
          <Box className="lqd-image-wrapper" sx={{ maxWidth: contentSizesMaxWidth, width: contentSizes }}>
            <img
              alt={`Tela inicial da Liquid. Na imagem, uma mulher, quase de perfil, olhando para frente e sorrindo.
              Alguns gráficos e dados estão sobrepostos à imagem.`}
              src={signInImg}
              style={{ borderRadius: "20px", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: contentSizesMaxWidth,
              width: contentSizes,
            }}
          >
            <LiquidLogo />
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1 }} textstyle="h5Headline">
              Aumentando seu sucesso com análises inteligentes e dinâmicas.
            </LqdTypography>
            <LqdButton href="/signin#begin" sx={{ display: { sm: "none", xs: "flex" }, mt: 4 }}>
              Começar
            </LqdButton>
          </Box>
        </Stack>
      }
      rightContent={<SignInForm sessionExpired={!!sessionExpired} />}
    />
  );
}
