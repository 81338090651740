import { LqdLoadingIcon, LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import SignatureHeader from "./SignatureHeader";

export default function SignatureLoadingScreen() {
  return (
    <Stack direction="column" sx={{ height: "100vh", width: "100%" }}>
      <SignatureHeader />
      <Stack alignItems="center" direction="row" justifyContent="center" sx={{ height: "100%", width: "100%" }}>
        <LqdLoadingIcon size={42} />
        <Stack direction="column">
          <LqdTypography>Agora é só esperar!</LqdTypography>
          <LqdTypography>Aguarde alguns instantes...</LqdTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}
