import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import VideoAskEmbedFooter from "./VideoAskEmbedFooter";
import VideoAskEmbedHeader from "./VideoAskEmbedHeader";

export default function VideoAskEmbedNoData() {
  const { width } = useWindowSize();

  return (
    <>
      <VideoAskEmbedHeader />
      <Box
        sx={{
          alignItems: "center",
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: width === "xs" ? "475px" : "575px",
          justifyContent: "center",
          my: 7.5,
          textAlign: "center",
          width: "100%",
        }}
      >
        <LqdTypography textstyle="h1Headline">Ops!</LqdTypography>
        <LqdTypography textstyle="h4Headline">
          Parece que você está tentando enviar um documento que não está mais disponível.
        </LqdTypography>
        <LqdTypography textstyle="p1Paragraph">
          Para prosseguir com o envio de documentos, solicite um link atualizado.
        </LqdTypography>
      </Box>
      <VideoAskEmbedFooter />
    </>
  );
}
