import { LqdIconProps } from "../../types/IconProps";

export default function LqdRepeatThreeIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.99664C7.96456 3.99726 4.56082 7.00213 4.05982 11.0064C3.55881 15.0106 6.11729 18.7614 10.0282 19.7563C13.9391 20.7511 17.9789 18.6787 19.4521 14.9218C20.9254 11.1649 19.3712 6.89888 15.8266 4.97005"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.8265 8.04632V4.50885H19.364"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
