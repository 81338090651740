import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Stack } from "@mui/material";
import { simulatorTesterSearchChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionTemplate } from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { SimulatorVariableTypeTemplate } from "@simulatorBuilder/types/SimulatorVariableTypeTemplate";
import { handleSimulatorTesterSearchChanged } from "@simulatorBuilder/utils/simulatorTesterSearchChanged";
import { Dispatch } from "react";
import { simulatorTesterLeftSideBarVariablesBox } from "./simulatorTesterLeftSideBarVariablesBox";

type SimulatorTesterHighlightVariablesProps = {
  dispatch: Dispatch<unknown>;
  emptyVariablesBox: JSX.Element;
  functionTemplates: Array<SimulatorFunctionTemplate>;
  inputSearchData: SimulatorTesterSearchData;
  simulator: Simulator;
  variableCategory: string;
  variableTypes: Array<SimulatorVariableTypeTemplate>;
};

/**
 * Componente para destacar variáveis na barra lateral esquerda do Tester.
 *
 * @param {SimulatorTesterHighlightVariablesProps} props - As propriedades do componente.
 */
export const simulatorTesterHighlightVariables = (props: SimulatorTesterHighlightVariablesProps) => {
  const {
    dispatch,
    emptyVariablesBox,
    functionTemplates,
    inputSearchData,
    simulator,
    variableCategory,
    variableTypes,
  } = props;

  const filteredVariables = simulator.variables.filter((vari) => vari.category === variableCategory);
  const searchedFilteredVariables = inputSearchData.variables.filter((vari) => vari.category === variableCategory);

  const handleVariableClick = (variable: SimulatorVariable) => () => {
    const response = handleSimulatorTesterSearchChanged({
      formattedInputText: variable.name,
      functionTemplates,
      inputText: variable.label,
      simulator,
      type: "click",
    });

    if (!response.blocks.length) {
      const notification: ToastNotification = {
        customTitle: "Variável sem uso",
        message: "Parece que nenhum bloco utiliza esta variável.",
        type: "warning",
      };

      dispatch(toastCalled(notification));
    }

    dispatch(
      simulatorTesterSearchChanged({
        blocks: response.blocks,
        functions: response.functions,
        searchedText: response.searchedText,
        type: "click",
        variables: response.variables,
      })
    );
  };

  if (!inputSearchData || !inputSearchData.searchedText) {
    if (filteredVariables.length) {
      return filteredVariables.map((variable, i) => (
        <Stack
          key={i}
          onClick={handleVariableClick(variable)}
          sx={{
            background: "rgba(249, 249, 250, 1)",
            borderRadius: 1.5,
            mt: 1.5,
            p: 1.5,
          }}
        >
          {simulatorTesterLeftSideBarVariablesBox({ inputSearchData, variable, variableTypes })}
        </Stack>
      ));
    } else {
      return emptyVariablesBox;
    }
  }

  if (inputSearchData.type === "text" && inputSearchData.searchedText) {
    if (searchedFilteredVariables.length) {
      return searchedFilteredVariables.map((variable, i) => (
        <Stack
          key={i}
          onClick={handleVariableClick(variable)}
          sx={{
            background: "rgba(249, 249, 250, 1)",
            borderRadius: 1.5,
            mt: 1.5,
            p: 1.5,
          }}
        >
          {simulatorTesterLeftSideBarVariablesBox({ inputSearchData, variable, variableTypes })}
        </Stack>
      ));
    } else {
      return emptyVariablesBox;
    }
  }

  if (inputSearchData.type === "click") {
    if (filteredVariables.length) {
      return filteredVariables.map((variable, i) => (
        <Stack
          key={i}
          onClick={handleVariableClick(variable)}
          sx={{
            background:
              inputSearchData.searchedText === variable.label ? "rgba(236, 238, 240, 1)" : "rgba(249, 249, 250, 1)",
            border: inputSearchData.searchedText === variable.label ? "1px solid rgba(191, 196, 205, 1)" : "",
            borderRadius: 1.5,
            boxShadow:
              inputSearchData.searchedText === variable.label ? "0px 20px 40px -8px rgba(16, 24, 40, 0.10)" : "",
            mt: 1.5,
            p: 1.5,
          }}
        >
          {simulatorTesterLeftSideBarVariablesBox({ inputSearchData, variable, variableTypes })}
        </Stack>
      ));
    } else {
      return emptyVariablesBox;
    }
  }

  return emptyVariablesBox;
};
