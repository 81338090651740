import { LqdIconProps } from "../../types/IconProps";

export default function LqdFunctionTwoIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99988 18.2719C6.47274 18.7378 7.11392 18.9996 7.78255 18.9999V18.9999C9.03487 19.0003 10.0977 18.0947 10.2783 16.8733L11.7215 7.12648C11.9021 5.9051 12.9649 4.99949 14.2172 4.99988V4.99988C14.8858 5.00012 15.527 5.26196 15.9999 5.72788"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.9999 11.4999H6.99988"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.5 19H16H17.25V14L16 15.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
