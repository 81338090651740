import { LqdCalendarIcon, LqdTextField, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

type BuildingDateInputProps = {
  inputValue: string;
  mask?: string;
  onChangeMethod: (value: string) => void;
  placeholder?: string;
};

export default function BuildingDateInput(props: BuildingDateInputProps) {
  const { inputValue, mask, onChangeMethod, placeholder } = props;

  return (
    <Box sx={{ height: "110px", mt: 1, width: "400px" }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1, mt: 3 }} textstyle="p2Paragraph">
        Data de conclusão da obra
        <LqdTypography color="rgba(246, 61, 94, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
          *
        </LqdTypography>
      </LqdTypography>
      <LqdTextField
        inputProps={{
          sx: {
            color: "rgba(155, 162, 175, 1)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            ml: 1,
            mt: 0.5,
          },
        }}
        mask={mask}
        onChange={(event) => onChangeMethod(event.target.value)}
        placeholder={placeholder}
        startAdornment={<LqdCalendarIcon color="rgba(127, 135, 152, 1)" size={20} />}
        value={inputValue}
      />
    </Box>
  );
}
