import { post } from "../../common/utils/apiUtils";

/**
 * Endpoint que valida o documento para o SCR (Open Credit).
 * @param document Documento a ser validado.
 * @param passId PassId do usuário.
 * @returns Indica se o documento é válido.
 * @remarks Endpoint PÚBLICO.
 */
export const validateOpenCreditDocument = (document: string, passId: string) => {
  return post<{ valid: boolean }>("/open-credit/agreement/document/validation", { document, pass_id: passId });
};
