import { LqdIconProps } from "../../types/IconProps";

export default function LqdCheckboxOffIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 18 18" width={size} xmlns="http://www.w3.org/2000/svg">
      <rect height="16.8" rx="1.4" stroke={color} strokeWidth="1.2" width="16.8" x="0.6" y="0.6" />
    </svg>
  );
}
