import {
  LqdButton,
  LqdCalendarIcon,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdTextField,
  LqdToggle,
  LqdTypography,
} from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { isValidEndDate } from "../../../analysisDetails/utils/analysisDetailsDataFormatter";
import {
  dialogCalled,
  dialogDateFieldChanged,
  dialogErrorMessageChanged,
  setBlank,
  toggleOption,
} from "../../commonSlice";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";

/** Componente de SmallDialog para usos em que é necessário confirmar uma ação, através de um campo de texto.
 * @param actions Lista de ações do Dialog.
 * @param body Texto principal do Dialog.
 * @param bodySecundary Texto secundário do Dialog.
 * @param confirmationString String utilizada no .test() do Regex, para confirmar a ação.
 * @param specialValidation Função de validação do input, nos casos em que não se pode utilizar o Regex (como na recuperação de senha).
 * @param title Título do Dialog.
 */
export default function SmallDialogDate() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { dialogContent, dialogDateFieldEnd, dialogDateFieldStart, dialogLoading, dialogShowState } = useAppSelector(
    (state) => state.common
  );

  const formatDate = (date: string): string => {
    const updatedDate = date.slice(0, 2) + "/" + date.slice(2, 4) + "/" + date.slice(4, 8);
    return updatedDate;
  };

  const shouldRenderToggle = !dialogContent.fixedRange || !dialogContent.blank;
  const formattedInitialDate = formatDate(dialogDateFieldStart);
  const formattedEndDate = formatDate(dialogDateFieldEnd);

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const handleConfirmClick = (action: SmallDialogAction, actionIndex: number) => {
    const { fixedRange, selectedOption } = dialogContent;
    if (fixedRange && action.onClick) {
      action.onClick(selectedOption);
    } else if (action.onClick) {
      action.onClick(formattedInitialDate, formattedEndDate, selectedOption);
    }
    if (actionIndex === 0) {
      dispatch(dialogCalled(null));
    }
  };
  const validateDateInput = !isValidEndDate(formattedInitialDate, formattedEndDate);
  const filledInput = dialogDateFieldStart.length === 8 && dialogDateFieldEnd.length === 8;
  const hasError = filledInput ? validateDateInput : false;

  const shouldDisableButton = (index: number) => {
    if (dialogContent.fixedRange) {
      return dialogLoading || dialogContent.blank;
    }
    return index > 0 && (dialogLoading || validateDateInput);
  };

  const handleToggle = (currentOption: number) => {
    if (shouldRenderToggle) {
      dispatch(toggleOption(dialogContent.selectedOption === 1 ? 2 : 1));
      return;
    }

    dispatch(setBlank(false));
    dispatch(toggleOption(currentOption === 1 ? 1 : 2));
  };

  return (
    <LqdDialog
      onClose={() => {
        handleConfirmClick(dialogContent.actions[0], 0), dispatch(setBlank(true));
      }}
      open={dialogShowState}
    >
      <SmallDialogHeader body={dialogContent.body} title={dialogContent.title} />
      {dialogContent.bodySecundary ? (
        <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ my: 1.5 }} textstyle="c1Caption">
          {dialogContent.bodySecundary}
        </LqdTypography>
      ) : null}
      {dialogContent.itemList ? (
        <Box sx={{ alignItems: "center", display: "flex", gap: 2, paddingBottom: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              bgcolor: "rgba(249, 249, 250, 1)",
              borderRadius: "99px",
              display: "flex",
              gap: 1,
              padding: 2,
            }}
          >
            <LqdToggle
              active={!!(shouldRenderToggle && dialogContent.selectedOption === 1)}
              onChange={() => handleToggle(1)}
            />
            <LqdTypography>{dialogContent?.itemList[0].labelOne}</LqdTypography>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              bgcolor: "rgba(249, 249, 250, 1)",
              borderRadius: "99px",
              display: "flex",
              gap: 1,
              padding: 2,
            }}
          >
            <LqdToggle
              active={!!(shouldRenderToggle && dialogContent.selectedOption === 2)}
              onChange={() => {
                handleToggle(2);
              }}
            ></LqdToggle>
            <LqdTypography>{dialogContent?.itemList[0].labelTwo}</LqdTypography>
          </Box>
        </Box>
      ) : null}
      {!dialogContent.fixedRange ? (
        <>
          <Box sx={{ display: "flex", mt: dialogContent.bodySecundary ? 0 : 0.5 }}>
            <Box sx={{ display: "flex", flexDirection: "column", mr: 4.5, width: "242px" }}>
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="c1Caption">
                {t("De")}
              </LqdTypography>
              <Box sx={{ alignItems: "center", display: "flex", height: "56px", mt: 1.5 }}>
                <LqdTextField
                  error={hasError}
                  mask="00/00/0000"
                  onChange={(event) => {
                    dispatch(dialogDateFieldChanged({ type: "start", value: event.target.value }));
                    dispatch(dialogErrorMessageChanged(""));
                  }}
                  placeholder="Ex: 01/01/2024"
                  startAdornment={
                    <Box sx={{ mr: 1.5, mt: 0.2 }}>
                      <LqdCalendarIcon color="rgba(127, 135, 152, 1)" size={20} />
                    </Box>
                  }
                  value={dialogDateFieldStart === "" ? dialogDateFieldStart : formattedInitialDate}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: "242px" }}>
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="c1Caption">
                {t("Até")}
              </LqdTypography>
              <Box sx={{ alignItems: "center", display: "flex", height: "56px", mt: 1.5 }}>
                <LqdTextField
                  error={hasError}
                  mask="00/00/0000"
                  onChange={(event) => {
                    dispatch(dialogDateFieldChanged({ type: "end", value: event.target.value }));
                    dispatch(dialogErrorMessageChanged(""));
                  }}
                  placeholder="Ex: 15/01/2024"
                  startAdornment={
                    <Box sx={{ mr: 1.5, mt: 0.2 }}>
                      <LqdCalendarIcon color="rgba(127, 135, 152, 1)" size={20} />
                    </Box>
                  }
                  value={dialogDateFieldEnd === "" ? dialogDateFieldEnd : formattedEndDate}
                />
              </Box>
            </Box>
          </Box>
          {hasError ? (
            <LqdTypography color="rgba(177, 30, 84, 1)" sx={{ mt: 1 }} textstyle="c1Caption">
              A data escolhida é inválida. Por favor, selecione um intervalo de período válido.
            </LqdTypography>
          ) : null}
        </>
      ) : null}
      <LqdDialogActions sx={{ mt: 2.5 }}>
        {dialogContent.actions.map((action, index, actions) => {
          return (
            <LqdButton
              disabled={shouldDisableButton(index)}
              key={action.title}
              onClick={() => handleConfirmClick(action, index)}
              type={getButtonType(index, actions)}
            >
              {dialogLoading && actions.length > 1 && index !== 0 ? (
                <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
              ) : (
                action.title
              )}
            </LqdButton>
          );
        })}
      </LqdDialogActions>
    </LqdDialog>
  );
}
