import { LqdDownArrowIcon } from "@/liquid-components/src";
import { Stack } from "@mui/material";

export default function AutomationStepArrow() {
  return (
    <Stack alignItems="center" sx={{ height: "28px" }}>
      <LqdDownArrowIcon />
    </Stack>
  );
}
