import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassProviderData } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassProviderData";
import PDFGeneralInfoCard from "../PDFGeneralinfoCard";

type PDFLiquidpassAssistancesContainerProps = {
  provider: LiquidPassProviderData;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  generalInfoText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
});

export default function PDFLiquidpassAssistancesContainer(props: PDFLiquidpassAssistancesContainerProps) {
  const { provider } = props;

  const hasAuxEmergencial = provider?.assistances?.find((assistance) => assistance.type === 204);
  const hasSegDesemprego = provider?.assistances?.find((assistance) => assistance.type === 206);
  const hasOtherAssistances = provider?.assistances?.find(
    (assistance) => assistance.type !== 204 && assistance.type !== 206
  );

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.generalInfoText}>Assistências</Text>
      <View style={styles.generalInfoCardContainer}>
        <PDFGeneralInfoCard title="Auxílio Emergencial" value={hasAuxEmergencial ? "Sim" : "Não"} />
        <PDFGeneralInfoCard title="Seguro Desemprego" value={hasSegDesemprego ? "Sim" : "Não"} />
        <PDFGeneralInfoCard title="Outras Assistências" value={hasOtherAssistances ? "Sim" : "Não"} />
      </View>
    </View>
  );
}
