import { LqdCircularLoader } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ObjectOf } from "../../../common/types/ObjectOf";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { adminCreateSimulatorParameter } from "../../api/CreateSimulatorParameter";
import { adminDeleteSimulatorParametersByProduct } from "../../api/DeleteSimulatorParametersByProduct";
import { adminListSimulatorParametersByProduct } from "../../api/ListSimulatorParametersByProduct";
import { adminUpdateSimulatorParametersByProduct } from "../../api/UpdateSimulatorParametersByProduct";
import { SimulatorParametersRaw } from "../../types/SimulatorParametersRaw";
import SuperTenantCard from "../SuperTenantCard";
import SimulatorParametrizationAdd from "./SimulatorParametrizationAdd";
import SimulatorParametrizationButton from "./SimulatorParametrizationButton";
import SimulatorParametrizationEmptyParameters from "./SimulatorParametrizationEmptyParameters";
import SimulatorParametrizationLoadingParameters from "./SimulatorParametrizationLoadingParameters";
import SimulatorParametrizationTable from "./SimulatorParametrizationTable";
import SimulatorProductSelect from "./SimulatorProductSelect";

export default function SimulatorParametrization() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const currentSelectedTenant = useAppSelector((state) => state.admin.currentSelectedTenant);
  const tenantCode = currentSelectedTenant.code;

  const products = Object.values(useAppSelector((state) => state.admin.products));
  const productsByTenant = products.filter((product) => product.tenantCode === tenantCode);

  const [adjustment, setAdjustment] = useState<string>("Não Definido");
  const [ageConstraint, setAgeConstraint] = useState<string>("");
  const [amortizationType, setAmortizationType] = useState<string>("Não Definido");
  const [bankName, setBankName] = useState<string>("");
  const [interestRate, setInterestRate] = useState<string>("");
  const [ltvNewProperty, setLtvNewProperty] = useState<string>("");
  const [maxIncomeCommitment, setMaxIncomeCommitment] = useState<string>("");
  const [maxInstallmentQty, setMaxInstallmentQty] = useState<string>("");
  const [productId, setProductId] = useState<string>("");
  const [originalBankName, setOriginalBankName] = useState<string>("");

  const [isAddingNewParameter, setIsAddingNewParameter] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingParameters, setIsLoadingParameters] = useState<boolean>(false);
  const [loadedSelectedProductParameters, setLoadedSelectedProductParameters] = useState<boolean>(false);
  const [productSimulatorParameters, setProductSimulatorParameters] = useState<Array<SimulatorParametersRaw>>([]);

  const resetState = () => {
    setAdjustment("Não Definido");
    setAgeConstraint("");
    setAmortizationType("Não Definido");
    setBankName("");
    setInterestRate("");
    setLtvNewProperty("");
    setMaxIncomeCommitment("");
    setMaxInstallmentQty("");
    setIsEditing(false);
    setIsAddingNewParameter(false);
  };

  const onSelectProduct = (value: string) => {
    resetState();
    setProductId(value);
    setProductSimulatorParameters([]);
    setLoadedSelectedProductParameters(false);
  };

  const onLoadCurrentProductParameters = async () => {
    setIsLoadingParameters(true);
    const productSimulatorParametersRaw = await adminListSimulatorParametersByProduct(productId, tenantCode);
    const productSimulatorParameters = productSimulatorParametersRaw.data;
    setProductSimulatorParameters(productSimulatorParameters);
    setIsLoadingParameters(false);
    setLoadedSelectedProductParameters(true);
  };

  const handleButtonMethod = async () => {
    switch (true) {
      case isAddingNewParameter:
        await onSubmitParametrization();
        break;
      case loadedSelectedProductParameters:
        setIsAddingNewParameter(true);
        break;
      default:
        await onLoadCurrentProductParameters();
        break;
    }
  };

  const handleButtonDisabledState = () => {
    switch (true) {
      case isAddingNewParameter:
        return !bankName || !maxInstallmentQty || !interestRate || amortizationType === "Não Definido" || isLoading;
      case loadedSelectedProductParameters:
        return isLoading;
      default:
        return !productId;
    }
  };

  const onSubmitParametrization = async () => {
    const amortizationTypeValue: ObjectOf<string> = {
      Nenhuma: "none",
      "Não Definido": "",
      PRICE: "price",
      SAC: "sac",
    };

    setIsLoading(true);
    const interestRateCleaned = interestRate.replace(/[^0-9.]/g, "");
    const ltvNewPropertyCleaned = ltvNewProperty.replace(/[^0-9.]/g, "");
    const maxIncomeCommitmentCleaned = maxIncomeCommitment.replace(/[^0-9.]/g, "");

    const simulatorData: Omit<SimulatorParametersRaw, "product_id" | "tenant_code"> = {
      bank_name: bankName,
      params: {
        adjustment: adjustment !== "Não Definido" ? adjustment.toLowerCase() : "",
        amortization_type: amortizationTypeValue[amortizationType],
        interest_rate: Number((Number(interestRateCleaned) / 100).toFixed(4)),
        max_installment_qty: Number(maxInstallmentQty),
      },
    };

    // Validate simulatorData optional fields
    if (ageConstraint) simulatorData.params.age_constraint = Number(ageConstraint);
    if (ltvNewProperty)
      simulatorData.params.ltv_new_property = Number((Number(ltvNewPropertyCleaned) / 100).toFixed(4));
    if (maxIncomeCommitment)
      simulatorData.params.max_income_commitment = Number((Number(maxIncomeCommitmentCleaned) / 100).toFixed(4));

    try {
      if (isEditing) {
        const simUpdate: Omit<SimulatorParametersRaw, "product_id" | "tenant_code"> & { original_bank_name: string } = {
          ...simulatorData,
          original_bank_name: originalBankName,
          params: {
            ...simulatorData.params,
            adjustment: adjustment !== "Não Definido" ? adjustment.toLowerCase() : "",
            amortization_type: amortizationTypeValue[amortizationType],
          },
        };

        await adminUpdateSimulatorParametersByProduct(productId, tenantCode, simUpdate);
        const productSimulatorParametersRaw = await adminListSimulatorParametersByProduct(productId, tenantCode);
        const productSimulatorParameters = productSimulatorParametersRaw.data;
        setProductSimulatorParameters(productSimulatorParameters);

        const notification: ToastNotification = {
          message: "Parâmetro atualizado com sucesso.",
          type: "success",
        };

        dispatch(toastCalled(notification));
        resetState();
      } else {
        await adminCreateSimulatorParameter(productId, tenantCode, simulatorData);
        const productSimulatorParametersRaw = await adminListSimulatorParametersByProduct(productId, tenantCode);
        const productSimulatorParameters = productSimulatorParametersRaw.data;
        setProductSimulatorParameters(productSimulatorParameters);

        const notification: ToastNotification = {
          message: "Parâmetro adicionado com sucesso.",
          type: "success",
        };

        dispatch(toastCalled(notification));
        resetState();
      }
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditParameter = async (parameter: SimulatorParametersRaw) => {
    const amortizationTypeValue: ObjectOf<string> = {
      "": "Não Definido",
      none: "Nenhuma",
      price: "PRICE",
      sac: "SAC",
    };

    setIsAddingNewParameter(true);
    setIsEditing(true);

    const adjustmentUpdated = parameter.params.adjustment === "" ? "Não Definido" : parameter.params.adjustment;
    const amortizationTypeUpdated = amortizationTypeValue[parameter.params.amortization_type];
    const ageConstraint =
      parameter.params.age_constraint !== undefined ? parameter.params.age_constraint.toString() : "";
    const ltvNewProperty = parameter.params.ltv_new_property
      ? (parameter.params.ltv_new_property * 100).toString()
      : "";
    const maxIncomeCommitment = parameter.params.max_income_commitment
      ? (parameter.params.max_income_commitment * 100).toString()
      : "";

    setBankName(parameter.bank_name);
    setMaxInstallmentQty(parameter.params.max_installment_qty.toString());
    setInterestRate((parameter.params.interest_rate * 100).toString());
    setAmortizationType(amortizationTypeUpdated);
    setAdjustment(adjustmentUpdated);
    setAgeConstraint(ageConstraint);
    setLtvNewProperty(ltvNewProperty);
    setMaxIncomeCommitment(maxIncomeCommitment);
    setOriginalBankName(parameter.bank_name);
  };

  const handleDeleteParameter = async (parameter: SimulatorParametersRaw) => {
    setIsAddingNewParameter(false);
    setBankName(parameter.bank_name);

    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: onConfirmDeleteParameter, title: "Excluir parâmetro" }],
        body: "Ao confirmar, você excluirá o parâmetro selecionado das opções do simulador. Deseja continuar?",
        title: "Exclusão de Parâmetro",
        type: "default",
      })
    );
  };

  const onConfirmDeleteParameter = async () => {
    dispatch(dialogLoaded(true));

    try {
      await adminDeleteSimulatorParametersByProduct(bankName, productId, tenantCode);
      const productSimulatorParametersRaw = await adminListSimulatorParametersByProduct(productId, tenantCode);
      const productSimulatorParameters = productSimulatorParametersRaw.data;
      setProductSimulatorParameters(productSimulatorParameters);

      const notification: ToastNotification = {
        message: "Parâmetro excluído com sucesso.",
        type: "success",
      };

      dispatch(toastCalled(notification));

      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
    }
  };

  const renderButtonTitle = () => {
    switch (true) {
      case isLoading:
        return <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />;
      case isEditing:
        return "Salvar Edição";
      case isAddingNewParameter:
        return "Salvar Parâmetro";
      case loadedSelectedProductParameters:
        return "Adicionar Parâmetro";
      default:
        return "Parametrizar";
    }
  };

  const renderSimulatorParameters = () => {
    switch (true) {
      case productSimulatorParameters.length > 0:
        return (
          <SimulatorParametrizationTable
            handleDeleteParameter={handleDeleteParameter}
            handleEditParameter={handleEditParameter}
            parameters={productSimulatorParameters}
          />
        );
      case isLoadingParameters:
        return null;
      default:
        return <SimulatorParametrizationEmptyParameters />;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 5, width: "95%" }}>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <SimulatorProductSelect
        onChangeMethod={onSelectProduct}
        options={productsByTenant}
        selectedProductId={productId}
      />
      {isLoadingParameters ? <SimulatorParametrizationLoadingParameters /> : null}
      {loadedSelectedProductParameters ? renderSimulatorParameters() : null}
      {isAddingNewParameter ? (
        <SimulatorParametrizationAdd
          adjustment={adjustment}
          ageConstraint={ageConstraint}
          amortizationType={amortizationType}
          bankName={bankName}
          interestRate={interestRate}
          ltvNewProperty={ltvNewProperty}
          maxIncomeCommitment={maxIncomeCommitment}
          maxInstallmentQty={maxInstallmentQty}
          setAdjustment={setAdjustment}
          setAgeConstraint={setAgeConstraint}
          setAmortizationType={setAmortizationType}
          setBankName={setBankName}
          setInterestRate={setInterestRate}
          setLtvNewProperty={setLtvNewProperty}
          setMaxIncomeCommitment={setMaxIncomeCommitment}
          setMaxInstallmentQty={setMaxInstallmentQty}
        />
      ) : null}
      {!isLoadingParameters ? (
        <SimulatorParametrizationButton
          onClick={handleButtonMethod}
          shouldDisable={handleButtonDisabledState()}
          title={renderButtonTitle()}
        />
      ) : null}
    </Box>
  );
}
