import { LqdButton, LqdDownloadIcon, LqdQuestionIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { onCloseDocumentOptionsMenuModal } from "@signature/signatureSlice";
import { downloadDocument } from "@signature/utils/signatureDocument";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store";

export default function SignatureDocumentOptionsMenu() {
  const dispatch = useAppDispatch();
  const { contract } = useAppSelector((state) => state.signature);

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        left: 0,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          minWidth: "300px",
          padding: "24px",
          width: "384px",
        }}
      >
        <LqdTypography sx={{ marginBottom: "32px" }} textstyle="h5Headline">
          Opções
        </LqdTypography>
        <LqdButton
          onClick={() => downloadDocument(contract.originalDocumentUrl)}
          startIcon={<LqdDownloadIcon />}
          sx={{ width: "100%" }}
          type="outlineTertiary"
        >
          Baixar Documento Original
        </LqdButton>
        <LqdTooltip placement="right-start" sx={{ width: "100%" }} titlebody={t("Em breve")}>
          <LqdButton
            disabled
            startIcon={<LqdQuestionIcon />}
            sx={{ marginBottom: "42px", width: "100%" }}
            type="outlineTertiary"
          >
            Ajuda
          </LqdButton>
        </LqdTooltip>
        <LqdButton
          onClick={() => dispatch(onCloseDocumentOptionsMenuModal())}
          sx={{ width: "100%" }}
          type="outlineTertiary"
        >
          Fechar
        </LqdButton>
      </Box>
    </Box>
  );
}
