import { useErrorHandler } from "@/analysis-v3/src/features/common/utils/useErrorHandler";
import { Stack } from "@mui/material";
import { useState } from "react";
import LqdCircularLoader from "../CircularLoader/CircularLoader";
import LqdTypography from "../Typography";

type LqdToggleProps = {
  active: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => Promise<void> | void;
  renderText?: boolean;
  size?: number;
};

export default function LqdToggle(props: LqdToggleProps) {
  const { active, disabled, onChange, renderText, size = 28 } = props;

  const handleLiquidErrors = useErrorHandler();

  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    if (loading || disabled) return;

    setLoading(true);

    try {
      await onChange(!active);
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const renderToggleComponent = () => {
    switch (true) {
      case loading:
        return <LqdCircularLoader size={18} />;
      case active:
        return (
          <svg fill="none" height={size * 0.65} viewBox="0 0 28 18" width={size} xmlns="http://www.w3.org/2000/svg">
            <rect
              height="16.8"
              rx="8.4"
              stroke="rgba(61, 172, 128, 1)"
              strokeWidth="1.2"
              width="26.8"
              x="0.6"
              y="0.6"
            />
            <circle cx="19" cy="9" fill="rgba(61, 172, 128, 1)" r="5" />
          </svg>
        );
      default:
        return (
          <svg fill="none" height={size * 0.65} viewBox="0 0 28 18" width={size} xmlns="http://www.w3.org/2000/svg">
            <rect
              height="16.8"
              rx="8.4"
              stroke={disabled ? "rgba(212, 215, 220, 1)" : "rgba(155, 162, 175, 1)"}
              strokeWidth="1.2"
              width="26.8"
              x="0.6"
              y="0.6"
            />
            <circle cx="9" cy="9" fill={disabled ? "rgba(212, 215, 220, 1)" : "rgba(155, 162, 175, 1)"} r="5" />
          </svg>
        );
    }
  };

  const renderTextContent = () => {
    switch (true) {
      case disabled:
        return "Desabilitado";
      case active:
        return "Ativo";
      default:
        return "Inativo";
    }
  };

  const toggleComponent = renderToggleComponent();
  const textContent = renderTextContent();

  return (
    <Stack onClick={handleClick} sx={{ cursor: disabled || loading ? "auto" : "pointer" }}>
      {toggleComponent}
      {renderText ? (
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", pt: 0.5 }} textstyle="p2Paragraph">
          {textContent}
        </LqdTypography>
      ) : null}
    </Stack>
  );
}
