import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AnalysisDetailsStep } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { PeerDataWithSteps } from "../../../../../types/AnalysisDetails/PeerData/PeerData";
import { parseString } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFTrustpadPeerCircularSVG from "./StepSVG/PDFTrustpadPeerCircularSVG";

type PDFTrustpadPeerCardContainerProps = {
  peer: PeerDataWithSteps;
  steps: Array<AnalysisDetailsStep>;
};

const styles = StyleSheet.create({
  peerLabel: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingTop: "7px",
  },
  peerMainInfoContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "12px",
  },
  peerName: {
    color: "rgb(56, 61, 70)",
    fontSize: "20px",
    paddingTop: "15px",
  },
  peerPassId: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
  },
  trustpadPeerCardContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    marginLeft: "95px",
    marginTop: "90px",
  },
});

const getIconColor = (action: string) => {
  switch (action) {
    case "match":
      return "rgb(88, 207, 182)";
    case "unmatch":
      return "rgb(246, 61, 94)";
    case "moderation":
      return "rgb(246, 216, 61)";
    default:
      return "rgb(155, 162, 175)";
  }
};

const getBackgroundColor = (action: string) => {
  switch (action) {
    case "match":
      return "rgb(215, 247, 139)";
    case "unmatch":
      return "rgb(252, 139, 138)";
    case "moderation":
      return "rgb(246, 232, 148)";
    default:
      return "rgb(227, 230, 229)";
  }
};

export default function PDFTrustpadPeerCardContainer(props: PDFTrustpadPeerCardContainerProps) {
  const { peer, steps } = props;

  return (
    <View minPresenceAhead={40} style={styles.trustpadPeerCardContainer}>
      <PDFTrustpadPeerCircularSVG
        backgroundColor={getBackgroundColor(steps[steps.length - 1]?.action as string)}
        iconColor={getIconColor(steps[steps.length - 1]?.action as string)}
        peerType={parseString(peer.document).length === 11 ? "CPF" : "CNPJ"}
      />
      <View style={styles.peerMainInfoContainer}>
        <Text style={styles.peerLabel}>{peer.peer}</Text>
        <Text style={styles.peerName}>{capitalizeString(peer.fullName)}</Text>
        <Text style={styles.peerPassId}>{peer.passId}</Text>
      </View>
    </View>
  );
}
