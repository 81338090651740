import { Box, SxProps, useTheme } from "@mui/material";
import { ReactNode } from "react";

type LqdOutlinedBoxProps = {
  children: ReactNode;
  error?: boolean;
  sx?: SxProps;
};

export default function LqdOutlinedBox(props: LqdOutlinedBoxProps) {
  const { children, error, sx } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        ".Mui-focused": { backgroundColor: "rgba(71, 116, 255, 0.04)" },
        borderColor: error ? theme.palette.error.main : theme.palette.secondary.main,
        borderRadius: "4px",
        borderStyle: "solid",
        borderWidth: "1px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
