export const stringInitials = (text: string) => {
  const fullName = text.split(/\s+/);

  if (fullName.length === 1) {
    return fullName[0].slice(0, 2).toUpperCase();
  } else {
    let initials = fullName[0][0].toUpperCase();
    const lastName = fullName[fullName.length - 1];

    if (lastName.length > 1) {
      initials += lastName[0].toUpperCase();
    } else if (fullName.length > 2) {
      initials += fullName[1][0].toUpperCase();
    }

    return initials;
  }
};
