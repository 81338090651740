import { AppError, AppErrorOptions } from "../../common/types/AppError";

export class LoopInRulesError extends AppError {
  constructor(options?: AppErrorOptions) {
    super(
      {
        message: "Não pode haver loop nas regras definidas para o produto.",
      },
      options
    );
  }
}
