import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PeerData } from "../../../../../types/AnalysisDetails/PeerData/PeerData";
import PDFPeerData from "../PDFPeerData";

type PDFSimulatorPeerDataProps = {
  peersToSimulator: Array<PeerData>;
};

const styles = StyleSheet.create({
  borderContainer: {
    borderBottom: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  proponentIDPass: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    marginBottom: "16px",
    paddingTop: "25px",
  },
  simulatorProponentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
});

export default function PDFSimulatorPeerData(props: PDFSimulatorPeerDataProps) {
  const { peersToSimulator } = props;

  return (
    <>
      <Text style={styles.proponentIDPass}>{`ID Pass (${peersToSimulator.length})`}</Text>
      <View minPresenceAhead={40} style={styles.simulatorProponentContainer}>
        {peersToSimulator.map((user) => (
          <PDFPeerData isFirst={peersToSimulator[0].passId === user.passId} key={user.fullName} user={user} />
        ))}
      </View>
      <View style={styles.borderContainer} />
    </>
  );
}
