import { get } from "../../common/utils/apiUtils";
import { AIMessage } from "../types/AnalysisDetails/IA/AIMessage";

/** Parâmetros da requisição.
 * @param action Ação da mensagem.
 * @param analysis_id ID da conexão.
 * @param tenant_code Código do tenant.
 * @param username Nome do usuário.
 */
export type GetAIMessagesParams = {
  action?: "dislike" | "like" | "neutral";
  analysis_id: string;
  tenant_code: string;
  username: string;
};

/** Endpoint para buscar as mensagens de IA.
 * @param params Parâmetros da requisição.
 * @returns Array de AIMessage.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getAIMessages = (params: GetAIMessagesParams) =>
  get<{ messages: Array<AIMessage> }>(`/ai/message`, { params });
