import { Box, Snackbar } from "@mui/material";
import { SyntheticEvent } from "react";
import { LqdCloseIcon, LqdDeleteIcon, LqdDoneIcon, LqdInfoIcon, LqdWarningIcon } from "../../icons";
import LqdTypography from "../Typography";

type LqdToastProps = {
  duration?: number;
  horizontal?: "center" | "left" | "right";
  message?: string;
  onClose: () => void;
  open: boolean;
  title?: string;
  type: "error" | "info" | "success" | "warning" | null;
  vertical?: "bottom" | "top";
};

export default function LqdToast(props: LqdToastProps) {
  const { duration, horizontal, message, onClose, open, title, type, vertical } = props;

  const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    else return onClose();
  };

  const toastColors = {
    error: { background: "rgba(255, 244, 244, 1)", text: "rgba(118, 11, 70, 1)" },
    info: { background: "rgba(245, 248, 255, 1)", text: "rgba(0, 58, 153, 1)" },
    success: { background: "rgba(242, 255, 242, 1)", text: "rgba(20, 119, 126, 1)" },
    warning: { background: "rgba(255, 251, 235, 1)", text: "rgba(122, 60, 13, 1)" },
  };

  const toastIcon = {
    error: <LqdDeleteIcon color="rgba(118, 11, 70, 1)" size={20} />,
    info: <LqdInfoIcon color="rgba(0, 58, 153, 1)" size={20} />,
    success: <LqdDoneIcon color="rgba(20, 119, 126, 1)" size={20} />,
    warning: <LqdWarningIcon color="rgba(122, 60, 13, 1)" size={20} />,
  };

  return type ? (
    <Snackbar
      anchorOrigin={{ horizontal: horizontal || "right", vertical: vertical || "bottom" }}
      autoHideDuration={duration || 4000}
      onClose={handleClose}
      open={open}
    >
      <Box
        sx={{
          backgroundColor: toastColors[type].background,
          borderRadius: 5,
          display: "flex",
          flexDirection: "row",
          maxWidth: "360px",
          minWidth: "260px",
          px: 2.5,
          py: 3,
          width: "360px",
        }}
      >
        {toastIcon[type]}
        <Box sx={{ mx: 1, width: "305px" }}>
          <LqdTypography sx={{ color: toastColors[type].text }}>{title}</LqdTypography>
          <LqdTypography sx={{ color: toastColors[type].text, pt: 1 }} textstyle="c1Caption">
            {message}
          </LqdTypography>
        </Box>
        <Box onClick={handleClose} sx={{ "&:hover": { cursor: "pointer" }, mt: 0.1 }}>
          <LqdCloseIcon color={toastColors[type].text} size={20} />
        </Box>
      </Box>
    </Snackbar>
  ) : null;
}
