import { LqdButton, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { updateTenantAutoArchiveStatus } from "@account/api/UpdateTenantAutoArchiveStatus";
import { updateTenantMFAActivation } from "@account/api/UpdateTenantMFAActivation";
import { useGenerateReport } from "@account/hooks/useGenerateReport";
import { SmallDialogItem } from "@common/components/SmallDialog/SmallDialog";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  dialogCalled,
  dialogDateFieldChanged,
  dialogLoaded,
  toastCalled,
  toggleOption,
} from "../../common/commonSlice";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { onLoadCurrentTenant } from "../../users/usersSlice";
import ArchiveOptionsSelect from "../components/ArchiveOptionsSelect";

const ARCHIVE_OPTIONS = [1, 3, 6, 12];

export default function AccountPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { openCreditCSVReport, regularCSVReport } = useGenerateReport();

  const { currentTenant } = useAppSelector((state) => state.users);
  const hasMFAActive = currentTenant.mfaActive;
  const hasAutoArchive = currentTenant.autoArchive.status;

  const regularCSV: SmallDialogItem = {
    labelOne: "Dia 1 - 30",
    labelTwo: "Dia 16 - 15",
    valueOne: "",
    valueTwo: "",
  };

  const CustomizedCSVOptions: SmallDialogItem = {
    labelOne: "Geral",
    labelTwo: "Open Credit",
    valueOne: "",
    valueTwo: "",
  };

  /** Abre o modal de confirmação para ativação ou desativação da autenticação de dois fatores. */
  const onClickActivateMFA = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          { onClick: handleMFAChangeConfirm, title: !hasMFAActive ? "Habilitar" : "Desabilitar" },
        ],
        body: `A autenticação de dois fatores será habilitada para todos os usuários no seu
                  próximo login. Deseja prosseguir?`,
        title: "Autenticação de dois fatores",
        type: "default",
      })
    );
  };

  /** Ativa ou desativa a autenticação de dois fatores no tenant. */
  const handleMFAChangeConfirm = async () => {
    dispatch(dialogLoaded(true));

    try {
      await updateTenantMFAActivation(!hasMFAActive);
      await dispatch(onLoadCurrentTenant());

      const notification: ToastNotification = {
        message: t(`Autenticação de dois fatores {{value}} com sucesso.`, {
          value: !hasMFAActive ? "ativada" : "desativada",
        }),
        type: "success",
      };

      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogCalled(null));
      dispatch(toggleOption(1));
    }
  };

  /** Ativa ou desativa o arquivamento automático de conexões no tenant. */
  const handleArchiveStatus = async (optionCreate?: number | undefined, optionFinished?: number | undefined) => {
    try {
      const requestBody = {
        created_from: (optionCreate as number) || currentTenant.autoArchive.createdFrom || 1,
        finished_from: (optionFinished as number) || currentTenant.autoArchive.finishedFrom || 1,
        status: optionCreate || optionFinished ? hasAutoArchive : !hasAutoArchive,
      };

      await updateTenantAutoArchiveStatus(requestBody);
      await dispatch(onLoadCurrentTenant());

      const toastMessage =
        optionCreate || optionFinished
          ? t(`Período de arquivamento atualizado com sucesso. `)
          : t(`Arquivamento de conexões foi {{value}} com sucesso. `, {
              value: !hasAutoArchive ? "ativado" : "desativado",
            });

      const notification: ToastNotification = {
        message: toastMessage + t("Essa mudança pode demorar algumas horas para ser aplicada."),
        type: "success",
      };

      dispatch(toastCalled(notification));
      dispatch(toggleOption(1));
    } catch (error) {
      handleLiquidErrors(error);
      dispatch(toggleOption(1));
    }
  };

  /** Abre o modal de confirmação para gerar o relatório de consumo personalizado. */
  const onClickGenerateCustomizedReport = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          {
            onClick: (start, end, csv) => generateReportCSV(start! as string, end! as string, csv as number),
            title: "Exportar CSV",
          },
        ],
        body: "",
        bodySecundary: "Defina o tipo e período de vigência de exportação:",
        fixedRange: false,
        itemList: [CustomizedCSVOptions],
        selectedOption: 1,
        title: "Personalizar Relatório de Consumo",
        type: "date",
      })
    );
    dispatch(dialogDateFieldChanged({ type: "start", value: "" }));
    dispatch(dialogDateFieldChanged({ type: "end", value: "" }));
  };

  /** Abre o modal de confirmação para gerar o relatório de consumo em CSV com datas fixas. */
  const onClickGenerateFixedReport = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          { onClick: (dateRange) => generateFixedReportCSV(dateRange as number), title: "Exportar CSV" },
        ],
        blank: true,
        body: "Esse relatório é utilizado como base para o cálculo do faturamento mensal.",
        bodySecundary: "Escolha o período de vigência de exportação:",
        fixedRange: true,
        itemList: [regularCSV],
        selectedOption: 1,
        title: "Relatório Mensal de Consumo",
        type: "date",
      })
    );
  };

  /** Gera o relatório de consumo fixo em CSV.
   * @param dateRange Período de vigência do relatório.
   * @returns Relatório em CSV.
   */
  const generateFixedReportCSV = async (dateRange: number) => {
    dispatch(dialogLoaded(true));

    const report =
      dateRange === 1 ? await regularCSVReport("", "", true, "1-30") : await regularCSVReport("", "", true, "16-15");
    return report;
  };

  /** Gera o relatório de consumo em CSV.
   * @param startDate Data de início do relatório.
   * @param endDate Data de fim do relatório.
   * @param csv Tipo de relatório a ser gerado.
   * @returns Relatório em CSV.
   */
  const generateReportCSV = async (startDate: string, endDate: string, csv: number) => {
    dispatch(dialogLoaded(true));
    const report =
      csv === 1 ? await regularCSVReport(startDate, endDate, false, "") : await openCreditCSVReport(startDate, endDate);

    return report;
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "auto",
        pt: 6,
        px: { lg: "100px", md: 6, sm: 4 },
      }}
    >
      <LqdTypography className="lqd-account-page-title" sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h4Headline">
        Conta
      </LqdTypography>
      <Box sx={{ maxWidth: "600px", mt: 2 }}>
        <Box
          sx={{
            alignItems: "center",
            borderBottom: "1px solid rgba(212, 215, 220, 1)",
            display: "flex",
            flexDirection: "row",
            height: "50px",
            justifyContent: "space-between",
            mr: hasMFAActive ? 1.5 : 0,
            py: 3,
          }}
        >
          <Box>
            <LqdTypography sx={{ color: "rgba(0, 0, 0, 1)" }}>{t("Autenticação de dois fatores")}</LqdTypography>
            <LqdTypography sx={{ color: "rgba(192, 192, 192, 1)", fontSize: "14px", pt: 1 }} textstyle="p2Paragraph">
              {t("Autenticar todos os usuários no momento do Login")}
            </LqdTypography>
          </Box>
          <LqdToggle active={hasMFAActive} onChange={onClickActivateMFA} renderText />
        </Box>
        <Box sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", py: 3 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              height: "50px",
              justifyContent: "space-between",
              mr: hasAutoArchive ? 1.5 : 0,
            }}
          >
            <LqdTypography sx={{ color: "rgba(0, 0, 0, 1)" }}>{t("Arquivamento automático de conexões")}</LqdTypography>
            <LqdToggle active={hasAutoArchive} onChange={() => handleArchiveStatus()} renderText />
          </Box>
          {hasAutoArchive ? (
            <Box sx={{ display: "flex", flexDirection: "column", mb: 3, width: "360px" }}>
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="c1Caption">
                {t("Criadas a partir de")}:
              </LqdTypography>
              <ArchiveOptionsSelect
                onChange={(value) => handleArchiveStatus(value as number)}
                options={ARCHIVE_OPTIONS}
                value={currentTenant.autoArchive.createdFrom as number}
              />
              <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mt: 8 }} textstyle="c1Caption">
                {t("Finalizadas a partir de")}:
              </LqdTypography>
              <ArchiveOptionsSelect
                onChange={(value) => handleArchiveStatus(undefined, value as number)}
                options={ARCHIVE_OPTIONS}
                value={currentTenant.autoArchive.finishedFrom as number}
              />
            </Box>
          ) : null}
        </Box>
        <LqdTypography sx={{ pb: 3, pt: 6 }} textstyle="p2Paragraph">
          {t("Exportar Detalhes de Consumo")}
        </LqdTypography>
        <Box sx={{ display: "flex", gap: 3, justifyContent: "flex-end" }}>
          <LqdButton onClick={onClickGenerateFixedReport}>{t("Relatório Mensal")}</LqdButton>
          <LqdButton onClick={onClickGenerateCustomizedReport}>{t("Personalizar Datas")}</LqdButton>
        </Box>
      </Box>
    </Box>
  );
}
