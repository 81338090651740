import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { createTemplateModalStepNext, editTemplateModalStepGoTo } from "../../../adminSlice";

type TemplateFormStep1Props = {
  name: string;
  setName: (value: string) => void;
};

const CHAR_LIMIT = 25;

export default function TemplateFormStep1(props: TemplateFormStep1Props) {
  const { name, setName } = props;

  const dispatch = useAppDispatch();
  const { createTemplate, templates, templateToEdit } = useAppSelector((state) => state.admin);
  const templateNames = Object.values(templates).map((template) => template.name);

  const [hasTemplateNameAlteration, setHasTemplateNameAlteration] = useState<boolean>(false);
  const showNameLengthAlert = name.length > 25;

  const handleNameChange = (newName: string) => {
    setHasTemplateNameAlteration(true);
    setName(newName);
  };

  // Envia as modificações deste step para o parent, quando clicar no botão de avançar
  const handleSubmit = () => {
    dispatch(createTemplate ? createTemplateModalStepNext() : editTemplateModalStepGoTo(4));
  };

  const handleNameAcrossTemplates = () => {
    const nameExists = templateNames.some(
      (templateName) => templateName.trim().toLowerCase() === name.trim().toLowerCase()
    );
    return nameExists;
  };

  const handleNameError = () => {
    switch (true) {
      case showNameLengthAlert:
        return `Você ultrapassou o limite de ${CHAR_LIMIT} caracteres. Abrevie para continuar.`;
      case handleNameAcrossTemplates():
        return "Já existe um template com este nome.";
      default:
        return "";
    }
  };

  let maySubmit = name.length > 3 && !handleNameAcrossTemplates() && !showNameLengthAlert;
  // Só permite o submit caso o nome tenha sido alterado
  maySubmit = templateToEdit ? name !== templateToEdit?.name : maySubmit;

  return (
    <Box sx={{ px: 10, width: "100vw" }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        Nome do template
      </LqdTypography>
      <TextField
        autoFocus
        error={showNameLengthAlert}
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={(event) => handleNameChange(event.target.value)}
        onKeyUp={(key) => key.key === "Enter" && handleSubmit()}
        placeholder="Digite o nome do template"
        sx={{
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          width: "100%",
        }}
        value={name}
        variant="standard"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
          {hasTemplateNameAlteration ? handleNameError() : null}
        </LqdTypography>
        <LqdTypography
          sx={{
            color: showNameLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)",
            display: "block",
            textAlign: "right",
          }}
          textstyle="p2Paragraph"
        >
          {`${name.length}/${CHAR_LIMIT} caracteres`}
        </LqdTypography>
      </Box>
      <Box sx={{ mt: 3, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          Avançar
        </LqdButton>
      </Box>
    </Box>
  );
}
