import { AxiosPromise } from "axios";
import { post } from "../../common/utils/apiUtils";
import { TenantCreateRaw } from "../../superadmin/types/TenantCreateRaw";
import { TenantResponseRaw } from "../../superadmin/types/TenantResponseRaw";

/**
 * Create Tenant (Normal Endpoint)
 * POST /tenants
 * Used to create a new tenant (both in Superadmin flow and SignUp)
 * @param tenantInfo
 * @returns TenantResponseRaw
 */
export const createTenant = (tenantInfo: TenantCreateRaw): AxiosPromise<TenantResponseRaw> => {
  return post("/tenants", tenantInfo);
};
