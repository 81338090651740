import { dialogCalled, dialogLoaded } from "@common/commonSlice";
import FullscreenDialog, { FullScreenDialogExtraButton } from "@common/components/FullscreenDialog";
import { SmallDialogInput } from "@common/components/SmallDialog/SmallDialog";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box } from "@mui/material";
import { getSimulatorTesterResult } from "@simulatorBuilder/api/GetSimulatorTesterResult";
import { testSimulator } from "@simulatorBuilder/api/simulationTest";
import { simulatorTesterResult } from "@simulatorBuilder/api/SimulatorTesterResult";
import { updateSimulatorTesterResult } from "@simulatorBuilder/api/UpdateSimulatorTesterResult";
import {
  simulatorResult,
  simulatorResultLoading,
  simulatorTesterResultActive,
} from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorTesterModalTab } from "@simulatorBuilder/types/SimulatorTesterModalTab";
import { simulatorTesterInputVariablesUpdate } from "@simulatorBuilder/utils/simulatorTesterInputVariablesUpdate";
import { parseRawSimulator } from "@simulatorBuilder/utils/transformSimulatorTypes";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import SimulatorTesterLeftSideBar from "./LeftSidebar/SimulatorTesterLeftSideBar";
import SimulatorTesterFlowDiagram from "./SimulatorTesterFlowDiagram/SimulatorTesterFlowDiagram";
import SimulatorTesterResult from "./SimulatorTesterResult";
import SimulatorTesterRightSideBar from "./SimulatorTesterRightSideBar";

type SimulationTesterProps = {
  renderSimulationTester: boolean;
  setRenderSimulationTester: (value: boolean) => void;
  simulator: Simulator;
};

export type SelectedFunctionType = {
  blockId: number;
  functionIndex: number;
  functionName: string;
};

/** Este componente é responsável por renderizar a interface do Tester. Ele permite que os usuários testem
 * o simulador fornecendo valores de entrada e visualizando os resultados. O componente gerencia o estado
 * do Tester, incluindo a aba selecionada, dados de busca de entrada e os resultados do teste.
 */
export default function SimulatorTester(props: SimulationTesterProps) {
  const { renderSimulationTester, setRenderSimulationTester, simulator } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const [hasRedirectedNode, setHasRedirectedNode] = useState(false);
  const [inputSearchData, setInputSearchData] = useState<SimulatorTesterSearchData>({
    blocks: [],
    functions: [],
    searchedText: "",
    type: "",
    variables: [],
  });
  const [result, setResult] = useState<SimulatorTesterResultDBResponse | null>(null);
  const [selectedTab, setSelectedTab] = useState<SimulatorTesterModalTab>(SimulatorTesterModalTab.FLOW);
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [showRightSidebar, setShowRightSidebar] = useState(true);

  const blocks = [...simulator!.blocks];

  const { simulatorTesterSearchData, testerResult, variableTypes } = useAppSelector((state) => state.simulatorBuilder);

  useEffect(() => {
    const fetchSimulatorTesterResult = async () => {
      dispatch(simulatorResultLoading(true));
      try {
        const { data } = await getSimulatorTesterResult(simulator.simulator_id);
        if (data) {
          dispatch(simulatorResult(data));
          dispatch(simulatorTesterResultActive(data.is_results_active));

          if (data.is_results_active) {
            setResult(data);
          }
        }
      } catch (error) {
        dispatch(simulatorResult(null));
        setResult(null);
      } finally {
        dispatch(simulatorResultLoading(false));
      }
    };

    fetchSimulatorTesterResult();
  }, []);

  useEffect(() => {
    setInputSearchData(simulatorTesterSearchData);
  }, [simulatorTesterSearchData]);

  const headerTabs: Array<FullScreenDialogExtraButton> = useMemo(() => {
    return [
      {
        disabled: false,
        onClick: () => setSelectedTab(SimulatorTesterModalTab.FLOW),
        title: "flow",
        type: "tab",
      },
      {
        disabled: false,
        onClick: () => setSelectedTab(SimulatorTesterModalTab.RESULTS),
        title: "results",
        type: "tab",
      },
    ];
  }, [setSelectedTab]);

  const handleTabChange = (newTab: string) => {
    switch (newTab) {
      case "flow":
        setSelectedTab(SimulatorTesterModalTab.FLOW);
        break;
      case "results":
        setSelectedTab(SimulatorTesterModalTab.RESULTS);
        break;
      default:
        setSelectedTab(SimulatorTesterModalTab.FLOW);
    }
  };

  const useTesterResultFunction = async (value: boolean) => {
    await updateSimulatorTesterResult({ ...testerResult!, is_results_active: value });
    dispatch(simulatorTesterResultActive(value));
    setResult(value ? testerResult : null);
  };

  const handleConfirmation = async (updatedInputs: Array<SmallDialogInput>) => {
    dispatch(dialogLoaded(true));
    const updatedVariables = simulator.variables.map((variable) => {
      const input = updatedInputs.find((input) => input.id === variable.id);
      return input ? { ...variable, value: input.value } : variable;
    });

    const updatedSimulator = { ...simulator!, variables: updatedVariables };
    const testSimulatorBody = parseRawSimulator(updatedSimulator, blocks);

    try {
      const { data } = await testSimulator(testSimulatorBody);

      const testerResultBody = {
        inputs: updatedVariables.filter((variable) => variable.is_input),
        is_results_active: true,
        result: data,
        simulator_id: simulator.simulator_id,
        simulator_name: simulator.name,
      };

      if (testerResult) {
        setHasRedirectedNode(false);
        await updateSimulatorTesterResult(testerResultBody);
        setHasRedirectedNode(!!testerResultBody.result.error);
      } else {
        await simulatorTesterResult(testerResultBody);
      }

      const updatedFetchedResult = {
        ...testerResultBody,
        result: data,
      };

      dispatch(simulatorTesterResultActive(true));
      dispatch(simulatorResult(updatedFetchedResult));
      setResult(updatedFetchedResult);
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
    }
  };

  const inputVariables = simulatorTesterInputVariablesUpdate({ simulator, testerResult, variableTypes });

  const openInputsModal = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Limpar Valores" },
          {
            onClick: (updatedInputs) => handleConfirmation(updatedInputs as Array<SmallDialogInput>),
            title: "Confirmar",
          },
        ],
        body: "Digite abaixo os valores para testar o simulador.",
        inputs: inputVariables,
        title: "Preencha as variáveis",
        type: "inputs",
      })
    );
  };

  const renderSimulatorTester = () => {
    if (selectedTab === SimulatorTesterModalTab.FLOW) {
      return (
        <SimulatorTesterFlowDiagram
          hasRedirectedNode={hasRedirectedNode}
          setHasRedirectedNode={setHasRedirectedNode}
          simulator={simulator}
        />
      );
    }
    if (selectedTab === SimulatorTesterModalTab.RESULTS && result) {
      return <SimulatorTesterResult inputSearchData={inputSearchData} result={result} />;
    }
  };

  return (
    <FullscreenDialog
      extraButtons={headerTabs}
      headerType="simulatorTesterDialogHeader"
      onAddInputsClick={openInputsModal}
      onClose={() => setRenderSimulationTester(false)}
      onTabChange={handleTabChange}
      open={renderSimulationTester}
      simulator={simulator}
      tabValue={selectedTab}
      title={simulator.name}
      useTesterResultFunction={useTesterResultFunction}
    >
      <Box sx={{ display: "flex", height: "100%", overflow: "hidden", width: "100%" }}>
        {selectedTab !== SimulatorTesterModalTab.RESULTS ? (
          <SimulatorTesterLeftSideBar
            inputSearchData={inputSearchData}
            selectedTab={selectedTab}
            setShowSidebar={setShowLeftSidebar}
            showSidebar={showLeftSidebar}
            simulator={simulator}
          />
        ) : null}
        <Box sx={{ flexGrow: 1, justifyItems: "center", overflowY: "auto" }}>{renderSimulatorTester()}</Box>
        {selectedTab !== SimulatorTesterModalTab.RESULTS ? (
          <SimulatorTesterRightSideBar
            inputSearchData={inputSearchData}
            selectedTab={selectedTab}
            setShowSidebar={setShowRightSidebar}
            showSidebar={showRightSidebar}
            simulator={simulator}
          />
        ) : null}
      </Box>
    </FullscreenDialog>
  );
}
