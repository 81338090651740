import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFCNPJGeneralInfoCardProps = {
  title: string;
  value: number | string;
};

const styles = StyleSheet.create({
  generalInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "188px",
    marginRight: "24px",
    width: "250px",
  },
  generalInfoCardTitle: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    height: "50px",
    marginTop: "42px",
    paddingLeft: "24px",
  },
  generalInfoCardValue: {
    color: "rgb(7, 65, 84)",
    fontSize: "32px",
    paddingLeft: "24px",
    paddingTop: "20px",
  },
});

export default function PDFCNPJGeneralInfoCard(props: PDFCNPJGeneralInfoCardProps) {
  const { title, value } = props;

  return (
    <View style={styles.generalInfoCard}>
      <Text style={styles.generalInfoCardTitle}>{title}</Text>
      <Text style={styles.generalInfoCardValue}>{value}</Text>
    </View>
  );
}
