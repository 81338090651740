import { LqdIconProps } from "../../types/IconProps";

export default function LqdListIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99875 6.43667H20.0033"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M20.0033 12H8.99875"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8.99875 17.5633H20.0033"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4.49688 5.93646C4.22062 5.93646 3.99667 6.16041 3.99667 6.43667C3.99667 6.71293 4.22062 6.93688 4.49688 6.93688C4.77314 6.93688 4.99709 6.71293 4.99709 6.43667C4.99709 6.16041 4.77314 5.93646 4.49688 5.93646"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4.49688 11.4998C4.22062 11.4998 3.99667 11.7238 3.99667 12C3.99667 12.2763 4.22062 12.5002 4.49688 12.5002C4.77314 12.5002 4.99709 12.2763 4.99709 12C4.99709 11.7238 4.77314 11.4998 4.49688 11.4998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4.49688 17.0631C4.22062 17.0631 3.99667 17.2871 3.99667 17.5633C3.99667 17.8396 4.22062 18.0635 4.49688 18.0635C4.77314 18.0635 4.99709 17.8396 4.99709 17.5633C4.99709 17.2871 4.77314 17.0631 4.49688 17.0631"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
