import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFSBPEInsightCheckMarkSVG from "./SBPE/PDFSBPEInsightCheckMarkSVG";
import PDFSBPEInsightUncheckMarkSVG from "./SBPE/PDFSBPEInsightUncheckMarkSVG";

type PDFSBPEAndBuilderSimulatorInfoCardProps = {
  hasMarginRight?: boolean;
  iconRender?: boolean | null;
  size: "extra-small" | "full-row" | "large" | "small";
  title: string;
  value: string;
};

const styles = StyleSheet.create({
  bottomInfoContainer: {
    paddingTop: "24px",
  },
  extraSmallCardTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
  },
  extraSmallCardValue: {
    color: "rgb(33, 36, 42)",
    fontSize: "14px",
  },
  extraSmallInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    height: "140px",
    justifyContent: "flex-end",
    marginBottom: "12px",
    paddingBottom: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "245px",
  },
  fullRowInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    height: "60px",
    justifyContent: "flex-end",
    marginBottom: "12px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    width: "100%",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoCardRealSign: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
  },
  infoCardTitle: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    minHeight: "50px",
  },
  infoCardValue: {
    color: "rgb(7, 65, 84)",
    fontSize: "28px",
  },
  largeInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    height: "164px",
    padding: "24px",
    width: "500px",
  },
  smallInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    height: "164px",
    padding: "24px",
    width: "325px",
  },
});

/**
 * Componente que renderiza um card com informações de simulação.
 * No caso de simulador do Builder, é utilizada a variante "extra-small".
 * No caso de simulador do SBPE, são utilizadas as variantes "large" e "small".
 * @param props hasMarginRight?: booleano que define se o card deve ter margem à direita.
 * @param props iconRender?: booleano que define se o card deve renderizar um ícone de check ou não.
 * @param props size: tamanho do card.
 * @param props title: título do card.
 * @param props value: valor do card.
 */
export default function PDFSBPEAndBuilderSimulatorInfoCard(props: PDFSBPEAndBuilderSimulatorInfoCardProps) {
  const { hasMarginRight, iconRender, size, title, value } = props;

  const renderCheckIcon = () => {
    switch (iconRender) {
      case true:
        return <PDFSBPEInsightCheckMarkSVG />;
      case false:
        return <PDFSBPEInsightUncheckMarkSVG />;
      default:
        return null;
    }
  };

  const renderCardBasedOnSize = () => {
    switch (size) {
      case "extra-small":
        return (
          <View style={{ ...styles.extraSmallInfoCard, marginRight: hasMarginRight ? "12px" : "0px" }}>
            <View style={styles.bottomInfoContainer}>
              <Text style={styles.extraSmallCardTitle}>{title}</Text>
              <Text style={styles.extraSmallCardValue}>{value}</Text>
            </View>
          </View>
        );
      case "full-row":
        return (
          <View style={styles.fullRowInfoCard}>
            <Text style={styles.extraSmallCardTitle}>{title}</Text>
            <Text style={styles.extraSmallCardValue}>{value}</Text>
          </View>
        );
      case "large":
        return (
          <View style={styles.largeInfoCard}>
            <Text style={styles.infoCardTitle}>{title}</Text>
            <Text style={styles.infoCardRealSign}>R$</Text>
            <Text style={styles.infoCardValue}>{value}</Text>
          </View>
        );
      case "small":
        return (
          <View style={styles.smallInfoCard}>
            <Text style={styles.infoCardTitle}>{title}</Text>
            <View style={styles.genericFlexRowContainer}>
              <View style={styles.bottomInfoContainer}>
                <Text style={styles.infoCardRealSign}>R$</Text>
                <Text style={styles.infoCardValue}>{value}</Text>
              </View>
              {renderCheckIcon()}
            </View>
          </View>
        );
      default:
        return null;
    }
  };

  return renderCardBasedOnSize();
}
