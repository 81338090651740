import { AnalysisDetailsRaw } from "@analysis/types/AnalysisDetails/Analysis/AnalysisDetailsRaw";
import { put } from "../../common/utils/apiUtils";

/**
 * Endpoint responsável por adicionar um comentário à conexão.
 * @param analysisId ID da conexão.
 * @param comments Objeto com o comentário da conexão.
 * @returns Detalhes da conexão, no formato AnalysisDetailsRaw.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const updateAnalysisDetails = (analysisId: string, comments: { comment?: string; status?: string }) =>
  put<AnalysisDetailsRaw>(`/analysis/${analysisId}`, { comments });
