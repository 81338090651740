import { LqdButton, LqdCircularLoader, LqdIconButton, LqdTextField, LqdTypography } from "@/liquid-components/src";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";
import { useAppDispatch } from "../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../common/commonSlice";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { validateEmail } from "../../common/utils/validateEmail";
import { recoverPassword } from "../../recoverPassword/api/RecoverPassword";
import { MFASignForm } from "../types/MFASignForm";

type RenderSignInFormStepsProps = {
  currentStep: number;
  emailError: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>, MFAForm?: MFASignForm) => void;
  loading: boolean;
  password: string;
  setPassword: (password: string) => void;
  setSignInError: (signInError: string) => void;
  setUsername: (username: string) => void;
  signInError: string;
  username: string;
};

const PASSWORD_MIN_LENGTH = 6;

export default function RenderSignInFormSteps(props: RenderSignInFormStepsProps) {
  const {
    currentStep,
    emailError,
    handleSubmit,
    loading,
    password,
    setPassword,
    setSignInError,
    setUsername,
    signInError,
    username,
  } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const [showPassword, setShowPassword] = useState(false);

  const handleRecoverPassword = async (email: string, resend?: boolean) => {
    try {
      dispatch(dialogLoaded(true));
      await recoverPassword(email);

      dispatch(
        dialogCalled({
          actions: [{ title: "Ok!" }, { onClick: () => handleRecoverPassword(email, true), title: "Reenviar e-mail" }],
          body: "Enviamos um link de recuperação de senha para o seu e-mail.",
          title: "Confira seu e-mail",
          type: "default",
        })
      );

      if (resend) {
        const notification: ToastNotification = {
          message: "Reenviamos o e-mail de recuperação de senha para você.",
          type: "success",
        };

        dispatch(toastCalled(notification));
      }
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
    }
  };

  const handleRecoverPasswordClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setPassword("");

    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          { onClick: (email) => handleRecoverPassword(email! as string), title: "Enviar" },
        ],
        body: "Insira o e-mail de cadastro para recuperar sua senha.",
        bodySecundary: "Você receberá as instruções de recuperação no e-mail cadastrado.",
        specialValidation: validateEmail,
        title: "Recuperar senha",
        type: "confirmation",
      })
    );
  };

  const step2OnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (signInError === "") {
      setPassword(event.target.value);
    } else {
      setSignInError("");
      setPassword(event.target.value);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={handleSubmit}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
              E-mail
            </LqdTypography>
            <LqdTextField
              autoFocus
              error={emailError}
              id="signin-email-input"
              inputProps={{
                sx: {
                  color: "rgba(33, 36, 42, 1)",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  padding: 0,
                },
              }}
              label={
                emailError ? (
                  <LqdTypography color="rgba(246, 61, 94, 1)" textstyle="c1Caption">
                    E-mail inválido. Digite novamente.
                  </LqdTypography>
                ) : undefined
              }
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Digite seu e-mail"
              type="email"
              value={username}
            />
            <LqdButton disabled={!username} id="forward-button-email" onClick={handleSubmit} sx={{ float: "right" }}>
              Próximo
            </LqdButton>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleSubmit}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
              Senha
            </LqdTypography>
            <LqdTextField
              autoFocus
              endAdornment={
                <LqdIconButton onClick={() => setShowPassword(!showPassword)} type="ghostIcon">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </LqdIconButton>
              }
              error={!!signInError}
              id="signin-password-input"
              inputProps={{
                sx: {
                  color: "rgba(33, 36, 42, 1)",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  padding: 0,
                },
              }}
              label={
                <LqdTypography color="rgba(246, 61, 94, 1)" sx={{ mt: 0.75 }} textstyle="c1Caption">
                  {signInError}
                </LqdTypography>
              }
              onChange={step2OnChange}
              placeholder="Digite sua senha"
              sx={{ ".MuiInputBase-root": { my: 1.5, pl: 2 }, width: "100%" }}
              type={showPassword ? "text" : "password"}
              value={password}
            />
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Button
                onClick={handleRecoverPasswordClick}
                sx={{ ":hover": { backgroundColor: "transparent" }, margin: "auto" }}
              >
                <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 5, mt: 1.25 }} textstyle="p2Paragraph">
                  Esqueci minha senha
                </LqdTypography>
              </Button>
            </Box>
            <LqdButton
              disabled={loading || password.length < PASSWORD_MIN_LENGTH}
              id="forward-button-password"
              onClick={(event) => handleSubmit(event)}
              sx={{ float: "right" }}
            >
              {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Entrar"}
            </LqdButton>
          </form>
        );
    }
  };

  return renderCurrentStep();
}
