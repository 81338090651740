import { LqdEditIcon, LqdIconButton, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { formatValueToPercentage } from "../../../analysisDetails/utils/analysisDetailsDataFormatter";
import { SimulatorParametersRaw } from "../../types/SimulatorParametersRaw";

type SimulatorParametrizationTableProps = {
  handleDeleteParameter: (parameter: SimulatorParametersRaw) => Promise<void>;
  handleEditParameter: (parameter: SimulatorParametersRaw) => Promise<void>;
  parameters: Array<SimulatorParametersRaw>;
};

export default function SimulatorParametrizationTable(props: SimulatorParametrizationTableProps) {
  const { handleDeleteParameter, handleEditParameter, parameters } = props;

  return (
    <Box sx={{ margin: "auto", width: "90%" }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 2, mt: 6 }} textstyle="p2Paragraph">
        Parâmetros já cadastrados no simulador deste produto:
      </LqdTypography>
      <Table sx={{ width: "114%" }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "rgba(65, 70, 103, 0.04)", height: "60px" }}>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Nome
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Prazo
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Taxa de Juros
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Tabela de Ajuste
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Tipo de Amortização
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                LTV Máximo
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Máx. Comp. de Renda
              </LqdTypography>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <LqdTypography color="rgba(65, 70, 103, 1)" textstyle="p2Paragraph">
                Limite de Idade
              </LqdTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters.map((parameter) => (
            <TableRow key={parameter.bank_name} sx={{ height: "90px" }}>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.bank_name}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {`${parameter.params.max_installment_qty} parcelas`}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {formatValueToPercentage(parameter.params.interest_rate)}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.params.adjustment.toUpperCase() || "Sem correção"}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.params.amortization_type === "none"
                    ? "Nenhuma"
                    : parameter.params.amortization_type.toUpperCase() || "Não configurada"}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.params.ltv_new_property
                    ? formatValueToPercentage(parameter.params.ltv_new_property)
                    : "Não configurado"}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.params.max_income_commitment
                    ? formatValueToPercentage(parameter.params.max_income_commitment)
                    : "Não configurado"}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ p: 1, textAlign: "center" }}>
                <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                  {parameter.params.age_constraint !== undefined
                    ? `${parameter.params.age_constraint} anos`
                    : "Não configurada"}
                </LqdTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                <Box sx={{ display: "flex" }}>
                  <LqdIconButton
                    onClick={() => handleEditParameter(parameter)}
                    round="true"
                    sx={{ mx: 2 }}
                    type="ghostLink"
                  >
                    <LqdEditIcon color="rgba(127, 135, 152, 1)" size={18} />
                  </LqdIconButton>
                  <LqdIconButton onClick={() => handleDeleteParameter(parameter)} round="true" type="ghostLink">
                    <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={18} />
                  </LqdIconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
