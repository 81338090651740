import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import store from "../../../store";

export const hasVariableAlterations = (simulatorVariables: Array<SimulatorVariable>) => {
  const { hasAlterations, initialSimulatorState } = store.getState().simulatorBuilder;
  const simulatorInitialState = initialSimulatorState!;

  const initialVariables = simulatorInitialState.variables;
  const initialVariablesMap = new Map(initialVariables.map((variable) => [variable.name, variable]));

  for (const variable of simulatorVariables) {
    const initialVariable = initialVariablesMap.get(variable.name);

    if (!initialVariable) {
      return true;
    }

    if (
      variable.label !== initialVariable.label ||
      variable.type !== initialVariable.type ||
      variable.value !== initialVariable.value
    ) {
      return true;
    }
  }

  for (const initialVariable of initialVariables) {
    const currentVariable = simulatorVariables.find((variable) => variable.name === initialVariable.name);

    // Se a variável inicial não está nas variáveis atuais, houve uma remoção
    if (!currentVariable) {
      return true;
    }
  }

  if (hasAlterations) return true;

  return false;
};
