import { ObjectOf } from "@/analysis-v3/src/features/common/types/ObjectOf";
import { truncateString } from "@/analysis-v3/src/features/common/utils/truncateString";
import { validateEmail } from "@/analysis-v3/src/features/common/utils/validateEmail";
import { Box, Checkbox, Divider, FormControlLabel, Menu, MenuItem } from "@mui/material";
import { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LqdButton,
  LqdCheckboxOffIcon,
  LqdCheckboxOnIcon,
  LqdCircularLoader,
  LqdLink,
  LqdMenuStepper,
  LqdTypography,
} from "../../../../liquid-components/src";

type LqdMenuProps = {
  allOptions: Array<string>;
  anchorEl: HTMLElement | null;
  currentStep: number;
  loading: boolean;
  menuItems: Array<{ label: string; value: string }>;
  onConfirmMethod: () => Promise<void>;
  openMenu: boolean;
  selectedOptions: Array<string>;
  setAnchorEl: (value: HTMLElement | null) => void;
  setCurrentStep: (value: number) => void;
  setSelectedOptions: Dispatch<SetStateAction<Array<string>>>;
  type: "download" | "export";
};

export default function LqdCheckboxMenu(props: LqdMenuProps) {
  const {
    allOptions,
    anchorEl,
    currentStep,
    loading,
    menuItems,
    onConfirmMethod,
    openMenu,
    selectedOptions,
    setAnchorEl,
    setCurrentStep,
    setSelectedOptions,
    type,
  } = props;

  const { t } = useTranslation();

  const [checkedStates, setCheckedStates] = useState<ObjectOf<boolean>>({});

  const allOptionsLabel = t("Todos");

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = new Set(prevSelectedOptions);

      if (checked) {
        if (name !== allOptionsLabel) {
          newSelectedOptions.add(name);
        }

        if (name === allOptionsLabel) {
          allOptions.forEach((option) => newSelectedOptions.add(option));
        }
      } else {
        newSelectedOptions.delete(name);

        if (name === allOptionsLabel) {
          allOptions.forEach((option) => newSelectedOptions.delete(option));
        }
      }

      return Array.from(newSelectedOptions);
    });
  };

  const handleGoBack = () => {
    setCheckedStates((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (validateEmail(key)) {
          newState[key] = false;
        }
      });
      return newState;
    });

    setSelectedOptions((prevSelectedOptions) => prevSelectedOptions.filter((option) => !validateEmail(option)));

    setCurrentStep(1);
  };

  const handleButtonTitle = () => {
    switch (true) {
      case loading:
        return <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />;
      case type === "download":
        return t("Baixar");
      case type === "export":
        return currentStep === 1 ? t("Continuar") : t("Enviar");
    }
  };

  useEffect(() => {
    const newCheckedStates: ObjectOf<boolean> = { ...checkedStates };

    allOptions.forEach((option) => {
      newCheckedStates[option] = selectedOptions.includes(option);
    });

    newCheckedStates[allOptionsLabel] =
      currentStep === 1
        ? selectedOptions.filter((option) => !validateEmail(option)).length >= allOptions.length
        : selectedOptions.filter((option) => validateEmail(option)).length >= allOptions.length;
    setCheckedStates(newCheckedStates);
  }, [allOptions, selectedOptions]);

  const handleConfirmAction = async () => {
    if (type === "download") {
      await onConfirmMethod();
    } else if (type === "export") {
      return currentStep === 1 ? setCurrentStep(2) : await onConfirmMethod();
    }
  };

  const renderMenuItemLabel = (option: { label: string; value: string }, index: number) => {
    const label = option.label || "Simulador sem nome";

    switch (true) {
      case currentStep === 1:
        return (
          <>
            {label.slice(0, 25)}
            {label.length > 25 ? "..." : null}
          </>
        );
      case currentStep === 2 && index === 1:
        return (
          <>
            {`${truncateString(label, 20)} (você)`}
            {label.length > 25 ? "..." : null}
          </>
        );
      default:
        return (
          <>
            {label.slice(0, 25)}
            {label.length > 25 ? "..." : null}
          </>
        );
    }
  };

  const getCheckedState = (option: { label: string; value: string }) => {
    switch (true) {
      case currentStep === 1 && option.value === "simulator":
        return checkedStates[`${option.value}-${option.label}`] !== undefined
          ? checkedStates[`${option.value}-${option.label}`]
          : false;
      default:
        return checkedStates[option.value] !== undefined ? checkedStates[option.value] : false;
    }
  };

  const renderMenuItem = (option: { label: string; value: string }, index: number) => {
    return (
      <Fragment key={`${option.label}-${index}`}>
        <MenuItem
          disabled={loading}
          onClick={() => {
            const event = {
              target: {
                checked:
                  currentStep === 1 && option.value === "simulator"
                    ? !checkedStates[`${option.value}-${option.label}`]
                    : !checkedStates[option.value],
                name:
                  currentStep === 1 && option.value === "simulator" ? `${option.value}-${option.label}` : option.value,
              },
            } as ChangeEvent<HTMLInputElement>;
            handleOptionChange(event);
          }}
          sx={{
            "&:hover": { backgroundColor: "rgba(236, 238, 240, 1)" },
            borderRadius: "32px",
            height: "44px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={getCheckedState(option)}
                checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={18} />}
                disabled={loading}
                icon={<LqdCheckboxOffIcon color="rgba(155, 162, 175, 1)" size={18} />}
                name={
                  currentStep === 1 && option.value === "simulator" ? `${option.value}-${option.label}` : option.value
                }
              />
            }
            label={
              <LqdTypography color="rgba(101, 110, 127, 1)" textstyle="p2Paragraph">
                {renderMenuItemLabel(option, index)}
              </LqdTypography>
            }
          />
        </MenuItem>
        {option.label === allOptionsLabel ? <Divider sx={{ borderColor: "rgba(212, 215, 220, 1)", m: 0.5 }} /> : null}
      </Fragment>
    );
  };

  const handleMenuContent = () => {
    switch (true) {
      case type === "download" || (type === "export" && currentStep === 1):
        return (
          <Box>
            <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mb: 0.5 }} textstyle="p2Paragraph">
              {`Selecione as páginas que serão ${type === "download" ? "baixadas" : "enviadas"}:`}
            </LqdTypography>
            {menuItems.map((option, index) => renderMenuItem(option, index))}
          </Box>
        );
      case type === "export" && currentStep === 2:
        return (
          <Box>
            <LqdTypography color="rgba(79, 85, 98, 1)" sx={{ mb: 0.5 }} textstyle="p2Paragraph">
              Selecione os e-mails que deverão receber o PDF:
            </LqdTypography>
            <Box sx={{ maxHeight: "317px", overflowY: "auto" }}>
              {menuItems.map((option, index) => renderMenuItem(option, index))}
            </Box>
          </Box>
        );
    }
  };

  const buttonTitle = handleButtonTitle();
  const menuContent = useMemo(() => handleMenuContent(), [currentStep, selectedOptions, checkedStates]);

  return (
    <Menu
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      open={openMenu}
      sx={{
        "& .MuiList-root": { padding: 0 },
        "& .MuiPaper-root": {
          backgroundColor: "rgba(249, 249, 250, 1)",
          borderRadius: "20px",
          boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10)",
          px: 2.5,
          py: 3,
          width: "260px",
        },
        mt: 0.5,
      }}
    >
      {menuContent}
      <LqdButton
        buttonsize="medium"
        disabled={
          currentStep === 1
            ? !selectedOptions.filter((option) => !validateEmail(option)).length || loading
            : !selectedOptions.filter((option) => validateEmail(option)).length || loading
        }
        onClick={handleConfirmAction}
        sx={{ mt: 1.5, width: "100%" }}
      >
        {buttonTitle}
      </LqdButton>
      {currentStep !== 1 ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1.2, width: "100%" }}>
          <LqdLink onClick={handleGoBack} sx={{ color: "rgba(127, 135, 152, 1)", fontSize: "14px" }}>
            Voltar
          </LqdLink>
        </Box>
      ) : null}
      {type === "export" ? <LqdMenuStepper currentStep={currentStep} stepsAmount={2} /> : null}
    </Menu>
  );
}
