import { LqdLockIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

export default function LqdSecureEnvironment() {
  return (
    <Box sx={{ alignItems: "center", alignSelf: "center", display: "flex", mt: "40px" }}>
      <LqdLockIcon color="rgba(155, 162, 175, 1)" />
      <LqdTypography sx={{ color: "rgba(155, 162, 175, 1)", ml: 1 }} textstyle="p2Paragraph">
        Ambiente Seguro Liquid AI
      </LqdTypography>
    </Box>
  );
}
