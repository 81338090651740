import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DefaultLoadingScreen from "../../../common/components/DefaultLoadingScreen";
import getVideoAskTokenData from "../../api/GetVideoAskTokenData";
import { VideoAskTokenData } from "../../types/VideoAskTokenData";
import VideoAskEmbedNoData from "./VideoAskEmbedNoData";
import VideoAskEmbedTokenData from "./VideoAskEmbedTokenData";

export default function VideoAskEmbed() {
  const { tokenId, videoaskId } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [tokenData, setTokenData] = useState<VideoAskTokenData | null>(null);

  useEffect(() => {
    const getTokenData = async () => {
      const response = await getVideoAskTokenData(tokenId!);
      return setTokenData(response.data);
    };
    getTokenData();

    // Fake timeout para dar a sensação de que algo carregou de fato (às vezes o carregamento é tão rápido que a tela pisca)
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const renderVideoAskStatus = () => {
    switch (true) {
      case loading:
        return <DefaultLoadingScreen />;
      case !!tokenData && !loading:
        return <VideoAskEmbedTokenData tokenData={tokenData} videoaskId={videoaskId} />;
      default:
        return <VideoAskEmbedNoData />;
    }
  };

  return <Box sx={{ height: "100vh", overflowX: "hidden", overflowY: "auto" }}>{renderVideoAskStatus()}</Box>;
}
