import { LqdDownTwoIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { MenuItem, Select } from "@mui/material";

type ArchiveOptionsSelectProps = {
  onChange: (value: number | string) => void;
  options: Array<number>;
  value: number;
};

export default function ArchiveOptionsSelect(props: ArchiveOptionsSelectProps) {
  const { onChange, options, value } = props;

  const renderSelectOptionTitle = (option: number) => {
    switch (true) {
      case option === 1:
        return "1 mês";
      case option < 12:
        return `${option} meses`;
      case option === 12:
        return "1 ano";
      default:
        return "Nunca";
    }
  };

  return (
    <Select
      displayEmpty
      IconComponent={(props) => (
        <LqdIconButton sx={{ mt: -1 }} type="ghostPrimary" {...props}>
          <LqdDownTwoIcon />
        </LqdIconButton>
      )}
      MenuProps={{ sx: { ".MuiList-root": { py: 0 }, ".MuiPaper-root": { borderRadius: 0 } } }}
      onChange={(event) => onChange(event.target.value)}
      sx={{
        "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
        alignItems: "flex-end",
        minHeight: 50,
        minWidth: "360px",
        pb: 1,
      }}
      value={value}
      variant="standard"
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          sx={{
            "&.MuiMenuItem-root": { backgroundColor: "rgba(247, 247, 248, 1)" },
            "&.MuiMenuItem-root:hover": { backgroundColor: "rgba(236, 238, 240, 1)" },
            width: "360px",
          }}
          value={option}
        >
          <LqdTypography sx={{ color: "rgba(101, 110, 127, 1)" }} textstyle="p2Paragraph">
            {renderSelectOptionTitle(option)}
          </LqdTypography>
        </MenuItem>
      ))}
    </Select>
  );
}
