import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { maskHash } from "../../../../../utils/maskHash";

const styles = StyleSheet.create({
  descriptionText: {
    color: "rgb(127, 135, 152)",
    fontSize: "24px",
    textAlign: "center",
  },
  titleText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "20px",
    paddingTop: "40px",
  },
});

type PDFOpenCreditEmptyStateContainerProps = {
  passId: string;
};

export default function PDFOpenCreditEmptyStateContainer(props: PDFOpenCreditEmptyStateContainerProps) {
  const { passId } = props;

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.titleText}>Open Credit</Text>
      <Text style={styles.descriptionText}>Não foram encontrados dados para o ID Pass {maskHash(passId)}</Text>
    </View>
  );
}
