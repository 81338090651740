import { LqdButton, LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { Box, Divider, Grid, Link } from "@mui/material";
import { t } from "i18next";
import { Fragment, MouseEventHandler } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ObjectOf } from "../../common/types/ObjectOf";
import Group from "../../groups/types/Group";
import { createUserModalStepChangedTo, editUserFormStepChangedTo } from "../usersSlice";

type UserFormLastStepProps = {
  actionButtonLabel: string;
  loading: boolean;
  onSubmit: () => void;
  selectedGroups: ObjectOf<Group>;
  userEmail: string;
  userName: string;
  userProfile: string;
};

export default function UserFormStepLast(props: UserFormLastStepProps) {
  const { actionButtonLabel, loading, onSubmit, selectedGroups, userEmail, userName, userProfile } = props;

  const dispatch = useAppDispatch();
  const { groups } = useAppSelector((state) => state.groups);
  const { currentUser, userToEdit } = useAppSelector((state) => state.users);
  const belongsToCurrentUser = userEmail === currentUser?.username;

  const handleNameEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(userToEdit ? editUserFormStepChangedTo(1) : createUserModalStepChangedTo(1));
  };

  const handleEmailEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(createUserModalStepChangedTo(2));
  };

  const handleProfileEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(userToEdit ? editUserFormStepChangedTo(2) : createUserModalStepChangedTo(3));
  };

  const handleGroupsEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(userToEdit ? editUserFormStepChangedTo(3) : editUserFormStepChangedTo(4));
  };

  const renderGroupList = () => {
    switch (true) {
      case Object.values(groups).length > 0 && Object.values(selectedGroups).length > 0:
        return Object.values(selectedGroups).map((group) => (
          <Fragment key={group.id}>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 2 }}>
              {group.title}
            </LqdTypography>
            <Divider sx={{ mt: 2 }} />
          </Fragment>
        ));

      case Object.values(groups).length > 0 && !Object.values(selectedGroups).length:
        return (
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 2 }}>
            {t("Nenhum")}
          </LqdTypography>
        );

      default:
        return (
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 2 }}>
            {t("Não há grupos criados.")}
          </LqdTypography>
        );
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 5, pt: { lg: 7, md: 4 } }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: { lg: 5, md: 3 } }} textstyle="h4Headline">
        {t("Resumo")}
      </LqdTypography>
      <Box sx={{ maxHeight: "46.5vh", overflowY: "auto", width: { lg: "60vw", md: "80vw" } }}>
        <Grid container>
          <Grid item sx={{ pb: 2 }} xs={4}>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
              {t("Nome completo")}
            </LqdTypography>
            <Link href="#" onClick={handleNameEdit} sx={{ fontSize: "14px", ml: 1, textDecoration: "none" }}>
              {t("Editar")}
            </Link>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1 }}>
              {userName}
            </LqdTypography>
          </Grid>
          <Grid
            item
            sx={{
              alignItems: "center",
              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
              flexDirection: "column",
              pb: 2,
              px: 4,
            }}
            xs={5}
          >
            <Box sx={{ width: "100%" }}>
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
                E-mail
              </LqdTypography>
              {!userToEdit ? (
                <Link href="#" onClick={handleEmailEdit} sx={{ fontSize: "14px", ml: 1, textDecoration: "none" }}>
                  Editar
                </Link>
              ) : null}
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{
                  mt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {userEmail}
              </LqdTypography>
            </Box>
          </Grid>
          <Grid item sx={{ alignItems: "flex-start", display: "flex", flexDirection: "column", pb: 2, px: 4 }} xs={3}>
            <Box>
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
                {t("Perfil")}
              </LqdTypography>
              {!belongsToCurrentUser ? (
                <Link href="#" onClick={handleProfileEdit} sx={{ fontSize: "14px", ml: 1, textDecoration: "none" }}>
                  {t("Editar")}
                </Link>
              ) : null}
              <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1 }} textstyle="p2Paragraph">
                {capitalizeString(userProfile)}
              </LqdTypography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 7, mt: 2 }} />
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          sx={{ color: "", display: "inline-block" }}
          textstyle="p2Paragraph"
        >
          {t("Grupos selecionados")}
        </LqdTypography>
        {Object.values(groups).length > 0 ? (
          <Link href="#" onClick={handleGroupsEdit} sx={{ fontSize: "14px", ml: 1, textDecoration: "none" }}>
            {t("Editar")}
          </Link>
        ) : null}
        {renderGroupList()}
      </Box>
      <Box sx={{ mt: 5, textAlign: "right" }}>
        <LqdButton className="lqd-user-form-steplast-save-button" disabled={loading} onClick={onSubmit}>
          {loading ? <LqdCircularLoader sx={{ color: "inherit" }} /> : actionButtonLabel}
        </LqdButton>
      </Box>
    </Box>
  );
}
