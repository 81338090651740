import { LqdTypography } from "@/liquid-components/src";
import { ObjectOf } from "@common/types/ObjectOf";
import { highlightText } from "@common/utils/highlightText";
import { ArrowDropDown, ArrowLeft, ArrowRight, ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { simulatorTesterFunctionClick } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorTesterModalTab } from "@simulatorBuilder/types/SimulatorTesterModalTab";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";

type SimulatorTesterRightSideBarProps = {
  inputSearchData: SimulatorTesterSearchData;
  selectedTab: SimulatorTesterModalTab;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showSidebar: boolean;
  simulator: Simulator;
};

/**
 * Componente para renderizar a barra lateral direita do Tester. Ele mostra os blocos e funções
 * do simulador e permite que os usuários cliquem neles para destacar a função no diagrama de fluxo.
 *
 * @param {SimulatorTesterRightSideBarProps} props - As propriedades passadas para o componente.
 */
export default function SimulatorTesterRightSideBar(props: SimulatorTesterRightSideBarProps) {
  const { inputSearchData, selectedTab, setShowSidebar, showSidebar, simulator } = props;

  const dispatch = useAppDispatch();

  const { functionTemplates, selectedFunction } = useAppSelector((state) => state.simulatorBuilder);

  const [expandedBlocks, setExpandedBlocks] = useState<ObjectOf<boolean>>({});
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);
  const [isSimulatorExpanded, setIsSimulatorExpanded] = useState<boolean>(true);

  useEffect(() => {
    setIsEmptyState(simulator.blocks.length === 0);
  }, [simulator.blocks]);

  const toggleBlock = (blockId: number) => {
    setExpandedBlocks((prev) => ({
      ...prev,
      [blockId]: !prev[blockId],
    }));
  };

  const toggleSimulator = () => {
    setIsSimulatorExpanded((prev) => !prev);
  };

  const handleFunctionClick = (blockId: number, functionIndex: number, functionName: string) => {
    dispatch(simulatorTesterFunctionClick({ blockId, functionIndex, functionName }));
  };

  const renderArrowIcon = () => {
    if (isEmptyState) {
      return null;
    }

    return isSimulatorExpanded ? (
      <ArrowDropDown sx={{ color: "rgba(152, 162, 179, 1)", fontSize: 16 }} />
    ) : (
      <ArrowRightIcon sx={{ color: "rgba(152, 162, 179, 1)", fontSize: 16 }} />
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box
        onClick={() => (selectedTab === SimulatorTesterModalTab.FLOW ? setShowSidebar(!showSidebar) : null)}
        sx={{
          "&:hover": { background: "rgb(233, 233, 233)" },
          alignItems: "center",
          backgroundColor: "rgba(236, 238, 240, 1)",
          cursor: "pointer",
          display: "flex",
          height: "100%",
          width: 16,
        }}
      >
        {showSidebar ? (
          <ArrowRight sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        ) : (
          <ArrowLeft sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        )}
      </Box>

      {showSidebar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            maxHeight: "100%",
            overflowY: "auto",
            padding: "40px 20px",
            position: "relative",
            width: "259px",
          }}
        >
          <LqdTypography textstyle="p1Paragraph">Índex</LqdTypography>
          <Stack
            alignItems="center"
            direction="row"
            gap={0.5}
            onClick={isEmptyState ? undefined : toggleSimulator}
            sx={{ cursor: isEmptyState ? "default" : "pointer" }}
          >
            {renderArrowIcon()}
            <LqdTypography textstyle="c1Caption">{simulator.name}</LqdTypography>
          </Stack>

          {isSimulatorExpanded ? (
            <Box sx={{ ml: 2.5, position: "relative" }}>
              {simulator.blocks.map((block, index) => {
                const isExpanded = expandedBlocks[block.block_id];
                const isLastBlock = index === simulator.blocks.length - 1;

                return (
                  <Box
                    key={block.block_id}
                    sx={{
                      "&::before": {
                        backgroundColor: "rgba(228, 231, 236, 1)",
                        content: '""',
                        height: isExpanded && isLastBlock ? "calc(100% - 56px)" : "100%",
                        left: -12,
                        position: "absolute",
                        top: 0,
                        width: "1px",
                      },
                      pl: 0.5,
                      position: "relative",
                    }}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={0.5}
                      onClick={() => toggleBlock(block.block_id)}
                      sx={{
                        "&::before": {
                          backgroundColor: "rgba(228, 231, 236, 1)",
                          content: '""',
                          height: "1px",
                          left: -16,
                          position: "absolute",
                          top: "50%",
                          width: 16,
                        },
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {isExpanded ? (
                        <ArrowDropDown sx={{ color: "rgba(152, 162, 179, 1)", fontSize: "16px" }} />
                      ) : (
                        <ArrowRightIcon sx={{ color: "rgba(152, 162, 179, 1)", fontSize: "16px" }} />
                      )}
                      <LqdTypography color="rgba(71, 84, 103, 1)" textstyle="c1Caption">
                        {block.name}
                      </LqdTypography>
                    </Stack>
                    {isExpanded ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: 1,
                          position: "relative",
                          pt: 1.5,
                        }}
                      >
                        {block.functions.map((func, funcIndex) => {
                          const isLastFunction = funcIndex === block.functions.length - 1;
                          const isSelectedFunction =
                            selectedFunction?.blockId === block.block_id &&
                            selectedFunction.functionIndex === func.index &&
                            selectedFunction.functionName === func.function_name;

                          return (
                            <Box
                              key={func.index}
                              sx={{
                                "&::before": {
                                  backgroundColor: "rgba(228, 231, 236, 1)",
                                  content: '""',
                                  height: isLastFunction ? "calc(100% - 23px)" : "100%",
                                  left: 0,
                                  position: "absolute",
                                  top: 0,
                                  width: "1px",
                                },
                                pb: 1.5,
                                pl: 2.5,
                                position: "relative",
                              }}
                            >
                              <Box
                                onClick={() => handleFunctionClick(block.block_id, func.index, func.function_name)}
                                sx={{
                                  "&::before": {
                                    backgroundColor: "rgba(228, 231, 236, 1)",
                                    content: '""',
                                    height: "1px",
                                    left: -20,
                                    position: "absolute",
                                    top: "50%",
                                    width: 16,
                                  },
                                  alignItems: "center",
                                  cursor: "pointer",
                                  display: "flex",
                                  position: "relative",
                                }}
                              >
                                <LqdTypography
                                  sx={{
                                    color: isSelectedFunction ? "rgba(71, 71, 71, 1)" : "rgba(152, 162, 179, 1)",
                                    cursor: "pointer",
                                    fontWeight: isSelectedFunction ? "600" : "normal",
                                  }}
                                  textstyle="c1Caption"
                                >
                                  {highlightText(
                                    functionTemplates.find((template) => template.function_name === func.function_name)
                                      ?.function_label || "",
                                    inputSearchData.searchedText,
                                    "12px"
                                  )}
                                </LqdTypography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
