import { LqdTable, LqdToggle, LqdTypography } from "@/liquid-components/src";
import SettingsRouteHeader from "@common/components/SettingsRouteHeader";
import { Box, Stack } from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, dialogLoaded } from "../../common/commonSlice";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import updateGroup from "../api/UpdateGroup";
import CreateGroupForm from "../components/CreateGroupForm";
import EditGroupForm from "../components/EditGroupForm";
import { createGroupModalOpened, editGroupModalOpened, onLoadGroups } from "../groupsSlice";
import Group from "../types/Group";
import { getGroupRaw } from "../utils/getGroupRaw";

export default function GroupsPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();

  const { groups } = useAppSelector((state) => state.groups);
  const products = useAppSelector((state) => {
    if (Object.values(state.products.tenantProducts).length > 0) {
      return state.products.tenantProducts;
    }
    return state.products.groupProducts;
  });
  const { users } = useAppSelector((state) => state.users);

  /** Faz a request para ativar/desativar o grupo
   * @param group Grupo a ser ativado/desativado
   * @param confirm Se a ação deve ser confirmada
   */
  const handleToggleGroup = async (group: Group, confirm?: boolean) => {
    const updateGroupStatus = async () => {
      try {
        const updatedGroup: Group = { ...group, active: !group.active };
        const groupRaw = getGroupRaw(updatedGroup);
        await updateGroup(groupRaw);
        dispatch(onLoadGroups());
      } catch (error) {
        handleLiquidErrors(error, "Houve um erro ao ativar o grupo. Tente novamente.");
      } finally {
        dispatch(dialogCalled(null));
      }
    };

    if (group.active) {
      dispatch(
        dialogCalled({
          actions: [
            { title: "Cancelar" },
            {
              onClick: async () => {
                onConfirmInactivateGroup(group);
                await updateGroupStatus();
              },
              title: "Confirmar",
            },
          ],
          body: "Tem certeza que deseja desativar este grupo?",
          title: "Desativar grupo",
          type: "default",
        })
      );
    } else if (confirm) {
      await updateGroupStatus();
    }
  };

  /** Confirma a desativação do grupo, após clicar no botão de confirmação do dialog
   * @param group Grupo a ser desativado
   */
  const onConfirmInactivateGroup = (group: Group) => {
    dispatch(dialogLoaded(true));
    handleToggleGroup(group, true);
  };

  const onEditGroupClick = (group: Group, step: number) => {
    dispatch(editGroupModalOpened({ group, step }));
  };

  const onCreateGroupClick = () => {
    dispatch(createGroupModalOpened());
  };

  const tableContent = Object.values(groups).map((group) => {
    const groupProducts = group.products.map((productId) => products[productId]?.name);
    const firstThreeProducts = groupProducts.slice(0, 3);
    const extraProductsLength = groupProducts.length - firstThreeProducts.length;

    const groupUsers = group.users.map((userEmail) => users[userEmail]);
    const firstThreeUsers = groupUsers.slice(0, 3);
    const extraUsersLength = groupUsers.length - firstThreeUsers.length;

    return [
      {
        primaryValue: group.title,
        tertiaryValue: { method: () => onEditGroupClick(group, 1), text: "Editar" },
      },
      {
        header: "Produtos",
        primaryValue: groupProducts.length > 9 ? groupProducts.length : "0" + groupProducts.length,
        tertiaryValue: { method: () => onEditGroupClick(group, 2), text: "Editar" },
        tooltip: {
          body:
            firstThreeProducts.length > 0 ? (
              <>
                {firstThreeProducts.map((product, index) => (
                  <Box key={`${product}-${index}`}>{product}</Box>
                ))}
                {extraProductsLength > 0 ? `+ ${extraProductsLength} outros` : null}
              </>
            ) : (
              "Nenhum produto"
            ),
          header: "Produtos",
        },
      },
      {
        primaryValue: groupUsers.length > 9 ? groupUsers.length : "0" + groupUsers.length,
        tertiaryValue: { method: () => onEditGroupClick(group, 3), text: "Editar" },
        tooltip: {
          body: (
            <>
              {firstThreeUsers.length > 0 ? (
                <>
                  {firstThreeUsers.map((user) => (
                    <Box key={user?.email}>{user?.name}</Box>
                  ))}
                  {extraUsersLength > 0 ? <Box>{`+ ${extraUsersLength} outros`}</Box> : null}
                </>
              ) : (
                "Nenhum usuário"
              )}
              <Stack onClick={() => navigate("/config/users")} sx={{ cursor: "pointer", mt: 1 }}>
                <LqdTypography color="rgba(240, 241, 243, 1)" textstyle="p2Paragraph">
                  {"Ver detalhes >"}
                </LqdTypography>
              </Stack>
            </>
          ),
          header: "Usuários",
        },
      },
      {
        primaryValue: format(group.created, "dd MMM yyyy", { locale: ptBR }),
        tertiaryValue: format(group.created, "HH:mm", { locale: ptBR }),
      },
      {
        primaryValue: (
          <LqdToggle
            active={group.active}
            onChange={async (confirm) => await handleToggleGroup(group, confirm)}
            renderText
          />
        ),
      },
    ];
  });

  return (
    <>
      <SettingsRouteHeader
        buttonMethod={onCreateGroupClick}
        buttonTitle="Novo Grupo"
        sx={{ mt: 5 }}
        title="Grupos"
        tooltipBody="Crie um grupo e associe políticas e usuários da maneira que desejar."
        tooltipHeader="Adicionar um grupo"
      />
      {Object.values(groups).length > 0 ? (
        <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
          <Stack sx={{ width: "87.5%" }}>
            <LqdTable<Group>
              tableContent={tableContent}
              tableHeader={["Grupo", "Produtos", "Usuários", "Criação", "Status"]}
            />
          </Stack>
        </Stack>
      ) : (
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ textAlign: "center" }} textstyle="h4Headline">
          Você não participa de nenhum grupo.
        </LqdTypography>
      )}
      <CreateGroupForm />
      <EditGroupForm />
    </>
  );
}
