import { Timestamp } from "@common/types/Timestamp";

export const formatSignatureDate = (timestamp: Timestamp): string => {
  const date = new Date(timestamp * 1000);

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formattedDate = `${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;

  return formattedDate;
};
