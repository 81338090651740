import { LqdButton, LqdCircularLoader, LqdSelect } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { simulatorBuilderChanged } from "../simulatorBuilderSlice";

type SimulatorFormStepNameProps = {
  editing: boolean;
  loading: boolean;
  onConfirm: () => void;
};

export default function SimulatorFormStepTenant(props: SimulatorFormStepNameProps) {
  const { editing, loading, onConfirm } = props;

  const { duplicating, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const { tenants } = useAppSelector((state) => state.admin);
  const simulator = simulatorData!;

  const dispatch = useAppDispatch();

  const [selectedTenant, setSelectedTenant] = useState<{ code: string; contact: { name: string } } | null>(
    simulator.tenant_code
      ? {
          code: simulator.tenant_code,
          contact: { name: simulator.tenant_name },
        }
      : null
  );
  const [tenantId, setTenantId] = useState<string | null>(simulator.tenant_code ? simulator.tenant_code : null);

  const maySubmit = Boolean(tenantId) && !loading;

  useEffect(() => {
    if (tenantId) {
      const tenant = tenants[tenantId];
      setSelectedTenant(tenant);
      dispatch(simulatorBuilderChanged({ key: "tenant_code", value: tenant.code }));
      dispatch(simulatorBuilderChanged({ key: "tenant_name", value: tenant.contact.name }));
    }
  }, [tenantId]);

  const handleUpdateTenant = () => {
    onConfirm();
  };

  const renderButtonContent = () => {
    switch (true) {
      case loading:
        return <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />;
      case editing:
        return "Atualizar";
      case duplicating:
        return "Duplicar";
      default:
        return "Avançar";
    }
  };

  const buttonContent = renderButtonContent();

  const mappedTenants = Object.values(tenants).map((tenant) => ({
    id: tenant.code,
    label: tenant.contact.name,
    value: tenant.code,
  }));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%", margin: "auto", width: { md: "700px", xs: "400px" } }}
    >
      <LqdSelect
        onChange={setTenantId}
        options={mappedTenants}
        searchPlaceholder="Buscar empresas"
        title="O simulador será associado a qual empresa?"
        type="complete"
        value={selectedTenant ? selectedTenant.code : ""}
      />
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3, width: "100%" }}>
        <LqdButton disabled={!maySubmit} onClick={handleUpdateTenant}>
          {buttonContent}
        </LqdButton>
      </Stack>
    </Stack>
  );
}
