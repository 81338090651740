import { Box, Stack, StackProps } from "@mui/material";
import useWindowSize from "../../../../analysis-v3/src/features/common/hooks/useWindowSize";

type LqdInfoCardProps = {
  children: React.ReactNode;
};

export default function LqdInfoCardContainer(props: LqdInfoCardProps & StackProps) {
  const { children, sx } = props;

  const { width } = useWindowSize();

  const renderContent = () => {
    switch (width) {
      case "xs":
      case "sm":
        return (
          <Box
            sx={{
              "::-webkit-scrollbar": { height: "0px", width: "0px" },
              display: "flex",
              gap: { sm: 1.5, xs: 0.75 },
              mb: 3,
              overflowX: { md: "visible", xs: "scroll" },
              width: "100%",
              ...sx,
            }}
          >
            {children}
          </Box>
        );
      default:
        return (
          <Stack
            direction="row"
            flexWrap="wrap"
            spacing={1.5}
            sx={{
              mb: 3,
              ...sx,
            }}
            useFlexGap
          >
            {children}
          </Stack>
        );
    }
  };

  return renderContent();
}
