import { LqdTypography } from "@/liquid-components/src";
import { Avatar, Box } from "@mui/material";
import { TenantResponse } from "../types/TenantResponse";

type SuperTenantCardProps = {
  currentSelectedTenant: TenantResponse;
};

export default function SuperTenantCard(props: SuperTenantCardProps) {
  const { currentSelectedTenant } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(191, 196, 205, 0.12)",
        borderRadius: "20px",
        display: "flex",
        height: "108px",
        margin: "auto",
        width: "90%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Avatar
          alt="Imagem Genérica de uma Empresa"
          src="/img/generic-product.png"
          sx={{ height: "60px", mx: 3, width: "60px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }}>{currentSelectedTenant.contact.name}</LqdTypography>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="p2Paragraph">
            {currentSelectedTenant.code}
          </LqdTypography>
        </Box>
      </Box>
    </Box>
  );
}
