import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterSeven() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        7. OBRIGAÇÕES DO USUÁRIO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        7.1. O Usuário Decisor se compromete a:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) Utilizar o Liquid exclusivamente em seu nome, ou de suas Empresas Afiliadas (caso em que permanecerá
        integralmente responsável, nos termos do item 6.2 supra), em estrita conformidade com estes Termos e para fins
        lícitos;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) Realizar o pagamento da Retribuição (conforme definido na cláusula 9 abaixo), em conformidade com o previsto
        nestes Termos e também na Proposta Comercial, sempre que aplicável;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        c) Deter todas as autorizações necessárias para o Tratamento de Dados Pessoais (conforme definido na Lei nº
        13.709, de 14 de agosto de 2018, ou “LGPD”), comprometendo-se a cessar qualquer tratamento cuja autorização não
        tenha sido obtida ou tenha sido revogada pelo Titular;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        d) Atuar em estrita conformidade com a Política; e
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        e) Não tomar medidas para analisar, ou contratar um terceiro para analisar, fazer engenharia reversa, traduzir
        ou decompor o Liquid, colaborando com a proteção dos direitos de propriedade intelectual do Liquid, conforme
        cláusula 13 abaixo.
      </LqdTypography>
    </>
  );
}
