import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ObjectOf } from "../../../../../../../common/types/ObjectOf";
import { BuilderSimulation } from "../../../../../../types/Simulation/Builder/BuilderSimulation";
import { BuilderSimulationTableData } from "../../../../../../types/Simulation/Builder/BuilderSimulationTable";
import { formatCardValueBasedOnType } from "../../../../../../utils/analysisDetailsDataFormatter";

type PDFBuilderSimulatorTableProps = {
  simulation: BuilderSimulation;
};

const styles = StyleSheet.create({
  builderTableHeaderTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "16px",
    paddingTop: "24px",
  },
  genericFlexRow: {
    flexDirection: "row",
  },
  tableCell: {
    borderBottom: "1px solid rgb(212, 215, 220)",
    borderRight: "1px solid rgb(212, 215, 220)",
    display: "flex",
    fontSize: "12px",
    paddingBottom: "16px",
    paddingLeft: "8px",
    paddingTop: "16px",
  },
  tableCellBackgroundOne: {
    backgroundColor: "rgb(127, 135, 152, 0.04)",
  },
  tableCellLast: {
    borderRight: "none",
  },
});

export default function PDFBuilderSimulatorTable(props: PDFBuilderSimulatorTableProps) {
  const { simulation } = props;

  const tables = simulation.dashboard?.tables || [];

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.builderTableHeaderTitle}>Tabelas</Text>
      <View minPresenceAhead={40}>
        {tables.map((table, tableIndex) => {
          const groupedData = table.data
            .flat()
            .reduce((acc: ObjectOf<Array<BuilderSimulationTableData>>, curr: BuilderSimulationTableData) => {
              if (!acc[curr.line]) {
                acc[curr.line] = [];
              }
              acc[curr.line][curr.column] = curr;
              return acc;
            }, {});

          const cellWidth = `${(100 / table.column_names.length).toString()}%`;

          return (
            <View key={tableIndex}>
              <View style={styles.genericFlexRow}>
                {table.column_names.map((column) => (
                  <Text
                    key={column.index}
                    style={[
                      styles.tableCell,
                      { width: cellWidth },
                      styles.tableCellBackgroundOne,
                      column.index === table.column_names.length - 1 ? styles.tableCellLast : {},
                    ]}
                  >
                    {column.name ? column.name : ""}
                  </Text>
                ))}
              </View>
              {Object.values(groupedData).map((row, index) => (
                <View key={index} style={styles.genericFlexRow}>
                  {row.map((cell, cellIndex) => (
                    <Text
                      key={cellIndex}
                      style={[
                        styles.tableCell,
                        { width: cellWidth },
                        cellIndex === row.length - 1 ? styles.tableCellLast : {},
                      ]}
                    >
                      {formatCardValueBasedOnType(cell.value, cell.type, cell.label)}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          );
        })}
      </View>
    </View>
  );
}
