import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterSixteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        16. DEMANDA DE TERCEIROS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        16.1. Os Usuários Decisores serão responsáveis pelas informações inclusas no Liquid e eximem a Liquid de todos
        os danos, perdas e custos (incluindo, entre outros; honorários e despesas advocatícias ou legais) relacionados a
        todas as ações judiciais no âmbito civil ou criminal propostas por terceiros, causadas: (1) pelo não cumprimento
        pelos Usuários dos presentes Termos, incluindo, entre outros, a publicação de conteúdo que viole os direitos de
        terceiros ou as leis aplicáveis; (2) pelo uso inadequado e ilícito do Liquid; (3) pela inserção de informações
        confidenciais não autorizadas ou por dados que sejam de fontes ilegais; e (4) por qualquer atividade ilícita na
        qual o Usuário se envolva por utilização irregular do Liquid.
      </LqdTypography>
    </>
  );
}
