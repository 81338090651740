import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { BuilderSimulation } from "../../../../../../types/Simulation/Builder/BuilderSimulation";
import { formatCardValueBasedOnType } from "../../../../../../utils/analysisDetailsDataFormatter";
import PDFSBPEAndBuilderSimulatorInfoCard from "../PDFSBPEAndBuilderSimulatorInfoCard";

type PDFBuilderSimulatorCardsProps = {
  simulation: BuilderSimulation;
};

const styles = StyleSheet.create({
  builderSimulatorCardsSectionTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "16px",
    paddingTop: "24px",
  },
  builderSimulatorCardsSectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export default function PDFBuilderSimulatorCards(props: PDFBuilderSimulatorCardsProps) {
  const { simulation } = props;

  const cards = simulation.dashboard?.cards || [];

  return (
    <View minPresenceAhead={40}>
      <View style={styles.builderSimulatorCardsSectionTitleContainer}>
        <Text style={styles.builderSimulatorCardsSectionTitle}>Cards</Text>
      </View>
      <View minPresenceAhead={40} style={styles.generalInfoCardContainer}>
        {cards.map((card, index) => (
          <PDFSBPEAndBuilderSimulatorInfoCard
            hasMarginRight={index === 0 || index % 3 !== 0}
            key={card.name}
            size="extra-small"
            title={card.label}
            value={formatCardValueBasedOnType(card.value, card.type, card.label)}
          />
        ))}
      </View>
    </View>
  );
}
