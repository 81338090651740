import { LqdButton, LqdInput, LqdTypography } from "@/liquid-components/src";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { formatDatabaseDate } from "@analysis/utils/analysisDetailsDataFormatter";
import { Box, Stack } from "@mui/material";
import { onChangingView, onScheduling, onSendingMetadata } from "@signature/signatureSlice";
import { GenerateScheduleConsts, Peer } from "@signature/types/schedule";
import { useEffect, useState } from "react";
import store, { useAppDispatch, useAppSelector } from "../../../store";
import { SignatureViews } from "../types/signatureViews";
import RegistryNavigationBar from "./RegistryNavigationBar";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function EmailSearch() {
  const dispatch = useAppDispatch();

  const [emailState, setEmailState] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { cpf, peerData, token } = useAppSelector((state) => state.signature);

  const getPeerDataFromStore = () => {
    const reverseBirthdate = formatDatabaseDate(peerData.birthday, "/");
    const userdata: Peer = {
      holderbirthdate: reverseBirthdate,
      holdercellphone: peerData.phone,
      holdercpf: cpf,
      holderddd: peerData.ddd,
      holderemail: emailState,
      holdername: peerData.full_name,
    };
    const liderConsts: GenerateScheduleConsts = {
      pa: 66,
      product: 1306,
      user: 239,
    };
    const peerInfo = { userdata, ...liderConsts };
    return peerInfo;
  };

  useEffect(() => {
    const onInputChange = () => {
      const emailRegex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim;
      const emailIsValid = emailRegex.test(emailState);
      setEmailError(!emailIsValid);
    };
    onInputChange();
  }, [emailState]);

  const handleSearch = async () => {
    setLoading(true);
    const peerInfo = getPeerDataFromStore();
    await dispatch(onScheduling(peerInfo)).unwrap();
    const updatedScheduleData = store.getState().signature.scheduleData;
    dispatch(onSendingMetadata({ metadata: { email: emailState, process_id: updatedScheduleData.data.id }, token }));
    setLoading(false);
    if (updatedScheduleData.success) {
      dispatch(onChangingView(SignatureViews.SCHEDULED));
    } else {
      dispatch(onChangingView(SignatureViews.NOT_SCHEDULED));
    }
  };

  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 2 de 2",
        }}
        previusPage={SignatureViews.WITHOUT_CLOUD_SIGNING}
        step={SignatureViews.EMAIL_SEARCH}
      />

      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Confirme seus dados para agendar a emissão
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Preencha o campo abaixo para continuar:
        </LqdTypography>

        <Stack sx={{ alignItems: "center", display: "flex", flexDirection: "row", mb: 3, mt: 5 }}>
          <LqdTypography color="rgba(101, 110, 127, 1)">Qual o seu email?</LqdTypography>
        </Stack>
        <Stack>
          <LqdInput placeholderText="Digite aqui" setValue={setEmailState} value={emailState} />
          {emailError && emailState.length > 0 ? (
            <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)", textAlign: "left" }} textstyle="p1Paragraph">
              Digite um E-mail válido
            </LqdTypography>
          ) : null}
        </Stack>
        <LqdButton
          color="primary"
          disabled={emailError || loading}
          endIcon={loading ? null : <RightArrow />}
          onClick={handleSearch}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          Continuar
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
