import { LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { useAppSelector } from "../../../store";
import AutomationCard from "./AutomationCard";

export default function AutomationsList() {
  const { automations, automationSearch } = useAppSelector((state) => state.automations);
  const automationsToShow = useMemo(
    () =>
      Object.values(automations)
        .filter((automation) =>
          automationSearch.trim() ? automation.name.toLowerCase().includes(automationSearch.toLowerCase().trim()) : true
        )
        .sort((automationA, automationB) => {
          return automationB.created_at - automationA.created_at;
        }),
    [automationSearch, automations]
  );

  const renderAutomationsList = () => {
    const searchExists = Boolean(automationSearch.trim().length > 0);

    switch (true) {
      case automationsToShow.length > 0:
        return automationsToShow.map((automation) => (
          <AutomationCard automation={automation} key={automation.configuration_id} />
        ));
      case searchExists:
        return (
          <LqdTypography
            sx={{ color: "rgba(127, 135, 152, 1)", left: 0, position: "absolute", right: 0, top: "calc(50% - 0.5em)" }}
            textAlign="center"
            textstyle="h4Headline"
          >
            Não foram encontradas automações com esse nome.
          </LqdTypography>
        );
      default:
        return (
          <LqdTypography
            sx={{ color: "rgba(127, 135, 152, 1)", left: 0, position: "absolute", right: 0, top: "calc(50% - 0.5em)" }}
            textAlign="center"
            textstyle="h4Headline"
          >
            Você ainda não possui automações criadas.
          </LqdTypography>
        );
    }
  };

  return (
    <Stack
      className="lqd-automations-list"
      spacing={2}
      sx={{
        boxSizing: "border-box",
        margin: "auto",
        maxWidth: "1135px",
        mt: 3,
        overflowX: "hidden",
        pb: "100px",
        px: 4,
        width: "100%",
      }}
    >
      {renderAutomationsList()}
    </Stack>
  );
}
