import { Edge } from "react-flow-renderer";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { ObjectOf } from "../../common/types/ObjectOf";
import { ActionNodeData, ProviderNodeData } from "../types/NodeData";
import Proponent from "../types/Proponent";
import { getProviderNodeHandles } from "./getProviderNodeHandles";

// Remove as edges saindo de handles inexistentes
export const validateEdges = (edges: Array<Edge>, nodes: Array<Node>, proponents: Array<Proponent>): Array<Edge> => {
  /* ---------- ETAPA 1: validar se edges possuem source handles e target handles válidos ---------- */

  // Guarda todas as edges que possuem conexões válidas (seus target e source existem)
  const connectedEdges: Array<Edge> = [];
  // Guarda todos os handles de todos os nós
  const allNodeHandles: Array<string> = [];

  // Faz a montagem do allNodesHandles
  nodes.forEach((node) => {
    let nodeHandles: Array<string> = [];
    // Se o nó for de action
    if ((node.data as ActionNodeData).action) {
      nodeHandles = [`handle-${node.id}`];
      // O nó pode ter um source handle se for do tipo warning
      if ((node.data as ActionNodeData).action === "warning") {
        nodeHandles.push(`handle-warning-${node.id}`);
      }
    }
    // Se o nó for do tipo provider
    if ((node.data as ProviderNodeData).provider) {
      nodeHandles = getProviderNodeHandles(node as Node<ProviderNodeData>);
    }
    // Se o nó for de start-flow, os handles dele são obtidos a partir dos proponentes
    if (node.id === "node-start_flow" && proponents) {
      nodeHandles = proponents.map((proponent) => `handle-${proponent.id}`);
    }
    allNodeHandles.push(...nodeHandles);
  });

  // Faz a montagem do connectedEdges
  edges.forEach((edge) => {
    // A edge entra somente se os source/target handles dela existirem
    const sourceHandleExists = allNodeHandles.find((nodeHandle) => edge.sourceHandle === nodeHandle);
    const targetHandleExists = allNodeHandles.find((nodeHandle) => edge.targetHandle === nodeHandle);
    if (sourceHandleExists && targetHandleExists) {
      connectedEdges.push({ ...edge, type: "customedge" });
    }
  });
  /* ---------- ETAPA 2: eliminar edges que estão saindo do mesmo source handle ---------- */

  // Guarda os sourceHandles que ja possuem conexão
  const nodeSourcedHandles: ObjectOf<boolean> = {};
  // Guarda as edges que passaram nesta etapa de validação
  const validEdges: Array<Edge> = [];
  connectedEdges.forEach((edge) => {
    const handleIsSourced = nodeSourcedHandles[edge.sourceHandle as string];
    if (!handleIsSourced) {
      nodeSourcedHandles[edge.sourceHandle as string] = true;
      validEdges.push(edge);
    }
  });
  return validEdges;
};
