import { LqdButton, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import openFinanceValidate from "../../assets/openFinanceValidate.png";

type OpenFinanceStartStateProps = {
  onStart: () => void;
  pluggyToken: null | string;
};

export default function OpenFinanceStartState(props: OpenFinanceStartStateProps) {
  const { onStart, pluggyToken } = props;

  const { width } = useWindowSize();

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 0 } }}
    >
      <Box>
        <Box sx={{ mb: { sm: "30px", xs: "20px" } }}>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Autorize a consulta com a segurança
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(101, 110, 127, 1)", display: "inline", ml: { lg: 1.5, xs: 1 } }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Liquid
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            .
          </LqdTypography>
        </Box>
        <LqdTypography sx={{ mb: 5 }} textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}>
          Siga o passo a passo e finalize o compartilhamento em poucos segundos.
        </LqdTypography>
        <LqdTypography sx={{ display: "inline", mr: 0.5 }} textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}>
          Ao iniciar, você concorda com nossos
        </LqdTypography>
        <LqdLink
          href="/terms-and-conditions"
          sx={{ fontSize: { sm: "16px", xs: "12px" }, textDecorationLine: "underline" }}
          target="_blank"
        >
          Termos e Condições de Uso
        </LqdLink>
        <LqdTypography sx={{ display: "inline", mb: 5 }} textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}>
          .
        </LqdTypography>
        <Stack alignItems="flex-start" direction={{ sm: "row", xs: "column" }} spacing={2.5} sx={{ my: 5 }}>
          <LqdButton disabled={!pluggyToken} onClick={onStart}>
            Iniciar validação
          </LqdButton>
        </Stack>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openFinanceValidate} />
      </Box>
    </Stack>
  );
}
