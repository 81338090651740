import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassAddresses } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassAddresses";
import { formatValueToAddZeroes, getDateFormat } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFLiquidpassAddressesTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<LiquidPassAddresses>;
  tableLength: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithFourColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "45%",
  },
  tableWithFourColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "15%",
  },
  tableWithFourColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "25%",
  },
  tableWithFourColumns4: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "15%",
  },
  tableWithoutLabelTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingTop: "40px",
  },
});

export default function PDFLiquidpassAddressesTable(props: PDFLiquidpassAddressesTableProps) {
  const { currentPage, lastPage, tableData, tableLength } = props;

  const getAddress = (address: string) => {
    const addressFirstHalf = address.split("-")[0].toUpperCase();
    const addressSecondHalf = capitalizeString(address.split("-")[1]);

    return `${addressFirstHalf} - ${addressSecondHalf}`;
  };

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFTableIconOrangeSVG />
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.tableTitle}>Endereços</Text>
          {tableLength > 0 ? (
            <View style={styles.genericFlexRowContainer}>
              <Text style={styles.tableCount}>{tableLength}</Text>
              {tableLength > 16 ? (
                <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
              ) : null}
            </View>
          ) : (
            <Text style={styles.tableCount}>0</Text>
          )}
        </View>
      </View>
      <Text style={styles.tableFullLabel}>Histórico de endereços:</Text>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithFourColumns1}>Logradouro</Text>
          <Text style={styles.tableWithFourColumns2}>CEP</Text>
          <Text style={styles.tableWithFourColumns3}>Cidade</Text>
          <Text style={styles.tableWithFourColumns4}>Data</Text>
        </View>
        {tableData.map((address, index) => (
          <View key={`${address.street}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.tableWithFourColumns1}>
              {address.street ? capitalizeString(address.street) : "Não encontrado"}
            </Text>
            <Text style={styles.tableWithFourColumns2}>
              {address.zip_code ? formatValueToAddZeroes(address.zip_code, "CEP") : "Não encontrado"}
            </Text>
            <Text style={styles.tableWithFourColumns3}>
              {address.city ? getAddress(address.city) : "Não encontrado"}
            </Text>
            <Text style={styles.tableWithFourColumns4}>
              {address.date ? getDateFormat(new Date(address.date).getTime() / 1000) : "Não encontrado"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
