import { LqdButton, LqdDeleteFilledIcon, LqdTypography } from "@/liquid-components/src";
import LeftArrow from "@/liquid-components/src/icons/LeftArrow";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { onChangingView } from "../signatureSlice";
import { Action } from "../types/signature";
import { SignatureViews } from "../types/signatureViews";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

type ActionFailedProps = {
  action: Action;
};

export default function ActionFailed(props: ActionFailedProps) {
  const { action } = props;
  const dispatch = useAppDispatch();

  const signatureMessage = "a assinatura do documento";
  const scheduleMessage = "o agendamento";

  const renderActionMessage = () => {
    switch (action) {
      case "signature":
        return signatureMessage;
      case "schedule":
        return scheduleMessage;
      default:
        return "sua solicitação";
    }
  };

  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: {
            sm: "400px",
            xs: "80%",
          },
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdDeleteFilledIcon color="rgba(255, 59, 48, 1)" size={45} />
        </Stack>
        <LqdTypography sx={{ mt: 2, textAlign: "center" }} textAlign="center" textstyle="h3Headline">
          Ops, algo deu errado.
        </LqdTypography>
        <LqdTypography sx={{ mt: 1, textAlign: "center" }}>
          {` Aconteceu alguma coisa que impediu ${renderActionMessage()}. Aguarde alguns minutos e tente novamente.`}
        </LqdTypography>
        <LqdButton
          color="primary"
          onClick={() => dispatch(onChangingView(SignatureViews.DOCUMENT_VIEW))}
          startIcon={<LeftArrow />}
          sx={{ mt: 2, width: "100%" }}
          type="ghostPrimary"
          variant="contained"
        >
          Voltar
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
