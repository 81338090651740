import { LqdDownIcon } from "@/liquid-components/src";
import { Select, SelectProps } from "@mui/material";

export default function AutomationSelect(props: SelectProps) {
  const { sx, value } = props;

  return (
    <Select
      {...props}
      displayEmpty
      IconComponent={LqdDownIcon}
      inputProps={{
        sx: {
          ":active": { boxShadow: "0px 0px 0px 4px rgba(235, 235, 245, 0.70)" },
          ":focus": { borderColor: "rgba(107, 114, 128, 1)" },
          ":hover": {
            borderColor: "rgba(107, 114, 128, 1)",
          },
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(229, 231, 235, 1)",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
          display: "flex",
          pl: "12px !important",
        },
      }}
      MenuProps={{
        MenuListProps: { sx: { ".MuiMenuItem-root.Mui-selected": { backgroundColor: "transparent" }, p: 0 } },
        PaperProps: {
          sx: {
            border: "1px solid rgba(33, 36, 42, 1)",
            borderRadius: "8px",
            boxSizing: "border-box",
            maxHeight: "400px",
            mt: "10px",
            pl: 2,
            pr: "9px",
            py: 3,
          },
        },
      }}
      sx={{
        ".MuiInput-input": { height: "48px !important", p: 0 },
        ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
        },
        "::after": { display: "none" },
        "::before": { display: "none" },
        color: value ? "inherit" : "rgba(156, 163, 175, 1)",
        svg: { pointerEvents: "none", position: "absolute", right: "12px" },
        ...sx,
      }}
      variant="standard"
    />
  );
}
