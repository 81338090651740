import { Box, Stack } from "@mui/material";
import Lottie from "react-lottie-player";
import signatureJson from "../assets/signatureLoadingLottie.json";

export default function SignatureLoading() {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        width: "100vw",
      }}
    >
      <Stack sx={{ width: { md: "500px", xs: "260px" } }}>
        <Lottie
          animationData={signatureJson}
          loop={true}
          play
          rendererSettings={{ preserveAspectRatio: "none" }}
          speed={1.5}
        />
      </Stack>
    </Box>
  );
}
