import { LqdHomeIcon, LqdSearch, LqdTypography, LqdWorldIcon } from "@/liquid-components/src";
import { Avatar, Box } from "@mui/material";
import { useState } from "react";
import { ProductResponse } from "../../types/ProductResponse";
import { normalizeText } from "@common/utils/normalizeText";

type SimulatorProductSelectProps = {
  onChangeMethod: (value: string) => void;
  options: Array<ProductResponse>;
  selectedProductId: string;
};

export default function SimulatorProductSelect(props: SimulatorProductSelectProps) {
  const { onChangeMethod, options, selectedProductId } = props;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredProducts = options.filter((option) => normalizeText(option.name).includes(normalizeText(searchTerm)));

  const emptyString =
    options.length === 0
      ? "Ainda não há nenhum produto cadastrado para essa empresa."
      : "Não foram encontrados produtos com esse nome.";

  return (
    <Box sx={{ margin: "auto", width: "90%" }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mr: 2, mt: 4 }}>
        Selecione o Produto desejado
      </LqdTypography>
      <Box sx={{ mt: 2, width: "fit-content" }}>
        <LqdSearch
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder="Buscar produtos"
          value={searchTerm}
        />
      </Box>
      <Box
        sx={{
          "::-webkit-scrollbar": { height: "8px" },
          "::-webkit-scrollbar-track": { border: "none" },
          height: "280px",
          mt: 3,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Box
              key={product.productId}
              onClick={() => onChangeMethod(product.productId)}
              sx={{
                ":hover": { outline: "2px solid rgba(127, 135, 152, 0.40)" },
                alignItems: "center",
                backgroundColor: "rgba(191, 196, 205, 0.12)",
                borderRadius: "20px",
                boxSizing: "border-box",
                cursor: "pointer",
                display: "flex",
                height: "114px",
                margin: "auto",
                mb: 1.5,
                ml: 0.2,
                mr: 1.5,
                mt: 1.5,
                outline: selectedProductId === product.productId ? "2px solid rgba(127, 135, 152, 0.60)" : "none",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ alignItems: "center", display: "flex", height: "90px" }}>
                  <Box sx={{ display: "flex", width: "360px" }}>
                    <Avatar alt={product.name} src={product.image} sx={{ height: "40px", mx: 2, width: "40px" }} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="p2Paragraph">
                        Produto
                      </LqdTypography>
                      <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="p2Paragraph">
                        {product.name}
                      </LqdTypography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "360px" }}>
                    <Box sx={{ display: "flex", mr: 3 }}>
                      <LqdWorldIcon />
                      <LqdTypography
                        sx={{ alignSelf: "center", color: "rgba(127, 135, 152, 1)", ml: 1 }}
                        textstyle="p2Paragraph"
                      >
                        {product.country} / {product.currency}
                      </LqdTypography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <LqdHomeIcon />
                      <LqdTypography
                        sx={{ alignSelf: "center", color: "rgba(127, 135, 152, 1)", ml: 1 }}
                        textstyle="p2Paragraph"
                      >
                        {product.segment}
                      </LqdTypography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <LqdTypography sx={{ mt: 3 }}>{emptyString}</LqdTypography>
        )}
      </Box>
    </Box>
  );
}
