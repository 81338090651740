import { LqdToast } from "@/liquid-components/src";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toastReseted } from "../commonSlice";

export const ToastNotification = () => {
  const dispatch = useAppDispatch();

  const { toastNotification } = useAppSelector((state) => state.common);

  return (
    <LqdToast
      duration={toastNotification.duration || 4000}
      horizontal={toastNotification.horizontal || "right"}
      onClose={() => dispatch(toastReseted())}
      open={!!toastNotification.type}
      vertical={toastNotification.vertical || "bottom"}
      {...toastNotification}
    />
  );
};
