import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFTableIconGraySVG from "./PDFTableIconGraySVG";

type PDFSerasaNotFoundTableProps = {
  notFoundTitle: string;
};

const styles = StyleSheet.create({
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  notFoundInfoContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    height: "80px",
    justifyContent: "center",
    marginTop: "24px",
    width: "100%",
  },
  notFoundInfoText: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "12px",
    paddingTop: "2px",
  },
});

export default function PDFSerasaNotFoundTable(props: PDFSerasaNotFoundTableProps) {
  const { notFoundTitle } = props;

  return (
    <View minPresenceAhead={40} style={styles.notFoundInfoContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFTableIconGraySVG />
        <Text style={styles.notFoundInfoText}>{notFoundTitle}</Text>
      </View>
    </View>
  );
}
