import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassPartnershipRaw } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassPartnershipsRaw";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFLiquidpassCPFShareHoldingsTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<LiquidPassPartnershipRaw>;
  tableLength: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginHorizontal: "auto",
    marginTop: "24px",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithSixColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "30%",
  },
  tableWithSixColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "18%",
  },
  tableWithSixColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "18%",
  },
  tableWithSixColumns4: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "12%",
  },
  tableWithSixColumns5: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "12%",
  },
  tableWithSixColumns6: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "10%",
  },
});

export default function PDFLiquidpassCPFShareHoldingsTable(props: PDFLiquidpassCPFShareHoldingsTableProps) {
  const { currentPage, lastPage, tableData, tableLength } = props;

  const entryDateValue = (entryDate: string) => {
    return new Date(entryDate).toLocaleDateString();
  };

  const entryDateColumnValue = (entryDate: string) => {
    return `${entryDateValue(entryDate).split("/")[0]}/${entryDateValue(entryDate).split("/")[1]}/${
      entryDateValue(entryDate).split("/")[2]
    }`;
  };

  const partnershipStatus = (status: number) => {
    switch (status) {
      case 0:
        return "Inativa";
      case 1:
      case 2:
      case 3:
        return "Ativa";
      default:
        return "Não encontrado";
    }
  };

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <PDFTableIconOrangeSVG />
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.tableTitle}>Empresas com Sociedade</Text>
          {tableLength > 0 ? (
            <View style={styles.genericFlexRowContainer}>
              <Text style={styles.tableCount}>{tableLength}</Text>
              {tableLength > 16 ? (
                <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
              ) : null}
            </View>
          ) : (
            <Text style={styles.tableCount}>0</Text>
          )}
        </View>
      </View>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithSixColumns1}>Razão Social</Text>
          <Text style={styles.tableWithSixColumns2}>CNPJ</Text>
          <Text style={styles.tableWithSixColumns3}>Local</Text>
          <Text style={styles.tableWithSixColumns4}>Entrada</Text>
          <Text style={styles.tableWithSixColumns5}>Participação</Text>
          <Text style={styles.tableWithSixColumns6}>Status</Text>
        </View>
        {tableData.map((partnership, index) => {
          return (
            <View key={`${partnership.CompanyDocument}-${index}`} style={styles.tableRowContainer}>
              <Text style={styles.tableWithSixColumns1}>
                {partnership.CompanyName ? partnership.CompanyName : "Não encontrado"}
              </Text>
              <Text style={styles.tableWithSixColumns2}>
                {partnership.CompanyDocument ? partnership.CompanyDocument : "Não encontrado"}
              </Text>
              <Text style={styles.tableWithSixColumns3}>
                {partnership.City && partnership.State
                  ? `${capitalizeString(partnership.City)} - ${partnership.State}`
                  : "Não encontrado"}
              </Text>
              <Text style={styles.tableWithSixColumns4}>
                {partnership.EntryDate ? entryDateColumnValue(partnership.EntryDate) : "Não encontrado"}
              </Text>
              <Text style={styles.tableWithSixColumns5}>
                {partnership.Quote !== null
                  ? `${JSON.stringify(partnership.Quote)?.replace(".", ",")}%`
                  : "Não encontrado"}
              </Text>
              <Text style={styles.tableWithSixColumns6}>
                {partnership.Status !== null ? partnershipStatus(partnership.Status) : "Não encontrado"}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}
