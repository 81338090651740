import { LqdAdminIcon, LqdGridIcon, LqdSettingsIcon, LqdTypography, LqdUserThreeIcon } from "@/liquid-components/src";
import { env } from "@common/utils/apiUtils";
import { Grid, List, ListItemButton, ListItemIcon, Stack, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";

export default function SettingsPageLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const pageName = pathname.split("/")[2];

  const { appCommit, appVersion } = useAppSelector((state) => state.common);
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const renderMenuItemColor = (menuItem: string) => {
    if (menuItem === pageName) {
      return theme.palette.primary.main;
    }

    return "rgba(168, 173, 173, 1)";
  };

  return (
    <Grid
      className="lqd-configurations-page-layout"
      columns={{ lg: 12, md: 12, sm: 10, xs: 4 }}
      container
      sx={{ height: "100%" }}
    >
      <Grid
        item
        md={2.5}
        sm={3}
        sx={{
          backgroundColor: "rgba(127, 135, 152, 0.04)",
          display: { flexDirection: "column", justifyContent: "space-between", sm: "flex", xs: "none" },
          px: { md: 3, sm: 2 },
          py: { lg: 5, sm: 3 },
        }}
      >
        <List>
          {currentUser?.userRole === "super" ? (
            <ListItemButton
              onClick={() => navigate("/config/admin")}
              sx={{ ".MuiTypography-root": { fontSize: "14px" }, color: renderMenuItemColor("admin"), px: 0, py: 1.5 }}
            >
              <ListItemIcon sx={{ color: "inherit", justifyContent: "center" }}>
                <LqdSettingsIcon color={renderMenuItemColor("admin")} />
              </ListItemIcon>
              <LqdTypography sx={{ color: "inherit" }} textstyle="buttonM">
                Administrador
              </LqdTypography>
            </ListItemButton>
          ) : null}
          <ListItemButton
            onClick={() => navigate("/config/account")}
            sx={{
              ".MuiTypography-root": { fontSize: "14px" },
              color: pageName === "account" ? theme.palette.primary.main : "rgba(168, 173, 173, 1)",
              px: 0,
              py: 1.5,
            }}
          >
            <ListItemIcon sx={{ color: "inherit", justifyContent: "center" }}>
              <LqdAdminIcon color={pageName === "account" ? theme.palette.primary.main : "rgba(168, 173, 173, 1)"} />
            </ListItemIcon>
            <LqdTypography sx={{ color: "inherit" }} textstyle="buttonM">
              Conta
            </LqdTypography>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/config/groups")}
            sx={{ ".MuiTypography-root": { fontSize: "14px" }, color: renderMenuItemColor("groups"), px: 0, py: 1.5 }}
          >
            <ListItemIcon sx={{ color: "inherit", justifyContent: "center" }}>
              <LqdGridIcon color={renderMenuItemColor("groups")} />
            </ListItemIcon>
            <LqdTypography sx={{ color: "inherit" }} textstyle="buttonM">
              Grupos
            </LqdTypography>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/config/users")}
            sx={{ ".MuiTypography-root": { fontSize: "14px" }, color: renderMenuItemColor("users"), px: 0, py: 1.5 }}
          >
            <ListItemIcon sx={{ color: "inherit", justifyContent: "center" }}>
              <LqdUserThreeIcon color={renderMenuItemColor("users")} />
            </ListItemIcon>
            <LqdTypography sx={{ color: "inherit" }} textstyle="buttonM">
              Usuários
            </LqdTypography>
          </ListItemButton>
        </List>
        <Stack>
          <LqdTypography
            sx={{
              backgroundColor: "rgba(240, 241, 243, 1)",
              borderRadius: "40px",
              display: "inline-block",
              fontSize: "10px",
              lineHeight: "10px",
              m: "auto",
              p: 1,
            }}
            textstyle="l1Label"
          >
            Versão {appVersion} {env === "development" ? `{${appCommit}}` : ""}
          </LqdTypography>
        </Stack>
      </Grid>
      <Grid
        item
        md={9.5}
        sm={7}
        sx={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}
        xs={4}
      >
        <Outlet />
      </Grid>
    </Grid>
  );
}
