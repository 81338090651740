const newContractsPerMonthOptions = [
  {
    id: "1",
    label: "0-50 por mês",
    value: "0",
  },
  {
    id: "2",
    label: "50-100 por mês",
    value: "50",
  },
  {
    id: "3",
    label: "100-500 por mês",
    value: "100",
  },
  {
    id: "4",
    label: "500-2.500 por mês",
    value: "500",
  },
  {
    id: "5",
    label: "Acima de 2.500 por mês",
    value: "2500",
  },
];

export default newContractsPerMonthOptions;
