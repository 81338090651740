import { LqdIconButton, LqdTooltip, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { truncateString } from "@common/utils/truncateString";
import { Box, Stack, SxProps } from "@mui/material";
import { formatCardValueBasedOnType } from "../../../../../analysisDetails/utils/analysisDetailsDataFormatter";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";

type SimulatorResultFilledCard = {
  handleDeleteClick: (index: number) => void;
  index: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

export default function SimulatorHighLightFilledCard(props: SimulatorResultFilledCard) {
  const { handleDeleteClick, index, selectedVariable, wrapper } = props;

  return (
    <Stack direction="row" spacing="auto" sx={wrapper}>
      <Stack direction="column">
        <Stack direction="row" spacing={0.5}>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.label.length > 35 ? "false" : "true"}
            placement="top"
            titlebody={selectedVariable!.content!.label}
          >
            <LqdTypography color="rgba(75, 75, 75, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
              {truncateString(selectedVariable!.content!.label, 35)}
            </LqdTypography>
          </LqdTooltip>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.name.length > 35 ? "false" : "true"}
            placement="top"
            titlebody={selectedVariable!.content!.name}
          >
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
              {truncateString(selectedVariable!.content!.name, 35)}
            </LqdTypography>
          </LqdTooltip>
        </Stack>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="buttonXL">
          {selectedVariable.content?.value
            ? formatCardValueBasedOnType(
                selectedVariable.content.value,
                selectedVariable.content.type!,
                selectedVariable.content.label
              )
            : "Valor não definido (variável dinâmica)"}
        </LqdTypography>
      </Stack>
      <Box sx={{ alignSelf: "flex-end", display: "inline-flex" }}>
        <LqdIconButton onClick={() => handleDeleteClick(index)} type="ghostIcon">
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={20} />
        </LqdIconButton>
      </Box>
    </Stack>
  );
}
