import {
  LqdButton,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdTypography,
  LqdWarningIcon,
} from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { GroupResponseRaw } from "../../types/GroupResponseRaw";

type UserDeletionConfirmationModalProps = {
  groupList: Array<GroupResponseRaw>;
  isLoading: boolean;
  onCloseMethod: () => void;
  onDeleteMethod: () => void;
  open: boolean;
};

export default function UserDeletionConfirmationModal(props: UserDeletionConfirmationModalProps) {
  const { groupList, isLoading, onCloseMethod, onDeleteMethod, open } = props;

  return (
    <LqdDialog onClose={onCloseMethod} open={open}>
      {isLoading ? (
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
          <LqdCircularLoader size={64} />
        </Box>
      ) : (
        <>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "rgba(240, 241, 243, 1)",
              borderRadius: "50%",
              height: "48px",
              mb: 2.5,
              width: "48px",
            }}
          >
            <LqdWarningIcon color="rgba(127, 135, 152, 1)" size={24} />
          </Stack>
          <>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2.5 }} textstyle="h4Headline">
              Exclusão de Usuários
            </LqdTypography>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }} textstyle="p2Paragraph">
              Os usuários selecionados serão removidos dos grupos abaixo:
            </LqdTypography>
            <Box sx={{ boxSizing: "border-box", maxHeight: "400px", overflow: "auto" }}>
              {!groupList.length ? (
                <Box sx={{ backgroundColor: "rgba(249, 249, 250, 1)", borderRadius: 4, p: 1.5, width: "92%" }}>
                  <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="c1Caption">
                    Não há grupos a serem atualizados.
                  </LqdTypography>
                </Box>
              ) : (
                groupList.map((group) => (
                  <Box
                    key={group.code}
                    sx={{
                      ":last-child": { mb: 0 },
                      backgroundColor: "rgba(249, 249, 250, 1)",
                      borderRadius: 4,
                      display: "flex",
                      flexDirection: "column",
                      mb: 1.5,
                      p: 1.5,
                      width: "92%",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <LqdTypography color="rgba(56, 61, 70, 1)" sx={{ mb: 0.5, mr: 1 }} textstyle="c1Caption">
                        Nome do Grupo:
                      </LqdTypography>
                      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                        {group.name}
                      </LqdTypography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <LqdTypography color="rgba(56, 61, 70, 1)" sx={{ mr: 1 }} textstyle="c1Caption">
                        ID do Grupo:
                      </LqdTypography>
                      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                        {group.code}
                      </LqdTypography>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mt: "30px" }} textstyle="p2Paragraph">
              Excluir os usuários atualizará os grupos acima. Deseja continuar?
            </LqdTypography>
          </>
          <LqdDialogActions>
            <LqdButton disabled={isLoading} onClick={onCloseMethod} type="filledSecundary">
              Cancelar
            </LqdButton>
            <LqdButton disabled={isLoading} onClick={onDeleteMethod}>
              Confirmar exclusão
            </LqdButton>
          </LqdDialogActions>
        </>
      )}
    </LqdDialog>
  );
}
