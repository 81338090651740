import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ObjectOf } from "../../../../../../common/types/ObjectOf";
import PDFRatingLiquidA from "./RatingLiquid SVGs/PDFRatingLiquidA";
import PDFRatingLiquidB from "./RatingLiquid SVGs/PDFRatingLiquidB";
import PDFRatingLiquidC from "./RatingLiquid SVGs/PDFRatingLiquidC";
import PDFRatingLiquidD from "./RatingLiquid SVGs/PDFRatingLiquidD";
import PDFRatingLiquidE from "./RatingLiquid SVGs/PDFRatingLiquidE";
import PDFRatingLiquidF from "./RatingLiquid SVGs/PDFRatingLiquidF";
import PDFRatingLiquidG from "./RatingLiquid SVGs/PDFRatingLiquidG";
import PDFRatingLiquidH from "./RatingLiquid SVGs/PDFRatingLiquidH";

type PDFPeerModelProps = {
  rating: string;
};

const styles = StyleSheet.create({
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  ratingLiquidContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    height: "327px",
    marginBottom: "40px",
    marginTop: "40px",
    width: "100%",
  },
  ratingLiquidDescription: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingTop: "50px",
  },
  ratingLiquidText: {
    color: "rgb(33, 36, 42)",
    fontSize: "32px",
  },
  tableWithoutLabelTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingTop: "40px",
  },
});

const getSVG = (rating: string) => {
  switch (rating) {
    case "A":
      return <PDFRatingLiquidA />;
    case "B":
      return <PDFRatingLiquidB />;
    case "C":
      return <PDFRatingLiquidC />;
    case "D":
      return <PDFRatingLiquidD />;
    case "E":
      return <PDFRatingLiquidE />;
    case "F":
      return <PDFRatingLiquidF />;
    case "G":
      return <PDFRatingLiquidG />;
    case "H":
      return <PDFRatingLiquidH />;
  }
};

const contentDictionary: ObjectOf<string> = {
  A: "Baixa probabilidade de inadimplência",
  B: "Baixa probabilidade de inadimplência",
  C: "Probabilidade razoável de inadimplência",
  D: "Probabilidade razoável de inadimplência",
  E: "Alta probabilidade de inadimplência",
  F: "Alta probabilidade de inadimplência",
  G: "Alto risco de inadimplência",
  H: "Alto risco de inadimplência",
};

export default function PDFPeerModel(props: PDFPeerModelProps) {
  const { rating } = props;

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.tableWithoutLabelTitle}>Rating Liquid</Text>
      <View style={styles.ratingLiquidContainer}>
        <View style={styles.genericFlexRowContainer}>
          {getSVG(rating)}
          <View style={styles.descriptionContainer}>
            <Text style={styles.ratingLiquidDescription}>Descrição</Text>
            <Text style={styles.ratingLiquidText}>{contentDictionary[rating]}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
