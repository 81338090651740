import useWindowSize from "@/analysis-v3/src/features/common/hooks/useWindowSize";
import { normalizeText } from "@/analysis-v3/src/features/common/utils/normalizeText";
import { Stack } from "@mui/material";
import { useState } from "react";
import LqdSearch from "../../Search";
import LqdTypography from "../../Typography";
import { SelectOption } from "../types/SelectOption";
import LqdSelectOption from "./LqdSelectOption";

type LqdCompleteSelectProps = {
  onChange: (value: string) => void;
  options: Array<SelectOption>;
  searchPlaceholder: string;
  title: string;
  value: number | string;
};

export default function LqdCompleteSelect(props: LqdCompleteSelectProps) {
  const { onChange, options, searchPlaceholder, title, value } = props;

  const { width } = useWindowSize();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredOptions =
    searchTerm.length > 0
      ? options.filter((option) => normalizeText(option.label).includes(normalizeText(searchTerm)))
      : options;

  return (
    <Stack>
      <LqdTypography
        color="rgba(33, 36, 42, 1)"
        sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", pb: 1 }}
        textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
      >
        {title}
      </LqdTypography>
      <Stack sx={{ mt: 2, width: { sm: "96%", xs: "90%" } }}>
        <LqdSearch
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder={searchPlaceholder}
          value={searchTerm}
        />
      </Stack>
      <Stack
        sx={{
          "::-webkit-scrollbar": { height: "8px" },
          "::-webkit-scrollbar-track": { border: "none" },
          height: "300px",
          mt: 3,
          overflow: "auto",
          width: { md: "700px", xs: "350px" },
        }}
      >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <LqdSelectOption
              image={option.image || ""}
              isSelected={value === option.value}
              key={option.id}
              label={option.label}
              labelTitle={option.labelTitle || ""}
              onChange={onChange}
              searchTerm={searchTerm}
              secondaryLabel={option.secondaryLabel || ""}
              secondaryLabelTitle={option.secondaryLabelTitle || ""}
              value={option.value as string}
            />
          ))
        ) : (
          <Stack sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="h5Headline">
              Não foram encontradas opções com esse nome.
            </LqdTypography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
