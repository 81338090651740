import { LqdConnectionIcon, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { newAutomationChanged } from "../automationsSlice";
import AutomationConnectionProductCard from "./AutomationConnectionProductCard";
import AutomationSelect from "./AutomationSelect";

export default function AutomationConnectionCard() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { automationModules, automationProducts, newAutomationForm } = useAppSelector((state) => state.automations);
  const { tenantProducts } = useAppSelector((state) => state.products);

  const [selectedProductId, setSelectedProductId] = useState<string | null>(newAutomationForm?.product?.id || null);

  const selectedProduct = selectedProductId ? automationProducts[selectedProductId] : null;
  const selectedProductLabel = selectedProduct?.name || "Selecione o produto";

  // Atualiza o produto no newAutomationForm toda vez que um produto é selecionado no select
  useEffect(() => {
    if (selectedProductId) {
      dispatch(newAutomationChanged({ ...newAutomationForm!, product: automationProducts[selectedProductId] }));
    }
  }, [selectedProductId]);

  const productsToRender = Object.values(automationProducts).map((product) => {
    return {
      ...product,
      country: tenantProducts[product.id].country,
      currency: tenantProducts[product.id].currency,
      segment: tenantProducts[product.id].segment,
    };
  });

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{ backgroundColor: "rgba(249, 250, 251, 1)", borderRadius: "20px", pl: 2.5, pr: 8, py: 4 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "rgba(33, 36, 42, 1)",
          border: "1px solid rgba(56, 61, 70, 1)",
          borderRadius: "50%",
          display: { md: "flex", xs: "none" },
          height: "34px",
          width: "34px",
        }}
      >
        <LqdConnectionIcon color="rgba(255, 255, 255, 1)" size={20} />
      </Stack>
      <Box sx={{ flex: 1 }}>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="h4Headline">
          {t("Conexão")}
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 4 }} textstyle="c1Caption">
          {t("Criar nova conexão em um produto.")}
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
          {t("Conectar com")}
        </LqdTypography>
        <AutomationSelect
          fullWidth
          onChange={(event) => setSelectedProductId(event.target.value as string)}
          renderValue={() => (
            <LqdTypography color={selectedProduct ? "inherit" : "rgba(156, 163, 175, 1)"} textstyle="p2Paragraph">
              {selectedProductLabel}
            </LqdTypography>
          )}
          sx={{ mb: "10px" }}
          value={selectedProductId}
        >
          {productsToRender.length > 0 ? (
            productsToRender.map((product, index) => (
              <MenuItem key={product.id} sx={{ mt: index > 0 ? 3 : 0 }} value={product.id}>
                <AutomationConnectionProductCard product={product} selectedProduct={selectedProductId === product.id} />
              </MenuItem>
            ))
          ) : (
            <LqdTypography color="rgba(155, 162, 175, 1)">Não foram encontrados resultados</LqdTypography>
          )}
        </AutomationSelect>
        <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
          {automationModules[newAutomationForm!.origin_module].connection.connection_template}
        </LqdTypography>
      </Box>
    </Stack>
  );
}
