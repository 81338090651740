import { Box, Step, StepLabel, Stepper, SxProps } from "@mui/material";
import LqdCompletedStep from "./CompletedStep";
import LqdCurrentStep from "./CurrentStep";
import LqdIncompleteStep from "./IncompleteStep";

type LqdStepperProps = {
  currentStep: number;
  steps: Array<{ label: string }>;
  sx?: SxProps;
};

export default function LqdStepper(props: LqdStepperProps) {
  const { currentStep, steps, sx } = props;

  const renderStepIcon = (index: number) => {
    switch (true) {
      case index < currentStep:
        return LqdCompletedStep;
      case index === currentStep:
        return LqdCurrentStep;
      default:
        return LqdIncompleteStep;
    }
  };

  return (
    <Stepper sx={{ ...sx }}>
      {steps.map((step, index) => (
        <Step key={`${step.label}-${index}`}>
          <StepLabel StepIconComponent={renderStepIcon(index)} sx={{ ".MuiStepLabel-iconContainer": { padding: 0 } }}>
            {step.label ? <Box sx={{ px: 1 }}>{step.label}</Box> : null}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
