import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { SimulatorTesterEdges } from "@simulatorBuilder/types/SimulatorTesterEdges";

type createStyledEdgesAfterTestRunProps = {
  simulator: Simulator;
  testerResult: SimulatorTesterResultDBResponse;
};

/**
 * Cria arestas estilizadas para blocos não visitados em um Tester.
 *
 * Esta função itera através de um array de objetos `SimulatorFunctionBlock` e gera um array de objetos `SimulatorTesterEdges`.
 * Ela lida com saltos incondicionais e saltos condicionais entre blocos, estilizando as arestas com base em se um bloco está quebrado.
 *
 * @param {Array<SimulatorFunctionBlock>} blocks - Um array de blocos de função do simulador a serem processados.
 * @param {number | null} brokenBlock - O ID do bloco quebrado, ou null se não houver bloco quebrado.
 * @returns {Array<SimulatorTesterEdges>} Um array de arestas estilizadas representando as conexões entre blocos.
 */
const createStyledUnvisitedBlocks = (blocks: Array<SimulatorFunctionBlock>, brokenBlock: number | null) => {
  const edges: Array<SimulatorTesterEdges> = [];
  let nextBlockId = blocks[0]?.block_id;

  while (nextBlockId <= blocks[blocks.length - 1]?.block_id) {
    const currentBlock = blocks.find((block) => block.block_id === nextBlockId);

    if (!currentBlock) {
      nextBlockId += 1;
      continue;
    }

    const jumpFunction = currentBlock?.functions.find((f) => f.function_name === "jump");
    const jumpConditionalFunction = currentBlock?.functions.find((f) => f.function_name === "jump_conditional");

    if (jumpFunction) {
      const targetBlockId = jumpFunction.variables.block_id;

      edges.push({
        animated: true,
        id: `e${currentBlock.block_id}-${targetBlockId}`,
        source: `${currentBlock.block_id}`,
        style: {
          stroke:
            brokenBlock && currentBlock.block_id >= brokenBlock ? "rgba(246, 61, 94, 1)" : "rgba(127, 135, 152, 1)",
        },
        target: `${targetBlockId}`,
        type: "jump",
      });

      nextBlockId += 1;
      continue;
    }

    if (jumpConditionalFunction) {
      const targetBlocks = [
        jumpConditionalFunction.variables.block_true,
        jumpConditionalFunction.variables.block_false,
      ];

      targetBlocks.forEach((blockId) => {
        edges.push({
          animated: true,
          id: `e${currentBlock.block_id}-${blockId}`,
          source: `${currentBlock.block_id}`,
          style: {
            stroke:
              brokenBlock && currentBlock.block_id >= brokenBlock ? "rgba(246, 61, 94, 1)" : "rgba(127, 135, 152, 1)",
          },
          target: `${blockId}`,
          type: "jump_conditional",
        });
      });

      nextBlockId += 1;

      continue;
    }

    nextBlockId += 1;
    continue;
  }

  return edges;
};

/**
 * Cria arestas estilizadas após a execução do Tester.
 *
 * @param props - As propriedades necessárias para criar as arestas estilizadas.
 * @param props.simulator - O simulador contendo os blocos e funções.
 * @param props.testerResult - O resultado do teste contendo os passos e possíveis erros.
 * @returns Uma lista de arestas estilizadas para visualização do fluxo do simulador.
 */
export const createStyledEdgesAfterTestRun = (props: createStyledEdgesAfterTestRunProps) => {
  const { simulator, testerResult } = props;

  let unusedBlocks = simulator.blocks;
  let nextBlockId = simulator.blocks[0].block_id;

  const edges: Array<SimulatorTesterEdges> = [];
  const jumpConditionalUnusedBlock = [];

  const brokenBlock = testerResult.result?.error
    ? testerResult.result.steps[testerResult.result.steps.length - 1] || simulator.blocks[0]
    : null;

  while (nextBlockId < simulator.blocks.length) {
    const currentBlock = simulator.blocks.find((block) => block.block_id === nextBlockId);

    if (!currentBlock) {
      nextBlockId += 1;
      continue;
    }
    const jumpFunction = currentBlock?.functions.find((f) => f.function_name === "jump");
    const jumpConditionalFunction = currentBlock?.functions.find((f) => f.function_name === "jump_conditional");

    if (jumpFunction) {
      const targetBlockId = jumpFunction.variables.block_id;

      edges.push({
        id: `e${currentBlock.block_id}-${targetBlockId}`,
        source: `${currentBlock.block_id}`,
        style: {
          stroke:
            brokenBlock && currentBlock.block_id >= brokenBlock.block_id
              ? "rgba(246, 61, 94, 1)"
              : "rgba(127, 135, 152, 1)",
        },
        target: `${targetBlockId}`,
        type: "jump",
      });

      unusedBlocks = unusedBlocks.filter(
        (block) => block.block_id !== Number(targetBlockId) && block.block_id !== currentBlock.block_id
      );

      nextBlockId = Number(targetBlockId);
      continue;
    }

    if (jumpConditionalFunction) {
      const targetBlocks = [
        jumpConditionalFunction.variables.block_true,
        jumpConditionalFunction.variables.block_false,
      ];

      const booleanVariable = jumpConditionalFunction.variables.a;

      const booleanValue =
        testerResult.result?.results[booleanVariable as keyof typeof testerResult.result.results]?.toString();

      const handleBooleanValue = (i: number) => {
        if (booleanValue === "true" && i === 0) {
          return false;
        } else if (booleanValue === "false" && i === 1) {
          return false;
        } else {
          return true;
        }
      };

      targetBlocks.forEach((blockId, index: number) => {
        edges.push({
          animated: handleBooleanValue(index),
          id: `e${currentBlock.block_id}-${blockId}`,
          source: `${currentBlock.block_id}`,
          style: {
            stroke:
              brokenBlock && currentBlock.block_id >= brokenBlock.block_id && !handleBooleanValue(index)
                ? "rgba(246, 61, 94, 1)"
                : "rgba(127, 135, 152, 1)",
          },
          target: `${blockId}`,
          type: "jump_conditional",
        });
      });

      jumpConditionalUnusedBlock.push(booleanValue === "true" ? Number(targetBlocks[1]) : Number(targetBlocks[0]));

      unusedBlocks = unusedBlocks.filter((block) => {
        if (booleanValue === "true") {
          return block.block_id !== Number(targetBlocks[0]) && block.block_id !== currentBlock.block_id;
        }
        if (booleanValue === "false") {
          return block.block_id !== Number(targetBlocks[1]) && block.block_id !== currentBlock.block_id;
        }
      });

      nextBlockId = booleanValue === "true" ? Number(targetBlocks[0]) : Number(targetBlocks[1]);

      continue;
    }

    break;
  }

  if (unusedBlocks.length > 0) edges.push(...createStyledUnvisitedBlocks(unusedBlocks, brokenBlock?.block_id || null));

  return edges;
};

/**
 * Cria as arestas iniciais do Tester com base nos blocos do simulador.
 *
 * @param simulator - O objeto do simulador contendo os blocos e funções.
 * @returns Uma matriz de arestas do Tester.
 *
 * A função percorre os blocos do simulador e cria arestas com base nas funções "jump" e "jump_conditional".
 * Se um bloco contiver uma função "jump", uma aresta é criada para o bloco de destino especificado.
 * Se um bloco contiver uma função "jump_conditional", duas arestas são criadas para os blocos de destino
 * especificados para as condições verdadeira e falsa.
 */
export const createSimulatorTesterInitialEdges = (simulator: Simulator) => {
  const edges: Array<SimulatorTesterEdges> = [];

  if (simulator.blocks.length === 0) return edges;

  const jumpConditionalUnusedBlock = [];
  let nextBlockId = simulator.blocks[0].block_id;

  while (nextBlockId < simulator.blocks.length) {
    const currentBlock = simulator.blocks.find((block) => block.block_id === nextBlockId);

    if (!currentBlock) {
      nextBlockId += 1;
      continue;
    }

    const jumpFunction = currentBlock?.functions.find((f) => f.function_name === "jump");
    const jumpConditionalFunction = currentBlock?.functions.find((f) => f.function_name === "jump_conditional");

    if (jumpFunction) {
      const targetBlockId = jumpFunction.variables.block_id;

      edges.push({
        id: `e${currentBlock.block_id}-${targetBlockId}`,
        source: `${currentBlock.block_id}`,
        target: `${targetBlockId}`,
        type: "jump",
      });

      nextBlockId += 1;

      continue;
    }

    if (jumpConditionalFunction) {
      const targetBlocks = [
        jumpConditionalFunction.variables.block_true,
        jumpConditionalFunction.variables.block_false,
      ];

      targetBlocks.forEach((blockId) => {
        edges.push({
          id: `e${currentBlock.block_id}-${blockId}`,
          source: `${currentBlock.block_id}`,
          target: `${blockId}`,
          type: "jump_conditional",
        });
      });

      jumpConditionalUnusedBlock.push(Number(targetBlocks[0]), Number(targetBlocks[1]));

      nextBlockId += 1;

      continue;
    }

    nextBlockId += 1;
    continue;
  }

  return edges;
};
