import { Route } from "react-router-dom";
import RecoverPasswordRoute from "../features/recoverPassword/routes/recover-password";
import ExpiredTokenRoute from "../features/signin/routes/expired-token";
import FirstSignInRoute from "../features/signin/routes/first-signin";
import FirstSignInErrorRoute from "../features/signin/routes/first-signin-error";
import SignInRoute from "../features/signin/routes/signin";
import SignUpRoute from "../features/signup/routes/signup";

export default function PublicRoutes() {
  return (
    <>
      <Route element={<ExpiredTokenRoute />} path="token-expired" />
      <Route element={<FirstSignInErrorRoute />} path="first-signin-error" />
      <Route element={<FirstSignInRoute />} path="first-signin/:token" />
      <Route element={<RecoverPasswordRoute />} path="recover-password/:token" />
      <Route element={<SignInRoute />} path="signinadmin" />
      <Route element={<SignInRoute />} path="signin" />
      <Route element={<SignUpRoute />} path="signup" />
    </>
  );
}
