import { StyleSheet, View } from "@react-pdf/renderer";
import { OpenCreditProviderData } from "../../../../../types/AnalysisDetails/OpenCredit/OpenCreditProviderData";
import { formatValueToCurrency } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFMonetaryGeneralInfoCard from "../PDFMonetaryGeneralInfoCard";

type PDFOpenCreditCreditInfoContainerProps = {
  provider: OpenCreditProviderData;
};

export default function PDFOpenCreditCreditInfoContainer({ provider }: PDFOpenCreditCreditInfoContainerProps) {
  const styles = StyleSheet.create({
    generalInfoCardContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    generalInfoText: {
      color: "rgb(22, 53, 57)",
      fontSize: "24px",
      paddingBottom: "40px",
      paddingTop: "40px",
    },
  });

  return (
    <View minPresenceAhead={40}>
      <View style={styles.generalInfoCardContainer}>
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Limite de Crédito Total"
          value={formatValueToCurrency(provider.credit_limit_total_value, "BRL", true)}
        />
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Limite com vencimento até 360 dias"
          value={formatValueToCurrency(provider.credit_limit_until_360, "BRL", true)}
        />
        <PDFMonetaryGeneralInfoCard
          size="medium"
          title="Limite com vencimento acima de 360 dias"
          value={formatValueToCurrency(provider.credit_limit_above_360, "BRL", true)}
        />
      </View>
    </View>
  );
}
