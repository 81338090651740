import { LqdTypography } from "@/liquid-components/src";
import LqdConstantVariableIcon from "@/liquid-components/src/icons/ConstantVariable/ConstantVariable";
import LqdInputVariableIcon from "@/liquid-components/src/icons/InputVariable/InputVariable";
import LqdResultVariableIcon from "@/liquid-components/src/icons/ResultVariable/ResultVariable";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorTesterModalTab } from "@simulatorBuilder/types/SimulatorTesterModalTab";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { simulatorTesterHighlightVariables } from "./simulatorTesterHighlightVariables";

type SimulatorTesterLeftSideBarProps = {
  inputSearchData: SimulatorTesterSearchData;
  selectedTab: SimulatorTesterModalTab;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showSidebar: boolean;
  simulator: Simulator;
};

const variablesCategories = [
  ["constant", "Variáveis Constantes"],
  ["input", "Variáveis de Entrada"],
  ["result", "Variáveis de Resultado"],
];

/** Componente para renderizar a barra lateral esquerda do Tester.
 * @param inputSearchData - Dados usados para a funcionalidade de busca de entrada.
 * @param selectedTab - A aba atualmente selecionada no Tester.
 * @param setShowSidebar - Função para alternar a visibilidade da barra lateral.
 * @param showSidebar - Booleano indicando se a barra lateral está visível.
 * @param simulator - O objeto simulador contendo variáveis e outros dados.
 */
export default function SimulatorTesterLeftSideBar(props: SimulatorTesterLeftSideBarProps) {
  const { inputSearchData, selectedTab, setShowSidebar, showSidebar, simulator } = props;

  const dispatch = useAppDispatch();

  const { functionTemplates, variableTypes } = useAppSelector((state) => state.simulatorBuilder);

  const emptyVariablesBox = (
    <Box
      sx={{
        alignItems: "center",
        background: "rgba(249, 249, 250, 1)",
        borderRadius: 1.5,
        display: "flex",
        justifyContent: "center",
        mb: 2,
        mt: 1.5,
        padding: 1.5,
        textAlign: "center",
      }}
    >
      <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="l2Label">
        Ops. Você ainda não criou variáveis desse tipo.
      </LqdTypography>
    </Box>
  );

  const renderIcon = (variableCategory: string) => {
    switch (variableCategory) {
      case "input":
        return <LqdInputVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
      case "constant":
        return <LqdConstantVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
      case "result":
        return <LqdResultVariableIcon color="rgba(127, 135, 152, 1)" size={14} />;
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      {showSidebar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            maxHeight: "100%",
            overflowY: "auto",
            padding: "40px 20px",
            position: "relative",
            width: "259px",
          }}
        >
          {variablesCategories.map(([variableCategory, variableCategoryTitle], i) => (
            <Stack key={i} sx={{ display: "flex", flowDirection: "row" }}>
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{ alignItems: "center", display: "flex", gap: 1, marginBottom: 1 }}
                textstyle="l1Label"
              >
                {renderIcon(variableCategory)}
                {variableCategoryTitle}
              </LqdTypography>
              <Divider sx={{ color: "rgba(191, 196, 205, 0.24)" }} />
              <Stack>
                {simulator.variables.length
                  ? simulatorTesterHighlightVariables({
                      dispatch,
                      emptyVariablesBox,
                      functionTemplates,
                      inputSearchData,
                      simulator,
                      variableCategory,
                      variableTypes,
                    })
                  : emptyVariablesBox}
              </Stack>
            </Stack>
          ))}
        </Box>
      ) : null}
      <Box
        onClick={() => (selectedTab === SimulatorTesterModalTab.FLOW ? setShowSidebar(!showSidebar) : null)}
        sx={{
          "&:hover": { background: "rgb(233, 233, 233)" },
          alignItems: "center",
          backgroundColor: "rgba(236, 238, 240, 1)",
          cursor: "pointer",
          display: "flex",
          height: "100%",
          width: 16,
        }}
      >
        {showSidebar ? (
          <ArrowLeft sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        ) : (
          <ArrowRight sx={{ color: "rgba(127, 135, 152, 1)", fontSize: 16 }} />
        )}
      </Box>
    </Box>
  );
}
