import {
  LqdDownArrowIcon,
  LqdIconButton,
  LqdInput,
  LqdSelect,
  LqdToggle,
  LqdTrashIcon,
  LqdTypography,
  LqdUpArrowIcon,
} from "@/liquid-components/src";
import { Box, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import Proponent from "../../../types/Proponent";

type TargetFormProps = {
  hideDownButton: boolean;
  hideUpButton: boolean;
  multiple: boolean;
  onDeleteProponent: () => void;
  onMoveProponentDown: () => void;
  onMoveProponentUp: () => void;
  onUpdateProponent: (proponent: Proponent) => void;
  proponent: Proponent;
};

export default function TargetForm(props: TargetFormProps) {
  const {
    hideDownButton,
    hideUpButton,
    multiple,
    onDeleteProponent,
    onMoveProponentDown,
    onMoveProponentUp,
    onUpdateProponent,
    proponent,
  } = props;

  const [acceptMultiples, setAcceptMultiples] = useState(proponent.acceptMultiples);
  const [mandatory, setMandatory] = useState(proponent.mandatory);
  const [name, setName] = useState(proponent.name);
  const [type, setType] = useState(proponent.type);

  useEffect(() => {
    onUpdateProponent({ ...proponent, acceptMultiples, mandatory, name, type });
  }, [acceptMultiples, mandatory, name, type]);

  return (
    <Card elevation={0} sx={{ ":last-child": { mb: 0 }, borderRadius: "10px", mb: 3, padding: 1 }}>
      <CardContent>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1 }} textstyle="l2Label">
          Tipo
        </LqdTypography>
        <LqdSelect
          onChange={(value) => setType([value] as Array<"" | "cpf" | "cnpj">)}
          options={[
            { id: "1", label: "CNPJ", value: "cnpj" },
            { id: "2", label: "CPF", value: "cpf" },
          ]}
          placeholder="Selecione aqui"
          value={type[0]}
        />
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ my: 1 }} textstyle="l2Label">
          Nome
        </LqdTypography>
        <LqdInput boxSize="small" placeholderText="Ex: Pessoa, Empresa" setValue={setName} value={name} />
        <Box sx={{ display: "flex", my: 1 }}>
          <LqdToggle active={mandatory} onChange={(value) => setMandatory(value)} />
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ ml: 1 }} textstyle="buttonS">
            Obrigatório
          </LqdTypography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <LqdToggle active={acceptMultiples} onChange={(value) => setAcceptMultiples(value)} />
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ ml: 1 }} textstyle="buttonS">
            Aceita múltiplos
          </LqdTypography>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid rgba(240, 241, 243, 1)",
            display: "flex",
            justifyContent: multiple ? "space-between" : "flex-end",
            mt: 1,
            pt: 2,
          }}
        >
          {multiple ? (
            <Box>
              {!hideUpButton ? (
                <LqdIconButton onClick={onMoveProponentUp} sx={{ mr: 1 }} type="ghostPrimary">
                  <LqdUpArrowIcon />
                </LqdIconButton>
              ) : null}
              {!hideDownButton ? (
                <LqdIconButton onClick={onMoveProponentDown} type="ghostPrimary">
                  <LqdDownArrowIcon />
                </LqdIconButton>
              ) : null}
            </Box>
          ) : null}
          <LqdIconButton onClick={onDeleteProponent} type="ghostIcon">
            <LqdTrashIcon color="rgba(246, 61, 94, 1)" />
          </LqdIconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
