import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";
import { formatValueToCurrency } from "../../../../../../utils/analysisDetailsDataFormatter";

type PDFAllotmentSimulatorTableRow1Props = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  allotmentTableColumn: {
    width: "285px",
  },
  allotmentTableColumn3Row1Approve: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    paddingHorizontal: "12px",
    paddingVertical: "7px",
  },
  allotmentTableColumn3Row1Reprove: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  allotmentTableRow1Container: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    justifyContent: "space-between",
    width: "100%",
  },
  allotmentTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "18px",
    textAlign: "left",
  },
  allotmentTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
    textAlign: "left",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

export default function PDFAllotmentSimulatorTableRow1(props: PDFAllotmentSimulatorTableRow1Props) {
  const { highlight } = props;

  return (
    <View style={styles.allotmentTableRow1Container}>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Valor da proposta</Text>
          <Text style={styles.allotmentTableRowText}>
            {formatValueToCurrency(highlight.contract_value, "BRL", true)}
          </Text>
        </View>
      </View>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Valor da entrada</Text>
          <Text style={styles.allotmentTableRowText}>{formatValueToCurrency(highlight.down_payment, "BRL", true)}</Text>
        </View>
      </View>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Financiamento pretendido</Text>
          <Text style={styles.allotmentTableRowText}>
            {formatValueToCurrency(highlight.sec_loan_amount, "BRL", true)}
          </Text>
        </View>
      </View>
      <View style={styles.allotmentTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.allotmentTableRowTitle}>Composição das rendas</Text>
          <Text style={styles.allotmentTableRowText}>
            {formatValueToCurrency(highlight.monthly_income, "BRL", true)}
          </Text>
        </View>
      </View>
    </View>
  );
}
