import { AxiosPromise } from "axios";
import { deleteAxios } from "../../common/utils/apiUtils";

// Delete Simulator Parameters by Product (Superadmin Endpoint)
export const adminDeleteSimulatorParametersByProduct = (
  bankName: string,
  productId: string,
  tenantCode: string
): AxiosPromise => {
  return deleteAxios(
    `/admin/simulation/product?bank_name=${bankName}&product_id=${productId}&tenant_code=${tenantCode}`
  );
};
