import { ProductResponse } from "../types/ProductResponse";
import { ProductResponseRaw } from "../types/ProductResponseRaw";

export const formatRawProductSuperAdmin = (productRaw: ProductResponseRaw): ProductResponse => ({
  country: productRaw.country,
  creation: productRaw.creation * 1000,
  currency: productRaw.currency,
  image: productRaw.image,
  isActive: productRaw.is_active,
  name: productRaw.name,
  productId: productRaw.product_id,
  projectCompletionDate: productRaw.project_completion_date,
  segment: productRaw.segment,
  targetUser: productRaw.target_user,
  tenantCode: productRaw.tenant_code,
});
