import axios, { AxiosPromise } from "axios";
import { ProgressCallback } from "../../productBuilder/api/uploadProductPicture";
import { ObjectOf } from "../types/ObjectOf";

export const env = import.meta.env.MODE || "development";
export const REPOSITORY_TOKEN = import.meta.env.REPOSITORY_TOKEN || import.meta.env.VITE_REPOSITORY_TOKEN;
export const GO_SAFFE_KEY = import.meta.env.GO_SAFFE_KEY;
export const LOGIN_SECRET = import.meta.env.LOGIN_SECRET || import.meta.env.VITE_LOGIN_SECRET;
export const NEW_RELIC_API_KEY = import.meta.env.NEW_RELIC_API_KEY;
export const NEW_RELIC_APP_ID = import.meta.env.NEW_RELIC_APP_ID;

export const BASE_URL: ObjectOf<string> = {
  development: "https://api.dev.liquidpass.co",
  production: "https://api.liquidpass.co",
  staging: "https://api.stg.liquidpass.co",
};

export const WS_URL: ObjectOf<string> = {
  development: "wss://aveohhhvs6.execute-api.us-east-1.amazonaws.com/dev?access_token=",
  production: "wss://arvve7iiy2.execute-api.us-east-1.amazonaws.com/prod?access_token=",
  staging: "wss://agka9tg0j3.execute-api.us-east-1.amazonaws.com/stg?access_token=",
};

export const PRODUCT_IMG_BASE_URL: ObjectOf<string> = {
  development: "https://s3.amazonaws.com/app.dev.public-store/",
  production: "https://s3.amazonaws.com/app.prod.public-store/",
  staging: "https://s3.amazonaws.com/app.stg.public-store/",
};

export const get = <T>(
  route: string,
  options?: { headers?: ObjectOf<string>; params?: ObjectOf<boolean | number | string> }
): AxiosPromise<T> => {
  return axios({
    headers: options?.headers || { Authorization: "Bearer " + localStorage.getItem("access_token") || "" },
    method: "GET",
    params: options?.params || {},
    url: `${BASE_URL[env]}${route}`,
  });
};

export const post = <T>(
  route: string,
  data: ObjectOf<unknown>,
  customOptions?: { headers?: ObjectOf<string> } | ObjectOf<{ Authorization: string } | ProgressCallback | undefined>,
  baseUrl?: string
): AxiosPromise<T> => {
  return axios({
    data,
    headers: customOptions?.headers || { Authorization: "Bearer " + localStorage.getItem("access_token") || "" },
    method: "POST",
    url: baseUrl ? `${baseUrl}${route}` : `${BASE_URL[env]}${route}`,
    ...customOptions,
  } as unknown as ObjectOf<{ Authorization: string }>);
};

export const put = <T>(
  route: string,
  data: Array<ObjectOf<unknown>> | ObjectOf<unknown> | null,
  customOptions?: ObjectOf<unknown>
): AxiosPromise<T> => {
  return axios({
    data,
    headers: customOptions?.headers || { Authorization: "Bearer " + localStorage.getItem("access_token") || "" },
    method: "PUT",
    url: `${BASE_URL[env]}${route}`,
    ...customOptions,
  } as unknown as ObjectOf<{ Authorization: string }>);
};

export const deleteAxios = <T>(route: string, customOptions?: ObjectOf<{ Authorization: string }>): AxiosPromise<T> => {
  return axios({
    headers: customOptions?.headers || { Authorization: "Bearer " + localStorage.getItem("access_token") || "" },
    method: "DELETE",
    url: `${BASE_URL[env]}${route}`,
    ...customOptions,
  });
};

export const getNewAccessToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const getTokenRes = await post<{ access_token: string }>("/auth/refresh-token", { refresh_token: refreshToken });
  const accessToken = getTokenRes.data.access_token;
  localStorage.setItem("access_token", accessToken);

  return accessToken;
};
