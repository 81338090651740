import { LqdCircularLoader, LqdTypography } from "@/liquid-components/src";

export default function OpenCreditLoadingState() {
  return (
    <>
      <LqdCircularLoader size={80} />
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", py: 2.5, textAlign: "center" }} textstyle="h5Headline">
        Carregando informações...
      </LqdTypography>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", textAlign: "center" }}>
        Enquanto carregamos suas informações, não feche a página.
      </LqdTypography>
    </>
  );
}
