import { getAnalysisFinancialReportData } from "@account/api/GetAnalysisFinancialReportData";
import { getOpenCreditReportData } from "@account/api/GetOpenCreditReportData";
import { getScheduledFinancialReportData } from "@account/api/GetScheduledFinancialReport";
import { FormattedAnalysisReportData } from "@account/types/FormattedAnalysisReportData";
import { FormattedOpenCreditReportData } from "@account/types/FormattedOpenCreditReportData";
import { formatReportData, getCSVData, reportCustomHeaders } from "@account/utils/formatCSV";
import {
  getOpenCreditCSVData,
  openCreditFormatReportData,
  reportOpenCreditCustomHeaders,
} from "@account/utils/formatOpenCreditCSV";
import { dialogCalled, dialogLoaded, setBlank } from "@common/commonSlice";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import Papa from "papaparse";
import { useAppDispatch } from "../../../store";

const BYTE_ORDER_MARK = "\uFEFF";

export const useGenerateReport = () => {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  /** Gera o relatório de Open Credit em CSV.
   * @param startDate Data de início do relatório.
   * @param endDate Data de fim do relatório.
   */
  const openCreditCSVReport = async (startDate: string, endDate: string) => {
    try {
      const openCreditReportDataRaw = await getOpenCreditReportData(startDate, endDate);
      const openCreditReportData = openCreditReportDataRaw.data;

      const customHeaders = [...reportOpenCreditCustomHeaders];
      const formattedDataList: Array<FormattedOpenCreditReportData> = [];
      const formattedReportData = openCreditFormatReportData(openCreditReportData, formattedDataList);

      const reportCSV = Papa.unparse(getOpenCreditCSVData(formattedReportData), {
        columns: customHeaders,
        delimiter: ";",
      });

      const blob = new Blob([BYTE_ORDER_MARK + reportCSV], { type: "text/csv;charset=utf-8;" });
      const downloadURL = URL.createObjectURL(blob);
      const downloadElement = document.createElement("a");
      downloadElement.setAttribute("href", downloadURL);
      downloadElement.setAttribute("download", `Relatório Open Credit Liquid - ${startDate} a ${endDate}.csv`);

      downloadElement.click();
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
      dispatch(dialogLoaded(false));
    }
  };

  /** Gera o relatório de consumo regular em CSV.
   * @param startDate Data de início do relatório.
   * @param endDate Data de fim do relatório.
   * @param isScheduled Indica se o relatório é de consumo (período de um mês; nesse caso, true) ou personalizado.
   * @param interval Intervalo de datas do relatório agendado.
   */
  const regularCSVReport = async (startDate: string, endDate: string, isScheduled: boolean, interval: string) => {
    let maxPeers = 0;
    const customHeaders = [...reportCustomHeaders];

    try {
      const analysisReportDataRaw = isScheduled
        ? await getScheduledFinancialReportData(interval)
        : await getAnalysisFinancialReportData(startDate, endDate);
      const analysisReportData = analysisReportDataRaw.data;

      for (let i = 0; i < analysisReportData.length; i += 1) {
        if (analysisReportData[i].peers.length > maxPeers) {
          maxPeers = analysisReportData[i].peers.length;
        }
      }

      for (let i = 0; i < maxPeers; i += 1) {
        customHeaders.push(`PEER ${i + 1} PASS ID`);
        customHeaders.push(`PEER ${i + 1} NOME`);
        customHeaders.push(`PEER ${i + 1} DOCUMENTO`);
        customHeaders.push(`PEER ${i + 1} RENDA`);
        customHeaders.push(`PEER ${i + 1} RENDA ESTIMADA`);
        customHeaders.push(`PEER ${i + 1} CAPAC. PAGTO. MENSAL ESTIMADA`);
        customHeaders.push(`PEER ${i + 1} COMPROMETIMENTO RENDA ESTIMADA`);
        customHeaders.push(`PEER ${i + 1} PARTICIPAÇÕES SOCIETÁRIAS`);
        customHeaders.push(`PEER ${i + 1} SCORE`);
        customHeaders.push(`PEER ${i + 1} RATING LIQUID`);
        customHeaders.push(`PEER ${i + 1} CPF REGULAR`);
        customHeaders.push(`PEER ${i + 1} RESTRIÇÕES`);
        customHeaders.push(`PEER ${i + 1} QTD. RESTRIÇÕES`);
        customHeaders.push(`PEER ${i + 1} VALOR RESTRIÇÕES`);
      }

      const formattedDataList: Array<FormattedAnalysisReportData> = [];
      const formattedReportData = formatReportData(analysisReportData, maxPeers, formattedDataList);

      const reportCSV = Papa.unparse(getCSVData(formattedReportData, maxPeers), {
        columns: customHeaders,
        delimiter: ";",
      });

      const blob = new Blob([BYTE_ORDER_MARK + reportCSV], { type: "text/csv;charset=utf-8;" });
      const downloadURL = URL.createObjectURL(blob);
      const downloadElement = document.createElement("a");
      downloadElement.setAttribute("href", downloadURL);

      if (isScheduled) {
        dispatch(setBlank(true));
        downloadElement.setAttribute("download", `Relatório Liquid - ${interval}.csv`);
      } else {
        downloadElement.setAttribute("download", `Relatório Liquid - ${startDate} a ${endDate}.csv`);
      }

      downloadElement.click();
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
      dispatch(dialogLoaded(false));
    }
  };

  return { openCreditCSVReport, regularCSVReport };
};
