import { highlightText } from "@/analysis-v3/src/features/common/utils/highlightText";
import { simulatorBuilderVariableSelected } from "@/analysis-v3/src/features/simulatorBuilder/simulatorBuilderSlice";
import { useAppDispatch } from "@/analysis-v3/src/store";
import { LqdDownIcon } from "@/liquid-components/src/icons";
import { Autocomplete, Box, Paper, Stack, TextField } from "@mui/material";
import { cloneElement } from "react";
import LqdSelectMenuItem from "../../SelectMenuItem";
import LqdTypography from "../../Typography";
import { SelectOption } from "../types/SelectOption";

type LqdStandardSelectProps = {
  editButton?: JSX.Element;
  functionKey?: string;
  handleInputChange: (event: React.SyntheticEvent<Element, Event> | null, newInputValue: string) => void;
  handleSelect: (newValue: SelectOption | null) => void;
  inputValue: string;
  isOpen: boolean;
  isTyping: boolean;
  newOptionButton?: JSX.Element | null;
  options: Array<SelectOption>;
  placeholder?: string;
  setIsOpen: (value: boolean) => void;
  value: number | string;
};

export default function LqdStandardSelect(props: LqdStandardSelectProps) {
  const {
    editButton,
    functionKey,
    handleInputChange,
    handleSelect,
    inputValue,
    isOpen,
    isTyping,
    newOptionButton,
    options,
    placeholder,
    setIsOpen,
    value,
  } = props;

  const dispatch = useAppDispatch();

  return (
    <>
      <Autocomplete
        ListboxProps={{ sx: { ".MuiMenuItem-root.Mui-selected": { backgroundColor: "transparent" }, p: 0 } }}
        noOptionsText={
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ ml: 1 }} textstyle="p2Paragraph">
            Nenhuma opção encontrada com este nome.
          </LqdTypography>
        }
        onChange={(_, newValue) => handleSelect(newValue)}
        onClose={() => setIsOpen(false)}
        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
        onOpen={() => setIsOpen(true)}
        open={isOpen}
        options={options}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              border: "1px solid rgba(217, 219, 233, 1)",
              borderRadius: "8px",
              boxShadow: "0px 25px 60px -15px rgba(16, 24, 40, 0.2)",
              boxSizing: "border-box",
              maxHeight: "400px",
              mt: 1,
              p: 1,
            }}
          >
            {children}
          </Paper>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: <LqdDownIcon />,
              sx: {
                ".MuiInput-input": { height: "48px !important", p: 0 },
                "::after": { display: "none" },
                "::before": { display: "none" },
                ":focus": { borderColor: "rgba(127, 135, 152, 0.5)" },
                ":hover": { borderColor: "rgba(127, 135, 152, 0.5)" },
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(217, 219, 233, 1)",
                borderRadius: "8px",
                color: "rgba(56, 61, 70, 1)",
                display: "flex",
                pl: "12px !important",
                svg: { pointerEvents: "none", position: "absolute", right: "12px" },
              },
            }}
            placeholder={placeholder}
            value={value}
            variant="standard"
          />
        )}
        renderOption={(props, option) => (
          <LqdSelectMenuItem {...props} key={option.value} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ height: "20px", py: 1, width: "100%" }}
            >
              <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="p2Paragraph">
                {isTyping ? highlightText(option.label, inputValue) : option.label}
              </LqdTypography>
              {editButton && option.category === "constant"
                ? cloneElement(editButton as JSX.Element, {
                    onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      if (editButton.props.onClick) {
                        editButton.props.onClick(event);
                      }
                      dispatch(simulatorBuilderVariableSelected({ functionKey: functionKey!, id: option.id! }));
                    },
                  })
                : null}
            </Stack>
          </LqdSelectMenuItem>
        )}
        sx={{ width: "100%" }}
        value={options.find((option) => option.value === value) || null}
      />
      {newOptionButton ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>{newOptionButton}</Box>
      ) : null}
    </>
  );
}
