import { LqdIconButton, LqdPlusIcon, LqdTypography, LqdViewerIcon } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

type SimulatorResultFormsContainerProps = {
  addNewComponent?: () => void;
  children: ReactNode;
  hideActionButton?: boolean;
  subtitle: string;
  title: string;
};

export default function SimulatorResultFormsContainer(props: SimulatorResultFormsContainerProps) {
  const { addNewComponent, children, hideActionButton = false, subtitle, title } = props;

  return (
    <Stack
      direction="column"
      gap={1.5}
      sx={{ backgroundColor: "rgba(249, 249, 250, 1)", borderRadius: "20px", p: 3.5, width: "-webkit-fill-available" }}
    >
      <Stack direction="row" spacing={1.5}>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(177, 227, 255, 1)",
            borderRadius: "50%",
            display: "flex",
            height: "34px",
            justifyContent: "center",
            width: "34px",
          }}
        >
          <LqdViewerIcon color="rgba(33, 36, 42, 1)" size={18} />
        </Box>
        <Stack direction="column" gap={0.5}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h4Headline">
            {title}
          </LqdTypography>
          <LqdTypography color="rgba(156, 163, 175, 1)" sx={{ lineHeight: "16px" }} textstyle="p2Paragraph">
            {subtitle}
          </LqdTypography>
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={1.5} sx={{ pl: 5.25 }} useFlexGap>
        {children}
        <LqdIconButton
          onClick={addNewComponent}
          round="true"
          sx={{
            alignItems: "center",
            display: hideActionButton ? "none" : "flex",
            height: "36px",
            justifyContent: "center",
            width: "36px",
          }}
        >
          <LqdPlusIcon color="rgba(255, 255, 255, 1)" />
        </LqdIconButton>
      </Stack>
    </Stack>
  );
}
