import { getUser } from "../../users/utils/getUser";
import { TenantResponse } from "../types/TenantResponse";
import { TenantResponseRaw } from "../types/TenantResponseRaw";

export const formatRawTenantSuperAdmin = (tenantRaw: TenantResponseRaw): TenantResponse => {
  const users = tenantRaw.users.map((user) => getUser(user));

  return {
    autoArchive: {
      createdFrom: tenantRaw.auto_archive?.created_from,
      finishedFrom: tenantRaw.auto_archive?.finished_from,
      status: tenantRaw.auto_archive?.status,
    },
    businessActivity: tenantRaw.business_activity,
    code: tenantRaw.code,
    contact: {
      email: tenantRaw.contact.email,
      name: tenantRaw.contact.name,
      phone: tenantRaw.contact.phone,
    },
    country: tenantRaw.country,
    createdAt: tenantRaw.created_at * 1000,
    document: {
      type: tenantRaw.document.type,
      value: tenantRaw.document.value,
    },
    inviteSent: tenantRaw.invite_sent,
    isBlocked: tenantRaw.is_blocked,
    lastUpdate: tenantRaw.last_update * 1000,
    mfaActive: tenantRaw.mfa_active,
    newContractsPerMonth: tenantRaw.new_contracts_per_month,
    providers: tenantRaw.providers,
    segments: tenantRaw.segments,
    users: users,
  };
};
