import { LqdIconButton, LqdTextField, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

type AnalysisInputProps = {
  analysisIndex: number;
  inputValue: string;
  onChangeMethod: (value: string) => void;
  onRemoveMethod: () => void;
  placeholder?: string;
};

export default function AnalysisInput(props: AnalysisInputProps) {
  const { analysisIndex, inputValue, onChangeMethod, onRemoveMethod, placeholder } = props;

  return (
    <Box id="lqd-analysis-deletion-id-input" sx={{ height: "110px", width: "400px" }}>
      <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mr: 0.5, width: "fit-content" }} textstyle="p2Paragraph">
          ID da Conexão
          <LqdTypography color="rgba(246, 61, 94, 1)" sx={{ display: "inline-block" }} textstyle="p2Paragraph">
            *
          </LqdTypography>
        </LqdTypography>
        {analysisIndex >= 1 ? (
          <LqdIconButton onClick={onRemoveMethod} sx={{ ml: 1.5 }} type="ghostIcon">
            <LqdTrashIcon />
          </LqdIconButton>
        ) : null}
      </Box>
      <LqdTextField
        inputProps={{ sx: { color: "rgba(155, 162, 175, 1)", fontSize: "16px", fontWeight: 400, lineHeight: "24px" } }}
        onChange={(event) => onChangeMethod(event.target.value)}
        placeholder={placeholder}
        value={inputValue}
      />
    </Box>
  );
}
