import {
  LqdButton,
  LqdHomeIcon,
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdTypography,
  LqdWorldIcon,
} from "@/liquid-components/src";
import { Box, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store";

import { createTemplateModalStepPrev } from "../../../../adminSlice";

type TemplateFormStep4HeaderProps = {
  existingChanges?: boolean;
  isLoading: boolean;
  onGoBack: () => void;
  onSubmit: () => void;
  templateCountry: string;
  templateCurrency: string;
  templateSegments: Array<string>;
  templateTitle: string;
};

export default function TemplateFormStep4Header(props: TemplateFormStep4HeaderProps) {
  const {
    existingChanges,
    isLoading,
    onGoBack,
    onSubmit,
    templateCountry,
    templateCurrency,
    templateSegments,
    templateTitle,
  } = props;

  const dispatch = useAppDispatch();
  const { createTemplate, segments, templateToEdit } = useAppSelector((state) => state.admin);
  const theme = useTheme();

  const handleGoBack = () => {
    if (createTemplate) {
      dispatch(createTemplateModalStepPrev());
    } else {
      onGoBack();
    }
  };

  let shouldDisableSaveButton = existingChanges ? isLoading : true;
  if (createTemplate) {
    shouldDisableSaveButton = false;
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        backdropFilter: "blur(80px)",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderBottom: "1px solid rgba(127, 135, 152, 0.08)",
        boxSizing: "border-box",
        display: "flex",
        height: "96px",
        justifyContent: "space-between",
        opacity: 0.7,
        position: "absolute",
        top: 0,
        width: "97.5vw",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <LqdIconButton onClick={handleGoBack} round="true" sx={{ mr: 2 }} type="outlinePrimary">
          <LqdLeftArrowIcon />
        </LqdIconButton>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "inline-block", mr: 1 }} textstyle="h5Headline">
          {templateTitle}
        </LqdTypography>
        <Box sx={{ display: "flex", pl: 7, pt: templateToEdit ? 1 : 0 }}>
          <LqdWorldIcon color={theme.palette.text.secondary} />
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ ml: 1 }} textstyle="p2Paragraph">
            {`${templateCountry}/${templateCurrency}`}
          </LqdTypography>
        </Box>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", pl: 3 }}>
          <LqdHomeIcon color={theme.palette.text.secondary} />
          <Box sx={{ display: "flex", flexWrap: "", gap: "10px", maxHeight: "80px", ml: 0.75 }}>
            {templateSegments.map((segmentId, index) => (
              <LqdTypography
                color="rgba(127, 135, 152, 1)"
                key={segmentId}
                sx={{ ml: index > 0 ? 1 : 0 }}
                textstyle="p2Paragraph"
              >
                {segments[segmentId].name}
              </LqdTypography>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <LqdButton
          disabled={shouldDisableSaveButton}
          onClick={onSubmit}
          sx={{ ml: 2, mr: templateToEdit ? 5 : 0, mt: templateToEdit ? 1 : 0 }}
        >
          Salvar
        </LqdButton>
      </Box>
    </Box>
  );
}
