import { ToastNotification } from "./ToastNotification";

type HorizontalToastOptions = "center" | "left" | "right";
type VerticalToastOptions = "bottom" | "top";

export type AppErrorOptions = {
  horizontal?: HorizontalToastOptions;
  vertical?: VerticalToastOptions;
};

export class AppError extends Error {
  constructor(
    {
      duration,
      message,
      type,
    }: Omit<ToastNotification, "type"> & {
      type?: "error" | "info" | "success" | "warning";
    },
    options?: AppErrorOptions
  ) {
    super(message);
    const data = {
      duration,
      message: message || "",
      type: type || "error",
    };
    this.data = data;
    if (options) {
      this.options = options;
    }
  }

  data: ToastNotification;
  options?: AppErrorOptions;
}
