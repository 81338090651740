import { LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { newAutomationFormFilterUpdated } from "../automationsSlice";
import { automationFilterDictionary } from "../helpers/automationFilterDictionary";
import { AutomationFilter } from "../types/AutomationFilter";
import { AutomationModule } from "../types/AutomationModule";
import AutomationSelect from "./AutomationSelect";

type AutomationFilterFormProps = {
  actionIndex: number;
  filter: AutomationFilter;
  filterIndex: number;
  onRemoveClick: () => void;
  template: AutomationModule;
};

export default function AutomationFilterForm(props: AutomationFilterFormProps) {
  const { actionIndex, filter, filterIndex, onRemoveClick, template } = props;
  const { selectedAutomation } = useAppSelector((state) => state.automations);

  const dispatch = useAppDispatch();

  const filterConditionChanged = (condition: "==" | "!=") => {
    const updatedFilter: AutomationFilter = {
      ...filter,
      condition,
    };
    dispatch(newAutomationFormFilterUpdated({ actionIndex, filter: updatedFilter, filterIndex }));
  };

  const filterValueChanged = (value: "match" | "unmatch" | "moderation") => {
    const updatedFilter: AutomationFilter = {
      ...filter,
      value,
    };
    dispatch(newAutomationFormFilterUpdated({ actionIndex, filter: updatedFilter, filterIndex }));
  };

  const filterKeyChanged = (key: string) => {
    const updatedFilter: AutomationFilter = {
      ...filter,
      key,
    };
    dispatch(newAutomationFormFilterUpdated({ actionIndex, filter: updatedFilter, filterIndex }));
  };

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
        <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
          {filterIndex ? "E se" : "Se"}{" "}
          <span style={{ color: "rgba(56, 61, 70, 1)" }}>{selectedAutomation?.product?.name}</span>
        </LqdTypography>
        <Stack
          alignItems="center"
          justifyContent="center"
          onClick={onRemoveClick}
          sx={{
            border: "1px solid rgba(155, 162, 175, 1)",
            borderRadius: "50%",
            boxSizing: "border-box",
            cursor: "pointer",
            height: "24px",
            width: "24px",
          }}
        >
          <LqdTrashIcon color="rgba(155, 162, 175, 1)" size={12} />
        </Stack>
      </Stack>
      <AutomationSelect
        fullWidth
        onChange={({ target: { value } }) => filterKeyChanged(value as string)}
        renderValue={() => (filter.key ? automationFilterDictionary[filter.key] : "Selecionar indicador")}
        value={filter.key}
      >
        <MenuItem value="status">Resultado da conexão</MenuItem>
      </AutomationSelect>
      <Box sx={{ mb: 3, mt: 1.5, pl: "42px" }}>
        <Grid container spacing={4} sx={{ pt: 1 }}>
          <Grid item md={6}>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
              Condição
            </LqdTypography>
            <AutomationSelect
              fullWidth
              onChange={(event) => filterConditionChanged(event.target.value as "==" | "!=")}
              renderValue={() =>
                filter.condition ? automationFilterDictionary[filter.condition] : "Selecionar condição"
              }
              value={filter.condition}
            >
              {template.action.filters[0].conditions.map((condition) => (
                <MenuItem key={condition} value={condition}>
                  {automationFilterDictionary[condition]}
                </MenuItem>
              ))}
            </AutomationSelect>
          </Grid>
          <Grid item md={6}>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
              Valor
            </LqdTypography>
            <AutomationSelect
              fullWidth
              onChange={(event) => filterValueChanged(event.target.value as "match" | "unmatch")}
              renderValue={() => (filter.value ? automationFilterDictionary[filter.value] : "Selecionar valor")}
              value={filter.value}
            >
              {template.action.filters[0].values.map((value) => (
                <MenuItem key={value} value={value}>
                  {automationFilterDictionary[value]}
                </MenuItem>
              ))}
            </AutomationSelect>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
