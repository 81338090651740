import { OmittedTargetUserRawNode } from "@productBuilder/types/RawNode";
import { useAppSelector } from "../../../store";
import { retrieveDashboardOptions } from "../utils/retrieveDashboardOptions";

/** Recupera os dados da store relacionados aos detalhes da conexão. */
export default function useAnalysisDetails() {
  const {
    analysisDashboardSelectedOption,
    analysisDetails,
    analysisLastRun,
    analysisPendencies,
    analysisSteps,
    productTargets,
    trustpadCurrentView,
  } = useAppSelector((state) => state.analysisDetails);

  const analysisDetailsValid = analysisDetails!;

  const mappedProductTargets: Array<OmittedTargetUserRawNode> = productTargets!.map((info) => ({
    id: info.id,
    multiple: info.multiple,
    origin: info.origin,
    required: info.required,
    title: info.title,
  }));

  const selectedPeer = analysisDetailsValid.peers[analysisDashboardSelectedOption.type];
  const selectedPeerProviderData = analysisDetailsValid.result.bureau
    ? analysisDetailsValid.result.bureau[analysisDashboardSelectedOption.type]
    : null;

  const analysisPeers = Object.values(analysisDetailsValid.peers);
  const simulations = analysisDetailsValid.result.simulations;

  const { analysisDashboardOptions, peerOptions, simulatorOptions } = retrieveDashboardOptions(
    analysisPeers,
    simulations
  );

  return {
    analysisDashboardOptions,
    analysisDashboardSelectedOption,
    analysisDetails: analysisDetailsValid,
    analysisLastRun,
    analysisPeers,
    analysisPendencies,
    analysisSteps,
    peerOptions,
    productTargets: mappedProductTargets,
    selectedPeer,
    selectedPeerProviderData,
    simulatorOptions,
    trustpadCurrentView,
  };
}
