import ActionNodeCustomData from "../types/ActionNodeCustomData";
import NodeCategory from "../types/NodeCategory";
import NodeData, { ActionNodeData, ProviderNodeData, StartFlowNodeData } from "../types/NodeData";
import { getNodesList } from "./getNodesList";

interface VariantTranslations {
  [key: string]: { subtitle: string; title: string };
}

const getCategoryTitle = (type: string) => {
  switch (type) {
    case "start_flow":
      return "Start Flow";
    case "providers":
      return "Providers";
    case "action":
      return "Actions";
    default:
      return "No title";
  }
};

const buildNode = (variant: NodeData): NodeData => {
  const providerNodeData = variant as ProviderNodeData;
  if (providerNodeData.provider) {
    const options = providerNodeData.options;

    return {
      ...providerNodeData,
      custom_data: {
        conditions: [],
        selectedKpi: null,
      },
      options,
      type: (variant.type || "providers") as "providers" | "global",
    };
  }

  const actionNodeData = variant as ActionNodeData;
  if (actionNodeData.action) {
    const custom_data: ActionNodeCustomData = {};

    if (actionNodeData.action === "jump") {
      custom_data.jumpToProduct = null;
    }

    if (actionNodeData.action === "warning") {
      custom_data.warningMessage = "";
    }

    return {
      ...actionNodeData,
      custom_data,
      type: "action",
    };
  }

  if ((variant as StartFlowNodeData).type === "start_flow") {
    return {
      ...variant,
      type: "start_flow",
    };
  }

  throw new Error("buildNode: could not identify node variant.type!");
};

const variantTranslations: VariantTranslations = {
  match: {
    subtitle: "Usuário deu match com o produto",
    title: "Aprovado",
  },
  moderation: {
    subtitle: "É hora de decisão humana",
    title: "Moderação",
  },
  unmatch: {
    subtitle: "Usuário não deu match com o produto",
    title: "Reprovado",
  },
  warning: {
    subtitle: "Exibir a mensagem:",
    title: "Atenção",
  },
};

const getNodeCategories = async () => {
  const getNodeCategoriesRes = await getNodesList();
  const nodeCategoriesDescription = getNodeCategoriesRes.data.data;

  const translatedNodeCategories = nodeCategoriesDescription.map((nodeCategoryDesc) => {
    if (nodeCategoryDesc.type === "action") {
      return {
        ...nodeCategoryDesc,
        variants: nodeCategoryDesc.variants.map((variant: NodeData) => {
          const actionVariant = variant as ActionNodeData;
          const translationValues = variantTranslations[actionVariant.action];
          return translationValues ? { ...variant, ...translationValues } : variant;
        }),
      };
    }
    return nodeCategoryDesc;
  });

  const nodeCategories = [] as Array<NodeCategory>;
  for (const nodeCategoryDesc of translatedNodeCategories) {
    const nodes: Array<NodeData> = [];
    for (const variant of nodeCategoryDesc.variants) {
      const node = buildNode(variant);
      if (node) {
        nodes.push(node);
      }
    }
    const nodeCategory: NodeCategory = {
      nodes,
      title: getCategoryTitle(nodeCategoryDesc.type),
      type: nodeCategoryDesc.type,
    };
    nodeCategories.push(nodeCategory);
  }
  return nodeCategories;
};

export default getNodeCategories;
