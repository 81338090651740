import { AnalysisStepsResponseRaw } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";
import { delayedGetSteps } from "./delayedGetSteps";

/** Tenta buscar os steps de uma conexão com um delay entre as tentativas.
 * @param analysisId Id da conexão.
 * @returns Steps da conexão.
 */
export const getStepsWithDelayedRetry = async (analysisId: string): Promise<AnalysisStepsResponseRaw | null> => {
  const GET_STEPS_RETRY_LIMIT = 40;
  let retryCount = 0;
  let rawSteps: AnalysisStepsResponseRaw | null;

  do {
    rawSteps = await delayedGetSteps(analysisId, retryCount === 0 ? 0 : 3000);
    retryCount++;
  } while (!rawSteps && retryCount <= GET_STEPS_RETRY_LIMIT);

  return rawSteps;
};
