import { Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

type PDFTrustpadPeerCircularSVGProps = {
  backgroundColor: string;
  iconColor: string;
  peerType: string;
};

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    marginRight: "18px",
    maxHeight: "100px",
    maxWidth: "100px",
  },
});

export default function PDFTrustpadPeerCircularSVG(props: PDFTrustpadPeerCircularSVGProps) {
  const { backgroundColor, iconColor, peerType } = props;

  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 107 108">
        <Path
          d="M107 53.8479C107 94.823 62.3466 120.863 26.68 100.322C10.1665 90.8117 0 73.1234 0 54.1521C0 13.3712 44.273 -12.6928 79.9385 7.46022C96.6667 16.9126 107 34.7203 107 53.8479Z"
          fill={backgroundColor}
        />
        <Path
          d="M53 93.5C75.0914 93.5 93 75.5914 93 53.5C93 31.4086 75.0914 13.5 53 13.5C30.9086 13.5 13 31.4086 13 53.5C13 75.5914 30.9086 93.5 53 93.5Z"
          fill="rgb(255, 255, 255)"
        />
        {peerType === "CPF" ? (
          <>
            <Path
              d="M41.332 66.8333C41.332 62.6667 44.6654 59.5 48.6654 59.5H57.1654C61.332 59.5 64.4987 62.8333 64.4987 66.8333"
              stroke={iconColor}
              strokeLineCap="round"
              strokeWidth="1.2"
            />
            <Path
              d="M58.0005 42.1665C60.8338 44.9998 60.8338 49.4998 58.0005 52.1665C55.1671 54.8332 50.6671 54.9998 48.0005 52.1665C45.3338 49.3332 45.1671 44.8332 48.0005 42.1665C50.8338 39.4998 55.1671 39.4998 58.0005 42.1665"
              stroke={iconColor}
              strokeLineCap="round"
              strokeWidth="1.2"
            />
          </>
        ) : (
          <>
            <Path
              d="M42.9987 48H49.6654C50.5858 48 51.332 48.7462 51.332 49.6667V66.3333H41.332V49.6667C41.332 48.7462 42.0782 48 42.9987 48Z"
              fillRule="evenodd"
              stroke={iconColor}
              strokeLineCap="round"
              strokeWidth="1.2"
            />
            <Path
              d="M52.9987 39.6665H62.9987C63.9192 39.6665 64.6654 40.4127 64.6654 41.3332V66.3332H51.332V41.3332C51.332 40.4127 52.0782 39.6665 52.9987 39.6665Z"
              fillRule="evenodd"
              stroke={iconColor}
              strokeLineCap="round"
              strokeWidth="1.2"
            />
            <Path d="M68 66.3333H38" stroke={iconColor} strokeLineCap="round" strokeWidth="1.2" />
          </>
        )}
      </Svg>
    </View>
  );
}
