import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Stack, TextField } from "@mui/material";
import formatVariableName from "@simulatorBuilder/utils/formatVariableName";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { simulatorBuilderChanged } from "../../../simulatorBuilderSlice";
import { ModalStepType, SimulatorVariable } from "../../../types/SimulatorVariable";

const CHAR_LIMIT = 35;

type SimulatorVariableModalFormProps = {
  modalStep: ModalStepType;
  onBack: () => void;
  onNext: () => void;
  variable: SimulatorVariable;
  variableIndex: number;
};

/**
 * Dumb component que renderiza a tela com input para edição de nome de variável do builder de simulador.
 */
export default function SimulatorVariableModalForm(props: SimulatorVariableModalFormProps) {
  const { modalStep, onBack, onNext, variable, variableIndex } = props;

  const dispatch = useAppDispatch();

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [name, setName] = useState<string>(variable.name);
  const [label, setLabel] = useState<string>(variable.label);

  const inputVariables = simulator.variables;
  const errorNameMaxLengthReached = name.length > CHAR_LIMIT;
  const errorAlreadyUsedVariableName = inputVariables
    .filter((varia) => varia.label !== variable.label)
    .some((vari) => vari.label === name);

  const showNameErrorAlert = errorNameMaxLengthReached || errorAlreadyUsedVariableName;
  const maySubmit =
    modalStep === "name" ? name.length > 0 && !showNameErrorAlert && !/[-_]$/.test(name) : label.length > 0;

  /**
   * Aplica a mudança de nome em uma variável de entrada do simulador.
   */
  const onSimulatorVariableNameChange = () => {
    const newVariable = {
      ...variable,
      category: "input",
      label,
      name,
      order_index: variableIndex,
    };

    const updatedSimulatorInputVariables = inputVariables
      .filter((vari) => vari.is_input)
      .map((vari, index) => (index === variableIndex ? newVariable : vari));

    const updatedSimulatorVariables = [
      ...inputVariables.filter((vari) => !vari.is_input),
      ...updatedSimulatorInputVariables,
    ];

    dispatch(simulatorBuilderChanged({ key: "variables", value: updatedSimulatorVariables }));
    onNext();
  };

  const renderNameErrorMessage = () => {
    switch (true) {
      case errorNameMaxLengthReached:
        return "Você ultrapassou o limite de 35 caracteres, abrevie para continuar.";
      case errorAlreadyUsedVariableName:
        return "Já existe uma variável com este nome. Por favor, escolha um nome diferente.";
      default:
        return "";
    }
  };

  const handleFormattedName = (value: string) => {
    if (modalStep === "name") {
      setName(formatVariableName(value));
    } else {
      setLabel(value);
    }
  };

  return (
    <Stack
      justifyContent="center"
      sx={{ height: "100%", margin: "auto", maxWidth: "874px", px: { sm: 0, xs: 3 }, width: "100%" }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        {modalStep === "name" ? "Qual o nome da variável de entrada?" : "Qual o nome de exibição da variável?"}
      </LqdTypography>
      <TextField
        helperText={
          showNameErrorAlert ? (
            <LqdTypography color="rgba(246, 61, 94, 1)" textstyle="c1Caption">
              {renderNameErrorMessage()}
            </LqdTypography>
          ) : null
        }
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={({ target: { value } }) => handleFormattedName(value)}
        onKeyUp={(key) => key.key === "Enter" && maySubmit && (() => {})}
        placeholder={modalStep === "name" ? "nome_da_variavel" : "Digite o nome de exibição aqui"}
        sx={{
          "& .MuiInput-underline:after": { borderBottomColor: "2px solid rgba(41, 176, 160, 1)" },
          "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
          ".MuiFormHelperText-root": {
            position: { sm: "absolute", xs: "relative" },
            top: { sm: "58px", xs: "0px" },
            width: "fit-content",
          },
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          height: "56px",
          mt: 1.5,
          width: "100%",
        }}
        value={modalStep === "name" ? name : label}
        variant="standard"
      />
      <LqdTypography
        color={showNameErrorAlert ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)"}
        sx={{ display: "block", opacity: name.length > 0 ? 1 : 0.5, textAlign: "right" }}
        textstyle="p2Paragraph"
      >
        {`${name.length}/${CHAR_LIMIT} caracteres`}
      </LqdTypography>
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
        <LqdButton onClick={() => onBack()} sx={{ mr: 3 }} type="outlineTertiary">
          Voltar
        </LqdButton>
        <LqdButton disabled={!maySubmit} onClick={onSimulatorVariableNameChange}>
          {modalStep === "name" ? "Continuar" : "Confirmar"}
        </LqdButton>
      </Stack>
    </Stack>
  );
}
