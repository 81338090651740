import { Path, Svg } from "@react-pdf/renderer";

export default function PDFHighestPaymentTermSVG() {
  return (
    <Svg height="32" viewBox="0 0 33 32" width="33">
      <Path
        d="M14.1156 27.6916C19.3479 28.8979 24.7465 26.4927 27.349 21.796C29.9515 17.0992 29.1285 11.2467 25.3316 7.44985C21.5348 3.65298 15.6823 2.83 10.9855 5.43249C6.28874 8.03498 3.88363 13.4335 5.08988 18.6659"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path d="M12.3535 15.1063H21.2858" stroke="rgb(127, 135, 152)" strokeLineCap="round" strokeWidth="1.2" />
      <Path
        d="M18.6573 20.4657H13.5445C12.8865 20.4657 12.3535 19.9328 12.3535 19.2747V13.3199C12.3535 12.6619 12.8865 12.1289 13.5445 12.1289H20.0948C20.7528 12.1289 21.2858 12.6619 21.2858 13.3199V17.8372C21.2858 17.995 21.2233 18.1469 21.1113 18.2582L19.0783 20.2912C18.9664 20.4032 18.8151 20.4657 18.6573 20.4657Z"
        fillRule="evenodd"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path d="M18.9042 11.5332V12.7242" stroke="rgb(127, 135, 152)" strokeLineCap="round" strokeWidth="1.2" />
      <Path d="M14.7352 11.5332V12.7242" stroke="rgb(127, 135, 152)" strokeLineCap="round" strokeWidth="1.2" />
      <Path
        d="M5.05155 27.8874L5.45014 25.7153L3.88683 24.1556C3.75695 24.026 3.71191 23.8339 3.77065 23.6601C3.82938 23.4862 3.9817 23.3608 4.16356 23.3366L6.35248 23.0445L7.35277 21.0757C7.43588 20.9121 7.60466 20.8099 7.78814 20.812C7.97161 20.8141 8.13796 20.9203 8.21724 21.0857L9.17138 23.0772L11.3529 23.4202C11.5342 23.4486 11.6835 23.5776 11.7382 23.7527C11.7929 23.9279 11.7433 24.1189 11.6105 24.2454L10.0113 25.7684L10.3593 27.9491C10.3882 28.1303 10.3118 28.3122 10.1621 28.4183C10.0124 28.5245 9.81542 28.5364 9.65402 28.4491L7.71152 27.3988L5.74501 28.4036C5.58163 28.487 5.38502 28.4705 5.23785 28.361C5.09067 28.2514 5.01845 28.0678 5.05155 27.8874V27.8874Z"
        fillRule="evenodd"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
