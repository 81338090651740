import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { AnalysisByProductResponseRaw } from "../types/AnalysisByProductResponseRaw";

/** List Analysis by Product List (Superadmin Endpoint) */
export const adminListAnalysisByProductList = (
  productIdList: Array<string>,
  tenantCode: string
): AxiosPromise<Array<AnalysisByProductResponseRaw>> => {
  return get(`/admin/analysis/product?product_id_list=${productIdList}&tenant_code=${tenantCode}`);
};
