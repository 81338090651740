import {
  LqdDownIcon,
  LqdDuplicateIcon,
  LqdEditIcon,
  LqdEllipsisIcon,
  LqdIconButton,
  LqdMenu,
  LqdMenuItem,
  LqdTrashIcon,
  LqdTypography,
  LqdUpIcon,
  LqdVariableIcon,
} from "@/liquid-components/src";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { simulatorBuilderHasAlterations } from "@simulatorBuilder/simulatorBuilderSlice";
import { reorderVariablesPosition } from "@simulatorBuilder/utils/handleVariablesPosition";
import { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { SimulatorVariable } from "../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../types/SimulatorVariableTypeTemplate";

type SimulatorVariableCardFormProps = {
  onDuplicateClick: () => void;
  onEditNameClick: () => void;
  onRemoveClick: () => void;
  onSimulatorVariableTypeChange: (value: SimulatorVariableTemplateType) => void;
  simulatorVariable: SimulatorVariable;
};

/** Card de variável no builder de simuladores (na tela de listagem de variáveis). */
export default function SimulatorVariableCardForm(props: SimulatorVariableCardFormProps) {
  const { onDuplicateClick, onEditNameClick, onRemoveClick, onSimulatorVariableTypeChange, simulatorVariable } = props;

  const dispatch = useAppDispatch();

  const { simulatorData, variableTypes } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);

  const inputVariables = simulator.variables.filter((variable) => variable.is_input);

  /** Retorna uma função que faz o handle de um click event, fecha o menu e chama o callback.
   * @param callback Função a ser chamada após fechar o menu.
   */
  const handleClick = (callback: () => void) => () => {
    setShowMenu(null);
    callback();
  };

  const handleVariablesPosition = (type: "down" | "up") => {
    reorderVariablesPosition(dispatch, simulator.variables, simulatorVariable, type);
    dispatch(simulatorBuilderHasAlterations(true));
  };

  return (
    <Box
      sx={{
        alignItems: "flex-start",
        backgroundColor: "rgba(249, 249, 250, 1)",
        borderRadius: "20px",
        display: "flex",
        pb: 3,
        pt: 4,
        px: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(177, 227, 255, 1)",
          borderRadius: "50%",
          mr: 1.5,
          p: 1,
          svg: { display: "block" },
        }}
      >
        <LqdVariableIcon />
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h4Headline">
            Variável de Entrada
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(156, 163, 175, 1)", fontSize: "14px", lineHeight: "16px", mt: 1 }}
            textstyle="c1Caption"
          >
            Crie variáveis para input de dados do usuário
          </LqdTypography>
          <Stack alignItems="center" direction="row" spacing={1.25} sx={{ mb: 1, mt: 2.5 }}>
            <LqdTypography color="rgba(33, 36, 42, 1)">{simulatorVariable.label || "Nova variável"}</LqdTypography>
            <LqdTypography color="rgba(127, 135, 152, 1)">
              {simulatorVariable.name === "" ? "" : simulatorVariable.name}
            </LqdTypography>
            <LqdIconButton buttonsize="small" onClick={onEditNameClick} round="true" type="ghostIcon">
              <LqdEditIcon size={20} />
            </LqdIconButton>
          </Stack>
          <Select
            displayEmpty
            IconComponent={LqdDownIcon}
            inputProps={{
              sx: {
                ":active": { boxShadow: "0px 0px 0px 4px rgba(235, 235, 245, 0.70)" },
                ":focus": { borderColor: "rgba(107, 114, 128, 1)" },
                ":hover": {
                  borderColor: "rgba(107, 114, 128, 1)",
                },
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(229, 231, 235, 1)",
                borderRadius: "8px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
                display: "flex",
                pl: "12px !important",
              },
            }}
            MenuProps={{
              MenuListProps: { sx: { ".MuiMenuItem-root.Mui-selected": { backgroundColor: "transparent" }, p: 0 } },
              PaperProps: {
                sx: {
                  border: "1px solid rgba(33, 36, 42, 1)",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  maxHeight: "400px",
                  mt: "10px",
                  pl: 2,
                  pr: "9px",
                  py: 3,
                },
              },
            }}
            onChange={({ target: { value } }) => onSimulatorVariableTypeChange(value as SimulatorVariableTemplateType)}
            renderValue={() =>
              !simulatorVariable.type ? (
                <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
                  Selecione o tipo da variável
                </LqdTypography>
              ) : (
                variableTypes.find((type) => type.value === simulatorVariable.type)?.label
              )
            }
            sx={{
              ".MuiInput-input": { height: "48px !important", p: 0 },
              ".MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "8px",
              },
              "::after": { display: "none" },
              "::before": { display: "none" },
              color: simulatorVariable.type ? "inherit" : "rgba(156, 163, 175, 1)",
              minWidth: "320px",
              svg: { pointerEvents: "none", position: "absolute", right: "12px" },
            }}
            value={simulatorVariable.type}
            variant="standard"
          >
            {variableTypes.map((variableType, index) => (
              <MenuItem
                key={variableType.value}
                sx={{
                  "&.Mui-selected": {
                    "&:hover": { backgroundColor: "transparent" },
                    backgroundColor: "transparent",
                    borderColor: "rgba(33, 36, 42, 1)",
                    color: "rgba(33, 36, 42, 1)",
                  },
                  borderRadius: 5,
                  color: "rgba(101, 110, 127, 1)",
                  mt: index ? 1 : 0,
                }}
                value={variableType.value}
              >
                {variableType.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {inputVariables.length > 1 ? (
          <Box sx={{ display: "flex", gap: "12px" }}>
            <LqdIconButton
              disabled={simulatorVariable.order_index === 0}
              onClick={() => handleVariablesPosition("up")}
              round="true"
              type="filledSecundary"
            >
              <LqdUpIcon color="rgba(127, 135, 152, 1)" size={38} />
            </LqdIconButton>
            <LqdIconButton
              disabled={simulatorVariable.order_index === inputVariables.length - 1}
              onClick={() => handleVariablesPosition("down")}
              round="true"
              type="filledSecundary"
            >
              <LqdDownIcon color="rgba(127, 135, 152, 1)" size={38} />
            </LqdIconButton>
            <LqdIconButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => setShowMenu(event.currentTarget)}
              round="true"
              type="outlinePrimary"
            >
              <LqdEllipsisIcon />
            </LqdIconButton>
          </Box>
        ) : null}
        <LqdMenu anchorEl={showMenu} onClose={() => setShowMenu(null)} open={Boolean(showMenu)}>
          <LqdMenuItem onClick={handleClick(onDuplicateClick)}>
            <LqdDuplicateIcon />
            <Box sx={{ ml: 1 }}>Duplicar</Box>
          </LqdMenuItem>
          <LqdMenuItem disabled={inputVariables.length <= 1} onClick={handleClick(onRemoveClick)}>
            <LqdTrashIcon />
            <Box sx={{ ml: 1 }}>Excluir</Box>
          </LqdMenuItem>
        </LqdMenu>
      </Stack>
    </Box>
  );
}
