import { put } from "../../common/utils/apiUtils";
import { TenantResponseRaw } from "../../superadmin/types/TenantResponseRaw";

/**
 * Update Tenant MFA Activation.
 * @param mfaActive MFA Activation status.
 * @returns TenantResponseRaw data.
 * @remarks Endpoint ADMIN.
 */
export const updateTenantMFAActivation = (mfaActive: boolean) => {
  return put<TenantResponseRaw>("/tenants/mfa", { mfa_active: mfaActive });
};
