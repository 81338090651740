import { LqdIconProps } from "../../types/IconProps";

export default function LqdHourglassIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.8125 3.38269C5.50312 3.38269 5.25 3.63582 5.25 3.94519C5.25 4.25457 5.50312 4.50769 5.8125 4.50769H6.375V5.97019C6.375 7.70691 7.17656 9.34519 8.54766 10.4104L11.0824 12.3827L8.54766 14.355C7.17656 15.4202 6.375 17.062 6.375 18.7952V20.2577H5.8125C5.50312 20.2577 5.25 20.5108 5.25 20.8202C5.25 21.1296 5.50312 21.3827 5.8125 21.3827H6.9375H17.0625H18.1875C18.4969 21.3827 18.75 21.1296 18.75 20.8202C18.75 20.5108 18.4969 20.2577 18.1875 20.2577H17.625V18.7952C17.625 17.0585 16.8234 15.4202 15.4523 14.355L12.9176 12.3827L15.4559 10.4104C16.827 9.34519 17.6285 7.70691 17.6285 5.97019V4.50769H18.191C18.5004 4.50769 18.7535 4.25457 18.7535 3.94519C18.7535 3.63582 18.5004 3.38269 18.191 3.38269H17.0625H6.9375H5.8125ZM16.5 4.50769V5.97019C16.5 7.35886 15.8602 8.67019 14.7633 9.52097L12 11.669L9.23672 9.52097C8.13984 8.67019 7.5 7.35886 7.5 5.97019V4.50769H16.5ZM12 13.0964L14.7633 15.2444C15.8602 16.0952 16.5 17.4065 16.5 18.7952V20.2577H7.5V18.7952C7.5 17.4065 8.13984 16.0952 9.23672 15.2444L12 13.0964Z"
        fill={color}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
