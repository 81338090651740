import { ObjectOf } from "../../common/types/ObjectOf";
import { AnalysisDetailsStep } from "../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { AnalysisStepsResponseRaw, RawPeer } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";

export const formatRawAnalysisDetailsSteps = (
  analysisStepsResponseRaw: AnalysisStepsResponseRaw
): ObjectOf<Array<AnalysisDetailsStep>> => {
  const peersSteps: ObjectOf<Array<AnalysisDetailsStep>> = {};

  for (const peerDocument in analysisStepsResponseRaw) {
    if (peerDocument === "error") continue;

    const peerDocumentSteps = analysisStepsResponseRaw[peerDocument];

    if (typeof peerDocumentSteps !== "string" && typeof peerDocumentSteps !== "number") {
      const stepsRaw = (peerDocumentSteps as RawPeer).steps;

      const steps = stepsRaw.map((stepRaw) => ({
        action: stepRaw.action,
        condition: stepRaw.condition,
        description: stepRaw.description,
        kpi: stepRaw.kpi,
        matchAt: stepRaw.match_at,
        node: stepRaw.node,
        peerDocument,
        provider: stepRaw.provider,
        providerValue: stepRaw.provider_value,
        result: stepRaw.result,
        value: stepRaw.value,
        valueType: stepRaw.value_type,
        warningMessage: stepRaw.warning_message,
      }));

      peersSteps[peerDocument] = steps;
    }
  }

  return peersSteps;
};
