import { LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import openCreditFinishedSVG from "../../assets/openCreditFinished.svg";

export default function OpenCreditAcceptedState() {
  const { width } = useWindowSize();

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 0 } }}
    >
      <Box
        sx={{
          alignItems: { md: "flex-start", xs: "center" },
          display: "flex",
          flexDirection: "column",
          maxWidth: "550px",
        }}
      >
        <LqdTypography
          sx={{ color: "rgba(33, 36, 42, 1)", textAlign: { md: "left", xs: "center" } }}
          textstyle={width === "xs" ? "h3Headline" : "h2Headline"}
        >
          Você já realizou sua autenticação!
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(0, 0, 0, 1)", pt: { md: 2.5, xs: 1.5 } }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          Seu cadastro está em análise.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(0, 0, 0, 1)", pt: { md: 2.5, xs: 1 } }}
          textstyle={width === "xs" ? "p2Paragraph" : "c1Caption"}
        >
          Agradecemos por confiar na Liquid ❤️
        </LqdTypography>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openCreditFinishedSVG} />
      </Box>
    </Stack>
  );
}
