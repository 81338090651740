import {
  LqdCloseIcon,
  LqdIconButton,
  LqdNodeCard,
  LqdSearchIcon,
  LqdTextField,
  LqdTypography,
} from "@/liquid-components/src";
import { Avatar, Box, CardHeader, CardMedia } from "@mui/material";
import { DragEvent, Fragment, useState } from "react";
import { useAppSelector } from "../../../../../../store";
import notFoundImg from "../../../../../productBuilder/assets/no-results-img.svg";
import NodeCardSkeleton from "../../../../../productBuilder/components/NodeCardSkeleton";
import NodeIcon from "../../../../../productBuilder/components/NodeIcon";

export default function TemplateFormStep4AddNodeSidebar() {
  const { nodeCategories } = useAppSelector((state) => state.productBuilder);
  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeDataJSON: string) => {
    event.dataTransfer.setData("application/reactflow", nodeDataJSON);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragEnd = () => {
    setSearch("");
  };

  const [search, setSearch] = useState<string>("");

  const filteredNodeCategories = nodeCategories.map((nodeCategory) => {
    const filteredNodes = nodeCategory.nodes.filter(
      (node) =>
        node.title.toLowerCase().includes(search.toLowerCase()) ||
        node.subtitle.toLowerCase().includes(search.toLowerCase())
    );

    return {
      ...nodeCategory,
      nodes: filteredNodes,
    };
  });

  const emptyNodeCategories = filteredNodeCategories.every((nodeCategory) => !nodeCategory.nodes.length);

  const renderNodeCategories = () => {
    switch (emptyNodeCategories) {
      case false:
        return filteredNodeCategories.map((nodeCategory, nodeCategoryIndex) =>
          nodeCategory.type !== "start_flow" ? (
            <Fragment key={`${nodeCategory.title}-${nodeCategoryIndex}`}>
              <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2 }}>
                {nodeCategory.title}
              </LqdTypography>
              {nodeCategory.nodes.map((node, nodeIndex) => (
                <LqdNodeCard
                  draggable="true"
                  key={`${node.title}-${nodeIndex}`}
                  onDragEnd={() => onDragEnd()}
                  onDragStart={(event) => onDragStart(event, JSON.stringify(node))}
                  sx={{
                    ":active": { cursor: "grabbing", outline: "3px solid rgba(33, 36, 42, 1)" },
                    ":hover": { cursor: "grab", outline: "3px solid rgba(191, 196, 205, 0.32)" },
                    backgroundColor: node.color,
                    border: "1px solid rgba(127, 135, 152, 0.16)",
                    boxShadow: "none",
                    mb: 2,
                    ml: 0.5,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar sx={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "rgba(0, 0, 0, 1)" }}>
                        <NodeIcon {...node} />
                      </Avatar>
                    }
                    subheader={
                      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                        {node.subtitle}
                      </LqdTypography>
                    }
                    title={
                      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ fontWeight: 600 }}>
                        {node.title}
                      </LqdTypography>
                    }
                  />
                </LqdNodeCard>
              ))}
            </Fragment>
          ) : null
        );
      case true:
        return (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              maxWidth: "160px",
              mt: 3,
              mx: "auto",
              textAlign: "center",
            }}
          >
            <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
              Ops!
            </LqdTypography>
            <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="p2Paragraph">
              Não foi encontrado nenhum arquivo com este nome
            </LqdTypography>
            <CardMedia component="img" image={notFoundImg} sx={{ mt: 4, width: "200px" }} />
          </Box>
        );
      default:
        null;
    }
  };

  return (
    <Box sx={{ boxSizing: "border-box", height: "100%", px: 4, py: 5 }}>
      <Box
        sx={{
          "::-webkit-scrollbar-track": {
            background: !emptyNodeCategories ? "rgba(221, 225, 240, 1)" : "transparent",
            backgroundClip: "padding-box",
          },
          boxSizing: "border-box",
          height: "100%",
          overflowY: "scroll",
          pr: 2,
        }}
      >
        <LqdTextField
          endAdornment={
            <LqdIconButton onClick={() => setSearch("")} type="ghostIcon">
              <LqdCloseIcon />
            </LqdIconButton>
          }
          error={!!(emptyNodeCategories && filteredNodeCategories.length > 0)}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Pesquise aqui"
          startAdornment={
            <Box sx={{ mr: 1, mt: 0.5 }}>
              <LqdSearchIcon />
            </Box>
          }
          value={search}
        />
        {filteredNodeCategories.length ? (
          renderNodeCategories()
        ) : (
          <>
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
            <NodeCardSkeleton />
          </>
        )}
      </Box>
    </Box>
  );
}
