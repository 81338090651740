import {
  LqdCheckboxOffIcon,
  LqdCheckboxOnIcon,
  LqdEyeClosedIcon,
  LqdEyeOpenIcon,
  LqdIconButton,
  LqdProgress,
  LqdRightArrowIcon,
  LqdTextField,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { ObjectOf } from "../types/ObjectOf";

type CreatePasswordFormProps = {
  loading: boolean;
  onCreatePassword: (password: string) => void;
};

/**
 * Form reaproveitável com inputs para criação/redefinição de senhas.
 * @param props
 * @returns
 */

export default function CreatePasswordForm(props: CreatePasswordFormProps) {
  const { loading, onCreatePassword } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const theme = useTheme();

  const passwordsAreEqual = password === passwordAgain;

  const passwordHas = {
    allCasesWithNumber: /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password),
    atLeast6Chars: password.length >= 6,
    specialChars: /[^A-Za-z0-9]/.test(password),
  } as ObjectOf<boolean>;

  const passwordStrength = Object.keys(passwordHas).filter((criteria) => passwordHas[criteria]).length;

  const passwordProps = (): { color: "error" | "success" | "warning"; label: string } => {
    switch (passwordStrength) {
      case 3:
        return { color: "success", label: "Forte" };
      case 2:
        return { color: "warning", label: "Média" };
      default:
        return { color: "error", label: "Fraca" };
    }
  };

  return (
    <>
      <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
        Crie uma senha
      </LqdTypography>
      <LqdTypography sx={{ mb: 8 }} textstyle="p2Paragraph">
        Digite uma senha de acesso
      </LqdTypography>
      <form>
        <LqdTypography textstyle="c1Caption">Nova senha</LqdTypography>
        <LqdTextField
          // data-testid="lqd-password-input"
          endAdornment={
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <LqdEyeOpenIcon /> : <LqdEyeClosedIcon />}
            </IconButton>
          }
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Digite sua nova senha"
          type={showPassword ? "text" : "password"}
          value={password}
        />
        <LqdTypography textstyle="c1Caption">Digite a sua senha novamente</LqdTypography>
        <LqdTextField
          // data-testid="lqd-password-again-input"
          endAdornment={
            <IconButton onClick={() => setShowPasswordAgain(!showPasswordAgain)}>
              {showPasswordAgain ? <LqdEyeOpenIcon /> : <LqdEyeClosedIcon />}
            </IconButton>
          }
          error={!passwordsAreEqual}
          label={
            passwordAgain.length >= 6 && !passwordsAreEqual ? (
              <LqdTypography color="error" sx={{ height: "20px" }} textstyle="c1Caption">
                As senhas precisam ser iguais.
              </LqdTypography>
            ) : null
          }
          onChange={(event) => setPasswordAgain(event.target.value)}
          placeholder="Digite sua nova senha novamente"
          type={showPasswordAgain ? "text" : "password"}
          value={passwordAgain}
        />
        <LqdProgress
          amount={6}
          color={passwordProps().color}
          filled={2 * passwordStrength}
          prependContent={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <LqdTypography color="secondary" textstyle="c1Caption">
                Força da senha
              </LqdTypography>
              <LqdTypography color="secondary" textstyle="c1Caption">
                {passwordProps().label}
              </LqdTypography>
            </Box>
          }
          sx={{ mb: 4, mt: 2 }}
        />
        <Box
          sx={{
            backgroundColor: "rgba(65, 70, 103, 0.04)",
            borderRadius: "3px",
            mb: 5,
            p: 2,
            pt: 3,
          }}
        >
          <LqdTypography sx={{ mb: 2 }} textstyle="p2Paragraph">
            Sua senha precisa ter:
          </LqdTypography>
          <LqdTypography sx={{ alignItems: "center", display: "flex", pb: 1 }} textstyle="c1Caption">
            {passwordHas.atLeast6Chars ? (
              <LqdCheckboxOnIcon color={theme.palette.primary.main} />
            ) : (
              <LqdCheckboxOffIcon color="rgba(212, 215, 220, 1)" />
            )}
            <Box sx={{ ml: 1 }}>Mínimo de 6 caracteres</Box>
          </LqdTypography>
          <LqdTypography sx={{ alignItems: "center", display: "flex", pb: 1 }} textstyle="c1Caption">
            {passwordHas.allCasesWithNumber ? (
              <LqdCheckboxOnIcon color={theme.palette.primary.main} />
            ) : (
              <LqdCheckboxOffIcon color="rgba(212, 215, 220, 1)" />
            )}
            <Box sx={{ ml: 1 }}>Números e letras maiúscula e minúscula</Box>
          </LqdTypography>
          <LqdTypography sx={{ alignItems: "center", display: "flex", pb: 1 }} textstyle="c1Caption">
            {passwordHas.specialChars ? (
              <LqdCheckboxOnIcon color={theme.palette.primary.main} />
            ) : (
              <LqdCheckboxOffIcon color="rgba(212, 215, 220, 1)" />
            )}
            <Box sx={{ ml: 1 }}>Caracteres especiais</Box>
          </LqdTypography>
        </Box>
        <LqdIconButton
          // data-testid="lqd-password-submit-button"
          disabled={!(passwordsAreEqual && passwordStrength === 3) || loading}
          onClick={() => onCreatePassword(password)}
        >
          <LqdRightArrowIcon />
        </LqdIconButton>
      </form>
    </>
  );
}
