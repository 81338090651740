import { Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  checkMark: {
    alignItems: "center",
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "50%",
    height: "30px",
    justifyContent: "center",
    marginTop: "35px",
    width: "30px",
  },
});

export default function PDFSBPEInsightCheckMarkSVG() {
  return (
    <View style={styles.checkMark}>
      <Svg height="16" viewBox="0 0 16 16" width="16">
        <Path
          d="M13.334 4.5625L6.45898 11.4375L3.33398 8.3125"
          stroke="rgb(7, 65, 84)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
      </Svg>
    </View>
  );
}
