import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
import Poppins from "../../../../../../fonts/Poppins/Poppins-Regular.ttf";
import { ObjectOf } from "../../../../common/types/ObjectOf";
import { Product } from "../../../../products/types/Product";
import { CurrentUser } from "../../../../users/types/CurrentUser";
import { User } from "../../../../users/types/User";
import { AnalysisDetails } from "../../../types/AnalysisDetails/Analysis/AnalysisDetails";
import { PeerData, PeerDataWithSteps } from "../../../types/AnalysisDetails/PeerData/PeerData";
import { AllotmentSimulation } from "../../../types/Simulation/Allotment/AllotmentSimulation";
import { AnalysisSimulation } from "../../../types/Simulation/AnalysisSimulation";
import { BasicSimulation } from "../../../types/Simulation/Basic/BasicSimulation";
import { BuilderSimulation } from "../../../types/Simulation/Builder/BuilderSimulation";
import { SBPESimulation } from "../../../types/Simulation/SBPE/SBPESimulation";
import PDFPeerData from "./Body/PDFPeerData";
import PDFAllotmentFinancingTable from "./Body/Simulator/Allotment/PDFAllotmentFinancingTable";
import PDFAllotmentSimulatorInfoContainer from "./Body/Simulator/Allotment/PDFAllotmentSimulatorInfoContainer";
import PDFAllotmentSimulatorTable from "./Body/Simulator/Allotment/PDFAllotmentSimulatorTable";
import PDFBasicSimulatorGeneralInfoContainer from "./Body/Simulator/Basic/PDFBasicSimulatorGeneralInfoContainer";
import PDFBasicSimulatorTable from "./Body/Simulator/Basic/PDFBasicSimulatorTable";
import PDFBuilderInfoContainer from "./Body/Simulator/Builder/PDFBuilderSimulatorInfoContainer";
import PDFBuilderSimulatorTable from "./Body/Simulator/Builder/PDFBuilderSimulatorTable";
import PDFSimulatorPeerData from "./Body/Simulator/PDFSimulatorPeerData";
import PDFSimulatorWarningCard from "./Body/Simulator/PDFSimulatorWarningCard";
import PDFSBPEBankTables from "./Body/Simulator/SBPE/PDFSBPEBankTables";
import PDFSBPESimulatorBuildingTimeInfo from "./Body/Simulator/SBPE/PDFSBPESimulatorBuildingTimeInfo";
import PDFSBPESimulatorFinancingInfo from "./Body/Simulator/SBPE/PDFSBPESimulatorFinancingInfo";
import PDFSBPESimulatorGeneralInfoContainer from "./Body/Simulator/SBPE/PDFSBPESimulatorGeneralInfoContainer";
import PDFSBPESimulatorOutstandingAmountInfoRow from "./Body/Simulator/SBPE/PDFSBPESimulatorOutstandingAmountInfoRow";
import PDFTrustpadPeerData from "./Body/Trustpad/PDFTrustpadPeerData";
import PDFFooter from "./Footer/PDFFooter";
import PDFHeader from "./Header/PDFHeader";
import PDFCNPJDashboard from "./PDFCNPJDashboard";
import PDFCPFDashboard from "./PDFCPFDashboard";

Font.register({ family: "Poppins", src: Poppins });

const styles = StyleSheet.create({
  blockViewer: {
    paddingTop: "18px",
  },
  borderContainer: {
    borderBottom: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  page: {
    backgroundColor: "rgb(255, 255, 255)",
    fontFamily: "Poppins",
    paddingBottom: "240px",
    paddingLeft: "195px",
    paddingRight: "204px",
    paddingTop: "110px",
  },
  peerIDPass: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    marginBottom: "16px",
    paddingTop: "25px",
  },
});

type DashboardPDFProps = {
  analysisDetails: AnalysisDetails;
  currentUser: CurrentUser;
  footerData: { exportedAt: string; exportedBy: string };
  peersToSimulator: Array<PeerData>;
  peersWithSteps: Array<PeerDataWithSteps>;
  product: Product;
  shouldRenderTrustpad: boolean;
  simulatorsToRenderOnPDF: Array<AnalysisSimulation>;
  users: ObjectOf<User>;
  usersToRenderOnPDF: Array<PeerData>;
};

export default function DashboardPDF(props: DashboardPDFProps) {
  const {
    analysisDetails,
    currentUser,
    footerData,
    peersToSimulator,
    peersWithSteps,
    product,
    shouldRenderTrustpad,
    simulatorsToRenderOnPDF,
    users,
    usersToRenderOnPDF,
  } = props;

  const simulatorDataLookup: ObjectOf<ObjectOf<(simulation: AnalysisSimulation) => JSX.Element | null>> = {
    allotment: {
      buildingTime: () => null,
      financingTable: (simulation) => (
        <>
          <Text break />
          <PDFAllotmentFinancingTable
            product={product}
            result={(simulation.simulation as AllotmentSimulation).result}
          />
        </>
      ),
      infoContainer: (simulation) => (
        <PDFAllotmentSimulatorInfoContainer highlight={(simulation.simulation as AllotmentSimulation).highlight} />
      ),
      outstandingAmount: () => null,
      table: (simulation) => (
        <PDFAllotmentSimulatorTable highlight={(simulation.simulation as AllotmentSimulation).highlight} />
      ),
    },
    basic: {
      buildingTime: () => null,
      financingTable: () => null,
      infoContainer: (simulation) => (
        <PDFBasicSimulatorGeneralInfoContainer simulation={simulation.simulation as BasicSimulation} />
      ),
      outstandingAmount: () => null,
      table: (simulation) => <PDFBasicSimulatorTable simulation={simulation.simulation as BasicSimulation} />,
    },
    sbpe: {
      buildingTime: (simulation) => {
        if ((simulation.simulation as SBPESimulation).highlight?.months_until_building > 0) {
          return <PDFSBPESimulatorBuildingTimeInfo highlight={(simulation.simulation as SBPESimulation).highlight} />;
        }

        return null;
      },
      financingTable: (simulation) => (
        <>
          <Text break />
          <PDFSBPEBankTables simulation={simulation.simulation as SBPESimulation} />
        </>
      ),
      infoContainer: (simulation) => (
        <PDFSBPESimulatorGeneralInfoContainer highlight={(simulation.simulation as SBPESimulation).highlight} />
      ),
      outstandingAmount: (simulation) => {
        if ((simulation.simulation as SBPESimulation).highlight?.outstanding_amount > 0) {
          return (
            <PDFSBPESimulatorOutstandingAmountInfoRow highlight={(simulation.simulation as SBPESimulation).highlight} />
          );
        }

        return null;
      },
      table: (simulation) => (
        <PDFSBPESimulatorFinancingInfo highlight={(simulation.simulation as SBPESimulation).highlight} />
      ),
    },
    simulator: {
      buildingTime: () => null,
      financingTable: () => null,
      infoContainer: (simulation) => (
        <PDFBuilderInfoContainer simulation={simulation.simulation as BuilderSimulation} />
      ),
      outstandingAmount: () => null,
      table: (simulation) => <PDFBuilderSimulatorTable simulation={simulation.simulation as BuilderSimulation} />,
    },
  };

  const peerDataLookup: ObjectOf<(user: PeerData) => JSX.Element | null> = {
    cnpj: (user) => <PDFCNPJDashboard analysisDetails={analysisDetails} user={user} />,
    cpf: (user) => <PDFCPFDashboard analysisDetails={analysisDetails} user={user} />,
    default: () => null,
  };

  const isAbleToViewUserData =
    currentUser.userRole === "super" || currentUser.userRole === "admin" || currentUser.userRole === "decisor";

  return (
    <Document>
      <Page size="B2" style={styles.page}>
        <PDFHeader analysisDetails={analysisDetails} product={product} users={users} />

        {/* TRUSTPAD */}
        {shouldRenderTrustpad ? (
          <>
            <PDFSimulatorPeerData peersToSimulator={peersToSimulator} />
            {peersWithSteps.map((peer, index) => (
              <Fragment key={peer.document}>
                <PDFTrustpadPeerData peer={peer} productName={product.name} steps={peer.steps} />
                {index === peersWithSteps.length - 1 &&
                (usersToRenderOnPDF.length > 0 || simulatorsToRenderOnPDF.length > 0) ? (
                  <Text break />
                ) : null}
              </Fragment>
            ))}
          </>
        ) : null}

        {/* SIMULADORES */}
        {simulatorsToRenderOnPDF.map((simulation, index) => (
          <Fragment key={simulation.simulation_type}>
            <PDFSimulatorPeerData peersToSimulator={peersToSimulator} />
            {!simulation.simulation.description ? (
              <>
                {simulatorDataLookup[simulation.simulation_type].infoContainer(simulation)}
                {simulatorDataLookup[simulation.simulation_type].outstandingAmount(simulation)}
                {simulatorDataLookup[simulation.simulation_type].buildingTime(simulation)}
                {simulatorDataLookup[simulation.simulation_type].table(simulation)}
                {simulatorDataLookup[simulation.simulation_type].financingTable(simulation)}
                {index === simulatorsToRenderOnPDF.length - 1 ? (
                  <>
                    {simulation.simulation_type === "sbpe" ? <PDFSimulatorWarningCard /> : null}
                    {usersToRenderOnPDF.length > 0 ? <Text break /> : null}
                  </>
                ) : (
                  <Text break />
                )}
              </>
            ) : (
              <>
                <Text style={styles.blockViewer}>Não foram obtidos resultados para esta simulação.</Text>
                <Text break />
              </>
            )}
          </Fragment>
        ))}

        {/* DADOS DE PEERS */}
        {usersToRenderOnPDF.map((user, index) => (
          <Fragment key={`${user.fullName}-${index}`}>
            <Text style={styles.peerIDPass}>{`ID Pass (${usersToRenderOnPDF.length})`}</Text>
            <PDFPeerData isFirst={usersToRenderOnPDF[0].passId === user.passId} user={user} />
            <View style={styles.borderContainer} />
            {isAbleToViewUserData ? (
              <>
                {peerDataLookup[user.document.length === 11 ? "cpf" : "cnpj"](user)}
                {index !== usersToRenderOnPDF.length - 1 ? <Text break /> : null}
              </>
            ) : (
              <Text style={styles.blockViewer}>Seu perfil não permite acesso às informações deste usuário.</Text>
            )}
          </Fragment>
        ))}
        <PDFFooter footerData={footerData} />
      </Page>
    </Document>
  );
}
