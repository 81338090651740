import { LqdIconProps } from "../../types/IconProps";

export default function LqdTermsIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 10V5C6 3.89543 6.89543 3 8 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M10 7.5H17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 11H17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17 15H15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        clipRule="evenodd"
        d="M4.55213 20.9999H3.44701C3.32845 20.9999 3.21476 20.9464 3.13093 20.8511C3.0471 20.7558 3 20.6265 3 20.4918V20.4918V16.5082C3 16.3734 3.0471 16.2441 3.13093 16.1488C3.21476 16.0535 3.32845 16 3.44701 16H4.55213C4.67281 16.0022 4.7878 16.0587 4.87178 16.1573C4.95576 16.2558 5.00187 16.3883 4.99994 16.5254V20.5254C4.98708 20.7947 4.78931 21.0042 4.55213 20.9999V20.9999Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M5 16.6692L7.04878 13.6446C7.39878 13.1299 8.08377 12.8873 8.72728 13.0502C9.3708 13.2132 9.81688 13.7421 9.82039 14.3464V15.9504H11.4944C11.9975 15.9506 12.4671 16.1787 12.745 16.5579C12.9923 16.8941 13.0641 17.3115 12.9412 17.6998L12.1297 20.2682C11.9923 20.7011 11.5546 20.9996 11.0565 21H7.08647C6.7627 21 6.44908 20.8978 6.19955 20.7113L5 19.809"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
