import { Box, SxProps, useTheme } from "@mui/material";
import LqdFormField, { LqdFormFieldProps } from "../FormField/FormField";

interface LqdProgressProps extends LqdFormFieldProps {
  amount: number;
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  filled: number;
  sx?: SxProps;
}

export default function LqdProgress(props: LqdProgressProps) {
  const { amount, color, error, filled, info, label, prependContent, sx } = props;

  const theme = useTheme();
  const elements = [];

  for (let inx = 0; inx < amount; inx += 1) {
    elements.push(
      <Box
        component="span"
        sx={{
          backgroundColor: inx < filled ? theme.palette[color || "primary"].main : "rgba(221, 225, 240, 1)",
          borderRadius: "50px",
          display: "inline-block",
          height: "5px",
          marginLeft: inx === 0 ? 0 : "6px",
          width: `calc(100% / ${amount})`,
        }}
      />
    );
  }
  return (
    <LqdFormField
      element={<Box sx={{ display: "flex", width: "100%" }}>{elements}</Box>}
      error={error}
      info={info}
      label={label}
      prependContent={prependContent}
      sx={{ ...sx }}
    />
  );
}
