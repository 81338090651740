import { LqdHomeIcon, LqdLink, LqdTypography, LqdWorldIcon } from "@/liquid-components/src";
import { Avatar, Box, Grid } from "@mui/material";
import { t } from "i18next";
import { ReactNode } from "react";
import { Product } from "../../products/types/Product";
import product1 from "/img/generic-product.png";

type ProductRowProps = {
  checkbox: ReactNode;
  hideButton?: boolean;
  product: Product;
};

export default function ProductRow(props: ProductRowProps) {
  const { checkbox, hideButton, product } = props;

  return (
    <Grid className="lqd-product-row" container sx={{ minHeight: "90px" }}>
      <Grid item sx={{ alignItems: "center", display: "flex" }} xs={4.7}>
        {checkbox}
        <Avatar src={product1} sx={{ display: "inline-block", ml: 3, mr: 1 }} />
        <Box sx={{ ml: 0.5 }}>
          <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="p2Paragraph">
            {t("Produto")}
          </LqdTypography>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="p2Paragraph">
            {product.name}
          </LqdTypography>
        </Box>
      </Grid>
      <Grid item sx={{ alignItems: "center", display: "flex" }} xs={4.8}>
        <LqdWorldIcon />
        <LqdTypography
          sx={{ color: "rgba(127, 135, 152, 1)", display: "inline-block", ml: 1, mr: 2 }}
          textstyle="p2Paragraph"
        >
          {product.country} / {product.currency}
        </LqdTypography>
        <LqdHomeIcon />
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", display: "inline-block", ml: 1 }} textstyle="p2Paragraph">
          {product.segment}
        </LqdTypography>
      </Grid>
      <Grid item sx={{ alignItems: "center", display: "flex", justifyContent: "center" }} xs={2.5}>
        {!hideButton ? (
          <LqdLink href={`/products/${product.id}`} target="_blank">
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              {t("Visualizar")}
            </LqdTypography>
          </LqdLink>
        ) : null}
      </Grid>
    </Grid>
  );
}
