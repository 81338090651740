import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ObjectOf } from "../../common/types/ObjectOf";
import { ToastNotification } from "../../common/types/ToastNotification";
import { onLoadGroupProducts } from "../../products/productsSlice";
import { Product } from "../../products/types/Product";
import { onLoadGroupsAdmin } from "../../superadmin/adminSliceThunks";
import { User } from "../../users/types/User";
import { onLoadUsers } from "../../users/usersSlice";
import {
  createGroupModalClosed,
  createGroupModalStepPrev,
  onCreateGroup,
  onLoadGroups,
  onLoadLoggedUserGroups,
} from "../groupsSlice";
import Group from "../types/Group";
import { getGroupRaw } from "../utils/getGroupRaw";
import GroupFormStep1 from "./GroupFormStep1";
import GroupFormStep2 from "./GroupFormStep2";
import GroupFormStep3 from "./GroupFormStep3";
import GroupFormStepLast from "./GroupFormStepLast";

export default function CreateGroupForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { createGroup, createGroupCurrentStep } = useAppSelector((state) => state.groups);

  const [groupTitle, setGroupTitle] = useState("");
  const [groupProducts, setGroupProducts] = useState<ObjectOf<Product>>({});
  const [groupUsers, setGroupUsers] = useState<ObjectOf<User>>({});
  const [loading, setLoading] = useState(false);

  const groupToCreate: Group = {
    active: false,
    created: new Date().getTime(),
    createdBy: { fullname: "", username: "" },
    id: "",
    products: Object.values(groupProducts).map((product) => product.id),
    tenant: "",
    title: groupTitle,
    updated: new Date().getTime(),
    users: Object.values(groupUsers).map((user) => user.email),
  };

  const handleGoBack = () => {
    dispatch(createGroupModalStepPrev());
  };

  const handleCloseClick = () => {
    dispatch(createGroupModalClosed());
  };

  // Faz a request para criar o grupo, quando clicado no submit do último form step
  const handleCreateGroup = async () => {
    setLoading(true);

    try {
      const groupRaw = getGroupRaw(groupToCreate);
      await dispatch(onCreateGroup(groupRaw));

      // Recarrega todos os grupos, produtos, usuários e grupos
      const promises = [
        dispatch(onLoadGroups()),
        dispatch(onLoadGroupProducts()),
        dispatch(onLoadLoggedUserGroups()),
        dispatch(onLoadUsers()),
        dispatch(onLoadGroupsAdmin()),
      ];
      await Promise.all(promises);

      const notification: ToastNotification = {
        message: t("Grupo criado com sucesso."),
        type: "success",
      };

      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, "Houve um erro ao criar o grupo. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  // Renderiza o form step atual, dentro do template
  const renderCurrentStep = () => {
    switch (createGroupCurrentStep) {
      case 1:
        return <GroupFormStep1 setTitle={setGroupTitle} title={groupTitle} />;
      case 2:
        return <GroupFormStep2 selectedProducts={groupProducts} setSelectedProducts={setGroupProducts} />;
      case 3:
        return <GroupFormStep3 groupUsers={groupUsers} setGroupUsers={setGroupUsers} />;
      case 4:
        return (
          <GroupFormStepLast
            actionButtonLabel="Criar grupo"
            loading={loading}
            newProducts={groupProducts}
            newTitle={groupTitle}
            newUsers={groupUsers}
            onSubmit={handleCreateGroup}
          />
        );
    }
  };

  return (
    <FullscreenDialog
      action="create"
      currentStep={createGroupCurrentStep}
      handleGoBack={handleGoBack}
      onClose={handleCloseClick}
      open={Boolean(createGroup)}
      title={t("Novo Grupo")}
    >
      <Box sx={{ alignItems: "center", display: "flex", height: "100%", justifyContent: "center" }}>
        {renderCurrentStep()}
      </Box>
    </FullscreenDialog>
  );
}
