import { AxiosError } from "axios";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogLoaded, toastCalled } from "../commonSlice";
import { ToastNotification } from "../types/ToastNotification";
import { ERROR_MESSAGES_DICTIONARY } from "./errorDictionary";

/** Hook que retorna um middleware para tratar erros de requisições para a API da Liquid.
 * @param error - O erro recebido.
 * @param toastMessage - A mensagem exibida no toast. Se não for passada, será exibida uma mensagem padrão.
 * @returns Um objeto contendo dados, mensagem e status de erro da API, ou uma mensagem padrão caso o componente queira tratar o erro de outra forma.
 */
export const useErrorHandler = () => {
  const dispatch = useAppDispatch();

  const { dialogLoading } = useAppSelector((state) => state.common);

  const handleLiquidErrors = (error: unknown, toastMessage?: string) => {
    const errorData = error as AxiosError;
    console;

    const data = errorData?.response?.data || {};
    const message = data.message || t("Houve um erro com a requisição. Tente novamente. [MENSAGEM PADRÂO]");
    const status = errorData?.response?.status || 500;

    !Object.values(data).length ? console.log("Error: ", errorData) : console.log("Error: ", data);

    if (status < 500) {
      // Short circuit para prevenir toast de erro nesse caso em específico.
      if (message === "missing terms and conditions acceptance") return { data, message, status };

      let notificationMessage;

      if (message in ERROR_MESSAGES_DICTIONARY) {
        notificationMessage = ERROR_MESSAGES_DICTIONARY[message];
      } else if (toastMessage) {
        notificationMessage = toastMessage;
      } else {
        notificationMessage = t("Aguarde alguns segundos e tente novamente.");
      }

      const notification: ToastNotification = {
        message: notificationMessage,
        type: "error",
      };

      dispatch(toastCalled(notification));

      if (dialogLoading) dispatch(dialogLoaded(false));
    }

    return { data, message, status };
  };

  return handleLiquidErrors;
};
