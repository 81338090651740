import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { formatValueToCurrency } from "../../../../../../utils/analysisDetailsDataFormatter";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";

type PDFSBPESimulatorBuildingTimeInfoRowProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelApprove: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  labelReprove: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "13px",
    paddingRight: "12px",
    paddingTop: "8px",
  },
  sbpeTableColumn: {
    width: "300px",
  },
  sbpeTableContainer: {
    alignItems: "center",
    borderBottom: "1px solid rgb(224, 226, 230)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
    justifyContent: "space-between",
    width: "100%",
  },
  sbpeTableRowText: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    textAlign: "left",
  },
  sbpeTableRowTitle: {
    alignSelf: "flex-start",
    color: "rgb(155, 162, 175)",
    fontSize: "13px",
    textAlign: "left",
  },
});

export default function PDFSBPESimulatorBuildingTimeInfoRow(props: PDFSBPESimulatorBuildingTimeInfoRowProps) {
  const { highlight } = props;

  const reachedBuildingPayment = highlight.max_time_payment >= highlight.building_payment_over_time;

  return (
    <View style={styles.sbpeTableContainer}>
      <View style={styles.sbpeTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.sbpeTableRowTitle}>Limite disponível de acordo com a renda</Text>
          <Text style={styles.sbpeTableRowText}>{formatValueToCurrency(highlight.max_time_payment, "BRL", true)}</Text>
        </View>
      </View>
      <View style={styles.sbpeTableColumn}>
        <View style={styles.genericFlexColumnContainer}>
          <Text style={styles.sbpeTableRowTitle}>Valor da parcela mensal máxima</Text>
          <Text style={styles.sbpeTableRowText}>
            {formatValueToCurrency(highlight.max_time_payment_installment, "BRL", true)}
          </Text>
        </View>
      </View>
      <Text style={reachedBuildingPayment ? styles.labelApprove : styles.labelReprove}>
        {reachedBuildingPayment ? "Atingiu o pagamento durante a obra" : "Não atingiu o pagamento durante a obra"}
      </Text>
    </View>
  );
}
