import { LqdButton, LqdCheckboxOffIcon, LqdCheckboxOnIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Checkbox, Divider } from "@mui/material";
import { t } from "i18next";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ObjectOf } from "../../common/types/ObjectOf";
import Group from "../../groups/types/Group";
import { GroupResponse } from "../../superadmin/types/GroupResponse";
import { createUserModalStepNext, editUserFormStepNext } from "../usersSlice";

type UserFormStep4Props = {
  selectedGroups: ObjectOf<Group>;
  setSelectedGroups: (value: ObjectOf<Group>) => void;
};

const haveSameGroups = (selectedGroups: Array<Group>, allGroups: Array<Group | GroupResponse>): boolean => {
  return (
    selectedGroups
      .map((group) => group.id)
      .sort()
      .join(",") ===
    allGroups
      .map((group) => (group as Group).id || (group as GroupResponse).code)
      .sort()
      .join(",")
  );
};

export default function UserFormStep4(props: UserFormStep4Props) {
  const { selectedGroups, setSelectedGroups } = props;

  const dispatch = useAppDispatch();

  const adminGroups = useAppSelector((state) => state.admin.groups);
  const { groups } = useAppSelector((state) => state.groups);
  const { userToEdit } = useAppSelector((state) => state.users);

  const handleSubmit = () => dispatch(userToEdit ? editUserFormStepNext() : createUserModalStepNext());

  const handleToggleGroups = (group: Group) => {
    if (!Object.values(selectedGroups).find((selectedGroup) => selectedGroup.id === group.id)) {
      setSelectedGroups({ ...selectedGroups, [group.id]: group });
    } else {
      const newSelectedGroups = { ...selectedGroups };
      delete newSelectedGroups[group.id];
      setSelectedGroups(newSelectedGroups);
    }
  };

  const handleToggleAllGroups = (checkAll: boolean) => {
    if (checkAll) {
      const allGroups: ObjectOf<Group> = groupsToList.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {} as ObjectOf<Group>);

      setSelectedGroups(allGroups);
    } else {
      setSelectedGroups({});
    }
  };

  const allGroups = userToEdit?.userRole === "super" ? { ...adminGroups } : { ...groups };
  const loggedUserGroupsIds = userToEdit?.groups.map((groupId) => allGroups[groupId]) || [];
  const groupsToList = Object.values(groups);
  const maySubmit = userToEdit
    ? !haveSameGroups(Object.values(selectedGroups), Object.values(loggedUserGroupsIds))
    : true;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mt: 5, pt: { lg: 7, md: 4 }, width: { lg: "60vw", md: "80vw" } }}>
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: { lg: 7.5, md: 3 } }} textstyle="h4Headline">
          {t("Grupos")}
        </LqdTypography>
        {groupsToList.length ? (
          <Box sx={{ maxHeight: "46.5vh", overflowY: "auto" }}>
            {groupsToList.map((group, index) => (
              <Fragment key={group.id}>
                <LqdTypography color="rgba(33, 36, 42, 1)">
                  <Checkbox
                    checked={Boolean(selectedGroups[group.id])}
                    checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={24} />}
                    icon={<LqdCheckboxOffIcon size={24} />}
                    onChange={() => handleToggleGroups(group)}
                    sx={{ height: "40px", mr: 1, width: "40px" }}
                  />
                  {group.title}
                </LqdTypography>
                <Divider sx={{ mb: index === groupsToList.length - 1 ? 0 : 3, mt: 3 }} />
              </Fragment>
            ))}
          </Box>
        ) : (
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", py: 7, textAlign: "center" }} textstyle="h5Headline">
            {t("Não há nenhum grupo criado até o momento.")}
          </LqdTypography>
        )}
      </Box>
      <Box sx={{ mt: "60px", textAlign: "right" }}>
        <LqdButton
          onClick={() => handleToggleAllGroups(groupsToList.length !== Object.keys(selectedGroups).length)}
          sx={{ mr: 3 }}
          type="outlineTertiary"
        >
          {groupsToList.length === Object.keys(selectedGroups).length ? "Limpar seleção" : "Selecionar todos"}
        </LqdButton>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
