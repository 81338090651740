import { LqdIconProps } from "../../types/IconProps";

export default function LqdWorldIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        clipRule="evenodd"
        d="M10.168 4.05999C7.278 8.88499 7.278 15.116 10.168 19.941C11.014 21.354 12.987 21.354 13.833 19.941C16.723 15.116 16.723 8.88499 13.833 4.05999C12.986 2.64699 11.014 2.64699 10.168 4.05999Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3 12H21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
