import { LqdIconProps } from "../../types/IconProps";

export default function LqdSearchCPFIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 15H7C4.79086 15 3 16.7909 3 19V20"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <circle cx="11" cy="7" r="4" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
      <circle
        cx="17.4444"
        cy="16.5556"
        r="3.55556"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M14.9303 19.0697L13 21"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
