import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterTwelve() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        12. LIMITE DE RESPONSABILIDADE
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.1. A responsabilidade da Liquid por danos é limitada a danos diretos, excluindo expressamente lucros
        cessantes e danos indiretos, limitado a um valor equivalente a 100% (cem por cento) do valor anual da
        Retribuição, conforme Proposta Comercial, ou pelos valores já pagos pelo Usuário Decisor, o que for menor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.2. Ficam expressamente excluídas da limitação de responsabilidade aqui prevista as hipóteses defesas em lei,
        devendo-se, em tais casos, aplicar-se a legislação aplicável.
      </LqdTypography>
    </>
  );
}
