export const getDocumentName = (fileURL: string) => {
  const splitFileURL = fileURL.split("/").pop();
  const fileNameRaw = splitFileURL?.split("?").shift();
  const fileName = fileNameRaw?.split("-");

  if (!fileName) {
    return fileNameRaw;
  }

  return fileName[1];
};

export const downloadDocument = (documentUrl: string) => {
  window.open(documentUrl, "_blank");
  const newTab = window.open(documentUrl, "_blank");

  if (!newTab) return;

  newTab.close();
};
