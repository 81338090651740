import { ObjectOf } from "../../../../../common/types/ObjectOf";

export const openCreditTerms: ObjectOf<string> = {
  v110: "até 30 dias",
  v120: "de 31 a 60 dias",
  v130: "de 61 a 90 dias",
  v140: "de 91 a 180 dias",
  v150: "de 181 a 360 dias",
  v160: "acima de 360 dias",
};

export const openCreditModalitiesTerms: ObjectOf<ObjectOf<string>> = {
  "01": {
    "01": "Adiantamentos a depositantes",
    name: "Adiantamentos a depositantes",
  },
  "02": {
    "02": "Crédito pessoal - com consignação em folha de pagam.",
    "03": "Crédito pessoal - sem consignação em folha de pagam.",
    "04": "Crédito rotativo vinculado a cartão de crédito",
    "07": "Vendedor",
    "08": "Comprador",
    "09": "ARO - Adiantamento de receitas orçamentárias",
    "10": "Cartão de crédito - compra, fatura parcelada ou saque financiado pela instituição emitente do cartão",
    "11": "Home equity",
    "12": "Microcrédito",
    "13": "Cheque especial",
    "14": "Conta garantida",
    "15": "Capital de giro com prazo de vencimento até 365 d",
    "16": "Capital de giro com prazo vencimento superior 365 d",
    "17": "Capital de giro com teto rotativo",
    "18": "Cartão de crédito - não migrado",
    "50": "Recebíveis adquiridos",
    "90": "Financiamento de projeto",
    "99": "Outros empréstimos",
    name: "Empréstimos",
  },
  "03": {
    "01": "Desconto de duplicatas",
    "02": "Desconto de cheques",
    "03": "Antecipação de fatura de cartão de crédito",
    "98": "Outros direitos creditórios descontados",
    "99": "Outros títulos descontados",
    name: "Direitos creditórios descontados",
  },
  "04": {
    "01": "Aquisição de bens - veículos automotores",
    "02": "Aquisição de bens - outros bens",
    "03": "Microcrédito",
    "04": "Vendedor",
    "05": "Comprador",
    "06": "Cartão de crédito - compra ou fatura parcelada pela instituição financeira emitente do cartão",
    "40": "Financiamentos agroindustriais",
    "50": "Recebíveis adquiridos",
    "90": "Financiamento de projeto",
    "99": "Outros financiamentos",
    name: "Financiamentos",
  },
  "05": {
    "01": "Financiamento à exportação",
    "02": "Adiantamento sobre contratos de câmbio",
    "03": "Adiantamento sobre cambiais entregues",
    "04": "Créd decorrentes de contratos de exportação-export note",
    "90": "Financiamento de projeto",
    "99": "Outros financiamentos à exportação",
    name: "Financiamentos à exportação",
  },
  "06": {
    "01": "Financiamento à importação",
    "90": "Financiamento de projeto",
    name: "Financiamentos à importação",
  },
  "07": {
    "01": "Aquisição de bens com interveniência - veículos autom.",
    "02": "Aquisição de bens com interveniência - outros bens",
    "90": "Financiamento de projeto",
    "99": "Outros financiamentos com interveniência",
    name: "Financiamentos com interveniência",
  },
  "08": {
    "01": "Custeio",
    "02": "Investimento",
    "03": "Comercialização",
    "04": "Industrialização",
    name: "Financiamentos rurais",
  },
  "09": {
    "01": "Financiamento habitacional - SFH",
    "02": "Financiamento habitacional - exceto SFH",
    "03": "Financiamento imobiliário - empreendim, exceto habitac.",
    "90": "Financiamento de projeto",
    name: "Financiamentos imobiliários",
  },
  "10": {
    "01": "Financiamento de TVM",
    name: "Financiamentos de títulos e valores mobiliários",
  },
  "11": {
    "01": "Financiamento de infraestrutura e desenvolvimento",
    "90": "Financiamento de projeto",
    name: "Financiamentos de infraestrutura e desenvolvimento",
  },
  "12": {
    "01": "Arrendamento financeiro exceto veículos automotores e imóveis",
    "02": "Arrendamento financeiro imobiliário",
    "03": "Subarrendamento",
    "05": "Arrendamento operacional",
    "06": "Arrendamento financeiro de veículos automotores",
    "90": "Financiamento de projeto",
    name: "Operações de arrendamento",
  },
  "13": {
    "01": "Avaís e fianças honrados",
    "02": "Devedores por compra de valores e bens",
    "03": "Títulos e créditos a receber",
    "04": "Cartão de crédito - compra à vista e parcelado lojista",
    "50": "Recebíveis adquiridos",
    "90": "Financiamento de projeto",
    "99": "Outros com característica de crédito",
    name: "Outros créditos",
  },
  "14": {
    "01": "Repasses interfinanceiros",
    "02": "Recebíveis de arranjo de pagamento",
    "03": "Outros valores a receber relativos a transações de pagamento",
    name: "Relações Interfinanceiras",
  },
  "15": {
    "01": "Beneficiários de garantias prestadas para operações com PJ financeira",
    "02": "Beneficiários de garantias prestadas para operações com outras pessoas",
    "03": "Beneficiários de garantias prestadas para fundos constitucionais",
    "04": "Beneficiários de garantias prestadas para participação em processo licitatório",
    "05": "Carta de crédito de importação",
    "11": "Coobrigação assumida em cessão com coobrigação para pessoa integrante do SFN",
    "12": `Coobrigação assumida em cessão com coobrigação para pessoa não integrante do SFN, 
    inclusive securitizadora e fundos de investimento`,
    "13": "Beneficiários de outras coobrigações",
    "90": "Financiamento de projeto",
    "99": "Beneficiários de outras garantias prestadas",
    name: "Coobrigações",
  },
  "18": {
    "01": "CPR - Cédula de Produto Rural",
    "02": "EN - Nota de Exportação",
    "03": "Debêntures",
    "04": "Notas comerciais",
    "99": "Outros",
    name: "Títulos de crédito (fora da carteira classificada)",
  },
  "19": {
    "01": "Limite global",
    "02": "Cheque especial",
    "03": "Conta garantida",
    "04": "Cartão de crédito",
    "05": "Capital de giro",
    "06": "Crédito pessoal",
    "07": "Vendor",
    "08": "Compror",
    "09": "Descontos",
    "10": "Aquisição de bens",
    "99": "Outros",
    name: "Limite",
  },
  "20": {
    "01": "Retenção de risco assumida por aquisição de cotas de fundos",
    "02": "Retenção de risco assumida por aquisição de instrumentos com lastros em operações de crédito",
    name: "Retenção de risco",
  },
};
