import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterEight() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        8. OBRIGAÇÕES DA LIQUID
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        8.1. A Liquid se obriga a:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) Manter o Liquid atualizado e em plena operação, em conformidade com o disposto no item 4 acima; e
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) Cumprir com os prazos ajustados nestes Termos.
      </LqdTypography>
    </>
  );
}
