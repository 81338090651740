import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
  },
});

export default function PDFCaixaSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 400 400">
        <Circle cx="200" cy="200" fill="rgb(1, 109, 184)" r="200" />
        <Path
          d="M169.259 195.609H233.912L199.01 126H134.548L169.259 195.609Z"
          fill="rgb(255, 255, 255)"
          fillRule="evenodd"
        />
        <Path
          d="M200.666 273.042H264.934L229.842 203.428L187.437 247.801L200.666 273.042Z"
          fill="rgb(255, 255, 255)"
          fillRule="evenodd"
        />
        <Path
          d="M235.538 126.286L211.876 151.561L234.011 195.705L300 126.286H235.538Z"
          fill="rgb(248, 162, 61)"
          fillRule="evenodd"
        />
        <Path
          d="M165.609 203.4L100 272.247H163.892L229.686 203.4H165.609Z"
          fill="rgb(248, 162, 61)"
          fillRule="evenodd"
        />
      </Svg>
    </View>
  );
}
