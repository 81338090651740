import { Menu, MenuProps } from "@mui/material";

export default function LqdMenu(props: MenuProps) {
  const { MenuListProps, slotProps, transformOrigin } = props;

  return (
    <Menu
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      MenuListProps={{ sx: { py: 0, ...MenuListProps?.sx }, ...MenuListProps }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "10px",
            boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.2)",
            mt: 1.5,
          },
          ...slotProps?.paper,
        },
        ...slotProps,
      }}
      transformOrigin={{ horizontal: "right", vertical: "top", ...transformOrigin }}
      {...props}
    />
  );
}
