import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";

type CalculateNodePositionProps = {
  blocks: Array<SimulatorFunctionBlock>;
  simulator: Simulator;
};

/**
 * Calcula informações de destino para um simulador.
 *
 * @param simulator - O objeto do simulador contendo blocos e funções.
 * @returns Um objeto onde as chaves são IDs de blocos e os valores são informações calculadas baseadas nas variáveis e funções dos blocos.
 *
 * O cálculo é feito da seguinte forma:
 * - Para cada bloco no simulador, encontra a função "jump_conditional".
 * - Se a função "jump_conditional" existir, obtém os IDs dos blocos verdadeiros e falsos.
 * - Para cada bloco de destino, calcula a informação baseada no número de variáveis e funções:
 *   - Se o número de variáveis for maior que 15, multiplica por 15, caso contrário, multiplica por 20.
 *   - Se o número de funções for maior que 5, multiplica por 15, caso contrário, multiplica por 30.
 */
const calculateTargetInfo = (simulator: Simulator): Record<number, number> => {
  const targetInfo: Record<number, number> = {};

  simulator.blocks.forEach((block) => {
    const jumpConditionalFunction = block.functions.find((func) => func.function_name === "jump_conditional");

    if (jumpConditionalFunction) {
      const blockTrueId = Number(jumpConditionalFunction.variables.block_true);
      const blockFalseId = Number(jumpConditionalFunction.variables.block_false);

      const calculateInfo = (blockId: number): number => {
        const targetBlock = simulator.blocks.find((b) => b.block_id === blockId);
        if (!targetBlock) return 0;

        const variablesCount = targetBlock.functions.reduce((acc, func) => acc + Object.keys(func.variables).length, 0);
        const functionsCount = targetBlock.functions.length;

        return variablesCount * (variablesCount > 15 ? 15 : 20) + functionsCount * (functionsCount > 5 ? 15 : 30);
      };

      targetInfo[blockTrueId] = calculateInfo(blockTrueId);
      targetInfo[blockFalseId] = calculateInfo(blockFalseId);
    }
  });

  return targetInfo;
};

/**
 * Calcula a posição dos nós no simulador.
 *
 * @param props - As propriedades necessárias para calcular a posição dos nós.
 * @param props.blocks - Os blocos do simulador.
 * @param props.simulator - O simulador contendo os blocos e funções.
 * @returns Uma lista de objetos contendo a posição e dados dos blocos.
 */
export default function calculateNodePosition(props: CalculateNodePositionProps) {
  const { blocks, simulator } = props;

  return blocks.map((block, index) => {
    const targetInfo = calculateTargetInfo(simulator);

    const targetsFalse = simulator.blocks
      .filter((block) => block.functions.some((fn) => fn.function_name === "jump_conditional"))
      .flatMap((b) => b.functions)
      .filter((fn) => fn.function_name === "jump_conditional")
      .map((fn) => Number(fn.variables.block_false as number));

    const targetsTrue = simulator.blocks
      .filter((block) => block.functions.some((fn) => fn.function_name === "jump_conditional"))
      .flatMap((block) => block.functions)
      .filter((fn) => fn.function_name === "jump_conditional")
      .map((fn) => Number(fn.variables.block_true));

    const isTargetFalse = targetsFalse.includes(block.block_id);
    const isTargetTrue = targetsTrue.includes(block.block_id);

    let x = 500 + index * 500;
    let y = 5;

    if (index === 0) {
      x = 200;
    }

    if (isTargetFalse) {
      y = 60 + (targetInfo[block.block_id] || 0) * 2;
      x = 200 + index * 500;
    }

    if (isTargetTrue) {
      y = 60 - (targetInfo[block.block_id] || 0) * 2;
      x = 200 + (index + 1) * 500;
    }

    return {
      data: { block },
      id: `${block.block_id}`,
      position: { x, y },
      type: "customNode",
    };
  });
}
