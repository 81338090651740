import { AppError, AppErrorOptions } from "../../common/types/AppError";

export class NullRulesError extends AppError {
  constructor(options?: AppErrorOptions) {
    super(
      {
        message: "Houve um erro ao carregar os dados do produto (data: null).",
      },
      options
    );
  }
}
