import { ObjectOf } from "../../common/types/ObjectOf";
import { put } from "../../common/utils/apiUtils";

/**
 * Endpoint para arquivar uma conexão.
 * @param list Lista de conexões a serem arquivadas.
 * @returns Array de conexões arquivadas, com uma mensagem de sucesso.
 * @remarks Endpoint ADMIN.
 */
export const archiveAnalysis = (list: Array<ObjectOf<boolean>>) =>
  put<Array<{ analysis_id: string; message: string }>>(`/analysis/archive`, list);
