import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onFinishingCloudSigning } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import ActionFailed from "./ActionFailed";
import SignatureLoadingScreen from "./SignatureLoadingScreen";
import SignatureSuccess from "./SignatureSuccess";

export default function SignatureFinish() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { currentView } = useAppSelector((state) => state.signature);

  const sessionQueryParam = searchParams.get("session");

  useEffect(() => {
    if (sessionQueryParam) {
      const token = localStorage.getItem("peer_token");
      const data = { peerToken: token!, session: sessionQueryParam };
      dispatch(onFinishingCloudSigning(data));
      window.localStorage.removeItem("peer_token");
    }
  }, []);

  const renderContent = () => {
    switch (currentView) {
      case SignatureViews.SIGNATURE_SUCCESS:
        return <SignatureSuccess />;
      case SignatureViews.SIGNATURE_FAILED:
        return <ActionFailed action="signature" />;
      default:
        return <SignatureLoadingScreen />;
    }
  };

  return renderContent();
}
