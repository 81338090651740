import { LqdIconProps } from "../../types/IconProps";

export default function LqdEyeClosedIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5581 13.5577C13.9186 14.6361 12.6763 15.2036 11.4425 14.9811C10.2087 14.7585 9.24295 13.7928 9.02042 12.559C8.79789 11.3251 9.36538 10.0829 10.4437 9.44336"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17.9966 16.9962C16.2718 18.3044 14.1648 19.0095 12 19.0029C8.41291 19.0668 5.09856 17.0954 3.44245 13.9128C2.84755 12.707 2.84755 11.293 3.44245 10.0872C4.2707 8.43785 5.591 7.08659 7.22074 6.22034"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M20.4272 14.1345C20.4674 14.0585 20.5196 13.9903 20.5576 13.9128C21.1524 12.707 21.1524 11.293 20.5576 10.0872C18.9015 6.90465 15.5871 4.93323 12 4.99711C11.7751 4.99711 11.5565 5.02712 11.3345 5.04175"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M21.0037 20.0034L3.99664 2.99628"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
