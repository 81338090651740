import { Edge } from "react-flow-renderer";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { ActionNodeData, ProviderNodeData } from "../types/NodeData";

export const getEdgeColor = (edgeId: string, nodes: Array<Node>, edges: Array<Edge>) => {
  const edge = edges.find((edge) => edge.id === edgeId);
  let edgeColor = "rgba(127, 135, 152, 1)";
  if (edge) {
    const targetNode = nodes.find((node) => node.id === edge.target);
    const sourceNode = nodes.find((node) => node.id === edge.source);
    if (targetNode && sourceNode) {
      // Se o nó for de action
      if ((targetNode.data as ActionNodeData).action) {
        const { action } = targetNode.data as ActionNodeData;
        switch (action) {
          case "match":
            edgeColor = "rgba(41, 176, 160, 1)";
            break;
          case "unmatch":
            edgeColor = "rgba(246, 61, 94, 1)";
            break;
          case "moderation":
            edgeColor = "rgba(246, 216, 61, 1)";
            break;
          case "warning":
            edgeColor = "rgba(246, 216, 61, 1)";
            break;
          case "jump":
            edgeColor = "rgba(179, 169, 255, 1)";
            break;
        }
      }
      // Se for uma edge ligando o nó de start-flow a um nó de provider
      if (sourceNode.id === "node-start_flow" && (targetNode.data as ProviderNodeData).provider) {
        edgeColor = "rgba(155, 162, 175, 1)";
      }
    }
  }
  return edgeColor;
};
