import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { ProductBankRatesRaw } from "../types/Simulation/SBPE/ProductBankRatesRaw";

/**
 * Endpoint que retorna as taxas bancárias de um produto (em caso de Simulador Parametrizado).
 * @param productId ID do produto.
 * @returns Taxas bancárias do produto, no formato ProductBankRatesRaw.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getProductBankRates = (productId: string): AxiosPromise<Array<ProductBankRatesRaw>> => {
  return get(`/simulation/allotment/bank-rates/${productId}`);
};
