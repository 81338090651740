import { LqdCloseIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { Box, Divider, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LiquidLogo from "../../common/components/LiquidLogo";
import { useScrollFadeEffect } from "../../common/hooks/useScrollFadeEffect";
import { ObjectOf } from "../../common/types/ObjectOf";
import TermsAndConditionsOptionOne from "../components/TermsAndConditionsOptionOne";
import TermsAndConditionsOptionTwo from "../components/TermsAndConditionsOptionsTwo";

const OPTIONS_MENU: ObjectOf<string> = {
  1: "Termos e Condições de Uso",
  2: "Política de Privacidade",
};

const OPTIONS_TITLES: ObjectOf<string> = {
  1: "Termos e Condições de Uso do Software Liquid",
  2: "Política de Privacidade",
};

const OPTIONS_TEXTS: ObjectOf<JSX.Element> = {
  1: <TermsAndConditionsOptionOne />,
  2: <TermsAndConditionsOptionTwo />,
};

export default function TermsAndConditionsRoute() {
  const navigate = useNavigate();
  const { fadeElementBox, scrollBoxRef } = useScrollFadeEffect("200px");

  const [selectedOption, setSelectedOption] = useState(1);

  /** Fecha o modal e redireciona para a tela de login. */
  const handleClose = () => {
    navigate("/signin");
    setSelectedOption(1);
  };

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [selectedOption]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ backgroundColor: "rgba(250, 250, 250, 1)", height: "100vh", pl: 10, pr: 12.5, pt: 15, width: "25%" }}>
        <LiquidLogo height={52} sx={{ mb: 10 }} width={140} />
        <Stack gap={1.5}>
          {Object.values(OPTIONS_MENU).map((option, index) => {
            const isSelected = selectedOption === index + 1;

            return (
              <LqdTypography
                key={option}
                onClick={() => setSelectedOption(index + 1)}
                sx={{
                  borderLeft: isSelected ? "solid 7px rgba(56, 61, 70, 1)" : null,
                  color: isSelected ? "rgba(56, 61, 70, 1)" : "rgba(155, 162, 175, 1)",
                  cursor: "pointer",
                  fontWeight: isSelected ? 400 : 300,
                  pl: 2.5,
                }}
                textstyle="h5Headline"
              >
                {option}
              </LqdTypography>
            );
          })}
        </Stack>
      </Box>
      <Box sx={{ height: "100vh", pt: 15, px: 17.5, width: "70%" }}>
        <LqdIconButton
          onClick={handleClose}
          round="true"
          sx={{ position: "absolute", right: 30, top: 30 }}
          type="outlinePrimary"
        >
          <LqdCloseIcon />
        </LqdIconButton>
        <LqdTypography sx={{ maxWidth: "690px" }} textstyle="h2Headline">
          {OPTIONS_TITLES[selectedOption]}
        </LqdTypography>
        <Divider sx={{ backgroundColor: "rgba(155, 162, 175, 1)", mt: 4, width: "85%" }} />
        <Box ref={scrollBoxRef} sx={{ maxHeight: "550px", mt: 4, overflow: "auto", pr: 5.5, width: "85%" }}>
          {OPTIONS_TEXTS[selectedOption]}
        </Box>
        {fadeElementBox}
      </Box>
    </Box>
  );
}
