import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { Dispatch, SetStateAction } from "react";
import { ObjectOf } from "../../../../../common/types/ObjectOf";
import { SimulatorFunction } from "../../../../types/SimulatorFunction";
import { SimulatorFunctionTemplateVariable } from "../../../../types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";
import { SimulatorVariableTemplateType } from "../../../../types/SimulatorVariableTypeTemplate";
import { SimulatorVariableTypes } from "../../../../types/SimulatorVariableTypes";
import CompareInputLayout from "./Layouts/SimulatorFunctionBlocksFunctionCompareInputLayout";
import ConditionalInputLayout from "./Layouts/SimulatorFunctionBlocksFunctionConditionalInputLayout";
import DefaultInputLayout from "./Layouts/SimulatorFunctionBlocksFunctionDefaultInputLayout";
import DynamicInputLayout from "./Layouts/SimulatorFunctionBlocksFunctionDynamicInputLayout";
import MultipleInputLayout from "./Layouts/SimulatorFunctionBlocksFunctionMultipleInputLayout";

export type VariableInputProps = {
  currentBlock: SimulatorFunctionBlock;
  editVariable?: JSX.Element;
  func: SimulatorFunction;
  isOpen: boolean;
  isResult: boolean;
  newOption: JSX.Element | null;
  onBlur?: () => Array<SimulatorVariable>;
  onChange: Dispatch<SetStateAction<string>>;
  options: Array<{
    id: string;
    label: string;
    type: SimulatorVariableTemplateType | SimulatorVariableTypes;
    value: number | string;
  }>;
  value: number | string;
  variableData: { key: string; variable: SimulatorFunctionTemplateVariable };
};

export type LayoutProps = {
  currentBlock: SimulatorFunctionBlock;
  editVariableButton: JSX.Element;
  func: SimulatorFunction;
  layout: string;
  newBlockButton: JSX.Element;
  newVariableButton: JSX.Element;
  onBlurFunction: () => Array<SimulatorVariable>;
  onUpdateFunction: (
    key: string,
    value: string | number,
    remove?: boolean,
    isResult?: boolean,
    isResultName?: boolean
  ) => void;
  openSelectComponent: boolean;
  renderOptions: ObjectOf<
    Array<{
      id: string;
      label: string;
      type: SimulatorVariableTemplateType | SimulatorVariableTypes;
      value: number | string;
    }>
  >;
  resultInputs: ObjectOf<SimulatorFunctionTemplateVariable>;
  variableInputs: ObjectOf<SimulatorFunctionTemplateVariable>;
};

const layoutComponents: ObjectOf<(props: LayoutProps) => JSX.Element> = {
  compare: CompareInputLayout,
  conditional: ConditionalInputLayout,
  default: DefaultInputLayout,
  dynamic: DynamicInputLayout,
  multiple: MultipleInputLayout,
};

export default function SimulatorFunctionBlocksFunctionInputsRenderer(props: LayoutProps) {
  const { layout } = props;

  const LayoutComponent = layoutComponents[layout] || layoutComponents.default;

  return <LayoutComponent {...props} />;
}
