import { LqdButton, LqdCircularLoader, LqdDialog, LqdDialogActions, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled } from "../../commonSlice";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";

/**
 * Componente de SmallDialog para o login com MFA. Exibe um <img /> com src do QRCode.
 * @param actions Lista de ações do Modal.
 * @param body Texto principal do Modal.
 * @param bodySecundary Texto secundário do Modal.
 * @param title Título do Modal.
 */
export default function SmallDialogMFA() {
  const dispatch = useAppDispatch();

  const { dialogContent, dialogLoading, dialogShowState } = useAppSelector((state) => state.common);

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const handleCloseDialog = () => {
    dispatch(dialogCalled(null));
  };

  const handleConfirmClick = (action: SmallDialogAction) => {
    action.onClick ? action.onClick() : handleCloseDialog();
  };

  return (
    <LqdDialog onClose={handleCloseDialog} open={dialogShowState}>
      <SmallDialogHeader body={dialogContent.body} title={dialogContent.title} />
      {dialogContent.qrCodeURL ? (
        <Box
          alt="QR Code para autenticação de dois fatores"
          component="img"
          src={dialogContent.qrCodeURL}
          sx={{ height: "186px", margin: "auto", my: 1.5, width: "186px" }}
        />
      ) : null}
      {dialogContent.bodySecundary ? (
        <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="c1Caption">
          {dialogContent.bodySecundary}
        </LqdTypography>
      ) : null}
      <LqdDialogActions sx={{ mt: 2.5 }}>
        {dialogContent.actions.map((action, index, actions) => {
          return (
            <LqdButton
              disabled={dialogLoading}
              key={action.title}
              onClick={() => handleConfirmClick(action)}
              type={getButtonType(index, actions)}
            >
              {dialogLoading && actions.length > 1 && index !== 0 ? (
                <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
              ) : (
                action.title
              )}
            </LqdButton>
          );
        })}
      </LqdDialogActions>
    </LqdDialog>
  );
}
