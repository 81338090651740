import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterFifteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        15. PROTEÇÃO DE DADOS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        15.1. O Usuário Decisor se compromete a seguir integralmente todo o disposto na Política, disponível no link
        https://app.liquidpass.co/terms-and-conditions, bem como em estrita conformidade com a legislação aplicável, em
        especial, mas não se limitando à Lei nº 13.709, de 14 de agosto de 2018 (LGPD), declarando-se igualmente ciente
        de que a Liquid poderá, a qualquer tempo e a seu único e exclusivo critério, realizar a alteração dos termos da
        Política.
      </LqdTypography>
    </>
  );
}
