import { LqdButton, LqdPlusIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Stack, SxProps } from "@mui/material";

type SettingsRouteHeaderProps = {
  buttonMethod: () => void;
  buttonTitle: string;
  sx?: SxProps;
  title: string;
  tooltipBody: string;
  tooltipHeader: string;
};

export default function SettingsRouteHeader(props: SettingsRouteHeaderProps) {
  const { buttonMethod, buttonTitle, sx, title, tooltipBody, tooltipHeader } = props;

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        pb: 3,
        px: { lg: "100px", md: 6, xs: 4 },
        ...sx,
      }}
    >
      <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h4Headline">
        {title}
      </LqdTypography>
      <LqdTooltip placement="left" titlebody={tooltipBody} titleheader={tooltipHeader}>
        <LqdButton onClick={buttonMethod} startIcon={<LqdPlusIcon />}>
          {buttonTitle}
        </LqdButton>
      </LqdTooltip>
    </Stack>
  );
}
