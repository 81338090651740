import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AllotmentSimHighlight } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationHighlight";
import { getPortugueseFullDate } from "../../../../../../utils/analysisDetailsDataFormatter";
import PDFAllotmentSimulatorTableRow1 from "./PDFAllotmentSimulatorTableRow1";
import PDFAllotmentSimulatorTableRow2 from "./PDFAllotmentSimulatorTableRow2";
import PDFAllotmentSimulatorTableRow3 from "./PDFAllotmentSimulatorTableRow3";

type PDFAllotmentSimulatorTableProps = {
  highlight: AllotmentSimHighlight;
};

const styles = StyleSheet.create({
  allotmentSimulatorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  allotmentSimulatorDateText: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
    paddingTop: "52px",
  },
  allotmentSimulatorLabel: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  allotmentTableContainer: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    paddingHorizontal: "24px",
    width: "100%",
  },
});

export default function PDFAllotmentSimulatorTable(props: PDFAllotmentSimulatorTableProps) {
  const { highlight } = props;

  return (
    <View minPresenceAhead={40}>
      <View style={styles.allotmentSimulatorContainer}>
        <Text style={styles.allotmentSimulatorLabel}>Simulador Liquid Parametrizado</Text>
        <Text style={styles.allotmentSimulatorDateText}>
          Data da última atualização: {getPortugueseFullDate(highlight.updated_at)}
        </Text>
      </View>
      <View minPresenceAhead={40} style={styles.allotmentTableContainer}>
        <PDFAllotmentSimulatorTableRow1 highlight={highlight} />
        <PDFAllotmentSimulatorTableRow2 highlight={highlight} />
        <PDFAllotmentSimulatorTableRow3 highlight={highlight} />
      </View>
    </View>
  );
}
