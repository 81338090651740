/** Função para formatar o nome de uma variável de acordo com as regras de nomenclatura.
 * @param name Nome da variável a ser formatada.
 * @returns Nome da variável formatada.
 */
export default function formatVariableName(name: string): string {
  if (!name) return "";

  let formattedName = name
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_-]/g, "")
    .replace(/[_-]+/g, "_");

  if (formattedName === "_" || formattedName === "-") formattedName = "";

  return formattedName.substring(0, 35);
}
