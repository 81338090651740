import { MenuItem, MenuItemProps } from "@mui/material";

export default function LqdMenuItem(props: MenuItemProps) {
  const { sx } = props;

  return (
    <MenuItem
      {...props}
      sx={{
        "&:hover": { backgroundColor: "rgba(240, 241, 243, 1)" },
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: "rgba(101, 110, 127, 1)",
        minWidth: "157px",
        px: 2,
        py: "12px",
        ...sx,
      }}
    />
  );
}
