import { themes } from "@/liquid-components/src";
import { ThemeProvider } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import { ReactFlowProvider } from "reactflow";
import SmallDialog from "./features/common/components/SmallDialog/SmallDialog";
import { ToastNotification } from "./features/common/components/ToastNotification";
import HttpProvider from "./features/common/utils/http";
import i18n from "./i18n";
import RoutesProvider from "./routes";

export default function App() {
  return (
    <div className="App">
      <ReactFlowProvider>
        <ThemeProvider theme={themes.Liquid}>
          <HttpProvider>
            <I18nextProvider i18n={i18n}>
              <RoutesProvider />
              <SmallDialog />
            </I18nextProvider>
            <ToastNotification />
          </HttpProvider>
        </ThemeProvider>
      </ReactFlowProvider>
    </div>
  );
}
