import { LqdTable, LqdToggle, LqdTypography } from "@/liquid-components/src";
import SettingsRouteHeader from "@common/components/SettingsRouteHeader";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { Box, Stack } from "@mui/material";
import { GroupResponse } from "@superadmin/types/GroupResponse";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../common/commonSlice";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { onLoadGroups } from "../../groups/groupsSlice";
import cancelInvite from "../api/cancelInvite";
import inviteUser from "../api/inviteUser";
import updateUser from "../api/updateUser";
import CreateUserForm from "../components/CreateUserForm";
import EditUserForm from "../components/EditUserForm";
import { User } from "../types/User";
import { createUserModalOpened, editUserModalOpened, onLoadUsers } from "../usersSlice";
import { getUserRaw } from "../utils/getUserRaw";

export default function UsersPage() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const groupsAdmin = useAppSelector((state) => state.admin.groups);
  const { tenants } = useAppSelector((state) => state.admin);
  const allGroups = useAppSelector((state) => state.groups.groups);
  const allUsers = useAppSelector((state) => state.users.users);
  const { currentUser } = useAppSelector((state) => state.users);

  const groups = Object.values(allGroups);
  const users = Object.values(allUsers);

  useEffect(() => {
    const update = searchParams.get("update");
    if (update === "success") {
      const notification: ToastNotification = {
        message: "Usuário criado com sucesso",
        type: "success",
      };

      dispatch(toastCalled(notification));
      window.history.replaceState(null, "Liquid", "/config/users");
    }
  }, [searchParams]);

  const getUserGroups = (user: User) => {
    const userGroups = groups.filter((group) => group.users.includes(user.email)).map((group) => group.id);
    return userGroups;
  };

  const handleToggleUser = async (user: User, confirmed?: boolean) => {
    if (user.active && !confirmed) {
      dispatch(
        dialogCalled({
          actions: [{ title: "Cancelar" }, { onClick: () => onConfirmInactivateUser(user), title: "Confirmar" }],
          body: "Tem certeza que deseja desativar o usuário?",
          title: "Desativar usuário",
          type: "default",
        })
      );
    }

    if (confirmed) {
      const updatedUser = { ...user, active: !user.active, groups: getUserGroups(user) };
      const userRaw = getUserRaw(updatedUser);
      dispatch(dialogLoaded(true));

      try {
        await updateUser(userRaw);
        await dispatch(onLoadUsers());
      } catch (error) {
        handleLiquidErrors(error, "Houve um erro ao ativar o convite. Tente novamente.");
      } finally {
        dispatch(dialogCalled(null));
      }
    }
  };

  const onConfirmInactivateUser = async (user: User) => {
    await handleToggleUser(user, true);
    dispatch(dialogCalled(null));
  };

  const onResendInvite = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: () => onConfirmResendInvite(user), title: "Confirmar" }],
        body: "Tem certeza que deseja reenviar o convite?",
        title: "Reenviar convite",
        type: "default",
      })
    );
  };

  const onCancelInvite = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: () => onConfirmCancelInvite(user), title: "Confirmar" }],
        body: "Tem certeza que deseja cancelar o convite?",
        title: "Cancelar convite",
        type: "default",
      })
    );
  };

  const onChangeNotification = (user: User) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: () => onConfirmChangeNotification(user), title: "Confirmar" }],
        body: "Tem certeza que deseja alterar as preferências de notificação deste usuário?",
        title: "Preferências de Notificação",
        type: "default",
      })
    );
  };

  const onConfirmResendInvite = async (user: User) => {
    dispatch(dialogLoaded(true));

    try {
      await inviteUser(user.email);

      const notification: ToastNotification = {
        message: "Convite reenviado com sucesso.",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, "Houve um erro ao reenviar o convite. Tente novamente.");
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  const onConfirmCancelInvite = async (user: User) => {
    dispatch(dialogLoaded(true));

    try {
      await cancelInvite(user.email);

      const promises = [dispatch(onLoadUsers()), dispatch(onLoadGroups())];
      await Promise.all(promises);

      const notification: ToastNotification = {
        message: "Convite cancelado com sucesso.",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, "Houve um erro ao cancelar o convite. Tente novamente.");
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  const onConfirmChangeNotification = async (user: User) => {
    const updatedUser = { ...user, groups: getUserGroups(user), notifications: !user.notifications };
    const userRaw = getUserRaw(updatedUser);

    try {
      dispatch(dialogLoaded(true));
      await updateUser(userRaw);
      await dispatch(onLoadUsers());

      const notification: ToastNotification = {
        message: "Preferências de notificação modificadas com sucesso.",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error, "Houve um erro ao modificar as preferências de notificação. Tente novamente.");
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  const tableContent = users.map((user) => {
    const userGroups = user.groups.map((groupId) => allGroups[groupId]);
    const adminGroups: Array<GroupResponse> =
      currentUser?.userRole === "super"
        ? Object.values(groupsAdmin).filter((group) => group.users.includes(user?.email))
        : [];

    const firstThreeUserGroups = userGroups.slice(0, 3);
    const extraUserGroupsLength = userGroups.length - firstThreeUserGroups.length;

    const firstThreeAdminGroups = adminGroups.slice(0, 3);
    const extraAdminGroupsLength = adminGroups.length - firstThreeAdminGroups.length;

    const renderGroupsTooltip = () => {
      switch (true) {
        case currentUser?.userRole === "super":
          return firstThreeAdminGroups.length > 0 ? (
            <>
              {firstThreeAdminGroups.map((group) => (
                <Box key={group.code}>{`${group.name} [${tenants[group.tenantCode].contact.name}]`}</Box>
              ))}
              {extraAdminGroupsLength > 0 ? <Box>{`+ ${extraAdminGroupsLength} outros`}</Box> : null}
            </>
          ) : (
            "Nenhum grupo"
          );
        case currentUser?.userRole === "admin":
          return firstThreeUserGroups.length > 0 ? (
            <>
              {firstThreeUserGroups.map((group) => (
                <Box key={group?.id}>{group?.title}</Box>
              ))}
              {extraUserGroupsLength > 0 ? <Box>{`+ ${extraUserGroupsLength} outros`}</Box> : null}
            </>
          ) : (
            "Nenhum grupo"
          );
      }
    };

    return [
      {
        icon: stringInitials(user.name.trim()).toUpperCase(),
        primaryValue: user.name,
        secondaryValue: user.email,
        tertiaryValue: { method: () => () => dispatch(editUserModalOpened({ step: 1, user })), text: "Editar" },
      },
      {
        primaryValue: capitalizeString(user.profile),
        tertiaryValue: { method: () => dispatch(editUserModalOpened({ step: 2, user })), text: "Editar" },
        tooltip:
          user.email === currentUser?.username
            ? { body: "Não é possível alterar seu próprio perfil.", disabled: true, header: "Seu perfil" }
            : null,
      },
      {
        header: "Grupos",
        primaryValue: userGroups.length > 9 ? userGroups.length : "0" + userGroups.length,
        tertiaryValue: { method: () => dispatch(editUserModalOpened({ step: 3, user })), text: "Editar" },
        tooltip: {
          body: (
            <>
              {renderGroupsTooltip()}
              <Stack onClick={() => navigate("/config/groups")} sx={{ cursor: "pointer", mt: 1 }}>
                <LqdTypography color="rgba(240, 241, 243, 1)" textstyle="p2Paragraph">
                  {"Ver detalhes >"}
                </LqdTypography>
              </Stack>
            </>
          ),
          header: "Grupos",
        },
      },
      {
        primaryValue: format(user.created, "dd MMM yyyy", { locale: ptBR }),
        tertiaryValue: format(user.created, "HH:mm", { locale: ptBR }),
      },
      {
        primaryValue: user.pending ? (
          <LqdTypography
            color="rgba(33, 36, 42, 1)"
            sx={{ border: "1px solid rgba(65, 70, 103, 1)", borderRadius: "12px", mb: 0.5, p: 0.5 }}
            textstyle="buttonXS"
          >
            Pendente
          </LqdTypography>
        ) : (
          <LqdToggle active={user.active} onChange={() => handleToggleUser(user, !user.active)} renderText />
        ),
        tertiaryValue: user.pending ? { method: onResendInvite, text: "Reenviar" } : null,
        tooltip: user.pending
          ? {
              body: (
                <>
                  O usuário ainda não acessou a plataforma.
                  <Box onClick={() => onCancelInvite(user)} sx={{ cursor: "pointer", mt: 1 }}>
                    <LqdTypography color="rgba(240, 241, 243, 1)" textstyle="p2Paragraph">
                      {"Cancelar convite >"}
                    </LqdTypography>
                  </Box>
                </>
              ),
              disabled: false,
              header: "Convite pendente",
            }
          : null,
      },
      {
        primaryValue: !user.pending ? (
          <LqdToggle active={user.notifications} onChange={() => onChangeNotification(user)} renderText />
        ) : null,
      },
    ];
  });

  return (
    <>
      <SettingsRouteHeader
        buttonMethod={() => dispatch(createUserModalOpened())}
        buttonTitle="Novo Usuário"
        sx={{ mt: 5 }}
        title="Usuários"
        tooltipBody="Envie um convite para mais pessoas do seu time utilizarem a plataforma."
        tooltipHeader="Adicionar um usuário"
      />
      {users.length > 0 ? (
        <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
          <Stack sx={{ width: "87.5%" }}>
            <LqdTable<User>
              tableContent={tableContent}
              tableHeader={["Nome", "Perfil", "Grupos", "Criação", "Status", "Notificações"]}
            />
          </Stack>
        </Stack>
      ) : (
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ textAlign: "center" }} textstyle="h4Headline">
          Nenhum usuário foi criado até o momento.
        </LqdTypography>
      )}
      <CreateUserForm />
      <EditUserForm />
    </>
  );
}
