import { Typography, TypographyProps } from "@mui/material";

type LqdTypographyStyle =
  | "buttonL"
  | "buttonM"
  | "buttonS"
  | "buttonXL"
  | "buttonXS"
  | "c1Caption"
  | "c1CaptionMedium"
  | "h1Headline"
  | "h2Headline"
  | "h3Headline"
  | "h4Headline"
  | "h5Headline"
  | "l1Label"
  | "l2Label"
  | "p1Paragraph"
  | "p2Paragraph"
  | "p2ParagraphMedium"
  | "p2Table";

interface LqdTypographyProps extends TypographyProps {
  textstyle?: LqdTypographyStyle; // Default: p1Paragraph
}

type TextStyling = {
  fontSize: string;
  fontWeight: number;
  letterSpacing: string;
  lineHeight: string;
};

/**
 * Default textstyle: p1Paragraph
 */
export default function LqdTypography(props: Omit<LqdTypographyProps, "variant">) {
  const { sx, textstyle } = props;

  const renderTextStyling = (textstyle: string | undefined): TextStyling => {
    switch (textstyle) {
      case "h1Headline":
        return {
          fontSize: "3rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "58px",
        };
      case "h2Headline":
        return {
          fontSize: "2.5rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "48px",
        };
      case "h3Headline":
        return {
          fontSize: "2rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "38px",
        };
      case "h4Headline":
        return {
          fontSize: "1.5rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "30px",
        };
      case "h5Headline":
        return {
          fontSize: "1.25rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "28px",
        };
      case "l1Label":
        return {
          fontSize: "0.875rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "21px",
        };
      case "l2Label":
        return {
          fontSize: "0.75rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "18px",
        };
      case "p1Paragraph":
        return {
          fontSize: "1rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "24px",
        };
      case "p2Paragraph":
        return {
          fontSize: "0.875rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "21px",
        };
      case "p2ParagraphMedium":
        return {
          fontSize: "0.875rem",
          fontWeight: 600,
          letterSpacing: "0px",
          lineHeight: "21px",
        };
      case "p2Table":
        return {
          fontSize: "0.875rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "18px",
        };
      case "c1Caption":
        return {
          fontSize: "0.75rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "18px",
        };
      case "c1CaptionMedium":
        return {
          fontSize: "0.75rem",
          fontWeight: 600,
          letterSpacing: "0px",
          lineHeight: "18px",
        };
      case "buttonXL":
        return {
          fontSize: "1.125rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "27px",
        };
      case "buttonL":
        return {
          fontSize: "1rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "24px",
        };
      case "buttonM":
        return {
          fontSize: "0.875rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "21px",
        };
      case "buttonS":
        return {
          fontSize: "0.75rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "18px",
        };
      case "buttonXS":
        return {
          fontSize: "0.625rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "15px",
        };

      default:
        return {
          fontSize: "1rem",
          fontWeight: 400,
          letterSpacing: "0px",
          lineHeight: "24px",
        };
    }
  };

  const textstyleRendered = renderTextStyling(textstyle || "p1Paragraph");
  const componentProps = { ...props };

  const renderTypography = (textstyle: TextStyling, props: LqdTypographyProps) => {
    // Se tiver o textstyle existem dois cenários: ou tem sx, ou não.
    // Se tiver sx, tem que fazer a concatenação do sx das props com as do textstyle, sendo as do textstyle vindo depois.
    // Se não tiver, passa só o sx do textstyle.
    const { variant, ...componentProps } = props;

    switch (true) {
      case !!variant:
        return <Typography {...props} />;
      case textstyle !== null && "sx" in componentProps:
        componentProps["sx"] = { ...textstyle, ...sx };
        return <Typography {...componentProps} />;
      case textstyle !== null && !("sx" in props):
        componentProps["sx"] = { ...textstyle };
        return <Typography {...componentProps} />;
      default:
        return <Typography {...props} />;
    }
  };

  return <>{renderTypography(textstyleRendered, componentProps)}</>;
}
