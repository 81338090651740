import { LqdCircularLoader } from "@/liquid-components/src";
import { Dialog, Stack } from "@mui/material";
import { useAppSelector } from "../../../../store";

/**
 * Componente de SmallDialog para uso em que seja necessário um Loading geral na página.
 */
export default function SmallDialogLoading() {
  const { dialogShowState } = useAppSelector((state) => state.common);

  return (
    <Dialog open={dialogShowState} PaperProps={{ sx: { borderRadius: 1.25 } }}>
      <Stack alignItems="center" justifyContent="center" sx={{ display: "flex", height: "144px", width: "584px" }}>
        <LqdCircularLoader size="64px" />
      </Stack>
    </Dialog>
  );
}
