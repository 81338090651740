import { SegmentResponse } from "../types/SegmentResponse";
import { SegmentResponseRaw } from "../types/SegmentResponseRaw";

export const formatRawSegmentSuperAdmin = (segmentRaw: SegmentResponseRaw): SegmentResponse => ({
  createdAt: segmentRaw.created_at * 1000,
  lastUpdate: segmentRaw.last_update * 1000,
  name: segmentRaw.name,
  segmentId: segmentRaw.segment_id,
  templates: segmentRaw.templates,
});
