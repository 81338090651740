import { Path, StyleSheet, Svg } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  svgIcon: {
    height: "100px",
    width: "100px",
  },
});

export default function PDFSummaryApproveSVG() {
  return (
    <Svg style={styles.svgIcon}>
      <Path
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill="rgb(212, 251, 232)"
      />
      <Path
        d="M41.25 30C41.25 23.7975 36.2025 18.75 30 18.75C23.7963 18.75 18.75 23.7963 18.75 30"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M18.75 30C18.75 36.2037 23.7963 41.25 30 41.25C36.2037 41.25 41.25 36.2025 41.25 30"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M34.1589 26.875C33.9864 26.875 33.8464 27.015 33.8477 27.1875C33.8477 27.36 33.9877 27.5 34.1602 27.5C34.3327 27.5 34.4727 27.36 34.4727 27.1875C34.4727 27.015 34.3327 26.875 34.1589 26.875"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M25.7995 26.875C25.627 26.875 25.487 27.015 25.4883 27.1875C25.4883 27.36 25.6283 27.5 25.8008 27.5C25.9733 27.5 26.1133 27.36 26.1133 27.1875C26.1133 27.015 25.9733 26.875 25.7995 26.875"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M30 36.25C32.0838 36.25 33.75 34.5838 33.75 32.5H26.25C26.25 34.5838 27.9162 36.25 30 36.25V36.25Z"
        fillRule="evenodd"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
