import { LqdIconProps } from "../../types/IconProps";

export default function LqdRepeatIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 4L16 6.005L8.849 5.984C5.632 5.984 3 8.623 3 11.848V11.848C3 13.461 3.658 14.927 4.718 15.989"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M10 20L8 17.995L15.151 18.016C18.368 18.016 21 15.377 21 12.152V12.152C21 10.539 20.342 9.07299 19.282 8.01099"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
