import { LqdButton, LqdSearch, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadGroups } from "../../../groups/groupsSlice";
import { onLoadUsers } from "../../../users/usersSlice";
import { onLoadGroupsAdmin, onLoadTenants } from "../../adminSliceThunks";
import { adminUsersDeletion } from "../../api/DeleteUsers";
import { adminListGroupsByUsers } from "../../api/ListGroupsByUsers";
import { adminUpdateGroupsByUserList } from "../../api/UpdateGroupsByUserList";
import { GroupResponseRaw } from "../../types/GroupResponseRaw";
import SuperTenantCard from "../SuperTenantCard";
import UserDeletionConfirmationModal from "./UserDeletionConfirmationModal";
import UserDeletionUserCard from "./UserDeletionUserCard";
import { normalizeText } from "@common/utils/normalizeText";

export default function UserDeletion() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const currentSelectedTenant = useAppSelector((state) => state.admin.currentSelectedTenant);
  const tenants = Object.values(useAppSelector((state) => state.admin.tenants));
  const currentSelectedTenantUsers = tenants.find((tenant) => tenant.code === currentSelectedTenant.code)?.users ?? [];

  const [groupList, setGroupList] = useState<Array<GroupResponseRaw>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [groupUsers, setGroupUsers] = useState<Array<string>>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const filteredUsers = currentSelectedTenantUsers.filter((tenant) =>
    normalizeText(tenant.name).includes(normalizeText(searchTerm))
  );

  const handleSelectUser = (userEmail: string) => {
    if (groupUsers.includes(userEmail)) {
      const filteredUserEmails = groupUsers.filter((selectedUserEmail) => selectedUserEmail !== userEmail);
      setGroupUsers(filteredUserEmails);
    } else {
      setGroupUsers([...groupUsers, userEmail]);
    }
  };

  const onSubmitUserDeletion = async (groupUsers: Array<string>) => {
    setShowConfirmationModal(true);
    setIsLoading(true);

    try {
      const groupResponseRaw = await adminListGroupsByUsers(groupUsers, currentSelectedTenant.code);
      const groupResponse = groupResponseRaw.data;

      setGroupList(groupResponse);
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmUserDeletion = async () => {
    setIsLoading(true);

    try {
      const promises = [
        await adminUsersDeletion(groupUsers, currentSelectedTenant.code),
        await adminUpdateGroupsByUserList(groupUsers, currentSelectedTenant.code),
      ];
      await Promise.all(promises);

      const dispatchPromises = [
        dispatch(onLoadGroups()),
        dispatch(onLoadGroupsAdmin()),
        dispatch(onLoadTenants()),
        dispatch(onLoadUsers()),
      ];
      await Promise.all(dispatchPromises);

      const notification: ToastNotification = {
        message: "Os usuários selecionados foram excluídos com sucesso.",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setGroupList([]);
      setGroupUsers([]);
      setShowConfirmationModal(false);
      setIsLoading(false);
    }
  };

  const renderUserSelectInput = () => {
    switch (true) {
      case currentSelectedTenantUsers.length > 0:
        return (
          <>
            <LqdTypography
              sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", mt: 3, pb: 1.5 }}
              textstyle="p2Paragraph"
            >
              Selecione os Usuários que deseja excluir:
            </LqdTypography>
            <Box sx={{ my: 2, width: "fit-content" }}>
              <LqdSearch
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Buscar usuários"
                value={searchTerm}
              />
            </Box>
            <Box
              sx={{
                "::-webkit-scrollbar": { height: "8px" },
                "::-webkit-scrollbar-track": { border: "none" },
                maxHeight: "364px",
                overflowY: "auto",
                px: 1,
              }}
            >
              {filteredUsers.map((user) => (
                <UserDeletionUserCard
                  groupUsers={groupUsers}
                  key={user.email}
                  onChangeMethod={handleSelectUser}
                  user={user}
                />
              ))}
            </Box>
          </>
        );
      case !currentSelectedTenantUsers.length:
        return (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ height: "30px", mt: 4 }} textstyle="h4Headline">
            Ainda não há nenhum usuário cadastrado para essa empresa.
          </LqdTypography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <Box sx={{ display: "flex", flexDirection: "column", margin: "auto", width: "90%" }}>
        {renderUserSelectInput()}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 3 }}>
          <LqdButton
            disabled={!currentSelectedTenantUsers.length}
            onClick={() => onSubmitUserDeletion(currentSelectedTenantUsers.map((user) => user.email))}
            sx={{ mr: 2 }}
            type="outlineTertiary"
          >
            Excluir todos
          </LqdButton>
          <LqdButton disabled={!groupUsers.length} onClick={() => onSubmitUserDeletion(groupUsers)}>
            Excluir usuários selecionados
          </LqdButton>
        </Box>
        <UserDeletionConfirmationModal
          groupList={groupList}
          isLoading={isLoading}
          onCloseMethod={() => setShowConfirmationModal(false)}
          onDeleteMethod={onConfirmUserDeletion}
          open={showConfirmationModal}
        />
      </Box>
    </>
  );
}
