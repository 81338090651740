import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterSeven() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        7. CONSULTA DE DADOS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        7.1. Para a viabilização das Soluções da Liquid, Bancos de Dados públicos e/ou de terceiros podem ser acessados
        e utilizados como fontes de informação, dentre os quais destacamos: B3, Banco Central do Brasil, DIGESTO, IBGE,
        Neoway, PH3A, Pluggy, Sienge, SERASA Experian, Boa Vista, SPC, entre outros.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        7.2. Serão coletados apenas os Dados necessários para alcançar as finalidades estabelecidas pelo Usuário
        Decisor, nos termos desta Política.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        7.3. A Liquid não possui ingerência em relação às Políticas de Privacidade, formas de Tratamento ou mecanismos
        de segurança adotados pelas fontes de Dados mencionadas no item 6.1. acima.
      </LqdTypography>
    </>
  );
}
