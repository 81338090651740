import { LqdIconProps } from "../../types/IconProps";

export default function LqdDoneFilledIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
        fill={color}
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16 10L11 15L8 12"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
