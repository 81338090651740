import { Link, LinkProps, useTheme } from "@mui/material";

export default function LqdLink(props: LinkProps) {
  const { href, onClick, sx } = props;

  const theme = useTheme();

  return (
    <Link
      {...props}
      href={href || "#"}
      onClick={(event) => {
        if (!href) {
          event.preventDefault();
        }

        if (onClick) {
          onClick(event);
        }
      }}
      sx={{ color: theme.palette.primary.main, textDecoration: "none ", ...sx }}
    />
  );
}
