import { SimulatorFunction } from "../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../types/SimulatorFunctionBlock";

export const handleBlocksPosition = (
  blocks: Array<SimulatorFunctionBlock>,
  index: number,
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void,
  type: "down" | "up"
) => {
  const simulatorBlocks = JSON.parse(JSON.stringify(blocks));

  // Remove o bloco selecionado da posição original
  const removedBlock = simulatorBlocks.splice(index, 1)[0];

  // Determina a nova posição de inserção
  const newPosition = type === "up" ? index - 1 : index + 1;

  // Insere o bloco removido na nova posição
  simulatorBlocks.splice(newPosition, 0, removedBlock);

  // block_id original do bloco de cima (up) e bloco de baixo (down) independente de qual foi clicado
  const upBlockOldId: number = simulatorBlocks[index + (type === "up" ? -1 : 0)].block_id;
  const downBlockOldId: number = simulatorBlocks[index + (type === "up" ? +0 : +1)].block_id;

  // Atualiza os block_id conforme a mudança de posição
  if (type === "up") {
    // Atualiza o block_id do bloco que subiu
    simulatorBlocks[index - 1].block_id = downBlockOldId;

    // Atualiza o block_id do bloco que desceu
    simulatorBlocks[index].block_id = upBlockOldId;

    // clicked block
    simulatorBlocks[index - 1].functions.forEach((func: SimulatorFunction) => {
      if (func.function_name === "jump" && func.variables.block_id === downBlockOldId) {
        func.variables.block_id = upBlockOldId;
      } else if (func.function_name === "jump_conditional") {
        if (func.variables.block_false === downBlockOldId) {
          (func.variables.block_false as number) = upBlockOldId;
        }
        if (func.variables.block_true === downBlockOldId) {
          (func.variables.block_true as number) = upBlockOldId;
        }
      }
    });

    // other
    simulatorBlocks[index].functions.forEach((func: SimulatorFunction) => {
      if (func.function_name === "jump" && func.variables.block_id === upBlockOldId) {
        func.variables.block_id = downBlockOldId;
      } else if (func.function_name === "jump_conditional") {
        if (func.variables.block_false === upBlockOldId) {
          (func.variables.block_false as number) = downBlockOldId;
        }
        if (func.variables.block_true === upBlockOldId) {
          (func.variables.block_true as number) = downBlockOldId;
        }
      }
    });
  } else if (type === "down") {
    // Incrementa o block_id do bloco que desceu
    simulatorBlocks[index + 1].block_id = upBlockOldId;

    // Decrementa o block_id do bloco que subiu
    simulatorBlocks[index].block_id = downBlockOldId;

    // Atualiza o block_id das variáveis das funções de jump e jump_conditional do bloco clicado (que desceu)
    simulatorBlocks[index + 1].functions.forEach((func: SimulatorFunction) => {
      if (func.function_name === "jump" && func.variables.block_id === upBlockOldId) {
        func.variables.block_id = downBlockOldId; // O block_id atualizado
      } else if (func.function_name === "jump_conditional") {
        if (func.variables.block_false === upBlockOldId) {
          (func.variables.block_false as number) = downBlockOldId; // O block_id atualizado
        }
        if (func.variables.block_true === upBlockOldId) {
          (func.variables.block_true as number) = downBlockOldId; // O block_id atualizado
        }
      }
    });

    // Atualiza o block_id das variáveis das funções de jump e jump_conditional do bloco que subiu
    simulatorBlocks[index].functions.forEach((func: SimulatorFunction) => {
      if (func.function_name === "jump" && func.variables.block_id === downBlockOldId) {
        func.variables.block_id = upBlockOldId; // O block_id atualizado
      } else if (func.function_name === "jump_conditional") {
        if (func.variables.block_false === downBlockOldId) {
          (func.variables.block_false as number) = upBlockOldId; // O block_id atualizado
        }
        if (func.variables.block_true === downBlockOldId) {
          (func.variables.block_true as number) = upBlockOldId; // O block_id atualizado
        }
      }
    });
  }

  // Atualiza blocos que não se moveram, mas que referenciam os que se moveram
  simulatorBlocks.forEach((block: SimulatorFunctionBlock, i: number) => {
    if (i !== newPosition && i !== index) {
      block.functions.forEach((func) => {
        if (func.function_name === "jump") {
          if (type === "up") {
            // Se o bloco foi movido para cima
            if (func.variables.block_id === upBlockOldId) {
              func.variables.block_id = downBlockOldId;
            } else if (func.variables.block_id === downBlockOldId) {
              func.variables.block_id = upBlockOldId;
            }
          } else if (type === "down") {
            // Se o bloco foi movido para baixo
            if (func.variables.block_id === downBlockOldId) {
              func.variables.block_id = upBlockOldId;
            } else if (func.variables.block_id === upBlockOldId) {
              func.variables.block_id = downBlockOldId;
            }
          }
        } else if (func.function_name === "jump_conditional") {
          if (type === "up") {
            // Se o bloco foi movido para cima
            if (func.variables.block_false === upBlockOldId) {
              (func.variables.block_false as number) = downBlockOldId;
            } else if (func.variables.block_false === downBlockOldId) {
              (func.variables.block_false as number) = upBlockOldId;
            }
            if (func.variables.block_true === upBlockOldId) {
              (func.variables.block_true as number) = downBlockOldId;
            } else if (func.variables.block_true === downBlockOldId) {
              (func.variables.block_true as number) = upBlockOldId;
            }
          } else if (type === "down") {
            // Se o bloco foi movido para baixo
            if (func.variables.block_false === downBlockOldId) {
              (func.variables.block_false as number) = upBlockOldId;
            } else if (func.variables.block_false === upBlockOldId) {
              (func.variables.block_false as number) = downBlockOldId;
            }
            if (func.variables.block_true === downBlockOldId) {
              (func.variables.block_true as number) = upBlockOldId;
            } else if (func.variables.block_true === upBlockOldId) {
              (func.variables.block_true as number) = downBlockOldId;
            }
          }
        }
      });
    }
  });

  setBlocks(simulatorBlocks);
};
