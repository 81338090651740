import { LqdIconProps } from "../../types/IconProps";

export default function LqdSubmenuIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0242 14.976L10.0242 14.976C7.27417 14.976 5.02417 12.778 5.02417 10.091V10.091L5.02417 10.121L5.02417 4.97601"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16.9762 10.928L21.0242 14.976L16.9762 19.024"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
