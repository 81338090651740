import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
  },
});

export default function PDFBradescoSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 400 400">
        <Circle cx="200" cy="200" fill="rgb(206, 28, 48)" r="200" />
        <Path
          d="M194.565 145.745C182.936 145.745 170.221 146.813 157.662 148.949C170.376 132.626 190.533 122.099 213.016 122.099C229.606 122.099 246.197 128.354 257.36 137.66C259.376 139.338 260.927 139.491 262.478 137.965C264.028 136.44 263.873 134.914 262.167 132.931C247.592 115.997 226.195 104.556 200.922 105.013C167.896 105.624 139.832 126.523 129.133 155.509C119.365 158.56 110.372 162.221 102.464 166.645C100.293 167.866 99.5178 169.392 100.293 171.222C101.068 172.9 102.619 173.51 104.79 172.9C111.612 170.917 118.589 169.239 125.877 167.713C125.102 171.527 124.637 175.494 124.637 179.613C124.016 206.31 138.281 231.634 160.298 245.516C162.469 246.889 164.33 246.889 165.57 245.211C166.811 243.533 166.655 242.007 164.795 240.329C150.84 227.82 143.088 209.208 143.088 190.749C143.088 181.443 144.948 172.443 148.514 164.357C155.802 163.747 163.709 163.289 172.392 163.289C228.831 163.289 276.432 181.901 276.432 206.31C276.432 218.667 260.772 231.023 242.165 239.262C237.669 241.245 237.204 242.618 237.824 244.601C238.444 246.432 240.46 247.042 242.786 246.279C275.347 235.6 300 218.056 300 196.852C300 168.019 253.485 145.745 194.565 145.745Z"
          fill="rgb(239, 239, 239)"
        />
        <Path
          d="M221.515 292.399V230.628C221.515 228.261 219.229 227.397 217.344 228.471L201.641 237.508V295.379L218.51 295.401C220.169 295.403 221.515 294.059 221.515 292.399Z"
          fill="rgb(239, 239, 239)"
        />
        <Path
          d="M182.526 250.749V293.465C182.526 294.534 183.392 295.401 184.461 295.401H196.344V240.553L184.482 247.383C183.272 248.072 182.526 249.157 182.526 250.749Z"
          fill="rgb(239, 239, 239)"
        />
      </Svg>
    </View>
  );
}
