import { LqdRadioOffIcon, LqdRadioOnIcon, LqdTypography } from "@/liquid-components/src";
import { Avatar, Box } from "@mui/material";

type AutomationConnectionProductCardProps = {
  product: {
    country: string;
    currency: string;
    id: string;
    image: string;
    name: string;
    segment: string;
  };
  selectedProduct: boolean;
};

export default function AutomationConnectionProductCard(props: AutomationConnectionProductCardProps) {
  const { product, selectedProduct } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "52px",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        {selectedProduct ? (
          <LqdRadioOnIcon color="rgba(33, 36, 42, 1)" size={20} />
        ) : (
          <LqdRadioOffIcon color="rgba(33, 36, 42, 1)" size={20} />
        )}
        <Avatar src={product.image} sx={{ display: "inline-block", height: 50, ml: 2.5, mr: 2, width: 50 }} />
        <Box>
          <LqdTypography
            color="rgba(127, 135, 152, 1)"
            sx={{ border: 1, borderRadius: 10, px: 1.2, py: 0.5, width: "fit-content" }}
            textstyle="buttonXS"
          >
            {product.segment}
          </LqdTypography>
          <LqdTypography color="rgba(56, 61, 70, 1)" sx={{ mt: 1 }}>
            {product.name}
          </LqdTypography>
          <Box sx={{ alignItems: "left", display: { sm: "none", xs: "flex" }, flexDirection: "column", mt: 1 }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="c1Caption">
              Moeda / País
            </LqdTypography>
            <LqdTypography sx={{ display: "inline-block" }}>
              {product.currency} / {product.country}
            </LqdTypography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ alignItems: "left", display: { sm: "flex", xs: "none" }, flexDirection: "column", mr: 10 }}>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="c1Caption">
          Moeda / País
        </LqdTypography>
        <LqdTypography color="rgba(56, 61, 70, 1)" sx={{ display: "inline-block", mt: 1 }}>
          {product.currency} / {product.country}
        </LqdTypography>
      </Box>
    </Box>
  );
}
