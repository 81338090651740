import { Box, InputBase, OutlinedInputProps, useTheme } from "@mui/material";
import IMask from "imask";
import { ChangeEvent, ReactNode, useEffect, useRef } from "react";

type LqdTextFieldProps = {
  hidemessagebox?: string;
  mask?: string;
  startAdornment?: ReactNode;
  value: string;
};

export default function LqdTextField(props: LqdTextFieldProps & Omit<OutlinedInputProps, "value">) {
  const { error, hidemessagebox, label, mask, onChange, startAdornment, sx, value } = props;

  const theme = useTheme();

  const newMask = useRef(
    IMask.createMask({
      mask: mask || "",
    })
  );

  useEffect(() => {
    if (mask) {
      newMask.current = IMask.createMask({ mask: mask || "" });
    }

    if (value) {
      newMask.current.value = value;
    }
  }, [mask, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (mask) {
      const newValue = event.target.value;
      const lastCharIsNumber = !isNaN(Number(newValue.slice(-1)));
      newMask.current.resolve(lastCharIsNumber ? newValue : newValue.slice(0, -1));
      event.target.value = newMask.current.unmaskedValue;
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <InputBase
        {...props}
        onChange={handleChange}
        startAdornment={startAdornment}
        sx={{
          "&.Mui-focused": {
            backgroundColor: error ? "rgba(255, 71, 137, 0.04)" : "rgba(71, 116, 255, 0.04)",
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
          },
          borderBottom: `1px solid ${error ? theme.palette.error.main : "rgba(0, 0, 0, 0.42)"}`,
          height: "48px",
          mt: "6px",
          paddingLeft: !startAdornment ? "16px" : 0,
          paddingRight: "8px",
          width: "100%",
          ...sx,
        }}
        value={newMask.current.value ? newMask.current.value : value}
      />
      {hidemessagebox === "true" ? null : <Box sx={{ minHeight: "30px" }}>{label}</Box>}
    </>
  );
}
