import { Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  sbpeWarningContainer: {
    alignItems: "center",
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
    width: "100%",
  },
  sbpeWarningText: {
    color: "rgb(127, 135, 152)",
    fontSize: "13.5px",
    paddingBottom: "25px",
    paddingLeft: "15px",
    paddingRight: "24px",
    paddingTop: "25px",
  },
  svgIcon: {
    height: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    width: "24px",
  },
});

export default function PDFSimulatorWarningCard() {
  return (
    <View minPresenceAhead={40} style={styles.sbpeWarningContainer}>
      <Svg style={styles.svgIcon}>
        <Path
          d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
          fillRule="evenodd"
          stroke="rgb(127, 135, 152)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Path d="M12 17V12H11" stroke="rgb(127, 135, 152)" strokeLineCap="round" strokeWidth="1.2" />
        <Path
          d="M11.749 8C11.611 8 11.499 8.112 11.5 8.25C11.5 8.388 11.612 8.5 11.75 8.5C11.888 8.5 12 8.388 12 8.25C12 8.112 11.888 8 11.749 8"
          stroke="rgb(127, 135, 152)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
      </Svg>
      <Text style={styles.sbpeWarningText}>
        As informações exibidas são calculadas com base nas tabelas vigentes dos bancos. Pequenas divergências podem
        ocorrer, porém a{`\n`} Liquid garante a atualização regular das taxas para fornecer aos clientes uma estimativa
        confiável das condições atuais do mercado.
      </Text>
    </View>
  );
}
