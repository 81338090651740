import { LqdCopyIcon, LqdTypography } from "@/liquid-components/src";
import { useCopyToClipboard } from "@common/hooks/useCopyToClipboard";
import { Stack } from "@mui/material";
import { t } from "i18next";
import { useAppSelector } from "../../../store";
import AutomationTextField from "./AutomationTextField";

export default function AutomationWebhookDisplay() {
  const { newAutomationForm } = useAppSelector((state) => state.automations);

  const copyValueToClipboard = useCopyToClipboard();

  return newAutomationForm?.webhook_url ? (
    <>
      <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1, mt: 4 }} textstyle="p2Paragraph">
        Webhook Liquid
      </LqdTypography>
      <Stack
        direction="row"
        sx={{
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
        }}
      >
        <AutomationTextField
          disabled
          fullWidth
          sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, boxShadow: "none" }}
          value={newAutomationForm?.webhook_url}
        />
        <Stack
          alignItems="center"
          direction="row"
          onClick={() => copyValueToClipboard(newAutomationForm?.webhook_url, "URL do Webhook")}
          spacing={0.5}
          sx={{
            border: "1px solid rgba(229, 231, 235, 1)",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: "8px",
            borderLeft: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: "8px",
            boxSizing: "border-box",
            cursor: "pointer",
            height: "48px",
            px: 1.5,
            py: 1,
          }}
        >
          <LqdCopyIcon />
        </Stack>
      </Stack>
      <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ fontSize: "12px", mt: 1 }} textstyle="p2Paragraph">
        {t(`Ao criar o Webhook na plataforma Construtor de Vendas, copie o texto acima e cole no campo "Endereços".`)}
      </LqdTypography>
    </>
  ) : null;
}
