import { LqdButton, LqdSearch, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadGroups } from "../../../groups/groupsSlice";
import { onLoadUsers } from "../../../users/usersSlice";
import { onLoadGroupsAdmin } from "../../adminSliceThunks";
import { adminGroupsDeletion } from "../../api/DeleteGroups";
import { adminListUsersByGroups } from "../../api/ListUsersByGroups";
import { adminUpdateUsersByGroups } from "../../api/UpdateUsersByGroup";
import { UserResponseRaw } from "../../types/UserResponseRaw";
import SuperTenantCard from "../SuperTenantCard";
import GroupDeletionGroupCard from "./GroupDeletionGroupCard";
import { normalizeText } from "@common/utils/normalizeText";

export default function GroupDeletion() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const currentSelectedTenant = useAppSelector((state) => state.admin.currentSelectedTenant);
  const groups = Object.values(useAppSelector((state) => state.admin.groups));
  const groupsByTenant = groups.filter((group) => group.tenantCode === currentSelectedTenant.code);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>([]);

  const filteredGroups = groupsByTenant.filter((group) =>
    normalizeText(group.name).includes(normalizeText(searchTerm))
  );

  const handleSelectGroup = (groupId: string) => {
    if (selectedGroups.includes(groupId)) {
      const filteredProductsIds = selectedGroups.filter((selectedGroupId) => selectedGroupId !== groupId);
      setSelectedGroups(filteredProductsIds);
    } else {
      setSelectedGroups([...selectedGroups, groupId]);
    }
  };

  const onSubmitGroupDeletion = async (selectedGroups: Array<string>) => {
    dispatch(dialogCalled({ actions: [], body: "", title: "", type: "loading" }));

    try {
      const userResponseRaw = await adminListUsersByGroups(selectedGroups, currentSelectedTenant.code);
      const userResponse = userResponseRaw.data;

      const newUsersList: Array<UserResponseRaw> = [];

      userResponse.forEach((user) => {
        if (!newUsersList.some((item) => JSON.stringify(item) === JSON.stringify(user))) {
          newUsersList.push(user);
        }
      });

      dispatch(
        dialogCalled({
          actions: [{ title: "Cancelar" }, { onClick: onConfirmGroupDeletion, title: "Confirmar exclusão" }],
          body: "Excluir os grupos atualizará os usuários abaixo:",
          bodySecundary: "Deseja continuar?",
          emptyLabel: "Não há usuários a serem atualizados.",
          itemList: newUsersList.map((user) => ({
            labelOne: "E-mail do usuário:",
            labelTwo: "Nome do usuário:",
            valueOne: user.username,
            valueTwo: user.fullname,
          })),
          title: "Exclusão de Grupos",
          type: "list",
        })
      );
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const onConfirmGroupDeletion = async () => {
    dispatch(dialogLoaded(true));

    try {
      const promises = [
        await adminGroupsDeletion(selectedGroups, currentSelectedTenant.code),
        await adminUpdateUsersByGroups(selectedGroups, currentSelectedTenant.code),
      ];
      await Promise.all(promises);

      const notification: ToastNotification = {
        message: "Os grupos selecionados foram excluídos com sucesso.",
        type: "success",
      };

      const dispatchPromises = [
        await dispatch(onLoadGroups()),
        await dispatch(onLoadGroupsAdmin()),
        await dispatch(onLoadUsers()),
      ];

      await Promise.all(dispatchPromises);
      dispatch(toastCalled(notification));

      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setSelectedGroups([]);
      setTimeout(() => dispatch(dialogLoaded(false)), 1000);
    }
  };

  const renderGroupSelectInput = () => {
    switch (true) {
      case groupsByTenant.length > 0:
        return (
          <>
            <LqdTypography
              sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", mt: 3, pb: 1.5 }}
              textstyle="p2Paragraph"
            >
              Selecione os Grupos que deseja excluir:
            </LqdTypography>
            <Box sx={{ my: 2, width: "fit-content" }}>
              <LqdSearch
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Buscar grupos"
                value={searchTerm}
              />
            </Box>
            <Box
              sx={{
                "::-webkit-scrollbar": { height: "8px" },
                "::-webkit-scrollbar-track": { border: "none" },
                maxHeight: "364px",
                overflowY: "auto",
                px: 1,
              }}
            >
              {filteredGroups.map((group) => (
                <GroupDeletionGroupCard
                  group={group}
                  key={group.code}
                  onChangeMethod={handleSelectGroup}
                  selectedGroups={selectedGroups}
                />
              ))}
            </Box>
          </>
        );
      case !groupsByTenant.length:
        return (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ height: "30px", mt: 4 }} textstyle="h4Headline">
            Ainda não há nenhum grupo cadastrado para essa empresa.
          </LqdTypography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <Box sx={{ display: "flex", flexDirection: "column", margin: "auto", width: "90%" }}>
        {renderGroupSelectInput()}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 3 }}>
          <LqdButton
            disabled={!groupsByTenant.length}
            onClick={() => onSubmitGroupDeletion(groupsByTenant.map((group) => group.code))}
            sx={{ mr: 2 }}
            type="outlineTertiary"
          >
            Excluir todos
          </LqdButton>
          <LqdButton disabled={!selectedGroups.length} onClick={() => onSubmitGroupDeletion(selectedGroups)}>
            Excluir grupos selecionados
          </LqdButton>
        </Box>
      </Box>
    </>
  );
}
