import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterFive() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        5. HIPÓTESES DE TRATAMENTO APLICÁVEIS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        5.1. O Tratamento de Dados decorrente do uso da Plataforma ASK Liquid será baseado nas seguintes hipóteses
        legais:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        5.1.1. Na execução das Soluções contratadas através da adesão aos Termos de Uso pelo Usuário Decisor e/ou pelo
        Usuário Analisado e/ou Monitorado (art. 7º, V).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        5.1.2. Na proteção ao crédito (art. 7º, X, da LGPD), quando o Tratamento decorrer de decisões financeiras a
        serem tomadas pelo Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        5.1.3. Com base no consentimento do Usuário Analisado e/ou Monitorado, quando aplicável (arts. 7º, I, e 11, I,
        ambos da LGPD).
      </LqdTypography>
    </>
  );
}
