import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ObjectOf } from "../common/types/ObjectOf";
import { duplicateAutomation } from "./api/duplicateAutomation";
import { getAutomationModules } from "./api/GetAutomationModules";
import { getAutomationProducts } from "./api/GetAutomationProducts";
import { getAutomations } from "./api/GetAutomations";
import { getAutomationsResults } from "./api/GetAutomationsResults";
import { removeAutomation } from "./api/removeAutomation";
import { testAutomationCredentials } from "./api/testAutomationCredentials";
import { updateAutomation } from "./api/updateAutomation";
import { Automation } from "./types/Automation";
import { AutomationFilter } from "./types/AutomationFilter";
import { AutomationEventTemplate, AutomationModule } from "./types/AutomationModule";
import { AutomationProduct } from "./types/AutomationProduct";
import { AutomationResult } from "./types/AutomationResult";

type AutomationsState = {
  automationModules: ObjectOf<AutomationModule>; // Lista de modules de automação
  automationProducts: ObjectOf<AutomationProduct>; // Lista de produtos que podem ter automações
  automationResults: Array<AutomationResult>; // Lista de conexões da automação selecionada para visualizar
  automationResultsTotalPages: number; // Total de páginas da lista de conexões da automação selecionada
  automations: ObjectOf<Automation>; // Lista de automações existentes
  automationSearch: string; // Search query para filtrar a lista de automações existentes
  automationToDuplicateId: null | string; // ID (configuration_id) da automação a ser duplicada
  automationToRemove: Automation | null; // Automação a ser removida (pelo RemoveAutomationDialog)
  creatingNewAutomation: boolean; // Indica se uma nova automação está sendo criada
  editAutomationFormName: boolean; // Exibe o step de editar o nome da automação
  isEditingAutomation: boolean; // Indica se a automação está sendo editada
  newAutomationForm: Automation | null; // Automação a ser editada/criada (pelo CreateOrEditAutomationForm)
  selectedAutomation: Automation | null; // Automação selecionada (ver detalhes). Necessária para exibir a paginação
};

const initialState: AutomationsState = {
  automationModules: {},
  automationProducts: {},
  automationResults: [],
  automationResultsTotalPages: 1,
  automationSearch: "",
  automationToDuplicateId: null,
  automationToRemove: null,
  automations: {},
  creatingNewAutomation: false,
  editAutomationFormName: false,
  isEditingAutomation: false,
  newAutomationForm: null,
  selectedAutomation: null,
};

const AUTOMATION_RESULTS_FETCH_AMOUNT = 10;

export const onGetAutomations = createAsyncThunk("automations/onGetAutomations", async () => {
  const req = await getAutomations();
  return req.data;
});

export const onGetAutomationModules = createAsyncThunk("automations/onGetAutomationModules", async () => {
  const req = await getAutomationModules();
  return req.data;
});

export const onTestAutomationCredentials = createAsyncThunk(
  "automation/onTestAutomationCredentials",
  async (credentialTestData: {
    credentials: { client_domain: string; email: string; token: string };
    originModule: string;
  }) => {
    const { credentials, originModule } = credentialTestData;
    const req = await testAutomationCredentials(credentials, originModule);
    return req.data;
  }
);

export const onUpdateAutomation = createAsyncThunk("automations/onUpdateAutomation", async (automation: Automation) => {
  const req = await updateAutomation(automation as Automation);
  return { ...req.data, resumed_results: automation.resumed_results };
});

export const onGetAutomationResults = createAsyncThunk(
  "automations/onGetAutomationResults",
  async (page: number, { getState }) => {
    const { automations } = getState() as { automations: AutomationsState };
    const { selectedAutomation } = automations;
    const limit = AUTOMATION_RESULTS_FETCH_AMOUNT;
    const params = {
      limit,
      offset: page,
    };
    if (selectedAutomation) {
      const req = await getAutomationsResults(selectedAutomation.configuration_id as string, params);
      return req.data;
    }
  }
);

export const onGetAutomationProducts = createAsyncThunk(
  "automation/onGetAutomationProducts",
  async (origin: string) => {
    const req = await getAutomationProducts(origin);
    return req.data;
  }
);

export const onDuplicateAutomation = createAsyncThunk(
  "automations/onDuplicateAutomations",
  async ({ configurationId, name }: { configurationId: string; name: string }) => {
    const req = await duplicateAutomation(configurationId, name);
    return req.data;
  }
);

export const onRemoveAutomation = createAsyncThunk(
  "automations/onRemoveAutomation",
  async (configurationId: string) => {
    await removeAutomation(configurationId);
    return configurationId;
  }
);

const automationsSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onGetAutomations.fulfilled, (state, action) => {
        const automations = action.payload;
        automations.forEach((automation) => (state.automations[automation.configuration_id] = automation));
      })
      .addCase(onGetAutomationModules.fulfilled, (state, action) => {
        const automationModulesRaw = action.payload;
        automationModulesRaw.forEach((module) => (state.automationModules[module.origin_module] = module));
      })
      .addCase(onGetAutomationResults.fulfilled, (state, action) => {
        if (action.payload) {
          const { results, total_pages } = action.payload;
          state.automationResultsTotalPages = total_pages;
          state.automationResults = results;
        }
      })
      .addCase(onGetAutomationProducts.fulfilled, (state, action) => {
        action.payload.forEach((product) => (state.automationProducts[product.id] = product));
      })
      .addCase(onTestAutomationCredentials.fulfilled, (state, action) => {
        const validCredentials = action.payload.message;

        if (validCredentials) {
          const newAutomationForm = state.newAutomationForm;

          state.newAutomationForm = { ...newAutomationForm!, valid_credentials: true };
        }
      })
      .addCase(onTestAutomationCredentials.rejected, () => {
        throw new Error("Validation rejected");
      })
      .addCase(onUpdateAutomation.fulfilled, (state, action) => {
        const automation = action.payload;
        state.automations[automation.configuration_id] = automation;
      })
      .addCase(onDuplicateAutomation.fulfilled, (state, action) => {
        const duplicatedAutomation = action.payload;
        state.automations[duplicatedAutomation.configuration_id];
      })
      .addCase(onRemoveAutomation.fulfilled, (state, action) => {
        const configurationId = action.payload;
        delete state.automations[configurationId];
      }),
  initialState,
  name: "automations",
  reducers: {
    automationSearchChanged(state, action: PayloadAction<string>) {
      state.automationSearch = action.payload;
    },
    automationToDuplicateIdChanged(state, action: PayloadAction<string | null>) {
      state.automationToDuplicateId = action.payload;
    },
    automationToRemoveChanged(state, action: PayloadAction<Automation | null>) {
      state.automationToRemove = action.payload;
    },
    creatingNewAutomationChanged(state, action: PayloadAction<boolean>) {
      state.creatingNewAutomation = action.payload;
      state.editAutomationFormName = true;
    },
    editAutomationFormNameChanged(state, action: PayloadAction<boolean>) {
      state.editAutomationFormName = action.payload;
    },
    editingAutomationChanged(state, action: PayloadAction<boolean>) {
      state.isEditingAutomation = action.payload;
    },
    newAutomationChanged(state, action: PayloadAction<Automation | null>) {
      const newAutomation = action.payload;
      state.newAutomationForm = newAutomation;
      if (!newAutomation) {
        state.creatingNewAutomation = false;
      }
    },
    newAutomationFormEventChanged(
      state,
      action: PayloadAction<{ actionIndex: number; eventTemplate: AutomationEventTemplate }>
    ) {
      const { actionIndex, eventTemplate } = action.payload;
      if (eventTemplate) {
        (state.newAutomationForm as Automation).actions[actionIndex].event = {
          informations: eventTemplate.fields.reduce(
            (acc, field) => ({ ...acc, [field.key]: field.key === "mail-addresses" ? [] : "" }),
            {} as ObjectOf<string | Array<string>>
          ),
          name: eventTemplate.name,
        };
      }
    },
    newAutomationFormEventFieldValueChanged(
      state,
      action: PayloadAction<{ actionIndex: number; fieldKey: string; value: Array<string> | string }>
    ) {
      const { actionIndex, fieldKey, value } = action.payload;
      (state.newAutomationForm as Automation).actions[actionIndex].event.informations[fieldKey] = value;
    },
    newAutomationFormFilterAdded(state, action: PayloadAction<{ actionIndex: number; filter: AutomationFilter }>) {
      const { actionIndex, filter } = action.payload;
      (state.newAutomationForm as Automation).actions[actionIndex].filters.push(filter);
    },
    newAutomationFormFilterRemoved(state, action: PayloadAction<{ actionIndex: number; filterIndex: number }>) {
      const { actionIndex, filterIndex } = action.payload;
      (state.newAutomationForm as Automation).actions[actionIndex].filters.splice(filterIndex, 1);
    },
    newAutomationFormFilterUpdated(
      state,
      action: PayloadAction<{ actionIndex: number; filter: AutomationFilter; filterIndex: number }>
    ) {
      const { actionIndex, filter, filterIndex } = action.payload;
      (state.newAutomationForm as Automation).actions[actionIndex].filters[filterIndex] = filter;
    },
    selectedAutomationChanged(state, action: PayloadAction<Automation | null>) {
      const automation = action.payload;
      state.selectedAutomation = automation;
      if (!automation) {
        state.creatingNewAutomation = false;
      }
    },
  },
});

export const {
  automationSearchChanged,
  automationToDuplicateIdChanged,
  automationToRemoveChanged,
  creatingNewAutomationChanged,
  editAutomationFormNameChanged,
  editingAutomationChanged,
  newAutomationChanged,
  newAutomationFormEventChanged,
  newAutomationFormEventFieldValueChanged,
  newAutomationFormFilterAdded,
  newAutomationFormFilterRemoved,
  newAutomationFormFilterUpdated,
  selectedAutomationChanged,
} = automationsSlice.actions;

export default automationsSlice.reducer;
