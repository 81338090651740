import {
  LqdAdminIcon,
  LqdButton,
  LqdCreatorIcon,
  LqdDecisorIcon,
  LqdEyeOpenIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import { t } from "i18next";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import RadioCard from "../../common/components/RadioCard";
import { createUserModalStepNext, editUserFormStepNext } from "../usersSlice";

type UserFormStep3Props = {
  profile: string;
  setProfile: (value: string) => void;
};

export default function UserFormStep3(props: UserFormStep3Props) {
  const { profile, setProfile } = props;

  const dispatch = useAppDispatch();
  const { userToEdit } = useAppSelector((state) => state.users);

  const theme = useTheme();

  const currentUser = useAppSelector((state) => state.users.currentUser);
  const currentUserAutoEditing = currentUser?.username === userToEdit?.email;

  const profiles = [
    {
      actions: ["Visualiza propostas"],
      icon: (color: string) => <LqdEyeOpenIcon color={color} />,
      type: "viewer",
    },
    {
      actions: ["Visualiza propostas", "Cria propostas"],
      icon: (color: string) => <LqdCreatorIcon color={color} />,
      type: "creator",
    },
    {
      actions: ["Visualiza propostas", "Cria propostas", "Decide o resultado das propostas"],
      icon: (color: string) => <LqdDecisorIcon color={color} />,
      type: "decisor",
    },
    {
      actions: [
        "Visualiza propostas",
        "Cria propostas",
        "Decide o resultado das propostas",
        "Administra produtos, grupos e usuários",
      ],
      icon: (color: string) => <LqdAdminIcon color={color} />,
      type: "admin",
    },
  ];

  const handleSubmit = () => {
    dispatch(userToEdit ? editUserFormStepNext() : createUserModalStepNext());
  };

  const maySubmit = userToEdit ? profile !== userToEdit.profile : profile;
  const getBackgroundColor = (currentProfile: { type: string }) => {
    switch (true) {
      case currentProfile.type === profile && currentUserAutoEditing:
        return "rgba(170, 170, 170, 1)";
      case currentProfile.type === profile:
        return theme.palette.primary.main;
      default:
        return "rgba(191, 196, 205, 0.16)";
    }
  };

  return (
    <Box className="lqd-user-form-step3" sx={{ mt: 12, width: { lg: "1100px", md: "80vw" } }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 7.5 }} textstyle="h4Headline">
        {t("Perfil")}
      </LqdTypography>
      <Grid columns={4} container spacing={1.5}>
        {profiles.map((currentProfile) => (
          <Grid item key={currentProfile.type} xs={1}>
            <RadioCard
              active={currentProfile.type === profile}
              disabled={currentUserAutoEditing}
              handleClick={() => setProfile(currentProfile.type)}
              sx={{
                backgroundColor: "transparent",
                border:
                  currentProfile.type === profile
                    ? "3px solid rgba(33, 36, 42, 1)"
                    : "1px solid rgba(127, 135, 152, 0.24)",
                borderRadius: "10px",
                boxSizing: "border-box",
                height: "100%",
                p: 3,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  backgroundColor: getBackgroundColor(currentProfile),
                  borderRadius: "50%",
                  display: "inline-flex",
                  justifyContent: "center",
                  mb: 3,
                  p: 2,
                }}
              >
                {currentProfile.icon(
                  currentProfile.type === profile ? "rgba(255, 255, 255, 1)" : "rgba(56, 61, 70, 1)"
                )}
              </Box>
              <LqdTypography
                sx={{ color: currentUserAutoEditing ? theme.palette.grey[400] : "rgba(33, 36, 42, 1)", mb: 3 }}
                textstyle="h5Headline"
              >
                {capitalizeString(currentProfile.type)}
              </LqdTypography>
              {currentProfile.actions.map((action, index) => (
                <Fragment key={action}>
                  <LqdTypography
                    sx={{ color: currentUserAutoEditing ? theme.palette.grey[400] : "rgba(127, 135, 152, 1)" }}
                    textstyle="p2Paragraph"
                  >
                    {action}
                  </LqdTypography>
                  {index < currentProfile.actions.length - 1 ? <Divider sx={{ my: 1.5 }} /> : null}
                </Fragment>
              ))}
            </RadioCard>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit} sx={{ mt: 7.5 }}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
