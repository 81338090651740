import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFTrustpadUnmatchSVG from "./StepSVG/PDFTrustpadUnmatchSVG";

type PDFTrustpadPeerUnmatchStepCardProps = {
  peerName: string;
  productName: string;
};

const styles = StyleSheet.create({
  stepMainText: {
    color: "rgb(33, 36, 42)",
    fontSize: "12px",
  },
  stepTitle: {
    color: "rgb(246, 61, 94)",
    fontSize: "20px",
  },
  trustpadPeerMatchStepCardContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    marginVertical: "20px",
  },
  trustpadPeerMatchStepCardDescriptionContainer: {
    margin: "0 auto",
    paddingLeft: "24px",
    width: "70%",
  },
});

export default function PDFTrustpadPeerUnmatchStepCard(props: PDFTrustpadPeerUnmatchStepCardProps) {
  const { peerName, productName } = props;

  return (
    <View minPresenceAhead={40} style={styles.trustpadPeerMatchStepCardContainer}>
      <PDFTrustpadUnmatchSVG />
      <View style={styles.trustpadPeerMatchStepCardDescriptionContainer}>
        <Text style={styles.stepTitle}>Usuário não deu match com o Produto!</Text>
        <Text style={styles.stepMainText}>{`Esse usuário ${capitalizeString(
          peerName
        )} não foi aprovado de acordo com as regras de ${productName}.`}</Text>
      </View>
    </View>
  );
}
