import {
  LqdCircularLoader,
  LqdDeleteFilledIcon,
  LqdDoneFilledIcon,
  LqdEyeClosedIcon,
  LqdEyeOpenIcon,
  LqdIconButton,
  LqdLoadingIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { t } from "i18next";
import { Fragment, KeyboardEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { newAutomationChanged, onTestAutomationCredentials, onUpdateAutomation } from "../automationsSlice";
import { Automation } from "../types/Automation";
import { AutomationModuleCredentialField } from "../types/AutomationModule";
import AutomationTextField from "./AutomationTextField";

type AutomationCredentialFormProps = {
  domain: string;
  email: string;
  setDomain: (value: string) => void;
  setEmail: (value: string) => void;
  setToken: (value: string) => void;
  token: string;
};

export default function AutomationCredentialForm(props: AutomationCredentialFormProps) {
  const { domain, email, setDomain, setEmail, setToken, token } = props;

  const dispatch = useAppDispatch();

  const { automationModules, newAutomationForm } = useAppSelector((state) => state.automations);

  const [credentialsError, setCredentialsError] = useState(false);
  const [disabledValidateButton, setDisabledValidateButton] = useState(newAutomationForm?.valid_credentials || false);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedModuleName, setSelectedModuleName] = useState(newAutomationForm?.origin_module);
  const [showCredentials, setShowCredentials] = useState<boolean>(!newAutomationForm?.valid_credentials);
  const [validatingCredentials, setValidatingCredentials] = useState(false);
  const [validationData, setValidationData] = useState({ message: "", success: false });

  const selectedModule = selectedModuleName ? automationModules[selectedModuleName] : null;
  const everyCredentialHasValue = domain && email && token;

  useEffect(() => {
    setFirstRender(false);
  }, []);

  /**
   * Limpa o form toda vez que o módulo de origem mudar
   */
  useEffect(() => {
    if (newAutomationForm?.origin_module !== selectedModuleName) {
      clearCredentialsInfo();
      setSelectedModuleName(newAutomationForm?.origin_module);
    }
  }, [newAutomationForm, newAutomationForm?.origin_module]);

  /**
   * Limpa o erro de credencial inválida toda vez que um dos inputs mudarem de valor
   */
  const onFieldsChange = (setFieldValue: (value: string) => void, value: string) => {
    setFieldValue(value);

    if (credentialsError) {
      setCredentialsError(false);
      setValidationData({ message: "", success: false });
      if (!firstRender) {
        setDisabledValidateButton(false);
        const updatedAutomation = { ...newAutomationForm, valid_credentials: false } as Automation;
        dispatch(newAutomationChanged(updatedAutomation));
      }
    }
  };

  /**
   * Limpa o form
   */
  const clearCredentialsInfo = () => {
    setDomain("");
    setEmail("");
    setToken("");
    setShowCredentials(true);
    setValidationData({ message: "", success: false });
  };

  /**
   * Executa a validação das credenciais ao clicar no botão de Testar
   */
  const onTestConnectionClick = async () => {
    setCredentialsError(false);
    setValidatingCredentials(true);

    if (!newAutomationForm?.origin_module) {
      throw new Error("Could not get newAutomationForm");
    }
    try {
      const testCredentialData = {
        credentials: { client_domain: domain, email, token },
        originModule: newAutomationForm.origin_module as string,
      };
      // Faz o teste de credenciais
      await dispatch(onTestAutomationCredentials(testCredentialData));
      // Monta uma automação com o valid_credentials = true
      const updateAutomationForm = {
        ...newAutomationForm,
        credentials: { client_domain: domain, email, token },
        valid_credentials: true,
      };
      const updatedAutomation = await dispatch(onUpdateAutomation(updateAutomationForm)).unwrap();
      dispatch(newAutomationChanged(updatedAutomation));
      setValidationData({ message: t("Credencial verificada com sucesso."), success: true });
      setDisabledValidateButton(true);
      setShowCredentials(false);
    } catch (error) {
      setCredentialsError(true);
      setValidationData({
        message: t("Falha ao validar a credencial. Tente novamente."),
        success: false,
      });
      const updatedAutomation = { ...newAutomationForm, valid_credentials: false } as Automation;
      dispatch(newAutomationChanged(updatedAutomation));
      setDisabledValidateButton(true);
    } finally {
      setValidatingCredentials(false);
    }
  };

  const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !disabledValidateButton) {
      // await onTestConnectionClick();
      // TODO: implementar essa funcionalidade (card no Trello: https://trello.com/c/2GX2UqAT/1490-revis%C3%A3o-nova-automa%C3%A7%C3%A3o-credenciais)
    }
  };

  const renderTestButton = () => {
    switch (true) {
      case validatingCredentials:
        return <LqdCircularLoader />;
      case credentialsError:
        return (
          <>
            <LqdDeleteFilledIcon color="rgba(246, 61, 94, 1)" />
            <LqdTypography color="rgba(177, 30, 84, 1)" textstyle="p2Paragraph">
              {t("Erro")}
            </LqdTypography>
          </>
        );
      case newAutomationForm?.valid_credentials && !validatingCredentials:
        return (
          <>
            <LqdDoneFilledIcon color="rgba(52, 199, 89, 1)" />
            <LqdTypography color="rgba(61, 172, 128, 1)" textstyle="p2Paragraph">
              {t("Sucesso")}
            </LqdTypography>
          </>
        );
      default:
        return (
          <>
            <LqdLoadingIcon />
            <LqdTypography textstyle="p2Paragraph">{t("Testar")}</LqdTypography>
          </>
        );
    }
  };

  const renderField = (field: AutomationModuleCredentialField) => {
    switch (field.key) {
      case "client_domain":
        return (
          <AutomationTextField
            disabled={validatingCredentials}
            fullWidth
            key={field.key}
            onChange={(event) => onFieldsChange(setDomain, event.target.value)}
            onKeyUp={onKeyPress}
            placeholder={field.placeholder}
            sx={{ boxShadow: "none", mb: 1.5 }}
            type="text"
            value={domain}
          />
        );
      case "email":
        return (
          <AutomationTextField
            disabled={validatingCredentials}
            endAdornment={
              <LqdIconButton onClick={() => setShowCredentials(!showCredentials)} round="true" type="ghostIcon">
                {showCredentials ? (
                  <LqdEyeOpenIcon color="rgba(127, 135, 152, 1)" />
                ) : (
                  <LqdEyeClosedIcon color="rgba(127, 135, 152, 1)" />
                )}
              </LqdIconButton>
            }
            fullWidth
            key={field.key}
            onChange={(event) => onFieldsChange(setEmail, event.target.value)}
            onKeyUp={onKeyPress}
            placeholder={field.placeholder}
            sx={{ mb: 1.5 }}
            type={showCredentials ? "text" : "password"}
            value={email}
          />
        );
      case "token":
        return (
          <Fragment key={field.key}>
            <Stack
              direction="row"
              sx={{
                borderRadius: "8px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
                mb: 1.5,
              }}
            >
              <AutomationTextField
                disabled={validatingCredentials}
                fullWidth
                onChange={(event) => onFieldsChange(setToken, event.target.value)}
                onKeyUp={onKeyPress}
                placeholder={field.placeholder}
                sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, boxShadow: "none" }}
                type={showCredentials ? "text" : "password"}
                value={token}
              />
              <Box sx={{ pointerEvents: disabledValidateButton ? "none" : "auto" }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  onClick={onTestConnectionClick}
                  spacing={0.5}
                  sx={{
                    border: "1px solid rgba(229, 231, 235, 1)",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: "8px",
                    borderLeft: "none",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: "8px",
                    boxSizing: "border-box",
                    cursor: everyCredentialHasValue ? "pointer" : "not-allowed",
                    height: "48px",
                    opacity: !everyCredentialHasValue ? 0.4 : 1,
                    px: 1.5,
                    py: 1,
                  }}
                >
                  {renderTestButton()}
                </Stack>
              </Box>
            </Stack>
            {validationData.message ? (
              <LqdTypography
                sx={{ color: validationData.success ? "rgba(61, 172, 128, 1)" : "rgba(177, 30, 84, 1)", mt: 1 }}
                textstyle="c1Caption"
              >
                {validationData.message}
              </LqdTypography>
            ) : null}
          </Fragment>
        );
      default:
        return null;
    }
  };

  return selectedModule?.credentials.fields
    .concat()
    .sort((fieldA, fieldB) => fieldA.position - fieldB.position)
    .map(renderField);
}
