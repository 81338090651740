import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Product } from "../../../../../../../products/types/Product";
import { AllotmentSimulationResult } from "../../../../../../types/Simulation/Allotment/AllotmentSimulationResult";
import { formatValueToCurrency, formatValueToPercentage } from "../../../../../../utils/analysisDetailsDataFormatter";
import { institutionDictionary } from "../../../../../../utils/analysisDictionaries";

type PDFAllotmentFinancingTableProps = {
  product: Product;
  result: Array<AllotmentSimulationResult>;
};

const styles = StyleSheet.create({
  allotmentFinancingTableColumn1: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn2: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn3: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn4: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn5: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn6: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn7: {
    fontSize: "14px",
    paddingLeft: "18px",
    width: "12%",
  },
  allotmentFinancingTableColumn8BodyApprove: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "11px",
    paddingRight: "10px",
    paddingTop: "8px",
  },
  allotmentFinancingTableColumn8BodyReprove: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    paddingBottom: "7px",
    paddingLeft: "11px",
    paddingRight: "10px",
    paddingTop: "8px",
  },
  allotmentFinancingTableColumn8Header: {
    fontSize: "14px",
    paddingLeft: "32px",
    width: "12%",
  },
  allotmentFinancingTableInnerContainer: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingBottom: "12px",
    paddingHorizontal: "24px",
    paddingTop: "24px",
    width: "100%",
  },
  productImage: {
    height: 40,
    marginRight: "20px",
    width: 40,
  },
  tableOuterContainer: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "24px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "92px",
  },
  tableWithoutLabelHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableWithoutLabelTitle: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
  },
});

export default function PDFAllotmentFinancingTable(props: PDFAllotmentFinancingTableProps) {
  const { product, result } = props;

  return (
    <View minPresenceAhead={40} style={styles.allotmentFinancingTableInnerContainer}>
      <View style={styles.tableOuterContainer}>
        <Image src={product.picture} style={styles.productImage} />
        <Text style={styles.tableWithoutLabelTitle}>Financiamento direto</Text>
      </View>
      <View style={styles.tableWithoutLabelHeaderContainer}>
        <Text style={styles.allotmentFinancingTableColumn1}>{`Tabela\nIncorp.`}</Text>
        <Text style={styles.allotmentFinancingTableColumn2}>{`CR máx.\npossível`}</Text>
        <Text style={styles.allotmentFinancingTableColumn3}>{`LTV máx.\npossível`}</Text>
        <Text style={styles.allotmentFinancingTableColumn4}>CET</Text>
        <Text style={styles.allotmentFinancingTableColumn5}>Taxa mês</Text>
        <Text style={styles.allotmentFinancingTableColumn6}>Prazo</Text>
        <Text style={styles.allotmentFinancingTableColumn7}>Parcelas</Text>
        <Text style={styles.allotmentFinancingTableColumn8Header}>{`CR / LTV`}</Text>
      </View>
      {result.map((res, index) => {
        const match =
          res.loaned_percentage <= res.bank_rate.ltv_new_property &&
          res.income_commitment_desired <= res.bank_rate.max_income_commitment;

        return (
          <View key={`${res.bank_rate}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.allotmentFinancingTableColumn1}>{institutionDictionary(res.bank_rate.bank_name)}</Text>
            <Text style={styles.allotmentFinancingTableColumn2}>
              {res.bank_rate.max_income_commitment
                ? formatValueToPercentage(res.bank_rate.max_income_commitment)
                : formatValueToPercentage(0.3)}
            </Text>
            <Text style={styles.allotmentFinancingTableColumn3}>
              {res.bank_rate.ltv_new_property
                ? formatValueToPercentage(res.bank_rate.ltv_new_property)
                : formatValueToPercentage(1)}
            </Text>
            <Text style={styles.allotmentFinancingTableColumn4}>{formatValueToPercentage(res.cet)}</Text>
            <Text style={styles.allotmentFinancingTableColumn5}>
              {formatValueToPercentage(res.monthly_interest_rate)}
            </Text>
            <Text style={styles.allotmentFinancingTableColumn6}>{res.available_payment_term}</Text>
            <Text style={styles.allotmentFinancingTableColumn7}>
              {formatValueToCurrency(res.final_installment_desired, "BRL", true)}
            </Text>
            {match ? (
              <Text style={styles.allotmentFinancingTableColumn8BodyApprove}>
                {`${formatValueToPercentage(res.income_commitment_desired)} / ${formatValueToPercentage(
                  res.loaned_percentage
                )}`}
              </Text>
            ) : (
              <Text style={styles.allotmentFinancingTableColumn8BodyReprove}>
                {`${formatValueToPercentage(res.income_commitment_desired)} / ${formatValueToPercentage(
                  res.loaned_percentage
                )}`}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
}
