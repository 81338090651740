import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterZero() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontStyle: "italic" }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        Atualizado em 18/03/2024
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" textAlign="justify" textstyle="p2Paragraph">
        Estes Termos e Condições de Uso do Software “Liquid” (“Termos”) regulam a contratação e utilização do software
        Liquid, de propriedade da LIQUID DO BRASIL LTDA., sociedade empresária limitada, com sede na Avenida Paulista,
        nº 1.471, conjunto 1.110, sala 01, Edifício Barão de Cristina, bairro Bela Vista, na cidade de São Paulo, Estado
        de São Paulo, inscrita no CNPJ/MF sob o nº 52.099.247/0001-00, doravante denominada simplesmente de (a “Liquid”)
        e pelo Usuário conforme abaixo definido, tendo em vista que:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        A) A Liquid é uma empresa de tecnologia que desenvolveu e é proprietária do software Liquid (“Liquid”), que é
        uma infraestrutura de automatização de decisões e análises;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        B) O Usuário será toda PESSOA JURÍDICA ou PESSOA FÍSICA:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        i. que após receber uma proposta comercial encaminhada diretamente pela Liquid ou por parceiros e distribuidores
        da Liquid tiver interesse em contratar o uso do software e definir como será feita a análise de dados e
        automatização de decisões, passando tal pessoa a ser denominada daqui em diante de “Usuário Decisor”; e/ou
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        ii. que se cadastrar junto à Liquid ou parceiros e distribuidores da Liquid para utilizar o software, passando
        tal pessoa a ser denominada daqui em diante de “Usuário Analisado”, o qual deverá manter seus dados cadastrais
        atualizados junto à Liquid;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        C) Usuário Decisor e Usuário Analisado, no que se aplicar mutuamente a eles, serão chamados, em conjunto e sem
        distinção, de "Usuário"; e
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        D) Para utilizar o software Liquid, o Usuário deverá realizar expressamente um ato de vontade, o qual pode
        incluir exemplificadamente, entre outors, um clique no botão "Concordo" ou qualquer forma de manifestação
        positiva e expressa via WhatsApp, Chatbot ou e-mail, demonstrando concordância e aceite de todos os termos e
        condições aqui previstos, os quais declara ter lido integralmente (“Aceite”).
      </LqdTypography>
    </>
  );
}
