import { AxiosPromise } from "axios";
import { put } from "../../common/utils/apiUtils";
import ProductRaw from "../types/ProductRaw";

const updateProduct = (
  productId: string,
  product: Omit<ProductRaw, "created_at" | "last_update" | "product_id" | "creation">
): AxiosPromise<ProductRaw> => put(`/products/${productId}`, product);

export default updateProduct;
