import { SimulatorResultTableData } from "@simulatorBuilder/types/SimulatorDashboard";
import { v4 as uuid } from "uuid";

export default function createInitialSimulatorTables(tableContent: Array<SimulatorResultTableData>) {
  const initialColumn =
    tableContent.length > 0
      ? tableContent[0].columns
      : [
          {
            columnId: uuid(),
            columnName: "Nome da coluna",
          },
          {
            columnId: uuid(),
            columnName: null,
          },
        ];
  const initialRow =
    tableContent.length > 0
      ? tableContent[0].rows
      : [
          {
            rowContent: [
              {
                data: {
                  column: 0,
                  label: "",
                  line: 0,
                  name: "",
                  type: "textDefault",
                  value: "",
                },
                id: initialColumn[0].columnId,
              },
              {
                data: null,
                id: initialColumn[1].columnId,
              },
            ],
            rowId: 0,
          },
        ];

  return [
    {
      columns: initialColumn,
      rows: initialRow,
    },
  ];
}
