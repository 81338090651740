import { LqdIconProps } from "../../types/IconProps";

export default function LqdConstantVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill={color} height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M20.0903 13.7055L13.2047 20.5911C12.5386 21.2572 11.4586 21.2572 10.7926 20.5911L3.90701 13.7055C3.24096 13.0395 3.24096 11.9595 3.90701 11.2934L10.7926 4.40786C11.4586 3.74181 12.5386 3.74181 13.2047 4.40786L20.0903 11.2934C20.7563 11.9604 20.7563 13.0395 20.0903 13.7055Z"
        fill="rgba(207, 211, 218, 1)"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
