import { AppError, AppErrorOptions } from "../../common/types/AppError";

export class SaveRulesError extends AppError {
  constructor(options?: AppErrorOptions) {
    super(
      {
        message: "Houve um erro ao salvar as regras. Tente novamente.",
      },
      options
    );
  }
}
