import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { institutionDictionary } from "../../../../../../utils/analysisDictionaries";
import { renderBankSVG } from "../BankSVGs/RenderBankSVG";

type PDFSBPEBankLabelProps = {
  bankName: string;
};

const styles = StyleSheet.create({
  bankNameContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "24px",
  },
  bankNameText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
  },
});

export default function PDFSBPEBankLabel(props: PDFSBPEBankLabelProps) {
  const { bankName } = props;

  return (
    <View style={styles.bankNameContainer}>
      {renderBankSVG(institutionDictionary(bankName))}
      <Text style={styles.bankNameText}>{institutionDictionary(bankName)}</Text>
    </View>
  );
}
