const palette = {
  contrastThreshold: 3,
  error: { main: "rgba(255, 71, 137, 1)" }, // Danger
  info: { main: "rgba(71, 116, 255, 1)" }, // Blue
  primary: {
    contrastText: "rgba(255, 255, 255, 1)", // Primary
    light: "rgba(127, 135, 152, 0.08)",
    main: "rgba(33, 36, 42, 1)",
  },
  secondary: { main: "rgba(117, 123, 162, 1)" }, // Purple
  success: { main: "rgba(52, 199, 89, 1)" }, // Success
  text: { primary: "rgba(65, 70, 103, 1)" },
  tonalOffset: 0.2,
  warning: { main: "rgba(234, 191, 66, 1)" }, // Warning
};

export default palette;
