import { Breadcrumbs, Link } from "@mui/material";
import { LqdTypography } from "..";

type BreadcrumbOption = {
  name: string;
  onClick?: () => void;
};

type LqdBreadcrumbProps = {
  options: Array<BreadcrumbOption | null>;
  separator?: string;
};

export default function LqdBreadcrumb(props: LqdBreadcrumbProps) {
  const { options, separator } = props;

  const lastValidIndex = options.reduce((lastIndex, option, index) => (option ? index : lastIndex), 0);

  return (
    <Breadcrumbs separator={separator || "/"}>
      {options.map((option, index) => {
        if (!option) return null;

        if (index === lastValidIndex) {
          return (
            <LqdTypography color="rgba(33, 36, 42, 1)" key={`${option.name}-${index}`} textstyle="p2Paragraph">
              {option.name}
            </LqdTypography>
          );
        }

        return (
          <Link
            color="rgba(33, 36, 42, 1)"
            component="button"
            key={option.name}
            onClick={option.onClick}
            underline="hover"
          >
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
              {option.name}
            </LqdTypography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
