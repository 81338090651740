import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import RouteSearchHeader from "../../common/components/RouteSearchHeader";
import {
  automationSearchChanged,
  creatingNewAutomationChanged,
  editingAutomationChanged,
  newAutomationChanged,
} from "../automationsSlice";
import CreateOrEditAutomationModal from "../components/CreateOrEditAutomationModal";
import DuplicateAutomationModal from "../components/DuplicateAutomationModal";

const initialAutomationState = {
  actions: [],
  configuration_id: "",
  created_at: 0,
  created_by: "",
  credentials: { client_domain: "", email: "", token: "" },
  extra_information: { idtipo: "" },
  is_active: true,
  is_blocked: false,
  is_deleted: false,
  name: "",
  origin_module: "",
  product: null,
  resumed_results: { error: 0, processing: 0, success: 0 },
  tenant_code: "",
  updated_at: 0,
  updated_by: "",
  valid_credentials: false,
  webhook_url: "",
};

export default function AutomationsPage() {
  const { t } = useTranslation();

  const { automationId } = useParams();
  const dispatch = useAppDispatch();

  const { automations, selectedAutomation } = useAppSelector((state) => state.automations);

  const onCreateAutomationClick = () => {
    dispatch(editingAutomationChanged(false));
    dispatch(newAutomationChanged(initialAutomationState));
    dispatch(creatingNewAutomationChanged(true));
  };

  const getAutomations = () => {
    if (!automationId) return [];

    const automation = automations[automationId];
    const automationName = automation?.name || "";

    return [automationName];
  };

  return (
    // TODO: investigar lag absurdo nessa rota (modal de exclusão de automação apresenta bastante lag -> o mesmo modal não tem lag no SignIn)
    <>
      <Box
        id="automations-route"
        sx={{ boxSizing: "border-box", display: "flex", flexDirection: "column", height: "100%" }}
      >
        <RouteSearchHeader
          archiveComponent={null}
          breadcrumbContent={getAutomations()}
          buttonName="Nova Automação"
          filterComponent={null}
          labelValue={t("Automações")}
          onButtonClick={onCreateAutomationClick}
          placeholder={t(selectedAutomation ? "Digite aqui" : "Digite o nome da automação")}
          routeHref="/automations"
          setSearchInpuValue={(value) => dispatch(automationSearchChanged(value as string))}
          tooltipBody={t("Crie uma nova automação selecionando origem, tipo de conexão e ações.")}
          tooltipHeader={t("Adicionar uma automação")}
        />
        <Box
          className="lqd-automations-content"
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            flexBasis: 0,
            justifyContent: "center",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <CreateOrEditAutomationModal />
      <DuplicateAutomationModal />
    </>
  );
}
