import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    marginRight: "18px",
    maxHeight: "40px",
    maxWidth: "40px",
  },
});

export default function PDFTrustpadUnmatchSVG() {
  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 45 45">
        <Path d="M29.691 18.4023L17.9336 30.1597" stroke="rgb(246, 61, 94)" strokeLineCap="round" strokeWidth="1.2" />
        <Path d="M17.9336 18.4023L29.691 30.1597" stroke="rgb(246, 61, 94)" strokeLineCap="round" strokeWidth="1.2" />
        <Circle cx="23.8" cy="23.8" r="20" stroke="rgb(246, 61, 94)" />
      </Svg>
    </View>
  );
}
