import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFSBPEUpperSimulatorInfoCardProps = {
  title: string;
  value: string;
};

const styles = StyleSheet.create({
  sbpeSimulatorGrayCardColumnContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "24px",
  },
  sbpeSimulatorGrayCardMainLabelText: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  sbpeSimulatorGrayCardMainValueText: {
    color: "rgb(246, 61, 94)",
    fontSize: "32px",
  },
});

export default function PDFSBPEUpperSimulatorInfoCard(props: PDFSBPEUpperSimulatorInfoCardProps) {
  const { title, value } = props;

  return (
    <View style={styles.sbpeSimulatorGrayCardColumnContainer}>
      <Text style={styles.sbpeSimulatorGrayCardMainLabelText}>{title}</Text>
      <Text style={styles.sbpeSimulatorGrayCardMainValueText}>{value}</Text>
    </View>
  );
}
