import { Node } from "react-flow-renderer/dist/nocss/esm";
import { ProviderNodeData } from "../types/NodeData";

// Retorna os handles de um nó de provider
export const getProviderNodeHandles = (node: Node<ProviderNodeData>) => {
  let providerNodeHandles: Array<string> = [];
  providerNodeHandles = node.data.custom_data.conditions.map((condition) => `handle-${condition.id}`);
  // O handle da condição default só existe se houver um kpi selecionado
  if (node.data.custom_data.selectedKpi) {
    providerNodeHandles.push(`handle-default-condition-${node.id}`);
  }
  // Por último, adiciona o sourceHandle do nó de provider
  providerNodeHandles.push(`handle-${node.id}`);
  return providerNodeHandles;
};
