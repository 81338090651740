import { LqdIconProps } from "../../types/IconProps";

export default function LqdEditIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9 14.9998L12.15 14.6108C12.371 14.5838 12.577 14.4828 12.735 14.3258L20.366 6.69475C21.211 5.84975 21.211 4.47975 20.366 3.63375V3.63375C19.521 2.78875 18.151 2.78875 17.305 3.63375L9.745 11.1938C9.592 11.3468 9.493 11.5448 9.462 11.7598L9 14.9998Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M19 14V19C19 20.105 18.105 21 17 21H5C3.895 21 3 20.105 3 19V7C3 5.895 3.895 5 5 5H10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
