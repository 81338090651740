import { DefaultMessageResponse } from "@common/types/DefaultMessageResponse";
import { post } from "../../common/utils/apiUtils";

/**
 * Endpoint responsável por realizar o run de uma conexão.
 * @param analysisId ID da conexão.
 * @param peers Lista de pares de documentos e peers.
 * @returns Mensagem de sucesso.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const runOpenedAnalysis = (analysisId: string, peers: Array<{ document: string; peer: string }>) =>
  post<DefaultMessageResponse>(`/analysis/${analysisId}/run`, { peers });
