import { Path, Svg } from "@react-pdf/renderer";

export default function PDFSBPESimulatorWalletSVG() {
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24">
      <Path
        d="M3 5V18.5C3 19.605 3.895 20.5 5 20.5H19C20.105 20.5 21 19.605 21 18.5V8.5C21 7.395 20.105 6.5 19 6.5H4.5C3.672 6.5 3 5.828 3 5ZM3 5C3 4.172 3.672 3.5 4.5 3.5H17M16.595 13.125C16.388 13.126 16.221 13.294 16.221 13.501C16.221 13.708 16.389 13.876 16.596 13.875C16.803 13.875 16.971 13.707 16.971 13.5C16.971 13.293 16.803 13.125 16.595 13.125Z"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
