import { AnalysisDetailsStep } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { PeerDataWithSteps } from "../../../../../types/AnalysisDetails/PeerData/PeerData";
import PDFTrustpadPeerCardContainer from "./PDFTrustpadPeerCardContainer";
import PDFTrustpadPeerDefaultStepCard from "./PDFTrustpadPeerDefaultStepCard";
import PDFTrustpadPeerMatchStepCard from "./PDFTrustpadPeerMatchStepCard";
import PDFTrustpadPeerModerateStepCard from "./PDFTrustpadPeerModerateStepCard";
import PDFTrustpadPeerUnmatchStepCard from "./PDFTrustpadPeerUnmatchStepCard";
import PDFTrustpadPeerWarningStepCard from "./PDFTrustpadPeerWarningStepCard";

type PDFTrustpadPeerDataProps = {
  peer: PeerDataWithSteps;
  productName: string;
  steps: Array<AnalysisDetailsStep>;
};

const getLineColor = (action: string) => {
  switch (action) {
    case "match":
      return "rgb(41, 176, 160)";
    case "unmatch":
      return "rgb(246, 61, 94)";
    case "moderation":
    case "warning":
      return "rgb(255, 181, 71)";
    default:
      return "rgb(127, 135, 152)";
  }
};

export default function PDFTrustpadPeerData(props: PDFTrustpadPeerDataProps) {
  const { peer, productName, steps } = props;

  return (
    <>
      <PDFTrustpadPeerCardContainer peer={peer} steps={steps} />
      {steps.map((step, index) => {
        switch (step.action) {
          case "match":
            return (
              <PDFTrustpadPeerMatchStepCard
                key={`${step.kpi}-${step.action}-${index}`}
                peerName={peer.fullName}
                productName={productName}
              />
            );
          case "unmatch":
            return (
              <PDFTrustpadPeerUnmatchStepCard
                key={`${step.kpi}-${step.action}-${index}`}
                peerName={peer.fullName}
                productName={productName}
              />
            );
          case "moderation":
            return (
              <PDFTrustpadPeerModerateStepCard
                key={`${step.kpi}-${step.action}-${index}`}
                peerName={peer.fullName}
                productName={productName}
              />
            );
          case "warning":
            return (
              <PDFTrustpadPeerWarningStepCard
                key={`${step.kpi}-${step.action}-${index}`}
                lastIndex={index === steps.length - 1}
                lineColor={getLineColor(steps[index + 1]?.action as string)}
                step={step}
              />
            );
          default:
            return (
              <PDFTrustpadPeerDefaultStepCard
                key={`${step.kpi}-${step.action}-${index}`}
                lastIndex={index === steps.length - 1}
                lineColor={getLineColor(steps[index + 1]?.action as string)}
                step={step}
              />
            );
        }
      })}
    </>
  );
}
