import { LqdIconProps } from "../../types/IconProps";

export default function LqdResultVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill={color} height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0953 4.0979C11.5248 3.45052 12.4754 3.45052 12.9049 4.0979L15.1837 7.53243C15.328 7.7499 15.5458 7.90812 15.7972 7.97815L19.7678 9.08407C20.5162 9.29253 20.81 10.1966 20.327 10.8052L17.7648 14.0337C17.6025 14.2382 17.5193 14.4942 17.5304 14.7549L17.7056 18.873C17.7387 19.6492 16.9696 20.2079 16.2416 19.9366L12.3792 18.4975C12.1347 18.4064 11.8655 18.4064 11.621 18.4975L7.75861 19.9366C7.03059 20.2079 6.26154 19.6492 6.29456 18.873L6.46976 14.7549C6.48085 14.4942 6.39767 14.2382 6.23543 14.0337L3.67318 10.8052C3.19022 10.1966 3.48397 9.29253 4.23239 9.08407L8.20301 7.97815C8.45442 7.90812 8.6722 7.7499 8.81649 7.53243L11.0953 4.0979Z"
        fill="rgba(207, 211, 218, 1)"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
