import { Card, CardProps } from "@mui/material";

export default function MasterCard(props: CardProps & { shadow?: string }) {
  const { shadow, sx } = props;

  return (
    <Card
      {...props}
      elevation={0}
      sx={{
        backgroundColor: "rgba(249, 249, 250, 1)",
        border: "2px solid rgba(127, 135, 152, 0.16);",
        borderRadius: "20px",
        boxShadow: shadow === "true" ? "0px 44px 30px -30px rgba(0, 0, 0, 0.25)" : "none",
        ...sx,
      }}
    />
  );
}
