import { LqdTypography } from "@/liquid-components/src";
import InsightCards from "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/Cards";
import Highlights from "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/Highlights";
import Tables from "@analysis/components/AnalysisDashboard/DashBoardPeerView/Simulator/BuilderSimulator/Tables";
import { Box, Stack } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { mapToBuilderSimulation } from "@simulatorBuilder/utils/mapToBuilderSimulation";

type SimulatorTesterResultProps = {
  inputSearchData: SimulatorTesterSearchData;
  result: SimulatorTesterResultDBResponse | null;
};

/**
 * Componente para exibir os resultados do Tester.
 *
 * @param {SimulatorTesterResultProps} props - As propriedades para o componente.
 *
 * O componente renderiza três seções:
 * 1. Cards: Exibe cartões de insights com base nos resultados da simulação.
 * 2. Destaques: Exibe cartões de destaques com base nos resultados da simulação.
 * 3. Tabelas: Exibe tabelas com base nos resultados da simulação.
 *
 * Cada seção é envolvida em um componente Stack com estilização específica e contém um título e um componente Box
 * que renderiza o conteúdo respectivo (cartões ou tabelas) usando os dados de pesquisa de entrada e os resultados da simulação fornecidos.
 */
export default function SimulatorTesterResult(props: SimulatorTesterResultProps) {
  const { inputSearchData, result } = props;

  const builderSimulation = result ? mapToBuilderSimulation(result) : null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pb: "80px",
        width: "922px",
      }}
    >
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Cards
        </LqdTypography>
        <Box margin="28px">
          <InsightCards inputSearchData={inputSearchData} simulation={builderSimulation!} />
        </Box>
      </Stack>
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Destaques
        </LqdTypography>
        <Box margin="28px">
          <Highlights inputSearchData={inputSearchData} simulation={builderSimulation!} />
        </Box>
      </Stack>
      <Stack borderRadius={5} marginTop="28px" paddingTop="28px">
        <LqdTypography color="rgba(33, 36, 42, 1)" paddingBottom="12px" textstyle="h4Headline">
          Tabelas
        </LqdTypography>
        <Box margin="28px">
          <Tables inputSearchData={inputSearchData} simulation={builderSimulation!} type="tester" />
        </Box>
      </Stack>
    </Box>
  );
}
