import { get } from "../../common/utils/apiUtils";
import { AnalysisDetailsRaw } from "../types/AnalysisDetails/Analysis/AnalysisDetailsRaw";

/**
 * Endpoint que busca os detalhes de uma conexão.
 * @param analysisId ID da conexão.
 * @returns Detalhes da conexão, no formato AnalysisDetailsRaw.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getAnalysisDetails = (analysisId: string) => get<AnalysisDetailsRaw>(`/analysis/${analysisId}`);
