import { LqdButton, LqdDownTwoIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import { createTemplateModalStepNext, editTemplateModalStepGoTo } from "../../../adminSlice";
import { TemplateCreateRaw } from "../../../types/TemplateCreateRaw";

type TemplateFormStep2Props = {
  country: string;
  currency: string;
  onSubmit: (template: TemplateCreateRaw) => void;
  options: Array<string>;
  setCountry: (value: string) => void;
  setCurrency: (value: string) => void;
};

export default function TemplateFormStep2(props: TemplateFormStep2Props) {
  const { country, currency, options, setCountry, setCurrency } = props;

  const { width } = useWindowSize();
  const { createTemplate } = useAppSelector((state) => state.admin);
  const dispatch = useAppDispatch();

  const handleValueChange = (value: string) => {
    const country = value.split(" / ")[0];
    const currency = value.split(" / ")[1];
    setCountry(country);
    setCurrency(currency);
  };

  // Envia as modificações deste step para o parent, quando clicar no botão de avançar
  const handleSubmit = () => {
    dispatch(createTemplate ? createTemplateModalStepNext() : editTemplateModalStepGoTo(4));
  };

  const selectPlaceholder = (
    <LqdTypography
      sx={{
        color: "rgba(155, 162, 175, 1)",
        mt: { md: 0, xs: 3 },
        my: 1.5,
        pl: "16px",
      }}
      textstyle={width === "xs" ? "h5Headline" : "h4Headline"}
    >
      Procure aqui...
    </LqdTypography>
  );

  const maySubmit = country && currency;

  return (
    <Box sx={{ px: 10, width: { lg: "40vw", md: "60vw" } }}>
      <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mb: 8, mt: 15 }} textstyle="h4Headline">
        Qual é a moeda?
      </LqdTypography>
      <Select
        IconComponent={(props) => (
          <LqdIconButton type="ghostPrimary" {...props} sx={{ "&.MuiButtonBase-root": { top: 0 } }}>
            <LqdDownTwoIcon />
          </LqdIconButton>
        )}
        onChange={(event) => handleValueChange(event.target.value)}
        renderValue={() =>
          country && currency ? (
            <>
              <LqdTypography sx={{ display: "inline", mr: 2 }} textstyle="h4Headline">
                🇧🇷
              </LqdTypography>
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{ display: "inline" }}
                textstyle="h4Headline"
              >{`${country} / ${currency}`}</LqdTypography>
            </>
          ) : (
            selectPlaceholder
          )
        }
        sx={{
          "& .MuiSelect-select": { p: 1 },
          "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
          "&:after": {
            borderBottom: !country ? "2px solid rgba(33, 36, 42, 1)" : "2px solid rgba(56, 61, 70, 1)",
          },
          "&:before": {
            borderBottom: !country ? "2px solid rgba(212, 215, 220, 1)" : "2px solid rgba(127, 135, 152, 0.32)",
          },
          "&:hover": { borderBottom: !country ? "2px solid rgba(65, 70, 103, 1)" : null },
          "&:not(.Mui-disabled):hover::before": {
            borderBottom: !country ? "1px solid rgba(212, 215, 220, 1)" : "2px solid rgba(56, 61, 70, 1)",
          },
          height: "60px",
          width: "100%",
        }}
        value={`${country} / ${currency}`}
        variant="standard"
      >
        {options?.map((option) => (
          <MenuItem
            key={option}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                border: "3px solid rgba(56, 61, 70, 1)",
              },
              "&.MuiMenuItem-root": { backgroundColor: "rgba(247, 247, 248, 1)", mb: 3 },
              "&.MuiMenuItem-root:hover": { backgroundColor: "rgba(225, 225, 225, 1)" },
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
              ":first-child": { mt: 1 },
              ":last-child": { mb: 1 },
              backgroundColor: "rgba(191, 196, 205, 0.12)",
              borderRadius: 5,
              height: "108px",
              mx: 1,
            }}
            value={option}
          >
            <LqdTypography sx={{ display: "inline", mr: 2 }} textstyle="h4Headline">
              🇧🇷
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "inline" }} textstyle="h4Headline">
              {option}
            </LqdTypography>
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ mt: 37.5, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          Avançar
        </LqdButton>
      </Box>
    </Box>
  );
}
