import { LqdTypography } from "@/liquid-components/src";
import { highlightText } from "@common/utils/highlightText";
import { truncateString } from "@common/utils/truncateString";
import { Stack } from "@mui/material";
import { SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { SimulatorVariableTypeTemplate } from "@simulatorBuilder/types/SimulatorVariableTypeTemplate";

type SimulatorTesterLeftSideBarVariablesBoxProps = {
  inputSearchData: SimulatorTesterSearchData;
  variable: SimulatorVariable;
  variableTypes: Array<SimulatorVariableTypeTemplate>;
};

/** Componente que renderiza uma caixa de variáveis na barra lateral esquerda do simulador.
 * @param props.inputSearchData - Dados de busca de entrada.
 * @param props.variable - Variável a ser exibida.
 * @param props.variableTypes - Tipos de variáveis disponíveis.
 */
export const simulatorTesterLeftSideBarVariablesBox = (props: SimulatorTesterLeftSideBarVariablesBoxProps) => {
  const { inputSearchData, variable, variableTypes } = props;

  return (
    <>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack
          sx={{
            background:
              inputSearchData.searchedText === variable.label ? "rgba(249, 249, 250, 1)" : "rgba(236, 238, 240, 1)",
            borderRadius: 5,
            mb: 0.5,
            p: "4px 8px",
            width: "fit-content",
          }}
        >
          <LqdTypography color="rgba(127, 138, 155, 1)" textstyle="buttonXS">
            {truncateString(variable.name, 25)}
          </LqdTypography>
        </Stack>
        {variable.is_input ? (
          <Stack
            sx={{
              background:
                inputSearchData.searchedText === variable.label ? "rgba(249, 249, 250, 1)" : "rgba(236, 238, 240, 1)",
              borderRadius: 5,
              mb: 0.5,
              p: "4px 8px",
              width: "fit-content",
            }}
          >
            <LqdTypography color="rgba(127, 138, 155, 1)" textstyle="buttonXS">
              {variable.order_index}
            </LqdTypography>
          </Stack>
        ) : null}
      </Stack>
      <LqdTypography textstyle="l1Label">
        {inputSearchData && inputSearchData.searchedText
          ? highlightText(truncateString(variable.label, 25), inputSearchData.searchedText)
          : truncateString(variable.label, 25)}
      </LqdTypography>
      <Stack direction="row" justifyContent="space-between">
        <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="l2Label">
          {variableTypes.find((type) => type.value === variable.type)?.label
            ? `${variableTypes.find((type) => type.value === variable.type)?.label}`
            : ""}
        </LqdTypography>
        {variable.category === "constant" ? (
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="l2Label">
            {variable.value}
          </LqdTypography>
        ) : null}
      </Stack>
    </>
  );
};
