import { get } from "../../common/utils/apiUtils";
import { AnalysisPendencyRaw } from "../types/AnalysisDetails/Analysis/AnalysisPendencyRaw";

/**
 * Endpoint que busca as pendências de uma conexão.
 * @param analysisId ID da conexão.
 * @returns Pendências da conexão, no formato AnalysisPendencyRaw.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getAnalysisPendenciesList = (analysisId: string) =>
  get<Array<AnalysisPendencyRaw>>(`/pendencies/${analysisId}`);
