import { Grid } from "@mui/material";
import { ReactNode } from "react";

type FrontPageLayoutProps = {
  hideLeftContentOnPhone?: boolean;
  leftContent: ReactNode;
  rightContent: ReactNode;
};

export default function FrontPageLayout(props: FrontPageLayoutProps) {
  const { hideLeftContentOnPhone, leftContent, rightContent } = props;

  return (
    <Grid
      className="lqd-frontpage-layout"
      container
      sx={{
        height: "100vh",
        justifyContent: "flex-end",
        overflowY: "auto",
        width: 1,
      }}
    >
      <Grid
        className="lqd-frontpage-layout__left-content"
        item
        md={7}
        sm={6}
        sx={{
          background: `radial-gradient(75.66% 116.22% at 135% 50%, rgba(206, 217, 223, 0.95) 0%,
          rgba(206, 217, 223, 0.70) 37.48%, rgba(206, 217, 223, 0.00) 79.51%)`,
          height: hideLeftContentOnPhone ? { sm: "100vh", xs: 0 } : "100vh",
          position: "relative",
        }}
        xs={12}
      >
        {leftContent}
      </Grid>
      <Grid
        className="lqd-frontpage-layout__right-content"
        item
        md={5}
        sm={6}
        sx={{ minHeight: { sm: "unset", xs: "100vh" } }}
        xs={12}
      >
        {rightContent}
      </Grid>
    </Grid>
  );
}
