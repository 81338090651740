export const percentageMaskOptions = {
  allowDecimal: true,
  allowLeadingZeroes: false,
  allowNegative: false,
  decimalLimit: 6,
  decimalSymbol: ",",
  includeThousandsSeparator: true,
  integerLimit: null,
  prefix: "",
  suffix: "%",
  thousandsSeparatorSymbol: "",
};
