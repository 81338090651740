import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterOne() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        1. DEFINIÇÕES
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        1.1. Antes de começarmos, abaixo estão alguns termos utilizados nesta Política para que, em caso de dúvidas,
        você possa consultar o seu significado:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) “LGPD”: Lei nº 13.709/2018 – a Lei Geral de Proteção de Dados.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) “Dados Pessoais”, “Dado Pessoal” ou “Dados”: toda e qualquer informação relacionada a uma pessoa natural
        identificada ou identificável. Ou seja, toda informação capaz de identificar direta ou indiretamente um
        indivíduo. Ex.: nome, telefone, e-mail, endereço, número do documento, dados bancários, dentre outros.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        c) “Dados Pessoais Sensíveis”: Dados Pessoais sobre origem racial ou étnica, convicção religiosa, opinião
        política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à
        saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        d) “Tratamento”: toda operação realizada com Dados Pessoais, como as que se referem a coleta, produção,
        recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento,
        armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão
        ou extração.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        e) “Titular”: pessoal natural a quem se referes os dados pessoais que são objeto do Tratamento.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        f) “Encarregado” ou “DPO”: pessoa indicada pela Liquid para atuar como canal de comunicação entre a Empresa, os
        Titulares e a Autoridade Nacional de Proteção de Dados (ANPD) em relação ao tema “proteção de dados”.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        g) “Controlador”: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
        referentes ao Tratamento de Dados Pessoais.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        h) “Operador”: pessoa natural ou jurídica, de direito público ou privado, que realiza o Tratamento de Dados
        Pessoais em nome do Controlador.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        i) “Usuário Decisor”: pessoa natural ou jurídica que contratar as Soluções da Liquid para tomada de decisões e,
        durante esse processo, realiza o Tratamento de Dados Pessoais.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        j) “Usuário Analisado e/ou Monitorado”: Titular dos Dados Pessoais que são objeto de Tratamento através do uso
        das Soluções da Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        k) “Contratante(s)”: toda pessoa natural ou jurídica que tiver contato, receber uma proposta comercial
        encaminhada pela Liquid ou que se cadastrar para utilizar quaisquer das Soluções disponibilizadas pela Liquid.
        Em alguns casos, o Contratante será o “Usuário Analisado e/ou Monitorado” (Titular dos Dados). Em outros casos,
        o Contratante figurará como “Usuário Decisor” (Controlador dos Dados).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        l) “Banco de Dados”: conjunto estruturado de Dados Pessoais, estabelecido em um ou em vários locais, em suporte
        eletrônico ou físico.
      </LqdTypography>
    </>
  );
}
