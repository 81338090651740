import { LqdIconProps } from "../../types/IconProps";

export default function LqdServerDatabaseIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 10C17 7.791 15.209 6 13 6H6C3.791 6 2 7.791 2 10C2 12.209 3.791 14 6 14H12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17 20C14.239 20 12 17.761 12 15C12 12.239 14.239 10 17 10C19.762 10 22 12.239 22 15C22 17.761 19.762 20 17 20"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8 10H12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M6.03501 9.96499C6.05501 9.98499 6.05501 10.016 6.03501 10.036C6.01501 10.056 5.98401 10.056 5.96401 10.036C5.94401 10.016 5.94401 9.98499 5.96401 9.96499C5.98401 9.94499 6.01601 9.94499 6.03501 9.96499"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17 12.778V13.522"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17 16.478V17.222"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.0779 13.889L15.7219 14.256"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.289 15.744L18.922 16.111"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.0779 16.111L15.7219 15.744"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.289 14.256L18.922 13.889"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17.0001 13.518C17.8191 13.518 18.4821 14.182 18.4821 15C18.4821 15.818 17.8191 16.482 17.0001 16.482C16.1811 16.482 15.5181 15.819 15.5181 15C15.5181 14.181 16.1811 13.518 17.0001 13.518"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
