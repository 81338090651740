import { LqdIconProps } from "../../types/IconProps";

export default function LqdConnectionIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0833 14.75H12.9166C14.4356 14.75 15.6666 13.519 15.6666 12V8.33337C15.6666 6.81446 14.4356 5.58337 12.9166 5.58337H5.58331C4.0644 5.58337 2.83331 6.81446 2.83331 8.33337V12C2.83331 13.519 4.0644 14.75 5.58331 14.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.9166 9.25H11.0833C9.5644 9.25 8.33331 10.4811 8.33331 12V15.6667C8.33331 17.1856 9.5644 18.4167 11.0833 18.4167H18.4166C19.9356 18.4167 21.1666 17.1856 21.1666 15.6667V12C21.1666 10.4811 19.9356 9.25 18.4166 9.25V9.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
