import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
  },
});

export default function PDFSantanderSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 400 400">
        <Circle cx="200" cy="200" fill="rgb(236, 0, 0)" r="200" />
        <Path
          d="M237.55 172.244C241.012 178.369 243.009 185.16 243.276 192.084C276.831 200.872 300 219.514 300 241.085C300 271.045 255.26 295.28 200 295.28C144.74 295.28 100 271.045 100 241.085C100 219.514 123.169 200.872 156.724 192.217C156.325 200.473 158.322 208.595 162.45 215.786L193.742 270.113C196.005 274.108 197.736 278.502 198.668 283.162L200 280.899C207.723 267.45 207.723 250.939 200 237.49L174.834 194.081C167.244 180.632 167.244 164.121 174.967 150.806L176.298 148.542C177.23 153.069 178.828 157.463 181.225 161.591L195.872 186.891L218.775 226.704C221.172 230.699 222.77 235.093 223.702 239.754L225.033 237.49C232.756 224.041 232.756 207.53 225.033 194.081L200 150.672C192.277 137.224 192.277 120.712 200 107.264L201.332 105C202.264 109.66 203.862 114.055 206.258 118.182L237.55 172.244Z"
          fill="rgb(255, 255, 255)"
        />
      </Svg>
    </View>
  );
}
