import { LqdIconProps } from "../../types/IconProps";

export default function LqdOriginIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9V15"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15 12H9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 3.57857C12.036 3.57857 12.0643 3.60686 12.0643 3.64286C12.0643 3.67886 12.036 3.70714 12 3.70714C11.964 3.70714 11.9357 3.67886 11.9357 3.64286C11.9357 3.60686 11.964 3.57857 12 3.57857"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 20.2929C12.036 20.2929 12.0643 20.3212 12.0643 20.3572C12.0643 20.3932 12.036 20.4214 12 20.4214C11.964 20.4214 11.9357 20.3932 11.9357 20.3572C11.9357 20.3212 11.964 20.2929 12 20.2929"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M7.7893 4.70738C7.82016 4.68938 7.85873 4.69967 7.87673 4.73053C7.89473 4.76138 7.88445 4.79995 7.85359 4.81795C7.82273 4.83595 7.78416 4.82567 7.76616 4.79481C7.74816 4.76395 7.75845 4.72538 7.7893 4.70738"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16.1464 19.1821C16.1773 19.1641 16.2158 19.1743 16.2338 19.2052C16.2518 19.2361 16.2416 19.2746 16.2107 19.2926C16.1798 19.3106 16.1413 19.3003 16.1233 19.2695C16.1053 19.2386 16.1156 19.2001 16.1464 19.1821"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4.70738 7.7893C4.72538 7.75845 4.76395 7.74816 4.79481 7.76616C4.82567 7.78416 4.83595 7.82273 4.81795 7.85359C4.79995 7.88445 4.76138 7.89473 4.73053 7.87673C4.69967 7.85873 4.68938 7.82016 4.70738 7.7893"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M19.1821 16.1464C19.2001 16.1156 19.2386 16.1053 19.2695 16.1233C19.3003 16.1413 19.3106 16.1798 19.2926 16.2107C19.2746 16.2416 19.2361 16.2518 19.2052 16.2338C19.1743 16.2158 19.1641 16.1773 19.1821 16.1464"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3.57857 12C3.57857 11.964 3.60686 11.9357 3.64286 11.9357C3.67886 11.9357 3.70714 11.964 3.70714 12C3.70714 12.036 3.67886 12.0643 3.64286 12.0643C3.60686 12.0643 3.57857 12.036 3.57857 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M20.2929 12C20.2929 11.964 20.3212 11.9357 20.3572 11.9357C20.3932 11.9357 20.4214 11.964 20.4214 12C20.4214 12.036 20.3932 12.0643 20.3572 12.0643C20.3212 12.0643 20.2929 12.036 20.2929 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M4.70738 16.2107C4.68938 16.1798 4.69967 16.1413 4.73053 16.1233C4.76138 16.1053 4.79995 16.1156 4.81795 16.1464C4.83595 16.1773 4.82567 16.2158 4.79481 16.2338C4.76395 16.2518 4.72538 16.2416 4.70738 16.2107"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M19.1821 7.85359C19.1641 7.82273 19.1743 7.78416 19.2052 7.76616C19.2361 7.74816 19.2746 7.75845 19.2926 7.7893C19.3106 7.82016 19.3003 7.85873 19.2695 7.87673C19.2386 7.89473 19.2001 7.88445 19.1821 7.85359"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M7.7893 19.2926C7.75845 19.2746 7.74816 19.2361 7.76616 19.2052C7.78416 19.1743 7.82273 19.1641 7.85359 19.1821C7.88445 19.2001 7.89473 19.2386 7.87673 19.2695C7.85873 19.3003 7.82016 19.3106 7.7893 19.2926"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16.1464 4.81795C16.1156 4.79995 16.1053 4.76138 16.1233 4.73053C16.1413 4.69967 16.1798 4.68938 16.2107 4.70738C16.2416 4.72538 16.2518 4.76395 16.2338 4.79481C16.2158 4.82567 16.1773 4.83595 16.1464 4.81795"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 20.4214C11.964 20.4214 11.9357 20.3932 11.9357 20.3572C11.9357 20.3212 11.964 20.2929 12 20.2929C12.036 20.2929 12.0643 20.3212 12.0643 20.3572C12.0643 20.3932 12.036 20.4214 12 20.4214"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12 3.70714C11.964 3.70714 11.9357 3.67886 11.9357 3.64286C11.9357 3.60686 11.964 3.57857 12 3.57857C12.036 3.57857 12.0643 3.60686 12.0643 3.64286C12.0643 3.67886 12.036 3.70714 12 3.70714"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
