import { LqdCloseIcon, LqdIconButton, LqdPlusTwoIcon, LqdTypography, LqdUserFourIcon } from "@/liquid-components/src";
import { Box, Card, CardContent, CardMedia, useTheme } from "@mui/material";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import startFlowImg from "../../../assets/start-flow.png";
import Proponent from "../../../types/Proponent";
import DashedAddButton from "./DashedAddButton";
import TargetForm from "./TargetForm";

type JumpActionProps = {
  onClose: () => void;
  setTemporaryProponents: (proponents: Array<Proponent>) => void;
  temporaryProponents: Array<Proponent>;
};

export default function StartFlow(props: JumpActionProps) {
  const { onClose, setTemporaryProponents, temporaryProponents } = props;

  const theme = useTheme();

  const [hoveringAddButton, setHoveringAddButton] = useState(false);

  const addProponent = () => {
    const newProponent: Proponent = {
      acceptMultiples: true,
      id: uuid(),
      mandatory: true,
      name: "",
      type: [""],
    };
    const updatedProponents = [...temporaryProponents];
    updatedProponents.push(newProponent);
    setTemporaryProponents(updatedProponents);
  };

  const handleUpdateProponent = (updatedProponent: Proponent) => {
    const proponentIndex = temporaryProponents.findIndex((proponent) => proponent.id === updatedProponent.id);
    if (proponentIndex >= 0) {
      const updatedProponents = [...temporaryProponents];
      updatedProponents.splice(proponentIndex, 1, updatedProponent);
      setTemporaryProponents(updatedProponents);
    }
  };

  const handleDeleteProponent = (index: number) => {
    const updatedProponents = [...temporaryProponents];
    updatedProponents.splice(index, 1);
    setTemporaryProponents(updatedProponents);
  };

  const handleMoveProponentUp = (index: number) => {
    if (index > 0) {
      const updatedProponents = [...temporaryProponents];
      const previousProponent = updatedProponents[index - 1];
      updatedProponents[index - 1] = updatedProponents[index];
      updatedProponents[index] = previousProponent;
      setTemporaryProponents(updatedProponents);
    }
  };

  const handleMoveProponentDown = (index: number) => {
    const temporaryProponentsLength = temporaryProponents.length;
    if (index < temporaryProponentsLength - 1) {
      const updatedProponents = [...temporaryProponents];
      const nextProponent = updatedProponents[index + 1];
      updatedProponents[index + 1] = updatedProponents[index];
      updatedProponents[index] = nextProponent;
      setTemporaryProponents(updatedProponents);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(249, 249, 250, 1)",
          minWidth: "380px",
          mt: -6,
          position: "fixed",
          pt: 6,
          zIndex: 1,
        }}
      >
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", mr: 3 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
            Start Flow
          </LqdTypography>
          <LqdIconButton className="lqd-config-sidebar__close-button" onClick={onClose} type="ghostPrimary">
            <LqdCloseIcon color="rgba(127, 135, 152, 1)" />
          </LqdIconButton>
        </Box>
      </Box>
      <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3, mt: 8 }} textstyle="p2Paragraph">
        Add your target audience choosing a type and a name to each user
      </LqdTypography>
      {temporaryProponents.length ? (
        <>
          {temporaryProponents.map((proponent, index) => (
            <TargetForm
              hideDownButton={index === temporaryProponents.length - 1}
              hideUpButton={index === 0}
              key={proponent.id}
              multiple={temporaryProponents.length > 1}
              onDeleteProponent={() => handleDeleteProponent(index)}
              onMoveProponentDown={() => handleMoveProponentDown(index)}
              onMoveProponentUp={() => handleMoveProponentUp(index)}
              onUpdateProponent={handleUpdateProponent}
              proponent={proponent}
            />
          ))}
          <DashedAddButton onClick={addProponent} sx={{ mb: 3 }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              New target
            </LqdTypography>
          </DashedAddButton>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 8,
            width: "100%",
          }}
        >
          <Box
            onClick={addProponent}
            onMouseEnter={() => setHoveringAddButton(true)}
            onMouseLeave={() => setHoveringAddButton(false)}
            sx={{
              alignItems: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              height: "150px",
              justifyContent: "center",
              position: "relative",
              width: "150px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: hoveringAddButton ? theme.palette.primary.dark : theme.palette.primary.main,
                borderRadius: "50%",
                display: "flex",
                height: "24px",
                justifyContent: "center",
                p: 2,
                position: "absolute",
                right: "-8px",
                top: "-8px",
                width: "24px",
              }}
            >
              <LqdPlusTwoIcon color="rgba(255, 255, 255, 1)" size={16} />
            </Box>
            <LqdUserFourIcon color={theme.palette.primary.main} size={82} />
          </Box>
        </Box>
      )}
      <Card elevation={0} sx={{ borderRadius: "10px", padding: 0 }}>
        <CardMedia component="img" image={startFlowImg} />
        <CardContent>
          <LqdTypography color="rgba(65, 70, 103, 1)" sx={{ mb: 2, ml: 1 }}>
            About
          </LqdTypography>
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            The Target User determines what or who you want to combine with your Product.
          </LqdTypography>
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            Add Providers to choose what will be analyzed and Actions to give automatic conclusions to different paths
            of the Product's flow.
          </LqdTypography>
        </CardContent>
      </Card>
    </>
  );
}
