import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterFour() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        4. COMPARTILHAMENTO DE DADOS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.1. Através de suas Soluções, a Liquid também funciona como canal de comunicação entre o Usuário Decisor, o
        Usuário Analisado e/ou Monitorado e outros agentes envolvidos na operação, de modo a permitir maior agilidade
        aos processos de análise e gestão de documentos, hipótese em que os Dados Pessoais do Usuário Analisado e/ou
        Monitorado são ou podem ser compartilhados com terceiros.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.2. Nas operações que envolvem transações financeiras, tais como, sem se limitar, cessão de crédito,
        financiamento de projetos, financiamento por incorporadoras, financiamento de unidades imobiliárias pelo Usuário
        Analisado e/ou Monitorado ou transações de carteiras de investimentos, os Dados Pessoais do Usuário Analisado
        e/ou Monitorado podem ser compartilhados pelo Usuário Decisor com instituições financeiras, investidores ou
        quaisquer agentes intermediários necessários.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.3. Via de regra, os Dados Pessoais do Usuário Analisado e/ou Monitorado são compartilhados de forma
        anonimizada ou pseudonimizada, de modo que os terceiros envolvidos nas transações financeiras têm acesso apenas
        aos dados referentes à operação da qual o Usuário Analisado e/ou Monitorado faz parte, sem informações
        complementares que permitem lhe identificar direta ou indiretamente como um indivíduo.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.4. O compartilhamento de quaisquer Dados Pessoais ou demais informações do Usuário Analisado e/ou Monitorado
        deve ser realizado dentro do estritamente necessário para permitir a viabilização e continuidade das transações
        financeiras ou demais operações em que o Usuário Analisado e/ou Monitorado estiver envolvido, cabendo ao Usuário
        Decisor definir os Parâmetros necessários e terceiros envolvidos na operação, nos termos do item 12.5 desta
        Política.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.5. Caso tenha dúvidas sobre como os seus Dados Pessoais podem ser compartilhados ou acessados pelas Soluções
        da Liquid, o Titular dos Dados poderá nos contatar pelo endereço de e-mail dpo@askliquid.com, hipótese em que
        encaminharemos ao Controlador dos Dados a sua solicitação.
      </LqdTypography>
    </>
  );
}
