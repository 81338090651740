import { Stack } from "@mui/material";
import { LqdCircleFilledIcon } from "../../icons";

type LqdMenuStepperProps = {
  currentStep: number;
  stepsAmount: number;
};

export default function LqdMenuStepper(props: LqdMenuStepperProps) {
  const { currentStep, stepsAmount } = props;

  return (
    <Stack alignItems="center" flexDirection="row" justifyContent="center" mt={1.5}>
      {Array.from({ length: stepsAmount }).map((_, index) => (
        <LqdCircleFilledIcon
          color={currentStep === index + 1 ? "rgba(33, 36, 42, 1)" : "rgba(217, 217, 217, 1)"}
          key={index}
          size={14}
        />
      ))}
    </Stack>
  );
}
