import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
  },
});

export default function PDFBancoBrasilSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 400 400">
        <Circle cx="200" cy="200" fill="rgb(255, 242, 0)" r="200" />
        <Path
          d="M259.429 127.218L279.903 141.247L299.941 127.856L300 100.345L259.429 127.218ZM141.034 273.013L120.63 259.1L100 273.038L100.205 300L141.034 273.013ZM218.52 154.014L238.517 167.465L143.505 231.751L202.214 271.377L229.786 252.689L208.368 238.504L257.799 205.372L299.157 232.812L199.235 299.421L100.64 233.276L218.52 154.014ZM181.091 245.211L161.096 231.76L256.107 167.474L197.398 127.849L169.827 146.535L191.243 160.723L141.813 193.853L100.454 166.414L200.021 99.8037L298.875 166.241L181.091 245.211Z"
          fill="rgb(26, 93, 173)"
          fillRule="evenodd"
        />
      </Svg>
    </View>
  );
}
