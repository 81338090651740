import { Edge } from "react-flow-renderer/dist/nocss/esm";
import { post } from "../../common/utils/apiUtils";
import RawNode from "../types/RawNode";

export const upsertRules = (
  rules: Array<RawNode>,
  edges: Array<Edge>,
  product_id: string,
  auto_save: boolean,
  publish?: boolean
) =>
  post<{ bad_connections?: Array<string> }>(publish ? "/nodes/rules" : "/nodes/rules/save", {
    auto_save,
    edges,
    product_id,
    rules,
  });
