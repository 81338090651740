import { CircularProgress, SxProps } from "@mui/material";

type LqdCircularLoaderProps = {
  size?: number | string;
  sx?: SxProps;
};

export default function LqdCircularLoader(props: LqdCircularLoaderProps) {
  const { size = 24, sx } = props;

  return <CircularProgress sx={{ height: `${size}px !important`, width: `${size}px !important`, ...sx }} />;
}
