import { LqdIconProps } from "../../types/IconProps";

export default function LqdHumanIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 3C7.037 3 3 7.037 3 12C3 16.963 7.037 21 12 21C16.963 21 21 16.962 21 12C21 7.038 16.962 3 12 3Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.3281 9.5C15.1901 9.5 15.0781 9.612 15.0791 9.75C15.0791 9.888 15.1911 10 15.3291 10C15.4671 10 15.5791 9.888 15.5791 9.75C15.5791 9.612 15.4671 9.5 15.3281 9.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8.6389 9.5C8.5009 9.5 8.3889 9.612 8.3899 9.75C8.3899 9.888 8.5019 10 8.6399 10C8.7779 10 8.8899 9.888 8.8899 9.75C8.8899 9.612 8.7779 9.5 8.6389 9.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.5 15.688C15.5 15.688 14.187 17 12 17C9.812 17 8.5 15.688 8.5 15.688"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
