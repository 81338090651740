import { Stack } from "@mui/material";
import TermsAndConditionsOptionOneChapterZero from "./EULA/TermsAndConditionsOptionsOne/00Chapter";
import TermsAndConditionsOptionOneChapterOne from "./EULA/TermsAndConditionsOptionsOne/01Chapter";
import TermsAndConditionsOptionOneChapterTwo from "./EULA/TermsAndConditionsOptionsOne/02Chapter";
import TermsAndConditionsOptionOneChapterThree from "./EULA/TermsAndConditionsOptionsOne/03Chapter";
import TermsAndConditionsOptionOneChapterFour from "./EULA/TermsAndConditionsOptionsOne/04Chapter";
import TermsAndConditionsOptionOneChapterFive from "./EULA/TermsAndConditionsOptionsOne/05Chapter";
import TermsAndConditionsOptionOneChapterSix from "./EULA/TermsAndConditionsOptionsOne/06Chapter";
import TermsAndConditionsOptionOneChapterEight from "./EULA/TermsAndConditionsOptionsOne/08Chapter";
import TermsAndConditionsOptionOneChapterNine from "./EULA/TermsAndConditionsOptionsOne/09Chapter";
import TermsAndConditionsOptionOneChapterTen from "./EULA/TermsAndConditionsOptionsOne/10Chapter";
import TermsAndConditionsOptionOneChapterEleven from "./EULA/TermsAndConditionsOptionsOne/11Chapter";
import TermsAndConditionsOptionOneChapterTwelve from "./EULA/TermsAndConditionsOptionsOne/12Chapter";
import TermsAndConditionsOptionOneChapterThirteen from "./EULA/TermsAndConditionsOptionsOne/13Chapter";
import TermsAndConditionsOptionOneChapterFourteen from "./EULA/TermsAndConditionsOptionsOne/14Chapter";
import TermsAndConditionsOptionOneChapterFifteen from "./EULA/TermsAndConditionsOptionsOne/15Chapter";
import TermsAndConditionsOptionOneChapterSixteen from "./EULA/TermsAndConditionsOptionsOne/16Chapter";
import TermsAndConditionsOptionOneChapterSeventeen from "./EULA/TermsAndConditionsOptionsOne/17Chapter";
import TermsAndConditionsOptionOneChapterSeven from "./EULA/TermsAndConditionsOptionsOne/07Chapter";

export default function TermsAndConditionsOptionOne() {
  return (
    <Stack gap={3}>
      <TermsAndConditionsOptionOneChapterZero />
      <TermsAndConditionsOptionOneChapterOne />
      <TermsAndConditionsOptionOneChapterTwo />
      <TermsAndConditionsOptionOneChapterThree />
      <TermsAndConditionsOptionOneChapterFour />
      <TermsAndConditionsOptionOneChapterFive />
      <TermsAndConditionsOptionOneChapterSix />
      <TermsAndConditionsOptionOneChapterSeven />
      <TermsAndConditionsOptionOneChapterEight />
      <TermsAndConditionsOptionOneChapterNine />
      <TermsAndConditionsOptionOneChapterTen />
      <TermsAndConditionsOptionOneChapterEleven />
      <TermsAndConditionsOptionOneChapterTwelve />
      <TermsAndConditionsOptionOneChapterThirteen />
      <TermsAndConditionsOptionOneChapterFourteen />
      <TermsAndConditionsOptionOneChapterFifteen />
      <TermsAndConditionsOptionOneChapterSixteen />
      <TermsAndConditionsOptionOneChapterSeventeen />
    </Stack>
  );
}
