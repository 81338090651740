import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";
import PDFSBPESimulatorBuildingTimeInfoRow from "./PDFSBPESimulatorBuildingTimeInfoRow";
import PDFSBPESimulatorBuildingTimeTable from "./PDFSBPESimulatorBuildingTimeTable";

type PDFSBPESimulatorBuildingTimeInfoProps = {
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  sbpeSimulatorLabel: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  sbpeTableContainer: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    paddingHorizontal: "24px",
    width: "100%",
  },
});

export default function PDFSBPESimulatorBuildingTimeInfo(props: PDFSBPESimulatorBuildingTimeInfoProps) {
  const { highlight } = props;

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.sbpeSimulatorLabel}>Simulação de Pagamento Durante a Obra</Text>
      <View minPresenceAhead={40} style={styles.sbpeTableContainer}>
        <PDFSBPESimulatorBuildingTimeInfoRow highlight={highlight} />
      </View>
      <PDFSBPESimulatorBuildingTimeTable highlight={highlight} />
    </View>
  );
}
