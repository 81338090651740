import { Box, Dialog, DialogProps } from "@mui/material";
import { LqdCloseIcon } from "../../icons";

export default function LqdDialog(props: DialogProps & { customMaxWidth?: string }) {
  const { children, customMaxWidth, onClose, PaperProps } = props;

  return (
    <Dialog
      {...props}
      className="lqd-dialog"
      PaperProps={{
        ...PaperProps,
        sx: {
          borderRadius: "20px",
          boxShadow: "0px 20px 40px -8px rgba(16, 24, 40, 0.10)",
          maxWidth: { sm: customMaxWidth || "452px" },
          minWidth: { sm: "352px" },
          p: 3,
          position: "relative",
          ...PaperProps?.sx,
        },
      }}
    >
      <Box
        onClick={(ev) => (onClose ? onClose(ev, "escapeKeyDown") : null)}
        sx={{ cursor: "pointer", position: "absolute", right: "24px", top: "24px" }}
      >
        <LqdCloseIcon color="rgba(115, 115, 115, 1)" />
      </Box>
      {children}
    </Dialog>
  );
}
