import { LqdIconProps } from "../../types/IconProps";

export default function LqdEllipsisIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.997 17.5023C11.7219 17.5023 11.4968 17.7274 11.4998 18.0025C11.4998 18.2776 11.7249 18.5027 12 18.5027C12.2751 18.5027 12.5002 18.2776 12.5002 18.0025C12.5002 17.7274 12.2751 17.5023 11.997 17.5023"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.997 11.4998C11.7219 11.4998 11.4968 11.7249 11.4998 12C11.4998 12.2751 11.7249 12.5002 12 12.5002C12.2751 12.5002 12.5002 12.2751 12.5002 12C12.5002 11.7249 12.2751 11.4998 11.997 11.4998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.997 5.49731C11.7219 5.49731 11.4968 5.72241 11.4998 5.99752C11.4998 6.27264 11.7249 6.49773 12 6.49773C12.2751 6.49773 12.5002 6.27264 12.5002 5.99752C12.5002 5.72241 12.2751 5.49731 11.997 5.49731"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
