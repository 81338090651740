import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { LiquidPassCNPJProviderData } from "../../../../../types/AnalysisDetails/LiquidPass/LiquidPassCNPJProviderData";
import { formatValueToCurrency } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFGeneralInfoCard from "../PDFGeneralinfoCard";

type PDFLiquidpassCNPJBusinessInfoProps = {
  provider: LiquidPassCNPJProviderData;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  generalInfoText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingVertical: "40px",
  },
});

export default function PDFLiquidpassCNPJBusinessInfo(props: PDFLiquidpassCNPJBusinessInfoProps) {
  const { provider } = props;

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.generalInfoText}>Informações Gerais</Text>
      <View style={styles.generalInfoCardContainer}>
        <PDFGeneralInfoCard
          title="Faturamento Presumido"
          value={formatValueToCurrency(provider.revenue_presumed, "BRL", true)}
        />
        <PDFGeneralInfoCard
          title="Capital Social"
          value={formatValueToCurrency(provider.revenue_shared, "BRL", true)}
        />
        <PDFGeneralInfoCard title="Possui Veículo?" value={provider.has_vehicle ? "Sim" : "Não"} />
      </View>
    </View>
  );
}
