import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { BasicSimulation } from "../../../../../../types/Simulation/Basic/BasicSimulation";
import PDFBasicSimulatorTableRow1 from "../Basic/PDFBasicSimulatorTableRow1";
import PDFBasicSimulatorTableRow2 from "../Basic/PDFBasicSimulatorTableRow2";
import PDFBasicSimulatorTableRow3 from "../Basic/PDFBasicSimulatorTableRow3";

type PDFBasicSimulatorTableProps = {
  simulation: BasicSimulation;
};

const styles = StyleSheet.create({
  allotmentSimulatorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  allotmentSimulatorDateText: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
    paddingTop: "52px",
  },
  allotmentSimulatorLabel: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
  allotmentTableContainer: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    paddingHorizontal: "24px",
    width: "100%",
  },
});

export default function PDFBasicSimulatorTable(props: PDFBasicSimulatorTableProps) {
  const { simulation } = props;

  return (
    <View minPresenceAhead={40}>
      <View style={styles.allotmentSimulatorContainer}>
        <Text style={styles.allotmentSimulatorLabel}>Simulador básico</Text>
      </View>
      <View minPresenceAhead={40} style={styles.allotmentTableContainer}>
        <PDFBasicSimulatorTableRow1 simulation={simulation} />
        <PDFBasicSimulatorTableRow2 simulation={simulation} />
        <PDFBasicSimulatorTableRow3 simulation={simulation} />
      </View>
    </View>
  );
}
