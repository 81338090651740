import { AppError, AppErrorOptions } from "../../common/types/AppError";

export class StartNodeNotFoundError extends AppError {
  constructor(options?: AppErrorOptions) {
    super(
      {
        message: "É necessário definir um nó de partida para publicar o produto.",
      },
      options
    );
  }
}
