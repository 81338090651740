import { Avatar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import LqdLink from "../Link";
import LqdTooltip from "../Tooltip";
import LqdTypography from "../Typography";

type LqdTableCell<T> = {
  icon?: string | { color: string; icon: JSX.Element };
  primaryValue: JSX.Element | null | number | string;
  secondaryValue?: JSX.Element | string;
  tertiaryValue?: null | string | { method: (arg: T) => void; text: string };
  tooltip?: null | { body: JSX.Element | string; disabled?: boolean; header: string };
};

type LqdTableProps<T> = {
  height?: string;
  tableContent: Array<Array<LqdTableCell<T>>>;
  tableHeader: Array<string>;
};

/** Liquid generic Table component.
 * @param tableHeader - Array de strings que representam os headers da tabela.
 * @param tableContent - Matriz de objetos que representam o conteúdo da tabela.
 * @param tableContent.icon - Ícone (ou texto de ícone).
 * @param tableContent.primaryValue - Valor principal da célula.
 * @param tableContent.secondaryValue - Valor secundário da célula.
 * @param tableContent.tertiaryValue - Valor terciário da célula.
 * @param tableContent.tertiaryValue.method - Função a ser executada ao clicar no valor terciário.
 * @param tableContent.tertiaryValue.text - Texto do valor terciário.
 * @param tableContent.tooltip - Objeto que contém as informações do tooltip.
 * @param tableContent.tooltip.body - Corpo do tooltip.
 * @param tableContent.tooltip.disabled - Desabilita o tooltip.
 * @param tableContent.tooltip.header - Título do tooltip.
 */
export default function LqdTable<T>(props: LqdTableProps<T>) {
  const { height, tableContent, tableHeader } = props;

  const renderTertiaryValue = (cell: LqdTableCell<T>) => {
    if (!cell.tertiaryValue) return null;

    if (typeof cell.tertiaryValue === "string") {
      return (
        <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mt: 0.5 }} textstyle="p2Paragraph">
          {cell.tertiaryValue}
        </LqdTypography>
      );
    }

    return (
      <LqdLink
        onClick={cell.tooltip?.disabled ? () => {} : (cell.tertiaryValue?.method as () => void)}
        sx={{ cursor: cell.tooltip?.disabled ? "default" : "pointer", textDecoration: "none", width: "fit-content" }}
      >
        <LqdTypography
          color={cell.tooltip?.disabled ? "rgba(217, 217, 217, 1)" : "rgba(155, 162, 175, 1)"}
          textstyle="p2Paragraph"
        >
          {cell.tertiaryValue.text}
        </LqdTypography>
      </LqdLink>
    );
  };

  const renderIcon = (icon?: { color: string; icon: JSX.Element } | string) => {
    if (!icon) return null;

    if (typeof icon === "string") {
      return (
        <Avatar sx={{ backgroundColor: "rgba(65, 70, 103, 0.12)" }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
            {icon}
          </LqdTypography>
        </Avatar>
      );
    }

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: icon.color,
          borderRadius: "50%",
          display: { md: "flex", xs: "none" },
          height: "40px",
          width: "40px",
        }}
      >
        {icon.icon}
      </Stack>
    );
  };

  return (
    <TableContainer
      sx={{
        "& thead th": { backgroundColor: "rgba(248, 248, 249, 1)", position: "sticky", top: 0, zIndex: 1 },
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        maxHeight: height || "600px",
        minWidth: { sm: "1000px", xs: "900px" },
        overflow: "auto",
      }}
    >
      <Table>
        <TableHead sx={{ borderBottom: "1px solid rgba(222, 225, 229, 1)", height: "80px" }}>
          <TableRow>
            {tableHeader.map((hValue, index) => (
              <TableCell key={index} sx={{ border: "none", m: 0 }}>
                <LqdTypography textstyle="p2Paragraph">{hValue}</LqdTypography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableContent.map((row, index) => (
            <TableRow key={index} sx={{ ":hover": { backgroundColor: "rgba(127, 135, 152, 0.08)" } }}>
              {row.map((cell, index) => (
                <TableCell key={index}>
                  <LqdTooltip
                    hidetooltip={cell.tooltip ? "false" : "true"}
                    placement="left"
                    titlebody={cell.tooltip ? cell.tooltip.body : ""}
                    titleheader={cell.tooltip ? cell.tooltip.header : ""}
                  >
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      {renderIcon(cell.icon)}
                      <Stack sx={{ ml: cell.icon ? 2 : 0 }}>
                        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                          {cell.primaryValue}
                        </LqdTypography>
                        <LqdTypography
                          color="rgba(127, 135, 152, 1)"
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                          textstyle="c1Caption"
                        >
                          {cell.secondaryValue}
                        </LqdTypography>
                        {renderTertiaryValue(cell)}
                      </Stack>
                    </Stack>
                  </LqdTooltip>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
