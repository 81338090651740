import { LqdIconProps } from "../../types/IconProps";

export default function LqdClockFilledIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.55691 3.68702C13.1479 1.78502 18.4109 3.96602 20.3129 8.55702C22.2149 13.148 20.0339 18.411 15.4429 20.313C10.8519 22.215 5.58891 20.034 3.68691 15.443C1.78591 10.852 3.96591 5.58902 8.55691 3.68702Z"
        fill={color}
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.7178 7.98499V12.636L15.3738 14.865"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
