import { Line, StyleSheet, Svg, View } from "@react-pdf/renderer";

type PDFTrustpadLineSVGProps = {
  color: string;
};

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    height: "70px",
    marginRight: "18px",
    marginTop: "50px",
    maxWidth: "40px",
    position: "absolute",
  },
});

export default function PDFTrustpadLineSVG(props: PDFTrustpadLineSVGProps) {
  const { color } = props;

  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 2 62">
        <Line
          stroke={color}
          strokeDasharray="2"
          strokeLineCap="round"
          strokeWidth="0.5"
          x1="0.6"
          x2="0.599997"
          y1="1.38125"
          y2="61.1813"
        />
      </Svg>
    </View>
  );
}
