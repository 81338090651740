import { Route } from "react-router-dom";
import OpenCredit from "../features/analysisDetails/routes/open-credit";
import OpenFinancePage from "../features/analysisDetails/routes/open-finance";
import OpenCreditTermsAndConditions from "../features/common/routes/open-credit-terms-and-conditions";
import ExpiredLink from "../features/signature/components/ExpiredLink";
import SignatureFinish from "../features/signature/components/SignatureCloudFinish";
import Signature from "../features/signature/routes/Signature";
import TermsAndConditionsRoute from "../features/signin/routes/terms-and-conditions";
import VideoAskEmbed from "../features/uploadDocument/components/VideoAsk/VideoAskEmbed";

export default function CommonRoutes() {
  return (
    <>
      {/* Open Credit */}
      <Route element={<OpenCredit />} path="open-credit/agreement/confirmation/:method/:token/:agreeDisagree?" />
      <Route element={<OpenCreditTermsAndConditions />} path="open-credit/terms-and-conditions" />

      {/* Open Finance */}
      <Route element={<OpenFinancePage />} path="open-finance/agreement/confirmation/:method/:token/:agreeDisagree?" />

      {/* Signature */}
      <Route element={<ExpiredLink />} path="signature/expired" />
      <Route element={<Signature />} path="signature/:token" />
      <Route element={<SignatureFinish />} path="signature/finish" />

      {/* Terms and Conditions */}
      <Route element={<TermsAndConditionsRoute />} path="terms-and-conditions" />

      {/* VideoAsk */}
      <Route element={<VideoAskEmbed />} path="upload/document/:tokenId/:videoaskId" />
    </>
  );
}
