import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFMonetaryGeneralInfoCardProps = {
  size: "medium" | "small";
  title: string;
  value: number | string;
};

const styles = StyleSheet.create({
  generalInfoCardMedium: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "188px",
    justifyContent: "space-between",
    padding: "24px",
    width: "325px",
  },
  generalInfoCardSmall: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "188px",
    justifyContent: "space-between",
    padding: "24px",
    width: "245px",
  },
  generalInfoCardTitle: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    height: "50px",
  },
  generalInfoCardValue: {
    color: "rgb(7, 65, 84)",
    fontSize: "32px",
  },
  generalInfoCurrencyLabel: {
    fontSize: "12px",
  },
});

export default function PDFMonetaryGeneralInfoCard(props: PDFMonetaryGeneralInfoCardProps) {
  const { size, title, value } = props;

  return (
    <View style={size === "small" ? styles.generalInfoCardSmall : styles.generalInfoCardMedium}>
      <Text style={styles.generalInfoCardTitle}>{title}</Text>
      <View>
        <Text style={styles.generalInfoCurrencyLabel}>R$</Text>
        <Text style={styles.generalInfoCardValue}>{value}</Text>
      </View>
    </View>
  );
}
