import { LqdIconProps } from "../../types/IconProps";

export default function LqdInputVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill={color} height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M17.8408 6.65921C21.0666 9.885 21.0666 15.115 17.8408 18.3408C14.615 21.5666 9.38498 21.5666 6.15921 18.3408C2.93342 15.115 2.93342 9.88498 6.15921 6.65921C9.385 3.43342 14.615 3.43342 17.8408 6.65921Z"
        fill="rgba(207, 211, 218, 1)"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
