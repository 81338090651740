import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
import { ObjectOf } from "../../../../../../../common/types/ObjectOf";
import { SBPESimulation } from "../../../../../../types/Simulation/SBPE/SBPESimulation";
import { SBPESimulationResult } from "../../../../../../types/Simulation/SBPE/SBPESimulationResult";
import PDFSBPEBankLabel from "./PDFSBPEBankLabel";
import PDFSBPEBottomSimulatorInfo from "./PDFSBPEBottomSimulatorInfo";
import PDFSBPEUpperSimulatorInfo from "./PDFSBPEUpperSimulatorInfo";

type PDFSBPEBankTablesProps = {
  simulation: SBPESimulation;
};

const styles = StyleSheet.create({
  sbpeBankTableTypeFullContainerWithBorder: {
    borderBottom: "1px solid rgb(222, 225, 229)",
    marginTop: "20px",
    paddingBottom: "48px",
    width: "100%",
  },
  sbpeBankTableTypeFullContainerWithoutBorder: {
    marginTop: "20px",
    paddingBottom: "48px",
    width: "100%",
  },
  sbpeSimulatorLabel: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
  },
});

export default function PDFSBPEBankTables(props: PDFSBPEBankTablesProps) {
  const { simulation } = props;

  const getBanksByName = (banks: Array<SBPESimulationResult>) => {
    const parsedBanks: ObjectOf<Array<SBPESimulationResult>> = {};
    banks.forEach((bank: SBPESimulationResult) => {
      if (!parsedBanks[bank.bank_rate.bank_name]) {
        parsedBanks[bank.bank_rate.bank_name] = [];
      }
      parsedBanks[bank.bank_rate.bank_name].push(bank);
    });
    return parsedBanks;
  };

  const parsedBankData = Object.values(getBanksByName(simulation.result));

  return (
    <>
      <Text style={styles.sbpeSimulatorLabel}>Simuladores</Text>
      {parsedBankData.map((bankData, index) => (
        <Fragment key={bankData[0].bank_rate.bank_name}>
          <PDFSBPEBankLabel bankName={bankData[0].bank_rate.bank_name} />
          {bankData.map((bank, bIndex) => (
            <View
              key={`${bank.bank_rate}-${index}`}
              style={
                bIndex !== bankData.length - 1
                  ? styles.sbpeBankTableTypeFullContainerWithBorder
                  : styles.sbpeBankTableTypeFullContainerWithoutBorder
              }
            >
              <PDFSBPEUpperSimulatorInfo bank={bank} />
              <PDFSBPEBottomSimulatorInfo bank={bank} highlight={simulation.highlight} />
            </View>
          ))}
          {parsedBankData && index !== parsedBankData.length - 1 ? <Text break /> : null}
        </Fragment>
      ))}
    </>
  );
}
