import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { AnalysisResponseRaw } from "../types/AnalysisResponseRaw";

/** Find Analysis (Superadmin Endpoint) */
export const adminFindAnalysis = (
  analysisIdList: Array<string>,
  tenantCode: string
): AxiosPromise<Array<AnalysisResponseRaw>> => {
  return get(`/admin/analysis?analysis_id_list=${analysisIdList}&tenant_code=${tenantCode}`);
};
