import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { ObjectOf } from "../../../../../../common/types/ObjectOf";
import { kpiDictionary } from "../../../../../../productBuilder/utils/nodeContents";
import { AnalysisDetailsStep } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import { formatValueToCurrency, formatValueToPercentage } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFTrupstadLineSVG from "./StepSVG/PDFTrupstadLineSVG";
import PDFTrustpadDefaultSVG from "./StepSVG/PDFTrustpadDefaultSVG";

type PDFTrustpadPeerDefaultStepCardProps = {
  lastIndex?: boolean;
  lineColor: string;
  step: AnalysisDetailsStep;
};

const styles = StyleSheet.create({
  conditionValue: {
    alignSelf: "flex-end",
    color: "rgb(127, 135, 152)",
    fontSize: "10px",
  },
  flexColumnContainerJustify: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexRowContainerJustify: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  providerValue: {
    alignSelf: "flex-end",
    color: "rgb(33, 36, 42)",
    fontSize: "24px",
  },
  stepDate: {
    color: "rgb(127, 135, 152)",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  stepDescription: {
    color: "rgb(33, 36, 42)",
    fontSize: "12px",
  },
  trustpadDefaultStepDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
  },
  trustpadDefaultStepGrayContainer: {
    backgroundColor: "rgb(245, 248, 249)",
    borderRadius: "20px",
    margin: "0 auto",
    padding: "24px",
    width: "70%",
  },
  trustpadDefaultStepMainContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    marginVertical: "20px",
  },
});

const conditionDictionary: ObjectOf<string> = {
  "!=": "diferente de",
  "<": "menor que",
  "<=": "igual ou menor que",
  "=": "igual a",
  "==": "igual a",
  ">": "maior que",
  ">=": "igual ou maior que",
  in: "está contido em",
};

export default function PDFTrustpadPeerDefaultStepCard(props: PDFTrustpadPeerDefaultStepCardProps) {
  const { lastIndex, lineColor, step } = props;

  const formatRatingString = (valueString: string) => {
    return valueString
      .toUpperCase()
      .split(",")
      .map((char, index, self) => (index !== self.length - 1 ? `${char}, ` : char))
      .join("");
  };

  const validateCondition = (condition: string | undefined) => {
    if (!condition) return "Condição não encontrada";

    return condition;
  };

  const validateValue = (value: number | null | string) => {
    if (!value) return "";

    if (step.valueType === "rating") {
      return formatRatingString(value.toString());
    }

    return value;
  };

  const formatValueByType = () => {
    switch (step.valueType) {
      case "currency":
        return formatValueToCurrency(Number(step.providerValue), "BRL", true);
      case "decimal":
        return formatValueToPercentage(Number(step.providerValue));
      default:
        return step.providerValue;
    }
  };

  const formattedCondition = () => {
    if (!step.condition) {
      return "Condição não encontrada";
    }

    switch (step.valueType) {
      case "boolean":
        return null;
      case "currency":
        return `• ${conditionDictionary[step.condition]} ${formatValueToCurrency(Number(step.value), "BRL", true)}`;
      case "integer":
        return `• ${conditionDictionary[step.condition]} ${step.value}`;
      case "rating":
        return `• ${validateCondition(conditionDictionary[step.condition])} ${validateValue(step.value)}`;
      case "string":
        if (step.result) {
          return `• ${validateCondition(conditionDictionary[step.condition])} ${validateValue(step.value)}`;
        }
        return "Condição não encontrada";
      case "decimal": {
        return `• ${conditionDictionary[step.condition]} ${formatValueToPercentage(Number(step.value))}`;
      }
      default:
        return `• ${validateCondition(conditionDictionary[step.condition])} ${validateValue(step.value)}`;
    }
  };

  const formattedProviderValue = () => {
    switch (typeof step.providerValue) {
      case "boolean":
        return step.providerValue ? "Sim" : "Não";
      case "number":
        return formatValueByType();
      case "string":
        return step.providerValue;
      default:
        return "Não encontrado";
    }
  };

  return (
    <View minPresenceAhead={40} style={styles.trustpadDefaultStepMainContainer}>
      <View style={styles.genericFlexColumnContainer}>
        <PDFTrustpadDefaultSVG />
        {!lastIndex ? <PDFTrupstadLineSVG color={lineColor} /> : null}
      </View>
      <View style={styles.trustpadDefaultStepGrayContainer}>
        <View style={styles.flexRowContainerJustify}>
          <View style={styles.trustpadDefaultStepDescriptionContainer}>
            <Text style={styles.stepDate}>{format(new Date(step.matchAt * 1000), "MMMM dd yyyy HH:mm")}</Text>
            <Text style={styles.stepDescription}>
              {step.kpi === "highlight__has_approved_bank"
                ? step.description
                : kpiDictionary[step.kpi] || step.description}
            </Text>
          </View>
          <View style={styles.flexColumnContainerJustify}>
            <Text style={styles.providerValue}>{formattedProviderValue()}</Text>
            <Text style={styles.conditionValue}>{formattedCondition()}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
