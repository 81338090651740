import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ProviderData } from "../../../../../types/AnalysisDetails/Provider/ProviderData";
import PDFCPFRestrictionCard from "../PDFCPFRestrictionCard";

type PDFCPFSerasaRestrictionsProps = {
  serasaCompleto: ProviderData["serasa_completo"];
  serasaParcial: ProviderData["serasa_parcial"];
};

const styles = StyleSheet.create({
  blockedRestrictionText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "10px",
    paddingTop: "40px",
  },
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  restrictionText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
});

export default function PDFCPFSerasaRestrictions(props: PDFCPFSerasaRestrictionsProps) {
  const { serasaCompleto, serasaParcial } = props;

  return (
    <View minPresenceAhead={40}>
      {serasaParcial?.exec_error || serasaCompleto?.exec_error ? (
        <Text style={styles.blockedRestrictionText}>Restrições</Text>
      ) : (
        <>
          <Text style={styles.restrictionText}>Restrições</Text>
          <View style={styles.generalInfoCardContainer}>
            {serasaCompleto ? (
              <>
                <PDFCPFRestrictionCard title="Score de Crédito" value={serasaCompleto?.score} />
                <PDFCPFRestrictionCard title="Classificação de Crédito" value={serasaCompleto?.score_rule} />
              </>
            ) : null}
            {serasaParcial ? (
              <>
                <PDFCPFRestrictionCard title="Regularização Cadastral" value={serasaParcial?.cpf_status} />
                <PDFCPFRestrictionCard title="Possui restrições?" value={serasaParcial?.has_debts ? "Sim" : "Não"} />
              </>
            ) : null}
            {serasaCompleto ? (
              <PDFCPFRestrictionCard title="Probabilidade de Negativação" value={serasaCompleto?.delinquency_prob} />
            ) : null}
          </View>
        </>
      )}
    </View>
  );
}
