import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterFourteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        14. DISPOSIÇÕES GERAIS
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.1. Se uma ou mais disposições contidas nesta Política for considerada inválida, ilegal ou inexequível sob
        qualquer aspecto, a validade, legalidade ou exequibilidade das demais disposições contidas nesta Política não
        será, de forma alguma afetada e/ou prejudicada por esse fato. Nesse caso, a Liquid providenciará a substituição
        das disposições inválidas, ilegais ou inexequíveis, por disposições válidas.
      </LqdTypography>
    </>
  );
}
