import { ProviderResponse } from "../types/ProviderResponse";
import { ProviderResponseRaw } from "../types/ProviderResponseRaw";

export const formatRawProviderSuperAdmin = (providerRaw: ProviderResponseRaw): ProviderResponse => ({
  color: providerRaw.color,
  global: providerRaw.global,
  options: {
    inputData: providerRaw.options.input_data,
    kpis: providerRaw.options.kpis,
    providerName: providerRaw.options.provider_name,
    reference: providerRaw.options.reference,
  },
  provider: providerRaw.provider,
  providerId: providerRaw.provider_id,
  subtitle: providerRaw.subtitle,
  title: providerRaw.title,
});
