import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    marginRight: "18px",
    maxHeight: "40px",
    maxWidth: "40px",
  },
});

export default function PDFTrustpadMatchSVG() {
  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 45 45">
        <Path d="M32 18.5L21 29.5L16 24.5" stroke="rgb(41, 176, 160)" strokeLineCap="round" strokeWidth="1.2" />
        <Circle cx="23" cy="23" r="20" stroke="rgb(41, 176, 160)" />
      </Svg>
    </View>
  );
}
