import { post } from "../../common/utils/apiUtils";
import { CloudSigningProviders } from "../types/cloudProvidersSession";

export const performCloudSigning = async (session: string, peerToken: string): Promise<CloudSigningProviders> => {
  const response = await post<CloudSigningProviders>(
    "/sign/perform-cloud-signature",
    {
      session,
    },
    { headers: { "peer-token": peerToken } }
  );
  return response.data;
};
