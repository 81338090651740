import {
  LqdClockFilledIcon,
  LqdDeleteFilledIcon,
  LqdDoneFilledIcon,
  LqdRightIcon,
  LqdServerDatabaseIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Divider, Stack } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { useAppSelector } from "../../../store";
import { automationsModulesDictionary } from "../helpers/automationModulesDictionary";
import { Automation } from "../types/Automation";
import AutomationCardActionsList from "./AutomationCardActionsList";
import AutomationCardHeader from "./AutomationCardHeader";
import AutomationChip from "./AutomationChip";

type AutomationCardProps = {
  automation: Automation;
};

export default function AutomationCard(props: AutomationCardProps) {
  const { automation } = props;

  const { groupProducts, tenantProducts } = useAppSelector((state) => state.products);
  const { users } = useAppSelector((state) => state.users);

  // Renderiza o timestamp e o usuário da automação (criação/última alteração)
  const renderAutomationTimestampLabel = () => {
    if (automation.updated_at > automation.created_at) {
      return {
        time: `Updated ${formatDistanceToNowStrict(automation.updated_at * 1000)} ago`,
        user: `by ${users[automation.updated_by]?.name || "Usuário não encontrado"}`,
      };
    } else {
      return {
        time: `Created ${formatDistanceToNowStrict(automation.created_at * 1000)} ago`,
        user: `by ${users[automation.created_by]?.name || "Usuário não encontrado"}`,
      };
    }
  };

  const automationStatus = {
    error: {
      color: automation.is_active ? "rgba(177, 30, 84, 1)" : "rgba(151, 158, 172, 1)",
      icon: (
        <LqdDeleteFilledIcon
          color={automation.is_active ? "rgba(177, 30, 84, 1)" : "rgba(151, 158, 172, 1)"}
          size={20}
        />
      ),
      text: `Erro: ${automation.resumed_results.error}`,
    },
    processing: {
      color: automation.is_active ? "rgba(183, 112, 35, 1)" : "rgba(151, 158, 172, 1)",
      icon: (
        <LqdClockFilledIcon
          color={automation.is_active ? "rgba(183, 112, 35, 1)" : "rgba(151, 158, 172, 1)"}
          size={20}
        />
      ),
      text: `Processando: ${automation.resumed_results.processing}`,
    },
    success: {
      color: automation.is_active ? "rgba(52, 199, 89, 1)" : "rgba(151, 158, 172, 1)",
      icon: (
        <LqdDoneFilledIcon color={automation.is_active ? "rgba(52, 199, 89, 1)" : "rgba(151, 158, 172, 1)"} size={20} />
      ),
      text: `Sucesso: ${automation.resumed_results.success}`,
    },
  };

  const timestampLabel = renderAutomationTimestampLabel();
  const isDeactivatedAutomation = !automation.is_active || automation.is_blocked;
  const automationProduct = automation.product ? { ...groupProducts, ...tenantProducts }[automation.product.id] : null;

  return (
    <Box
      className="lqd-automation-card"
      sx={{
        backgroundColor: "rgba(249, 249, 250, 1)",
        borderRadius: "24px",
        boxSizing: "border-box",
        display: "flex",
        p: { md: 3, xs: 2.5 },
        width: "100%",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: !isDeactivatedAutomation ? "rgba(52, 199, 89, 1)" : "rgba(177, 177, 185, 1)",
          borderRadius: "50%",
          display: { md: "flex", xs: "none" },
          height: "40px",
          width: "40px",
        }}
      >
        <LqdServerDatabaseIcon color="rgba(255, 255, 255, 1)" />
      </Stack>
      <Box sx={{ flex: 1, flexBasis: 0, overflow: "hidden", pl: { md: 2, xs: 0 } }}>
        <AutomationCardHeader automation={automation} />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            overflowX: "auto",
            pb: 2,
            textWrap: "nowrap",
            width: "100%",
          }}
        >
          <Box sx={{ display: "inline-block" }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
              Gatilho
            </LqdTypography>
            <LqdTypography color="rgba(79, 85, 98, 1)" textstyle="c1Caption">
              {automationsModulesDictionary[automation.origin_module]}
            </LqdTypography>
          </Box>
          <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", mx: 2 }}>
            <LqdRightIcon />
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
              Conexão
            </LqdTypography>
            <LqdTypography color="rgba(79, 85, 98, 1)" textstyle="c1Caption">
              {automationProduct ? automationProduct.name : "Não definido"}
            </LqdTypography>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              mx: 2,
              transform: "rotate(0deg)",
            }}
          >
            <LqdRightIcon />
          </Box>
          <AutomationCardActionsList actions={automation.actions} />
        </Box>
        <Divider sx={{ borderColor: "rgba(140, 140, 140, 0.28)", mb: 2 }} />
        <Stack
          alignItems="flex-end"
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          spacing={2.5}
          sx={{ maxWidth: "100%" }}
        >
          <Box
            sx={{
              "::-webkit-scrollbar": { display: "none" },
              maxWidth: "100%",
              overflowX: "auto",
              textWrap: "nowrap",
            }}
          >
            <AutomationChip
              color={automationStatus["success"].color}
              icon={automationStatus["success"].icon}
              text={automationStatus["success"].text}
            />
            <AutomationChip
              color={automationStatus["error"].color}
              icon={automationStatus["error"].icon}
              text={automationStatus["error"].text}
            />
            {/* TODO: Aguardar WebSocket para implementar esse status (basta apenas descomentá-lo) */}
            {/* <AutomationChip
              color={automationStatus["processing"].color}
              icon={automationStatus["processing"].icon}
              text={automationStatus["processing"].text}
            /> */}
          </Box>
          <Box>
            <LqdTypography color="rgba(127, 135, 152, 1)" textAlign="right" textstyle="c1Caption">
              {timestampLabel.time}
            </LqdTypography>
            <LqdTypography color="rgba(127, 135, 152, 1)" textAlign="right" textstyle="c1Caption">
              {timestampLabel.user}
            </LqdTypography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
