import { LqdIconProps } from "../../types/IconProps";

export default function LqdAdminIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M19 20.5H5C3.895 20.5 3 19.605 3 18.5V9.5C3 8.395 3.895 7.5 5 7.5H19C20.105 7.5 21 8.395 21 9.5V18.5C21 19.605 20.105 20.5 19 20.5Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16.174 7.5V5.5C16.174 4.395 15.279 3.5 14.174 3.5H9.82605C8.72105 3.5 7.82605 4.395 7.82605 5.5V7.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3 9.5L9.351 13.691C9.678 13.907 10.061 14.022 10.453 14.022H13.547C13.939 14.022 14.322 13.907 14.649 13.691L21 9.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
