import {
  LqdDownArrowDashedIcon,
  LqdFunctionIcon,
  LqdFunctionTwoIcon,
  LqdTooltip,
  LqdTypography,
  LqdVariableIcon,
  LqdViewerIcon,
} from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import blockFunctionClick from "@simulatorBuilder/utils/blockFunctionClick";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import { simulatorBuilderChanged } from "../../simulatorBuilderSlice";
import { SimulatorFormStepResumeView } from "../../types/SimulatorFormStepResumeView";
import { SimulatorFunctionBlock } from "../../types/SimulatorFunctionBlock";
import { handleBlocksPosition } from "../../utils/handleBlocksPosition";
import { formatFunctionsForTooltip, getBlocksContent } from "../../utils/simulatorFunctionBlocksHelpers";
import SimulatorFormStepResumeCard from "./SimulatorFormStepResumeCard";
import SimulatorFormBlocksPositionSaveButton from "./SimulatorFunctionBlocksForm/SimulatorFormBlocksPositionSaveButton";
import SimulatorFunctionBlocksForm from "./SimulatorFunctionBlocksForm/SimulatorFunctionBlocksForm";
import SimulatorResultForm from "./SimulatorResultForm/SimulatorResultForm";
import SimulatorVariablesForm from "./SimulatorVariablesForm/SimulatorVariablesForm";

const emptyFunction = { function_name: "", index: 1, param_type: "", result: { r: "" }, type: "", variables: {} };

type SimulatorFormStepResumeProps = {
  blocks: Array<SimulatorFunctionBlock>;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
};

export default function SimulatorFormStepResume(props: SimulatorFormStepResumeProps) {
  const { blocks, setBlocks } = props;

  const dispatch = useAppDispatch();

  const { functionTemplates, initialSimulatorState, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;
  const simulatorInitialState = initialSimulatorState!;

  const [blockId, setBlockId] = useState<number | null>(null);
  const [blockName, setBlockName] = useState<string>("");
  const [currentFunctionBlocksView, setCurrentFunctionBlocksView] = useState<SimulatorFunctionBlocksViews>("blockName");
  const [disableTooltipOnHover, setDisableTooltipOnHover] = useState<boolean>(false);
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modalView, setModalView] = useState<SimulatorFormStepResumeView | null>(null);

  const functionsBlockEmpty = !blocks.length;
  const variablesEmpty = !simulator.variables.filter((variable) => variable.is_input).length;
  const dashboardEmpty = simulator.dashboard
    ? Object.entries(simulator.dashboard)
        .map(([, value]) => value)
        .every((key) => !key.length)
    : null;

  const resultComponentState = () => {
    switch (true) {
      case dashboardEmpty && !functionsBlockEmpty:
        return "active_empty";
      case !dashboardEmpty && !functionsBlockEmpty:
        return "active_filled";
      default:
        return "inactive";
    }
  };

  const getNewBlockFormTitle = () => {
    switch (true) {
      case !!blocks.find((block) => block.block_id === blockId):
        return blocks.find((block) => block.block_id === blockId)!.name;
      default:
        return "Novo Bloco";
    }
  };
  const newBlockFormTitle = getNewBlockFormTitle();

  const handleBlockFunctionClick = (block?: SimulatorFunctionBlock) => {
    blockFunctionClick(
      blocks,
      emptyFunction,
      setBlocks,
      setBlockId,
      setBlockName,
      setCurrentFunctionBlocksView,
      setModalView,
      setIsDuplicating,
      block
    );
  };

  const handleGoBack = {
    functions: () => {
      switch (currentFunctionBlocksView) {
        case "blockFunctions":
          dispatch(simulatorBuilderChanged({ key: "blocks", value: simulatorInitialState.blocks }));
          setBlocks(simulatorInitialState.blocks);
          setBlockName(simulatorInitialState.blocks.find((block) => block.block_id === blockId)?.name || "");
          setCurrentFunctionBlocksView("blockName");
          break;
        default:
          setBlockName("");
          setBlocks(simulatorInitialState.blocks);
          setModalView(null);
          setIsDuplicating(false);
          break;
      }
    },
    result: (saving: boolean) => {
      if (!saving) {
        dispatch(simulatorBuilderChanged({ key: "dashboard", value: simulatorInitialState.dashboard }));
      }
      setModalView(null);
    },
    variables: (saving: boolean) => {
      if (!saving) {
        dispatch(simulatorBuilderChanged({ key: "variables", value: simulatorInitialState.variables }));
      }
      setModalView(null);
    },
  };

  const getContent = () => {
    switch (modalView) {
      case SimulatorFormStepResumeView.VARIABLES:
        return {
          content: (
            <SimulatorVariablesForm
              blocks={blocks}
              onClose={(saving) => handleGoBack[SimulatorFormStepResumeView.VARIABLES](saving)}
            />
          ),
          handleGoBack: handleGoBack[SimulatorFormStepResumeView.VARIABLES],
          onClose: (saving: boolean) => handleGoBack[SimulatorFormStepResumeView.VARIABLES](saving),
          title: simulator.name,
        };
      case SimulatorFormStepResumeView.FUNCTIONS:
        return {
          content: (
            <SimulatorFunctionBlocksForm
              blockId={blockId!}
              blockName={blockName}
              blocks={blocks}
              currentView={currentFunctionBlocksView}
              isDuplicating={isDuplicating}
              onClose={(saving) => {
                if (!saving) {
                  dispatch(simulatorBuilderChanged({ key: "blocks", value: simulatorInitialState.blocks }));
                  setBlocks(simulatorInitialState.blocks);
                }
                setModalView(null);
              }}
              setBlockId={setBlockId}
              setBlockName={setBlockName}
              setBlocks={setBlocks}
              setCurrentView={setCurrentFunctionBlocksView}
              setIsDuplicating={setIsDuplicating}
            />
          ),
          handleGoBack: handleGoBack[SimulatorFormStepResumeView.FUNCTIONS],
          onClose: (saving: boolean) => {
            if (!saving) {
              dispatch(simulatorBuilderChanged({ key: "blocks", value: simulatorInitialState.blocks }));
              setBlocks(simulatorInitialState.blocks);
              setIsDuplicating(false);
            }
            setBlockId(null);
            setBlockName("");
            setCurrentFunctionBlocksView("blockName");
            setModalView(null);
          },
          title: isDuplicating ? "Duplicar Bloco" : newBlockFormTitle,
        };
      case SimulatorFormStepResumeView.RESULT:
        return {
          content: (
            <SimulatorResultForm
              blocks={blocks}
              onClose={(saving) => handleGoBack[SimulatorFormStepResumeView.RESULT](saving)}
            />
          ),
          handleGoBack: handleGoBack[SimulatorFormStepResumeView.RESULT],
          onClose: (saving: boolean) => handleGoBack[SimulatorFormStepResumeView.RESULT](saving),
          title: simulator.name,
        };
      default:
        return {
          content: null,
          handleGoBack: () => null,
          onClose: (saving: boolean) => saving,
          title: "",
        };
    }
  };

  const renderContent = getContent();
  const inputVariables = simulator.variables.filter((variable) => variable.is_input);

  const getVariablesContent = () => {
    return variablesEmpty ? (
      <LqdTypography color="rgba(156, 163, 175, 1)" sx={{ lineHeight: "16px" }} textstyle="p2Paragraph">
        Crie variáveis para input de dados do usuário
      </LqdTypography>
    ) : (
      <Stack direction="row" flexWrap="wrap" justifyContent="center" spacing={1} useFlexGap>
        {inputVariables.map((variable) => (
          <Box key={variable.name} sx={{ backgroundColor: "rgba(177, 227, 255, 1)", borderRadius: "100px", p: 1 }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="buttonXS">
              {variable.label}
            </LqdTypography>
          </Box>
        ))}
      </Stack>
    );
  };

  const changeBlocksPosition = (index: number, type: "down" | "up") => {
    setIsEditing(true);
    handleBlocksPosition(blocks, index, setBlocks, type);
  };

  return (
    <>
      <Stack alignItems="center" direction="column" gap={1.5} sx={{ mx: "auto", my: 2.5 }}>
        <SimulatorFormStepResumeCard
          buttonAction={() => setModalView(SimulatorFormStepResumeView.VARIABLES)}
          buttonTitle={variablesEmpty ? "Adicionar variáveis" : "Editar variáveis"}
          componentIcon={<LqdVariableIcon size={18} />}
          componentState={variablesEmpty ? "active_empty" : "active_filled"}
          componentSubTitle={getVariablesContent()}
          componentTitle="Variáveis de Entrada"
          setBlockId={setBlockId}
          setBlockName={setBlockName}
          setIsDuplicating={setIsDuplicating}
          setModalView={setModalView}
        />
        <LqdDownArrowDashedIcon color="rgba(127, 135, 152, 1)" size={28} />
        {/* BLOCOS DE FUNÇÕES */}
        {/* Faz um map dos blocos de funções existentes, e depois adiciona um empty block padrão. */}
        {blocks.map((block, index) => (
          <LqdTooltip
            followCursor={true}
            hidetooltip={
              block.functions.every((func) => func.function_name === "") || disableTooltipOnHover ? "true" : "false"
            }
            key={`${block.name}-${block.block_id}-${index}`}
            titlebody={formatFunctionsForTooltip(block, functionTemplates, simulator)}
          >
            <Stack alignItems="center">
              <SimulatorFormStepResumeCard
                block={block}
                blocks={blocks}
                buttonAction={() => handleBlockFunctionClick(block)}
                buttonTitle="Editar bloco"
                changeBlocksPosition={changeBlocksPosition}
                componentIcon={index === 0 ? <LqdFunctionTwoIcon size={18} /> : <LqdFunctionIcon size={18} />}
                componentState="active_filled"
                componentSubTitle={getBlocksContent(false, block, blocks)}
                componentTitle={block.name}
                index={index}
                setBlockId={setBlockId}
                setBlockName={setBlockName}
                setDisableTooltipOnHover={setDisableTooltipOnHover}
                setIsDuplicating={setIsDuplicating}
                setModalView={setModalView}
              />
            </Stack>
          </LqdTooltip>
        ))}
        {blocks.length > 1 ? (
          <SimulatorFormBlocksPositionSaveButton blocks={blocks} isEditing={isEditing} setIsEditing={setIsEditing} />
        ) : null}
        <SimulatorFormStepResumeCard
          buttonAction={() => handleBlockFunctionClick()}
          buttonTitle="Adicionar bloco"
          componentIcon={<LqdFunctionIcon size={18} />}
          componentState="active_empty"
          componentSubTitle={getBlocksContent(true, undefined, blocks)}
          componentTitle="Blocos de Funções"
          setBlockId={setBlockId}
          setBlockName={setBlockName}
          setIsDuplicating={setIsDuplicating}
          setModalView={setModalView}
        />
        <LqdDownArrowDashedIcon color="rgba(127, 135, 152, 1)" size={28} />
        {/* RESULTADOS */}
        <SimulatorFormStepResumeCard
          buttonAction={() => setModalView(SimulatorFormStepResumeView.RESULT)}
          buttonTitle={dashboardEmpty ? "Adicionar Resultado" : "Configurar Resultado"}
          componentIcon={<LqdViewerIcon size={18} />}
          componentState={resultComponentState()}
          componentSubTitle={
            <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
              Configure a visualização dos resultados
            </LqdTypography>
          }
          componentTitle="Resultado"
          setBlockId={setBlockId}
          setBlockName={setBlockName}
          setIsDuplicating={setIsDuplicating}
          setModalView={setModalView}
        />
      </Stack>
      <FullscreenDialog
        action="create"
        currentStep={2}
        handleGoBack={() => renderContent.handleGoBack(false)}
        onClose={(saving: boolean) => renderContent.onClose(saving)}
        open={modalView !== null}
        title={renderContent.title}
      >
        {renderContent.content}
      </FullscreenDialog>
    </>
  );
}
