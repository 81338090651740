import { Button, ButtonProps } from "@mui/material";

export type LqdButtonTypes =
  | "filledPrimary"
  | "filledSecundary"
  | "ghostPrimary"
  | "outlinePrimary"
  | "outlineSecundary"
  | "outlineTertiary";

type LqdButtonProps = {
  buttonsize?: "large" | "medium" | "small"; // Default: large
  type?: LqdButtonTypes; // Default: filledPrimary
};

const fontSize = {
  large: "16px",
  medium: "14px",
  small: "12px",
};

const lineHeight = {
  large: "24px",
  medium: "20px",
  small: "18px",
};

const padding = {
  large: "14px 20px",
  medium: "12px 18px",
  small: "8px 16px",
};

const textColor = {
  filledPrimary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 1)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  filledSecundary: {
    active: "rgba(155, 162, 175, 1)",
    default: "rgba(155, 162, 175, 1)",
    disabled: "rgba(155, 162, 175, 1)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(155, 162, 175, 1)",
  },
  ghostPrimary: {
    active: "rgba(127, 135, 152, 1)",
    default: "rgba(127, 135, 152, 1)",
    disabled: "rgba(255, 255, 255, 1)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
  outlinePrimary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(0, 0, 0, 1)",
    disabled: "rgba(0, 0, 0, 1)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  outlineSecundary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 1)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  outlineTertiary: {
    active: "rgba(127, 135, 152, 1)",
    default: "rgba(127, 135, 152, 1)",
    disabled: "rgba(127, 135, 152, 1)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
};

const backgroundColor = {
  filledPrimary: {
    active: "rgba(33, 36, 42, 1)",
    default: "rgba(33, 36, 42, 1)",
    disabled: "rgba(33, 36, 42, 1)",
    focus: "rgba(101, 110, 127, 1)",
    hover: "rgba(56, 61, 70, 1)",
  },
  filledSecundary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 1)",
    focus: "rgba(155, 162, 175, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  ghostPrimary: {
    active: "rgba(212, 215, 220, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(240, 241, 243, 1)",
    focus: "rgba(240, 241, 243, 1)",
    hover: "rgba(240, 241, 243, 1)",
  },
  outlinePrimary: {
    active: "rgba(33, 36, 42, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(33, 36, 42, 1)",
    hover: "rgba(33, 36, 42, 1)",
  },
  outlineSecundary: {
    active: "rgba(101, 110, 127, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
  outlineTertiary: {
    active: "rgba(212, 215, 220, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(240, 241, 243, 1)",
    focus: "rgba(240, 241, 243, 1)",
    hover: "rgba(240, 241, 243, 1)",
  },
};

const border = {
  filledPrimary: {
    active: "1px solid rgba(56, 61, 70, 1)",
    default: "1px solid transparent",
    disabled: "1px solid rgba(56, 61, 70, 1)",
    focus: "1px solid rgba(56, 61, 70, 1) ",
    hover: "1px solid rgba(56, 61, 70, 1)",
  },
  filledSecundary: {
    active: "1px solid rgba(255, 255, 255, 1)",
    default: "1px solid rgba(255, 255, 255, 1)",
    disabled: "1px solid rgba(212, 215, 220, 1)",
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(127, 135, 152, 1)",
  },
  ghostPrimary: {
    active: "1px solid rgba(0, 0, 0, 0)",
    default: "1px solid rgba(0, 0, 0, 0)",
    disabled: "1px solid rgba(0, 0, 0, 0)",
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(0, 0, 0, 0)",
  },
  outlinePrimary: {
    active: "1px solid rgba(255, 255, 255, 1)",
    default: "1px solid rgba(0, 0, 0, 1)",
    disabled: "1px solid rgba(0, 0, 0, 1)",
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(255, 255, 255, 1)",
  },
  outlineSecundary: {
    active: "1px solid rgba(255, 255, 255, 1)",
    default: "1px solid rgba(255, 255, 255, 1)",
    disabled: "1px solid rgba(255, 255, 255, 1)",
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(255, 255, 255, 1)",
  },
  outlineTertiary: {
    active: "1px solid rgba(0, 0, 0, 0)",
    default: "1px solid rgba(155, 162, 175, 1)",
    disabled: "1px solid rgba(0, 0, 0, 0)",
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(0, 0, 0, 0)",
  },
};

const focusOutline = {
  filledPrimary: { active: "6px solid rgba(191, 196, 205, 0.32)" },
  filledSecundary: { active: "6px solid rgba(127, 135, 152, 0.12)" },
  ghostPrimary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  outlinePrimary: { active: "6px solid rgba(127, 135, 152, 0.32)" },
  outlineSecundary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  outlineTertiary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
};

const disabledOpacity = {
  filledPrimary: 0.4,
  filledSecundary: 0.5,
  ghostPrimary: 0.5,
  outlinePrimary: 0.5,
  outlineSecundary: 0.5,
  outlineTertiary: 0.5,
};

/** Default styling
 * @param buttonsize large
 * @param type filledPrimary
 */
export default function LqdButton(props: LqdButtonProps & Omit<ButtonProps, "type" | "buttonSize">) {
  const { buttonsize, className, disabled, sx, type } = props;

  return (
    <Button
      {...props}
      className={`lqd-button-${className}`}
      disableElevation
      sx={{
        ":active": {
          background: backgroundColor[type || "filledPrimary"].active,
          border: border[type || "filledPrimary"].active,
          color: textColor[type || "filledPrimary"].active,
          outline: focusOutline[type || "filledPrimary"].active,
        },
        ":disabled": {
          background: backgroundColor[type || "filledPrimary"].disabled,
          border: border[type || "filledPrimary"].disabled,
          color: textColor[type || "filledPrimary"].disabled,
        },
        ":focus": {
          background: backgroundColor[type || "filledPrimary"].focus,
          border: border[type || "filledPrimary"].focus,
          color: textColor[type || "filledPrimary"].focus,
        },
        ":hover": {
          background: backgroundColor[type || "filledPrimary"].hover,
          border: border[type || "filledPrimary"].hover,
          color: textColor[type || "filledPrimary"].hover,
        },
        background: backgroundColor[type || "filledPrimary"].default,
        border: border[type || "filledPrimary"].default,
        borderRadius: "40px",
        color: textColor[type || "filledPrimary"].default,
        fontSize: fontSize[buttonsize || "large"],
        lineHeight: lineHeight[buttonsize || "large"],
        minHeight: 0,
        minWidth: "124px",
        opacity: disabled ? disabledOpacity[type || "filledPrimary"] : 1,
        padding: padding[buttonsize || "large"],
        ...sx,
      }}
      type={undefined}
    />
  );
}
