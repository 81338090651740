import { VariableInputProps } from "./SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableSelect from "./SimulatorFunctionBlocksFunctionVariableSelect";
import SimulatorFunctionBlocksFunctionVariableTextField from "./SimulatorFunctionBlocksFunctionVariableTextField";

export default function SimulatorFunctionBlocksFunctionVariableInput(props: VariableInputProps) {
  const { isResult } = props;

  if (!isResult) {
    return <SimulatorFunctionBlocksFunctionVariableSelect {...props} />;
  } else {
    return <SimulatorFunctionBlocksFunctionVariableTextField {...props} />;
  }
}
