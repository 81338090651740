import { LqdButton, LqdCircularLoader, LqdDialog, LqdDialogActions } from "@/liquid-components/src";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled } from "../../commonSlice";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";

/**
 * Componente de SmallDialog para uso geral.
 * @param actions Lista de ações do Modal.
 * @param body Texto principal do Modal.
 * @param title Título do Modal.
 */
export default function SmallDialogDefault() {
  const dispatch = useAppDispatch();

  const { dialogContent, dialogLoading, dialogShowState } = useAppSelector((state) => state.common);

  const handleCloseDialog = () => {
    dispatch(dialogCalled(null));
  };

  const handleConfirmClick = (action: SmallDialogAction) => {
    action.onClick ? action.onClick() : handleCloseDialog();
  };

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  return (
    <LqdDialog onClose={handleCloseDialog} open={dialogShowState}>
      <SmallDialogHeader body={dialogContent.body} title={dialogContent.title} />
      <LqdDialogActions sx={{ mt: 3.75 }}>
        {dialogContent.actions.map((action, index, actions) => {
          return (
            <LqdButton
              className="lqd-dialog-button"
              disabled={dialogLoading}
              key={action.title}
              onClick={() => handleConfirmClick(action)}
              type={getButtonType(index, actions)}
            >
              {dialogLoading && actions.length > 1 && index !== 0 ? (
                <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
              ) : (
                action.title
              )}
            </LqdButton>
          );
        })}
      </LqdDialogActions>
    </LqdDialog>
  );
}
