import { simulatorTesterFunctionClick } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import calculateNodePosition from "@simulatorBuilder/utils/calculateNodePosition";
import {
  createSimulatorTesterInitialEdges,
  createStyledEdgesAfterTestRun,
} from "@simulatorBuilder/utils/createSimulatorTesterEdges";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import ReactFlow, { Background, ControlButton, MiniMap, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import CustomNode from "./CustomNode/SimulatorTesterCustomNode";

type SimulatorTesterFlowDiagramProps = {
  hasRedirectedNode: boolean;
  setHasRedirectedNode: Dispatch<SetStateAction<boolean>>;
  simulator: Simulator;
};

const EMPTY_BLOCKS_NODE = [
  {
    data: { block: { block_id: 0, functions: [], name: "Não há blocos" } },
    id: "0",
    position: { x: 100, y: 200 },
    type: "customNode",
  },
];

/** Componente responsável por renderizar o flow do simulador. */
export default function SimulatorTesterFlowDiagram(props: SimulatorTesterFlowDiagramProps) {
  const { hasRedirectedNode, setHasRedirectedNode, simulator } = props;

  const dispatch = useAppDispatch();
  const { fitView, getNode, setViewport } = useReactFlow();

  const { selectedFunction, testerResult } = useAppSelector((state) => state.simulatorBuilder);

  const blocks = simulator.blocks;

  const initialEdges = testerResult
    ? createStyledEdgesAfterTestRun({ simulator, testerResult })
    : createSimulatorTesterInitialEdges(simulator);

  const [edges, setEdges] = useEdgesState(initialEdges);

  const initialNodes = useMemo(() => calculateNodePosition({ blocks: blocks, simulator }), [simulator]);
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);
  const [nodes, setNodes, onNodesChange] = useNodesState(blocks.length === 0 ? EMPTY_BLOCKS_NODE : initialNodes);

  useEffect(() => {
    if (testerResult && blocks.length > 0) {
      setEdges(createStyledEdgesAfterTestRun({ simulator, testerResult }));
    } else if (!testerResult) {
      setEdges(createSimulatorTesterInitialEdges(simulator));
    }
  }, [testerResult]);

  useEffect(() => {
    if (selectedFunction) {
      const targetNode = getNode(String(selectedFunction.blockId));
      if (targetNode) {
        fitView({ duration: 300, nodes: [targetNode] });
        setHasRedirectedNode(true);
      }
    }

    if (testerResult?.result?.error && !hasRedirectedNode) {
      const targetNode = getNode(String(testerResult.result.steps[testerResult.result.steps.length - 1]?.block_id));
      if (targetNode) {
        fitView({ duration: 300, nodes: [targetNode] });
        setHasRedirectedNode(true);
      }
    }

    setTimeout(() => {
      dispatch(simulatorTesterFunctionClick(null));
    }, 301);
  }, [getNode, hasRedirectedNode, selectedFunction, setViewport, testerResult]);

  useEffect(() => {
    if (blocks.length === 0) {
      setNodes(EMPTY_BLOCKS_NODE);
    } else {
      const updatedNodes = calculateNodePosition({ blocks, simulator });

      setNodes(updatedNodes);
    }
  }, [blocks]);

  return (
    <ReactFlow
      defaultViewport={{ x: 100, y: 200, zoom: 0.8 }}
      edges={edges}
      nodes={nodes}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
    >
      <MiniMap />
      <ControlButton />
      <Background color="rgba(207, 211, 218, 1)" gap={20} size={2} />
    </ReactFlow>
  );
}
