import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import { useAppSelector } from "../../../../../store";
import ConfigSidebar from "../../../../productBuilder/components/ConfigSidebar/ConfigSidebar";
import TemplateFormStep4AddNodeSidebar from "./TemplateBuilder/TemplateFormStep4AddNodeSidebar";
import TemplateFormStep4Flow from "./TemplateBuilder/TemplateFormStep4Flow";
import TemplateFormStep4Header from "./TemplateBuilder/TemplateFormStep4Header";

type TemplateFormStep4Props = {
  existingChanges?: boolean;
  isLoading: boolean;
  onGoBack: () => void;
  onSubmit: () => void;
  templateCountry: string;
  templateCurrency: string;
  templateSegments: Array<string>;
  templateTitle: string;
};

export default function TemplateFormStep4(props: TemplateFormStep4Props) {
  const {
    existingChanges,
    isLoading,
    onGoBack,
    onSubmit,
    templateCountry,
    templateCurrency,
    templateSegments,
    templateTitle,
  } = props;

  const { activeNode } = useAppSelector((state) => state.productBuilder);

  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <>
      <TemplateFormStep4Header
        existingChanges={existingChanges}
        isLoading={isLoading}
        onGoBack={onGoBack}
        onSubmit={onSubmit}
        templateCountry={templateCountry}
        templateCurrency={templateCurrency}
        templateSegments={templateSegments}
        templateTitle={templateTitle}
      />
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          boxSizing: "border-box",
          height: "89vh",
          position: "absolute",
          top: "96px",
          width: "98%",
        }}
      >
        <Grid columns={18} container sx={{ height: "100%" }}>
          {showSidebar ? (
            <Grid item sx={{ height: "100%" }} xs={4}>
              <Box sx={{ backgroundColor: "rgba(249, 249, 250, 1)", height: "100%" }}>
                <TemplateFormStep4AddNodeSidebar />
              </Box>
            </Grid>
          ) : null}
          <Grid item sx={{ height: "100%", pl: 3, position: "relative" }} xs>
            <Box
              onClick={() => setShowSidebar(!showSidebar)}
              sx={{
                "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                height: "100%",
                left: 0,
                position: "absolute",
                width: "24px",
                zIndex: 10,
              }}
            >
              {showSidebar ? (
                <ArrowLeft sx={{ color: "rgba(127, 135, 152, 1)" }} />
              ) : (
                <ArrowRight sx={{ color: "rgba(127, 135, 152, 1)" }} />
              )}
            </Box>
            <ReactFlowProvider>
              <TemplateFormStep4Flow />
              <ConfigSidebar key={String(activeNode?.id)} />
            </ReactFlowProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
