import {
  LqdButton,
  LqdCircularLoader,
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdSearch,
  LqdToggle,
  LqdTypography,
} from "@/liquid-components/src";
import { FullScreenDialogExtraButton } from "@common/components/FullscreenDialog";
import { normalizeText } from "@common/utils/normalizeText";
import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import { simulatorTesterSearchChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { handleSimulatorTesterSearchChanged } from "../../utils/simulatorTesterSearchChanged";

type SimulatorTesterDialogHeaderProps = {
  extraButtons?: Array<FullScreenDialogExtraButton>;
  goBack?: () => void;
  hashValue?: string;
  onAddInputsClick?: () => void;
  onClose: () => void;
  onEditClick?: () => void;
  onTabChange?: (tab: string) => void;
  showBackButton?: boolean;
  showEditButton?: boolean;
  showSkeleton?: boolean;
  simulator?: Simulator;
  tabValue?: string;
  testerResultFunction?: (value: boolean) => void;
  title: string;
};

/**
 * Componente para renderizar o cabeçalho do Dialogo do Tester. Ele exibe o título, barra de pesquisa e botões (adicionar inputs, editar inputs, toggle).
 * O botão toggle é exibido apenas se o resultado do testador estiver disponível e não houver erros.
 * A aba de resultados é desativada se o resultado do testador não estiver disponível.
 *
 * @param {SimulatorTesterDialogHeaderProps} props - As propriedades para o componente.
 */
export default function SimulatorTesterDialogHeader(props: SimulatorTesterDialogHeaderProps) {
  const { extraButtons, onAddInputsClick, onClose, onTabChange, simulator, tabValue, testerResultFunction, title } =
    props;

  const [inputText, setInputText] = useState<string>("");

  const dispatch = useAppDispatch();

  const { functionTemplates, isLoading, isResultActive, simulatorData, testerResult } = useAppSelector(
    (state) => state.simulatorBuilder
  );

  const handleDebouncedDispatch = useCallback(() => {
    const response = handleSimulatorTesterSearchChanged({
      functionTemplates,
      inputText: normalizeText(inputText),
      simulator: simulatorData!,
      type: "text",
    });

    dispatch(
      simulatorTesterSearchChanged({
        blocks: response.blocks,
        functions: response.functions,
        searchedText: response.searchedText,
        type: "text",
        variables: response.variables,
      })
    );
  }, [inputText, dispatch, simulator]);

  useEffect(() => {
    const handler = setTimeout(handleDebouncedDispatch, 300);

    return () => clearTimeout(handler);
  }, [inputText, handleDebouncedDispatch]);

  // Adiciona um efeito para monitorar o isResultActive e mudar a aba para "flow" se for desativado
  useEffect(() => {
    if (!isResultActive && tabValue === "results") {
      onTabChange?.("flow");
    }
  }, [isResultActive, onTabChange, tabValue]);

  const renderButtonContent = () => {
    if (isLoading) {
      return <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />;
    }
    return testerResult ? "Editar Inputs" : "Adicionar Inputs";
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderBottom: "0.5px solid rgba(127, 135, 152, 0.08)",
        display: "flex",
        height: "68px",
        justifyContent: "space-between",
        padding: "12px 24px",
      }}
    >
      <Stack alignItems={{ sm: "center", xs: "flex-start" }} direction={{ sm: "row", xs: "column" }}>
        <LqdIconButton onClick={onClose} round="true" type="outlinePrimary">
          <LqdLeftArrowIcon />
        </LqdIconButton>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ ml: 2, mt: { sm: 0, xs: 1.5 } }} textstyle="h4Headline">
          {title}
        </LqdTypography>
      </Stack>
      <Grid
        item
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: "center",
        }}
      >
        {extraButtons && extraButtons.length > 0 && extraButtons[0].type === "tab" ? (
          <Tabs
            onChange={(_, newValue) => (isResultActive || newValue !== "results" ? onTabChange!(newValue) : null)}
            sx={{
              ".MuiTabs-indicator": { top: { md: 0, xs: null } },
              mr: 2,
              mt: { sm: 0, xs: 2 },
              width: "100%",
            }}
            value={tabValue}
          >
            {extraButtons.map((button) => (
              <Tab
                key={button.title}
                label={button.title === "flow" ? "Visualizar Fluxo" : "Visualizar Resultados"}
                sx={{
                  "&.MuiButtonBase-root": {
                    fontSize: { sm: "16px", xs: "14px" },
                    lineHeight: "24px",
                    padding: "14px",
                  },
                  borderTop: tabValue === button.title.toLowerCase() ? "3px solid rgba(0, 0, 0, 1)" : null,
                  height: { md: "96px", xs: "auto" },
                  minWidth: "120px",
                  pointerEvents: button.title === "results" && !isResultActive ? "none" : "auto",
                  width: { sm: "auto", xs: `${100 / extraButtons.length}%` },
                }}
                value={button.title.toLowerCase()}
              />
            ))}
          </Tabs>
        ) : null}
      </Grid>
      {testerResult?.result && !testerResult.result.error ? (
        <Box sx={{ display: "flex" }}>
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mr: 1 }} textstyle="p2Paragraph">
            Utilizar Resultados
          </LqdTypography>
          <LqdToggle active={isResultActive} onChange={(value) => testerResultFunction?.(value)} />
        </Box>
      ) : null}
      <Stack alignItems="center" direction="row" spacing={2} sx={{ mr: 8 }}>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            height: "40px",
            width: { md: "258px", xs: "160px" },
          }}
        >
          <LqdSearch onChange={(e) => setInputText(e.target.value)} value={inputText} />
        </Box>
        <LqdButton
          buttonsize="medium"
          disabled={isLoading}
          onClick={onAddInputsClick}
          sx={{ height: "40px", width: "152px" }}
        >
          {renderButtonContent()}
        </LqdButton>
      </Stack>
    </Box>
  );
}
