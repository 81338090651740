import { LqdIconProps } from "../../types/IconProps";

export default function LqdEmailIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4.636 5.04498H19.363C20.267 5.04498 21 5.77798 21 6.68198V17.318C21 18.222 20.267 18.954 19.364 18.954H4.636C3.733 18.955 3 18.222 3 17.318V6.68198C3 5.77798 3.733 5.04498 4.636 5.04498Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3.1189 6.07497L10.8129 11.578C11.5079 12.075 12.4419 12.076 13.1379 11.58L20.8759 6.06097"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
