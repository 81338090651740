import { LqdButton, LqdCircularLoader, LqdInput, LqdTypography } from "@/liquid-components/src";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { Box, Stack } from "@mui/material";
import { cpf } from "cpf-cnpj-validator";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { onChangingView, onSavingCpf, onSearchCloudProvidersForCPF } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import RegistryNavigationBar from "./RegistryNavigationBar";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function DocumentSearch() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { token } = useAppSelector((state) => state.signature);

  const [cpfState, setCpfState] = useState<string>("");
  const [cpfError, setCpfError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const onInputChange = () => {
      const cpfIsValid = cpf.isValid(cpfState);
      setCpfError(!cpfIsValid);
      dispatch(onSavingCpf(cpfState));
    };
    onInputChange();
  }, [cpfState]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      const data = { cpf: cpfState, peerToken: token };
      await dispatch(onSearchCloudProvidersForCPF(data));
      dispatch(onChangingView(SignatureViews.SIGNING_PROCESS));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 1 de 2",
        }}
        previusPage={SignatureViews.START_SIGNING}
        step={SignatureViews.DOCUMENT_SEARCH}
      />

      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Confirme seus dados
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Preencha o campo abaixo para continuar:
        </LqdTypography>

        <Stack sx={{ alignItems: "center", display: "flex", flexDirection: "row", mb: 3, mt: 5 }}>
          <LqdTypography color="rgba(101, 110, 127, 1)">Qual o seu CPF?</LqdTypography>
        </Stack>
        <Stack>
          <LqdInput maskType="cpf" placeholderText="Digite aqui" setValue={setCpfState} value={cpfState} />
          {cpfError && cpfState.length > 0 ? (
            <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)", textAlign: "left" }} textstyle="p1Paragraph">
              Digite um CPF válido
            </LqdTypography>
          ) : null}
        </Stack>
        <LqdButton
          color="primary"
          disabled={cpfError || cpfState.length !== 11 || loading}
          endIcon={loading ? null : <RightArrow />}
          onClick={handleSearch}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          {loading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Avançar"}
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
