import { LqdIconProps } from "../../types/IconProps";

export default function LqdProviderIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1778 12.4842C18.4767 13.7831 18.4767 15.889 17.1778 17.1879C15.8789 18.4868 13.773 18.4868 12.4741 17.1879C11.1752 15.889 11.1752 13.7831 12.4741 12.4842C13.773 11.1854 15.8789 11.1854 17.1778 12.4842"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M20 20.01L17.18 17.19"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11 21.01H6C4.895 21.01 4 20.115 4 19.01V5.01001C4 3.90501 4.895 3.01001 6 3.01001H17C18.105 3.01001 19 3.90501 19 5.01001V9.01001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
