import { get } from "../../common/utils/apiUtils";
import { AnalysisReportDataRaw } from "../types/AnalysisReportDataRaw";

/** Endpoint RECURSIVO para buscar os dados financeiros/analíticos de um determinado tenant (dados de consumo no período de um mês).
 * @param intervalType Tipo de intervalo para filtrar os dados.
 * @param page Número da página para a consulta (usado internamente para recursão).
 * @param accumulatedData Dados acumulados de todas as páginas.
 * @returns Array de AnalysisReportDataRaw.
 * @remarks Endpoint ADMIN.
 */
export const getScheduledFinancialReportData = async (
  intervalType: string,
  page: number = 1,
  accumulatedData: Array<AnalysisReportDataRaw> = []
): Promise<{ data: Array<AnalysisReportDataRaw> }> => {
  const response = await get<{
    page: number;
    results: Array<AnalysisReportDataRaw>;
    total: number;
    total_pages: number;
  }>(`/analysis/report/client/fetch-scheduled?interval_type=${intervalType}&offset=${page}&limit=400`);

  const updatedData = [...accumulatedData, ...response.data.results];

  if (page < response.data.total_pages) {
    return getScheduledFinancialReportData(intervalType, page + 1, updatedData);
  }

  return { data: updatedData };
};
