import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Product } from "../../../../../products/types/Product";

type PDFProductDataProps = {
  product: Product;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  productContainer: {
    borderBottom: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "25px",
    paddingTop: "25px",
  },
  productCountry: {
    color: "rgb(33, 36, 42)",
    fontSize: "16px",
    paddingTop: "3px",
  },
  productHeader: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    marginBottom: "16px",
  },
  productLabel: {
    color: "rgb(127, 135, 152)",
    fontSize: "12px",
    marginTop: "7px",
  },
  productLabelContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "40px",
    paddingTop: "55px",
  },
  productName: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingTop: "5px",
  },
  productType: {
    border: "1px solid rgb(127, 135, 152)",
    borderRadius: "20px",
    color: "rgb(127, 135, 152)",
    fontSize: "12px",
    paddingBottom: "5px",
    paddingLeft: "6px",
    paddingRight: "5px",
    paddingTop: "6px",
    textAlign: "center",
  },
});

export default function PDFProductData(props: PDFProductDataProps) {
  const { product } = props;

  const getProductTypeWidth = (label: string) => {
    let size = 0;

    const splitLabel = label.split("");
    splitLabel.forEach(() => (size += 8));

    return `${size}px`;
  };

  return (
    <View minPresenceAhead={40} style={styles.productContainer}>
      <View style={styles.genericFlexColumnContainer}>
        <Text style={styles.productHeader}>Produto</Text>
        <Text style={{ ...styles.productType, width: getProductTypeWidth(product.segment) }}>{product.segment}</Text>
        <Text style={styles.productName}>{product.name}</Text>
      </View>
      <View style={styles.productLabelContainer}>
        <Text style={styles.productLabel}>Country / Currency</Text>
        <Text style={styles.productCountry}>
          {product.country} / {product.currency}
        </Text>
      </View>
    </View>
  );
}
