import { toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { createDuplicateSimulatorRequestData } from "@simulatorBuilder/utils/createDuplicateSimulatorRequestData";
import { onChangePanel } from "@superadmin/adminSlice";
import { AdminPagePanels } from "@superadmin/types/AdminPagePanels";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import {
  duplicateSimulator,
  onChangingCurrentView,
  onChangingIsEditSimulatorTenant,
  onCreateSimulator,
  onUpdateSimulator,
  simulatorBuilderChanged,
  simulatorBuilderModalClosed,
  simulatorBuilderModalOpened,
} from "../simulatorBuilderSlice";
import { SimulatorFormView } from "../types/SimulatorFormView";
import SimulatorFormStepName from "./SimulatorFormStepName";
import SimulatorFormStepResume from "./SimulatorFormStepResume/SimulatorFormStepResume";
import SimulatorFormStepTenant from "./SimulatorFormStepTenant";
import SimulatorTester from "./SimulatorTester/SimulatorTester";

/** Modal de criação/edição de simuladores. */
export default function SimulatorForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const params = useParams();
  const navigate = useNavigate();

  const { simulator } = useSimulatorBuilder();
  const { currentView, duplicating, initialSimulatorState, isEditSimulatorTenant } = useAppSelector(
    (state) => state.simulatorBuilder
  );
  const { tenants } = useAppSelector((state) => state.admin);

  const simulatorInitialState = initialSimulatorState!;

  const [blocks, setBlocks] = useState(simulator.blocks);
  const [loading, setLoading] = useState(false);
  const [renderSimulationTester, setRenderSimulationTester] = useState<boolean>(false);

  /** Cria ou edita um simulador.
   * @param editing Booleano que define se o simulador está sendo editado.
   */
  const handleCreateOrEditSimulator = async (editing: boolean = false, duplicating: boolean = false) => {
    setLoading(true);

    try {
      const simulatorTenant = tenants[simulator.tenant_code];
      const simulatorData = { ...simulator!, tenant_name: simulatorTenant.contact.name };

      if (editing && !duplicating) {
        const requestData = { blocks, simulator: simulatorData, simulatorId: simulatorData.simulator_id! };
        await dispatch(onUpdateSimulator(requestData)).unwrap();
      } else if (!editing && duplicating) {
        const requestData = createDuplicateSimulatorRequestData(simulatorData);
        const newSimulator = await dispatch(onCreateSimulator(requestData)).unwrap();

        dispatch(duplicateSimulator(false));
        dispatch(simulatorBuilderModalOpened(newSimulator.simulator_id));
        navigate(`/config/admin/${newSimulator.simulator_id}`);
      } else {
        await dispatch(onCreateSimulator(simulatorData));
      }
    } catch (error) {
      const { message } = handleLiquidErrors(error);

      const notification: ToastNotification = {
        message: `Existem variáveis com nome inválido: ${message.invalid_vars.join(",\n")}`,
        type: "error",
      };

      dispatch(toastCalled(notification));
    } finally {
      setLoading(false);
    }
  };

  /** Fecha o modal do simulator Form redirecionando para a tela de configuração de simuladores */
  /** Seta os estados da slice necessários ao estado default */
  const closeModal = () => {
    dispatch(duplicateSimulator(false));
    dispatch(simulatorBuilderModalClosed());
    navigate("/config/admin");
    dispatch(onChangePanel(AdminPagePanels.SIMULATOR_CONFIGURATOR));
    dispatch(onChangingIsEditSimulatorTenant(false));
    restartSimulatorCurrentViewDefault();
  };

  const restartSimulatorCurrentViewDefault = () => {
    dispatch(onChangingCurrentView(SimulatorFormView.NAME));
  };

  /** Avança o step na criação/edição de simulador. */
  const onChangeSimulatorCurrentView = async () => {
    switch (currentView) {
      case SimulatorFormView.NAME:
        dispatch(onChangingCurrentView(SimulatorFormView.TENANT));
        break;
      case SimulatorFormView.TENANT:
        if (!simulator?.simulator_id || duplicating) {
          await handleCreateOrEditSimulator(false, duplicating);

          if (duplicating) {
            const notification: ToastNotification = {
              message: "Simulador duplicado com sucesso.",
              type: "success",
            };

            dispatch(toastCalled(notification));
          }

          dispatch(onChangingCurrentView(SimulatorFormView.RESUME));
        } else {
          await handleCreateOrEditSimulator(true);
          isEditSimulatorTenant ? closeModal() : dispatch(onChangingCurrentView(SimulatorFormView.RESUME));
        }
        break;
    }
  };

  /** Volta o step na criação/edição do simulador. */
  const handleGoBack = () => {
    switch (currentView) {
      case SimulatorFormView.TENANT:
        dispatch(simulatorBuilderChanged({ key: "tenant_code", value: simulatorInitialState.tenant_code }));
        dispatch(simulatorBuilderChanged({ key: "tenant_name", value: simulatorInitialState.tenant_name }));
        dispatch(onChangingCurrentView(SimulatorFormView.NAME));
        break;
      case SimulatorFormView.RESUME:
        dispatch(simulatorBuilderChanged({ key: "blocks", value: simulatorInitialState.blocks }));
        dispatch(simulatorBuilderChanged({ key: "dashboard", value: simulatorInitialState.dashboard }));
        dispatch(simulatorBuilderChanged({ key: "variables", value: simulatorInitialState.variables }));
        setBlocks(simulatorInitialState.blocks);
        dispatch(onChangingCurrentView(SimulatorFormView.TENANT));
        break;
    }
  };

  /** Renderiza a tela a ser exibida. */
  const renderContent = () => {
    switch (currentView) {
      case SimulatorFormView.NAME:
        return <SimulatorFormStepName onConfirm={onChangeSimulatorCurrentView} />;
      case SimulatorFormView.TENANT:
        return (
          <SimulatorFormStepTenant
            editing={!!(!!simulator?.simulator_id && !duplicating)}
            loading={loading}
            onConfirm={onChangeSimulatorCurrentView}
          />
        );
      case SimulatorFormView.RESUME:
        return <SimulatorFormStepResume blocks={blocks} setBlocks={setBlocks} />;
    }
  };

  /** Volta para a tela inicial quando o modal fechar. */
  useEffect(() => {
    if (!simulator) {
      dispatch(onChangingCurrentView(SimulatorFormView.NAME));
    }

    setBlocks(simulator.blocks);
  }, [simulator]);

  useEffect(() => {
    if (params.simulatorId) dispatch(simulatorBuilderModalOpened(params.simulatorId));
  }, [params.simulatorId]);

  return (
    <>
      <FullscreenDialog
        action="create"
        currentStep={currentView === SimulatorFormView.NAME ? 1 : 2}
        extraButtons={
          currentView !== SimulatorFormView.NAME && currentView !== SimulatorFormView.TENANT
            ? [
                {
                  disabled: loading,
                  onClick: () => setRenderSimulationTester(true),
                  title: "Visualizar Fluxo",
                  type: "outlineTertiary",
                },
              ]
            : []
        }
        handleGoBack={handleGoBack}
        hashValue={simulator.simulator_id}
        onClose={closeModal}
        open={!!params.simulatorId || !!simulator}
        title={simulator.simulator_id ? simulator.name : "Novo Simulador"}
      >
        {renderContent()}
      </FullscreenDialog>
      {renderSimulationTester ? (
        <SimulatorTester
          renderSimulationTester={renderSimulationTester}
          setRenderSimulationTester={setRenderSimulationTester}
          simulator={simulator}
        />
      ) : null}
    </>
  );
}
