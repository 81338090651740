import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterOne() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        1. OBJETO
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        1.1. Estes Termos estabelecem as regras de contratação e/ou utilização, pelo Usuário, do software “Liquid”, de
        propriedade da Liquid (“Liquid”) para a realização de decisões e análises no âmbito do uso da infraestrutura.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        1.1.1. Quando o Usuário for uma pessoa jurídica, o uso do Liquid será realizado exclusivamente nos termos destes
        Termos e da proposta comercial encaminhada, ou acessível via website, pela Liquid ou parceiros e distribuidores
        e aceita pelo Usuário (“Proposta Comercial”). Se o Usuário não tiver aceitado a Proposta Comercial
        expressamente, mas começar a utilizar o Software, isso significará seu aceite.
      </LqdTypography>
      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        1.1.2. Quando o Usuário for pessoa física, o uso do Liquid será completamente regido por estes Termos, a partir
        do aceite dado no momento do Aceite.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        1.2. Quando o Usuário for pessoa física, o uso do Liquid será completamente regido por estes Termos, a partir do
        aceite dado no momento do Aceite.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) Coleta os dados inseridos pela própria interação com ou disponibilização do Usuário para o Liquid;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) Coleta dados em bureaus de crédito, SCR e demais entidades financeiras que disponibilizam dados;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        c) Padroniza os dados coletados;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        d) Analisa os dados coletados;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        e) Elabora relatórios a partir da análise feita;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        f) Disponibiliza os dados e as análises ao Usuário Decisor; e
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        g) Realiza outros serviços que estejam especificados na Proposta Comercial.
      </LqdTypography>
    </>
  );
}
