import { LqdIconProps } from "../../types/IconProps";

export default function LqdMedalIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2426 4.75736C18.5858 7.10051 18.5858 10.8995 16.2426 13.2426C13.8995 15.5858 10.1005 15.5858 7.75736 13.2426C5.41421 10.8995 5.41421 7.1005 7.75736 4.75736C10.1005 2.41421 13.8995 2.41421 16.2426 4.75736"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M8 13.463V21L12 19L16 21V13.463"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
