import { LqdButton, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import openFinanceValidate from "../../assets/openFinanceValidate.png";

type OpenFinanceRefusedStateProps = {
  onCancel: () => void;
  onStart: () => void;
  pluggyToken: null | string;
};

export default function OpenFinanceRefusedState(props: OpenFinanceRefusedStateProps) {
  const { onCancel, onStart, pluggyToken } = props;

  const { width } = useWindowSize();

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 2 } }}
    >
      <Box>
        <Box sx={{ mb: { sm: "30px", xs: "20px" } }}>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Conte com a segurança
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(101, 110, 127, 1)", display: "inline", ml: { lg: 1.5, xs: 1 } }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            Liquid
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
          >
            .
          </LqdTypography>
        </Box>
        <LqdTypography sx={{ mb: { sm: 5, xs: 2.5 } }} textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}>
          Oferecemos um ambiente seguro para validar sua identidade e compartilhar informações do seu perfil. Tem
          certeza que deseja desistir da validação?
        </LqdTypography>
        <Stack
          alignItems="flex-start"
          direction={{ sm: "row", xs: "column" }}
          spacing={{ sm: 2.5, xs: 2 }}
          sx={{ mb: { sm: 5, xs: 2.5 } }}
        >
          <LqdButton buttonsize={width === "xs" ? "medium" : "large"} onClick={onCancel}>
            Não realizar a validação
          </LqdButton>
          <LqdButton
            buttonsize={width === "xs" ? "medium" : "large"}
            disabled={!pluggyToken}
            onClick={onStart}
            type="outlinePrimary"
          >
            Continuar com a validação
          </LqdButton>
        </Stack>
        <LqdTypography sx={{ display: "inline", mr: 0.5 }} textstyle="c1Caption">
          Para mais detalhes, acesse os nossos
        </LqdTypography>
        <LqdLink
          href="/terms-and-conditions"
          sx={{ fontSize: "12px", textDecorationLine: "underline" }}
          target="_blank"
        >
          Termos e Condições de Uso
        </LqdLink>
        <LqdTypography sx={{ display: "inline", mb: 5 }} textstyle="c1Caption">
          .
        </LqdTypography>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openFinanceValidate} />
      </Box>
    </Stack>
  );
}
