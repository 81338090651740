import { get } from "../../common/utils/apiUtils";
import { AnalysisReportDataRaw } from "../types/AnalysisReportDataRaw";

/** Endpoint RECURSIVO para buscar os dados financeiros/analíticos de um determinado tenant.
 * @param startDate Data de início para filtrar os dados.
 * @param endDate Data de fim para filtrar os dados.
 * @param page Número da página para a consulta (usado internamente para recursão).
 * @param accumulatedData Dados acumulados de todas as páginas.
 * @returns Array completo de AnalysisReportDataRaw.
 * @remarks Endpoint ADMIN.
 */
export const getAnalysisFinancialReportData = async (
  startDate: string,
  endDate: string,
  page: number = 1,
  accumulatedData: Array<AnalysisReportDataRaw> = []
): Promise<{ data: Array<AnalysisReportDataRaw> }> => {
  const response = await get<{
    page: number;
    results: Array<AnalysisReportDataRaw>;
    total: number;
    total_pages: number;
  }>(`/analysis/report/client?start_date=${startDate}&end_date=${endDate}&offset=${page}&limit=500`);

  const updatedData = [...accumulatedData, ...response.data.results];

  if (page < response.data.total_pages) {
    return getAnalysisFinancialReportData(startDate, endDate, page + 1, updatedData);
  }

  return { data: updatedData };
};
