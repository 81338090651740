import { InputBase, InputBaseProps } from "@mui/material";

export default function AutomationTextField(props: InputBaseProps) {
  const { sx } = props;

  return (
    <InputBase
      {...props}
      sx={{
        ".MuiInput-input": { p: 0 },
        "::after": { display: "none" },
        "::before": { display: "none" },
        ":active": { boxShadow: "0px 0px 0px 4px rgba(235, 235, 245, 0.70)" },
        ":focus": { borderColor: "red" },
        ":hover": {
          borderColor: "rgba(107, 114, 128, 1)",
        },
        backgroundColor: "rgba(255, 255, 255, 1)",
        border: "1px solid rgba(229, 231, 235, 1)",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)",
        boxSizing: "border-box",
        color: "rgba(17, 24, 39, 1)",
        fontSize: "14px",
        height: "48px",
        px: 1.5,
        py: 0,
        ...sx,
      }}
    />
  );
}
