import { LqdDoneFilledIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function SignatureSuccess() {
  return (
    <>
      <SignatureHeader />
      <Box
        borderRadius="8px"
        display="flex"
        height="496px"
        margin="60px auto 0"
        padding="24px"
        sx={{
          alignItems: "left",
          flexDirection: "column",
          gap: "16px",
          justifyContent: "center",
          width: {
            sm: "400px",
            xs: "80%",
          },
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdDoneFilledIcon color="rgba(52, 199, 89, 1)" size={45} />
        </Stack>
        <LqdTypography sx={{ mt: 2, textAlign: "center" }} textAlign="center" textstyle="h3Headline">
          Assinatura realizada com sucesso!
        </LqdTypography>
        <LqdTypography sx={{ mt: 1, textAlign: "center" }}>
          Quando todos assinarem você receberá o documento finalizado no seu e-mail.
        </LqdTypography>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
