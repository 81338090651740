import { SmallDialogInput } from "@common/components/SmallDialog/SmallDialog";

/**
 * Formata um valor de string como moeda em Real Brasileiro (BRL).
 *
 * Esta função recebe uma string, remove todos os caracteres não numéricos,
 * converte para um valor numérico e formata como moeda no idioma "pt-BR".
 *
 * @param value - O valor em string a ser formatado como moeda.
 * @returns A string formatada como moeda em Real Brasileiro (BRL).
 */
export const formatCurrencyInput = (value: string) => {
  const numericValue = parseInt(value.replace(/\D/g, "") || "0", 10);
  return (numericValue / 100).toLocaleString("pt-BR", { currency: "BRL", style: "currency" });
};

/**
 * Formata uma string de data inserindo barras nas posições apropriadas.
 *
 * - Se o valor possui 3 ou 4 caracteres numéricos, é formatado como `DD/MM`.
 * - Se o valor possui 5 ou mais caracteres numéricos, é formatado como `DD/MM/AAAA`.
 *
 * Caracteres não numéricos são removidos antes da formatação.
 *
 * @param value - A string de data a ser formatada.
 * @returns A string de data formatada.
 */
export const formatDateInput = (value: string) => {
  const numericValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos
  let formattedValue = numericValue;

  if (numericValue.length >= 3 && numericValue.length <= 4) {
    formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`;
  }

  if (numericValue.length >= 5) {
    formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4, 8)}`;
  }

  return formattedValue;
};

/**
 * Formata um valor de string como porcentagem.
 *
 * Esta função remove todos os caracteres não numéricos, converte o valor
 * resultante para um número inteiro e adiciona o sinal de porcentagem ("%").
 *
 * @param value - A string a ser formatada como porcentagem.
 * @returns A string formatada como porcentagem.
 */
export const formatPercentageInput = (value: string) => {
  const numericValue = parseInt(value.replace(/\D/g, "") || "0", 10);
  return `${numericValue}%`;
};

/**
 * Transforma um array de valores de entrada, removendo formatações
 * de tipos como moeda e porcentagem.
 *
 * @param inputValues - Um array de valores de entrada a serem transformados.
 * @returns Um novo array de valores de entrada com moedas e porcentagens sem formatação.
 *
 * @remarks
 * - Para entradas do tipo "currency", o valor é convertido para um inteiro, dividido por 100 e convertido para string.
 * - Para entradas do tipo "percentage", o valor é convertido para um inteiro, dividido por 100 e convertido para string.
 * - Outros tipos de entrada permanecem inalterados.
 */
export const getUnformattedValues = (inputValues: Array<SmallDialogInput>) =>
  inputValues.map((input) => {
    if (input.type === "currency") {
      return { ...input, value: (parseInt(input.value.replace(/[^\d]/g, ""), 10) / 100).toString() };
    }
    if (input.type === "percentage") {
      return { ...input, value: (parseInt(input.value.replace(/[^\d]/g, ""), 10) / 100).toString() };
    }
    return input;
  });

/**
 * Processa o valor de entrada com base no tipo especificado.
 *
 * @param type - O tipo do valor de entrada. Pode ser "currency", "date", "integer" ou "percentage".
 * @param value - O valor de entrada a ser processado.
 * @returns O valor processado com base no tipo especificado.
 */
export const processInputValue = (type: string, value: string) => {
  switch (type) {
    case "currency":
      return formatCurrencyInput(value);
    case "date":
      return formatDateInput(value);
    case "integer":
      return value.replace(/\D/g, "");
    case "percentage":
      return formatPercentageInput(value);
    default:
      return value;
  }
};
