import { Box, InputBase } from "@mui/material";
import { useState } from "react";
import { LqdCloseIcon, LqdSearchIcon } from "../../icons";
import LqdIconButton from "../IconButton/IconButton";

type LqdSearchProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
};

export default function LqdSearch(props: LqdSearchProps) {
  const { onChange, placeholder, value } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleClear = () => {
    const clearEvent = { target: { value: "" } } as React.ChangeEvent<HTMLInputElement>;
    onChange(clearEvent);
  };

  return (
    <Box
      sx={{
        "&:focus-within": { border: "1px solid rgba(127, 135, 152, 0.2)" },
        "&:hover": { backgroundColor: isFocused ? "rgba(249, 249, 250, 1)" : "rgba(236, 238, 240, 1)" },
        alignItems: "center",
        backgroundColor: isFocused ? "rgba(249, 249, 250, 1)" : "rgba(249, 249, 250, 1)",
        border: isFocused ? "1px solid rgba(127, 135, 152, 0.2)" : "none",
        borderRadius: "28px",
        display: "flex",
        padding: "0 15px",
        transition: "border 0.3s ease-in-out, background-color 0.3s ease-in-out",
        width: "100%",
      }}
    >
      <LqdSearchIcon color="rgba(127, 135, 152, 1)" />
      <InputBase
        onBlur={() => setIsFocused(false)}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        placeholder={placeholder || "Busque aqui"}
        sx={{
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(127, 135, 152, 1)",
            fontSize: "14px",
            fontWeight: 400,
            letterSpacing: "0px",
            lineHeight: "21px",
            opacity: 1,
          },
          flex: 1,
          ml: 1.5,
          p: "10px 0",
        }}
        value={value}
      />
      {value ? (
        <LqdIconButton buttonsize="small" onClick={handleClear} type="ghostIcon">
          <LqdCloseIcon color="rgba(127, 135, 152, 1)" />
        </LqdIconButton>
      ) : null}
      {/* Por enquanto o ícone não tem função nenhuma então ficará oculto */}
      {/* <LqdEllipsisIcon color="rgba(127, 135, 152, 1)" /> */}
    </Box>
  );
}
