import { LqdCheckboxOffIcon, LqdCheckboxOnIcon } from "@/liquid-components/src";
import { Box, Checkbox } from "@mui/material";
import { ProductResponse } from "../../types/ProductResponse";
import SuperRowInfo from "../SuperRowInfo";

type ProductDeletionProductCardProps = {
  onChangeMethod: (productId: string) => void;
  product: ProductResponse;
  selectedProducts: Array<string>;
};

export default function ProductDeletionProductCard(props: ProductDeletionProductCardProps) {
  const { onChangeMethod, product, selectedProducts } = props;

  const isChecked = selectedProducts.includes(product.productId);

  return (
    <Box
      sx={{
        "&:last-child": { borderBottom: "none" },
        alignItems: "center",
        backgroundColor: isChecked ? "rgba(127, 135, 152, 0.08)" : "transparent",
        borderBottom: "1px solid rgba(212, 215, 220, 1)",
        display: "flex",
        flexDirection: "row",
        height: "90px",
      }}
    >
      <Checkbox
        checked={isChecked}
        checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={18} />}
        icon={<LqdCheckboxOffIcon color="rgba(33, 36, 42, 1)" size={18} />}
        name={product.productId}
        onChange={(event) => onChangeMethod(event.target.name)}
      />
      <SuperRowInfo data={product} hideDate={true} />
    </Box>
  );
}
