import jwtDecode from "jwt-decode";

export type TokenData = {
  exp: number;
  sub: { tenant_code: string; user_groups: string; user_role: string; username: string };
};

const useAuth = () => ({
  accessToken: localStorage.getItem("access_token"),
  refreshToken: localStorage.getItem("refresh_token"),
  refreshTokenIsExpired: () => {
    const refreshToken = localStorage.getItem("refresh_token");
    const tokenData = jwtDecode<TokenData>(refreshToken!);
    const refreshTokenExpireDate = new Date(tokenData.exp * 1000);
    const now = new Date();
    return now.getTime() > refreshTokenExpireDate.getTime();
  },
  setAccessToken: (accessToken: string) => {
    localStorage.setItem("access_token", accessToken);
  },
  signIn: (accessToken: string, refreshToken: string) => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
  },
  signOut: (shouldRerender: boolean = true) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    if (shouldRerender) window.location.href = "/signin";
  },
});

export default useAuth;
