export enum SignatureViews {
  CLOUD_SIGNING = "cloudSigning",
  CREATE_CERTIFICATE = "createCertificate",
  DOCUMENT_SEARCH = "documentSearch",
  DOCUMENT_VIEW = "documentView",
  EMAIL_SEARCH = "emailSearch",
  EXPIRED_LINK = "expiredLink",
  LOADING = "loading",
  LOCAL_SIGNING = "localSigning",
  NOT_SCHEDULED = "notScheduled",
  SCHEDULED = "scheduled",
  SIGNATURE_FAILED = "signatureFailed",
  SIGNATURE_PENDENCIES = "signaturePendencies",
  SIGNATURE_SUCCESS = "signatureSuccess",
  SIGNING_PROCESS = "siningProcess",
  SOMETHING_WENT_WRONG = "somethingWentWrong",
  START_SIGNING = "startSigning",
  WITHOUT_CLOUD_SIGNING = "withoutCloudSigning",
}
