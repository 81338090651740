import { SmallDialogItem } from "@common/components/SmallDialog/SmallDialog";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectOf } from "../common/types/ObjectOf";
import { User } from "../users/types/User";
import UserRaw from "../users/types/UserRaw";
import createGroup from "./api/CreateGroup";
import getGroups from "./api/GetGroups";
import getGroupsUser from "./api/GetGroupsUser";
import updateGroup from "./api/UpdateGroup";
import updateProductGroupsRequest from "./api/updateProductGroups";
import Group from "./types/Group";
import GroupRaw from "./types/GroupRaw";
import { getGroup } from "./utils/getGroup";
import { getGroupRaw } from "./utils/getGroupRaw";

interface GroupsState {
  checkedItems: Array<string>;
  createGroup: boolean;
  createGroupCurrentStep: number;
  editGroupCurrentStep: number;
  groupBeingEdited: Group | null;
  groups: ObjectOf<Group>;
  groupsToRender: Array<SmallDialogItem> | undefined;
  loggedUserGroups: ObjectOf<Group>;
}

const initialState: GroupsState = {
  checkedItems: [],
  createGroup: false,
  createGroupCurrentStep: 1,
  editGroupCurrentStep: 1,
  groupBeingEdited: null,
  groups: {},
  groupsToRender: undefined,
  loggedUserGroups: {},
};

export const onLoadGroups = createAsyncThunk("groups/onLoadGroups", async (_, thunk) => {
  try {
    const groupsRes = await getGroups();
    const rawGroups = groupsRes.data;
    const groups = rawGroups.map(getGroup);

    const thunkState = thunk.getState() as { users: { currentUser: User; users: ObjectOf<User> } };
    const usersState = thunkState.users;
    const users = usersState.users;
    const currentUserRole = usersState.currentUser?.userRole || "";

    let filteredGroups = groups;

    if (currentUserRole !== "super") {
      filteredGroups = groups.map((group) => {
        const filteredUsers = group.users.filter(
          (userEmail) => users[userEmail] && users[userEmail].userRole !== "super"
        );
        return { ...group, users: filteredUsers };
      });
    }

    return filteredGroups;
  } catch (error) {
    const errorData = error as AxiosError;
    const status = errorData.response?.status;

    if (status === 401) {
      return null;
    }
  }
});

export const onCreateGroup = createAsyncThunk("groups/onCreateGroup", async (groupRaw: GroupRaw) => {
  const createGroupReq = await createGroup(groupRaw);
  return createGroupReq.data;
});

export const onLoadLoggedUserGroups = createAsyncThunk("groups/onLoadLoggedUserGroups", async () => {
  const groupsRes = await getGroupsUser();
  const rawGroups = groupsRes.data as Array<GroupRaw>;
  const loggedUserGroups = rawGroups.map(getGroup);
  return loggedUserGroups;
});

const onUpdateGroup = createAsyncThunk("groups/onUpdateGroup", async (group: Group) => {
  const groupRaw = getGroupRaw(group);
  const updateGroupRes = await updateGroup(groupRaw);
  const updatedGroupRaw = updateGroupRes.data;
  const updatedGroup = getGroup(updatedGroupRaw);
  return updatedGroup;
});

export const onUpdateProductGroups = createAsyncThunk(
  "groups/onUpdateProductGroups",
  async ({ groupsArray, productId }: { groupsArray: Array<string>; productId: string }) => {
    await updateProductGroupsRequest(productId, groupsArray);
    return { groupsArray, productId };
  }
);

const groupsSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadGroups.fulfilled, (state, action) => {
        const groups = action.payload!;

        state.groups = {};

        groups.forEach((group) => {
          state.groups[group.id] = group;
        });
      })
      .addCase(onUpdateGroup.fulfilled, (state, action) => {
        const group = action.payload!;

        state.groups[group.id] = group;
      })
      .addCase(onLoadLoggedUserGroups.fulfilled, (state, action) => {
        const groups = action.payload!;

        state.loggedUserGroups = {};

        groups.forEach((group) => {
          state.loggedUserGroups[group.id] = group;
        });
      })
      .addCase(onCreateGroup.fulfilled, (state) => {
        state.createGroupCurrentStep = 1;
        state.createGroup = false;
      })
      .addCase(onUpdateProductGroups.fulfilled, (state, action) => {
        const { groupsArray, productId } = action.payload;

        Object.values(state.groups)
          .filter((group) => {
            return !groupsArray.includes(group.id);
          })
          .forEach((group) => {
            const productIndex = group.products.indexOf(productId);
            if (productIndex !== -1) {
              group.products.splice(productIndex, 1);
            }
          });

        groupsArray.forEach((currentGroup) => {
          if (!state.groups[currentGroup].products.includes(productId)) {
            state.groups[currentGroup].products.push(productId);
          }
        });
      }),
  initialState,
  name: "groups",
  reducers: {
    createGroupModalClosed(state) {
      state.createGroup = false;
      state.groupBeingEdited = null;
      state.editGroupCurrentStep = 1;
    },
    createGroupModalOpened(state) {
      state.createGroup = true;
      state.createGroupCurrentStep = 1;
    },
    createGroupModalStepNext(state) {
      state.createGroupCurrentStep += 1;
    },
    createGroupModalStepPrev(state) {
      if (state.createGroupCurrentStep > 1) {
        state.createGroupCurrentStep -= 1;
      }
    },
    editGroupFormStepChangedTo(state, action: PayloadAction<number>) {
      state.editGroupCurrentStep = action.payload;
    },
    editGroupModalClosed(state) {
      state.groupBeingEdited = null;
      state.editGroupCurrentStep = 1;
    },
    editGroupModalOpened(state, action: PayloadAction<{ group: Group; step: number }>) {
      const { group, step } = action.payload;
      state.groupBeingEdited = group;
      state.editGroupCurrentStep = step;
    },
    updateCheckedItems(state, action: PayloadAction<Array<string>>) {
      state.checkedItems = action.payload;
    },
    updateGroupsOnUserCreated(state, action: PayloadAction<UserRaw>) {
      const user = action.payload;

      const userEmail = user.username;
      const groupIds = user.groups;

      groupIds.forEach((groupId) => {
        const group = state.groups[groupId];
        if (group) {
          group.users.push(userEmail);
        }
      });
    },
    updateGroupsToRender(state, action: PayloadAction<Array<SmallDialogItem> | undefined>) {
      state.groupsToRender = action.payload;
    },
  },
});

export const {
  createGroupModalClosed,
  createGroupModalOpened,
  createGroupModalStepNext,
  createGroupModalStepPrev,
  editGroupFormStepChangedTo,
  editGroupModalClosed,
  editGroupModalOpened,
  updateCheckedItems,
  updateGroupsOnUserCreated,
  updateGroupsToRender,
} = groupsSlice.actions;

export default groupsSlice.reducer;
