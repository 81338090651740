import {
  formatValueToCurrency,
  formatValueToPercentage,
  getDateFormat,
} from "@analysis/utils/analysisDetailsDataFormatter";
import { highlightText } from "@common/utils/highlightText";
import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";

type renderVariableValueProps = {
  result?: string;
  searchedText: string;
  simulator: Simulator;
  testerResult: SimulatorTesterResultDBResponse;
  type: string;
  varValue?: number | string;
};

export const renderVariableValue = (props: renderVariableValueProps) => {
  const { result, searchedText, simulator, testerResult, type, varValue } = props;

  const variableValue =
    testerResult.result?.results[
      type === "result" && result
        ? (result as keyof typeof testerResult.result.results)
        : (varValue as keyof typeof testerResult.result.results)
    ]?.toString() || "";

  let variableKey = "";
  if (type === "result" && result) {
    variableKey = result && testerResult.result?.results ? (result as keyof typeof testerResult.result.results) : "";
  } else {
    variableKey =
      varValue && testerResult.result?.results ? (varValue as keyof typeof testerResult.result.results) : "";
  }

  const checkAndConvertValue = (value: string) => {
    const isDate = simulator.variables.find((vari) => vari.name === variableKey)?.type === "date";
    const isPercentage = simulator.variables.find((vari) => vari.name === variableKey)?.type === "percentage";
    const isCurrency = simulator.variables.find((vari) => vari.name === variableKey)?.type === "currency";

    if (isDate) return getDateFormat(Number(value));

    if (isPercentage) return formatValueToPercentage(Number(value));

    if (isCurrency) return formatValueToCurrency(Number(value), "BRL", true);

    return value;
  };

  return highlightText(checkAndConvertValue(variableValue), searchedText, "12px");
};
