import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterFour() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        4. DISPONIBILIDADE DO LIQUID
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.1. A Liquid se compromete a envidar seus maiores esforços para manter o Liquid em pleno funcionamento. No
        entanto, o Usuário está ciente de que o Liquid é um software e que, portanto, está sujeito a funcionamento
        inesperado e/ou necessidade de manutenções corretivas ou preventivas, situações em que o Liquid pode apresentar
        lentidão ou interrupção temporária, sem que tal hipótese seja considerada inadimplemento contratual pela Liquid.
        A Liquid não poderá responder por quaisquer perdas e danos, de qualquer natureza, decorrentes de uma
        indisponibilidade do Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        4.1.1. A Liquid tentará, sempre que possível, informar ao Usuário sobre a realização de manutenções periódicas,
        preferencialmente, com 1 (um) dia útil de antecedência; no entanto, manutenções corretivas, por sua própria
        natureza, poderão ocorrer a qualquer tempo, sem necessidade de prévia comunicação e sem que isso sujeite a
        Liquid a qualquer penalização ou indenização.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        4.1.2. Ainda, o Liquid poderá ficar indisponível nas seguintes hipóteses, sem que isso configure inadimplemento
        da Liquid: (i) interrupção no fornecimento de energia elétrica para o sistema da Liquid; (ii) desligamento ou
        interrupção temporária do sistema, decorrente de reparos ou manutenção das redes elétrica e telefônica externas;
        e/ou (iii) incompatibilidade dos sistemas e dispositivos do Usuário com os sistemas da Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.2. Em caso de falta de operação, lentidão, inatividade ou qualquer outra intercorrência, o Usuário deverá
        entrar em contato, imediatamente, com a Liquid, requerendo a verificação do problema identificado.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        4.2.1. A Liquid atenderá ao chamado assim que possível. A depender da gravidade do problema, poderá ser
        necessário um prazo maior para a sua resolução.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        4.2.2. O período de atendimento será sempre apenas em dias úteis na capital do Estado de São Paulo, das 9h00 às
        18h00 (hora de Brasília), via on-line.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        4.3. A Liquid não poderá ser responsabilizada por intercorrências no Liquid causadas, direta ou indiretamente,
        pelo Usuário, em especial, mas não se limitando a ausência de sinal de internet do Usuário ou uso do Liquid pelo
        Usuário ou terceiros em desacordo com a lei ou com estes Termos.
      </LqdTypography>
    </>
  );
}
