import { Box, Stack } from "@mui/material";
import { SimulatorVariableTemplateType } from "@simulatorBuilder/types/SimulatorVariableTypeTemplate";
import { SimulatorVariableTypes } from "@simulatorBuilder/types/SimulatorVariableTypes";
import { LayoutProps } from "../SimulatorFunctionBlocksFunctionInputsRenderer";
import SimulatorFunctionBlocksFunctionVariableInput from "../SimulatorFunctionBlocksFunctionVariableInput";

export default function SimulatorFunctionBlocksFunctionCompareInputLayout(props: LayoutProps) {
  const {
    currentBlock,
    editVariableButton,
    func,
    newBlockButton,
    newVariableButton,
    onBlurFunction,
    onUpdateFunction,
    openSelectComponent,
    renderOptions,
    resultInputs,
    variableInputs,
  } = props;

  const desiredOrder = ["a", "condition", "b"];

  const orderedVariableInputs = Object.entries(variableInputs).sort(
    ([keyA], [keyB]) => desiredOrder.indexOf(keyA) - desiredOrder.indexOf(keyB)
  );

  /** Renderiza as opções de comparadores de acordo com a função
   * @param functionName O nome da função em criação/edição
   * @param renderOptions Todas as opções de renderizações disponíveis para a função em questão.
   * @return Opções filtradas de comparadores para que fiquem específicas com as necessidades da função.
   */
  const renderComparatorOptions = (
    functionName: string,
    renderOptions: Array<{
      id: string;
      label: string;
      type: SimulatorVariableTemplateType | SimulatorVariableTypes;
      value: number | string;
    }>
  ) => {
    const booleanComparatorOptions = ["E", "Ou"];
    const filteredRenderOptions = renderOptions
      .filter((option) => booleanComparatorOptions.includes(option.label))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

    switch (functionName) {
      case "compare_bool":
        return filteredRenderOptions;
      default:
        return renderOptions;
    }
  };

  /** Renderiza as opções de variáveis para o usuário de acordo com a função
   * @param functionName O nome da função em criação/edição
   * @param renderOptions Todas as opções de renderizações disponíveis para a função em questão.
   * @return Opções filtradas para que fiquem específicas com as necessidades da função.
   * @remarks Função criada para garantir a escalabilidade caso necessária futuras implementações
   */
  const renderVariablesOptions = (
    renderOptions: Array<{
      id: string;
      label: string;
      type: SimulatorVariableTemplateType | SimulatorVariableTypes;
      value: number | string;
    }>
  ) => {
    return renderOptions;
  };

  /** Renderiza as options referentes ao select do componente SimulatorFunctionBlocksFunctionVariableInput para o usuário de acordo com a função
   * @param functionName O nome da função em criação/edição
   * @param renderOptions Todas as opções de renderizações disponíveis para a função em questão.
   * @return Opções filtradas para que fiquem específicas com as necessidades da função.
   */
  const renderFunctionOptions = (
    optionType: string,
    functionName: string,
    renderOptions: Array<{
      id: string;
      label: string;
      type: SimulatorVariableTemplateType | SimulatorVariableTypes;
      value: number | string;
    }>
  ) => {
    switch (optionType) {
      case "comparators":
        return renderComparatorOptions(functionName, renderOptions);
      case "math_comparators":
      case "types":
        return renderOptions;
      case "variables":
      default:
        return renderVariablesOptions(renderOptions);
    }
  };

  return (
    <Stack spacing={1} sx={{ ml: "80px", mt: 1.5 }}>
      <Box sx={{ display: "flex", gap: 5, width: "100%" }}>
        {orderedVariableInputs.slice(0, 2).map(([key, variable]) => (
          <Box key={key} sx={{ flex: key === "a" ? 3 : 2 }}>
            <SimulatorFunctionBlocksFunctionVariableInput
              currentBlock={currentBlock}
              editVariable={editVariableButton}
              func={func}
              isOpen={openSelectComponent}
              isResult={false}
              key={key}
              newOption={variable.select === "variables" ? newVariableButton : null}
              onChange={(value) => onUpdateFunction(key, value as string)}
              options={renderFunctionOptions(variable.select, func.function_name, renderOptions[variable.select])}
              value={func.variables[key]}
              variableData={{ key, variable }}
            />
          </Box>
        ))}
      </Box>
      {orderedVariableInputs.slice(2).map(([key, variable]) => (
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={false}
          key={key}
          newOption={variable.select === "variables" ? newVariableButton : newBlockButton}
          onChange={(value) => onUpdateFunction(key, value as string)}
          options={renderOptions[variable.select]}
          value={func.variables[key]}
          variableData={{ key, variable }}
        />
      ))}
      {Object.entries(resultInputs).map(([key, variable]) => (
        <SimulatorFunctionBlocksFunctionVariableInput
          currentBlock={currentBlock}
          editVariable={editVariableButton}
          func={func}
          isOpen={openSelectComponent}
          isResult={true}
          key={key}
          newOption={null}
          onBlur={onBlurFunction}
          onChange={(value) => onUpdateFunction(key, value as string, false, true)}
          options={[]}
          value={func.result[key]}
          variableData={{ key, variable }}
        />
      ))}
    </Stack>
  );
}
