import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ObjectOf } from "../../../../../common/types/ObjectOf";
import { Product } from "../../../../../products/types/Product";
import { User } from "../../../../../users/types/User";
import { AnalysisDetails } from "../../../../types/AnalysisDetails/Analysis/AnalysisDetails";
import PDFAnalysisSummaryCard from "../Body/PDFAnalysisSummaryCard";
import PDFProductData from "../Body/PDFProductData";

type PDFHeaderProps = {
  analysisDetails: AnalysisDetails;
  product: Product;
  users: ObjectOf<User>;
};

const styles = StyleSheet.create({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "50px",
    width: "100%",
  },
  headerPaginationContainer: {
    paddingBottom: "20px",
    width: "100%",
  },
  productHeaderHash: {
    color: "rgb(127, 135, 152)",
    fontSize: "16px",
    marginLeft: "24px",
    marginTop: "11px",
  },
  productHeaderName: {
    color: "rgb(33, 36, 42)",
    fontSize: "28px",
  },
  productHeaderPagination: {
    color: "rgb(127, 135, 152)",
    fontSize: "16px",
    textAlign: "right",
  },
});

export default function PDFHeader(props: PDFHeaderProps) {
  const { analysisDetails, product, users } = props;

  return (
    <>
      <View fixed style={styles.headerPaginationContainer}>
        <Text
          render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
          style={styles.productHeaderPagination}
        />
      </View>
      <View fixed style={styles.headerContainer}>
        <Text style={styles.productHeaderName}>{product.name}</Text>
        <Text style={styles.productHeaderHash}>{product.id}</Text>
      </View>
      {analysisDetails.finished ? <PDFAnalysisSummaryCard analysisDetails={analysisDetails} users={users} /> : null}
      <PDFProductData product={product} />
    </>
  );
}
