import { LqdButton, LqdHumanIcon, LqdTypography } from "@/liquid-components/src";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { onChangingView } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import RegistryNavigationBar from "./RegistryNavigationBar";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function SendLiderRequest() {
  const dispatch = useAppDispatch();
  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 2 de 2",
        }}
        previusPage={SignatureViews.WITHOUT_CLOUD_SIGNING}
        step={SignatureViews.CREATE_CERTIFICATE}
      />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdHumanIcon color="rgba(127, 135, 152, 1)" size={45} />
        </Stack>
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Vamos te ajudar na criação de um novo Certificado Digital!
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Agende sua emissão com nossa certificadora parceira.
          <span style={{ color: "rgba(127, 135, 152, 1)" }}> Este processo não tem custo para você!</span>
        </LqdTypography>
        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.EMAIL_SEARCH))}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          Emitir um e-CPF gratuitamente
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
