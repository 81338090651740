import { useCallback } from "react";
import { useAppDispatch } from "../../../store";
import { toastCalled } from "../commonSlice";
import { ToastNotification } from "../types/ToastNotification";

/** Hook que copia a informação selecionada para a área de transferência e exibe um Toast indicando a cópia.
 * @param value Informação a ser copiado
 * @param label Label do hash a ser copiado
 * @returns Função que realiza o processo de cópia da informação para a área de transferência
 */
export const useCopyToClipboard = () => {
  const dispatch = useAppDispatch();

  const copyValueToClipboard = useCallback(
    (value: string, label: string) => {
      navigator.clipboard.writeText(value);

      const notification: ToastNotification = {
        message: `${label} copiado com sucesso.`,
        type: "success",
      };

      dispatch(toastCalled(notification));
    },
    [dispatch]
  );

  return copyValueToClipboard;
};
