import { LqdIconProps } from "../../types/IconProps";

export default function LqdFlagIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.99667 4.5459V21.0037"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M3.99664 16.5018C6.19791 15.2439 8.86652 15.1091 11.1833 16.1388L12.8166 16.8647C15.1334 17.8944 17.802 17.7596 20.0033 16.5018V4.54579C17.802 5.80367 15.1334 5.93845 12.8166 4.90876L11.1833 4.18284C8.86653 3.15315 6.19791 3.28793 3.99664 4.54579"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
