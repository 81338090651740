import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";
import LqdTypography from "../Typography";
import LqdFilledBox from "./FilledBox";
import LqdInfoButton from "./InfoButton";
import LqdOutlinedBox from "./OutlinedBox";

export type LqdFormFieldProps = {
  details?: ReactNode;
  disabled?: boolean;
  element?: ReactNode;
  error?: boolean;
  info?: string;
  label?: string;
  prependContent?: ReactNode;
  sx?: SxProps;
  variant?: "filled" | "outlined";
};

export default function LqdFormField(props: LqdFormFieldProps) {
  const { details, disabled, element, error, info, label, prependContent, sx, variant } = props;

  const renderBox = () => {
    switch (variant) {
      case "filled":
        return <LqdFilledBox>{element}</LqdFilledBox>;
      case "outlined":
        return <LqdOutlinedBox error={error}>{element}</LqdOutlinedBox>;
      default:
        return element;
    }
  };

  return (
    <Box sx={{ ...sx }}>
      {prependContent}
      <Box
        sx={{
          color: disabled ? "rgba(221, 225, 240, 1)" : "rgba(117, 123, 162, 1)",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "4px",
        }}
      >
        <LqdTypography textstyle="c1Caption">{label}</LqdTypography>
        {info && !disabled && <LqdInfoButton info={info} /> ? info && !disabled : null}
      </Box>
      {renderBox()}
      {details}
    </Box>
  );
}
