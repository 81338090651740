import { LqdButton, LqdTypography } from "@/liquid-components/src";
import Ellipsis02 from "@/liquid-components/src/icons/Ellipsis02";
import LeftArrow from "@/liquid-components/src/icons/LeftArrow";
import { Box, Stack } from "@mui/material";
import { getDocumentName } from "@signature/utils/signatureDocument";
import { useAppDispatch, useAppSelector } from "../../../store";
import { onChangingView, onOpenDocumentOptionsMenuModal } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import SignatureDocumentOptionsMenu from "./SignatureDocumentOptionsMenu";

interface centerContent {
  text: string;
}

type navigationBarProps = {
  centerContent: centerContent;
  previusPage: SignatureViews;
  showDoc?: boolean;
  step?: SignatureViews;
};

export default function RegistryNavigationBar(props: navigationBarProps) {
  const { centerContent, previusPage, showDoc, step } = props;

  const dispatch = useAppDispatch();
  const { contract, showDocumentOptionsMenu } = useAppSelector((state) => state.signature);

  const handleBack = () => {
    if (previusPage) {
      dispatch(onChangingView(previusPage));
    }
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderBottom: !step ? "1px solid rgba(127, 135, 152, 0.08)" : "",
          display: "flex",
          height: "68px",
          justifyContent: "space-between",
          padding: "12px 24px",
        }}
      >
        <LqdButton onClick={handleBack} size="medium" startIcon={<LeftArrow />} type="ghostPrimary">
          <LqdTypography color="rgba(79, 85, 98, 1)">Voltar</LqdTypography>
        </LqdButton>

        <Stack
          sx={{
            display: {
              sm: "flex",
              xs: "none",
            },
            textAlign: "center",
          }}
        >
          <LqdTypography color={centerContent.text.endsWith(":") ? "rgba(127, 135, 152, 1)" : "rgba(33, 36, 42, 1)"}>
            {centerContent.text}
          </LqdTypography>
          {showDoc ? (
            <Stack sx={{ alignItems: "center", gap: 1, textAlign: "center" }}>
              <LqdTypography color="rgba(33, 36, 42, 1)">{getDocumentName(contract.portalDocumentUrl)}</LqdTypography>
            </Stack>
          ) : null}
        </Stack>
        <LqdButton
          endIcon={<Ellipsis02 />}
          onClick={() => dispatch(onOpenDocumentOptionsMenuModal())}
          size="medium"
          sx={{
            display: {
              sm: "flex",
              xs: "none",
            },
          }}
          type="outlinePrimary"
        >
          Opções
        </LqdButton>
      </Box>
      {step ? (
        <Stack
          sx={{
            border: "1px solid rgba(33, 36, 42, 1)",
            height: "0px",
            width: centerContent.text === "Etapa 2 de 2" ? "100%" : "50%",
          }}
        />
      ) : null}
      {showDocumentOptionsMenu ? <SignatureDocumentOptionsMenu /> : null}
    </>
  );
}
