import { BuilderSimulation } from "@analysis/types/Simulation/Builder/BuilderSimulation";
import { SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";

export const mapToBuilderSimulation = (resultData: SimulatorTesterResultDBResponse): BuilderSimulation => {
  const dashboard = {
    cards: resultData.result!.dashboard.cards.map((card) => ({
      index: card.index,
      label: card.label,
      name: card.name,
      type: card.type || "",
      value: card.value || "",
    })),
    highlight: resultData.result!.dashboard.highlight.map((highlight) => ({
      index: highlight.index,
      label: highlight.label,
      name: highlight.name,
      type: highlight.type || "",
      value: highlight.value || "",
    })),
    tables: resultData.result!.dashboard.tables.map((table) => ({
      column_names: table.column_names.map((column) => ({
        index: column.index,
        name: column.name || "",
      })),
      data: table.data.map((row) => {
        return {
          column: row.column,
          label: row.label,
          line: row.line,
          name: row.name,
          type: row.type || "",
          value: row.value || "",
        };
      }),
    })),
  };

  const results = resultData.result?.results || {};
  const name = resultData.simulator_name || "Simulador padrão";

  return {
    dashboard,
    name,
    results,
    type: "simulator",
  };
};
