import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterFifteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        15. LEGISLAÇÃO E FORO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        15.1. A presente Política será regida pela legislação da República Federativa do Brasil.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        15.2. Para a solução de eventuais questões ou conflitos decorrentes desta Política, fica eleito o Foro da
        Comarca de São Paulo, Estado de São Paulo, com renúncia a qualquer outro, por mais privilegiado que seja.
      </LqdTypography>
    </>
  );
}
