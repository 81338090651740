import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterTwo() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        2. CADASTRO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        2.1. Para o correto funcionamento do Liquid, o Usuário deverá realizar um cadastro, diretamente na plataforma
        Liquid, inserindo as informações solicitadas e cadastrando pelo menos um usuário e uma senha para utilização
        posterior (“Cadastro”).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        2.1.1. Uma vez realizado o Cadastro, o Liquid poderá ser acessado por dispositivos compatíveis com seu uso e
        desde que com acesso à internet.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        2.1.2. A Liquid não garante que todos os computadores, telefones celulares e/ou tablets são compatíveis com o
        Liquid, pois isto poderá variar de acordo com cada fabricante de dispositivos, softwares instalados e outras
        configurações. O Usuário deverá verificar, com antecedência, se seu dispositivo suporta o acesso ao Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        2.1.3. O Usuário não deve acessar o Liquid a partir de redes de internet públicas, como, por exemplo, aquelas
        disponibilizadas em praças, shoppings, rodoviárias e aeroportos, para maior segurança dos dados armazenados no
        Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        2.1.4. O Usuário declara que os dados de acesso que ele informar no Cadastro e todas as informações constantes
        na área logada são individuais e suas e não podem ser compartilhadas com terceiros, pois isto poderá comprometer
        a segurança dos seus dados e de outras informações que constam no Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        2.1.5. O Usuário será responsável por qualquer acesso e/ou alteração realizado por terceiros no seu Cadastro e
        por quaisquer dados inseridos, apagados ou modificados.
      </LqdTypography>
    </>
  );
}
