import { LqdIconProps } from "../../types/IconProps";

export default function LqdVariableIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.23145 13.9596C5.91487 14.6431 5.91487 15.7511 5.23145 16.4345C4.54803 17.1179 3.43999 17.1179 2.75658 16.4345C2.07316 15.7511 2.07316 14.6431 2.75658 13.9596C3.44 13.2762 4.54804 13.2762 5.23145 13.9596"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        clipRule="evenodd"
        d="M12.949 14.997C12.949 14.031 13.732 13.247 14.699 13.247C15.666 13.247 16.449 14.03 16.449 14.997C16.449 15.964 15.666 16.747 14.699 16.747C13.736 16.751 12.953 15.974 12.949 15.011C12.949 15.006 12.949 15.002 12.949 14.997Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M10.6254 7.75658C11.3089 8.44 11.3089 9.54804 10.6254 10.2315C9.94202 10.9149 8.83397 10.9149 8.15056 10.2315C7.46715 9.54803 7.46715 8.43999 8.15056 7.75658C8.83398 7.07316 9.94202 7.07316 10.6254 7.75658"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M21.2434 7.75658C21.9268 8.44 21.9268 9.54804 21.2434 10.2315C20.56 10.9149 19.452 10.9149 18.7685 10.2315C18.0851 9.54803 18.0851 8.43999 18.7685 7.75658C19.452 7.07316 20.56 7.07316 21.2434 7.75658"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.79 10.25L15.89 13.72"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M5.17999 13.91L8.19999 10.27"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M13.51 13.72L10.58 10.27"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
