import { Stack } from "@mui/material";
import TermsAndConditionsOptionTwoChapterZero from "./EULA/TermsAndConditionsOptionTwo/00Chapter";
import TermsAndConditionsOptionTwoChapterOne from "./EULA/TermsAndConditionsOptionTwo/01Chapter";
import TermsAndConditionsOptionTwoChapterTwo from "./EULA/TermsAndConditionsOptionTwo/02Chapter";
import TermsAndConditionsOptionTwoChapterThree from "./EULA/TermsAndConditionsOptionTwo/03Chapter";
import TermsAndConditionsOptionTwoChapterFour from "./EULA/TermsAndConditionsOptionTwo/04Chapter";
import TermsAndConditionsOptionTwoChapterFive from "./EULA/TermsAndConditionsOptionTwo/05Chapter";
import TermsAndConditionsOptionTwoChapterSix from "./EULA/TermsAndConditionsOptionTwo/06Chapter";
import TermsAndConditionsOptionTwoChapterSeven from "./EULA/TermsAndConditionsOptionTwo/07Chapter";
import TermsAndConditionsOptionTwoChapterEight from "./EULA/TermsAndConditionsOptionTwo/08Chapter";
import TermsAndConditionsOptionTwoChapterNine from "./EULA/TermsAndConditionsOptionTwo/09Chapter";
import TermsAndConditionsOptionTwoChapterTen from "./EULA/TermsAndConditionsOptionTwo/10Chapter";
import TermsAndConditionsOptionTwoChapterEleven from "./EULA/TermsAndConditionsOptionTwo/11Chapter";
import TermsAndConditionsOptionTwoChapterTwelve from "./EULA/TermsAndConditionsOptionTwo/12Chapter";
import TermsAndConditionsOptionTwoChapterThirteen from "./EULA/TermsAndConditionsOptionTwo/13Chapter";
import TermsAndConditionsOptionTwoChapterFourteen from "./EULA/TermsAndConditionsOptionTwo/14Chapter";
import TermsAndConditionsOptionTwoChapterFifteen from "./EULA/TermsAndConditionsOptionTwo/15Chapter";

export default function TermsAndConditionsOptionTwo() {
  return (
    <Stack gap={3}>
      <TermsAndConditionsOptionTwoChapterZero />
      <TermsAndConditionsOptionTwoChapterOne />
      <TermsAndConditionsOptionTwoChapterTwo />
      <TermsAndConditionsOptionTwoChapterThree />
      <TermsAndConditionsOptionTwoChapterFour />
      <TermsAndConditionsOptionTwoChapterFive />
      <TermsAndConditionsOptionTwoChapterSix />
      <TermsAndConditionsOptionTwoChapterSeven />
      <TermsAndConditionsOptionTwoChapterEight />
      <TermsAndConditionsOptionTwoChapterNine />
      <TermsAndConditionsOptionTwoChapterTen />
      <TermsAndConditionsOptionTwoChapterEleven />
      <TermsAndConditionsOptionTwoChapterTwelve />
      <TermsAndConditionsOptionTwoChapterThirteen />
      <TermsAndConditionsOptionTwoChapterFourteen />
      <TermsAndConditionsOptionTwoChapterFifteen />
    </Stack>
  );
}
