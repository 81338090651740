import { LqdButton, LqdPlusTwoIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import {
  editAutomationFormNameChanged,
  newAutomationChanged,
  onGetAutomationProducts,
  onGetAutomations,
  onUpdateAutomation,
} from "../automationsSlice";
import { Automation } from "../types/Automation";
import AutomationActionCard from "./AutomationActionCard";
import AutomationConnectionCard from "./AutomationConnectionCard";
import AutomationCredentialForm from "./AutomationCredentialForm";
import AutomationFieldCard from "./AutomationFieldCard";
import AutomationOriginCard from "./AutomationOriginCard";
import AutomationStepArrow from "./AutomationStepArrow";

const AUTOMATION_NAME_MAX_LENGTH = 25;

export default function CreateOrEditAutomationModal() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { automations, creatingNewAutomation, editAutomationFormName, isEditingAutomation, newAutomationForm } =
    useAppSelector((state) => state.automations);

  const [automationName, setAutomationName] = useState("");
  const [domain, setDomain] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    if (newAutomationForm) {
      if (newAutomationForm.origin_module.length > 0) {
        dispatch(onGetAutomationProducts(newAutomationForm.origin_module));
      }
      if (newAutomationForm.credentials && newAutomationForm.credentials.client_domain) {
        setDomain(newAutomationForm.credentials.client_domain);
      }
      if (newAutomationForm.credentials && newAutomationForm.credentials.email) {
        setEmail(newAutomationForm.credentials.email);
      }
      if (newAutomationForm.credentials && newAutomationForm.credentials.token) {
        setToken(newAutomationForm.credentials.token);
      }
    }
  }, [newAutomationForm]);

  const domainChanged = domain !== newAutomationForm?.credentials?.client_domain;
  const emailChanged = email !== newAutomationForm?.credentials?.email;
  const tokenChanged = token !== newAutomationForm?.credentials?.token;
  const automationCredentialsChanged = emailChanged || tokenChanged || domainChanged;

  const automationNameIsTooShort = automationName.length < 3;
  const showNameMaxLengthAlert = automationName.length > AUTOMATION_NAME_MAX_LENGTH;

  const automationUnderEdit = newAutomationForm ? automations[newAutomationForm.configuration_id] : null;
  // Obs: é obrigatório que o valor de valid_credentials seja igual nas duas, visto que ele não é resetado após um edit nas credenciais
  const automationDataChanged = Boolean(
    newAutomationForm &&
      JSON.stringify({
        ...newAutomationForm,
        product: { ...newAutomationForm.product, image: null },
        valid_credentials: true,
      }) !==
        JSON.stringify({
          ...automationUnderEdit,
          product: { ...automationUnderEdit?.product, image: null },
          valid_credentials: true,
        })
  );

  const automationChanged = automationCredentialsChanged || automationDataChanged;

  const correctlyFilledActions = newAutomationForm?.actions.filter((action) => {
    return Object.keys(action.event.informations).every((key) => {
      const value = action.event.informations[key];
      if (Array.isArray(value)) {
        return value.length !== 0;
      } else if (typeof value === "object" && value !== null) {
        return Object.keys(value).length !== 0;
      } else {
        return !!value;
      }
    });
  });

  const isEveryActionFilled =
    (correctlyFilledActions?.length || 0) === newAutomationForm?.actions.length &&
    newAutomationForm?.actions.every((action) => action.event.name);

  const onAutomationNameSubmit = () => {
    dispatch(editAutomationFormNameChanged(false));
    dispatch(newAutomationChanged({ ...(newAutomationForm as Automation), name: automationName }));
  };

  // Adiciona uma ação vazia quando o produto é definido no newAutomationForm
  useLayoutEffect(() => {
    if (newAutomationForm?.product?.id && !newAutomationForm.actions.length) {
      dispatch(
        newAutomationChanged({
          ...(newAutomationForm as Automation),
          actions: [{ event: { informations: {}, name: "" }, filters: [] }],
        })
      );
    }
    // Pula o step de edição de nome caso a automação ja tenha um nome
    if (newAutomationForm?.name) {
      dispatch(editAutomationFormNameChanged(false));
    }
  }, [newAutomationForm]);

  // Salva a automação sendo criada/editada ao clicar no botão de Salvar
  const onSaveConfirm = async () => {
    dispatch(dialogCalled({ actions: [], body: "", title: "", type: "loading" }));

    if (!newAutomationForm) {
      throw new Error("Could not get newAutomationForm");
    }
    const automationForm: Automation = { ...newAutomationForm, credentials: { client_domain: domain, email, token } };
    try {
      await dispatch(onUpdateAutomation(automationForm));
      dispatch(newAutomationChanged(null));
      dispatch(onGetAutomations());
      const notification: ToastNotification = {
        message: t(`A automação ${automationForm.name}
        foi ${creatingNewAutomation ? "criada" : "atualizada"} com sucesso.`),
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogCalled(null));
    }
  };

  // Salva a automação sendo criada/editada ao clicar no botão de Salvar
  const onSaveClick = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: t("Voltar") },
          {
            onClick: () => {
              // Exclui as ações não finalizadas:
              dispatch(
                newAutomationChanged({ ...(newAutomationForm as Automation), actions: correctlyFilledActions || [] })
              );
              onSaveConfirm();
              dispatch(dialogCalled(null));
            },
            title: t("Salvar mesmo assim"),
          },
        ],
        body: t(`Há uma configuração de ação em andamento, salvar agora excluirá as ações não finalizadas.
        Deseja continuar?`),
        title: t("Atenção"),
        type: "default",
      })
    );
  };

  const onAddActionClick = () => {
    dispatch(
      newAutomationChanged({
        ...(newAutomationForm as Automation),
        actions: [...(newAutomationForm as Automation).actions, { event: { informations: {}, name: "" }, filters: [] }],
      })
    );
  };

  const onCloseClick = () => {
    dispatch(newAutomationChanged(null));
  };

  const onEditAutomationNameClick = () => {
    dispatch(editAutomationFormNameChanged(true));
  };

  return (
    <FullscreenDialog
      action={!isEditingAutomation ? "create" : "edit"}
      currentStep={1} // Step aqui é fixo, já que a lógica foi feita utilizando o Redux, e não currentStep
      existingChanges={automationChanged}
      onClose={onCloseClick}
      onEditClick={onEditAutomationNameClick}
      open={Boolean(newAutomationForm)}
      showEditButton={!editAutomationFormName}
      title={newAutomationForm?.name || t("Nova Automação")}
    >
      <Box sx={{ height: "100%", my: newAutomationForm?.name && !editAutomationFormName ? 7.5 : 0 }}>
        <Stack
          spacing={1.5}
          sx={{
            alignItems: !editAutomationFormName ? null : "flex-end",
            flex: 1,
            flexShrink: 1,
            height: "100%",
            justifyContent: !editAutomationFormName ? "flex-start" : "center",
            margin: "auto",
            maxWidth: "834px",
            width: "100%",
          }}
        >
          {editAutomationFormName ? (
            <>
              <LqdTypography sx={{ width: "100%" }} textstyle="h5Headline">
                {creatingNewAutomation ? t("Qual é o nome da nova automação?") : t("Qual é o novo nome da automação?")}
              </LqdTypography>
              <TextField
                autoFocus
                id="create-group-form-step1-input"
                inputProps={{
                  sx: {
                    color: "rgba(33, 36, 42, 1)",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    marginBottom: "12px",
                    padding: 0,
                  },
                }}
                onChange={(event) => setAutomationName(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !automationNameIsTooShort && !showNameMaxLengthAlert) {
                    onAutomationNameSubmit();
                  }
                }}
                placeholder="Digite aqui"
                sx={{
                  "& .MuiInput-underline:after": { borderBottomColor: "rgba(33, 36, 42, 1)" },
                  "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
                  ".MuiInputBase-root": { boxSizing: "border-box", my: 1.5, pl: 2 },
                  width: "100%",
                }}
                value={automationName}
                variant="standard"
              />
              <Stack direction="row" justifyContent="space-between" sx={{ mt: 1, width: "100%" }}>
                <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
                  {showNameMaxLengthAlert
                    ? t("Você alcançou o limite de 25 caracteres, abrevie para continuar.")
                    : null}
                </LqdTypography>
                <LqdTypography
                  sx={{
                    color: showNameMaxLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)",
                    display: "block",
                    textAlign: "right",
                  }}
                  textstyle="p2Paragraph"
                >
                  {`${automationName.length}/${AUTOMATION_NAME_MAX_LENGTH} caracteres`}
                </LqdTypography>
              </Stack>
              <LqdButton disabled={automationNameIsTooShort || showNameMaxLengthAlert} onClick={onAutomationNameSubmit}>
                {t("Continuar")}
              </LqdButton>
            </>
          ) : (
            <>
              <AutomationOriginCard>
                <AutomationCredentialForm
                  domain={domain}
                  email={email}
                  setDomain={setDomain}
                  setEmail={setEmail}
                  setToken={setToken}
                  token={token}
                />
              </AutomationOriginCard>
              <AutomationStepArrow />
              {newAutomationForm?.webhook_url && newAutomationForm.origin_module ? (
                <AutomationConnectionCard />
              ) : (
                <AutomationFieldCard subtitle={t("Criar nova conexão em um produto")} title={t("Adicionar Conexão")} />
              )}
              <AutomationStepArrow />
              {newAutomationForm?.product ? (
                newAutomationForm?.actions.map((_, index) => <AutomationActionCard actionIndex={index} key={index} />)
              ) : (
                <AutomationFieldCard subtitle={t("Um evento que inicia")} title={t("Adicionar Ação")} />
              )}
              <Stack direction="row" justifyContent="flex-end" paddingBottom={3} spacing={2}>
                {newAutomationForm?.product ? (
                  <LqdButton endIcon={<LqdPlusTwoIcon size={12} />} onClick={onAddActionClick} type="ghostPrimary">
                    {t("Adicionar Ação")}
                  </LqdButton>
                ) : null}
                <LqdButton
                  disabled={!newAutomationForm?.origin_module || !newAutomationForm.product}
                  onClick={() => (!isEveryActionFilled ? onSaveClick() : onSaveConfirm())}
                >
                  {t("Salvar")}
                </LqdButton>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </FullscreenDialog>
  );
}
