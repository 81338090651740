import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionTwoChapterTwelve() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        {/* 12. ATUALIZAÇÃO DA POLÍTICA */}
        12. RESPONSABILIDADES
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        {/* 12.1. Esta Política poderá ser alterada ou substituída a qualquer tempo, a exclusivo critério da Liquid, sem
        necessidade de prévia notificação, comprometendo-se a Empresa a manter o inteiro teor deste documento sempre
        atualizado e disponível para acesso através de nosso Site www.askliquid.com. */}
        12.1. A Liquid adota as melhores ferramentas de segurança da informação e práticas de compliance digital para
        proporcionar o nível de privacidade e segurança adequados para Dados Pessoais. No entanto, destacamos que,
        apesar de todos os nossos esforços, não somos capazes de assegurar a absoluta e total inviolabilidade do
        Tratamento, de modo que não podemos nos responsabilizar por eventuais danos causados por terceiros em virtude do
        descumprimento desta Política e/ou das obrigações contratuais assumidas com a Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        {/* 12.2. Recomendamos que você revise periodicamente esta Política, pois as
        regras e informações sobre o Tratamento
        de seus Dados Pessoais estará sempre vinculado à sua versão mais recente. */}
        12.2. A Liquid, via de regra, figura como Operador de Dados, uma vez que apenas disponibiliza o meio para que
        seja realizado o Tratamento de Dados através de suas Soluções, de acordo com os Parâmetros estabelecidos pelo
        Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        12.2.1 A Plataforma ASK Liquid permite o Tratamento de quaisquer categorias de Dados Pessoais, incluindo Dados
        Pessoais Sensíveis, cabendo ao Usuário Decisor a responsabilidade de garantir a finalidade e hipótese de
        Tratamento adequada, assim como a necessidade, pertinência e qualidade dos Dados inseridos na Plataforma.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.3. A depender do tipo de solicitação enviada pelo Usuário Analisado e/ou Monitorado, a Liquid, na qualidade
        de Operador, a repassará ao Usuário Decisor, ao qual competem as decisões referentes ao Tratamento
        (Controlador), não podendo ser responsabilizada pela ausência ou ineficácia no atendimento de referida
        solicitação.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.4. Na qualidade de Operador, a Liquid não se responsabilizará nos casos em que os Parâmetros estabelecidos
        pelo Usuário Decisor forem ou puderem ser considerados como Tratamento discriminatório.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.5. O Usuário Decisor terá total autonomia para definir os Parâmetros e os terceiros envolvidos no Tratamento
        de Dados Pessoais do Usuário Analisado e/ou Monitorado, sendo integral e exclusivamente responsável por
        quaisquer terceiros que envolver na operação, assim como por eventuais danos ou prejuízos causados aos Titulares
        dos Dados em decorrência da ação ou omissão de referidos terceiros.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.6. Conforme estabelecido no item 10.2.1. acima, informações sobre a tomada de decisão automatizada deverão
        ser requeridas diretamente junto ao Usuário Decisor, que estabeleceu os Parâmetros e ao qual competem as
        decisões referentes ao Tratamento (Controlador).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.7. A Liquid não se responsabilizará pela decisão final tomada pelo Usuário Decisor ou pelo Tratamento de
        Dados realizado fora do contexto de suas Soluções.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.8. A Liquid não se responsabilizará pelo compartilhamento indevido por quaisquer Usuários de seu login ou
        senha de acesso a qualquer uma de suas Soluções, independentemente de se tratar do Usuário Decisor ou do Usuário
        Analisado e/ou Monitorado.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        12.9. A Liquid também não se responsabilizará pela atuação criminosa de hackers, crackers ou assemelhados, bem
        como por ações ou omissões que possam ser atribuídas exclusivamente aos Usuários (Decisor ou Analisado e/ou
        Monitorado) ou terceiros, com exceção de comprovada negligência da Liquid em proteger os Dados Pessoais.
      </LqdTypography>
    </>
  );
}
