import { LqdButton, LqdIconButton, LqdInput, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { SimulatorFunctionBlocksFormViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksFormViews";
import formatVariableName from "@simulatorBuilder/utils/formatVariableName";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../store";

type SimulatorFunctionBlocksFormNewVariableModalNameProps = {
  handleDeleteVariable: () => void;
  setCurrentView: (view: SimulatorFunctionBlocksFormViews) => void;
  setVariableName: (name: string) => void;
  variableName: string;
};

const VARIABLE_NAME_MIN_LENGTH = 1;
const VARIABLE_NAME_MAX_LENGTH = 35;

export default function SimulatorFunctionBlocksFormNewVariableModalName(
  props: SimulatorFunctionBlocksFormNewVariableModalNameProps
) {
  const { handleDeleteVariable, setCurrentView, setVariableName, variableName } = props;

  const { selectedVariable, simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const variables = simulatorData?.variables;

  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);

  useEffect(() => {
    if (!variableName && selectedVariable?.name) {
      setVariableName(selectedVariable.name);
    }

    if (variables) {
      const exists = variables.some(
        (variable) => variable.name === variableName && variable.name !== selectedVariable.name
      );
      setNameAlreadyExists(exists);
    }
  }, [selectedVariable, variableName, setVariableName, variables]);

  const variableNameTooShort = variableName.length < VARIABLE_NAME_MIN_LENGTH;
  const variableNameTooBig = variableName.length > VARIABLE_NAME_MAX_LENGTH;
  const enableSaveButton = !variableNameTooShort && !variableNameTooBig && !nameAlreadyExists;

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2.5 }} textstyle="h5Headline">
          Qual é o nome da nova variável constante?
        </LqdTypography>
        {selectedVariable.id ? (
          <LqdIconButton buttonsize="small" onClick={handleDeleteVariable} type="ghostIcon">
            <LqdTrashIcon />
          </LqdIconButton>
        ) : null}
      </Box>
      <LqdInput
        alertMessage={
          nameAlreadyExists ? "Já existe uma variável com este nome. Por favor, escolha um nome diferente." : ""
        }
        characterLimit={VARIABLE_NAME_MAX_LENGTH}
        onConfirm={() => setCurrentView("variableType")}
        onConfirmValidation={enableSaveButton}
        placeholderText="nome_da_variavel"
        setValue={setVariableName}
        showCharacterLimit
        value={formatVariableName(variableName)}
      />
      <LqdButton
        disabled={!enableSaveButton}
        onClick={() => setCurrentView("variableType")}
        sx={{ float: "right", mt: 3 }}
      >
        {selectedVariable.id ? "Continuar" : "Salvar"}
      </LqdButton>
    </>
  );
}
