import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { AppDispatch } from "../../../store";

/** Função para reordenar as variáveis do simulador.
 * @param dispatch dispatch do redux
 * @param variables todas as variáveis, incluindo is_input tanto falso quanto verdadeiro
 * @param simulatorVariable A variavel em si, que foi clicada para reordenação, ou para cima ou para baixo
 * @param type define se a ordenação é para cima: sentido o index 0, ou para baixo: sentido index array.length -1
 */
export const reorderVariablesPosition = (
  dispatch: AppDispatch,
  variables: Array<SimulatorVariable>,
  simulatorVariable: SimulatorVariable,
  type: "down" | "up"
) => {
  //recria a lista com todas as variáveis
  const variablesList = [...variables];

  // retorna o index da variável a ser alterada a ordem
  const index = variablesList.indexOf(simulatorVariable);

  // define a direção da reordenação
  const variableNewPosition = type === "up" ? index - 1 : index + 1;

  //validação para evitar bugs
  if (variableNewPosition < 0 || variableNewPosition >= variablesList.length) {
    return;
  }

  // atualiza chave de ordenação
  variablesList[index] = {
    ...variablesList[index],
    order_index: type === "up" ? variablesList[index].order_index! - 1 : variablesList[index].order_index! + 1,
  };

  // atualiza chave de ordenação
  variablesList[variableNewPosition] = {
    ...variablesList[variableNewPosition],
    order_index: type === "up" ? variablesList[index].order_index! + 1 : variablesList[index].order_index! - 1,
  };

  // troca os elementos da lista de lugar
  [variablesList[index], variablesList[variableNewPosition]] = [
    variablesList[variableNewPosition],
    variablesList[index],
  ];

  dispatch(simulatorBuilderChanged({ key: "variables", value: [...variablesList] }));
};
