import { LqdOriginIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { Box, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled } from "../../common/commonSlice";
import { newAutomationChanged } from "../automationsSlice";
import { automationsModulesDictionary } from "../helpers/automationModulesDictionary";
import { Automation } from "../types/Automation";
import AutomationSelect from "./AutomationSelect";
import AutomationWebhookDisplay from "./AutomationWebhookDisplay";

type AutomationOriginCardProps = {
  children?: ReactNode;
};

export default function AutomationOriginCard(props: AutomationOriginCardProps) {
  const { children } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const automationsState = useAppSelector((state) => state.automations);
  const automationModules = automationsState.automationModules;
  const newAutomationForm = automationsState.newAutomationForm as Automation;

  // Seleciona o novo origin module da automação, após a confirmação do dialog
  const onChangingOriginModule = (module: string) => {
    const updatedAutomation: Automation = {
      ...newAutomationForm,
      actions: [],
      credentials: { client_domain: "", email: "", token: "" },
      origin_module: module,
      product: null,
      valid_credentials: false,
      webhook_url: "",
    };
    dispatch(newAutomationChanged(updatedAutomation));
    dispatch(dialogCalled(null));
  };

  /**
   * Aplica a nova seleção de origin module da automação. Exibe o dialog de
   * confirmação caso já exista um origin_module selecionado.
   */
  const handleAutomationOriginChange = (module: string) => {
    newAutomationForm?.origin_module && newAutomationForm?.origin_module !== module
      ? dispatch(
          dialogCalled({
            actions: [{ title: "Voltar" }, { onClick: () => onChangingOriginModule(module), title: "Alterar Origem" }],
            body: "Você está prestes a modificar a origem da automação. \
            Isso removerá todos os dados inseridos até o momento, exigindo que você os reinsira. Deseja continuar?",
            title: "Alteração de Origem",
            type: "default",
          })
        )
      : onSelectedModuleChange(module);
  };

  // Seleciona o origin module da automação, caso nenhum tenha sido selecionado ainda
  const onSelectedModuleChange = (module: string) => {
    const updatedAutomation = { ...newAutomationForm };
    updatedAutomation.origin_module = module;
    dispatch(newAutomationChanged(updatedAutomation));
  };

  return newAutomationForm ? (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{ backgroundColor: "rgba(249, 250, 251, 1)", borderRadius: "20px", px: 2.5, py: 4 }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "rgba(33, 36, 42, 1)",
          border: "1px solid rgba(56, 61, 70, 1)",
          borderRadius: "50%",
          display: { md: "flex", xs: "none" },
          height: "34px",
          width: "34px",
        }}
      >
        <LqdOriginIcon color="rgba(255, 255, 255, 1)" />
      </Stack>
      <Box sx={{ flex: 1, pr: 5 }}>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="h4Headline">
          {t("Origem")}
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 4 }} textstyle="c1Caption">
          {t("De onde a informação vem.")}
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1 }} textstyle="p2Paragraph">
          {t("Origem")}
        </LqdTypography>
        <LqdTooltip
          hidetooltip={Object.values(automationModules).length > 0 ? "true" : "false"}
          titlebody={t(`A sua empresa não possui nenhuma automação contratada.
          Entre em contato com a Liquid para saber mais.`)}
          titleheader={t("Não há opções disponíveis.")}
        >
          <AutomationSelect
            disabled={!Object.values(automationModules).length}
            fullWidth
            onChange={(event) => handleAutomationOriginChange(event.target.value as string)}
            renderValue={() =>
              newAutomationForm.origin_module
                ? automationsModulesDictionary[newAutomationForm.origin_module]
                : t("Selecione a origem do trigger")
            }
            sx={{ mb: 1.5 }}
            value={newAutomationForm.origin_module}
          >
            {Object.values(automationModules).map((module, index) => (
              <MenuItem
                key={module.origin_module}
                sx={{
                  "&.Mui-selected": {
                    "&:hover": { backgroundColor: "transparent" },
                    backgroundColor: "transparent",
                    borderColor: "rgba(33, 36, 42, 1)",
                    color: "rgba(33, 36, 42, 1)",
                  },
                  border: "1px solid rgba(240, 241, 243, 1)",
                  borderRadius: "8px",
                  color: "rgba(101, 110, 127, 1)",
                  mt: index ? 1 : 0,
                }}
                value={module.origin_module}
              >
                {automationsModulesDictionary[module.origin_module]}
              </MenuItem>
            ))}
          </AutomationSelect>
        </LqdTooltip>
        {children}
        <AutomationWebhookDisplay />
      </Box>
    </Stack>
  ) : null;
}
