import { Path, StyleSheet, Svg } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  svgIcon: {
    height: "100px",
    width: "100px",
  },
});

export default function PDFSummaryReproveSVG() {
  return (
    <Svg style={styles.svgIcon}>
      <Path
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill="rgb(254, 223, 216)"
      />
      <Path
        d="M37.9549 22.045C42.3483 26.4384 42.3483 33.5616 37.9549 37.9549C33.5615 42.3483 26.4384 42.3483 22.045 37.9549C17.6517 33.5615 17.6517 26.4384 22.045 22.045C26.4384 17.6517 33.5616 17.6517 37.9549 22.045"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path d="M25.625 26.25V27.5" stroke="rgb(33, 36, 42)" strokeLineCap="round" strokeWidth="1.2" />
      <Path d="M34.375 26.25V27.5" stroke="rgb(33, 36, 42)" strokeLineCap="round" strokeWidth="1.2" />
      <Path
        d="M25.625 35.0004C25.625 35.0004 27.2662 33.3604 30 33.3604C32.735 33.3604 34.375 35.0004 34.375 35.0004"
        stroke="rgb(33, 36, 42)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
    </Svg>
  );
}
