import { ObjectOf } from "../../common/types/ObjectOf";
import { AnalysisDetails } from "../types/AnalysisDetails/Analysis/AnalysisDetails";
import { AnalysisComment } from "../types/AnalysisDetails/Analysis/AnalysisDetailsComments";
import { AnalysisDetailsRaw } from "../types/AnalysisDetails/Analysis/AnalysisDetailsRaw";
import { PeerData } from "../types/AnalysisDetails/PeerData/PeerData";

export const formatRawAnalysisDetails = (analysisDetailsRaw: AnalysisDetailsRaw): AnalysisDetails => ({
  analysisId: analysisDetailsRaw.analysis_id,
  analysisUrl: analysisDetailsRaw.analysis_url,
  archived: analysisDetailsRaw.analysis_result.archived,
  archivedAt: analysisDetailsRaw.analysis_result.archived_at
    ? analysisDetailsRaw.analysis_result.archived_at * 1000
    : null,
  archivedBy: analysisDetailsRaw.analysis_result.archived_by || null,
  callbackUrl: analysisDetailsRaw.callback_url,
  finished: analysisDetailsRaw.analysis_result.finished,
  finishedAt: analysisDetailsRaw.analysis_result.finished_at
    ? analysisDetailsRaw.analysis_result.finished_at * 1000
    : null,
  finishedBy: analysisDetailsRaw.analysis_result.finished_by || null,
  humanInteraction: analysisDetailsRaw.analysis_result.human_interaction,
  humanInteractionAt: analysisDetailsRaw.analysis_result.human_interaction_at
    ? analysisDetailsRaw.analysis_result.human_interaction_at * 1000
    : null,
  humanInteractionBy: analysisDetailsRaw.analysis_result.human_interaction_by || null,
  lastRunAt: analysisDetailsRaw.last_run_at,
  latestAIRunAt: analysisDetailsRaw.latest_ai_run_at,
  peers: analysisDetailsRaw.peers.reduce((acc, peer) => {
    acc[peer.document] = {
      birthday: peer.birthday,
      contact: peer.contact,
      document: peer.document,
      fullName: peer.full_name || "Nome não encontrado",
      passId: peer.pass_id,
      peer: peer.peer,
    };
    return acc;
  }, {} as ObjectOf<PeerData>),
  productId: analysisDetailsRaw.product_id,
  result: {
    bureau: analysisDetailsRaw.analysis_result.bureau,
    comments: analysisDetailsRaw.analysis_result.comments as AnalysisComment,
    finalResult: analysisDetailsRaw.analysis_result.final_result,
    simulations:
      analysisDetailsRaw.analysis_result.simulations?.length &&
      analysisDetailsRaw.analysis_result?.simulations?.length > 0
        ? analysisDetailsRaw.analysis_result.simulations
        : [],
  },
});
