import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterTen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        10. VIGÊNCIA
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        Os Termos terão sua vigência a partir da data de Aceite pelo Usuário Decisor, permanecendo vigentes enquanto o
        Usuário Decisor utilizar o Liquid, ou pelo prazo determinado na Proposta Comercial, o que durar mais.
      </LqdTypography>
    </>
  );
}
