import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFLiquidLogoSVG from "./PDFLiquidLogoSVG";

type PDFFooterProps = {
  footerData: { exportedAt: string; exportedBy: string };
};

const styles = StyleSheet.create({
  footerText: {
    bottom: "120px",
    color: "rgb(127, 135, 152)",
    fontSize: "16px",
    left: "915px",
    lineHeight: "1.5px",
    position: "absolute",
    textAlign: "right",
    width: "300px",
  },
});

export default function PDFFooter(props: PDFFooterProps) {
  const { footerData } = props;

  return (
    <>
      <PDFLiquidLogoSVG />
      <View fixed style={styles.footerText}>
        <Text>{footerData.exportedAt}</Text>
        <Text>{footerData.exportedBy}</Text>
      </View>
    </>
  );
}
