import LiquidAILogo from "@common/components/LiquidAILogo";
import { AppBar, Toolbar } from "@mui/material";

export default function SignatureHeader() {
  return (
    <AppBar
      sx={{
        alignItems: "center",
        alignSelf: "stretch",
        backgroundColor: "rgba(33, 36, 42, 1)",
        display: "flex",
        height: "60px",
        justifyContent: "space-between",
        padding: "0 32px 0 32px",
        position: "static",
        width: "100%",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <LiquidAILogo color="rgba(189, 199, 215, 1)" height={50} width={80} />
      </Toolbar>
    </AppBar>
  );
}
