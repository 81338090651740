import { LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import openFinanceFinished from "../../assets/openFinanceFinished.png";

export default function OpenFinanceAcceptedState() {
  const { width } = useWindowSize();

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 3 } }}
    >
      <Box>
        <Box sx={{ textAlign: { sm: "left", xs: "center" } }}>
          <Box sx={{ maxWidth: "517px" }}>
            <LqdTypography sx={{ mb: "30px" }} textstyle={width === "xs" ? "h3Headline" : "h2Headline"}>
              Seu compartilhamento foi concluído!
            </LqdTypography>
            <LqdTypography sx={{ fontSize: "20px", mb: 2.5 }} textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}>
              Suas informações já estão sendo analisadas.
            </LqdTypography>
            <LqdTypography textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}>
              Agradecemos por confiar na Liquid ❤️
            </LqdTypography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openFinanceFinished} />
      </Box>
    </Stack>
  );
}
