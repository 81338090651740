import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";

export default function useSimulatorBuilder() {
  const { simulatorId } = useParams<{ simulatorId: string }>();

  const { simulatorData, simulators, variableTypes } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData || simulators[simulatorId!];

  const defaultSimulatorVariables = simulator.variables.filter((variable) => variable.category === "default");
  const simulatorVariablesWithoutDefault = simulator.variables.filter((variable) => variable.category !== "default");
  const simulatorVariablesWithoutDefaultAndInputs = simulatorVariablesWithoutDefault.filter(
    (variable) => !variable.is_input
  );

  const comparatorVariables = defaultSimulatorVariables.filter((variable) => variable.type === "comparator");
  const mathComparatorVariables = defaultSimulatorVariables.filter((variable) => variable.type === "math_comparator");
  const typeComparatorVariables = variableTypes.filter(
    (variable) => variable.value !== "boolean" && variable.value !== "date"
  );

  const inputVariables = simulator.variables.filter((variable) => variable.is_input);

  return {
    comparatorVariables,
    defaultSimulatorVariables,
    inputVariables,
    mathComparatorVariables,
    simulator,
    simulatorVariablesWithoutDefault,
    simulatorVariablesWithoutDefaultAndInputs,
    typeComparatorVariables,
  };
}
