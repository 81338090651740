import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterEleven() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        11. EXTINÇÃO
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.1. Os Termos poderão, a qualquer tempo, ser resilidos pela Liquid, sem ônus de qualquer natureza à Liquid,
        mediante envio de simples comunicação ao Usuário com, no mínimo, 30 (trinta) dias de antecedência à extinção dos
        Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.2. O Usuário Decisor se declara ciente de que a celebração dos Termos gerou custos à Liquid, com expectativa
        de retorno mediante extensão da vigência dos Termos por todo o prazo previsto no item 10.1 acima. Deste modo, em
        caso de resilição contratual por iniciativa da Usuário Decisor, este desde já concorda com o pagamento da multa
        prevista na Proposta Comercial, caso aplicável.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.3. Adicionalmente às hipóteses de resilição previstas nos itens 11.1 e 11.2 acima, os Termos poderão ser
        resolvidos, imediatamente e independentemente de envio de comunicação à outra Parte, nas seguintes hipóteses:
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        a) Decretação de falência ou insolvência, ou homologação de pedido de recuperação judicial ou extrajudicial ou
        decretação de insolvência da outra Parte;
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        b) Inadimplemento de qualquer cláusula por qualquer das Partes.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.4. Em qualquer hipótese de término dos Termos, o Usuário Decisor se compromete a realizar o pagamento de
        todos os valores devidos à Liquid no prazo máximo de 10 (dez) dias a contar da data de extinção dos Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        11.5. Em qualquer hipótese de término dos Termos, o Usuário Decisor, a partir da data de extinção dos Termos,
        realizará o descadastramento da Usuário do Liquid, não sendo mais possível o acesso ou a recuperação de qualquer
        informação lá constante.
      </LqdTypography>
    </>
  );
}
