import { LqdInput } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorFunctionTemplateVariableLabel } from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import formatVariableName from "@simulatorBuilder/utils/formatVariableName";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { VariableInputProps } from "./SimulatorFunctionBlocksFunctionInputsRenderer";

const CHARACTER_LIMIT = 35;

export default function SimulatorFunctionBlocksFunctionVariableTextField(props: VariableInputProps) {
  const { currentBlock, func, onBlur, onChange, value, variableData } = props;

  const dispatch = useDispatch();
  const { simulator } = useSimulatorBuilder();

  const [resultVariableLabel, setResultVariableLabel] = useState<string>(
    simulator.variables.find((v) => v.name === value)?.label || ""
  );

  const handleResultVariableLabelBlur = (label: string) => {
    const simulatorVariables = simulator.variables;
    const resultVariable = simulatorVariables.find((v) => v.name === value);

    if (!resultVariable) return;

    const updatedVariable: SimulatorVariable = { ...resultVariable, label: label };

    const updatedSimulatorVariables = simulatorVariables.map((variable) => {
      return variable.name === resultVariable.name ? updatedVariable : variable;
    });

    const updatedCurrentBlockFuncs = currentBlock.functions.map((funct, index) => {
      const targetFunctIndex = index + 1;
      return targetFunctIndex === func.index ? func : funct;
    });

    const updatedCurrentBlock = { ...currentBlock, functions: updatedCurrentBlockFuncs };

    const updatedBlocks = simulator.blocks.map((block) => {
      return block.block_id === updatedCurrentBlock.block_id ? updatedCurrentBlock : block;
    });

    dispatch(simulatorBuilderChanged({ key: "variables", value: [...updatedSimulatorVariables] }));
    dispatch(simulatorBuilderChanged({ key: "blocks", value: [...updatedBlocks] }));
  };

  return (
    <Stack alignItems="flex-end" flexDirection="row" gap={1}>
      {(variableData.variable.label as Array<SimulatorFunctionTemplateVariableLabel>).map((label) => {
        const inputValue = label.type === "name" ? formatVariableName((value || "").toString()) : resultVariableLabel;

        return (
          <Stack key={label.type} width="100%">
            <LqdInput
              boxSize="small"
              characterLimit={CHARACTER_LIMIT}
              disabled={label.type === "label" ? ((value as string) || "").length < 1 : false}
              fullBox={true}
              label={label.title}
              onBlur={label.type === "name" ? onBlur : handleResultVariableLabelBlur}
              setValue={label.type === "name" ? onChange : setResultVariableLabel}
              showCharacterLimit={true}
              sx={{ backgroundColor: "rgba(255, 255, 255, 1)", height: "30px" }}
              value={inputValue}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
