import { Box } from "@mui/material";

type FakeHandleProps = {
  isTarget: "" | boolean | null;
};

export default function FakeHandle(props: FakeHandleProps) {
  const { isTarget } = props;

  return (
    <Box
      sx={{
        backgroundColor: isTarget ? "rgba(0, 0, 0, 0.1)" : "transparent",
        borderRadius: "0 16px 16px 0",
        height: "calc(100% - 48px)",
        left: "0",
        position: "absolute",
        top: "24px",
        width: "8px",
      }}
    />
  );
}
