import { Button, ButtonProps } from "@mui/material";
import { ReactElement } from "react";

export type LqdIconButtonTypes =
  | "filledPrimary"
  | "filledSecundary"
  | "ghostIcon"
  | "ghostLink"
  | "ghostPrimary"
  | "ghostSecundary"
  | "ghostTertiary"
  | "outlinePrimary"
  | "outlineSecundary";

type LqdIconButtonProps = {
  buttonsize?: "extraLarge" | "extraSmall" | "large" | "medium" | "small"; // default: medium
  round?: string;
  type?: LqdIconButtonTypes; // default: filledPrimary
};

const buttonDimensions = {
  extraLarge: "64px",
  large: "52px",
  medium: "44px",
  small: "32px",
};

const iconSize = {
  extraLarge: "44px",
  extraSmall: "20px",
  large: "36px",
  medium: "28px",
  small: "24px",
};

const iconColor = {
  filledPrimary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  filledSecundary: {
    active: "rgba(56, 61, 70, 1)",
    default: "rgba(33, 36, 42, 1)",
    disabled: "rgba(127, 135, 152, 0.32)",
    focus: "rgba(33, 36, 42, 1)",
    hover: "rgba(33, 36, 42, 1)",
  },
  ghostIcon: {
    active: "rgba(79, 85, 98, 1)",
    default: "rgba(127, 135, 152, 1)",
    disabled: "rgba(127, 135, 152, 0.5)",
    focus: "rgba(79, 85, 98, 1)",
    hover: "rgba(79, 85, 98, 1)",
  },
  ghostLink: {
    active: "rgba(56, 61, 70, 1)",
    default: "rgba(33, 36, 42, 1)",
    disabled: "rgba(127, 135, 152, 0.16)",
    focus: "rgba(56, 61, 70, 1)",
    hover: "rgba(56, 61, 70, 1)",
  },
  ghostPrimary: {
    active: "rgba(127, 135, 152, 1)",
    default: "rgba(155, 162, 175, 1)",
    disabled: "rgba(127, 135, 152, 0.5)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
  ghostSecundary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  ghostTertiary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
  outlinePrimary: {
    active: "rgba(127, 135, 152, 1)",
    default: "rgba(155, 162, 175, 1)",
    disabled: "rgba(127, 135, 152, 0.5)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
  outlineSecundary: {
    active: "rgba(255, 255, 255, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(255, 255, 255, 1)",
  },
};

const backgroundColor = {
  filledPrimary: {
    active: "rgba(33, 36, 42, 1)",
    default: "rgba(33, 36, 42, 1)",
    disabled: "rgba(33, 36, 42, 0.5)",
    focus: "rgba(101, 110, 127, 1)",
    hover: "rgba(56, 61, 70, 1)",
  },
  filledSecundary: {
    active: "rgba(212, 215, 220, 1)",
    default: "rgba(255, 255, 255, 1)",
    disabled: "rgba(255, 255, 255, 0.5)",
    focus: "rgba(255, 255, 255, 1)",
    hover: "rgba(240, 241, 243, 1)",
  },
  ghostIcon: {
    active: "rgba(0, 0, 0, 0)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(0, 0, 0, 0)",
    hover: "rgba(0, 0, 0, 0)",
  },
  ghostLink: {
    active: "rgba(0, 0, 0, 0)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(0, 0, 0, 0)",
    hover: "rgba(127, 135, 152, 0.16)",
  },
  ghostPrimary: {
    active: "rgba(212, 215, 220, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(240, 241, 243, 0.5)",
    focus: "rgba(240, 241, 243, 1)",
    hover: "rgba(240, 241, 243, 1)",
  },
  ghostSecundary: {
    active: "rgba(33, 36, 42, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(33, 36, 42, 1)",
    hover: "rgba(33, 36, 42, 1)",
  },
  ghostTertiary: {
    active: "rgba(101, 110, 127, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(0, 0, 0, 0)",
    focus: "rgba(127, 135, 152, 1)",
    hover: "rgba(127, 135, 152, 1)",
  },
  outlinePrimary: {
    active: "rgba(212, 215, 220, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(240, 241, 243, 0.5)",
    focus: "rgba(240, 241, 243, 1)",
    hover: "rgba(240, 241, 243, 1)",
  },
  outlineSecundary: {
    active: "rgba(56, 61, 70, 1)",
    default: "rgba(0, 0, 0, 0)",
    disabled: "rgba(127, 135, 152, 0.16)",
    focus: "rgba(33, 36, 42, 1)",
    hover: "rgba(33, 36, 42, 1)",
  },
};

const border = {
  filledPrimary: {
    active: "1px solid rgba(56, 61, 70, 1)",
    default: "1px solid rgba(56, 61, 70, 1)",
    disabled: "1px solid rgba(56, 61, 70, 0.5)",
    focus: "1px solid rgba(56, 61, 70, 1) ",
    hover: "1px solid rgba(56, 61, 70, 1)",
  },
  filledSecundary: {
    active: "1px solid rgba(127, 135, 152, 1)",
    default: null,
    disabled: null,
    focus: "1px rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(127, 135, 152, 1)",
  },
  ghostIcon: {
    active: null,
    default: null,
    disabled: null,
    focus: null,
    hover: null,
  },
  ghostLink: {
    active: null,
    default: null,
    disabled: null,
    focus: null,
    hover: null,
  },
  ghostPrimary: {
    active: null,
    default: null,
    disabled: null,
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: null,
  },
  ghostSecundary: {
    active: "1px solid rgba(255, 255, 255, 1)",
    default: "1px solid rgba(255, 255, 255, 1)",
    disabled: null,
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(255, 255, 255, 1)",
  },
  ghostTertiary: {
    active: "1px solid rgba(255, 255, 255, 1)",
    default: "1px solid rgba(255, 255, 255, 1)",
    disabled: null,
    focus: "1px solid rgba(255, 255, 255, 1)",
    hover: "1px solid rgba(255, 255, 255, 1)",
  },
  outlinePrimary: {
    active: "1px solid rgba(155, 162, 175, 1)",
    default: "1.2px solid rgba(155, 162, 175, 1)",
    disabled: "1px solid rgba(155, 162, 175, 0.5)",
    focus: "1px solid rgba(155, 162, 175, 1)",
    hover: "1px solid rgba(127, 135, 152, 1)",
  },
  outlineSecundary: {
    active: "1px solid rgba(56, 61, 70, 1)",
    default: "1px solid rgba(255, 255, 255, 1)",
    disabled: "1px solid rgba(56, 61, 70, 0.5)",
    focus: "1px solid rgba(56, 61, 70, 1)",
    hover: "1px solid rgba(56, 61, 70, 1)",
  },
};

const focusOutline = {
  filledPrimary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  filledSecundary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  ghostIcon: { active: null },
  ghostLink: { active: null },
  ghostPrimary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  ghostSecundary: { active: "6px solid rgba(127, 135, 152, 0.32)" },
  ghostTertiary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  outlinePrimary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
  outlineSecundary: { active: "6px solid rgba(127, 135, 152, 0.16)" },
};

/** Default styling
 * @param buttonsize medium
 * @param type filledPrimary
 */
export default function LqdIconButton(props: LqdIconButtonProps & Omit<ButtonProps, "type" | "buttonSize">) {
  const { buttonsize, children, round, sx, type } = props;

  const { size } = (children as ReactElement).props;

  return (
    <Button
      {...props}
      disableElevation
      sx={{
        "& > svg": {
          height: size ? "auto" : iconSize[buttonsize || "medium"],
          width: size ? "auto" : iconSize[buttonsize || "medium"],
        },
        ":active": {
          background: backgroundColor[type || "filledPrimary"].active,
          border: border[type || "filledPrimary"].active,
          color: iconColor[type || "filledPrimary"].active,
          outline: focusOutline[type || "filledPrimary"].active,
        },
        ":disabled": {
          background: backgroundColor[type || "filledPrimary"].disabled,
          border: border[type || "filledPrimary"].disabled,
          color: iconColor[type || "filledPrimary"].disabled,
        },
        ":focus": {
          background: backgroundColor[type || "filledPrimary"].focus,
          border: border[type || "filledPrimary"].focus,
          color: iconColor[type || "filledPrimary"].focus,
        },
        ":hover": {
          background: backgroundColor[type || "filledPrimary"].hover,
          border: border[type || "filledPrimary"].hover,
          color: iconColor[type || "filledPrimary"].hover,
        },
        alignItems: "center",
        background: backgroundColor[type || "filledPrimary"].default,
        border: border[type || "filledPrimary"].default,
        borderRadius: round && round === "true" ? "100px" : "8px",
        color: iconColor[type || "filledPrimary"].default,
        display: "inline-flex",
        height: buttonsize === "extraSmall" ? "auto" : buttonDimensions[buttonsize || "medium"],
        justifyContent: "center",
        minHeight: buttonsize === "extraSmall" ? "auto" : buttonDimensions[buttonsize || "medium"],
        minWidth: buttonsize === "extraSmall" ? "auto" : buttonDimensions[buttonsize || "medium"],
        padding: 0,
        width: buttonsize === "extraSmall" ? "auto" : buttonDimensions[buttonsize || "medium"],
        ...sx,
      }}
      type={undefined}
    >
      {children}
    </Button>
  );
}
