import {
  LqdButton,
  LqdCheckboxOffIcon,
  LqdCheckboxOnIcon,
  LqdTooltip,
  LqdTypography,
  LqdWorldIcon,
} from "@/liquid-components/src";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { createTenantModalStepNext, editTenantModalStepGoTo } from "../../../adminSlice";

type TenantFormStep3Props = {
  checkedProviders: Array<string>;
  setCheckedProviders: (providers: Array<string>) => void;
};

export default function TenantFormStep3(props: TenantFormStep3Props) {
  const { checkedProviders, setCheckedProviders } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { createTenant, providers, tenantToEdit } = useAppSelector((state) => state.admin);

  // Envia as modificações deste step para o parent, quando clicar no botão de avançar
  const handleSubmit = () => {
    dispatch(createTenant ? createTenantModalStepNext() : editTenantModalStepGoTo(4));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedProviders([...checkedProviders, event.target.name]);
    } else {
      setCheckedProviders(checkedProviders.filter((segment) => segment !== event.target.name));
    }
  };

  const maySubmit =
    JSON.stringify([...(tenantToEdit?.providers || [])].sort()) !== JSON.stringify([...checkedProviders].sort());

  return (
    <Box sx={{ px: 20, width: { lg: "100vw", md: "60vw" } }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 7.5 }} textstyle="h4Headline">
        {t("Selecione os Providers")}
      </LqdTypography>
      <Box sx={{ display: "flex", flexDirection: "column", height: "350px", overflow: "auto" }}>
        {Object.values(providers).map((provider) => (
          <FormControlLabel
            control={
              provider.global ? (
                <Checkbox
                  checked={true}
                  checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={18} />}
                  disabled
                  icon={<LqdCheckboxOffIcon color="rgba(33, 36, 42, 1)" size={18} />}
                  name={provider.providerId}
                />
              ) : (
                <Checkbox
                  checked={checkedProviders.includes(provider.providerId)}
                  checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={18} />}
                  icon={<LqdCheckboxOffIcon color="rgba(33, 36, 42, 1)" size={18} />}
                  name={provider.providerId}
                  onChange={(event) => handleCheckboxChange(event)}
                />
              )
            }
            disabled={provider.global}
            key={provider.providerId}
            label={
              provider.global ? (
                <LqdTooltip
                  placement="left"
                  titlebody="Providers globais são adicionados automaticamente e não podem ser removidos."
                >
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <LqdTypography color="rgba(0, 0, 0, 0.38)" sx={{ mr: 1 }}>
                      {provider.title}
                    </LqdTypography>
                    <LqdWorldIcon />
                  </Box>
                </LqdTooltip>
              ) : (
                <LqdTypography color="rgba(0, 0, 0, 0.38)">{provider.title}</LqdTypography>
              )
            }
            sx={{
              ".MuiTypography-root": { color: "rgba(33, 36, 42, 1)" },
              borderBottom: "1px solid rgba(212, 215, 220, 1)",
              p: 3,
            }}
          />
        ))}
      </Box>
      <Box sx={{ mt: 5, textAlign: "right" }}>
        <LqdButton disabled={checkedProviders.length ? !maySubmit : false} onClick={handleSubmit}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
