import axios, { AxiosRequestConfig } from "axios";
import { ReactNode, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store";
import { toastCalled } from "../commonSlice";
import useAuth from "../hooks/useAuth";
import { ToastNotification } from "../types/ToastNotification";
import { getNewAccessToken } from "./apiUtils";

type HttpAxiosProviderProps = {
  children: ReactNode;
};

interface OriginalRequestWithRetry extends AxiosRequestConfig {
  headers: { Authorization: string };
  retry?: boolean;
}

let isRefreshing = false;
const requestQueue: Array<(accessToken: string | null) => void> = [];

const addToRequestQueue = (callback: (accessToken: string | null) => void) => {
  requestQueue.push(callback);
};

const processRequestQueue = (newAccessToken: string | null) => {
  requestQueue.forEach((callback) => callback(newAccessToken));
  requestQueue.length = 0;
};

export default function HttpAxiosProvider(props: HttpAxiosProviderProps) {
  const { children } = props;

  const auth = useAuth();
  const dispatch = useDispatch();

  const { toastNotification } = useAppSelector((state) => state.common);

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest: OriginalRequestWithRetry = error.config;

        if (!error.response) {
          console.log("error: ", error);

          const notification: ToastNotification = {
            message: "",
            type: "info",
          };

          dispatch(toastCalled(notification));

          return Promise.reject(error);
        }

        // Caso o refresh token tenha expirado
        if (error.config.url?.match("refresh-token") && error.response?.status === 400) {
          auth.signOut();
          return Promise.reject("");
        }

        // Caso o access token tenha expirado
        if (error.response?.status === 403) {
          if (originalRequest.headers && !originalRequest.retry) {
            originalRequest.retry = true;

            // Previne múltiplas requests de refresh token
            if (!isRefreshing) {
              isRefreshing = true;

              (async () => {
                try {
                  const newAccessToken = await getNewAccessToken();
                  auth.setAccessToken(newAccessToken);
                  processRequestQueue(newAccessToken);
                  return newAccessToken;
                } catch (refreshError) {
                  console.log("Failed to refresh access token:", refreshError);
                  processRequestQueue(null);
                  auth.signOut();
                  return null;
                } finally {
                  isRefreshing = false;
                }
              })();
            }

            return new Promise((resolve, reject) => {
              addToRequestQueue((newAccessToken) => {
                if (newAccessToken) {
                  originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
                  resolve(axios(originalRequest));
                } else {
                  reject(error);
                }
              });
            });
          }
        }

        // Caso seja um erro interno do servidor
        else if (error.response?.status && error.response.status >= 500) {
          console.log("error: ", error.response);

          const notification: ToastNotification = {
            message: "",
            type: "info",
          };

          dispatch(toastCalled(notification));
        } else {
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [auth.setAccessToken, dispatch, toastNotification]);

  return <div>{children}</div>;
}
