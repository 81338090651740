import { put } from "../../common/utils/apiUtils";
import { AIMessage } from "../types/AnalysisDetails/IA/AIMessage";

/**
 * Endpoint para reagir a uma mensagem da IA.
 * @param action Ação a ser tomada (like, dislike, neutral).
 * @param analysisId ID da conexão.
 * @param messageId ID da mensagem.
 * @returns Mensagem da IA.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const reactToMessage = (action: "like" | "dislike" | "neutral", analysisId: string, messageId: string) =>
  put<AIMessage>(`/ai/message/${action}`, { analysis_id: analysisId, message_id: messageId });
