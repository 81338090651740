import { LqdRightIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { Fragment } from "react";
import { automationFilterDictionary } from "../helpers/automationFilterDictionary";
import { AutomationAction } from "../types/AutomationAction";

type AutomationCardActionsListProps = {
  actions: Array<AutomationAction>;
};

export default function AutomationCardActionsList(props: AutomationCardActionsListProps) {
  const { actions } = props;

  const displayExtra = actions.length > 3;
  const extraAmount = 3 - actions.length;

  return (
    <Stack direction="row" spacing={2}>
      {actions.length ? (
        actions.map((action, index) => {
          const actionLabel = action.event.name
            ? automationFilterDictionary[action.event.name + "__short"]
            : "Não definido";

          return (
            <Fragment key={`${action.event.name}-${index}`}>
              {index ? (
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", mx: 2 }}>
                  <LqdRightIcon />
                </Box>
              ) : null}
              <Box>
                <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
                  Ação
                </LqdTypography>
                <LqdTypography
                  color={action.event.name ? "rgba(79, 85, 98, 1)" : "rgba(127, 135, 152, 1)"}
                  textstyle="c1Caption"
                >
                  {actionLabel.length > 30 ? actionLabel.substring(0, 30).trim() + "..." : actionLabel}
                </LqdTypography>
              </Box>
            </Fragment>
          );
        })
      ) : (
        <Box>
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
            Ação
          </LqdTypography>
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
            Não definido
          </LqdTypography>
        </Box>
      )}
      {displayExtra ? (
        <Box
          sx={{
            backgroundColor: "rgba(127, 135, 152, 1)",
            borderRadius: "32px",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "12px",
            p: 1,
          }}
        >{`+ ${extraAmount} outras`}</Box>
      ) : null}
    </Stack>
  );
}
