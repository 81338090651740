import { post } from "../../common/utils/apiUtils";

type CreatePasswordRequestBody = {
  password: string;
  token: string;
  username: string;
};

const createPassword = (tenantCode: string, body: CreatePasswordRequestBody) =>
  post(`/users/password/create/${tenantCode}`, body);

export default createPassword;
