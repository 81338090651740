import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SBPESimulationResult } from "../../../../../../types/Simulation/SBPE/SBPESimulationResult";
import PDFSBPESimulatorGrayCardHeaderInfoCard from "./PDFSBPESimulatorGrayCardHeaderInfoCard";

type PDFSBPESimulatorGrayCardHeaderContentProps = {
  bank: SBPESimulationResult;
};

const styles = StyleSheet.create({
  sbpeSimulatorApproveLabel: {
    backgroundColor: "rgb(212, 251, 232)",
    borderRadius: "20px",
    color: "rgb(7, 65, 84)",
    fontSize: "14px",
    marginRight: "24px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "7px",
  },
  sbpeSimulatorGrayCardColumnContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "24px",
  },
  sbpeSimulatorGrayCardHeaderColumnsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "37.5%",
  },
  sbpeSimulatorGrayCardHeaderContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "24px",
    width: "100%",
  },
  sbpeSimulatorGrayCardHeaderLabelText: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
  },
  sbpeSimulatorGrayCardHeaderValueText: {
    color: "rgb(33, 36, 42)",
    fontSize: "16px",
  },
  sbpeSimulatorReproveLabel: {
    backgroundColor: "rgb(255, 218, 209)",
    borderRadius: "20px",
    color: "rgb(118, 11, 70)",
    fontSize: "14px",
    marginRight: "24px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "7px",
  },
});

export default function PDFSBPESimulatorGrayCardHeaderContent(props: PDFSBPESimulatorGrayCardHeaderContentProps) {
  const { bank } = props;

  return (
    <View style={styles.sbpeSimulatorGrayCardHeaderContainer}>
      <View style={styles.sbpeSimulatorGrayCardHeaderColumnsContainer}>
        <PDFSBPESimulatorGrayCardHeaderInfoCard
          title="Tabela"
          value={
            bank.amortization_type === "sac"
              ? bank.amortization_type.toUpperCase()
              : capitalizeString(bank.amortization_type)
          }
        />
        <PDFSBPESimulatorGrayCardHeaderInfoCard title="Correção" value={bank.bank_rate.adjustment.toUpperCase()} />
        <PDFSBPESimulatorGrayCardHeaderInfoCard title="Prazo" value={bank.available_payment_term} />
      </View>
      {bank.desired_loan_approval === true ? (
        <Text style={styles.sbpeSimulatorApproveLabel}>Aprovaria</Text>
      ) : (
        <Text style={styles.sbpeSimulatorReproveLabel}>Reprovaria</Text>
      )}
    </View>
  );
}
