import { LqdTypography } from "@/liquid-components/src";
import LiquidAILogo from "@common/components/LiquidAILogo";
import { Box, Stack } from "@mui/material";
import expiredImg from "../assets/expired_link_img.svg";
import SignatureHeader from "./SignatureHeader";

export default function ExpiredLink() {
  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 60px)",
          justifyContent: "center",
          marginLeft: "48px",
          marginRight: "60px",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Stack width="530px">
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 4 }} textstyle="h3Headline">
              Ops, esse link expirou!
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ fontWeight: "bold", mb: 4 }} textstyle="p1Paragraph">
              Parece que a solicitação das assinaturas para este documento foi cancelada.
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p1Paragraph">
              Precisa de ajuda? Envie um e-mail para suporte@askliquid.com.
            </LqdTypography>
          </Stack>
          <LiquidAILogo color="rgba(127, 135, 152, 1)" width={150} />
        </Box>
        <img alt="expired link" src={expiredImg} style={{ marginLeft: "16px" }} />
      </Box>
    </>
  );
}
