const typography = {
  body1: {
    fontSize: "1rem",
    fontWeight: "normal",
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: "normal",
  },
  fontFamily: ["Poppins", "Roboto", "Helvetica Neue", "Arial", "sans-serif"].join(","),
  h1: {
    fontSize: "3rem",
    fontWeight: "normal",
  },
  h2: {
    fontSize: "2.5rem",
    fontWeight: "normal",
  },
  h3: {
    fontSize: "2rem",
    fontWeight: "normal",
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: "normal",
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: "normal",
  },
  l1: {
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
  l2: {
    fontSize: "0.75rem",
    fontWeight: "normal",
  },
  overline: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    textTransform: "none",
  },
};

export default typography;
