import { getAnalysisDetailsSteps } from "../api/GetAnalysisDetailsSteps";
import { AnalysisStepsResponseRaw } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";

export const delayedGetSteps = async (
  analysisId: string,
  delayMs: number = 0
): Promise<AnalysisStepsResponseRaw | null> => {
  const retry = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return delayedGetSteps(analysisId, delayMs);
  };

  try {
    await new Promise((resolve) => setTimeout(resolve, delayMs));

    const res = await getAnalysisDetailsSteps(analysisId);
    return res.data;
  } catch (error) {
    return retry();
  }
};
