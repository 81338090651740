import { LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import { ReactNode } from "react";

type AutomationChipProps = {
  color: string;
  icon: ReactNode;
  text: string;
};

export default function AutomationChip(props: AutomationChipProps) {
  const { color, icon, text } = props;

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={0.5}
      sx={{
        ":not(:first-of-type)": { ml: 2 },
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "32px",
        display: "inline-flex",
        height: "20px",
        px: 2,
        py: 1,
      }}
    >
      {icon}
      <LqdTypography
        sx={{ color: color, display: "inline-block", fontSize: "14px", lineHeight: "20px" }}
        textstyle="c1Caption"
      >
        {text}
      </LqdTypography>
    </Stack>
  );
}
