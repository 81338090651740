import { LqdButton, LqdInput, LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { simulatorBuilderChanged } from "../simulatorBuilderSlice";

const CHAR_LIMIT = 25;

type SimulatorFormStepNameProps = {
  editing?: boolean;
  onConfirm: () => void;
};

export default function SimulatorFormStepName(props: SimulatorFormStepNameProps) {
  const { editing, onConfirm } = props;

  const dispatch = useAppDispatch();

  const { duplicating, simulators } = useAppSelector((state) => state.simulatorBuilder);
  const { simulator } = useSimulatorBuilder();

  const [name, setName] = useState<string>(duplicating ? `Cópia de ${simulator.name}` : simulator.name);

  const currentSimulatorName = simulator.name;
  const validSimulatorNames = Object.values(simulators)
    .filter((simulator) => simulator.name !== currentSimulatorName)
    .map((simulator) => simulator.name);
  const nameAlreadyExists = validSimulatorNames.some((simulatorName) => simulatorName === name);

  const showNameLengthAlert = name.length > CHAR_LIMIT;
  const maySubmit = name.length >= 3 && !showNameLengthAlert && !nameAlreadyExists;

  const handleUpdateName = () => {
    dispatch(simulatorBuilderChanged({ key: "name", value: name }));
    onConfirm();
  };

  return (
    <Stack
      justifyContent="center"
      sx={{ height: "100%", margin: "auto", maxWidth: "874px", px: { sm: 0, xs: 3 }, width: "100%" }}
    >
      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2 }} textstyle="h5Headline">
        Qual o nome do {editing ? "novo " : ""} simulador?
      </LqdTypography>
      <LqdInput
        alertMessage={nameAlreadyExists ? "Já existe um simulador com este nome." : ""}
        characterLimit={CHAR_LIMIT}
        maskType="string"
        onConfirm={handleUpdateName}
        onConfirmValidation={maySubmit}
        placeholderText="Digite o nome do simulador"
        setValue={setName}
        showCharacterLimit
        value={name}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={3} sx={{ mt: 3 }}>
        {editing ? (
          <LqdButton disabled={!maySubmit} onClick={handleUpdateName} sx={{ float: "right" }}>
            Atualizar
          </LqdButton>
        ) : (
          <LqdButton disabled={!maySubmit} onClick={handleUpdateName} sx={{ float: "right" }}>
            Continuar
          </LqdButton>
        )}
      </Stack>
    </Stack>
  );
}
