import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterSix() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        6. ACEITE
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.1. O Usuário Decisor, enquanto contratante do uso do Liquid, concorda que, ao realizar o Aceite, firmará um
        contrato com a Liquid, declarando aceitar a integralidade dos presentes Termos.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.2. O Usuário Decisor garante que utilizará o Liquid apenas em seu próprio nome ou em nome de sua empresa
        descrita na Proposta Comercial, sendo que, em caso de utilização do Cadastro para empresas afiliadas e/ou
        terceiros (conforme definidos no item 6.2.1 abaixo), o Usuário Decisor será integralmente responsável, perante a
        Liquid pela aplicação e observação destes Termos e da legislação aplicável, por toda e qualquer interação por
        eles realizada.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        6.2.1. Para fins desses Termos, serão consideradas “Empresas Afiliadas”: (a) as sociedades que controlem o
        Usuário Decisor, direta ou indiretamente; (b) as sociedades controladas, direta ou indiretamente pelo Usuário
        Decisor; (c) as sociedades que são controladas, direta ou indiretamente, por uma sociedade que controla o
        Usuário Decisor; (d) qualquer outra sociedade sob controle comum ou compartilhado, direta ou indiretamente, pelo
        Usuário Decisor ou por seu controlador; e/ou (e) Sociedades de Propósito Específico (“SPE’s”) relacionadas ao
        Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.3. O Usuário Decisor reconhece ter lido e compreendido integralmente os termos e condições previstos nestes
        Termos, concordando em se vincular a todas as suas condições.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        6.4. A Liquid poderá, a qualquer tempo e a seu único e exclusivo critério, alterar os presentes Termos, mediante
        prévia comunicação ao Usuário Decisor.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        6.4.1. Eventuais alterações nos Termos terão validade a partir da data indicada no documento ou outra indicada
        pela Liquid, estando certo que poderão haver alterações que incidam imediata ou retroativamente, caso, por sua
        natureza, não possam ser prorrogadas (exemplificadamente, mas não se limitando a alterações operacionais
        necessárias ao bom funcionamento do Liquid e mudanças legislativas que impactem nos Termos), o que não
        significará inadimplemento pela Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        6.4.2. Os Termos ficarão disponíveis no site da Liquid, por meio do link
        https://app.liquidpass.co/terms-and-conditions, podendo ser consultados a qualquer momento pelo Usuário Decisor.
      </LqdTypography>
    </>
  );
}
