import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, Stack, TextField } from "@mui/material";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { simulatorBuilderChanged } from "../../../simulatorBuilderSlice";
import { SimulatorFunction } from "../../../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";

type SimulatorFunctionBlocksFormNameProps = {
  blockId: number;
  blockName: string;
  blocks: Array<SimulatorFunctionBlock>;
  functionId?: number;
  functionKey?: string;
  isDuplicating?: boolean;
  setBlockId: (blockId: number) => void;
  setBlockName: (blockName: string) => void;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setCurrentView: (view: SimulatorFunctionBlocksViews) => void;
  setIsDuplicating: Dispatch<SetStateAction<boolean>>;
  setOpenModal?: (
    params: { functionId: number; functionKey: string; view: SimulatorFunctionBlocksViews } | null
  ) => void;
};

const BLOCK_NAME_MIN_LENGTH = 3;
const BLOCK_NAME_MAX_LENGTH = 35;

export default function SimulatorFunctionBlocksFormName(props: SimulatorFunctionBlocksFormNameProps) {
  const {
    blockId,
    blockName,
    blocks,
    functionId,
    functionKey,
    isDuplicating,
    setBlockId,
    setBlockName,
    setBlocks,
    setCurrentView,
    setIsDuplicating,
    setOpenModal,
  } = props;

  const dispatch = useAppDispatch();

  const blockNameTooShort = blockName.length < BLOCK_NAME_MIN_LENGTH;
  const blockNameTooBig = blockName.length > BLOCK_NAME_MAX_LENGTH;
  const blockNameAlreadyExists = blocks.some((block) => block.name === blockName) && functionId;

  const enableSaveButton = !blockNameTooShort && !blockNameTooBig && !blockNameAlreadyExists;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  // Handle if is editing a block or creating a new one
  const blockToBeEdited = blocks.find((block) => block.block_id === blockId);

  const handleCreateOrEditBlock = () => {
    let updatedBlocks = [];
    const lastBlockId = blocks[blocks.length - 1]?.block_id;

    if (isDuplicating) {
      const oldBlock = simulator.blocks.find((currentBlock) => currentBlock.block_id === blockId);
      if (!oldBlock || !blocks) return;

      const updatedBlocks: Array<SimulatorFunctionBlock> = [
        ...blocks,
        { ...oldBlock, block_id: blocks.length, name: blockName },
      ];

      setBlocks(updatedBlocks);
      setBlockId(blocks.length);
      dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
      setCurrentView("blockFunctions");
      setIsDuplicating(false);
      return;
    }

    const emptyFunction: SimulatorFunction = {
      function_name: "",
      index: 1,
      param_type: "",
      result: {},
      type: "",
      variables: {},
    };

    if (blockToBeEdited && !functionId) {
      updatedBlocks = blocks.map((block) => {
        if (block.block_id === blockId) {
          return {
            ...block,
            name: blockName,
          };
        }
        return block;
      });
    } else {
      const newId = lastBlockId !== undefined ? lastBlockId + 1 : 0;
      updatedBlocks = [
        ...blocks,
        {
          block_id: newId,
          functions: [emptyFunction],
          name: blockName,
        },
      ];
      setBlockId(newId);
    }

    setBlocks(updatedBlocks);
    dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));

    if (functionId && functionKey) {
      // Ao criar um novo bloco diretamente em um escopo de função, precisamos atualizar a função para apontar para o novo bloco.
      // Para isso, criamos uma nova função igual à anterior, mas com o block_id (dentro da chave variables) atualizado.
      const blockToEdit = updatedBlocks.find((block) => block.block_id === blockId)!;
      const functionToEdit = blockToEdit.functions.find((funct) => funct.index === functionId)!;
      const updatedFunction = {
        ...functionToEdit,
        variables: { ...functionToEdit.variables, [functionKey]: blocks[blocks.length - 1]?.block_id + 1 },
      };

      const updatedFunctions = blockToEdit!.functions.map((funct) =>
        funct.index === functionId ? updatedFunction : funct
      );

      const updatedBlock = {
        ...blockToEdit!,
        functions: updatedFunctions as Array<SimulatorFunction>,
      };

      const updatedBlocksNew = updatedBlocks.map((block) => (block.block_id === blockId ? updatedBlock : block));

      setBlocks(updatedBlocksNew);
      dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocksNew }));

      setOpenModal ? setOpenModal(null) : null;
    } else {
      setCurrentView("blockFunctions");
    }
  };

  return (
    <Box sx={{ width: "874px" }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
        {!functionId ? "Qual é o novo nome do bloco de funções?" : "Qual é o nome do novo bloco de funções?"}
      </LqdTypography>
      <>
        <TextField
          autoFocus
          inputProps={{
            sx: {
              color: "rgba(33, 36, 42, 1)",
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "28px",
              marginBottom: "12px",
              padding: 0,
            },
          }}
          onChange={(event) => setBlockName(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && enableSaveButton) {
              handleCreateOrEditBlock();
            }
          }}
          placeholder="Digite o nome do bloco aqui"
          sx={{
            "& .MuiInput-underline:after": { borderBottomColor: "rgba(33, 36, 42, 1)" },
            "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
            ".MuiInputBase-root": { boxSizing: "border-box", my: 1.5, pl: 2 },
            width: "100%",
          }}
          value={blockName}
          variant="standard"
        />
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1, width: "100%" }}>
          <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
            {blockNameTooBig ? "Você ultrapassou o limite de 35 caracteres, abrevie para continuar." : null}
            {blockNameAlreadyExists ? "Já existe um bloco com este nome. Por favor, escolha outro nome." : null}
          </LqdTypography>
          <LqdTypography
            sx={{
              color: blockNameTooBig ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)",
              display: "block",
              textAlign: "right",
            }}
            textstyle="p2Paragraph"
          >
            {`${blockName.length}/${BLOCK_NAME_MAX_LENGTH} caracteres`}
          </LqdTypography>
        </Stack>
        <LqdButton disabled={!enableSaveButton} onClick={handleCreateOrEditBlock} sx={{ float: "right", mt: 3 }}>
          Avançar
        </LqdButton>
      </>
    </Box>
  );
}
