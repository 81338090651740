import {
  LqdCloseIcon,
  LqdCopyIcon,
  LqdDuplicateIcon,
  LqdIconButton,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { useCopyToClipboard } from "@common/hooks/useCopyToClipboard";
import { Box, Stack } from "@mui/material";
import { Node } from "react-flow-renderer/nocss";
import { useAppSelector } from "../../../../store";
import { ProviderNodeData } from "../../types/NodeData";
import { getNodeTitle } from "../../utils/nodeContents";

type ProviderHeaderProps = {
  activeNode: Node<ProviderNodeData>;
  onClose: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  selectedSimulationId: null | string;
};

export default function ProviderHeader(props: ProviderHeaderProps) {
  const { activeNode, onClose, onDelete, onDuplicate, selectedSimulationId } = props;

  const copyValueToClipboard = useCopyToClipboard();

  const { simulators } = useAppSelector((state) => state.simulatorBuilder);

  return (
    <Box sx={{ backgroundColor: "rgba(249, 249, 250, 1)", minWidth: "380px" }}>
      <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", mr: 3 }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
          {selectedSimulationId ? simulators[selectedSimulationId].name : getNodeTitle(activeNode.data)}
        </LqdTypography>
        <LqdIconButton className="lqd-config-sidebar__close-button" onClick={onClose} round="true" type="ghostPrimary">
          <LqdCloseIcon color="rgba(127, 135, 152, 1)" />
        </LqdIconButton>
      </Box>
      {selectedSimulationId ? (
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
          sx={{
            backgroundColor: "rgba(240, 241, 243, 1)",
            borderRadius: "32px",
            display: "inline-flex",
            height: "32px",
            mt: 1,
            px: 2,
          }}
        >
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
            {selectedSimulationId.slice(0, 6)}...{selectedSimulationId.slice(-3)}
          </LqdTypography>
          <Box onClick={() => copyValueToClipboard(selectedSimulationId, "ID da simulação")} sx={{ cursor: "pointer" }}>
            <LqdCopyIcon color="rgba(127, 135, 152, 1)" size={18} />
          </Box>
        </Stack>
      ) : null}
      <Box sx={{ my: 2 }}>
        <LqdIconButton className="lqd-duplicate-node-button" onClick={onDuplicate} round="true" type="ghostPrimary">
          <LqdDuplicateIcon color="rgba(127, 135, 152, 1)" />
        </LqdIconButton>
        <LqdIconButton
          className="lqd-delete-node-button"
          onClick={onDelete}
          round="true"
          sx={{ ml: 1 }}
          type="ghostPrimary"
        >
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" />
        </LqdIconButton>
      </Box>
    </Box>
  );
}
