import { AxiosPromise } from "axios";
import { post } from "../../common/utils/apiUtils";
import { SignInResponse } from "../types/SignInResponse";
import SignInRequest from "../types/SignInRequest";

/**
 * SuperAdmin special login method.
 */
export const adminSignIn = (form: SignInRequest, tenantCode: string): AxiosPromise<SignInResponse> => {
  return post("/auth/adminlogin", { ...form, tenant_code: tenantCode });
};
