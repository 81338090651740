import { GroupResponse } from "../types/GroupResponse";
import { GroupResponseRaw } from "../types/GroupResponseRaw";

export const formatRawGroupSuperAdmin = (groupRaw: GroupResponseRaw): GroupResponse => ({
  code: groupRaw.code,
  createdAt: groupRaw.created_at * 1000,
  createdBy: groupRaw.created_by,
  isActive: groupRaw.is_active,
  lastUpdate: groupRaw.last_update * 1000,
  name: groupRaw.name,
  products: groupRaw.products,
  tenantCode: groupRaw.tenant_code,
  users: groupRaw.users,
});
