import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, TextField } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useAppDispatch } from "../../../store";
import useWindowSize from "../../common/hooks/useWindowSize";
import { validateEmail } from "../../common/utils/validateEmail";
import { createUserModalStepNext } from "../usersSlice";

type UserFormStep2Props = {
  email: string;
  setEmail: (value: string) => void;
};

export default function UserFormStep2(props: UserFormStep2Props) {
  const { email, setEmail } = props;
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = () => {
    const emailIsValid = validateEmail(email);
    if (!emailIsValid) {
      setEmailError(true);
    } else {
      dispatch(createUserModalStepNext());
    }
  };

  const maySubmit = email.length > 6;

  return (
    <Box
      sx={{
        height: "240px",
        mt: 20,
        pt: { lg: 7, md: 4 },
        px: 5,
        width: { lg: "60vw", md: "80vw" },
      }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        {t("E-mail")}
      </LqdTypography>
      <TextField
        autoFocus
        error={emailError}
        helperText={
          emailError ? (
            <LqdTypography color="error" sx={{ width: "fit-content" }} textstyle="c1Caption">
              Digite um formato válido, como: exemplo@email.com.
            </LqdTypography>
          ) : null
        }
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={(event) => {
          if (emailError) setEmailError(false);
          setEmail(event.target.value.toLowerCase());
        }}
        onKeyUp={(key) => key.key === "Enter" && handleSubmit()}
        placeholder="Digite"
        sx={{
          "& .MuiInput-underline:after": { borderBottomColor: "rgba(33, 36, 42, 1)" },
          "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
          ".MuiFormHelperText-root": {
            position: width === "xs" ? "relative" : "absolute",
            top: width === "xs" ? "0px" : "58px",
            width: "fit-content",
          },
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          width: "100%",
        }}
        value={email}
        variant="standard"
      />
      <Box sx={{ mt: 3, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
