import { AnalysisPendency } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendency";
import { put } from "../../common/utils/apiUtils";

/**
 * Endpoint responsável por atualizar os dados das pendências de uma conexão.
 * @param analysisId ID da conexão.
 * @param pendencyData Lista de pares de ID da pendência e valor da pendência.
 * @returns Mensagem de sucesso.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const updatePendencies = (
  analysisId: string,
  pendencyData: Array<{ pendency_id: string; value: boolean | string }>
) => put<Array<AnalysisPendency>>(`/pendencies/${analysisId}`, pendencyData);
