import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { GroupResponseRaw } from "../types/GroupResponseRaw";

// List Groups By User List (Superadmin Endpoint)
export const adminListGroupsByUsers = (
  emailList: Array<string>,
  tenantCode: string
): AxiosPromise<Array<GroupResponseRaw>> => {
  return get(`/admin/group/user?username_list=${emailList}&tenant_code=${tenantCode}`);
};
