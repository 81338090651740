import { LqdButton, LqdCheckboxOffIcon, LqdCheckboxOnIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  createTemplateModalStepGoTo,
  createTemplateModalStepNext,
  editTemplateModalStepGoTo,
} from "../../../adminSlice";

type TemplateFormStep3Props = {
  checkedSegments: Array<string>;
  setCheckedSegments: (segments: Array<string>) => void;
};

export default function TemplateFormStep3(props: TemplateFormStep3Props) {
  const { checkedSegments, setCheckedSegments } = props;

  const dispatch = useAppDispatch();
  const { createTemplate, segments, templateToEdit } = useAppSelector((state) => state.admin);
  // Envia as modificações deste step para o parent, quando clicar no botão de avançar
  const handleSubmit = () => {
    dispatch(createTemplate ? createTemplateModalStepNext() : editTemplateModalStepGoTo(4));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedSegments([...checkedSegments, event.target.name]);
    } else {
      setCheckedSegments(checkedSegments.filter((segment) => segment !== event.target.name));
    }
  };

  return (
    <Box sx={{ px: templateToEdit ? 20 : 10, width: { lg: templateToEdit ? "100vw" : "40vw", md: "60vw" } }}>
      <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mb: 5 }} textstyle="h4Headline">
        Quais são os segmentos?
      </LqdTypography>
      <Box sx={{ display: "flex", flexDirection: "column", height: "275px", overflow: "auto" }}>
        {Object.values(segments).map((segment) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedSegments.includes(segment.segmentId)}
                checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" />}
                icon={<LqdCheckboxOffIcon color="rgba(33, 36, 42, 1)" />}
                name={segment.segmentId}
                onChange={(event) => handleCheckboxChange(event)}
              />
            }
            key={segment.segmentId}
            label={segment.name}
            sx={{
              ".MuiTypography-root": { color: "rgba(33, 36, 42, 1)" },
              borderBottom: "1px solid rgba(212, 215, 220, 1)",
              p: 3,
            }}
          />
        ))}
      </Box>
      <Box sx={{ mt: 7.5, textAlign: "right" }}>
        {!templateToEdit ? (
          <LqdButton
            onClick={() => dispatch(createTemplate ? createTemplateModalStepGoTo(5) : editTemplateModalStepGoTo(5))}
            sx={{ mr: 3 }}
            type="outlineTertiary"
          >
            Criar um novo segmento
          </LqdButton>
        ) : null}
        <LqdButton disabled={!checkedSegments.length} onClick={handleSubmit}>
          Avançar
        </LqdButton>
      </Box>
    </Box>
  );
}
