import { Product } from "../types/Product";
import ProductRaw from "../types/ProductRaw";

export const transformProduct = (
  product: Product
): Omit<ProductRaw, "created_at" | "last_update" | "product_id" | "creation"> => ({
  country: product.country,
  currency: product.currency,
  image: product.picture,
  is_active: product.isActive,
  name: product.name,
  segment: product.segment,
  target_user: product.targetUser || [],
  tenant_code: product.tenantCode,
});

export const formatRawProduct = (productRaw: ProductRaw): Product => ({
  analysisCount: productRaw.analysis_count,
  contactInformation: productRaw.contact_information,
  country: productRaw.country,
  creation: productRaw.creation,
  currency: productRaw.currency,
  id: productRaw.product_id,
  isActive: productRaw.is_active,
  name: productRaw.name,
  picture: productRaw.image,
  segment: productRaw.segment,
  targetUser: productRaw.target_user || [],
  tenantCode: productRaw.tenant_code,
});

export const formatProductToRaw = (
  product: Product
): Omit<ProductRaw, "created_at" | "last_update" | "product_id" | "creation"> => ({
  analysis_count: product.analysisCount,
  contact_information: product.contactInformation,
  country: product.country,
  currency: product.currency,
  image: product.picture,
  is_active: product.isActive,
  name: product.name,
  segment: product.segment,
  target_user: product.targetUser || [],
  tenant_code: product.tenantCode,
});
