import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFSBPESimulatorBankResultCardProps = {
  title: string;
  value: string;
};

const styles = StyleSheet.create({
  sbpeSimulatorGrayCardColumnContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "24px",
  },
  sbpeSimulatorGrayCardHeaderLabelText: {
    color: "rgb(155, 162, 175)",
    fontSize: "12px",
  },
  sbpeSimulatorGrayCardHeaderValueText: {
    color: "rgb(33, 36, 42)",
    fontSize: "16px",
  },
});

export default function PDFSBPESimulatorBankResultCard(props: PDFSBPESimulatorBankResultCardProps) {
  const { title, value } = props;

  return (
    <View style={styles.sbpeSimulatorGrayCardColumnContainer}>
      <Text style={styles.sbpeSimulatorGrayCardHeaderLabelText}>{title}</Text>
      <Text style={styles.sbpeSimulatorGrayCardHeaderValueText}>{value}</Text>
    </View>
  );
}
