import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box } from "@mui/material";
import { useState } from "react";
import CreatePasswordForm from "../../common/components/CreatePasswordForm";
import recreatePassword from "../api/RecreatePassword";

type RecreatePasswordFormProps = {
  token: string;
};

const INITIAL_STEP = 1;

export default function RecreatePasswordForm(props: RecreatePasswordFormProps) {
  const { token } = props;

  const handleLiquidErrors = useErrorHandler();

  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(false);

  const handleRecreatePassword = async (password: string) => {
    setLoading(true);
    try {
      await recreatePassword({ password, token });
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
      setCurrentStep(2);
    }
  };

  const redirectToSignIn = () => {
    window.location.href = "/signin";
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <CreatePasswordForm loading={loading} onCreatePassword={handleRecreatePassword} />;
      case 2:
        return (
          <>
            <LqdTypography sx={{ mb: 1 }} textstyle="h3Headline">
              Tudo certo!
            </LqdTypography>
            <LqdTypography sx={{ mb: 7 }} textstyle="p2Paragraph">
              Sua senha foi redefinida com sucesso.
            </LqdTypography>
            <LqdButton onClick={redirectToSignIn}>Entrar</LqdButton>
          </>
        );
    }
  };

  return (
    <Box
      id="begin"
      sx={{ mx: { sm: 0, xs: 3 }, pb: 4, pt: { lg: "220px", xs: "110px" }, px: { lg: "20%", md: "10%", sm: 6, xs: 3 } }}
    >
      {renderCurrentStep()}
    </Box>
  );
}
