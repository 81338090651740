import { LqdButton } from "@/liquid-components/src";
import { Box } from "@mui/material";

type SimulatorParametrizationButtonProps = {
  onClick: () => void;
  shouldDisable: boolean;
  title: JSX.Element | string;
};

export default function SimulatorParametrizationButton(props: SimulatorParametrizationButtonProps) {
  const { onClick, shouldDisable, title } = props;

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "auto", mt: 3, width: "90%" }}>
      <LqdButton disabled={shouldDisable} onClick={onClick}>
        {title}
      </LqdButton>
    </Box>
  );
}
