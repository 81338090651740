import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    marginRight: "18px",
    maxHeight: "40px",
    maxWidth: "40px",
  },
});

export default function PDFTrustpadModerateSVG() {
  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 45 45">
        <Path
          d="M20.054 23H16.5C15.395 23 14.5 22.105 14.5 21V16C14.5 14.895 15.395 14 16.5 14H32.5C33.605 14 34.5 14.895 34.5 16V21C34.5 22.105 33.605 23 32.5 23H23.054"
          stroke="rgb(255, 181, 71)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Path
          d="M23.0542 25V20.5C23.0542 19.672 22.3822 19 21.5542 19V19C20.7262 19 20.0542 19.672 20.0542 20.5V23V28L19.1532 27.099C18.5462 26.492 17.5622 26.492 16.9552 27.099V27.099C16.4312 27.623 16.3502 28.444 16.7612 29.06L19.4602 33.109C19.8312 33.666 20.4562 34 21.1252 34H26.4132C27.4222 34 28.2722 33.249 28.3982 32.248L28.9402 27.911C29.0732 26.844 28.3392 25.862 27.2782 25.689L23.0542 25Z"
          fillRule="evenodd"
          stroke="rgb(255, 181, 71)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Path d="M31 17.5L28.5 20L27 18.5" stroke="rgb(255, 181, 71)" strokeLineCap="round" strokeWidth="1.2" />
        <Circle cx="23.8" cy="23.8" r="20" stroke="rgb(255, 181, 71)" />
      </Svg>
    </View>
  );
}
