import { get } from "../../common/utils/apiUtils";
import { SCRTokenData } from "../types/AnalysisDetails/OpenCredit/SCRTokenData";

/**
 * Endpoint que busca os dados do token do SCR (Open Credit).
 * @param token Token para acesso ao endpoint (SCR).
 * @returns Dados do token.
 * @remarks Endpoint PÚBLICO.
 */
export const getSCRTokenData = (token: string) => get<SCRTokenData>(`/open-credit/agreement/${token}`);
