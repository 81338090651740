import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFAllotmentAndBasicSimulatorInfoCardProps = {
  title: string;
  value: string;
};

const styles = StyleSheet.create({
  allotmentSimulatorInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "188px",
    width: "245px",
  },
  allotmentSimulatorInfoCardRealSign: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    paddingLeft: "24px",
    paddingTop: "16px",
  },
  allotmentSimulatorInfoCardTitle: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    height: "50px",
    marginTop: "30px",
    paddingLeft: "24px",
  },
  allotmentSimulatorInfoCardValue: {
    color: "rgb(7, 65, 84)",
    fontSize: "28px",
    paddingLeft: "24px",
  },
});

export default function PDFAllotmentAndBasicSimulatorInfoCard(props: PDFAllotmentAndBasicSimulatorInfoCardProps) {
  const { title, value } = props;

  return (
    <View style={styles.allotmentSimulatorInfoCard}>
      <Text style={styles.allotmentSimulatorInfoCardTitle}>{title}</Text>
      <Text style={styles.allotmentSimulatorInfoCardRealSign}>R$</Text>
      <Text style={styles.allotmentSimulatorInfoCardValue}>{value}</Text>
    </View>
  );
}
