import { Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { SimulatorFunctionBlocksViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksViews";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch } from "../../../../../store";
import { ObjectOf } from "../../../../common/types/ObjectOf";
import { simulatorBuilderChanged } from "../../../simulatorBuilderSlice";
import { SimulatorFunction } from "../../../types/SimulatorFunction";
import { SimulatorFunctionBlock } from "../../../types/SimulatorFunctionBlock";
import SimulatorFunctionBlocksFormFunctionList from "./SimulatorFunctionBlocksFormFunctionList";
import SimulatorFunctionBlocksFormName from "./SimulatorFunctionBlocksFormName";

type SimulatorFunctionBlocksFormProps = {
  blockId: number;
  blockName: string;
  blocks: Array<SimulatorFunctionBlock>;
  currentView: SimulatorFunctionBlocksViews;
  isDuplicating: boolean;
  onClose: (saving: boolean) => void;
  setBlockId: (id: number) => void;
  setBlockName: (name: string) => void;
  setBlocks: (blocks: Array<SimulatorFunctionBlock>) => void;
  setCurrentView: (view: SimulatorFunctionBlocksViews) => void;
  setIsDuplicating: Dispatch<SetStateAction<boolean>>;
};

export default function SimulatorFunctionBlocksForm(props: SimulatorFunctionBlocksFormProps) {
  const {
    blockId,
    blockName,
    blocks,
    currentView,
    isDuplicating,
    onClose,
    setBlockId,
    setBlockName,
    setBlocks,
    setCurrentView,
    setIsDuplicating,
  } = props;

  const dispatch = useAppDispatch();

  const { simulator } = useSimulatorBuilder();

  const currentBlock = blocks.find((block) => block.block_id === blockId)!;
  const blockFunctions = currentBlock?.functions;

  /** Atualiza as funções do bloco, disparando a action do Redux.
   * @param updatedFunctions Funções atualizadas do bloco
   */
  const updateBlocksAndDispatch = (updatedFunctions: Array<SimulatorFunction>) => {
    const blockWithUpdatedFunctions = {
      ...currentBlock,
      functions: updatedFunctions,
    };

    const updatedBlocks = blocks.map((block) => (block.block_id === blockId ? blockWithUpdatedFunctions : block));

    dispatch(simulatorBuilderChanged({ key: "blocks", value: updatedBlocks }));
    setBlocks(updatedBlocks);
  };

  /** Adiciona uma nova função ao bloco. */
  const addFunction = () => {
    const newFunction = {
      function_name: "",
      index: blockFunctions[blockFunctions.length - 1].index + 1,
      param_type: "",
      result: {},
      type: "",
      variables: {},
    };

    const updatedFunctions = [...currentBlock.functions, newFunction];
    updateBlocksAndDispatch(updatedFunctions);
  };

  /** Remove uma função do bloco, e também remove qualquer variável que tenha sido criada a partir do resultado da função. */
  const deleteFunction = (index: number) => {
    const functionToBeRemoved = blockFunctions.find((func) => func.index === index)!;
    const functionResults = Object.values(functionToBeRemoved.result);
    const filteredVariables = simulator.variables.filter((variable) => !functionResults.includes(variable.name));

    dispatch(simulatorBuilderChanged({ key: "variables", value: filteredVariables }));

    const updatedFunctions = blockFunctions.filter((func) => func.index !== index);
    updateBlocksAndDispatch(updatedFunctions);
  };

  /** Duplica uma função do bloco. */
  const duplicateFunction = (index: number) => {
    const functionToDuplicate = blockFunctions.find((func) => func.index === index) as SimulatorFunction;
    const newFunction = { ...functionToDuplicate, index: blockFunctions[blockFunctions.length - 1].index + 1 };

    const updatedFunctions = [...blockFunctions, newFunction];
    updateBlocksAndDispatch(updatedFunctions);
  };

  const renderCurrentView: ObjectOf<JSX.Element> = {
    blockFunctions: (
      <SimulatorFunctionBlocksFormFunctionList
        blockId={blockId}
        blockName={blockName}
        blocks={blocks}
        functionMethods={{ add: addFunction, delete: deleteFunction, duplicate: duplicateFunction }}
        isDuplicating={isDuplicating}
        onClose={onClose}
        setBlockId={setBlockId}
        setBlockName={setBlockName}
        setBlocks={setBlocks}
        setCurrentFunctionBlocksView={setCurrentView}
        setCurrentView={setCurrentView}
        setIsDuplicating={setIsDuplicating}
      />
    ),
    blockName: (
      <SimulatorFunctionBlocksFormName
        blockId={blockId}
        blockName={blockName}
        blocks={blocks}
        isDuplicating={isDuplicating}
        setBlockId={setBlockId}
        setBlockName={setBlockName}
        setBlocks={setBlocks}
        setCurrentView={setCurrentView}
        setIsDuplicating={setIsDuplicating}
      />
    ),
  };

  return (
    <Stack sx={{ alignItems: "center", justifyContent: "center", minHeight: "100%" }}>
      {renderCurrentView[currentView]}
    </Stack>
  );
}
