import { JWTToken } from "@common/types/JWTToken";
import jwtDecode from "jwt-decode";
import useWebSocket from "react-use-websocket";
import { useAppDispatch, useAppSelector } from "../../../store";
import { AnalysisHasPendencyWsMessage } from "../../analysisDetails/types/Websocket/AnalysisHasPendencyWsMessage";
import { AnalysisRunWsMessage } from "../../analysisDetails/types/Websocket/AnalysisRunWsMessage";
import { AnalysisStatusArchiveWsMessage } from "../../analysisDetails/types/Websocket/AnalysisStatusArchiveWsMessage";
import { AnalysisStatusChangeWsMessage } from "../../analysisDetails/types/Websocket/AnalysisStatusChangeWsMessage";
import { AnalysisStatusDetailWsMessage } from "../../analysisDetails/types/Websocket/AnalysisStatusDetailWsMessage";
import { AnalysisStatusFinishWsMessage } from "../../analysisDetails/types/Websocket/AnalysisStatusFinishWsMessage";
import { AnalysisStatusUpdateWsMessage } from "../../analysisDetails/types/Websocket/AnalysisStatusUpdateWsMessage";
import { onAnalysisAdded, onAnalysisDeleted, onAnalysisUpdateMonthCount } from "../../board/boardSlice";
import { Analysis } from "../../board/types/Analysis";
import { formatRawAnalysis } from "../../board/utils/formatRawAnalysis";
import { getAnalysisColumn } from "../../board/utils/getAnalysisColumn";
import { WebsocketEvent } from "../types/WebsocketEvent";
import { WS_URL, env, getNewAccessToken } from "../utils/apiUtils";

export const useSocket = (accessToken: string, caller: string) => {
  const dispatch = useAppDispatch();

  const { lastJsonMessage, sendJsonMessage } = useWebSocket<WebsocketEvent | null>(
    WS_URL[env] + accessToken + "&caller=" + caller,
    {
      shouldReconnect: () => {
        const accessToken = localStorage.getItem("access_token");
        const tokenData = jwtDecode<JWTToken>(accessToken!);

        const timeNow = new Date().getTime() / 1000;
        const timeDiff = tokenData.exp - timeNow;

        if (timeDiff > 0) {
          getNewAccessToken();
          return true;
        }

        return false;
      },
    }
  );

  const { completed, moderation, onboarding } = useAppSelector((state) => state.board.columns);
  const allAnalysis = { ...completed.analysisList, ...moderation.analysisList, ...onboarding.analysisList };

  // Processa uma mensagem recebida via websocket
  const dispatchWsMessage = (wsMessage: WebsocketEvent) => {
    let analysis: Analysis | null = null;

    switch (wsMessage.action) {
      case "analysis-run": {
        const analysisWsMessage = wsMessage as AnalysisRunWsMessage;
        const analysisRaw = analysisWsMessage.payload;
        analysis = formatRawAnalysis(analysisRaw);

        const column = getAnalysisColumn(analysis.status);
        dispatch(onAnalysisUpdateMonthCount({ analysis, column }));
        break;
      }
      case "analysis-status-change": {
        const analysisWsMessage = wsMessage as AnalysisStatusChangeWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          lastChangeBy: analysisWsMessage.payload.last_change_by,
          solvedPendency: analysisWsMessage.payload.solved_pendency,
          status: analysisWsMessage.payload.status,
          updatedAt: analysisWsMessage.payload.updated_at * 1000,
        };

        const column = getAnalysisColumn(analysis.status);
        const previousColumn = getAnalysisColumn(analysisWsMessage.payload.old_status);
        dispatch(onAnalysisUpdateMonthCount({ analysis, column, previousColumn }));
        break;
      }
      case "analysis-status-change-finish": {
        const analysisWsMessage = wsMessage as AnalysisStatusFinishWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          finished: true,
          finishedAt: analysisWsMessage.payload.finished_at * 1000,
          finishedBy: analysisWsMessage.payload.finished_by,
          lastChangeBy: analysisWsMessage.payload.last_change_by,
          solvedPendency: true,
          updatedAt: analysisWsMessage.payload.updated_at * 1000,
        };
        break;
      }
      case "analysis-has-pendency": {
        const analysisWsMessage = wsMessage as AnalysisHasPendencyWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          solvedPendency: !analysisWsMessage.payload.has_pendency,
        };
        break;
      }
      case "analysis-status-change-detail": {
        const analysisWsMessage = wsMessage as AnalysisStatusDetailWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          finished: analysisWsMessage.payload.finished,
          finishedAt: analysisWsMessage.payload.finished_at * 1000,
          finishedBy: analysisWsMessage.payload.finished_by,
          hasPendencies: analysisWsMessage.payload.solved_pendency,
          humanInteraction: analysisWsMessage.payload.human_interaction,
          humanInteractionBy: analysisWsMessage.payload.human_interaction_by,
          lastChangeBy: analysisWsMessage.payload.last_change_by,
          status: analysisWsMessage.payload.status,
          updatedAt: analysisWsMessage.payload.updated_at * 1000,
        };
        break;
      }
      case "analysis-status-change-update": {
        const analysisWsMessage = wsMessage as AnalysisStatusUpdateWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          humanInteraction: analysisWsMessage.payload.human_interaction,
          humanInteractionBy: analysisWsMessage.payload.human_interaction_by,
          status: analysisWsMessage.payload.status,
          updatedAt: analysisWsMessage.payload.updated_at * 1000,
        };
        break;
      }
      case "analysis-status-change-archive": {
        const analysisWsMessage = wsMessage as AnalysisStatusArchiveWsMessage;

        analysis = {
          ...allAnalysis[analysisWsMessage.payload.analysis_id],
          archived: analysisWsMessage.payload.archived,
          archivedAt: analysisWsMessage.payload.archived_at * 1000,
          archivedBy: analysisWsMessage.payload.archived_by,
          lastChangeBy: analysisWsMessage.payload.last_change_by,
        };
        break;
      }
    }

    if (analysis) {
      const column = getAnalysisColumn(analysis.status);
      const { analysis_id } = wsMessage.payload as { analysis_id: string };

      dispatch(onAnalysisDeleted(analysis_id));
      dispatch(onAnalysisAdded({ analysis, column }));
    }
  };

  return { dispatchWsMessage, lastJsonMessage, sendJsonMessage };
};
