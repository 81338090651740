import { AxiosPromise } from "axios";
import { get } from "../../common/utils/apiUtils";
import { GroupResponseRaw } from "../types/GroupResponseRaw";

// List Groups By Product List (Superadmin Endpoint)
export const adminListGroupsByProductList = (
  productIdList: Array<string>,
  tenantCode: string
): AxiosPromise<Array<GroupResponseRaw>> => {
  return get(`/admin/group/product?product_id_list=${productIdList}&tenant_code=${tenantCode}`);
};
