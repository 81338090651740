import { LqdIconProps } from "../../types/IconProps";

export default function LqdUserThreeIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 18C3 15.791 4.791 14 7 14H11C13.209 14 15 15.791 15 18"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16 4.651C17.381 4.651 18.5 5.77 18.5 7.151C18.5 8.532 17.381 9.651 16 9.651"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.4049 4.99613C12.7331 6.3243 12.7331 8.4777 11.4049 9.80587C10.0768 11.134 7.92336 11.134 6.59519 9.80587C5.26702 8.4777 5.26702 6.3243 6.59519 4.99613C7.92336 3.66796 10.0768 3.66796 11.4049 4.99613"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M17 13C19.209 13 21 14.791 21 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
