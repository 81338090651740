import { post } from "../../common/utils/apiUtils";
import { CloudSigningProviders } from "../types/cloudProvidersSession";

export const searchCloudProvidersbyCPF = async (cpf: string, peerToken: string): Promise<CloudSigningProviders> => {
  const body = {
    cpf: cpf,
  };

  const response = await post<CloudSigningProviders>("/sign/start-cloud-signature", body, {
    headers: { "peer-token": peerToken },
  });
  return response.data;
};
