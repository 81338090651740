import { LqdFunctionIcon, LqdInfoIcon, LqdTooltip, LqdTypography } from "@/liquid-components/src";
import { highlightText } from "@common/utils/highlightText";
import { Stack } from "@mui/material";
import useSimulatorBuilder from "@simulatorBuilder/hooks/useSimulatorBuilder";
import { SimulatorTesterCustomNodeData, SimulatorTesterSearchData } from "@simulatorBuilder/types/simulationTester";
import { SimulatorFunction } from "@simulatorBuilder/types/SimulatorFunction";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { useEffect, useState } from "react";
import { Handle, Position, ReactFlowProvider } from "reactflow";
import { useAppSelector } from "../../../../../../store";
import "./customNode.css";
import EmptyNode from "./EmptyNode";
import SimulatorTesterCustomNodeFunctionsTemplate from "./SimulatorTesterCustomNodeFunctionsTemplate";

type SimulatorTesterCustomNodeProps = {
  data: SimulatorTesterCustomNodeData;
};

/** Responsável por renderizar o nó personalizado para o Flow do Tester. Exibe o nome do bloco, índice da função e destaca quaisquer funções quebradas com mensagens de erro. */
export default function SimulatorTesterCustomNode(props: SimulatorTesterCustomNodeProps) {
  const { data } = props;

  const { simulator } = useSimulatorBuilder();

  const { functionTemplates, simulatorTesterSearchData, testerResult } = useAppSelector(
    (state) => state.simulatorBuilder
  );

  const [inputSearchData, setInputSearchData] = useState<SimulatorTesterSearchData>({
    blocks: [],
    functions: [],
    searchedText: "",
    type: "",
    variables: [],
  });

  const { searchedText } = inputSearchData;

  const errorMessage = testerResult?.result?.error;

  const handleBrokenBlock = () => {
    if (errorMessage && testerResult?.result?.steps && testerResult.result.steps.length > 0) {
      return testerResult?.result?.steps[testerResult.result.steps.length - 1];
    } else if (errorMessage && testerResult?.result && !testerResult.result.steps.length) {
      const block = simulator.blocks[0];
      return {
        block_id: block.block_id,
        block_name: block.name,
        function_index: block.functions[0].index,
        function_name: block.functions[0].function_name,
        function_params: [],
        result: {},
      };
    }
  };

  const brokenBlock = handleBrokenBlock();

  const isFunctionBroken = (func: SimulatorFunction) => {
    return brokenBlock?.block_id === data.block.block_id && brokenBlock?.function_index === func.index;
  };

  useEffect(() => {
    setInputSearchData(simulatorTesterSearchData);
  }, [simulatorTesterSearchData]);

  if (!simulator.blocks.length) {
    return <EmptyNode data={data} />;
  }

  const handleConnections = (position: string, block: SimulatorFunctionBlock): string | undefined => {
    const isBroken = brokenBlock && block.block_id >= brokenBlock.block_id;
    const isLastBlock = block.block_id === simulator.blocks[simulator.blocks.length - 1].block_id;
    const isFirstBlock = block.block_id === simulator.blocks[0].block_id;

    if ((isLastBlock && position === "right") || (isFirstBlock && position === "left")) {
      return;
    }

    const isInvalid =
      isBroken && (position === "right" || (position === "left" && block.block_id >= brokenBlock.block_id));

    return isInvalid ? "rgba(246, 61, 94, 1)" : "rgba(127, 135, 152, 1)";
  };

  return (
    <ReactFlowProvider>
      <Stack
        sx={{
          background: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(207, 211, 218, 1)",
          borderRadius: "20px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          minWidth: "287px",
          p: 2,
          pl: 2.5,
          width: "fit-content",
        }}
      >
        <Stack direction="row" sx={{ mb: "10px" }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "rgba(177, 227, 255, 1)",
              borderRadius: "50%",
              color: "rgba(13, 65, 122, 1)",
              height: 38,
              width: 38,
            }}
          >
            <LqdFunctionIcon size={18} />
          </Stack>

          <Stack sx={{ ml: "10px" }}>
            <LqdTypography color="rgba(0, 0, 0, 1)" textstyle="h5Headline">
              {highlightText(data.block.name, searchedText, "20px")}
            </LqdTypography>
            <LqdTypography color="rgba(102, 102, 102, 1)" textstyle="c1Caption">
              {`#${data.block.functions[0].index}`}
            </LqdTypography>
          </Stack>
        </Stack>
        {data.block.functions.map((func, key) => (
          <Stack key={key}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <LqdTypography
                color={isFunctionBroken(func) ? "rgba(246, 61, 94, 1)" : "rgba(127, 135, 152, 1)"}
                textstyle="l2Label"
              >
                {highlightText(
                  functionTemplates.find((template) => template.function_name === func.function_name)?.function_label ||
                    "",
                  searchedText,
                  "12px"
                )}
              </LqdTypography>
              {isFunctionBroken(func) ? (
                <LqdTooltip
                  followCursor={true}
                  hidetooltip={!isFunctionBroken(func) ? "true" : "false"}
                  titlebody={errorMessage}
                  tooltipColor="rgba(246, 61, 94, 1)"
                >
                  <LqdInfoIcon color={"rgba(246, 61, 94, 1)"} />
                </LqdTooltip>
              ) : null}
            </Stack>
            <SimulatorTesterCustomNodeFunctionsTemplate
              data={data}
              func={func}
              searchedText={searchedText}
              simulator={simulator}
              testerResult={testerResult}
            />
          </Stack>
        ))}
        <Handle
          className="custom-node-left-handle"
          position={Position.Left}
          style={{ background: handleConnections("left", data.block) }}
          type="target"
        />
        <Handle
          className="custom-node-right-handle"
          position={Position.Right}
          style={{ background: handleConnections("right", data.block) }}
          type="source"
        />
      </Stack>
    </ReactFlowProvider>
  );
}
