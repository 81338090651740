import { LqdIconProps } from "../../types/IconProps";

export default function LqdShieldIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M20 11.9484C20 16.4894 16.587 20.7354 12 21.7664C7.413 20.7354 4 16.4894 4 11.9484V8.38142C4 7.56942 4.491 6.83742 5.243 6.53042L10.243 4.48442C11.369 4.02342 12.631 4.02342 13.757 4.48442L18.757 6.53042C19.509 6.83842 20 7.56942 20 8.38142V11.9484V11.9484Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M15.25 10.8906L11.5 14.6406L9.25 12.3906"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
