import { LqdButton, LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadSegments } from "../../adminSliceThunks";
import { adminCreateSegment } from "../../api/CreateSegment";

type CreateSegmentStepProps = {
  checkedSegments: Array<string>;
  setCheckedSegments: (segments: Array<string>) => void;
};

export default function CreateSegmentStep(props: CreateSegmentStepProps) {
  const { checkedSegments, setCheckedSegments } = props;

  const dispatch = useAppDispatch();
  const { segments } = useAppSelector((state) => state.admin);
  const handleLiquidErrors = useErrorHandler();

  const CHAR_LIMIT = 25;

  const [isLoading, setIsLoading] = useState(false);
  const [segmentName, setSegmentName] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const segmentRaw = await adminCreateSegment(segmentName.trim());
      const segmentId = segmentRaw.data.segment_id;
      await dispatch(onLoadSegments());
      setCheckedSegments([...checkedSegments, segmentId]);

      const notification: ToastNotification = {
        message: "O segmento foi criado com sucesso!",
        type: "success",
      };
      dispatch(toastCalled(notification));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const segmentNames = checkedSegments.map((segmentId) => segments[segmentId].name);

  const handleNameAcrossSegments = () => {
    const nameExists = segmentNames.some(
      (segName) => segName.trim().toLowerCase() === segmentName.trim().toLowerCase()
    );
    return nameExists;
  };

  const handleNameError = () => {
    switch (true) {
      case showNameLengthAlert:
        return `Você ultrapassou o limite de ${CHAR_LIMIT} caracteres. Abrevie para continuar.`;
      case handleNameAcrossSegments():
        return "Já existe um segmento com este nome.";
      default:
        return "";
    }
  };

  const showNameLengthAlert = segmentName.length > 25;
  const maySubmit = segmentName.length > 3 && !handleNameAcrossSegments() && !showNameLengthAlert && !isLoading;

  return (
    <Box sx={{ height: "240px", width: "100vw" }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
        Nome do segmento
      </LqdTypography>
      <TextField
        autoFocus
        error={showNameLengthAlert}
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={(event) => setSegmentName(event.target.value)}
        onKeyUp={(key) => key.key === "Enter" && handleSubmit()}
        placeholder="Digite"
        sx={{
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          width: "100%",
        }}
        value={segmentName}
        variant="standard"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
          {handleNameError()}
        </LqdTypography>
        <LqdTypography
          sx={{
            color: showNameLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(33, 36, 42, 1)",
            display: "block",
            textAlign: "right",
          }}
          textstyle="p2Paragraph"
        >
          {`${segmentName.length}/${CHAR_LIMIT} caracteres`}
        </LqdTypography>
      </Box>
      <Box sx={{ mt: 5, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit} sx={{ px: 2.5, py: 2 }}>
          {isLoading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Criar segmento"}
        </LqdButton>
      </Box>
    </Box>
  );
}
