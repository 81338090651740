import { highlightText } from "@/analysis-v3/src/features/common/utils/highlightText";
import { Avatar, Stack } from "@mui/material";
import LqdChip from "../../Chip";
import LqdTypography from "../../Typography";

type LqdSelectOptionProps = {
  image: string;
  isSelected: boolean;
  label: string;
  labelTitle: string;
  onChange: (value: string) => void;
  searchTerm: string;
  secondaryLabel: string;
  secondaryLabelTitle: string;
  value: string;
};

export default function LqdSelectOption(props: LqdSelectOptionProps) {
  const { image, isSelected, label, labelTitle, onChange, searchTerm, secondaryLabel, secondaryLabelTitle, value } =
    props;

  return (
    <Stack
      onClick={() => onChange(value)}
      sx={{
        ":hover": {
          boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10)",
          outline: "2px solid rgba(127, 135, 152, 0.24)",
        },
        alignItems: "center",
        backgroundColor: "rgba(191, 196, 205, 0.12)",
        borderRadius: 4,
        cursor: "pointer",
        m: 1,
        outline: isSelected ? "2px solid rgba(127, 135, 152, 0.40)" : "none",
        p: 3,
      }}
    >
      {/* DESKTOP */}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ display: { sm: "flex", xs: "none" }, width: "100%" }}
      >
        <Stack alignItems="center" direction="row">
          {image ? <Avatar src={image} sx={{ display: "inline-block", height: 60, width: 60 }} /> : null}
          <Stack sx={{ ml: image ? 2 : 0 }}>
            {labelTitle ? (
              <LqdChip chipColor="rgba(127, 135, 152, 1)" chipSize="extra-small" label={labelTitle} />
            ) : null}
            <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="p1Paragraph">
              {highlightText(label, searchTerm)}
            </LqdTypography>
          </Stack>
        </Stack>
        <Stack sx={{ alignItems: "center", display: "flex", pl: 1.5 }}>
          <Stack alignItems="left" sx={{ pr: 1 }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ display: "inline-block" }} textstyle="c1Caption">
              {secondaryLabelTitle}
            </LqdTypography>
            <LqdTypography sx={{ display: "inline-block" }}>{secondaryLabel}</LqdTypography>
          </Stack>
        </Stack>
      </Stack>
      {/* MOBILE */}
      <Stack sx={{ display: { sm: "none", xs: "flex" }, width: "100%" }}>
        <Stack alignItems="center" direction="row">
          {image ? <Avatar src={image} sx={{ height: 40, width: 40 }} /> : null}
          <Stack sx={{ ml: image ? 1 : 0 }}>
            <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="p2Paragraph">
              {highlightText(label, searchTerm)}
            </LqdTypography>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
              {labelTitle}, {secondaryLabel}
            </LqdTypography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
