import { FormattedOpenCreditReportData } from "@account/types/FormattedOpenCreditReportData";
import { OpenCreditReportDataRaw } from "@account/types/OpenCreditReportDataRaw";

type FormattedOpenCreditCustomHeaders = {
  "AUTH ID": string;
  BROWSER: string;
  "CPF CLIENTE": string;
  "DATA AUTORIZAÇÃO": string;
  "DATA EXPIRAÇÃO": string;
  DISPOSITIVO: string;
  HASH: null | string;
  "ID EMPRESA": string;
  "MÉTODO DE ACEITE": string;
  "NOME CLIENTE": string;
  "NOME EMPRESA": string;
  "VERSÃO DOS TERMOS": string;
};

export const reportOpenCreditCustomHeaders: Array<string> = [
  "AUTH ID",
  "DATA AUTORIZAÇÃO",
  "DATA EXPIRAÇÃO",
  "CPF CLIENTE",
  "ID EMPRESA",
  "NOME CLIENTE",
  "HASH",
  "DISPOSITIVO",
  "BROWSER",
  "NOME EMPRESA",
  "VERSÃO DOS TERMOS",
  "MÉTODO DE ACEITE",
];

export const openCreditFormatReportData = (
  openCreditData: Array<OpenCreditReportDataRaw>,
  formattedDataList: Array<FormattedOpenCreditReportData>
) => {
  for (let i = 0; i < openCreditData.length; i += 1) {
    const formattedData: FormattedOpenCreditReportData = {
      acceptMethod: "",
      authExpTimestamp: "",
      authId: "",
      authTimestamp: "",
      browser: "",
      device: "",
      hash: "",
      tenantCode: "",
      tenantName: "",
      termsVersion: "",
      userDocument: "",
      userName: "",
    };
    const userDocument = openCreditData[i].authorization_data.person_info.cpf;
    const firstDigits = userDocument.slice(0, 3);
    const lastDigits = userDocument.slice(-2);
    const userName = openCreditData[i].authorization_data.person_info.name;

    formattedData["acceptMethod"] = openCreditData[i].authorization_data.accept_method;
    formattedData["authExpTimestamp"] = openCreditData[i].authorization_data.expiration_at;
    formattedData["authId"] = `${firstDigits}__${lastDigits}-${userName.slice(0, 4)}`;
    formattedData["authTimestamp"] = openCreditData[i].authorization_data.authorized_at;
    formattedData["browser"] = openCreditData[i].device_data.browser;
    formattedData["device"] = openCreditData[i].device_data.device;
    formattedData["hash"] = openCreditData[i].authorization_data.hash;
    formattedData["tenantCode"] = openCreditData[i].tenant_data.tenant_code;
    formattedData["tenantName"] = openCreditData[i].tenant_data.tenant_name;
    formattedData["termsVersion"] = openCreditData[i].authorization_data.terms;
    formattedData["userDocument"] = userDocument;
    formattedData["userName"] = userName;

    formattedDataList.push(formattedData);
  }

  return formattedDataList;
};

export const getOpenCreditCSVData = (openCreditData: Array<FormattedOpenCreditReportData>) => {
  return openCreditData.map((data) => {
    const formattedItem: FormattedOpenCreditCustomHeaders = {
      "AUTH ID": data.authId,
      BROWSER: data.browser,
      "CPF CLIENTE": data.userDocument,
      "DATA AUTORIZAÇÃO": data.authTimestamp,
      "DATA EXPIRAÇÃO": data.authExpTimestamp,
      DISPOSITIVO: data.device,
      HASH: data.hash,
      "ID EMPRESA": data.tenantCode,
      "MÉTODO DE ACEITE": data.acceptMethod,
      "NOME CLIENTE": data.userName,
      "NOME EMPRESA": data.tenantName,
      "VERSÃO DOS TERMOS": data.termsVersion,
    };
    return formattedItem;
  });
};
