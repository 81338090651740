import { ThemeOptions, createTheme, responsiveFontSizes } from "@mui/material";
import * as componentsTheming from "../../components/LiquidTheming";
import palette from "./palette";
import typography from "./typography";

const config = { components: { ...componentsTheming }, palette, typography } as ThemeOptions;

let theme = createTheme(config);
theme = responsiveFontSizes(theme);

export default theme;
