export const institutionDictionary = (institutionName: string) => {
  if (!institutionName) return "Nome não informado";

  switch (institutionName) {
    case "banco do brasil":
      return "Banco do Brasil";
    case "Banco BTG Pactual":
      return "BTG Pactual";
    case "bradesco":
      return "Bradesco";
    case "cef":
      return "Caixa Econômica Federal";
    case "itau":
    case "itaú":
      return "Itaú";
    case "nubank":
      return "Nubank";
    case "santander":
      return "Santander";
    default:
      return institutionName;
  }
};

export const providerDictionary = (providerData: { label: string; type: string }) => {
  switch (providerData.type) {
    case "allotment":
      return "Simulador Liquid Parametrizado";
    case "basic":
      return "Simulador Básico";
    case "liquid_simulation":
      return "Liquid";
    case "morar":
      return "Simulador Liquid Morar";
    case "ph3a":
      return "Liquidpass";
    case "pluggy":
      return "Open Finance Pluggy";
    case "sbpe":
      return "Simulador SBPE";
    case "serasa_cpa":
      return "Serasa Parcial";
    case "serasa_completo":
      return "Serasa Completo";
    case "simulator":
    default:
      return providerData.label;
  }
};
