import { LqdButton, LqdRepeatIcon, LqdTypography, LqdUserIcon } from "@/liquid-components/src";
import useWindowSize from "@common/hooks/useWindowSize";
import { Box, Stack } from "@mui/material";
import jwtDecode from "jwt-decode";
import { FormEvent, MouseEvent, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { adminSwitchAccount } from "../../signin/api/adminSwitchAccount";
import TenantSelectionModal from "../../signin/components/TenantSelectionModal";
import { dialogCalled, dialogLoaded } from "../commonSlice";
import useAuth from "../hooks/useAuth";
import { JWTToken } from "../types/JWTToken";
import { useErrorHandler } from "../utils/useErrorHandler";
import SystemHeader from "./SystemHeader";

export default function SystemLayout() {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { width } = useWindowSize();

  const { currentUser } = useAppSelector((state) => state.users);
  const { tenants } = useAppSelector((state) => state.admin);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTenantCode, setSelectedTenantCode] = useState<string>("");
  const [showAdminTenantSelectionModal, setShowAdminTenantSelectionModal] = useState(false);

  const accessToken = localStorage.getItem("access_token");
  const tokenData = jwtDecode<JWTToken>(accessToken!);
  const actualTenant = tokenData.sub.tenant_code;
  const userEmail = tokenData.sub.username;
  const tenantName = currentUser?.userRole === "super" ? tenants[actualTenant]?.contact.name : "outra empresa";

  const userIsSuper = currentUser?.userRole === "super";

  const handleMFAModalClick = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(dialogCalled(null));

    dispatch(
      dialogCalled({
        actions: [
          { title: "Cancelar" },
          { onClick: (qrCode) => handleSwitchAccount(event, qrCode! as string), title: "Verificar" },
        ],
        body: "Insira o código de autenticação.",
        bodySecundary: `Abra seu app de autenticação de dois fatores (TOTP) ou extensão no browser
        para verificar seu código de autenticação.`,
        title: "Autenticação de dois fatores",
        type: "confirmation",
      })
    );
  };

  const handleSwitchAccount = async (
    event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
    mfaCode?: string
  ) => {
    if (!mfaCode) setIsLoading(true);

    dispatch(dialogLoaded(true));

    try {
      const switchAccountRes = mfaCode
        ? await adminSwitchAccount(userEmail, selectedTenantCode, mfaCode)
        : await adminSwitchAccount(userEmail, selectedTenantCode);
      const { access_token, mfa, mfa_qrcode_create, qrcode_url, refresh_token } = switchAccountRes.data;

      if (mfa_qrcode_create) {
        return dispatch(
          dialogCalled({
            actions: [
              {
                onClick: () => handleMFAModalClick(event as unknown as FormEvent<HTMLFormElement>),
                title: "Verificar",
              },
            ],
            body: `Use seu aplicativo de autenticação para escanear este QR code. Se você não possui
            um aplicativo de autenticação, você deverá instalar um agora.`,
            bodySecundary: `No aplicativo, aponte a câmera para a tela. Ao finalizar o processo de
            escanear o QR Code, pressione avançar para seguir.`,
            qrCodeURL: qrcode_url!,
            title: "Vincule seu aplicativo à sua conta Liquid",
            type: "mfa",
          })
        );
      } else if (mfa) {
        return handleMFAModalClick(event as unknown as FormEvent<HTMLFormElement>);
      }

      auth.signIn(access_token, refresh_token);
      window.location.reload();
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
      setIsLoading(false);
    }
  };

  return (
    <Stack className="lqd-system-layout" sx={{ height: "100vh" }}>
      {userIsSuper ? (
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(33, 36, 42, 1)",
            display: "flex",
            height: "36px",
            justifyContent: "space-between",
            px: { sm: 4, xs: 2 },
            py: 1.5,
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <LqdUserIcon color="rgba(255, 255, 255, 1)" />
            <LqdTypography
              color="rgba(255, 255, 255, 1)"
              maxWidth={{ sm: "100%", xs: "200px" }}
              sx={{ ml: 1 }}
              textstyle={width === "xs" ? "c1Caption" : "p2Paragraph"}
            >
              Você está gerenciando {tenantName}.
            </LqdTypography>
          </Box>
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <LqdButton
              buttonsize="small"
              onClick={() => setShowAdminTenantSelectionModal(true)}
              startIcon={<LqdRepeatIcon color="rgba(255, 255, 255, 1)" />}
            >
              Trocar Empresa
            </LqdButton>
          </Box>
        </Box>
      ) : null}
      <SystemHeader />
      <Box sx={{ boxSizing: "border-box", flex: 1, overflowY: "hidden", position: "relative", width: "100%" }}>
        <Outlet />
      </Box>
      <TenantSelectionModal
        isLoading={isLoading}
        onCloseMethod={() => setShowAdminTenantSelectionModal(false)}
        onConfirmMethod={handleSwitchAccount}
        open={showAdminTenantSelectionModal}
        selectedTenantCode={selectedTenantCode}
        setSelectedTenantCode={setSelectedTenantCode}
        tenantList={Object.values(tenants)}
      />
    </Stack>
  );
}
