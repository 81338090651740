import { Box } from "@mui/material";
import SimulatorInput from "./SimulatorInput";

type SimulatorParametrizationAddProps = {
  adjustment: string;
  ageConstraint: string;
  amortizationType: string;
  bankName: string;
  interestRate: string;
  ltvNewProperty: string;
  maxIncomeCommitment: string;
  maxInstallmentQty: string;
  setAdjustment: (value: string) => void;
  setAgeConstraint: (value: string) => void;
  setAmortizationType: (value: string) => void;
  setBankName: (value: string) => void;
  setInterestRate: (value: string) => void;
  setLtvNewProperty: (value: string) => void;
  setMaxIncomeCommitment: (value: string) => void;
  setMaxInstallmentQty: (value: string) => void;
};

export default function SimulatorParametrizationAdd(props: SimulatorParametrizationAddProps) {
  const {
    adjustment,
    ageConstraint,
    amortizationType,
    bankName,
    interestRate,
    ltvNewProperty,
    maxIncomeCommitment,
    maxInstallmentQty,
    setAdjustment,
    setAgeConstraint,
    setAmortizationType,
    setBankName,
    setInterestRate,
    setLtvNewProperty,
    setMaxIncomeCommitment,
    setMaxInstallmentQty,
  } = props;

  return (
    <Box sx={{ display: "flex", margin: "auto", mt: 6, width: "90%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "450px", width: "575px" }}>
        <SimulatorInput
          inputText="Nome da Opção"
          inputValue={bankName}
          onChangeMethod={setBankName}
          placeholder="Ex. 12x sem juros"
          required
        />
        <SimulatorInput
          inputText="Quantidade de Parcelas"
          inputValue={maxInstallmentQty}
          mask="000"
          onChangeMethod={setMaxInstallmentQty}
          placeholder="Ex. 12"
          required
        />
        <SimulatorInput
          decimal
          inputText="Taxa de Juros"
          inputValue={interestRate}
          onChangeMethod={setInterestRate}
          placeholder="Ex. 8.3%"
          required
        />
        <SimulatorInput
          inputText="Tabela de Amortização"
          inputValue={amortizationType}
          onChangeMethod={setAmortizationType}
          options={["Não Definido", "Nenhuma", "SAC", "PRICE"]}
          required
          select
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", height: "450px", width: "575px" }}>
        <SimulatorInput
          inputText="Tipo de Correção"
          inputValue={adjustment}
          onChangeMethod={setAdjustment}
          options={["Não Definido", "IPCA", "INCC", "IGPM", "TR"]}
          select
        />
        <SimulatorInput
          inputText="Restrição de Idade"
          inputValue={ageConstraint}
          mask="00"
          onChangeMethod={setAgeConstraint}
          placeholder="Ex. 70"
        />
        <SimulatorInput
          decimal
          inputText="LTV"
          inputValue={ltvNewProperty}
          onChangeMethod={setLtvNewProperty}
          placeholder="Ex. 80%"
        />
        <SimulatorInput
          decimal
          inputText="Máximo Comprometimento de Renda"
          inputValue={maxIncomeCommitment}
          onChangeMethod={setMaxIncomeCommitment}
          placeholder="Ex. 35%"
        />
      </Box>
    </Box>
  );
}
