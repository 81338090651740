import { get } from "../../common/utils/apiUtils";
import { AnalysisRaw } from "../types/AnalysisRaw";

export type GetAnalysisParams = {
  analysis_id?: string;
  analyst_name?: string;
  archived?: boolean;
  document?: string;
  finished?: boolean;
  from?: number;
  has_pendency?: boolean;
  human_interaction?: boolean;
  limit?: number;
  offset?: number;
  pass_id?: string;
  peer_name?: string;
  product_id?: string;
  product_name?: string;
  status?: string;
  to?: number;
};

export const getAnalysis = (params?: GetAnalysisParams) =>
  get<{
    page: number;
    results: Array<AnalysisRaw>;
    total: number;
    total_pages: number;
  }>("/analysis", { params });
