import { useState } from "react";
import { useAppSelector } from "../../../../../../store";
import FullscreenDialog from "../../../../../common/components/FullscreenDialog";
import { ObjectOf } from "../../../../../common/types/ObjectOf";
import { SimulatorResultTableColumn, SimulatorResultTableFormRenderView } from "../../../../types/SimulatorDashboard";
import SimulatorResultFormsContainer from "../SimulatorResultFormsContainer";
import SimulatorColumnNameForm from "./SimulatorColumnNameForm";
import SimulatorResultTableView from "./SimulatorResultTableView";

export default function SimulatorResultTableForm() {
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const [currentView, setCurrentView] = useState<SimulatorResultTableFormRenderView>("tableView");
  const [tableColumnBeingChanged, setTableColumnBeingChanged] = useState<SimulatorResultTableColumn | null>(null);
  const tablesContent = simulator.dashboard.tables;

  /**
   * Open modal to change column name
   * @param columnIndex Column index
   * @param tableIndex Table index
   */
  const openTableColumnChangeNameModal = (columnIndex: number, tableIndex: number) => {
    const table = tablesContent[tableIndex];
    const columnName = table.columns[columnIndex];
    setTableColumnBeingChanged(columnName);
    setCurrentView("changeColumnName");
  };

  /** Close modal and change to result Table form view */
  const resetView = () => {
    setCurrentView("tableView");
    setTableColumnBeingChanged(null);
  };

  const renderView: ObjectOf<JSX.Element> = {
    // TODO: descobrir uma forma de identificar a tabela ao mudar o nome da coluna
    changeColumnName: (
      <FullscreenDialog
        onClose={() => resetView()}
        open={currentView === "changeColumnName"}
        title={tableColumnBeingChanged?.columnName ? "Editar coluna" : "Nova coluna"}
      >
        <SimulatorColumnNameForm columnNameBeingChanged={tableColumnBeingChanged!} resetView={resetView} />
      </FullscreenDialog>
    ),
    tableView: <SimulatorResultTableView handleColumnNameClick={openTableColumnChangeNameModal} />,
  };

  return (
    <SimulatorResultFormsContainer
      hideActionButton
      subtitle="Configure as tabelas que serão exibidas no Dashboard"
      title="Tabela"
    >
      {renderView[currentView]}
    </SimulatorResultFormsContainer>
  );
}
