import { LqdIconProps } from "../../types/IconProps";

export default function LqdCircleFilledIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill={color} height={size} viewBox={`0 0 ${size} ${size}`} width={size} xmlns="http://www.w3.org/2000/svg">
      <circle cx={size / 2} cy={size / 2} r={size / 4} stroke={color} strokeWidth="1.2" />
    </svg>
  );
}
