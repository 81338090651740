import { User } from "../types/User";
import UserRaw from "../types/UserRaw";

export const getUser = (userRaw: UserRaw): User => ({
  active: userRaw.is_active,
  blockedTenant: userRaw.blocked_tenant,
  created: userRaw.created_at * 1000,
  email: userRaw.username,
  groups: userRaw.groups,
  name: userRaw.fullname,
  notifications: userRaw.notifications || false,
  pending: userRaw.is_pending,
  profile: userRaw.user_role,
  userRole: userRaw.user_role,
});
