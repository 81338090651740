import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

export const useScrollFadeEffect = (fadeHeight: string) => {
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const fadeElementRef = useRef<HTMLElement | null>(null);

  const fadeElementBox = (
    <Box
      ref={fadeElementRef}
      sx={{
        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        position: "absolute",
        right: 0,
      }}
    />
  );

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBoxRef.current && fadeElementRef.current) {
        const element = scrollBoxRef.current;
        const fadeElement = fadeElementRef.current;

        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
          fadeElement.style.opacity = "0";
        } else {
          fadeElement.style.opacity = "1";
        }
      }
    };

    if (scrollBoxRef.current) {
      const element = scrollBoxRef.current;

      fadeElementRef.current = element.nextSibling as HTMLElement | null;

      if (fadeElementRef.current) {
        fadeElementRef.current.style.height = fadeHeight;
      }

      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fadeHeight]);

  return { fadeElementBox, scrollBoxRef };
};
