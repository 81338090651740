import Group from "../types/Group";
import GroupRaw from "../types/GroupRaw";

export const getGroup = (groupRaw: GroupRaw): Group => ({
  active: groupRaw.is_active,
  created: groupRaw.created_at * 1000,
  createdBy: groupRaw.created_by,
  id: groupRaw.code,
  products: groupRaw.products,
  tenant: groupRaw.tenant_code,
  title: groupRaw.name,
  updated: groupRaw.last_update * 1000,
  users: groupRaw.users,
});
