import {
  LqdCloseIcon,
  LqdDuplicateIcon,
  LqdIconButton,
  LqdSelect,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Card, CardContent, CardMedia, TextField } from "@mui/material";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { useAppSelector } from "../../../../store";
import approveActionImg from "../../assets/action-approve.png";
import denyActionImg from "../../assets/action-deny.png";
import jumpActionImg from "../../assets/action-jump.png";
import manualActionImg from "../../assets/action-manual.png";
import warningActionImg from "../../assets/action-warning.png";
import { ActionType } from "../../types/ActionType";

type ActionProps = {
  activeNode: Node;
  onClose: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  selectedProduct: null | string;
  setSelectedProduct: React.Dispatch<React.SetStateAction<string>>;
  setWarningMessage: (message: string) => void;
  type: string;
  warningMessage: string;
};

export default function Action(props: ActionProps) {
  const {
    onClose,
    onDelete,
    onDuplicate,
    selectedProduct,
    setSelectedProduct,
    setWarningMessage,
    type,
    warningMessage,
  } = props;

  const { tenantProducts } = useAppSelector((state) => state.products);

  const getTitle = () => {
    switch (type) {
      case ActionType.Approve:
        return "Aprovado";
      case ActionType.Disapprove:
        return "Reprovado";
      case ActionType.Manual:
        return "Moderação";
      case ActionType.Warning:
        return "Atenção";
      case ActionType.Jump:
        return "Jump Product";
    }
  };

  const getInteraction = () => {
    switch (type) {
      case ActionType.Approve:
      case ActionType.Disapprove:
      case ActionType.Manual:
        return null;
      case ActionType.Warning:
        return (
          <>
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }} textstyle="p2Paragraph">
              Escreva a mensagem a ser exibida
            </LqdTypography>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "block", mb: 0.75 }} textstyle="l2Label">
              Mensagem
            </LqdTypography>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              inputProps={{
                sx: {
                  color: "rgba(33, 36, 42, 1)",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  padding: 0,
                },
              }}
              multiline
              onChange={(event) => setWarningMessage(event.target.value as string)}
              placeholder="Ex: Olá, analista! 👋 Veja com o atenção o score do usuário..."
              rows={4}
              sx={{ mb: 3 }}
              value={warningMessage}
            />
          </>
        );
      case ActionType.Jump:
        return (
          <LqdSelect
            onChange={setSelectedProduct}
            options={Object.values(tenantProducts).map((product) => ({
              id: product.id,
              label: product.name,
              value: product.id,
            }))}
            placeholder="Search here"
            value={selectedProduct!}
          />
        );
    }
  };

  const getImg = () => {
    switch (type) {
      case ActionType.Approve:
        return approveActionImg;
      case ActionType.Disapprove:
        return denyActionImg;
      case ActionType.Manual:
        return manualActionImg;
      case ActionType.Warning:
        return warningActionImg;
      case ActionType.Jump:
        return jumpActionImg;
    }
  };

  const getDescription = () => {
    switch (type) {
      case ActionType.Approve:
        return (
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            Use this flow step to automate the decision. If the flow ends here, there is a match between the Product and
            the target user's data.
          </LqdTypography>
        );
      case ActionType.Disapprove:
        return (
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            Use this flow step to automate the decision. If the flow ends here, this Product does not fit your target
            user's data.
          </LqdTypography>
        );
      case ActionType.Manual:
        return (
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            Use this flow step to call a team member to analyze your target user's data and decide.
          </LqdTypography>
        );
      case ActionType.Warning:
        return (
          <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
            Write anything you want to who is creating a analysis. It can be a bit of advice, orientation, or just an
            observation.
          </LqdTypography>
        );
      case ActionType.Jump:
        return (
          <>
            <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mb: 2 }} textstyle="p2Paragraph">
              Use this Jump to directionate the actual target flow to other Product that have more chances to get a
              match.
            </LqdTypography>
            <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
              In this list you can only find the Products created on your companies' account and our templates.
            </LqdTypography>
          </>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(249, 249, 250, 1)",
          minWidth: "380px",
          mt: -6,
          position: "fixed",
          pt: 6,
          zIndex: 1,
        }}
      >
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", mr: 3 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
            {getTitle()}
          </LqdTypography>
          <LqdIconButton
            className="lqd-config-sidebar__close-button"
            onClick={onClose}
            round="true"
            sx={{ ml: 2 }}
            type="ghostPrimary"
          >
            <LqdCloseIcon />
          </LqdIconButton>
        </Box>
        <Box sx={{ my: 2 }}>
          <LqdIconButton className="lqd-duplicate-node-button" onClick={onDuplicate} round="true" type="ghostPrimary">
            <LqdDuplicateIcon color="rgba(127, 135, 152, 1)" />
          </LqdIconButton>
          <LqdIconButton
            className="lqd-delete-node-button"
            onClick={onDelete}
            round="true"
            sx={{ ml: 1 }}
            type="ghostPrimary"
          >
            <LqdTrashIcon color="rgba(127, 135, 152, 1)" />
          </LqdIconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 16 }}>{getInteraction()}</Box>
      <Card elevation={0} sx={{ borderRadius: "10px", mt: 8 }}>
        <CardMedia component="img" image={getImg()} />
        <CardContent>
          <LqdTypography color="rgba(65, 70, 103, 1)" sx={{ my: 1.5 }}>
            About
          </LqdTypography>
          {getDescription()}
        </CardContent>
      </Card>
    </>
  );
}
