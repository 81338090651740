import { AxiosPromise } from "axios";
import { put } from "../../common/utils/apiUtils";
import { TemplateResponseRaw } from "../types/TemplateResponseRaw";
import { TemplateUpdate } from "../types/TemplateUpdate";

// Update Template (Superadmin Endpoint)
export const adminUpdateTemplate = (
  templateId: string,
  templateInfo: TemplateUpdate
): AxiosPromise<TemplateResponseRaw> => {
  return put(`/admin/template?template_id=${templateId}`, templateInfo);
};
