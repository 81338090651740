import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterFourteen() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        14. CONFIDENCIALIDADE
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.1. Durante a vigência destes Termos, e pelo prazo de 05 (cinco) anos após sua extinção, cada uma das Partes,
        sendo elas a Liquid ou o Usuário Decisor, podendo cada uma delas ser considerada Parte ou Parte Contrária a
        depender do fluxo de divulgação de tais informações, por si e seu Pessoal (conforme definido abaixo), se
        compromete a manter o mais absoluto sigilo e confidencialidade acerca de toda e qualquer Informação Confidencial
        (conforme definido abaixo) que venha, a qualquer momento, a lhe ser fornecida pela Liquid em virtude deste
        Contrato, obrigando-se a não divulgá-la a qualquer terceiro, salvo se houver autorização prévia, específica,
        expressa e por escrito por esta última.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        14.1.1. Deverá ser considerada como informação confidencial toda e qualquer informação escrita e/ou oral
        revelada pela Parte Contrária, contendo ou não a expressão “Confidencial”. O termo informação corresponderá a
        toda e qualquer informação escrita, verbal e/ou de qualquer outro modo apresentada, tangível ou intangível,
        podendo incluir, mas não se limitando a: Tecnologia da Liquid, documentos, dispositivo e/ou detalhe, de caráter
        técnico e/ou comercial, seja ele informativo, ilustrativo, fotográfico, especificações técnicas e/ou comerciais,
        know-how, técnicas, design, especificações, desenhos, diagramas, cópias, fórmulas, amostras, fluxogramas,
        tabelas, modelos, croquis, fotografias, plantas, programas de computação, contratos, planos de negócios,
        projetos, processos, conceitos de produtos, preços, margem de lucro, custos, fornecedores, definições,
        informações mercadológicas, invenções, ideias, outras informações técnicas, comerciais e/ou financeiras, dentre
        outros, doravante denominadas (“Informações Confidenciais”), direta ou indiretamente, recebidas, pertencentes à
        Parte Contrária.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.2. Para os fins destes Termos, quando os Usuários Decisores forem pessoas jurídicas, considerar-se-á como
        pessoal da Parte todos os seus empregados, administradores, prepostos, sócios, representantes de qualquer
        natureza, contratados e subcontratados (“Pessoal”).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.3. Obrigam-se as Partes a restringir o acesso do seu Pessoal às Informações Confidenciais apenas ao Pessoal
        nas discussões, análises, reuniões e negócios objeto destes Termos, devendo cientificá-los da existência desta
        obrigação e da natureza confidencial das Informações Confidenciais, bem como exigir o seu rigoroso cumprimento.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.4. Todas as Informações Confidenciais deverão ser excluídas em até 24 (vinte e quatro) horas da extinção dos
        Termos, não podendo uma Parte ficar com qualquer cópia e/ou reprodução, no todo ou em parte, com as ressalvas de
        que seja dada a prévia e expressa aprovação por escrito da Parte Contrária ou nos casos em que a retenção de
        dados seja necessária para fins legais ou de auditoria.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        14.5. As Partes reconhecem que eventual violação da sua obrigação de sigilo impactará substancialmente a Parte
        Contrária, e sujeitará a Parte que tenha cometido a infração desta Cláusula a indenizar a Parte Contrária pelas
        perdas e danos, direta e indiretamente decorrentes da divulgação não autorizada das informações, sem prejuízo da
        aplicação das sanções cabíveis de natureza cível, criminal e trabalhista.
      </LqdTypography>
    </>
  );
}
