import { Box } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toastCalled } from "../../common/commonSlice";
import FullscreenDialog from "../../common/components/FullscreenDialog";
import { ObjectOf } from "../../common/types/ObjectOf";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import Group from "../../groups/types/Group";
import { User } from "../types/User";
import { createUserModalClosed, createUserModalStepPrev, onCreateUser } from "../usersSlice";
import { getCreateUserRaw } from "../utils/getCreateUserRaw";
import UserFormStep1 from "./UserFormStep1";
import UserFormStep2 from "./UserFormStep2";
import UserFormStep3 from "./UserFormStep3";
import UserFormStep4 from "./UserFormStep4";
import UserFormStepLast from "./UserFormStepLast";

const emptyUser: User = {
  active: true,
  blockedTenant: false,
  created: new Date().getTime(),
  email: "",
  groups: [],
  name: "",
  notifications: false,
  pending: true,
  profile: "",
  userRole: "viewer",
};

export default function CreateUserForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { createUser, createUserCurrentStep } = useAppSelector((state) => state.users);

  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userGroups, setUserGroups] = useState<ObjectOf<Group>>({});
  const [userName, setUserName] = useState<string>("");
  const [userProfile, setUserProfile] = useState<string>("");

  const onSubmitUserCreate = async () => {
    setLoading(true);

    const userRaw = getCreateUserRaw({
      ...emptyUser,
      email: userEmail,
      groups: Object.values(userGroups).map((group) => group.id),
      name: userName,
      profile: userProfile,
    });

    try {
      await dispatch(onCreateUser(userRaw)).unwrap();

      const notification: ToastNotification = {
        message: t("Convite enviado com sucesso."),
        type: "success",
      };

      dispatch(toastCalled(notification));

      dispatch(createUserModalClosed());
    } catch (error) {
      handleLiquidErrors(error, "Houve um erro ao criar o usuário. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const renderCurrentStep = () => {
    switch (createUserCurrentStep) {
      case 1:
        return <UserFormStep1 name={userName} setName={setUserName} />;
      case 2:
        return <UserFormStep2 email={userEmail} setEmail={setUserEmail} />;
      case 3:
        return <UserFormStep3 profile={userProfile} setProfile={setUserProfile} />;
      case 4:
        return <UserFormStep4 selectedGroups={userGroups} setSelectedGroups={setUserGroups} />;
      case 5:
        return (
          <UserFormStepLast
            actionButtonLabel={t("Enviar convite")}
            loading={loading}
            onSubmit={onSubmitUserCreate}
            selectedGroups={userGroups}
            userEmail={userEmail}
            userName={userName.trim()}
            userProfile={userProfile}
          />
        );
    }
  };

  const resetState = () => {
    setUserEmail("");
    setUserGroups({});
    setUserName("");
    setUserProfile("");
  };

  const handleCloseCreateUser = () => {
    resetState();
    dispatch(createUserModalClosed());
  };

  return (
    <FullscreenDialog
      action="create"
      currentStep={createUserCurrentStep}
      handleGoBack={() => dispatch(createUserModalStepPrev())}
      onClose={handleCloseCreateUser}
      open={createUser}
      title={t("Novo Usuário")}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>{renderCurrentStep()}</Box>
    </FullscreenDialog>
  );
}
