import { LqdButton, LqdCircularLoader, LqdSelect, LqdTypography } from "@/liquid-components/src";
import { SelectOption } from "@/liquid-components/src/components/Select/types/SelectOption";
import { LqdShieldIcon } from "@/liquid-components/src/icons";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { dialogCalled, toastCalled } from "@common/commonSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import LacunaWebPKI from "web-pki";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { onChangingView, onPerformingLocalSignature, onStartingLocalSignature } from "../../signatureSlice";
import { SignatureViews } from "../../types/signatureViews";
import RegistryNavigationBar from "../RegistryNavigationBar";
import LqdSecureEnvironment from "../SecureEnvironment";
import SignatureHeader from "../SignatureHeader";

export default function LocalSigning() {
  const dispatch = useAppDispatch();

  const { localSignatureToken, token } = useAppSelector((state) => state.signature);

  const [certificateDetails, setCertificateDetails] = useState<Array<SelectOption>>([]);
  const [loading, setLoading] = useState({
    signature: false,
    view: true,
  });
  const [selectedCertificate, setSelectedCertificate] = useState<string>("");

  const pki = new LacunaWebPKI();

  useEffect(() => {
    const changeView = () => {
      dispatch(onChangingView(SignatureViews.CREATE_CERTIFICATE));
      dispatch(dialogCalled(null));
    };

    const onWebPkiNotInstalled = (_status: LacunaWebPKI.InstallationStates, message: string) => {
      alert(message + "Você será redirecionado para a página de instalação da extensão!");
      // Coloquei pra abrir a página da extensão ao invés da página padrão da Lacuna.
      // Caso precise, é só chamar a função pki.redirectToInstallPage();
      pki.redirectToInstallPage();
      // window.open("https://chromewebstore.google.com/detail/web-pki/dcngeagmmhegagicpcmpinaoklddcgon", "_blank");
    };
    const loadCertificates = () => {
      pki.listCertificates().success(async (certs) => {
        const newCertificateDetails = certs.map((cert) => ({
          issuerName: cert.issuerName,
          subjectName: cert.subjectName,
          thumbprint: cert.thumbprint,
        }));
        const formattedDetails = newCertificateDetails.map((certificate) => ({
          id: certificate.thumbprint,
          label: `${certificate.issuerName} - ${certificate.subjectName}`,
          value: certificate.thumbprint,
        }));
        if (newCertificateDetails.length < 1) {
          dispatch(
            dialogCalled({
              actions: [
                {
                  onClick: () => changeView(),
                  title: "Criar Certificado",
                },
              ],
              body: `Acesse esta página de um outro dispositivo para localizar o seu
            certificado ou crie um novo certificado em nuvem gratuitamente.`,
              title: "Não encontramos um Certificado Local neste dispositivo",
              type: "default",
            })
          );
          return;
        }
        setCertificateDetails(formattedDetails);
        try {
          await dispatch(onStartingLocalSignature(token));
        } catch (error) {
          const notification: ToastNotification = {
            message: "Desculpe. Houve um erro ao tentar iniciar sua assinatura",
            type: "error",
          };
          dispatch(toastCalled(notification));
        }

        setLoading({ ...loading, view: false });
      });
    };
    pki.init({
      notInstalled: onWebPkiNotInstalled,
      ready: loadCertificates,
    });
  }, []);

  const handleSelectChange = (value: string) => {
    setSelectedCertificate(value);
  };

  const sign = async () => {
    setLoading({ ...loading, signature: true });
    if (!selectedCertificate) {
      setLoading({ ...loading, signature: false });
      return;
    }
    const selectedCertThumbprint = selectedCertificate;
    pki
      .signWithRestPki({
        thumbprint: selectedCertThumbprint,
        token: localSignatureToken,
      })
      .success(async () => {
        setLoading({ ...loading, view: true });
        await dispatch(onPerformingLocalSignature({ peerToken: token, signatureToken: localSignatureToken }));
      });
  };

  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 2 de 2",
        }}
        previusPage={SignatureViews.WITHOUT_CLOUD_SIGNING}
        step={SignatureViews.LOCAL_SIGNING}
      />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdShieldIcon />
        </Stack>
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Certificado Digital e-CPF
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Você precisa de um certificado para assinar este documento.
        </LqdTypography>

        <Stack sx={{ mb: 3, mt: 5 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2, textAlign: "left" }}>
            Selecione o seu certificado abaixo:
          </LqdTypography>
          <LqdSelect
            onChange={handleSelectChange}
            options={
              !loading.view
                ? certificateDetails
                : [{ id: "1", label: "Aguarde, carregando os certificados...", value: "" }]
            }
            placeholder="Selecione seu certificado"
            value={selectedCertificate}
          />
        </Stack>
        <LqdButton
          color="primary"
          disabled={!selectedCertificate.length}
          endIcon={!loading.signature ? <RightArrow /> : ""}
          onClick={sign}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          {loading.signature ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : "Assinar"}
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
