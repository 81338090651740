import { LqdDuplicateIcon, LqdEllipsisIcon, LqdIconButton, LqdTrashIcon } from "@/liquid-components/src";
import { Box, Menu, MenuItem } from "@mui/material";
import { t } from "i18next";
import { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, toastCalled } from "../../common/commonSlice";
import { ToastNotification } from "../../common/types/ToastNotification";
import { newAutomationChanged } from "../automationsSlice";
import { Automation } from "../types/Automation";
import { AutomationAction } from "../types/AutomationAction";

type AutomationActionCardMenuProps = {
  actionIndex: number;
};

export default function AutomationActionCardMenu(props: AutomationActionCardMenuProps) {
  const { actionIndex } = props;

  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);
  const { newAutomationForm } = useAppSelector((state) => state.automations);

  const onDuplicateActionConfirm = () => {
    const automationActionCopy = JSON.parse(
      JSON.stringify((newAutomationForm as Automation).actions[actionIndex])
    ) as AutomationAction;
    dispatch(
      newAutomationChanged({
        ...(newAutomationForm as Automation),
        actions: [...(newAutomationForm as Automation).actions, automationActionCopy],
      })
    );
    setShowMenu(null);
    dispatch(dialogCalled(null));
  };

  const onRemoveActionConfirm = () => {
    const updatedAutomationActions = [...(newAutomationForm as Automation).actions];
    updatedAutomationActions.splice(actionIndex, 1);
    dispatch(
      newAutomationChanged({
        ...(newAutomationForm as Automation),
        actions: updatedAutomationActions,
      })
    );

    const notification: ToastNotification = {
      message: "A ação foi excluida da automação.",
      type: "success",
    };

    dispatch(toastCalled(notification));
    setShowMenu(null);
    dispatch(dialogCalled(null));
  };

  const onDuplicateActionClick = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => onDuplicateActionConfirm(), title: "Duplicar ação" }],
        body: `Tem certeza de que deseja duplicar a ação selecionada? 
        Algumas variáveis podem necessitar de configuração novamente.`,
        title: "Duplicar Ação",
        type: "default",
      })
    );
  };

  const onRemoveActionClick = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: () => onRemoveActionConfirm(), title: "Excluir ação" }],
        body: "Tem certeza de que deseja excluir a ação selecionada?",
        title: "Exclusão de Ação",
        type: "default",
      })
    );
  };

  return (
    <>
      <LqdIconButton
        buttonsize="small"
        onClick={(event: MouseEvent<HTMLButtonElement>) => setShowMenu(event.currentTarget)}
        round="true"
        type="outlinePrimary"
      >
        <LqdEllipsisIcon color="rgba(127, 135, 152, 1)" />
      </LqdIconButton>
      <Menu
        anchorEl={showMenu}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={1}
        id="basic-menu"
        MenuListProps={{ sx: { borderRadius: "10px", py: 0 } }}
        onClose={() => setShowMenu(null)}
        open={Boolean(showMenu)}
        slotProps={{
          paper: { sx: { borderRadius: "10px", boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.20)", mt: 1.5 } },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={onDuplicateActionClick} sx={{ height: "54px", minWidth: "208px", px: 2 }}>
          <LqdDuplicateIcon color="rgba(127, 135, 152, 1)" />
          <Box sx={{ ml: 1 }}>{t("Duplicar")}</Box>
        </MenuItem>
        <MenuItem onClick={onRemoveActionClick} sx={{ height: "54px", minWidth: "208px", px: 2 }}>
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" />
          <Box sx={{ ml: 1 }}>{t("Excluir")}</Box>
        </MenuItem>
      </Menu>
    </>
  );
}
