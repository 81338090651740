import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    height: "40px",
    marginRight: "18px",
    width: "40px",
  },
});

export default function PDFTrustpadDefaultSVG() {
  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 45 45">
        <Path d="M15.9963 17.3271V33.785" stroke="rgb(191, 196, 205)" strokeLineCap="round" strokeWidth="1.2" />
        <Path
          d="M15.9961 29.2833C18.1974 28.0254 20.866 27.8906 23.1828 28.9203L24.8161 29.6462C27.1329 30.6759 29.8015 30.5411 32.0028 29.2833V17.3273C29.8015 18.5852 27.1329 18.7199 24.8161 17.6902L23.1828 16.9643C20.866 15.9346 18.1974 16.0694 15.9961 17.3273"
          stroke="rgb(191, 196, 205)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Circle cx="23" cy="23" r="20" stroke="rgb(191, 196, 205)" />
      </Svg>
    </View>
  );
}
