import { LqdSelect } from "@/liquid-components/src";
import { Box, SxProps } from "@mui/material";
import { useAppSelector } from "../../../../../store";
import { SimulatorVariable } from "../../../types/SimulatorVariable";

type SimulatorResultEmptyCardProps = {
  handleChange: (variableName: string, index: number) => void;
  index: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

export default function SimulatorEmptyCard(props: SimulatorResultEmptyCardProps) {
  const { handleChange, index, selectedVariable, wrapper } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const options = simulator.variables.map((variable, index) => ({
    id: variable.id || index.toString(),
    label: variable.label,
    value: variable.name,
  }));

  return (
    <Box sx={wrapper}>
      <LqdSelect
        isOpened={false}
        onChange={(value) => handleChange(value, index)}
        options={options}
        placeholder="Selecione uma variável"
        value={selectedVariable.content ? selectedVariable.content.name : ""}
      />
    </Box>
  );
}
