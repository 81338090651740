import { LqdIconProps } from "../../types/IconProps";

export default function LqdQuestionIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12.0001"
        cy="12"
        r="9.00375"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M9.68481 9.6847C9.92184 8.66314 10.8523 7.95484 11.9001 7.99832C13.0727 7.93337 14.078 8.82697 14.151 9.99916C14.151 11.5037 12.0001 12 12.0001 13.0004"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.125 15.7516C12.125 15.8206 12.069 15.8766 12 15.8766C11.9309 15.8766 11.8749 15.8206 11.8749 15.7516C11.8749 15.6825 11.9309 15.6265 12 15.6265"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.9999 15.6265C12.069 15.6265 12.125 15.6825 12.125 15.7516"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
