import { Card, CardProps } from "@mui/material";

export default function LqdNodeCard(props: CardProps & { shadow?: boolean }) {
  const { shadow, sx } = props;

  return (
    <Card
      {...props}
      elevation={0}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        border: "1px solid rgba(65, 70, 103, 0.24)",
        borderRadius: "20px",
        boxShadow: shadow ? "0px 44px 30px -30px rgba(0, 0, 0, 0.25)" : "none",
        ...sx,
      }}
    />
  );
}
