import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import tokenExpired from "../assets/token-expired.svg";

export default function ExpiredTokenRoute() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const renderMessage = () => {
    if (searchParams) {
      const forgetPasswordParam = searchParams.get("forget-password");
      const tokenExpiredByTimeParam = searchParams.get("expired-by-time");
      const tokenExpiredBySystemParam = searchParams.get("expired-by-system");

      switch (true) {
        case forgetPasswordParam !== null:
          return `Para acessar a Liquid, clique novamente em "esqueci minha senha"`;
        case tokenExpiredByTimeParam !== null:
          return `Para acessar a Liquid, peça a um usuário Admin que reenvie seu convite.`;
        case tokenExpiredBySystemParam !== null:
          return `Link expirado. clique no e-mail mais recente para acessar este conteúdo.`;
        default:
          break;
      }
    }
  };

  const onSignInClick = () => {
    navigate("/signin");
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-end",
        pb: 18,
        width: "100vw",
      }}
    >
      <Box sx={{ width: { pb: 4, sm: "400px", textAlign: "center", xs: "300px" } }}>
        <LqdTypography sx={{ mb: 2 }} textstyle="h3Headline">
          Ops, algo deu errado.
        </LqdTypography>
        <LqdTypography sx={{ mb: 2 }} textstyle="p2Paragraph">
          {renderMessage()}
        </LqdTypography>
        <LqdButton onClick={onSignInClick} sx={{ mb: 1 }}>
          Fazer login
        </LqdButton>
      </Box>
      <img alt="" src={tokenExpired} />
    </Box>
  );
}
