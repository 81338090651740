import { NumberMask, StringMask } from "../types";
import { MaskTypeConfig } from "../types/MaskTypeConfig";

export const isStringifiedMask = (mask: MaskTypeConfig): mask is StringMask => {
  return typeof mask === "object" && mask !== null && "mask" in mask && typeof mask.mask === "string";
};

export const isNumericMask = (mask: MaskTypeConfig): mask is NumberMask => {
  return typeof mask === "object" && mask !== null && "mask" in mask && mask.mask === Number;
};

export const formatCurrency = (value: string): string => {
  const numberValue = parseFloat(value.replace(/[^0-9]/g, "")) / 100;
  return isNaN(numberValue) ? "" : numberValue.toLocaleString("pt-BR", { currency: "BRL", style: "currency" });
};

export const unformatCurrency = (value: string): string => {
  return value.replace(/[^0-9]/g, "");
};
