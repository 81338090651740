import { AxiosPromise } from "axios";
import { put } from "../../common/utils/apiUtils";
import { ProductResponseRaw } from "../types/ProductResponseRaw";

// Register Project Completion Date in Products (Superadmin Endpoint)
export const adminRegisterProjectCompletionDate = (
  productIdList: Array<string>,
  projectCompletionDate: string,
  tenantCode: string
): AxiosPromise<ProductResponseRaw> => {
  return put(`/admin/product/date?product_id_list=${productIdList}&tenant_code=${tenantCode}`, {
    project_completion_date: projectCompletionDate,
  });
};
