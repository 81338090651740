import { put } from "../../common/utils/apiUtils";
import { TenantResponseRaw } from "../../superadmin/types/TenantResponseRaw";

/**
 * Endpoint para atualizar o status de auto arquivamento de um determinando Tenant.
 * @param requestBody Dados de auto arquivamento para atualizar.
 * @returns Retorno do Tenant atualizado, no formato TenantResponseRaw.
 * @remarks Endpoint ADMIN.
 */
export const updateTenantAutoArchiveStatus = (requestBody: TenantResponseRaw["auto_archive"]) => {
  return put<TenantResponseRaw>("/tenants/archive", { auto_archive: requestBody });
};
