import { ProductBankRates } from "../types/Simulation/SBPE/ProductBankRates";
import { ProductBankRatesRaw } from "../types/Simulation/SBPE/ProductBankRatesRaw";

export const formatRawProductBankRates = (productBankRatesRaw: Array<ProductBankRatesRaw>): Array<ProductBankRates> => {
  return productBankRatesRaw.map((bankRate) => ({
    title: `${bankRate.params.max_installment_qty} meses`,
    value: bankRate.params.max_installment_qty,
    valueType: "integer",
  }));
};
