import { ObjectOf } from "../../common/types/ObjectOf";
import NodeData, { ProviderNodeData } from "../types/NodeData";

const getNodeContents = (nodeData: NodeData) => {
  if (nodeData.type === "action") {
    switch (nodeData.action) {
      case "jump":
        return {
          color: "rgba(238, 235, 255, 1)",
          subtitle: "Changes the product flow",
          title: "Jump",
        };
      case "match":
        return {
          color: "rgba(229, 254, 229, 1)",
          subtitle: "Usuário deu match com o produto",
          title: "Aprovado",
        };
      case "unmatch":
        return {
          color: "rgba(254, 223, 216, 1)",
          subtitle: "Usuário não deu match com o produto",
          title: "Reprovado",
        };
      case "moderation":
        return {
          color: "rgba(254, 250, 216, 1)",
          subtitle: "É hora de decisão humana",
          title: "Moderação",
        };
      case "warning":
        return {
          color: "rgba(255, 246, 218, 1)",
          subtitle: "Exibir a mensagem:",
          title: "Atenção",
        };
    }
  } else if (nodeData.type === "providers" || nodeData.type === "global" || !nodeData.type) {
    switch (nodeData.provider) {
      case "serasa_completo":
        return {
          description: [
            `Score Credit provides a score based
        on the financial health of your target. 0 - Bad financial health
        1000 - Good financial health`,
            `By connecting Providers to other flow steps, you can create
      automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            Score: "Score de Crédito",
          },
          subtitle: "Score de Crédito",
          title: "Score de Crédito",
        };
      case "serasa_parcial":
        return {
          description: [
            `Partial Liquid consults a credit bureau to provide information
        about the debts and protests of your targets.`,
            `By connecting providers to other flow steps, you can create
        automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            cpf_cancelled: "CPF está cancelado?",
            cpf_regular: "CPF está regular?",
            debts_sum: "Soma das restrições",
            has_debts: "Possui restrição?",
          },
          subtitle: "Débitos e protestos",
          title: "Liquid Parcial",
        };
      case "serasa_cnpj":
        return {
          description: [
            `Partial Liquid consults a credit bureau to provide information
        about the debts and protests of your targets.`,
            `By connecting providers to other flow steps, you can create
        automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            debts_sum: "Soma das restrições",
            has_debts: "Possui restrição?",
            score: "Score de crédito",
          },
          subtitle: "Restrições e Score de crédito",
          title: "Liquid Pass - CNPJ",
        };
      case "financial_indicators":
        return {
          description: [
            `Liquid Financial Indicator consults a credit bureau to provide information
          about the payment capacity.`,
            `By connecting providers to other flow steps, you can create
          automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            income: "Salário estimado",
            income_commitment: "Comprometimento de renda estimado",
            max_payment: "Capacidade de pagamento mensal estimada",
          },
          subtitle: "Capacidade de pagamento e renda",
          title: "Indicador Financeiro Liquid",
        };
      case "liquidpass":
        return {
          description: [
            `Liquid Data Intelligence does a complete search about your target
          profile, showing information about IPTU, partners, political
          exposure, and vehicles.`,
            `By connecting Providers to other flow steps, you can create
          automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            has_assistances: "Possui assistências?",
            has_partners: "Possui participações societárias?",
            has_vehicles: "Possui veículos?",
          },
          subtitle: "Sociedade, assistências, veículos...",
          title: "Inteligência de Dados Liquid",
        };
      case "liquidpass_cnpj":
        return {
          description: [
            `Liquid Data Intelligence does a complete search about your target
            profile, showing information about IPTU, partners, political
            exposure, and vehicles.,
            By connecting Providers to other flow steps, you can create
            automated decisions based on the conditions you set here.`,
          ],
          kpis: {
            has_partners: "Possui participações societárias?",
            has_vehicles: "Possui veículos?",
          },
          subtitle: "Sociedade e veículos...",
          title: "Inteligência de Dados Liquid - CNPJ",
        };
      case "pluggy":
        return {
          description: [
            `Open Finance Pluggy investigates a financial health of your
        target, showing loan and balance information, in differents time
        periods.`,
            `Connecting Providers to another flow steps you can create
        automatic decisions based on the conditions you set here.`,
          ],
          kpis: {
            estimated_income: "Renda estimada",
            loans_request_3M: "Possui empréstimos nos últimos 3 meses?",
          },
          subtitle: "Empréstimos, créditos, débitos...",
          title: "Open Finance Pluggy",
        };
      case "peer_model":
        return {
          description: [
            `Rating Liquid is based on the risk of making business with your target.`,
            `H -> High risk`,
            `A -> Less risk`,
            `By connecting Providers to other flow steps, you can create automated decisions
            based on the conditions you set here.`,
          ],
          kpis: {
            rating: "Rating Liquid",
          },
          subtitle: "Classificação de Inadimplência",
          title: "Rating Liquid",
        };
      case "open_credit":
        return {
          description: [],
          kpis: {
            active_wallet_table_v30_value: "Valor a vencer em até 30 dias",
            has_prejudice: "Possui prejuízo?",
            risk_total_value: "Risco total",
          },
          subtitle: "Empréstimos, créditos, limites...",
          title: "Open Credit",
        };
      case "register_upload":
        return {
          description: [
            `Permita o envio de documentos de um cartório específico, tanto via WhatsApp como pela plataforma,
            necessários. Este nó funciona somente junto ao nó de Registro de Compra e Venda com Alienação.`,
          ],
          kpis: {
            docs_uploaded: "Algum documento foi adicionado?",
          },
          subtitle: "Para o Registro de Compra e Venda com Alienação",
          title: "Documentos por cartório",
        };
      case "doc_signature":
        return {
          description: [
            `Os nomes que vão aparecer na assinatura do documento serão os mesmos indicados acima.
            A assinatura não é sequencial, ou seja, o documento pode ser assinado em qualquer ordem.`,
          ],
          kpis: {
            signed: "Todos assinaram?",
          },
          subtitle: "Assinatura das partes selecionadas com Certificado e-CPF",
          title: "Portal de Assinaturas e-CPF",
        };
      case "simulation":
        switch (nodeData.options?.simulation_type) {
          case "allotment":
            return {
              description: [
                `Liquid Parameterized Simulation simulates the income commitment of each one of your scenarios.`,
                `By connecting Providers to other flow steps, you can create automated decisions 
                based on the conditions you set here.`,
              ],
              kpis: {
                highlight__desired_income_approval: "Está enquadrado na condição desejada do Simulador?",
                highlight__has_approved_bank: "Está enquadrado em alguma condição do Simulador Parametrizado?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid Parametrizado",
            };
          case "basic":
            return {
              description: [
                `Liquid Basic Simulation simulates the income commitment based on the desired monthly
                payment amount and incomes, providing insights into the necessary income increase.`,
                `By connecting Providers to other flow steps, you can create automated decisions based on
                the conditions you set here.`,
              ],
              kpis: {
                income_approval: "A renda está enquadrada no pagamento mensal pretendido?",
              },
              subtitle: "Comprometimento de renda, capacidade máxima...",
              title: "Simulador Liquid Básico",
            };
          case "morar":
            return {
              description: [
                `Morar Simulation simulates the income commitment based on the desired monthly
                  payment amount and incomes, providing insights into the necessary income increase.`,
                `By connecting Providers to other flow steps, you can create automated decisions based on
                  the conditions you set here.`,
              ],
              kpis: {
                highlight__advance_payment_approved: "Está enquadrado no simulador?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid Morar",
            };
          case "sbpe":
            return {
              description: [
                `Liquid SBPE Simulation combines different bureaus and an IA to simulate bank financing, 
                with information about installment, income, and pre-approval in banks.`,
                `By connecting Providers to other flow steps, you can create automated 
                decisions based on the conditions you set here.`,
              ],
              kpis: {
                highlight__has_approved_bank: "Está enquadrado no fluxo de financiamento?",
                highlight__has_approved_bank_and_building_payment_over_time:
                  "Está enquadrado no fluxo de financiamento e obra?",
                highlight__has_approved_building_payment_over_time: "Está enquadrado no fluxo de obra?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid SBPE",
            };
          case "simulator":
            return {
              description: [
                `This simulator was configured in the simulator builder. There, you can choose 
              the input variables, the functions that will be responsible for bringing important indicators 
              for your analysis, and assemble a customized dashboard.`,
              ],
              kpis: {},
              subtitle: "KPIs customizados, condições personalizadas, etc",
              title: "",
            };
        }
    }
  } else {
    return {
      color: "rgba(240, 246, 255, 1)",
      subtitle: "The target flow starts with:",
      title: "Start Flow",
    };
  }
};

export const getNodeTitle = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.title || nodeData.title;
};

export const getNodeSubtitle = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.subtitle || nodeData.subtitle;
};

export const getNodeColor = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.color || "rgba(249, 249, 250, 1)";
};

export const getNodeDescription = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.description || [];
};

export const getProviderNodeKpisData = (nodeData: ProviderNodeData) => {
  return getNodeContents(nodeData)?.kpis as unknown as ObjectOf<string>;
};

export const kpiDictionary: ObjectOf<string> = {
  Person__SalaryPresumed: "Salário estimado",
  Score: "Score de Crédito",
  active_wallet_table_v30_value: "Valor a vencer em até 30 dias",
  cpf_cancelled: "CPF está cancelado?",
  cpf_regular: "CPF está regular?",
  debts_sum: "Soma das restrições",
  docs_uploaded: "Algum documento foi adicionado?",
  estimated_income: "Renda estimada",
  funding_period: "Periodo Financiamento",
  has_assistances: "Possui assistências?",
  has_debts: "Possui restrição?",
  has_partners: "Possui participações societárias?",
  has_prejudice: "Possui prejuízo?",
  has_vehicles: "Possui veículos?",
  highlight__advance_payment_approved: "Está enquadrado no simulador?",
  highlight__desired_income_approval: "Está enquadrado na condição desejada do Simulador?",
  highlight__has_approved_bank_and_building_payment_over_time: "Está enquadrado no fluxo de financiamento e obra?",
  highlight__has_approved_building_payment_over_time: "Está enquadrado no fluxo de obra?",
  income: "Salário estimado",
  income_approval: "A renda está enquadrada no pagamento mensal pretendido?",
  income_commitment: "Comprometimento de renda estimado",
  incoming: "Renda",
  loans_request_3M: "Possui empréstimos nos últimos 3 meses?",
  max_payment: "Capacidade de pagamento mensal estimada",
  rating: "Rating Liquid",
  risk_total_value: "Risco total",
  score: "Score de Crédito",
  signed: "Assinatura de Documentos",
  value_entry: "Valor Entrada",
  value_total: "Valor Proposta",
};
