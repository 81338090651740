import { AnalysisPendency } from "../types/AnalysisDetails/Analysis/AnalysisPendency";
import { AnalysisPendencyRaw } from "../types/AnalysisDetails/Analysis/AnalysisPendencyRaw";

export const formatRawAnalysisPendency = (rawPendency: AnalysisPendencyRaw): AnalysisPendency => ({
  analysisId: rawPendency.analysis_id,
  choices: rawPendency.choices,
  creation: rawPendency.creation,
  description: rawPendency.description,
  index: rawPendency.index,
  kpi: rawPendency.kpi,
  passId: rawPendency.pass_id,
  pendencyId: rawPendency.pendency_id,
  providerName: rawPendency.provider_name,
  sentLinkTimestamp: rawPendency.sent_link_timestamp,
  tenantCode: rawPendency.tenant_code,
  value: rawPendency.value,
  valueType: rawPendency.value_type,
});
