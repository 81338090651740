import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SBPESimulationHighlight } from "../../../../../../types/Simulation/SBPE/SBPESimulationHighlight";
import { SBPESimulationResult } from "../../../../../../types/Simulation/SBPE/SBPESimulationResult";
import { formatValueToCurrency, formatValueToPercentage } from "../../../../../../utils/analysisDetailsDataFormatter";
import PDFHighestPaymentTermSVG from "./BankSummarySVGs/PDFHighestPaymentTermSVG";
import PDFLowestCetSVG from "./BankSummarySVGs/PDFLowestCetSVG";
import PDFLowestInstallmentPriceSVG from "./BankSummarySVGs/PDFLowestInstallmentPriceSVG";
import PDFLowestInstallmentSacSVG from "./BankSummarySVGs/PDFLowestInstallmentSacSVG";
import PDFSBPESimulatorBankResultCard from "./PDFSBPESimulatorBankResultCard";

type PDFSBPEBottomSimulatorInfoProps = {
  bank: SBPESimulationResult;
  highlight: SBPESimulationHighlight;
};

const styles = StyleSheet.create({
  bankSummaryLabelsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "12px",
    width: "100%",
  },
  sbpeSimulatorBottomColumnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "83%",
  },
  sbpeSimulatorText: {
    color: "rgb(33, 36, 42)",
    fontSize: "16px",
    paddingBottom: "24px",
    paddingLeft: "24px",
    paddingTop: "12px",
  },
});

export default function PDFSBPEBottomSimulatorInfo(props: PDFSBPEBottomSimulatorInfoProps) {
  const { bank, highlight } = props;

  const bankWithLowestCet = highlight?.best_cet_bank;
  const banksWithHighestPaymentTerm = highlight?.banks_with_highest_available_payment_term;
  const banksWithLowestInstallments = highlight?.banks_with_lowest_installments;

  const lowestCet =
    bankWithLowestCet &&
    bankWithLowestCet.name === bank.bank_rate.bank_name &&
    bankWithLowestCet.amortization_type === bank.amortization_type;

  const highestPaymentTerm = banksWithHighestPaymentTerm.some(
    (currentBank) =>
      currentBank.name === bank.bank_rate.bank_name && currentBank.amortization_type === bank.amortization_type
  );

  const lowestInstallmentSacBank = banksWithLowestInstallments.some(
    (currentBank) =>
      currentBank.name === bank.bank_rate.bank_name &&
      currentBank.amortization_type === "sac" &&
      bank.amortization_type === "sac"
  );

  const lowestInstallmentPriceBank = banksWithLowestInstallments.some(
    (currentBank) =>
      currentBank.name === bank.bank_rate.bank_name &&
      currentBank.amortization_type === "price" &&
      bank.amortization_type === "price"
  );

  return (
    <>
      <View style={styles.bankSummaryLabelsContainer}>
        {lowestCet ? <PDFLowestCetSVG /> : null}
        {highestPaymentTerm ? <PDFHighestPaymentTermSVG /> : null}
        {lowestInstallmentPriceBank ? <PDFLowestInstallmentPriceSVG /> : null}
        {lowestInstallmentSacBank ? <PDFLowestInstallmentSacSVG /> : null}
      </View>
      <Text style={styles.sbpeSimulatorText}>Simulador</Text>
      <View style={styles.sbpeSimulatorBottomColumnContainer}>
        <PDFSBPESimulatorBankResultCard title="CET" value={formatValueToPercentage(bank.cet)} />
        <PDFSBPESimulatorBankResultCard
          title="Valor financiamento"
          value={formatValueToCurrency(bank.max_sec_loan_available, "BRL", true)}
        />
        <PDFSBPESimulatorBankResultCard title="LTV" value={formatValueToPercentage(bank.ltv)} />
        <PDFSBPESimulatorBankResultCard title="Taxa" value={formatValueToPercentage(bank.interest_rate)} />
        <PDFSBPESimulatorBankResultCard
          title="1ª parcela"
          value={formatValueToCurrency(bank.final_installment, "BRL", true)}
        />
        <PDFSBPESimulatorBankResultCard
          title="Última parcela"
          value={formatValueToCurrency(bank.final_last_installment, "BRL", true)}
        />
        <PDFSBPESimulatorBankResultCard title="Comp. renda" value={formatValueToPercentage(bank.income_commitment)} />
      </View>
    </>
  );
}
