import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import FrontPageLayout from "../../common/components/FrontPageLayout";
import LiquidLogo from "../../common/components/LiquidLogo";
import signinImg from "../assets/signup.png";
import SignUpForm from "../components/SignUpForm/SignUpForm";

const contentSizes = { lg: "40%", md: "50%", sm: "60%", xs: "70%" };

export default function SignUpRoute() {
  return (
    <FrontPageLayout
      leftContent={
        <Box
          sx={{
            alignItems: "center",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            pt: { md: 8, xs: 4 },
            width: "100%",
          }}
        >
          <Box className="lqd-image-wrapper" sx={{ position: "relative", width: contentSizes }}>
            <img alt="" src={signinImg} style={{ borderRadius: "20px", width: "100%" }} />
            <Box
              sx={{
                background: "conic-gradient(from 180deg, rgba(207, 255, 70, 1), transparent)",
                borderRadius: "42px",
                bottom: "-32px",
                left: { md: "-92px", sm: "-48px", xs: "-24px" },
                position: "absolute",
                pt: { sm: "80%", xs: "70%" },
                width: { sm: "80%", xs: "70%" },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              m: 0,
              width: contentSizes,
            }}
          >
            <LiquidLogo />
            <LqdTypography sx={{ mt: 2 }} textstyle="h4Headline">
              Aumentando seu sucesso com análises inteligentes e dinâmicas.
            </LqdTypography>
            <LqdButton href="/signup#begin" sx={{ display: { sm: "none", xs: "flex" }, mt: 4 }}>
              Começar
            </LqdButton>
          </Box>
        </Box>
      }
      rightContent={<SignUpForm />}
    />
  );
}
