import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import LqdTextField from "../TextField";

interface LqdPhoneInputProps {
  DDI: string;
  DDIOptions: Array<string>;
  dense?: boolean;
  error?: boolean;
  onDDIChange: (value: string) => void;
  onNumberChange: (value: string) => void;
  phoneNumber: string;
  placeholder?: string;
}

export default function LqdPhoneInput(props: LqdPhoneInputProps) {
  const { DDI, DDIOptions, dense, error, onDDIChange, onNumberChange, phoneNumber, placeholder } = props;

  return (
    <>
      <Select
        disableUnderline
        error={error}
        MenuProps={{
          PaperProps: { sx: { borderRadius: 0, mt: "10px" } },
          elevation: 0,
          sx: {
            ".MuiList-root": { py: 0 },
            ".MuiMenuItem-root": {
              ":hover": { backgroundColor: "rgba(221, 225, 240, 1)" },
              backgroundColor: "rgba(239, 241, 249, 1)",
              fontSize: "14px",
              height: "40px",
            },
          },
        }}
        onChange={(event: SelectChangeEvent<string>) => onDDIChange(event.target.value)}
        size="small"
        sx={{
          ".MuiInputBase-input": { color: "rgba(101, 110, 127, 1)" },
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid rgba(212, 215, 220, 1)",
            borderBottomRightRadius: 0,
            borderColor: "rgba(212, 215, 220, 1)",
            borderTopRightRadius: 0,
          },
          height: "48px",
          position: "relative",
          top: "1px",
          width: "82px",
        }}
        value={DDI}
      >
        {DDIOptions.map((DDI) => (
          <MenuItem key={DDI} value={DDI}>
            {DDI}
          </MenuItem>
        ))}
      </Select>
      <LqdTextField
        error={error}
        mask="(00)00000-0000"
        onChange={(event) => onNumberChange(event.target.value)}
        placeholder={placeholder}
        sx={{
          ".MuiInputBase-input": { color: "rgba(101, 110, 127, 1)" },
          border: "1px solid rgba(212, 215, 220, 1)",
          borderBottomLeftRadius: 0,
          borderLeft: "none",
          borderTopLeftRadius: 0,
          height: dense ? "40px" : "48px",
          px: 2,
          width: "calc(100% - 82px)",
        }}
        value={phoneNumber}
      />
    </>
  );
}
