import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

type SmallDialogHeaderInputsProps = {
  body: string;
  title: string;
};

export default function SmallDialogHeaderInputs(props: SmallDialogHeaderInputsProps) {
  const { body, title } = props;

  return (
    <Box sx={{ mt: 5 }}>
      <LqdTypography color="rgba(23, 23, 23, 1)" sx={{ mb: 1 }} textstyle="h5Headline">
        {title}
      </LqdTypography>
      <LqdTypography color="rgba(115, 115, 115, 1)" textstyle="p2Paragraph">
        {body}
      </LqdTypography>
    </Box>
  );
}
