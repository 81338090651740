import { formatValueToPercentage } from "../../analysisDetails/utils/analysisDetailsDataFormatter";

export const formatValueByType = (value: number | string, valueType: string | null): string => {
  switch (valueType) {
    case "currency":
      return Intl.NumberFormat("pt-BR", { currency: "BRL", style: "currency" }).format(Number(value));
    case "decimal":
      return formatValueToPercentage(Number(value));
    case "integer":
      return Number(value).toString();
    case "rating":
      return (value as string)
        .split(",")
        .map((value, index) => `${index > 0 ? " " : ""}${value.toUpperCase()}`)
        .join("");
    default:
      return value.toString();
  }
};
