import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FrontPageLayout from "../../common/components/FrontPageLayout";
import LiquidLogo from "../../common/components/LiquidLogo";
import LqdLoadingPage from "../../common/components/LoadingPage";
import validateToken from "../../signin/api/ValidateToken";
import signinImg from "../../signin/assets/first-login.png";
import UserData from "../../signin/types/UserData";
import RecreatePasswordForm from "../components/RecreatePasswordForm";

const contentSizes = { lg: "40%", md: "50%", sm: "60%", xs: "70%" };

/** Página do fluxo de recriar a senha.
 * Redirecionamento para esta página é feito através do link no e-mail do usuário (é necessário a presença do token na URL).
 */
export default function RecoverPasswordRoute() {
  const handleLiquidErrors = useErrorHandler();
  const navigate = useNavigate();
  const { token } = useParams();

  const [loadingUserData, setLoadingUserData] = useState(true);
  const [userData, setUserData] = useState<UserData>({
    fullname: "",
    tenant_code: "",
    token: "",
    username: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await validateToken(token!);
        setUserData(res.data);
      } catch (error) {
        handleLiquidErrors(error);
        navigate("/token-expired?forget-password=true");
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchData();
  }, []);

  return loadingUserData ? (
    <LqdLoadingPage />
  ) : (
    <FrontPageLayout
      hideLeftContentOnPhone
      leftContent={
        <Box
          sx={{
            alignItems: "center",
            boxSizing: "border-box",
            display: { sm: "flex", xs: "none" },
            flexDirection: "column",
            height: "100%",
            pt: { md: 8, xs: 4 },
            width: "100%",
          }}
        >
          <Box className="lqd-image-wrapper" sx={{ position: "relative", width: contentSizes }}>
            <img alt="" src={signinImg} style={{ borderRadius: "20px", width: "100%" }} />
            <Box
              sx={{
                background: "conic-gradient(from 180deg, rgba(207, 255, 70, 1), transparent)",
                borderRadius: "42px",
                bottom: "-32px",
                left: { md: "-92px", sm: "-48px", xs: "-24px" },
                position: "absolute",
                pt: { sm: "80%", xs: "70%" },
                width: { sm: "80%", xs: "70%" },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              m: 0,
              width: contentSizes,
            }}
          >
            <LiquidLogo />
            <LqdTypography sx={{ mt: 2 }} textstyle="h4Headline">
              Aumentando seu sucesso com conexões inteligentes e dinâmicas.
            </LqdTypography>
            <LqdButton href="/signin#begin" sx={{ display: { sm: "none", xs: "flex" }, mt: 4 }}>
              Começar
            </LqdButton>
          </Box>
        </Box>
      }
      rightContent={<RecreatePasswordForm token={userData.token} />}
    />
  );
}
