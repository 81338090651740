import { LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import useWindowSize from "../../../common/hooks/useWindowSize";
import openCreditFinishedSVG from "../../assets/openCreditFinished.svg";

export default function OpenCreditRefusedState() {
  const { width } = useWindowSize();

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 0 } }}
    >
      <Box>
        <LqdTypography
          sx={{ color: "rgba(33, 36, 42, 1)", textAlign: { lg: "left", xs: "center" } }}
          textstyle={width === "xs" ? "h3Headline" : "h2Headline"}
        >
          Validação cancelada com sucesso!
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(56, 61, 70, 1)", pt: { lg: "30px", xs: 1 }, textAlign: "justify" }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          Caso mude de ideia, é só pedir para enviarem outra autorização a você.
        </LqdTypography>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openCreditFinishedSVG} />
      </Box>
    </Stack>
  );
}
