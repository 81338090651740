import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { LqdShieldIcon } from "@/liquid-components/src/icons";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { onChangingView } from "../../signatureSlice";
import { SignatureViews } from "../../types/signatureViews";
import RegistryNavigationBar from "../RegistryNavigationBar";
import LqdSecureEnvironment from "../SecureEnvironment";
import SignatureHeader from "../SignatureHeader";

export default function SigningWithoutCloud() {
  const dispatch = useAppDispatch();

  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 2 de 2",
        }}
        previusPage={SignatureViews.DOCUMENT_SEARCH}
        step={SignatureViews.DOCUMENT_SEARCH}
      />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdShieldIcon size={40} />
        </Stack>
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Certificado Digital e-CPF
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Você precisa de um certificado digital para assinar este documento. Siga o processo para localizar o
          certificado neste dispositivo.
        </LqdTypography>
        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.LOCAL_SIGNING))}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          Assinar com Certificado Local
        </LqdButton>
        <LqdButton
          onClick={() => dispatch(onChangingView(SignatureViews.CREATE_CERTIFICATE))}
          sx={{
            "&:hover": { cursor: "pointer" },
            alignSelf: "center",
            background: "transparent",
            border: "1px solid rgba(127, 135, 152, 1)",
            color: "rgba(127, 135, 152, 1)",
            mt: 2,
            textAlign: "center",
            width: "100%",
          }}
        >
          Criar um novo Certificado Digital
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
