import { LqdCopyIcon, LqdTypography } from "@/liquid-components/src";
import { Badge, Box, Stack } from "@mui/material";
import { useState } from "react";
import { maskHash } from "../../analysisDetails/utils/maskHash";
import useWindowSize from "../hooks/useWindowSize";

type CopyValueToClipboardBadgeProps = {
  value: string;
};

export default function CopyValueToClipboardBadge(props: CopyValueToClipboardBadgeProps) {
  const { value } = props;

  const { width } = useWindowSize();

  const [showCopyBadge, setShowCopyBadge] = useState<boolean>(false);

  const handleCopyHash = () => {
    if (value === "") return null;

    navigator.clipboard.writeText(value);
    setShowCopyBadge(true);
    setTimeout(() => setShowCopyBadge(false), 2500);
  };

  return (
    <Badge
      badgeContent={
        <Box sx={{ backgroundColor: "rgba(127, 135, 152, 0.08)", borderRadius: 6, p: 1 }}>
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
            Copiado!
          </LqdTypography>
        </Box>
      }
      invisible={!showCopyBadge}
      sx={{ ".MuiBadge-anchorOriginTopRight": { left: "115%", top: "50%" } }}
    >
      <Stack
        alignItems="center"
        direction="row"
        onClick={handleCopyHash}
        sx={{
          ":hover": { cursor: "pointer" },
          backgroundColor: "rgba(245, 246, 248, 1)",
          borderRadius: 8,
          height: "32px",
          pl: 2,
        }}
      >
        <LqdTypography
          color="rgba(127, 135, 152, 1)"
          sx={{ mr: 1 }}
          textstyle={width === "xs" ? "c1Caption" : "p2Paragraph"}
        >
          {value ? maskHash(value) : null}
        </LqdTypography>
        <Stack alignItems="center" sx={{ mr: 2 }}>
          <LqdCopyIcon color="rgba(127, 135, 152, 1)" size={18} />
        </Stack>
      </Stack>
    </Badge>
  );
}
