import { Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  uncheckMark: {
    alignItems: "center",
    backgroundColor: "rgb(254, 223, 216)",
    borderRadius: "50%",
    height: "30px",
    justifyContent: "center",
    marginTop: "35px",
    width: "30px",
  },
});

export default function PDFSBPEInsightUncheckMarkSVG() {
  return (
    <View style={styles.uncheckMark}>
      <Svg height="16" viewBox="0 0 16 16" width="16">
        <Path d="M11.5573 4.01367L4.20898 11.362" stroke="rgb(118, 11, 70)" strokeLineCap="round" strokeWidth="1.2" />
        <Path d="M4.20898 4.01298L11.5573 11.3613" stroke="rgb(118, 11, 70)" strokeLineCap="round" strokeWidth="1.2" />
      </Svg>
    </View>
  );
}
