import { LqdCircularLoader } from "@/liquid-components/src";
import { Box } from "@mui/material";

export default function SimulatorParametrizationLoadingParameters() {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(191, 196, 205, 0.12)",
        borderRadius: "20px",
        display: "flex",
        height: "112px",
        justifyContent: "center",
        margin: "auto",
        mt: 4,
        width: "90%",
      }}
    >
      <LqdCircularLoader size={64} />
    </Box>
  );
}
