import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { SerasaParcialProviderData } from "../../../../../types/AnalysisDetails/Serasa/SerasaParcialProviderData";
import { formatValueToCurrency } from "../../../../../utils/analysisDetailsDataFormatter";
import { institutionDictionary } from "../../../../../utils/analysisDictionaries";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFCPFSerasaCheckTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: SerasaParcialProviderData["check"];
  tableLength: number;
  tableValue?: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableOuterContainer2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithFiveColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  tableWithFiveColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  tableWithFiveColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  tableWithFiveColumns4: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  tableWithFiveColumns5: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  totalValueContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
  },
});

export default function PDFCPFSerasaCheckTable(props: PDFCPFSerasaCheckTableProps) {
  const { currentPage, lastPage, tableData, tableLength, tableValue } = props;

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.tableOuterContainer2}>
        <View style={styles.genericFlexRowContainer}>
          <PDFTableIconOrangeSVG />
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.tableTitle}>Cheques sem fundo</Text>
            {tableLength > 0 ? (
              <View style={styles.genericFlexRowContainer}>
                <Text style={styles.tableCount}>{tableLength}</Text>
                {tableLength > 16 ? (
                  <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
                ) : null}
              </View>
            ) : (
              <Text style={styles.tableCount}>0</Text>
            )}
          </View>
        </View>
        <View style={styles.totalValueContainer}>
          <Text style={styles.tableTotalTitle}>Valor total</Text>
          <Text style={styles.tableTotalValue}>
            {tableValue ? formatValueToCurrency(tableValue, "BRL", true) : "R$ 0,00"}
          </Text>
        </View>
      </View>
      <Text style={styles.tableFullLabel}>Detalhes dos cheques sem fundo mais recentes de cada tipo:</Text>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithFiveColumns1}>Credor</Text>
          <Text style={styles.tableWithFiveColumns2}>Banco e agência</Text>
          <Text style={styles.tableWithFiveColumns3}>Local</Text>
          <Text style={styles.tableWithFiveColumns4}>Data</Text>
          <Text style={styles.tableWithFiveColumns5}>Valor</Text>
        </View>
        {tableData?.map((check, index) => (
          <View key={`${check.bankName}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.tableWithFiveColumns1}>{check.bankName || "Informação não encontrada"}</Text>
            <Text style={styles.tableWithFiveColumns2}>{`${institutionDictionary(check.bankName)} ${
              check.bankAgency
            }`}</Text>
            <Text style={styles.tableWithFiveColumns3}>
              {check.city && check.state
                ? `${capitalizeString(check.city)}, ${check.state}`
                : "Informação não encontrada"}
            </Text>
            <Text style={styles.tableWithFiveColumns4}>{check.occurrenceDate || "Informação não encontrada"}</Text>
            <Text style={styles.tableWithFiveColumns5}>
              {check.value ? formatValueToCurrency(check.value, "BRL", true) : "Informação não encontrada"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
