import { Path, Svg } from "@react-pdf/renderer";

export default function PDFLowestCetSVG() {
  return (
    <Svg height="32" viewBox="0 0 33 32" width="33">
      <Path
        d="M14.1156 27.6916C19.3479 28.8979 24.7465 26.4927 27.349 21.796C29.9515 17.0992 29.1285 11.2467 25.3316 7.44985C21.5348 3.65298 15.6823 2.83 10.9855 5.43249C6.28874 8.03498 3.88363 13.4335 5.08988 18.6659"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path
        d="M5.05155 27.8874L5.45014 25.7153L3.88683 24.1556C3.75695 24.026 3.71191 23.8339 3.77065 23.6601C3.82938 23.4862 3.9817 23.3608 4.16356 23.3366L6.35248 23.0445L7.35277 21.0757C7.43588 20.9121 7.60466 20.8099 7.78814 20.812C7.97161 20.8141 8.13796 20.9203 8.21724 21.0857L9.17138 23.0772L11.3529 23.4202C11.5342 23.4486 11.6835 23.5776 11.7382 23.7527C11.7929 23.9279 11.7433 24.1189 11.6105 24.2454L10.0113 25.7684L10.3593 27.9491C10.3882 28.1303 10.3118 28.3122 10.1621 28.4183C10.0124 28.5245 9.81542 28.5364 9.65402 28.4491L7.71152 27.3988L5.74501 28.4036C5.58163 28.487 5.38502 28.4705 5.23785 28.361C5.09067 28.2514 5.01845 28.0678 5.05155 27.8874V27.8874Z"
        fillRule="evenodd"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.2"
      />
      <Path d="M13.6396 19.1807L19.9981 12.8223" stroke="rgb(127, 135, 152)" strokeLineCap="round" strokeWidth="1.5" />
      <Path
        d="M20.2966 18.9468C20.445 19.0951 20.445 19.3343 20.2966 19.4812C20.1483 19.6296 19.9091 19.6296 19.7622 19.4812C19.6138 19.3328 19.6138 19.0936 19.7622 18.9468C19.9075 18.7984 20.1483 18.7984 20.2966 18.9468"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.5"
      />
      <Path
        d="M13.8784 12.5177C14.0268 12.6661 14.0268 12.9068 13.8784 13.0536C13.7301 13.202 13.4894 13.202 13.3425 13.0536C13.1941 12.9053 13.1941 12.6646 13.3425 12.5177C13.4909 12.3709 13.7301 12.3709 13.8784 12.5177"
        stroke="rgb(127, 135, 152)"
        strokeLineCap="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
}
