import { LqdIconProps } from "../../types/IconProps";

export default function LqdCheckboxOnIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 18 18" width={size} xmlns="http://www.w3.org/2000/svg">
      <rect
        fill={color}
        height="9.01153"
        rx="0.711053"
        transform="rotate(44.1258 13.0771 5)"
        width="1.42211"
        x="13.0771"
        y="5"
      />
      <rect
        fill={color}
        height="5.12882"
        rx="0.711053"
        transform="rotate(-45 4 8.98828)"
        width="1.42211"
        x="4"
        y="8.98828"
      />
      <rect height="16.8" rx="1.4" stroke={color} strokeWidth="1.2" width="16.8" x="0.6" y="0.6" />
    </svg>
  );
}
