import { capitalizeString } from "@common/utils/capitalize";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { ProviderData } from "../../../../../types/AnalysisDetails/Provider/ProviderData";
import { formatValueToPercentage } from "../../../../../utils/analysisDetailsDataFormatter";
import PDFCNPJRestrictionCard from "../PDFCNPJRestrictionCard";

type PDFCNPJSerasaRestrictionsProps = {
  serasaCNPJ: ProviderData["serasa_cnpj"];
};

const styles = StyleSheet.create({
  blockedRestrictionText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "10px",
    paddingTop: "40px",
  },
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  restrictionText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
});

export default function PDFCNPJSerasaRestrictions(props: PDFCNPJSerasaRestrictionsProps) {
  const { serasaCNPJ } = props;

  const statusRegistration =
    serasaCNPJ?.report.registration.statusRegistration.split(" ")[
      serasaCNPJ?.report.registration.statusRegistration.split(" ").length - 1
    ] || "Não encontrado";
  const defaultRate = Number(serasaCNPJ?.report.score.defaultRate) / 100;

  const nonPaymentProb = () => {
    switch (true) {
      case serasaCNPJ?.report.score.defaultRate === "00000":
        return "-";
      case defaultRate && typeof defaultRate === "number":
        return formatValueToPercentage((defaultRate as number) / 100);
      default:
        return "Não encontrado";
    }
  };

  return (
    <View minPresenceAhead={40}>
      {serasaCNPJ?.exec_error ? (
        <Text style={styles.blockedRestrictionText}>Restrições</Text>
      ) : (
        <>
          <Text style={styles.restrictionText}>Restrições</Text>
          <View style={styles.generalInfoCardContainer}>
            <PDFCNPJRestrictionCard title="Score de Crédito" value={serasaCNPJ?.report.score.score as number} />
            <PDFCNPJRestrictionCard title="Regularização Cadastral" value={capitalizeString(statusRegistration)} />
            <PDFCNPJRestrictionCard title="Possui Restrição?" value={serasaCNPJ?.has_debts ? "Sim" : "Não"} />
            <PDFCNPJRestrictionCard title="Probabilidade de Não Pagamento" value={nonPaymentProb()} />
          </View>
        </>
      )}
    </View>
  );
}
