import { LqdCircularLoader, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

export default function DefaultLoadingScreen() {
  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column", height: "100vh", justifyContent: "center" }}
    >
      <LqdCircularLoader size={80} />
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", py: 2.5, textAlign: "center" }} textstyle="h5Headline">
        Carregando informações...
      </LqdTypography>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", textAlign: "center" }}>
        Enquanto carregamos suas informações, não feche a página.
      </LqdTypography>
    </Box>
  );
}
