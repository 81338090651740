import { Stack, StackProps } from "@mui/material";

export default function LqdDialogActions(props: StackProps) {
  return (
    <Stack
      {...props}
      direction="column-reverse"
      justifyContent="flex-end"
      spacing={2}
      sx={{ margin: "auto", mt: 3.5, width: "fit-content" }}
    />
  );
}
