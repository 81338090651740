import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import { AnalysisDetailsStep } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import PDFTrupstadLineSVG from "./StepSVG/PDFTrupstadLineSVG";
import PDFTrustpadWarningSVG from "./StepSVG/PDFTrustpadWarningSVG";

type PDFTrustpadPeerWarningStepCardProps = {
  lastIndex?: boolean;
  lineColor: string;
  step: AnalysisDetailsStep;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  stepDate: {
    color: "rgb(127, 135, 152)",
    fontSize: "12px",
  },
  stepMainText: {
    color: "rgb(33, 36, 42)",
    fontSize: "14px",
  },
  stepTitle: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  trustpadPeerMatchStepCardContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    marginVertical: "20px",
  },
  trustpadPeerMatchStepCardDescriptionContainer: {
    margin: "0 auto",
    paddingLeft: "24px",
    width: "70%",
  },
  warningOrangeContainer: {
    backgroundColor: "rgb(255, 246, 218)",
    borderRadius: "20px",
    margin: "0 auto",
    padding: "24px",
    width: "70%",
  },
});

export default function PDFTrustpadPeerWarningStepCard(props: PDFTrustpadPeerWarningStepCardProps) {
  const { lastIndex, lineColor, step } = props;

  return (
    <View minPresenceAhead={40} style={styles.trustpadPeerMatchStepCardContainer}>
      <View style={styles.genericFlexColumnContainer}>
        <PDFTrustpadWarningSVG />
        {!lastIndex ? <PDFTrupstadLineSVG color={lineColor} /> : null}
      </View>
      <View style={styles.warningOrangeContainer}>
        <Text style={styles.stepDate}>{format(new Date(step.matchAt * 1000), "MMMM dd yyyy hh:mm")}</Text>
        <Text style={styles.stepTitle}>Atenção</Text>
        <Text style={styles.stepMainText}>{step.warningMessage}</Text>
      </View>
    </View>
  );
}
