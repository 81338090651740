import { Path, Rect, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
    paddingLeft: "12px",
    paddingTop: "5px",
  },
});

export default function PDFDefaultBankSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 30 30">
        <Path
          d="M8.35547 20.1104H7.10547C5.37958 20.1104 3.98047 18.7112 3.98047 16.9854V8.23535C3.98047 6.50946 5.37958 5.11035 7.10547 5.11035H20.8555C22.5814 5.11035 23.9805 6.50946 23.9805 8.23535V10.1104"
          stroke="rgb(65, 70, 103)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Rect
          height="15"
          rx="2.5"
          stroke="rgb(65, 70, 103)"
          strokeLineCap="round"
          strokeWidth="1.2"
          width="18.75"
          x="8.98047"
          y="10.1104"
        />
        <Path d="M8.98047 15.1104H27.7305" stroke="rgb(65, 70, 103)" strokeLineCap="round" strokeWidth="1.2" />
        <Path d="M15.8555 20.1104H13.3555" stroke="rgb(65, 70, 103)" strokeLineCap="round" strokeWidth="1.2" />
      </Svg>
    </View>
  );
}
