import { StyleSheet, View } from "@react-pdf/renderer";
import { SBPESimulationResult } from "../../../../../../types/Simulation/SBPE/SBPESimulationResult";
import PDFSBPESimulatorGrayCardHeaderContent from "./PDFSBPESimulatorGrayCardHeaderContent";
import PDFSBPESimulatorGrayCardMainContent from "./PDFSBPESimulatorGrayCardMainContent";

type PDFSBPEUpperSimulatorInfoProps = {
  bank: SBPESimulationResult;
};

const styles = StyleSheet.create({
  sbpeSimulatorGrayCardContainer: {
    backgroundColor: "rgb(247, 247, 249)",
    borderRadius: "20px",
    marginTop: "10px",
    paddingVertical: "10px",
    width: "100%",
  },
});

export default function PDFSBPEUpperSimulatorInfo(props: PDFSBPEUpperSimulatorInfoProps) {
  const { bank } = props;

  return (
    <View minPresenceAhead={40} style={styles.sbpeSimulatorGrayCardContainer}>
      <PDFSBPESimulatorGrayCardHeaderContent bank={bank} />
      <PDFSBPESimulatorGrayCardMainContent bank={bank} />
    </View>
  );
}
