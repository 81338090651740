import { get } from "../../common/utils/apiUtils";
import { AnalysisStepsResponseRaw } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";

/**
 * Endpoint que busca os steps de uma conexão.
 * @param analysisId ID da conexão.
 * @returns Steps da conexão, no formato AnalysisStepsResponseRaw.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getAnalysisDetailsSteps = (analysisId: string) =>
  get<AnalysisStepsResponseRaw>(`/analysis/${analysisId}/steps`);
