import {
  LqdButton,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdSearch,
  LqdTypography,
} from "@/liquid-components/src";
import { highlightText } from "@common/utils/highlightText";
import { normalizeText } from "@common/utils/normalizeText";
import { Avatar, Box } from "@mui/material";
import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantResponse } from "../../superadmin/types/TenantResponse";
import { TenantResponseRaw } from "../../superadmin/types/TenantResponseRaw";

type TenantSelectionModalProps = {
  isLoading: boolean;
  onCloseMethod: () => void;
  onConfirmMethod: (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>, mfaCode?: string) => void;
  open: boolean;
  selectedTenantCode: string;
  setSelectedTenantCode: (tenantCode: string) => void;
  tenantList: Array<TenantResponse> | Array<TenantResponseRaw>;
};

export default function TenantSelectionModal(props: TenantSelectionModalProps) {
  const { isLoading, onCloseMethod, onConfirmMethod, open, selectedTenantCode, setSelectedTenantCode, tenantList } =
    props;

  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  // useEffect para limpar o estado de searchTerm e setSelectedTenantCode quando o modal for fechado
  useEffect(() => {
    if (!open) {
      setSearchTerm("");
      setSelectedTenantCode("");
    }
  }, [open]);

  const filteredTenantList = tenantList.filter(
    (tenant) =>
      normalizeText(tenant.contact.name).includes(normalizeText(searchTerm)) ||
      normalizeText(tenant.code).includes(normalizeText(searchTerm))
  );

  return (
    <LqdDialog onClose={onCloseMethod} open={open} sx={{ ".MuiPaper-root": { padding: 5 } }}>
      <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 0.5, mt: 2 }} textstyle="h4Headline">
        {t("Empresas Disponíveis")}
      </LqdTypography>
      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
        Selecione a empresa que será administrada:
      </LqdTypography>
      <Box sx={{ mt: 2, width: "95%" }}>
        <LqdSearch
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={t("Buscar empresa")}
          value={searchTerm}
        />
      </Box>
      <Box
        sx={{
          "::-webkit-scrollbar": { height: "8px" },
          "::-webkit-scrollbar-track": { border: "none" },
          height: "300px",
          mt: 3,
          overflow: "auto",
          width: { sm: "400px", xs: "275px" },
        }}
      >
        {filteredTenantList.length > 0 ? (
          filteredTenantList.map((tenant, index) => (
            <Box
              key={tenant.code}
              onClick={() => setSelectedTenantCode(tenant.code)}
              sx={{
                ":hover": { outline: "2px solid rgba(127, 135, 152, 0.4)" },
                alignItems: "center",
                backgroundColor: "rgba(191, 196, 205, 0.12)",
                borderRadius: "20px",
                boxSizing: "border-box",
                cursor: "pointer",
                display: "flex",
                height: "90px",
                margin: "auto",
                mb: index === 0 ? 0 : 1.5,
                mr: 1.5,
                mt: index === 0 ? 0.5 : 1.5,
                outline: selectedTenantCode === tenant.code ? "2px solid rgba(191, 196, 205, 0.32)" : "none",
                width: "calc(100% - 16px)",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Avatar
                  alt="Imagem da Empresa"
                  src="/img/generic-product.png"
                  sx={{ height: "40px", ml: "20px", mr: 1.5, width: "40px" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
                    {highlightText(tenant.contact.name, searchTerm)}
                  </LqdTypography>
                  <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ pt: 0.5 }} textstyle="p2Paragraph">
                    {tenant.code}
                  </LqdTypography>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", height: "100%", justifyContent: "center" }}>
            <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="h5Headline">
              {t("Não foram encontradas empresas com esse nome ou código.")}
            </LqdTypography>
          </Box>
        )}
      </Box>
      <LqdDialogActions sx={{ mt: 2 }}>
        <LqdButton disabled={isLoading || !filteredTenantList.length} onClick={onCloseMethod} type="outlineTertiary">
          {t("Cancelar")}
        </LqdButton>
        <LqdButton disabled={isLoading || !filteredTenantList.length || !selectedTenantCode} onClick={onConfirmMethod}>
          {isLoading ? <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} /> : t("Confirmar")}
        </LqdButton>
      </LqdDialogActions>
    </LqdDialog>
  );
}
