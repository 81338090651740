import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Stack, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { simulatorBuilderChanged } from "../../../../simulatorBuilderSlice";
import { SimulatorResultTableColumn } from "../../../../types/SimulatorDashboard";

const CHAR_LIMIT = 25;

type SimulatorVariableNameFormProps = {
  columnNameBeingChanged: SimulatorResultTableColumn;
  firstColumnRow?: boolean;
  resetView: () => void;
  setEditRowProps?: Dispatch<
    SetStateAction<{
      columnIndex: number;
      rowIndex: number;
      tableIndex: number;
      tableRowName: string;
    } | null>
  >;
};

/**
 * Dumb component que renderiza a tela com input para edição de nome de variável do builder de simulador.
 */
export default function SimulatorColumnNameForm(props: SimulatorVariableNameFormProps) {
  const { columnNameBeingChanged, firstColumnRow, resetView, setEditRowProps } = props;

  const dispatch = useAppDispatch();

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const columnActualName = columnNameBeingChanged?.columnName;
  const [newColumnName, setNewColumnName] = useState<string>(firstColumnRow ? "" : columnActualName || "");

  const tableContent = [...simulator.dashboard.tables];

  const showNameLengthAlert = newColumnName.length > CHAR_LIMIT;
  const maySubmit =
    !showNameLengthAlert &&
    (columnActualName ? newColumnName !== columnNameBeingChanged.columnName : true) &&
    newColumnName.length > 3;

  // sets the new column name value
  const changeColumnName = (tableColumnBeingChanged: SimulatorResultTableColumn, newColumnName: string) => {
    const tables = tableContent.map((table) => {
      const newColumns = table.columns.map((column) => {
        if (column.columnId === tableColumnBeingChanged.columnId) {
          return { ...column, columnName: newColumnName };
        }
        return column;
      });
      return { ...table, columns: newColumns };
    });

    const simulatorDashboard = {
      cards: simulator.dashboard.cards,
      highlight: simulator.dashboard.highlight,
      tables: [...tables],
    };

    dispatch(simulatorBuilderChanged({ key: "dashboard", value: simulatorDashboard }));
    resetView();
  };

  return (
    <Stack
      justifyContent="center"
      sx={{ height: "100%", margin: "auto", maxWidth: "874px", px: { sm: 0, xs: 3 }, width: "100%" }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        Qual o nome da {columnActualName ? "" : "nova"} {firstColumnRow ? "linha" : "coluna"}?
      </LqdTypography>
      <TextField
        helperText={
          showNameLengthAlert ? (
            <LqdTypography color="rgba(246, 61, 94, 1)" textstyle="c1Caption">
              Nome inválido
            </LqdTypography>
          ) : null
        }
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={({ target: { value } }) => {
          setNewColumnName(value);
          if (firstColumnRow) {
            setEditRowProps!((prevProps) => ({
              ...prevProps,
              columnIndex: prevProps?.columnIndex || 0,
              rowIndex: prevProps?.rowIndex || 0,
              tableIndex: prevProps?.tableIndex || 0,
              tableRowName: value,
            }));
          }
        }}
        onKeyUp={(key) =>
          key.key === "Enter" && maySubmit ? () => changeColumnName(columnNameBeingChanged, newColumnName) : null
        }
        placeholder={`Digite o nome da ${firstColumnRow ? "linha" : "coluna"}`}
        sx={{
          "& .MuiInput-underline:after": { borderBottomColor: "2px solid rgba(41, 176, 160, 1)" },
          "& .MuiInput-underline:before": { borderBottomColor: "rgba(212, 215, 220, 1)" },
          ".MuiFormHelperText-root": {
            position: { sm: "absolute", xs: "relative" },
            top: { sm: "58px", xs: "0px" },
            width: "fit-content",
          },
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          height: "56px",
          mt: 1.5,
          width: "100%",
        }}
        value={newColumnName}
        variant="standard"
      />
      <LqdTypography
        color={showNameLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)"}
        sx={{ display: "block", opacity: newColumnName.length > 0 ? 1 : 0.5, textAlign: "right" }}
        textstyle="p2Paragraph"
      >
        {`${newColumnName.length}/${CHAR_LIMIT} caracteres`}
      </LqdTypography>
      <Stack direction="row" justifyContent="flex-end" spacing={3} sx={{ mt: 3 }}>
        <LqdButton
          disabled={!maySubmit}
          onClick={() => {
            if (firstColumnRow) {
              resetView();
            } else {
              changeColumnName(columnNameBeingChanged, newColumnName);
            }
          }}
        >
          {columnActualName ? "Atualizar" : "Avançar"}
        </LqdButton>
      </Stack>
    </Stack>
  );
}
