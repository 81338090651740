import { LqdButton, LqdSearch, LqdTypography } from "@/liquid-components/src";
import { normalizeText } from "@common/utils/normalizeText";
import { Box, Divider, Stack } from "@mui/material";
import { t } from "i18next";
import { Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled } from "../../common/commonSlice";
import { ObjectOf } from "../../common/types/ObjectOf";
import { User } from "../../users/types/User";
import { createGroupModalStepNext, editGroupFormStepChangedTo } from "../groupsSlice";
import UserCard from "./UserCard";

type GroupFormStep3Props = {
  editing?: boolean;
  groupUsers: ObjectOf<User>;
  setGroupUsers: (value: ObjectOf<User>) => void;
};

export default function GroupFormStep3(props: GroupFormStep3Props) {
  const { editing, groupUsers, setGroupUsers } = props;
  const dispatch = useAppDispatch();

  const { users } = useAppSelector((state) => state.users);

  const { groupBeingEdited } = useAppSelector((state) => state.groups);
  const usersAlreadyAddedToGroup = Object.values(groupBeingEdited?.users || {}).map((userEmail) => users[userEmail]);

  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const avaliableSearchUsers = Object.values(users).filter(
    (user) =>
      normalizeText(user.name).startsWith(normalizeText(search)) && (user.active || (!user.active && user.pending))
  );
  const searchedUsers = search ? avaliableSearchUsers : [];
  const allUsersAddedToGroup: boolean = Object.keys(users).length === Object.keys(groupUsers).length;

  /**
   *  Caso seja um novo grupo em ciração, avança de etapa.
   *  Caso seja um grupo existente em edição, pula para a última etapa do GroupForm.
   * @returns void
   */
  const handleSubmit = () => {
    if (editing) {
      dispatch(editGroupFormStepChangedTo(4));
    } else {
      dispatch(createGroupModalStepNext());
    }
  };

  /**
   * Adiciona o usuário selecionado no grupo
   * @param user Usuário a ser adicionado ao grupo
   * @returns void
   */
  const addUser = (user: User) => {
    setGroupUsers({ ...groupUsers, [user.email]: user });
    setSearch("");
  };

  /**
   *  Remove um usuário do grupo, após a confirmação no dialog de remoção
   * @returns void
   * @remaks Esse dialog é exibido na execução da função onRemoveUser
   */
  const onRemoveUserConfirm = (userEmail: string) => {
    const updatedSelectedUsers = { ...groupUsers };
    delete updatedSelectedUsers[userEmail];
    setGroupUsers(updatedSelectedUsers);
    dispatch(dialogCalled(null));
  };

  /**
   * Adiciona todos os usuários possíveis de adição no grupo
   * @returns void
   */
  const addEveryUsers = () => {
    setGroupUsers(users);
  };

  /**
   * Remove todos os usuários já adicionados ao grupo
   * @returns void
   */
  const removeEveryUsers = () => {
    const noneUserObject: ObjectOf<User> = {};
    setGroupUsers(noneUserObject);
  };

  /**
   * Exibe o dialog de confirmação de remoção do usuário do grupo
   * @param userEmail endereço de email do usuário
   * @returns void
   */
  const onRemoveUser = (userEmail: string) => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Cancelar" }, { onClick: () => onRemoveUserConfirm(userEmail), title: "Sim" }],
        body: "Tem certeza que deseja remover o usuário do grupo selecionado?",
        title: "Remover usuário",
        type: "default",
      })
    );
  };

  /**
   * Verifica se duas listas de usuários são iguais, quando é feita a verificação do maySubmit
   * @returns true or false
   */
  const haveSameUsers = (groupUsers: ObjectOf<User>, usersAlreadyAddedToGroup: Array<User>): boolean => {
    const selectedUsersList = Object.values(groupUsers);
    return (
      selectedUsersList
        .map((user) => user.email)
        .sort()
        .join(",") ===
      usersAlreadyAddedToGroup
        .map((user) => user.email)
        .sort()
        .join(",")
    );
  };

  const maySubmit = editing
    ? !haveSameUsers(groupUsers, usersAlreadyAddedToGroup)
    : Object.values(groupUsers).length > 0;

  /**
   * Renderiza uma lista de usuários buscados na tela
   * @returns JSX Component: lista de card de usuários
   * @remarks Esta renderização é feita após o usuário clicar na barra de pesquisa para buscar um usuário
   */
  const renderFilteredUsers = () => {
    const sortedUsers = Object.values(users).sort((a, b) => a.name.localeCompare(b.name));
    const sortedSearchUsers = searchedUsers.sort((a, b) => a.name.localeCompare(b.name));

    switch (true) {
      case search === "":
        return sortedUsers.map((user) => {
          const added = Boolean(groupUsers[user.email]);
          return (
            <Fragment key={user.email}>
              <UserCard
                added={added}
                onAddUserClick={() => addUser(user)}
                onRemoveUserClick={() => onRemoveUser(user.email)}
                setIsSearching={setIsSearching}
                user={user}
              />
              <Divider />
            </Fragment>
          );
        });
      case searchedUsers.length > 0:
        return sortedSearchUsers.map((user) => {
          const added = Boolean(groupUsers[user.email]);
          return (
            <Fragment key={user.email}>
              <UserCard
                added={added}
                onAddUserClick={() => addUser(user)}
                onRemoveUserClick={() => onRemoveUser(user.email)}
                setIsSearching={setIsSearching}
                user={user}
              />
              <Divider />
            </Fragment>
          );
        });
      default:
        return (
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(127, 135, 152, 0.08)",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "90px",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <LqdTypography textstyle="p2Paragraph">{t("Não há usuários com este nome.")}</LqdTypography>
          </Box>
        );
    }
  };

  /**
   * Renderiza uma lista de usuários pertencentes ao grupo na tela
   * @returns JSX Component: lista de card de usuários
   * @remarks Esta renderização é feita antes do usuário clicar na barra de pesquisa para buscar um usuário
   */
  const renderUserSearch = () => {
    const sortedGroupUsers = Object.values(groupUsers).sort((a, b) => a.name.localeCompare(b.name));

    switch (isSearching) {
      case false:
        return Object.values(groupUsers).length > 0 ? (
          sortedGroupUsers.map((user) => (
            <Fragment key={user.email}>
              <UserCard
                added={Boolean(groupUsers[user.email])}
                onAddUserClick={() => addUser(user)}
                onRemoveUserClick={() => onRemoveUser(user.email)}
                user={user}
              />
              <Divider />
            </Fragment>
          ))
        ) : (
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(127, 135, 152, 0.08)",
              borderRadius: "20px",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "90px",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <LqdTypography sx={{ color: "rgba(65, 70, 103, 1)" }} textstyle="p2Paragraph">
              {t("Você ainda não tem usuários neste grupo.")}
            </LqdTypography>
          </Box>
        );
      case true:
        return renderFilteredUsers();
      default:
        return;
    }
  };

  return (
    <Box sx={{ alignItems: "center", display: "flex", height: "90vh", justifyContent: "center", width: "100%" }}>
      <Box sx={{ display: "flex", flexFlow: "column", pt: { lg: 7, md: 4 }, px: 5, width: { lg: "60vw", md: "80vw" } }}>
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
          {t("Adicione os usuários")}
        </LqdTypography>
        <Box
          onBlur={() => setIsSearching(false)}
          onClick={() => setIsSearching(true)}
          sx={{ boxSizing: "border-box", height: "fit-content", py: 1.5, width: "100%" }}
          tabIndex={-1}
        >
          <LqdSearch onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar" value={search} />
        </Box>
        <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>{renderUserSearch()}</Box>
        <Stack direction="row" justifyContent="flex-end" spacing={3} sx={{ mt: 3, width: "100%" }}>
          <LqdButton onClick={allUsersAddedToGroup ? removeEveryUsers : addEveryUsers} type="outlineTertiary">
            {allUsersAddedToGroup ? t("Remover todos") : t("Adicionar todos")}
          </LqdButton>
          <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
            {t("Avançar")}
          </LqdButton>
        </Stack>
        <Box sx={{ mt: 3, textAlign: "right" }}></Box>
      </Box>
    </Box>
  );
}
