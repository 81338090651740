import { LqdTypography } from "@/liquid-components/src";
import { Stack } from "@mui/material";

type AutomationFieldCardProps = {
  subtitle: string;
  title: string;
};

export default function AutomationFieldCard(props: AutomationFieldCardProps) {
  const { subtitle, title } = props;

  return (
    <Stack
      alignItems="center"
      spacing="10px"
      sx={{ border: "1px solid rgba(240, 241, 243, 1)", borderRadius: "20px", px: 2.5, py: 4 }}
    >
      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="h5Headline">
        {title}
      </LqdTypography>
      <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="p2Paragraph">
        {subtitle}
      </LqdTypography>
    </Stack>
  );
}
