import { normalizeText } from "@common/utils/normalizeText";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { SimulatorFunctionTemplate } from "@simulatorBuilder/types/SimulatorFunctionTemplate";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { translateFunctionName } from "@simulatorBuilder/utils/translateFunctionName";

type HandleDebouncedDispatchArgs = {
  formattedInputText?: string;
  functionTemplates: Array<SimulatorFunctionTemplate>;
  inputText: string;
  simulator: Simulator;
  type: string;
};

/**
 * Manipula as mudanças na busca do simulador.
 *
 * @param {string} args.formattedInputText - O texto de entrada formatado.
 * @param {Array} args.functionTemplates - Os templates de função.
 * @param {string} args.inputText - O texto de entrada.
 * @param {Object} args.simulator - O simulador.
 * @param {string} args.type - O tipo de evento (texto ou clique).
 *
 * @returns {Object} O resultado da busca contendo blocos, funções, texto pesquisado e variáveis filtradas.
 */
export const handleSimulatorTesterSearchChanged = ({
  formattedInputText,
  functionTemplates,
  inputText,
  simulator,
  type,
}: HandleDebouncedDispatchArgs) => {
  switch (type) {
    case "text": {
      const variablesFiltered = inputText
        ? simulator.variables.filter(
            (variable: SimulatorVariable) =>
              normalizeText(variable.name).includes(normalizeText(inputText)) ||
              normalizeText(variable.label).includes(normalizeText(inputText)) ||
              normalizeText(variable.value).includes(normalizeText(inputText))
          )
        : [];

      const blocksFiltered = inputText
        ? simulator.blocks.filter(
            (block: SimulatorFunctionBlock) =>
              normalizeText(block.name).includes(normalizeText(inputText)) && block.name !== ""
          )
        : [];

      const functionsFiltered = inputText
        ? simulator.blocks.flatMap((block: SimulatorFunctionBlock) =>
            block.functions.filter(
              (func) =>
                normalizeText(translateFunctionName(func.function_name, functionTemplates) || "").includes(
                  normalizeText(inputText)
                ) && func.function_name !== ""
            )
          )
        : [];

      return {
        blocks: blocksFiltered,
        functions: functionsFiltered,
        searchedText: inputText,
        variables: variablesFiltered,
      };
    }
    case "click":
      return {
        blocks:
          simulator.blocks.filter((b: SimulatorFunctionBlock) =>
            b.functions.some(
              (f) =>
                Object.values(f.variables).some((value) => value === formattedInputText) ||
                Object.values(f.result).some((value) => value === formattedInputText)
            )
          ) || [],
        functions: [],
        searchedText: inputText,
        variables: simulator.variables.filter((v) => v.label === inputText) || [],
      };

    default:
      return {
        blocks: [],
        functions: [],
        searchedText: inputText,
        variables: [],
      };
  }
};
