import { LqdEditIcon, LqdIconButton, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { handleGlobalProviders } from "../../../../products/utils/handleGlobalProviders";
import { createTenantModalStepGoTo, editTenantModalStepGoTo } from "../../../adminSlice";
import { TenantCreateRaw } from "../../../types/TenantCreateRaw";
import { TenantResponse } from "../../../types/TenantResponse";

type TenantSummaryCardsProps = {
  checkedProviders: Array<string>;
  checkedSegments: Array<string>;
  tenant: TenantCreateRaw | TenantResponse;
};

export default function TenantSummaryCards(props: TenantSummaryCardsProps) {
  const { checkedProviders, checkedSegments, tenant } = props;

  const dispatch = useAppDispatch();

  const { createTenant, providers, segments } = useAppSelector((state) => state.admin);

  const uniqueProviderList = handleGlobalProviders(Object.values(providers), checkedProviders);

  const onGoToStep = (step: 1 | 2 | 3 | 4) => () => {
    dispatch(createTenant ? createTenantModalStepGoTo(step) : editTenantModalStepGoTo(step));
  };

  return (
    <Box sx={{ width: "520px" }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 3, mt: -25 }} textstyle="h5Headline">
        Resumo
      </LqdTypography>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(191, 196, 205, 0.12)",
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          p: 3,
        }}
      >
        <Box>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="c1Caption">
            Nome da empresa
          </LqdTypography>
          <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
            {tenant.contact.name}
          </LqdTypography>
        </Box>
        <LqdIconButton onClick={onGoToStep(1)} type="ghostPrimary">
          <LqdEditIcon color="rgba(33, 36, 42, 1)" />
        </LqdIconButton>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(191, 196, 205, 0.12)",
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          p: 3,
        }}
      >
        <Box>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="c1Caption">
            Segmentos
          </LqdTypography>
          {checkedSegments.map((segmentId) => (
            <LqdTypography key={segmentId} sx={{ color: "rgba(33, 36, 42, 1)", mt: 0.5 }} textstyle="h5Headline">
              {segments[segmentId].name}
            </LqdTypography>
          ))}
        </Box>
        <LqdIconButton onClick={onGoToStep(2)} type="ghostPrimary">
          <LqdEditIcon color="rgba(33, 36, 42, 1)" />
        </LqdIconButton>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(191, 196, 205, 0.12)",
          borderRadius: 5,
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          p: 3,
        }}
      >
        <Box>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="c1Caption">
            Providers
          </LqdTypography>
          {uniqueProviderList.map((providerId) => (
            <LqdTypography key={providerId} sx={{ color: "rgba(33, 36, 42, 1)", mt: 0.5 }} textstyle="h5Headline">
              {providers[providerId].title}
            </LqdTypography>
          ))}
        </Box>
        <LqdIconButton onClick={onGoToStep(3)} type="ghostPrimary">
          <LqdEditIcon color="rgba(33, 36, 42, 1)" />
        </LqdIconButton>
      </Box>
    </Box>
  );
}
