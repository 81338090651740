import { ObjectOf } from "../../common/types/ObjectOf";

export const automationFilterDictionary: ObjectOf<string> = {
  "!=": "Diferente de",
  "==": "Igual a",
  "id-tipo": "ID Tipo",
  "id-tipo__placeholder": "Insira o ID Tipo aqui",
  "mail-addresses": "Para",
  "mail-addresses__placeholder": "Selecione os e-mails para recebimento",
  match: "Aprovado",
  moderation: "Moderação",
  "pdf-cv-reserva": "Adicionar PDF nos Documentos Construtor de Vendas",
  "pdf-cv-reserva__short": "Adicionar PDF nos Documentos",
  "pdf-email": "Enviar PDF por email",
  "pdf-email__short": "Enviar PDF por email",
  status: "Resultado da conexão",
  unmatch: "Reprovado",
};
