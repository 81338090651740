import {
  LqdButton,
  LqdDoneFilledIcon,
  LqdHourglassIcon,
  LqdTypography,
  LqdUserThreeIcon,
} from "@/liquid-components/src";
import LeftArrow from "@/liquid-components/src/icons/LeftArrow";
import { Box, Divider, Stack } from "@mui/material";
import { onChangingView } from "@signature/signatureSlice";
import { SignatureViews } from "@signature/types/signatureViews";
import { formatSignatureDate } from "@signature/utils/signatureDateFormatter";
import { getDocumentName } from "@signature/utils/signatureDocument";
import { useAppDispatch, useAppSelector } from "../../../store";
import SignatureHeader from "./SignatureHeader";

export default function SignaturePendencies() {
  const dispatch = useAppDispatch();
  const { contract, signatureLinkSentDate, signPendencies } = useAppSelector((state) => state.signature);

  const signers = signPendencies.peers;
  const documentsSigned = signers.filter((signer) => signer.signed).length;

  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
          display: "flex",
          height: "68px",
          justifyContent: "space-between",
          padding: "12px 24px",
        }}
      >
        <LqdButton
          onClick={() => dispatch(onChangingView(SignatureViews.DOCUMENT_VIEW))}
          size="medium"
          startIcon={<LeftArrow />}
          type="ghostPrimary"
        >
          <LqdTypography color="rgba(79, 85, 98, 1)">Voltar</LqdTypography>
        </LqdButton>
      </Box>
      <Box sx={{ ml: 6, mr: 7.5, padding: 2 }}>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 2 }} textstyle="h4Headline">
          Assinaturas
        </LqdTypography>
        <LqdTypography color="rgba(127, 135, 152, 1)">DOCUMENTO</LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ fontWeight: 600, my: 1 }}>
          {getDocumentName(contract.portalDocumentUrl)}
        </LqdTypography>
        {signatureLinkSentDate > 0 ? (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }}>
            {`Enviado para assinatura em ${formatSignatureDate(signatureLinkSentDate)}`}
          </LqdTypography>
        ) : null}
        <Stack alignItems="center" direction="row" spacing={1} sx={{ mb: 1 }}>
          <LqdUserThreeIcon />
          <LqdTypography color="rgba(33, 36, 42, 1)">
            {`${documentsSigned}/${signers.length} Assinatura${signers.length === 1 ? "" : "s"}`}
          </LqdTypography>
        </Stack>
        <Divider sx={{ border: "1px solid rgba(220, 221, 224, 1)", mt: 1 }} />
        {signers.map((signer, index) => (
          <>
            <Stack alignItems="center" direction="row" key={signer.pass_id} sx={{ pb: 1, pt: 1.5, px: 2 }}>
              {signer.signed ? (
                <Box sx={{ mr: "12px" }}>
                  <LqdDoneFilledIcon color="rgba(52, 199, 89, 1)" size={35} />
                </Box>
              ) : (
                <Box
                  sx={{
                    background: "rgba(255, 204, 0, 1)",
                    border: "2px solid rgba(255, 204, 0, 1)",
                    borderRadius: "50%",
                    display: "inline-flex",
                    mr: "12px",
                    padding: "5px",
                  }}
                >
                  <LqdHourglassIcon size={17} />
                </Box>
              )}
              <Box>
                <LqdTypography color="rgba(33, 36, 42, 1)">{signer.name}</LqdTypography>
                <LqdTypography color="rgba(127, 135, 152, 1)">
                  {signer.signed
                    ? `Assinatura Realizada em ${formatSignatureDate(signer.signature_timestamp)}`
                    : "Assinatura Pendente"}
                </LqdTypography>
              </Box>
            </Stack>
            <Divider
              sx={{
                border: "1px solid rgba(220, 221, 224, 1)",
                display: index === signers.length - 1 ? "none" : "auto",
                mt: 1,
              }}
            />
          </>
        ))}
      </Box>
    </>
  );
}
