import { LqdIconProps } from "../../types/IconProps";

export default function LqdProviderTwoIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.554 11H4C2.895 11 2 10.105 2 9V4C2 2.895 2.895 2 4 2H20C21.105 2 22 2.895 22 4V9C22 10.105 21.105 11 20 11H10.554"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        clipRule="evenodd"
        d="M10.554 13V8.5C10.554 7.672 9.88201 7 9.05401 7V7C8.22601 7 7.55401 7.672 7.55401 8.5V11V16L6.65301 15.099C6.04601 14.492 5.06201 14.492 4.45501 15.099V15.099C3.93101 15.623 3.85001 16.444 4.26101 17.06L6.96001 21.109C7.33101 21.666 7.95601 22 8.62501 22H13.913C14.922 22 15.772 21.249 15.898 20.248L16.44 15.911C16.573 14.844 15.839 13.862 14.778 13.689L10.554 13Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.5 5.5L16 8L14.5 6.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
