import { LqdPlusTwoIcon, LqdTypography } from "@/liquid-components/src";
import { Box, SxProps, useTheme } from "@mui/material";
import { ReactNode } from "react";

type DashedAddButtonProps = {
  children?: ReactNode;
  onClick: () => void;
  sx?: SxProps;
};

export default function DashedAddButton(props: DashedAddButtonProps) {
  const { children, onClick, sx } = props;

  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", ...sx }}>
      <Box
        onClick={onClick}
        sx={{
          alignItems: "center",
          backgroundColor: theme.palette.primary.light,
          border: `1px dashed ${theme.palette.primary.main}`,
          borderRadius: "32px",
          cursor: "pointer",
          display: "inline-flex",
          px: 2,
          py: 1,
        }}
      >
        <LqdPlusTwoIcon color={theme.palette.primary.main} size={16} />
        <LqdTypography sx={{ color: theme.palette.primary.main, ml: 1 }}>{children}</LqdTypography>
      </Box>
    </Box>
  );
}
