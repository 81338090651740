import {
  LqdButton,
  LqdCircularLoader,
  LqdDialog,
  LqdDialogActions,
  LqdInput,
  LqdTypography,
} from "@/liquid-components/src";
import { formatValueToCurrency } from "@analysis/utils/analysisDetailsDataFormatter";
import { dialogCalled, dialogLoaded } from "@common/commonSlice";
import {
  formatPercentageInput,
  getUnformattedValues,
  processInputValue,
} from "@common/utils/SmallDialogMultipleInputsHelpers";
import { useErrorHandler } from "@common/utils/useErrorHandler";
import { Box, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { SmallDialogAction, SmallDialogInput } from "./SmallDialog";
import SmallDialogHeaderInputs from "./SmallDialogHeaderInputs";

/** Modal que exibe múltiplos inputs para o usuário preencher.
 * @param {Object} dialogContent - O conteúdo do modal incluindo inputs e ações.
 * @param {Array} dialogContent.inputs - O array de inputs a serem renderizados no modal.
 * @param {Array} dialogContent.actions - O array de ações a serem renderizadas como botões no modal.
 * @param {string} dialogContent.title - O título do modal.
 * @param {string} dialogContent.body - O texto do corpo do modal.
 */
export default function SmallDialogMultipleInputs() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { dialogContent, dialogLoading, dialogShowState } = useAppSelector((state) => state.common);

  const [inputValues, setInputValues] = useState(dialogContent.inputs || []);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  useEffect(() => {
    const formattedInputValues =
      dialogContent.inputs?.map((input) => {
        if (input.type === "currency") {
          return { ...input, value: formatValueToCurrency(parseFloat(input.value), "BRL", true) };
        }
        if (input.type === "percentage") {
          return { ...input, value: formatPercentageInput((parseFloat(input.value) * 100).toString()) };
        }
        return input;
      }) || [];
    setInputValues(formattedInputValues);
  }, [dialogContent.inputs]);

  useEffect(() => {
    const hasEmptyOrInvalidInputs = inputValues.some((input) => {
      if (input.type === "date") {
        return !input.value || input.value.length !== 10;
      }
      return !input.value;
    });
    setIsConfirmDisabled(hasEmptyOrInvalidInputs);
  }, [inputValues]);

  const handleCloseDialog = () => {
    dispatch(dialogCalled(null));
  };

  const handleConfirmClick = async (action: SmallDialogAction, index: number) => {
    if (index === 0) {
      setInputValues(inputValues.map((input) => ({ ...input, value: "" })));
    }
    if (action.onClick) {
      dispatch(dialogLoaded(true));
      try {
        const unformattedInputs = getUnformattedValues(inputValues);
        await action.onClick(unformattedInputs as Array<SmallDialogInput>);
        handleCloseDialog();
      } catch (error) {
        handleLiquidErrors(error);
      } finally {
        dispatch(dialogLoaded(false));
      }
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number
  ) => {
    const { value } = event.target;
    const inputType = inputValues[index].type!;

    const updatedValue = processInputValue(inputType, value); // Chama a função de processamento de valor
    const updatedInputValues = inputValues.map((input, i) => (i === index ? { ...input, value: updatedValue } : input));
    setInputValues(updatedInputValues);
  };

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const getAlertMessage = (input: SmallDialogInput) => {
    if (input.type === "date" && input.value.length !== 10) {
      return "Data inválida";
    }

    return "";
  };

  const getMaskType = (type: string): "string" | "currency" | "date" | "integer" | "percentage" => {
    const maskTypes = ["string", "currency", "date", "integer", "percentage"];
    return maskTypes.includes(type) ? (type as "string" | "currency" | "date" | "integer") : "string";
  };

  const renderInputByType = (input: SmallDialogInput, index: number) => {
    if (input.type === "boolean") {
      return (
        <Select
          onChange={(event) => handleInputChange(event, index)}
          sx={{
            borderRadius: 2,
            width: "98%",
          }}
          value={input.value || ""}
        >
          <MenuItem disabled value="">
            {"Escolha uma opção"}
          </MenuItem>
          <MenuItem sx={{ color: "rgba(33, 36, 42, 1)" }} value="true">
            True
          </MenuItem>
          <MenuItem sx={{ color: "rgba(33, 36, 42, 1)" }} value="false">
            False
          </MenuItem>
        </Select>
      );
    }

    // TODO: Refatorar para usar o LqdInput. Atualmente, a máscara de porcentagem não está funcionando corretamente.
    if (input.type === "percentage") {
      return (
        <TextField
          onChange={(event) => handleInputChange(event, index)}
          placeholder={input.placeholder}
          sx={{ "&.MuiFormControl-root": { border: "none" }, width: "98%" }}
          value={input.value || ""}
        />
      );
    }

    return (
      <LqdInput
        alertMessage={getAlertMessage(input)}
        boxSize="normal"
        fullBox={true}
        maskType={getMaskType(input.type!)}
        placeholderText={input.placeholder || "Digite o valor aqui"}
        setValue={(value: string) =>
          handleInputChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>, index)
        }
        showCharacterLimit={false}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderColor: "rgba(217, 217, 217, 1)",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          height: "36px",
          padding: "8px",
          width: "94.5%",
        }}
        value={input.value || ""}
      />
    );
  };

  return (
    <LqdDialog
      onClose={handleCloseDialog}
      open={dialogShowState}
      PaperProps={{
        sx: {
          maxHeight: "719px",
          minWidth: "584px",
        },
      }}
    >
      <Box sx={{ margin: 2 }}>
        <SmallDialogHeaderInputs body={dialogContent.body} title={dialogContent.title} />
        <Stack sx={{ gap: 3, maxHeight: "460px", mt: 3, overflowY: "auto" }}>
          {inputValues.map((input, index) => {
            return (
              <Box key={index}>
                <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1.5 }} textstyle="l1Label">
                  {input.label}
                </LqdTypography>
                {renderInputByType(input, index)}
              </Box>
            );
          })}
        </Stack>
        <LqdDialogActions sx={{ pt: 3.75 }}>
          {dialogContent.actions.map((action, index, actions) => {
            return (
              <LqdButton
                className="lqd-dialog-button"
                disabled={action.title === "Confirmar" ? isConfirmDisabled : dialogLoading}
                key={action.title}
                onClick={() => handleConfirmClick(action, index)}
                type={getButtonType(index, actions)}
              >
                {dialogLoading && actions.length > 1 && index !== 0 ? (
                  <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
                ) : (
                  action.title
                )}
              </LqdButton>
            );
          })}
        </LqdDialogActions>
      </Box>
    </LqdDialog>
  );
}
