import { GetAnalysisParams } from "../api/GetAnalysis";
import { BoardFilter } from "../boardSlice";
import { AnalysisColumn } from "../types/AnalysisColumn";

/** Quantidade de conexões que são carregadas em cada paginação */
export const ANALYSIS_LOAD_AMOUNT = 10;

/**
 * Retorna um conjunto de query parameters para fazer as requests de GetAnalysis e GetTotalAnalysis.
 * @param column Coluna buscada
 * @param filter Filtro da board
 * @param searchQuery Texto de search da board
 * @param currentAnalysisCount Quantidade de conexões dentro da coluna selecionada
 * @param userProfile Perfil do usuário fazendo a busca
 * @returns
 */
export const buildGetAnalysisParams = (
  column: AnalysisColumn,
  filter: BoardFilter,
  searchQuery: string,
  currentAnalysisCount: number,
  userProfile: string
): GetAnalysisParams => {
  const hideArchivedAnalysis = userProfile === "viewer" || userProfile === "creator";
  const renderArchived = filter.archived && !hideArchivedAnalysis;
  const searchQueryAttrs: GetAnalysisParams = {
    analysis_id: searchQuery.trim() || undefined,
    analyst_name: searchQuery.trim() || undefined,
    archived: undefined,
    document: searchQuery.trim() || undefined,
    limit: ANALYSIS_LOAD_AMOUNT,
    offset: Math.floor(currentAnalysisCount / ANALYSIS_LOAD_AMOUNT) + 1,
    pass_id: searchQuery.trim() || undefined,
    peer_name: searchQuery.trim() || undefined,
    product_name: searchQuery.trim() || undefined,
  };
  const endDate =
    new Date(
      [filter.endDate.split("/")[1], filter.endDate.split("/")[0], filter.endDate.split("/")[2]].join("/") + " 23:59:59"
    ).getTime() / 1000;
  const startDate =
    new Date(
      [filter.startDate.split("/")[1], filter.startDate.split("/")[0], filter.startDate.split("/")[2]].join("/") +
        " 23:59:59"
    ).getTime() / 1000;
  if (!filter.humanDecision) searchQueryAttrs.human_interaction = false; // Bloquear as conexões com Human Decision

  let params: GetAnalysisParams = {};
  if (column === AnalysisColumn.COMPLETED) {
    let statusQuery: Array<string> = [];

    switch (renderArchived) {
      case true:
        statusQuery = ["match", "unmatch"];
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    // Coluna de Finalizadas
    if (filter.match && filter.unmatch) {
      statusQuery = ["match", "unmatch"];
    }
    if (filter.match && !filter.unmatch) {
      statusQuery = ["match"];
    }
    if (filter.unmatch && !filter.match) {
      statusQuery = ["unmatch"];
    }
    if (!filter.unmatch && !filter.match) {
      statusQuery = [];
    }
    const parsedStatusQuery = statusQuery.join(",");

    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  if (column === AnalysisColumn.MODERATION) {
    let statusQuery: Array<string> = [];
    switch (renderArchived) {
      case true:
        statusQuery = ["moderation", "unlabelled"];
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    // Coluna de Moderação
    if (filter.humanDecision && filter.unlabelled) {
      statusQuery = ["moderation", "unlabelled"];
    }
    if (!filter.humanDecision && !filter.unlabelled) {
      statusQuery = [];
    }
    if (filter.unlabelled && !filter.humanDecision) {
      statusQuery = ["unlabelled"];
    }
    if (filter.humanDecision && !filter.unlabelled) {
      statusQuery = ["moderation"];
    }

    const parsedStatusQuery = statusQuery.join(",");

    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  if (column === AnalysisColumn.ONBOARDING) {
    switch (renderArchived) {
      case true:
        searchQueryAttrs.archived = undefined;
        break;
      default:
        searchQueryAttrs.archived = renderArchived;
    }
    const statusQuery: Array<string> = filter.unlabelled ? ["onboarding", "unlabelled"] : [];

    const parsedStatusQuery = statusQuery.join(",");
    params = { ...searchQueryAttrs, from: startDate, status: parsedStatusQuery, to: endDate };
  }

  return params;
};
