import { AxiosPromise } from "axios";
import { put } from "../../common/utils/apiUtils";
import { SegmentResponseRaw } from "../types/SegmentResponseRaw";

// Update Segment (Superadmin Endpoint)
export const adminUpdateSegment = (
  segmentId: string,
  segmentInfo: { name: string; templates: Array<string> }
): AxiosPromise<SegmentResponseRaw> => {
  return put(`/admin/segment?segment_id=${segmentId}`, segmentInfo);
};
