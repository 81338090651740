import { LqdIconProps } from "../../types/IconProps";

export default function LqdFunctionIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99988 18.272C7.47274 18.7379 8.11392 18.9998 8.78255 19V19C10.0349 19.0004 11.0977 18.0948 11.2783 16.8734L12.7215 7.1266C12.9021 5.90522 13.9649 4.99961 15.2172 5V5C15.8858 5.00024 16.527 5.26208 16.9999 5.728"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M16.9999 11.5H7.99988"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
