export const capitalizeString = (name: string) => {
  if (!name) {
    return name;
  }
  const conjunctiveNames = ["da", "de", "do", "das", "dos"];

  const nameToLowerCase = name.toLowerCase();
  const splitNames = nameToLowerCase.split(" ");

  const capitalizedNames = splitNames.map((name) => {
    if (conjunctiveNames.includes(name)) {
      return name;
    }
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  });

  const getCapitalizedFullName = capitalizedNames.join(" ");

  return getCapitalizedFullName;
};
