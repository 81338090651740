import { LqdButton, LqdSearch, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadGroups, onLoadLoggedUserGroups } from "../../../groups/groupsSlice";
import { onLoadProducts } from "../../adminSliceThunks";
import { adminDeleteAnalysisByProductList } from "../../api/DeleteAnalysisByProductList";
import { adminProductsDeletion } from "../../api/DeleteProducts";
import { adminListAnalysisByProductList } from "../../api/ListAnalysisByProductList";
import { adminListGroupsByProductList } from "../../api/ListGroupsByProductList";
import { adminUpdateGroupsByProductList } from "../../api/UpdateGroupsByProductList";
import { AnalysisByProductResponseRaw } from "../../types/AnalysisByProductResponseRaw";
import { GroupResponseRaw } from "../../types/GroupResponseRaw";
import SuperTenantCard from "../SuperTenantCard";
import ProductDeletionProductCard from "./ProductDeletionProductCard";
import { normalizeText } from "@common/utils/normalizeText";

export default function ProductDeletion() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { currentSelectedTenant } = useAppSelector((state) => state.admin);
  const products = Object.values(useAppSelector((state) => state.admin.products));
  const productsByTenant = products.filter((product) => product.tenantCode === currentSelectedTenant.code);

  const [analysisList, setAnalysisList] = useState<Array<AnalysisByProductResponseRaw>>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedProducts, setSelectedProducts] = useState<Array<string>>([]);

  const filteredProducts = productsByTenant.filter((product) =>
    normalizeText(product.name).includes(normalizeText(searchTerm))
  );

  const renderAnalysisTotal = () => {
    switch (true) {
      case !analysisList.length:
        return "(0)";
      default:
        return `${analysisList.length}`;
    }
  };

  const handleSelectProduct = (productId: string) => {
    if (selectedProducts.includes(productId)) {
      const filteredProductsIds = selectedProducts.filter((selectedProductId) => selectedProductId !== productId);
      setSelectedProducts(filteredProductsIds);
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const onSubmitProductDeletion = async (selectedProducts: Array<string>) => {
    dispatch(dialogCalled({ actions: [], body: "", title: "", type: "loading" }));

    try {
      const groupResponseRaw = await adminListGroupsByProductList(selectedProducts, currentSelectedTenant.code);
      const groupResponse = groupResponseRaw.data;
      const analysisResponseRaw = await adminListAnalysisByProductList(selectedProducts, currentSelectedTenant.code);
      const analysisResponse = analysisResponseRaw.data;

      const newAnalysisList: Array<AnalysisByProductResponseRaw> = [];
      const newGroupsList: Array<GroupResponseRaw> = [];

      analysisResponse.forEach((analysis) => {
        if (!newAnalysisList.some((item) => JSON.stringify(item) === JSON.stringify(analysis))) {
          newAnalysisList.push(analysis);
        }
      });

      groupResponse.forEach((group) => {
        if (!newGroupsList.some((item) => JSON.stringify(item) === JSON.stringify(group))) {
          newGroupsList.push(group);
        }
      });

      setAnalysisList(newAnalysisList);

      dispatch(
        dialogCalled({
          actions: [{ title: "Cancelar" }, { onClick: onCallConfirmDialog, title: "Continuar" }],
          body: "Os produtos selecionados serão removidos dos grupos abaixo:",
          bodySecundary: `Ao excluir os produtos, todas as conexões criadas a partir deles ${renderAnalysisTotal()}
          serão excluídas. Deseja continuar?`,
          emptyLabel: "Não há grupos a serem atualizados.",
          itemList: newGroupsList.map((group) => ({
            labelOne: "ID do grupo:",
            labelTwo: "Nome do grupo:",
            valueOne: group.code,
            valueTwo: group.name,
          })),
          title: "Exclusão de Produtos",
          type: "list",
        })
      );
    } catch (error) {
      handleLiquidErrors(error);
    }
  };

  const onCallConfirmDialog = () => {
    dispatch(
      dialogCalled({
        actions: [{ title: "Voltar" }, { onClick: onConfirmProductDeletion, title: "Confirmar" }],
        body: `Este procedimento é irreversível e resultará na exclusão das conexões criadas a partir destes produtos e
        na sua consequente perda de rastreamento.`,
        bodySecundary: "Para prosseguir mesmo assim, digite EXCLUIR PRODUTOS no campo abaixo.",
        confirmationString: "EXCLUIR PRODUTOS",
        title: "Exclusão de Produtos",
        type: "confirmation",
      })
    );
  };

  const onConfirmProductDeletion = async () => {
    dispatch(dialogLoaded(true));

    try {
      const promises = [
        adminProductsDeletion(selectedProducts, currentSelectedTenant.code),
        adminUpdateGroupsByProductList(selectedProducts, currentSelectedTenant.code),
        adminDeleteAnalysisByProductList(selectedProducts, currentSelectedTenant.code),
      ];
      await Promise.all(promises);

      const notification: ToastNotification = {
        message: "Os produtos selecionados foram excluídos com sucesso.",
        type: "success",
      };

      const dispatchPromises = [
        dispatch(onLoadProducts()),
        dispatch(onLoadGroups()),
        dispatch(onLoadLoggedUserGroups()),
      ];

      await Promise.all(dispatchPromises);
      dispatch(toastCalled(notification));

      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setAnalysisList([]);
      setSelectedProducts([]);
      setTimeout(() => dispatch(dialogLoaded(false)), 1000);
    }
  };

  const renderProductSelectInput = () => {
    switch (true) {
      case productsByTenant.length > 0:
        return (
          <>
            <LqdTypography
              sx={{ borderBottom: "1px solid rgba(212, 215, 220, 1)", mt: 3, pb: 1.5 }}
              textstyle="p2Paragraph"
            >
              Selecione os Produtos que deseja excluir:
            </LqdTypography>
            <Box sx={{ my: 2, width: "fit-content" }}>
              <LqdSearch
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder="Buscar produtos"
                value={searchTerm}
              />
            </Box>
            <Box
              sx={{
                "::-webkit-scrollbar": { height: "8px" },
                "::-webkit-scrollbar-track": { border: "none" },
                maxHeight: "364px",
                overflowY: "auto",
                px: 1,
              }}
            >
              {filteredProducts.map((product) => (
                <ProductDeletionProductCard
                  key={product.productId}
                  onChangeMethod={handleSelectProduct}
                  product={product}
                  selectedProducts={selectedProducts}
                />
              ))}
            </Box>
          </>
        );
      case !productsByTenant.length:
        return (
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ height: "30px", mt: 4 }} textstyle="h5Headline">
            Ainda não há nenhum produto cadastrado para essa empresa.
          </LqdTypography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SuperTenantCard currentSelectedTenant={currentSelectedTenant} />
      <Box sx={{ display: "flex", flexDirection: "column", margin: "auto", width: "90%" }}>
        {renderProductSelectInput()}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 3 }}>
          <LqdButton
            disabled={!productsByTenant.length}
            onClick={() => onSubmitProductDeletion(productsByTenant.map((product) => product.productId))}
            sx={{ mr: 2 }}
            type="outlineTertiary"
          >
            Excluir todos
          </LqdButton>
          <LqdButton disabled={!selectedProducts.length} onClick={() => onSubmitProductDeletion(selectedProducts)}>
            Excluir produtos selecionados
          </LqdButton>
        </Box>
      </Box>
    </>
  );
}
