import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useAuth from "../features/common/hooks/useAuth";
import CommonRoutes from "./common";
import ProtectedRoutes from "./protected";
import PublicRoutes from "./public";

export default function RoutesProvider() {
  const auth = useAuth();

  useEffect(() => {
    // Faz logout de todas as abas quando uma aba for deslogada
    window.addEventListener("storage", (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === "access_token" && event.newValue === null) {
        window.location.reload();
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {CommonRoutes()}
        {auth.accessToken ? ProtectedRoutes() : PublicRoutes()}
        <Route element={<Navigate replace to={auth.accessToken ? "/" : "/signin"} />} path="*" />
      </Routes>
    </BrowserRouter>
  );
}
