import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Node } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import { ObjectOf } from "../../../common/types/ObjectOf";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { resetProductBuilderState } from "../../../productBuilder/productBuilderSlice";
import { ProviderNodeData } from "../../../productBuilder/types/NodeData";
import getRawNodes from "../../../productBuilder/utils/getRawNodes";
import { createTemplateModalClosed, createTemplateModalStepNext, createTemplateModalStepPrev } from "../../adminSlice";
import { onLoadSegments, onLoadTemplates } from "../../adminSliceThunks";
import { adminCreateTemplate } from "../../api/CreateTemplate";
import { adminUpdateSegment } from "../../api/UpdateSegment";
import { TemplateCreateRaw } from "../../types/TemplateCreateRaw";
import CreateSegmentStep from "./CreateSegmentStep";
import TemplateFormStep1 from "./TemplateCreationSteps/TemplateFormStep1";
import TemplateFormStep2 from "./TemplateCreationSteps/TemplateFormStep2";
import TemplateFormStep3 from "./TemplateCreationSteps/TemplateFormStep3";
import TemplateFormStep4 from "./TemplateCreationSteps/TemplateFormStep4";
import TemplateSummaryCards from "./TemplateCreationSteps/TemplateSummaryCards";

const emptyTemplate = {
  country: "",
  currency: "",
  edges: [],
  name: "",
  providers: [],
  rules: [],
  segments: [],
};

/**
 * Modal de criação de Templates.
 * Exibido no painel de admin.
 */
export default function CreateTemplateForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();
  const { createTemplate, createTemplateModalStep, segments } = useAppSelector((state) => state.admin);
  const { edges, nodes, proponents } = useAppSelector((state) => state.productBuilder);
  const { simulatorKpis } = useAppSelector((state) => state.simulatorBuilder);
  const [isLoading, setIsLoading] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateCountry, setTemplateCountry] = useState("");
  const [templateCurrency, setTemplateCurrency] = useState("");
  const [checkedSegments, setCheckedSegments] = useState<Array<string>>([]);
  const rules = getRawNodes(nodes, edges, proponents, simulatorKpis);

  // Reseta o form de template quando o modal for aberto
  useEffect(() => {
    if (createTemplate) {
      setTemplateTitle("");
      setTemplateCountry("");
      setTemplateCurrency("");
      setCheckedSegments(emptyTemplate.segments);
    }
  }, [createTemplate]);

  // Aplica as alterações no template e muda a view para o próximo step, quando é clicado em next
  const handleSubmit = () => {
    dispatch(createTemplateModalStepNext());
    dispatch(resetProductBuilderState());
  };

  const handleCloseClick = () => {
    dispatch(createTemplateModalClosed());
    setCheckedSegments([]);
  };

  const getProviderIdList = () => {
    const providerNodes = nodes.filter((node) => (node.data as ProviderNodeData).provider) as Array<
      Node<ProviderNodeData>
    >;
    const uniqueProviderIds = providerNodes
      .map((node) => node.data.provider)
      .reduce((acc, providerId) => ({ ...acc, [providerId]: providerId }), {} as ObjectOf<string>);

    return Object.values(uniqueProviderIds);
  };

  const handleSubmitTemplate = async () => {
    try {
      const requestBody: TemplateCreateRaw = {
        country: templateCountry,
        currency: templateCurrency,
        edges,
        name: templateTitle,
        providers: getProviderIdList(),
        rules,
        segments: checkedSegments,
      };

      const templateRaw = await adminCreateTemplate(requestBody);
      const template = templateRaw.data;

      // Update every segment that the template is part of
      const updatePromises = template.segments.map(async (segmentId) => {
        const updateBody = {
          name: segments[segmentId].name,
          templates: [...segments[segmentId].templates, template.template_id],
        };
        await adminUpdateSegment(segmentId, updateBody);
      });
      await Promise.all(updatePromises);
      await Promise.all([dispatch(onLoadTemplates()), dispatch(onLoadSegments())]);

      const notification: ToastNotification = {
        message: "Template criado com sucesso!",
        type: "success",
      };

      dispatch(resetProductBuilderState());
      dispatch(toastCalled(notification));
      dispatch(createTemplateModalClosed());
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeaderTitle = () => {
    switch (createTemplateModalStep) {
      case 1:
      case 2:
      case 3:
      case 6:
      case 7:
        return "Novo Template";
      case 4:
        return templateTitle;
      case 5:
        return "Novo Segmento";
      default:
        return "Novo Template";
    }
  };

  const renderCurrentStep = () => {
    switch (createTemplateModalStep) {
      case 1:
        return <TemplateFormStep1 name={templateTitle} setName={setTemplateTitle} />;
      case 2:
        return (
          <TemplateFormStep2
            country={templateCountry}
            currency={templateCurrency}
            onSubmit={handleSubmit}
            options={["Brasil / R$"]}
            setCountry={setTemplateCountry}
            setCurrency={setTemplateCurrency}
          />
        );
      case 3:
        return <TemplateFormStep3 checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
      case 4:
        return (
          <TemplateFormStep4
            isLoading={isLoading}
            onGoBack={() => {}}
            onSubmit={handleSubmitTemplate}
            templateCountry={templateCountry}
            templateCurrency={templateCurrency}
            templateSegments={checkedSegments}
            templateTitle={templateTitle}
          />
        );
      case 5:
        return <CreateSegmentStep checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
    }
  };

  return (
    <FullscreenDialog
      action="create"
      currentStep={createTemplateModalStep}
      handleGoBack={() => dispatch(createTemplateModalStepPrev())}
      hideHeader={createTemplateModalStep === 4}
      onClose={handleCloseClick}
      open={createTemplate}
      title={renderHeaderTitle()}
    >
      <Box
        sx={{
          alignItems: "center",
          display: createTemplateModalStep !== 4 ? "flex" : "block",
          height: "100%",
          justifyContent: "space-between",
          overflow: "hidden",
          px: createTemplateModalStep !== 4 ? "15%" : 0,
        }}
      >
        {renderCurrentStep()}
        {createTemplateModalStep > 1 && createTemplateModalStep < 4 ? (
          <TemplateSummaryCards
            checkedSegments={checkedSegments}
            country={templateCountry}
            currency={templateCurrency}
            title={templateTitle}
          />
        ) : null}
      </Box>
    </FullscreenDialog>
  );
}
