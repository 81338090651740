import { LqdTypography } from "@/liquid-components/src";

interface BreadcrumbPathProps {
  item: string;
  showMobileSearchInput: boolean;
}

export default function BreadcrumbPath({ item, showMobileSearchInput }: BreadcrumbPathProps) {
  return (
    <LqdTypography
      sx={{
        color: "rgba(33, 36, 42, 1)",
        display: { sm: "block", xs: showMobileSearchInput ? "none" : "block" },
        width: "100%",
      }}
      textstyle="h5Headline"
    >
      {item}
    </LqdTypography>
  );
}
