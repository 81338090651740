import { put } from "@common/utils/apiUtils";

type UpdateProductGroupsType = {
  groups: string;
  status_code: number;
};

const updateProductGroupsRequest = (productId: string, groups: Array<string>) =>
  put<UpdateProductGroupsType>(`/groups/product/${productId}`, { groups });

export default updateProductGroupsRequest;
