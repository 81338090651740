import { LqdButton, LqdCircularLoader, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Divider } from "@mui/material";
import { t } from "i18next";
import { Fragment, MouseEventHandler } from "react";
import { useAppDispatch } from "../../../store";
import { ObjectOf } from "../../common/types/ObjectOf";
import { Product } from "../../products/types/Product";
import { User } from "../../users/types/User";
import { editGroupFormStepChangedTo } from "../groupsSlice";
import ProductRow from "./ProductRow";
import UserCard from "./UserCard";

type CreateGroupFormLastStepProps = {
  actionButtonLabel: string;
  loading: boolean;
  newProducts: ObjectOf<Product>;
  newTitle: string;
  newUsers: ObjectOf<User>;
  onSubmit: () => void;
};

export default function CreateGroupFormStepLast(props: CreateGroupFormLastStepProps) {
  const { actionButtonLabel, loading, newProducts, newTitle, newUsers, onSubmit } = props;

  const dispatch = useAppDispatch();

  // Vai para o step de editar o nome do grupo, quando clicado no botão de editar nome do grupo
  const handleNameEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(editGroupFormStepChangedTo(1));
  };

  // Vai para o step de editar os produtos do grupo, quando clicado no botão de editar produtos do grupo
  const handleProductsEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(editGroupFormStepChangedTo(1));
  };

  // Vai para o step de editar os usuários do grupo, quando clicado no botão de editar os usuários do grupo
  const handleUsersEdit: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(editGroupFormStepChangedTo(1));
  };

  return (
    <Box
      className="lqd-group-form-step-last"
      sx={{
        display: "flex",
        flexFlow: "column",
        pt: { lg: 7, md: 4 },
        px: 5,
        width: { lg: "70vw", md: "80vw" },
      }}
    >
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", pb: 5 }} textstyle="h4Headline">
        Resumo
      </LqdTypography>
      <Box sx={{ flex: 1, maxHeight: "55vh", overflowY: "auto", pb: 3, pr: 2 }}>
        <Box sx={{ boxSizing: "border-box", pl: 3 }}>
          <LqdTypography
            sx={{
              color: "rgba(127, 135, 152, 1)",
              display: "inline-block",
              mr: 1,
            }}
          >
            {t("Nome do grupo")}
          </LqdTypography>
          <LqdLink onClick={handleNameEdit} sx={{ display: "inline-block" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              {t("Editar")}
            </LqdTypography>
          </LqdLink>
          <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mt: 1 }}>{newTitle}</LqdTypography>
        </Box>
        <Divider sx={{ mt: 3 }} />
        <Box>
          <LqdTypography
            sx={{
              color: "rgba(127, 135, 152, 1)",
              display: "inline-block",
              ml: 3,
              mr: 1,
              mt: 6,
            }}
          >
            {t("Produtos")}
          </LqdTypography>
          <LqdLink onClick={handleProductsEdit} sx={{ display: "inline-block" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              {t("Editar")}
            </LqdTypography>
          </LqdLink>
          {Object.values(newProducts).length > 0 ? (
            Object.values(newProducts).map((product) => (
              <ProductRow checkbox={<Box />} hideButton key={product.id} product={product} />
            ))
          ) : (
            <LqdTypography
              sx={{
                color: "rgba(127, 135, 152, 1)",
                mb: 3,
                ml: 3,
              }}
              textstyle="p2Paragraph"
            >
              {t("Nenhum produto adicionado.")}
            </LqdTypography>
          )}
        </Box>
        <Divider />
        <Box sx={{ ml: 3 }}>
          <LqdTypography
            sx={{
              color: "rgba(127, 135, 152, 1)",
              display: "inline-block",
              mb: 2,
              mr: 1,
              mt: 6,
            }}
          >
            {t("Usuários")}
          </LqdTypography>
          <LqdLink onClick={handleUsersEdit} sx={{ display: "inline-block" }}>
            <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p2Paragraph">
              {t("Editar")}
            </LqdTypography>
          </LqdLink>
          {Object.values(newUsers).length ? (
            Object.values(newUsers).map((user, index) => (
              <Fragment key={user.email}>
                <UserCard
                  added
                  hideButton
                  onAddUserClick={() => {}}
                  onRemoveUserClick={() => {}}
                  sx={{ pl: 1 }}
                  user={user}
                />
                {index !== Object.values(newUsers).length - 1 ? <Divider /> : null}
              </Fragment>
            ))
          ) : (
            <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)", mb: 2 }} textstyle="p2Paragraph">
              {t("Nenhum usuário adicionado.")}
            </LqdTypography>
          )}
        </Box>
        <Divider sx={{ mt: 3 }} />
      </Box>
      <Box sx={{ mt: 5, textAlign: "right" }}>
        <LqdButton disabled={loading} onClick={onSubmit}>
          {loading ? <LqdCircularLoader sx={{ color: "inherit" }} /> : actionButtonLabel}
        </LqdButton>
      </Box>
    </Box>
  );
}
