import { SimulatorTestDBResponse } from "@simulatorBuilder/types/Simulator";
import { AxiosResponse } from "axios";
import { post } from "../../common/utils/apiUtils";
import { SimulatorFunctionBlock } from "../types/SimulatorFunctionBlock";
import { SimulatorVariable } from "../types/SimulatorVariable";

export const testSimulator = (body: {
  blocks: Array<SimulatorFunctionBlock>;
  dashboard: object;
  name: string;
  variables: Array<SimulatorVariable>;
}): Promise<AxiosResponse<SimulatorTestDBResponse>> =>
  post<SimulatorTestDBResponse>("/simulation/builder/simulator/run", body);
