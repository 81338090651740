import { Dialog, DialogProps } from "@mui/material";

export default function LqdPopUp(props: DialogProps) {
  const { children, PaperProps } = props;

  return (
    <Dialog
      {...props}
      className="lqd-popup"
      PaperProps={{
        ...PaperProps,
        sx: {
          borderRadius: "20px",
          boxShadow: "0px 20px 40px -8px rgba(16, 24, 40, 0.10)",
          maxWidth: "560px",
          minWidth: "327px",
          position: "relative",
          px: { sm: 5, xs: 2.5 },
          py: { sm: 3.75, xs: 3 },
          ...PaperProps?.sx,
        },
      }}
    >
      {children}
    </Dialog>
  );
}
