import Group from "../types/Group";
import GroupRaw from "../types/GroupRaw";

export const getGroupRaw = (group: Group): GroupRaw => ({
  code: group.id,
  created_at: Math.round(group.created / 1000),
  created_by: group.createdBy,
  is_active: group.active,
  last_update: Math.round(group.updated / 1000),
  name: group.title,
  products: [...group.products],
  tenant_code: group.tenant,
  users: [...group.users],
});
