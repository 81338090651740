import { DefaultMessageResponse } from "@common/types/DefaultMessageResponse";
import { post } from "../../common/utils/apiUtils";

/**
 * Endpoint para recusar o acordo da Pluggy.
 * @param token Token para acesso ao endpoint.
 * @returns Mensagem de confirmação da recusa.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const pluggyAgreementRefuse = async (token: string) =>
  await post<DefaultMessageResponse>(`/plug/refuse-agreement/${token}`, {});
