import { TemplateResponse } from "../types/TemplateResponse";
import { TemplateResponseRaw } from "../types/TemplateResponseRaw";

export const formatRawTemplateSuperAdmin = (templateRaw: TemplateResponseRaw): TemplateResponse => ({
  country: templateRaw.country,
  createdAt: templateRaw.created_at * 1000,
  currency: templateRaw.currency,
  edges: templateRaw.edges,
  isActive: templateRaw.is_active,
  lastUpdate: templateRaw.last_update * 1000,
  name: templateRaw.name,
  providers: templateRaw.providers,
  rules: templateRaw.rules,
  segments: templateRaw.segments,
  templateId: templateRaw.template_id,
});
