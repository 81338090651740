import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { SimulatorUpdate } from "@simulatorBuilder/simulatorBuilderSlice";
import { Simulator } from "@simulatorBuilder/types/Simulator";
import {
  SimulatorDashboard,
  SimulatorResultTableColumn,
  SimulatorResultTableData,
  SimulatorResultTableRow,
} from "@simulatorBuilder/types/SimulatorDashboard";
import { Dispatch } from "react";

export const reorderTableColumns = (
  newOrder: Array<string>,
  tableIndex: number,
  tableContent: Array<SimulatorResultTableData>,
  simulatorDashboard: SimulatorDashboard,
  dispatch: Dispatch<unknown>,
  simulatorBuilderChanged: ActionCreatorWithPayload<
    SimulatorUpdate<keyof Simulator>,
    "simulatorBuilder/simulatorBuilderChanged"
  >
) => {
  const table = { ...tableContent[tableIndex] };

  const updatedColumns: Array<SimulatorResultTableColumn> = newOrder.map((columnId) => {
    const column = table.columns.find((col) => col.columnId === columnId);

    if (!column) {
      throw new Error(`Coluna com o ID ${columnId} não encontrada`);
    }

    return column;
  });

  const updatedRows: Array<SimulatorResultTableRow> = table.rows.map((row) => ({
    ...row,
    rowContent: newOrder.map((columnId) => {
      const cell = row.rowContent.find((cell) => cell.id === columnId);
      if (!cell) {
        throw new Error(`Linha com o Id ${columnId} não encontrada`);
      }
      return cell;
    }),
  }));

  const updatedTable = {
    ...table,
    columns: updatedColumns,
    rows: updatedRows,
  };

  const updatedTables = [...tableContent.slice(0, tableIndex), updatedTable, ...tableContent.slice(tableIndex + 1)];

  const updatedSimulatorDashboard = { ...simulatorDashboard, tables: updatedTables };

  dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedSimulatorDashboard }));
};
