import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bankSVGContainer: {
    marginRight: "18px",
    maxHeight: "42px",
    maxWidth: "42px",
  },
});

export default function PDFItauSVG() {
  return (
    <View style={styles.bankSVGContainer}>
      <Svg viewBox="0 0 400 400">
        <Circle cx="200" cy="200" fill="rgb(0, 73, 144)" r="200" />
        <Path d="M100 239.027H118.658V159.008H100V239.027Z" fill="rgb(255, 229, 18)" />
        <Path
          d="M202.261 177.305C190.822 177.305 182.287 180.277 177.653 182.975L181.157 194.765C185.363 192.221 192.609 189.937 199.153 189.937C210.07 189.906 212.375 195.454 212.375 198.264V198.908C188.187 198.805 173.249 206.659 173.249 222.165C173.249 231.289 180.481 240.499 193.825 240.499C201.774 240.499 208.484 237.616 212.56 232.793H212.758L214.798 239.026H229.984V203.556C229.984 189.14 222.905 177.305 202.261 177.305ZM212.916 216.891C212.916 217.841 212.826 218.927 212.605 219.908C212.578 220.035 212.457 220.453 212.421 220.543C212.38 220.643 212.339 220.746 212.299 220.845C210.642 224.754 206.264 228.434 200.374 228.434C195.244 228.434 191.709 225.777 191.709 220.737C191.709 215.09 196.528 212.18 203.715 211.073C205.395 210.807 207.246 210.649 209.228 210.573C210.421 210.532 211.646 210.519 212.916 210.537V216.891Z"
          fill="rgb(255, 229, 18)"
        />
        <Path
          d="M281.526 179.07V214.22C281.526 215.634 281.351 216.895 280.9 218.03C279.405 221.638 275.726 225.722 269.664 225.722C262.049 225.722 258.712 220.124 258.712 210.766V179.03H240.499V214.099C240.499 218.791 241.067 222.782 242.197 226.069C243.323 229.357 244.863 232.081 246.822 234.212C248.781 236.355 251.132 237.918 253.866 238.904C256.599 239.891 259.626 240.386 262.922 240.386C265.796 240.386 268.336 240.035 270.556 239.332C272.772 238.639 274.686 237.792 276.289 236.801C277.897 235.824 279.243 234.752 280.329 233.595C281.414 232.446 282.27 231.419 282.878 230.51L285.224 239.026H300V179.07H281.526Z"
          fill="rgb(255, 229, 18)"
        />
        <Path
          d="M136.165 159.009V179.031H127.663V192.709H136.165V217.091C136.165 225.157 137.782 231.214 141.281 234.898C144.281 238.118 149.554 240.505 155.94 240.505C161.426 240.505 163.592 239.685 166.051 238.73V224.801C165.546 225.04 164.11 225.323 162.241 225.323C155.778 225.323 154.283 221.302 154.283 213.952V192.709H165.853V179.031H154.283V159.009H136.165Z"
          fill="rgb(255, 229, 18)"
        />
        <Path d="M274.634 159L263.389 175.168H276.404L292.261 159H274.634Z" fill="rgb(255, 229, 18)" />
      </Svg>
    </View>
  );
}
