import {
  LqdButton,
  LqdCloseIcon,
  LqdEditIcon,
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { LqdButtonTypes } from "@/liquid-components/src/components/Button/Button";
import MobileDashboardMenu from "@analysis/components/AnalysisDashboard/AnalysisDashboardMobileAnalysisMenu";
import useWindowSize from "@common/hooks/useWindowSize";
import { Grid, Skeleton, Stack, Tab, Tabs } from "@mui/material";
import { useAppSelector } from "../../../store";
import CopyValueToClipboardBadge from "./CopyValueToClipboardBadge";
import { FullScreenDialogExtraButton } from "./FullscreenDialog";

type FullscreenDialogHeaderProps = {
  extraButtons?: Array<FullScreenDialogExtraButton>;
  goBack?: () => void;
  hashValue?: string;
  onClose: () => void;
  onEditClick?: () => void;
  onTabChange?: (tab: string) => void;
  showBackButton?: boolean;
  showEditButton?: boolean;
  showSkeleton?: boolean;
  tabValue?: string;
  title: string;
};

export default function FullscreenDialogHeader(props: FullscreenDialogHeaderProps) {
  const {
    extraButtons,
    goBack,
    hashValue,
    onClose,
    onEditClick,
    onTabChange,
    showBackButton,
    showEditButton,
    showSkeleton,
    tabValue,
    title,
  } = props;

  const { analysisDetails } = useAppSelector((state) => state.analysisDetails);
  const { width } = useWindowSize();

  return (
    <Grid
      container
      sx={{
        backdropFilter: "blur(15px)",
        backgroundBlendMode: "overlay",
        backgroundColor: "rgba(250, 250, 250, 0.7)",
        borderBottom: "0.5px solid rgba(127, 135, 152, 0.08)",
        minHeight: "96px",
        position: "sticky",
        px: { sm: 5, xs: 3 },
        top: 0,
        zIndex: 1,
      }}
    >
      {showSkeleton ? (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ pt: { sm: 0, xs: 2 }, width: "100%" }}
          >
            <Stack
              alignItems={{ sm: "center", xs: "flex-start" }}
              direction={{ sm: "row", xs: "column" }}
              sx={{ width: { sm: "85%", xs: "auto" } }}
            >
              <Skeleton sx={{ mr: { sm: 3, xs: 0 } }} variant="text" width={width === "xs" ? 170 : 280} />
              <Skeleton variant="text" width={105} />
            </Stack>
            <Stack alignItems="center" direction="row" display={{ sm: "flex", xs: "none" }} justifyContent="flex-end">
              <Skeleton sx={{ mr: 3 }} variant="text" width={100} />
              <Skeleton sx={{ mr: 3 }} variant="text" width={100} />
            </Stack>
            <LqdIconButton onClick={onClose} round="true" type="outlinePrimary">
              <LqdCloseIcon />
            </LqdIconButton>
          </Stack>
          {/* Mobile Only */}
          <Stack
            alignItems="center"
            direction="row"
            display={{ sm: "none", xs: "flex" }}
            justifyContent="space-around"
            sx={{ py: 3 }}
          >
            <Skeleton sx={{ mr: 3 }} variant="text" width={175} />
            <Skeleton sx={{ mr: 3 }} variant="text" width={175} />
          </Stack>
        </>
      ) : (
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent="space-between"
          sx={{ mt: { sm: 0, xs: 2 }, width: "100%" }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack alignItems={{ sm: "center", xs: "flex-start" }} direction={{ sm: "row", xs: "column" }}>
              {showBackButton ? (
                <LqdIconButton onClick={goBack} round="true" type="outlinePrimary">
                  <LqdLeftArrowIcon />
                </LqdIconButton>
              ) : null}
              <LqdTypography
                color="rgba(33, 36, 42, 1)"
                sx={{ ml: showBackButton ? 2 : 0, mt: { sm: 0, xs: analysisDetails ? 0 : 1.5 } }}
                textstyle={width === "xs" ? "p2Paragraph" : "h4Headline"}
              >
                {title}
              </LqdTypography>
              {hashValue ? (
                <Stack sx={{ ml: { sm: 2, xs: 0 }, mt: { sm: 0, xs: 1 } }}>
                  <CopyValueToClipboardBadge value={hashValue} />
                </Stack>
              ) : null}
              {showEditButton ? (
                <LqdIconButton onClick={onEditClick} round="true" sx={{ ml: 2 }} type="ghostIcon">
                  <LqdEditIcon />
                </LqdIconButton>
              ) : null}
            </Stack>
            <Stack direction="row" display={{ sm: "none", xs: "flex" }} gap={1.25}>
              <Stack display={analysisDetails && tabValue === "dashboard" ? "flex" : "none"}>
                <MobileDashboardMenu analysisDetails={analysisDetails!} />
              </Stack>
              <LqdIconButton onClick={onClose} round="true" type="outlinePrimary">
                <LqdCloseIcon />
              </LqdIconButton>
            </Stack>
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="flex-end">
            {extraButtons && extraButtons.length > 0 && extraButtons[0].type === "tab" ? (
              <Tabs
                onChange={(_, newValue) => onTabChange!(newValue)}
                sx={{
                  ".MuiTabs-indicator": { top: { md: 0, xs: null } },
                  mr: 2,
                  mt: { sm: 0, xs: 2 },
                  width: "100%",
                }}
                value={tabValue}
              >
                {extraButtons.map((button) => (
                  <Tab
                    key={button.title}
                    label={button.title}
                    sx={{
                      "&.MuiButtonBase-root": {
                        fontSize: { sm: "16px", xs: "14px" },
                        lineHeight: "24px",
                        padding: "14px",
                      },
                      height: { md: "96px", xs: "auto" },
                      minWidth: "120px",
                      width: { sm: "auto", xs: `${100 / extraButtons.length}%` },
                    }}
                    value={button.title.toLowerCase()}
                  />
                ))}
              </Tabs>
            ) : (
              extraButtons?.map((button) => (
                <LqdButton
                  buttonsize="medium"
                  disabled={button.disabled}
                  key={button.title}
                  onClick={button.onClick}
                  sx={{ mr: 1.25 }}
                  type={button.type as LqdButtonTypes}
                >
                  {button.title}
                </LqdButton>
              ))
            )}
            <LqdIconButton
              onClick={onClose}
              round="true"
              sx={{ display: { sm: "flex", xs: "none" } }}
              type="outlinePrimary"
            >
              <LqdCloseIcon />
            </LqdIconButton>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
}
