import PDFBancoBrasilSVG from "./PDFBancoBrasilSVG";
import PDFBradescoSVG from "./PDFBradescoSVG";
import PDFCaixaSVG from "./PDFCaixaSVG";
import PDFDefaultBankSVG from "./PDFDefaultBankSVG";
import PDFItauSVG from "./PDFItauSVG";
import PDFSantanderSVG from "./PDFSantanderSVG";

export const renderBankSVG = (bankName: string) => {
  switch (bankName) {
    case "Banco do Brasil":
      return <PDFBancoBrasilSVG />;
    case "Bradesco":
      return <PDFBradescoSVG />;
    case "Caixa Econômica Federal":
      return <PDFCaixaSVG />;
    case "Itaú":
      return <PDFItauSVG />;
    case "Santander":
      return <PDFSantanderSVG />;
    default:
      return <PDFDefaultBankSVG />;
  }
};
