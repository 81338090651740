import { ObjectOf } from "../types/ObjectOf";

export const ERROR_MESSAGES_DICTIONARY: ObjectOf<string> = {
  "Invalid mfa code": "Código de autenticação inválido.",
  "Password not found": "Realize o cadastro da senha antes de tentar recuperá-la.",
  "Rules array is empty": "Não é possível ativar um produto que não possua regras de negócio.",
  "There are variables with invalid names.": "Existem variáveis com nome inválido:",
  "invalid body": "O formato dos dados enviados é inválido. Tente novamente.",
  "invalid username/password": "E-mail ou senha inválidos. Tente novamente.",
  "tenant not found": "Seu login é inválido neste ambiente. Contate os administradores.",
  "username already exists": "Este e-mail já foi convidado. Reenvie ou altere o convite na lista de usuários.",
};
