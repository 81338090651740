import { BuilderSimulation } from "../../../../../../types/Simulation/Builder/BuilderSimulation";
import PDFBuilderSimulatorCards from "./PDFBuilderSimulatorCards";
import PDFBuilderSimulatorHighlights from "./PDFBuilderSimulatorHighlights";

type PDFBuilderInfoContainerProps = {
  simulation: BuilderSimulation;
};
export default function PDFBuilderInfoContainer(props: PDFBuilderInfoContainerProps) {
  const { simulation } = props;

  return (
    <>
      <PDFBuilderSimulatorCards simulation={simulation} />
      <PDFBuilderSimulatorHighlights simulation={simulation} />
    </>
  );
}
