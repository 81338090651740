import {
  LqdButton,
  LqdDownTwoIcon,
  LqdIconButton,
  LqdSelectMenuItem,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Select } from "@mui/material";
import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";
import { SimulatorFunctionBlocksFormViews } from "@simulatorBuilder/types/SimulatorFunctionBlocksFormViews";
import { useEffect } from "react";
import { useAppSelector } from "../../../../../../store";
import { SimulatorVariableTemplateType } from "../../../../types/SimulatorVariableTypeTemplate";

type SimulatorFunctionBlocksFormNewVariableModalTypeProps = {
  blockId: number;
  blocks: Array<SimulatorFunctionBlock>;
  functionId: number;
  functionKey: string;
  handleDeleteVariable: () => void;
  handleGoBack: () => void;
  setCurrentView: (view: SimulatorFunctionBlocksFormViews) => void;
  setVariableType: (type: SimulatorVariableTemplateType) => void;
  variableType: SimulatorVariableTemplateType;
};

export default function SimulatorFunctionBlocksFormNewVariableModalType(
  props: SimulatorFunctionBlocksFormNewVariableModalTypeProps
) {
  const {
    blockId,
    blocks,
    functionId,
    functionKey,
    handleDeleteVariable,
    handleGoBack,
    setCurrentView,
    setVariableType,
    variableType,
  } = props;

  const { functionTemplates, selectedVariable, variableTypes } = useAppSelector((state) => state.simulatorBuilder);

  const currentBlock = blocks.find((block) => block.block_id === blockId);
  const currentFunction = currentBlock?.functions.find((funct) => funct.index === functionId);

  const matchedFunctionTemplate = functionTemplates.find(
    (template) => template.function_name === currentFunction?.function_name
  )!;

  const filteredVariableTypes = variableTypes.filter((type) => {
    if ((matchedFunctionTemplate && selectedVariable.functionKey) || functionKey) {
      const variableTemplate = matchedFunctionTemplate.variables[selectedVariable.functionKey || functionKey];
      if (variableTemplate) {
        return variableTemplate.types.includes(type.value);
      }
    }
    return false;
  });

  useEffect(() => {
    if (selectedVariable.type && !variableType) {
      setVariableType(selectedVariable.type as SimulatorVariableTemplateType);
    }
  }, [selectedVariable, variableType, setVariableType]);

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
          Qual o tipo da variável constante?
        </LqdTypography>
        {selectedVariable.id ? (
          <LqdIconButton buttonsize="small" onClick={handleDeleteVariable} type="ghostIcon">
            <LqdTrashIcon />
          </LqdIconButton>
        ) : null}
      </Box>
      <>
        <Select
          displayEmpty
          IconComponent={(props) => (
            <LqdIconButton type="ghostPrimary" {...props}>
              <LqdDownTwoIcon />
            </LqdIconButton>
          )}
          MenuProps={{ sx: { borderRadius: "20px", mt: 1 } }}
          onChange={(event) => setVariableType(event.target.value as SimulatorVariableTemplateType)}
          renderValue={() =>
            variableType ? (
              <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1.5, ml: 2, padding: 0 }} textstyle="h5Headline">
                {variableTypes.find((variable) => variable.value === variableType)!.label}
              </LqdTypography>
            ) : (
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 1.5, ml: 2, padding: 0 }} textstyle="h5Headline">
                Selecione o tipo da variável
              </LqdTypography>
            )
          }
          sx={{
            "& .MuiSelect-select": {
              borderRadius: "20px",
              boxShadow: variableType ? "0px 30px 40px -20px rgba(0, 0, 0, 0.2)" : "none",
            },
            "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
            "&.Mui-focused": { borderBottom: "2px solid rgba(0, 0, 0, 0)" },
            "&:after": {
              borderBottom: !variableType ? "2px solid rgba(33, 36, 42, 1)" : "2px solid rgba(56, 61, 70, 1)",
            },
            "&:before": {
              borderBottom: !variableType ? "2px solid rgba(212, 215, 220, 1)" : "2px solid rgba(127, 135, 152, 0.32)",
            },
            "&:hover": {
              borderBottom: !variableType ? "2px solid rgba(65, 70, 103, 1)" : "2px solid rgba(0, 0, 0, 0)",
            },
            "&:not(.Mui-disabled):hover::before": {
              borderBottom: !variableType ? "1px solid rgba(212, 215, 220, 1)" : "2px solid rgba(56, 61, 70, 1)",
            },
            alignItems: "flex-end",
            borderBottom: "2px solid rgba(0, 0, 0, 0)",
            height: "90px",
            mt: -2,
            width: "100%",
          }}
          value={variableType}
          variant="standard"
        >
          {filteredVariableTypes.map((variableType) => (
            <LqdSelectMenuItem key={variableType.label} value={variableType.value}>
              <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
                {variableType.label}
              </LqdTypography>
            </LqdSelectMenuItem>
          ))}
        </Select>
        <Box sx={{ float: "right", mt: 3 }}>
          <LqdButton onClick={handleGoBack} sx={{ mr: 3 }} type="outlineTertiary">
            Voltar
          </LqdButton>
          <LqdButton disabled={!variableType} onClick={() => setCurrentView("variableLabel")}>
            {selectedVariable.id ? "Continuar" : "Salvar"}
          </LqdButton>
        </Box>
      </>
    </>
  );
}
