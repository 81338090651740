import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { OpenCreditActiveWalletRow } from "../../../../../types/AnalysisDetails/OpenCredit/OpenCreditActiveWalletRow";
import { formatValueToCurrency, formatValueToPercentage } from "../../../../../utils/analysisDetailsDataFormatter";
import { openCreditTerms } from "../../../DashBoardPeerView/OpenCredit/OpenCreditDictionary";
import PDFTableIconOrangeSVG from "../PDFTableIconOrangeSVG";

type PDFOpenCreditActiveWalletTableProps = {
  currentPage: number;
  lastPage: number;
  tableData: Array<OpenCreditActiveWalletRow>;
  tableLength: number;
  tableOccurrences?: number;
  tableValue?: number;
};

const styles = StyleSheet.create({
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  genericFlexRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableCount: {
    color: "rgb(33, 36, 42)",
    fontSize: "20px",
    paddingLeft: "24px",
  },
  tableFullLabel: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    padding: "24px",
  },
  tableHeaderContainer: {
    alignItems: "center",
    backgroundColor: "rgb(250, 250, 251)",
    borderTop: "none",
    display: "flex",
    flexDirection: "row",
    height: "60px",
  },
  tableInnerContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "95%",
  },
  tableOuterContainer: {
    border: "1px solid rgb(222, 225, 229)",
    borderRadius: "20px",
    marginTop: "24px",
    paddingTop: "25px",
    width: "100%",
  },
  tableResults: {
    color: "rgb(127, 135, 152)",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingTop: "5px",
  },
  tableRowContainer: {
    alignItems: "center",
    borderTop: "1px solid rgb(222, 225, 229)",
    display: "flex",
    flexDirection: "row",
    height: "72px",
  },
  tableTitle: {
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
    paddingLeft: "24px",
  },
  tableTotalTitle: {
    alignSelf: "flex-end",
    color: "rgb(155, 162, 175)",
    fontSize: "14px",
  },
  tableTotalValue: {
    color: "rgb(255, 181, 71)",
    fontSize: "20px",
  },
  tableWithThreeColumns1: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "40%",
  },
  tableWithThreeColumns2: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "40%",
  },
  tableWithThreeColumns3: {
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px",
    width: "20%",
  },
  totalValueContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
  },
});

export default function PDFOpenCreditActiveWalletTable(props: PDFOpenCreditActiveWalletTableProps) {
  const { currentPage, lastPage, tableData, tableLength, tableOccurrences, tableValue } = props;

  const rangeStart = currentPage * 16 - 15;
  const rangeEnd = currentPage === lastPage ? tableLength : currentPage * 16;

  return (
    <View minPresenceAhead={40} style={styles.tableOuterContainer}>
      <View style={styles.genericFlexRowContainer}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <PDFTableIconOrangeSVG />
          <View style={styles.genericFlexColumnContainer}>
            <Text style={styles.tableTitle}>Carteira Ativa</Text>
            {tableLength > 0 ? (
              <View style={styles.genericFlexRowContainer}>
                <Text style={styles.tableCount}>{tableLength}</Text>
                {tableLength > 16 ? (
                  <Text style={styles.tableResults}>{`(exibindo resultados ${rangeStart} a ${rangeEnd})`}</Text>
                ) : null}
              </View>
            ) : (
              <Text style={styles.tableCount}>0</Text>
            )}
          </View>
        </View>
        <View style={styles.totalValueContainer}>
          <Text style={styles.tableTotalTitle}>Valor total</Text>
          <Text style={styles.tableTotalValue}>
            {tableValue ? formatValueToCurrency(tableValue, "BRL", true) : "R$ 0,00"}
          </Text>
        </View>
      </View>
      <Text style={styles.tableFullLabel}>
        Total a vencer que está atrasado:
        {tableOccurrences ? ` ${formatValueToCurrency(tableOccurrences, "BRL", true)}` : " R$ 0,00"}
      </Text>
      <View style={styles.tableInnerContainer}>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.tableWithThreeColumns1}>A vencer</Text>
          <Text style={styles.tableWithThreeColumns2}>Percentual</Text>
          <Text style={styles.tableWithThreeColumns3}>Valor</Text>
        </View>
        {tableData.map((row, index) => (
          <View key={`${row.code}-${index}`} style={styles.tableRowContainer}>
            <Text style={styles.tableWithThreeColumns1}>{row.code ? openCreditTerms[row.code] : "Não encontrado"}</Text>
            <Text style={styles.tableWithThreeColumns2}>
              {row.pct ? `${formatValueToPercentage(row.pct)}` : "Não encontrado"}
            </Text>
            <Text style={styles.tableWithThreeColumns3}>
              {row.value ? formatValueToCurrency(row.value, "BRL", true) : "Não encontrado"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
