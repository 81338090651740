import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import LiquidLogo from "../components/LiquidLogo";
import useWindowSize from "../hooks/useWindowSize";

export default function OpenCreditTermsAndConditions() {
  const { width } = useWindowSize();
  return (
    <Box sx={{ overflow: "auto" }}>
      <Box
        sx={{
          backdropFilter: "blur(80px)",
          backgroundColor: "rgba(250, 250, 250, 1)",
          borderBottom: "1px solid rgba(127, 135, 152, 0.08)",
          opacity: 0.7,
        }}
      >
        <LiquidLogo sx={{ pb: "20px", pl: "40px", pt: "28px" }} />
      </Box>
      <Box sx={{ height: "calc(100vh - 96px)", margin: "auto", width: "80vw" }}>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 5,
            pt: { md: 10, xs: 5 },
          }}
          textstyle={width === "xs" ? "h4Headline" : "h3Headline"}
        >
          Termos e Condições de Uso
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(33, 36, 42, 1)", pb: 5 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h4Headline"}
        >
          Consulta ao Sistema de Informações de Crédito (SCR)
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          1. Autorização para Consulta SCR
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Ao acessar e utilizar os serviços da Fidúcia SCMEPP, por meio deste documento, concedo autorização expressa
          para que a Fidúcia SCMEPP consulte os débitos e responsabilidades decorrentes de operações com características
          de crédito, bem como as informações e os registros de medidas judiciais relacionadas a mim. Essa consulta será
          realizada no Sistema de Informações de Crédito (SCR), gerido pelo Banco Central do Brasil - Bacen, ou em
          sistemas assemelhados que venham a complementá-lo. Essa autorização visa embasar a análise de crédito
          necessária para a concessão de crédito ou vínculo comercial pela Fidúcia SCMEPP.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", fontSize: { md: "20px", xs: "16px" }, pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          2. Condições para Consulta SCR
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Estou ciente de que o SCR tem como finalidade prover informações ao Banco Central do Brasil para monitoramento
          do crédito no sistema financeiro e para atividades de fiscalização, conforme estabelecido na Lei Complementar
          nº 105, de 10 de janeiro de 2001. As consultas ao SCR podem ser realizadas diretamente junto ao Banco Central
          do Brasil ou por meio de API (Application Programming Interface). Adicionalmente, reconheço que a consulta ao
          SCR depende de minha prévia autorização, conforme formalizado por meio deste termo.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          3. Acesso aos Dados no SCR
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",

            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Tenho ciência de que poderei ter acesso aos dados constantes em meu nome no SCR por meio do sistema registrado
          do Banco Central do Brasil - Bacen, garantindo transparência e acesso às informações sobre minhas operações de
          crédito.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          4. Verificação e Correção de Informações
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Em caso de identificação de informações equivocadas inseridas no SCR pela Fidúcia SCMEPP, comprometo-me a
          informar à instituição para que sejam realizadas as devidas averiguações quanto à veracidade da informação. A
          Fidúcia SCMEPP, se necessário, compromete-se a praticar atos visando a correção ou exclusão dos dados
          incorretos.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", fontSize: { md: "20px", xs: "16px" }, pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          5. Consentimento para Uso de Informações Pessoais
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Conforme as disposições da Lei Geral de Proteção de Dados (Lei nº 13.709, de 14 de agosto de 2018), autorizo o
          uso de minhas informações pessoais, incluindo dados sensíveis, exclusivamente para os fins de operações de
          crédito e abertura de conta.
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(17, 17, 17, 1)", pb: 3 }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          6. Compartilhamento de Informações
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(33, 36, 42, 1)",
            pb: 3,
            textAlign: "justify",
          }}
          textstyle={width === "xs" ? "p2Paragraph" : "p1Paragraph"}
        >
          Autorizo a Fidúcia SCMEPP a compartilhar as informações coletadas, conforme descrito anteriormente, com
          terceiros envolvidos no processo de análise para a contratação de crédito. Além disso, a Fidúcia SCMEPP poderá
          transmitir essas informações a possíveis Cessionários ou Endossatários da operação que embasa a análise
          delineada neste documento. Ao aceitar estes termos e condições, reconheço a importância da autorização para
          consulta ao SCR e concordo com os procedimentos estabelecidos pela Fidúcia SCMEPP para a obtenção e utilização
          dessas informações.
        </LqdTypography>
        <LqdTypography
          sx={{
            color: "rgba(127, 135, 152, 1)",
            pb: { md: 15, xs: 5 },
          }}
          textstyle={width === "xs" ? "c1Caption" : "p2Paragraph"}
        >
          Versão 1: atualizada em Novembro de 2023.
        </LqdTypography>
      </Box>
    </Box>
  );
}
