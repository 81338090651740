import { StyleSheet, Text, View } from "@react-pdf/renderer";

type PDFGeneralInfoCardWithPhoneProps = {
  title: string;
  value: string;
};

const styles = StyleSheet.create({
  allotmentSimulatorInfoCard: {
    border: "1px solid rgb(224, 226, 230)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "188px",
    width: "245px",
  },
  allotmentSimulatorInfoCardRealSign: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    paddingLeft: "24px",
    paddingTop: "16px",
  },
  allotmentSimulatorInfoCardTitle: {
    color: "rgb(7, 65, 84)",
    fontSize: "16px",
    height: "50px",
    marginTop: "30px",
    paddingLeft: "24px",
  },
  allotmentSimulatorInfoCardValue: {
    color: "rgb(7, 65, 84)",
    fontSize: "28px",
    paddingLeft: "24px",
  },
});

export default function PDFGeneralInfoCardWithPhone(props: PDFGeneralInfoCardWithPhoneProps) {
  const { title, value } = props;

  const firstHalfOfPhone = value?.split(" ")[0];
  const secondHalfOfPhone = value?.split(" ")[1];

  return (
    <View style={styles.allotmentSimulatorInfoCard}>
      <Text style={styles.allotmentSimulatorInfoCardTitle}>{title}</Text>
      <Text style={styles.allotmentSimulatorInfoCardRealSign}>{firstHalfOfPhone}</Text>
      <Text style={styles.allotmentSimulatorInfoCardValue}>{secondHalfOfPhone || "Indisponível"}</Text>
    </View>
  );
}
