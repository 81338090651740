import { LqdButton, LqdLink, LqdTypography } from "@/liquid-components/src";
import { Box, Grid } from "@mui/material";
import FrontPageLayout from "../../common/components/FrontPageLayout";
import signinImg from "../assets/first-login.png";

export default function FirstSignInErrorRoute() {
  return (
    <FrontPageLayout
      leftContent={
        <>
          <Grid container>
            <Grid item sm={0} xs={2} />
            <Grid item sm={12} xs={10}>
              <img alt="" src={signinImg} style={{ width: "100%" }} />
              <Box sx={{ mt: { sm: 6, xs: 2 }, pr: 8 }}>
                <LqdTypography textstyle="h2Headline">ID Pass</LqdTypography>
                <LqdTypography color="secondary" textstyle="h4Headline">
                  Aumentando seu sucesso com análises inteligentes e dinâmicas.
                </LqdTypography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              bottom: "64px",
              boxSizing: "border-box",
              display: { sm: "none", xs: "block" },
              position: "absolute",
              px: 3,
              width: "100%",
            }}
          >
            <LqdButton href="/signin#begin">Começar</LqdButton>
          </Box>
        </>
      }
      rightContent={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <LqdTypography sx={{ mb: 3 }} textstyle="h1Headline">
            Olá usuário
          </LqdTypography>
          <LqdTypography sx={{ mb: 3 }}>
            Houve algum problema inesperado e não conseguimos identificar seu usuário.
          </LqdTypography>
          <LqdTypography sx={{ mb: 6 }}>Estamos trabalhando para resolver este problema.</LqdTypography>
          <LqdTypography>
            Aguarde nosso contato por e-mail ou <LqdLink>clique aqui</LqdLink> para falar com nosso suporte.
          </LqdTypography>
        </Box>
      }
    />
  );
}
