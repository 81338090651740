import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";
import LiquidLogo from "../../common/components/LiquidLogo";
import useWindowSize from "../../common/hooks/useWindowSize";
import { GO_SAFFE_KEY } from "../../common/utils/apiUtils";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import { getSCRTokenData } from "../api/GetSCRTokenData";
import { refuseOpenCreditValidation } from "../api/OpenCreditRefuseValidation";
import OpenCreditAcceptedState from "../components/OpenCreditConfirmation/OpenCreditAcceptedState";
import OpenCreditDisagreeState from "../components/OpenCreditConfirmation/OpenCreditDisagreeState";
import OpenCreditFinishedState from "../components/OpenCreditConfirmation/OpenCreditFinishedState";
import OpenCreditLoadingState from "../components/OpenCreditConfirmation/OpenCreditLoadingState";
import OpenCreditRefusedState from "../components/OpenCreditConfirmation/OpenCreditRefusedState";
import OpenCreditStartState from "../components/OpenCreditConfirmation/OpenCreditStartState";
import { SCRTokenData } from "../types/AnalysisDetails/OpenCredit/SCRTokenData";

interface GoSaffe {
  init: (goSaffeAuthKey: string, userEmail: string, endToEndId: string, transactionType: string) => void;
}

declare global {
  interface Window {
    GoSaffe: GoSaffe | undefined;
  }
}

export default function OpenCredit() {
  const handleLiquidErrors = useErrorHandler();
  const { agreeDisagree, method, token } = useParams();
  const { width } = useWindowSize();

  const CPFValue = useAppSelector((state) => state.common.dialogFieldValue);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingDisagree, setIsLoadingDisagree] = useState<boolean>(false);
  const [isRefused, setIsRefused] = useState<boolean>(false);
  const [isTokenDataAccepted, setIsTokenDataAccepted] = useState<boolean>(false);

  const [tokenData, setTokenData] = useState<SCRTokenData | null>(null);
  const [transactionType, setTransactionType] = useState<string>("");

  const isDisagreeRoute = agreeDisagree === "disagree";
  const END_TO_END_ID = `${token}/${method}`;

  /** Cria o script da GoSaffe, que será acessado depois. */
  useEffect(() => {
    const goSaffeScript = document.createElement("script");
    goSaffeScript.setAttribute("src", "https://go.saffe.ai/cdn/latest");
    document.head.appendChild(goSaffeScript);
  }, []);

  /** Recupera os dados do token no load inicial da aplicação */
  useEffect(() => {
    const getTokenData = async () => {
      const tokenDataRaw = await getSCRTokenData(token!);
      const tokenData = tokenDataRaw.data;

      setTokenData(tokenData);
      setTransactionType(tokenData.is_onboarding ? "onboarding" : "verification");
      setIsRefused(tokenData.status ? tokenData.status === "unauthorized" : false);
      setIsTokenDataAccepted(tokenData.accepted);
      setIsLoading(false);
    };

    getTokenData();
  }, []);

  /** Inicializa a transação com a GoSaffe. */
  const initTransaction = () => {
    if (window.GoSaffe && typeof window.GoSaffe.init === "function") {
      window.GoSaffe.init(GO_SAFFE_KEY, CPFValue as string, END_TO_END_ID, transactionType);
    }
  };

  /** Escuta os eventos da GoSaffe. */
  window.addEventListener("message", (event) => {
    if (event.data.source === "go-saffe-capture") {
      if (event.data.payload.event === "close") {
        setIsLoading(false);
      }

      if (event.data.payload.event === "finish") {
        setIsTokenDataAccepted(true);
        setIsLoading(false);
      }
    }
  });

  /** Recusa a validação do Open Credit. */
  const onRefuseValidation = async () => {
    setIsLoadingDisagree(true);

    try {
      await refuseOpenCreditValidation(token!);
      setIsRefused(true);
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setIsLoadingDisagree(false);
    }
  };

  /** Renderiza o conteúdo da página de acordo com o estado atual. */
  const renderContent = () => {
    switch (true) {
      case isLoading:
        return <OpenCreditLoadingState />;
      case tokenData && isRefused:
        return <OpenCreditRefusedState />;
      case tokenData && isDisagreeRoute:
        return (
          <OpenCreditDisagreeState
            isLoading={isLoadingDisagree}
            onCancel={onRefuseValidation}
            onInit={initTransaction}
            passId={tokenData!.pass_id}
          />
        );
      case tokenData && tokenData?.accepted:
        return <OpenCreditAcceptedState />;
      case tokenData && isTokenDataAccepted:
        return <OpenCreditFinishedState />;
      case tokenData && !isTokenDataAccepted:
        return <OpenCreditStartState onInit={initTransaction} passId={tokenData!.pass_id} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          backdropFilter: "blur(15px)",
          backgroundBlendMode: "overlay",
          backgroundColor: "rgba(250, 250, 250, 0.7)",
          borderBottom: "0.5px solid rgba(127, 135, 152, 0.08)",
          height: width === "sm" || width === "xs" ? "63px" : "96px",
          position: "sticky",
          px: { sm: 5, xs: 3 },
          top: 0,
          zIndex: 1,
        }}
      >
        <LiquidLogo sx={{ pb: { sm: "20px", xs: "4px" }, pt: { sm: "28px", xs: "14px" } }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          height: { lg: "calc(100vh - 97px)", xs: "calc(100vh - 120px)" },
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: isLoading ? "column" : { lg: "row", xs: "column-reverse" },
            justifyContent: isLoading ? "center" : { lg: "space-around", xs: "flex-start" },
            margin: "auto",
            width: "80vw",
          }}
        >
          <Box
            sx={{ alignItems: "center", display: "flex", flex: 1, flexDirection: "column", justifyContent: "center" }}
          >
            {renderContent()}
          </Box>
        </Box>
      </Box>
    </>
  );
}
