import { LqdButton, LqdLoadingIcon, LqdTypography, LqdUserIcon } from "@/liquid-components/src";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { onChangingView } from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
import RegistryNavigationBar from "./RegistryNavigationBar";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function SigningProcess() {
  const dispatch = useAppDispatch();

  return (
    <>
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Você está assinando:",
        }}
        previusPage={SignatureViews.DOCUMENT_VIEW}
        showDoc={true}
      />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Vamos assinar!
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Precisamos das seguintes informações para finalizar a assinatura.
        </LqdTypography>

        <Stack sx={{ alignItems: "center", display: "flex", flexDirection: "row", mb: 1.5, mt: 5 }}>
          <Stack sx={{ mr: 1.5 }}>
            <LqdUserIcon />
          </Stack>
          <LqdTypography color="rgba(101, 110, 127, 1)">Seu CPF</LqdTypography>
        </Stack>
        <Stack sx={{ alignItems: "center", display: "flex", flexDirection: "row", mb: 3 }}>
          <Stack sx={{ mr: 1.5 }}>
            <LqdLoadingIcon />
          </Stack>
          <LqdTypography color="rgba(101, 110, 127, 1)">Seu Certificado Digital ICP Brasil</LqdTypography>
        </Stack>

        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.DOCUMENT_SEARCH))}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          Continuar
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
