import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  onArchiveCurrentAnalysis,
  onLoadAnalysisBankRates,
  onLoadAnalysisDetails,
  onLoadAnalysisPendencies,
  onLoadPluggyBookData,
  onReactToMessage,
  onRunAnalysis,
  onSendMessageToAI,
  onUpdateAnalysisDetails,
  onUpdatePendencies,
  onUpdateSignaturePendencyDocumentPath,
  onUpdateUploadPendencyChoices,
  onUpdateUploadPendencyDocumentPath,
} from "./analysisDetailsThunks";
import { AnalysisPendency } from "./types/AnalysisDetails/Analysis/AnalysisPendency";
import { AIMessage } from "./types/AnalysisDetails/IA/AIMessage";
import { TrustpadView } from "./types/AnalysisDetails/Trustpad/TrustpadView";
import { AnalysisDetailsState } from "./types/AnalysisDetailsState";
import { formatRawAnalysisPendency } from "./utils/formatRawAnalysisPendency";
import { INITIAL_FAQS } from "./utils/liquidAIInitialFaq";

const initialState: AnalysisDetailsState = {
  aiAnswers: {}, // Lista das mensagens do modal Liquid AI
  aiFaqs: INITIAL_FAQS, // Lista de sugestão de perguntas do modal Liquid AI
  analysisDashboardSelectedOption: { label: "", type: "" }, // Menu selecionado no dashboard
  analysisDetails: null, // Conexão aberta
  analysisId: null, // Id da conexão que deve ser aberta (quando clica num card no kanban)
  analysisLastRun: 0, // Last run da conexão aberta
  analysisPendencies: {}, // Pendências da conexão aberta
  analysisPendenciesOriginal: {}, // Cópia da lista de pendências
  analysisSteps: null, // Steps da conexão aberta
  hasLoadedMessages: false, // Indica se as mensagens do modal Liquid AI já foram carregadas
  loadAnalysisStepsTimeout: false, // Indica se houve o timeout no carregamento dos steps
  loadingStatus: { analysisData: false, graphData: false }, // Status de loading
  pendenciesChanged: false, // Indica se as pendências da conexão aberta sofreram alguma edição
  pendencyToEditId: null, // Id da pendência que está sendo editada
  pluggyBookData: {},
  pluggyCashFlowBookData: {},
  pluggyCreditsBookData: {},
  pluggyDebitsBookData: {},
  pluggySpendingEvolutionBookData: {},
  productBankRates: null, // Dados de bank rates do produto da conexão aberta
  productTargets: null, // Target users da conexão aberta
  trustpadCurrentView: TrustpadView.STEPS, // View a ser exibida no trustpad
};

const analysisSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadAnalysisDetails.pending, (state) => {
        state.loadingStatus.analysisData = true;
      })
      .addCase(onLoadAnalysisDetails.fulfilled, (state, action) => {
        const { analysisDetails, analysisDetailsSteps, analysisLastRun, analysisPendencies, productTargets } =
          action.payload;

        if (analysisLastRun > state.analysisLastRun) state.analysisLastRun = analysisLastRun;
        state.analysisDetails = analysisDetails;
        state.analysisPendencies = analysisPendencies;
        state.analysisPendenciesOriginal = analysisPendencies;
        state.analysisSteps = analysisDetailsSteps || state.analysisSteps || {};
        state.productTargets = productTargets;
        state.hasLoadedMessages = true;
        state.loadingStatus.analysisData = false;
        state.loadAnalysisStepsTimeout = !analysisDetailsSteps;
        state.pendencyToEditId = null;
        state.pendenciesChanged = false;
        state.trustpadCurrentView = TrustpadView.STEPS;

        action.payload.aiMessageHistory.messages.forEach(
          (aiMessage) => (state.aiAnswers[aiMessage.message_id] = aiMessage)
        );
      })
      .addCase(onLoadAnalysisBankRates.fulfilled, (state, action) => {
        state.productBankRates = action.payload;
      })
      .addCase(onLoadAnalysisPendencies.fulfilled, (state, action) => {
        state.analysisPendencies = action.payload;
      })
      .addCase(onLoadPluggyBookData.pending, (state, action) => {
        const {
          meta: {
            arg: { whichGraph },
          },
        } = action;
        if (whichGraph === "all") state.loadingStatus.graphData = true;
      })
      .addCase(onLoadPluggyBookData.fulfilled, (state, action) => {
        const {
          peerDocument,
          pluggyBookData: { data },
          whichGraph,
        } = action.payload;
        switch (whichGraph) {
          case "credits":
            state.pluggyCreditsBookData[peerDocument] = data;
            break;
          case "debits":
            state.pluggyDebitsBookData[peerDocument] = data;
            break;
          case "spendingEvolution":
            state.pluggySpendingEvolutionBookData[peerDocument] = data;
            break;
          case "cashFlow":
            state.pluggyCashFlowBookData[peerDocument] = data;
            break;
          case "all":
            state.pluggyBookData[peerDocument] = data;
            break;
        }
        state.loadingStatus.graphData = false;
      })
      .addCase(onRunAnalysis.pending, (state) => {
        state.loadingStatus.analysisData = true;
      })
      .addCase(onRunAnalysis.fulfilled, (state, action) => {
        state.loadingStatus.analysisData = false;
        state.analysisId = action.payload;
      })
      .addCase(onUpdatePendencies.pending, (state) => {
        state.loadingStatus.analysisData = true;
      })
      .addCase(onUpdatePendencies.fulfilled, (state) => {
        state.loadingStatus.analysisData = false;
      })
      .addCase(onUpdateAnalysisDetails.fulfilled, (state, action) => {
        state.analysisDetails = action.payload;
      })
      .addCase(onSendMessageToAI.fulfilled, (state, action) => {
        const { assistant_message, faq, message_id, reaction, timestamp, user_message } = action.payload;
        const aiMessage: AIMessage = { assistant_message, faq: state.aiFaqs, message_id, timestamp, user_message };
        faq !== undefined && faq.length > 0 ? (state.aiFaqs = faq) : (state.aiFaqs = INITIAL_FAQS);
        if (reaction) aiMessage.reaction = reaction;
        state.aiAnswers[aiMessage.message_id] = aiMessage;
      })
      .addCase(onReactToMessage.fulfilled, (state, action) => {
        state.aiAnswers[action.payload.message_id] = action.payload;
      })
      .addCase(onArchiveCurrentAnalysis.fulfilled, (state, action) => {
        if (state.analysisDetails) state.analysisDetails.archived = action.payload;
      })
      .addCase(onUpdateUploadPendencyChoices.fulfilled, (state, action) => {
        const pendencyId = action.payload.pendency_id;
        const pendency = formatRawAnalysisPendency(action.payload);
        state.analysisPendencies[pendencyId] = pendency;
      })
      .addCase(onUpdateUploadPendencyDocumentPath.fulfilled, (state, action) => {
        const { choices, pendencyId } = action.payload;
        state.analysisPendencies[pendencyId].choices = choices;
      })
      .addCase(onUpdateSignaturePendencyDocumentPath.fulfilled, (state, action) => {
        const { choices, pendencyId } = action.payload;
        state.analysisPendencies[pendencyId].choices = choices;
      }),
  initialState,
  name: "analysisDetails",
  reducers: {
    onAnalysisDetailsModalClosed(state, action: PayloadAction<(() => void) | undefined>) {
      state.aiAnswers = {};
      state.analysisDashboardSelectedOption = { label: "", type: "" };
      state.analysisDetails = null;
      state.aiFaqs = INITIAL_FAQS;
      state.analysisId = null;
      state.analysisPendencies = {};
      state.analysisPendenciesOriginal = {};
      state.analysisSteps = null;
      state.productTargets = null;
      state.hasLoadedMessages = false;
      state.pluggyBookData = {};
      state.productBankRates = null;

      if (action.payload) action.payload();
    },
    onAnalysisDetailsModalOpened(state, action: PayloadAction<string>) {
      if (window.location.href.includes("dashboard")) {
        state.analysisId = action.payload;
        window.history.pushState("", "", `/board/${action.payload}/dashboard`);
      } else {
        state.analysisId = action.payload;
        window.history.pushState("", "", `/board/${action.payload}/trustpad`);
      }
    },
    onAnalysisPendencyUpdated(state, action: PayloadAction<AnalysisPendency>) {
      if (state.analysisPendencies) {
        state.analysisPendencies[action.payload.pendencyId] = action.payload;
        if (action.payload.kpi !== "open_credit_invite" && action.payload.kpi !== "open_finance_invite") {
          if (action.payload.value !== state.analysisPendenciesOriginal[action.payload.pendencyId].value) {
            state.pendenciesChanged = true;
          }
        }
      }
    },
    onCloseWithoutSavingPendencies(state) {
      state.analysisPendencies = state.analysisPendenciesOriginal;
      state.pendenciesChanged = false;
    },
    onLoadAnalysisStepsTimeoutClear(state) {
      state.loadAnalysisStepsTimeout = false;
    },
    onPendencyToEditChanged(state, action: PayloadAction<string | null>) {
      state.pendencyToEditId = action.payload;
    },
    onSelectAnalysisDashboardOption(state, action: PayloadAction<{ label: string; type: string }>) {
      state.analysisDashboardSelectedOption = action.payload ? action.payload : { label: "", type: "" };
    },
    onTrustpadCurrentViewChanged(state, action: PayloadAction<TrustpadView>) {
      state.trustpadCurrentView = action.payload;
    },
  },
});

export const {
  onAnalysisDetailsModalClosed,
  onAnalysisDetailsModalOpened,
  onAnalysisPendencyUpdated,
  onCloseWithoutSavingPendencies,
  onLoadAnalysisStepsTimeoutClear,
  onPendencyToEditChanged,
  onSelectAnalysisDashboardOption,
  onTrustpadCurrentViewChanged,
} = analysisSlice.actions;

export default analysisSlice.reducer;
