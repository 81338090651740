import {
  LqdButton,
  LqdDuplicateIcon,
  LqdEditIcon,
  LqdEllipsisIcon,
  LqdIconButton,
  LqdServerDatabaseIcon,
  LqdToggle,
  LqdTooltip,
  LqdTrashIcon,
  LqdTypography,
} from "@/liquid-components/src";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../common/commonSlice";
import { ToastNotification } from "../../common/types/ToastNotification";
import { useErrorHandler } from "../../common/utils/useErrorHandler";
import {
  automationToDuplicateIdChanged,
  automationToRemoveChanged,
  editingAutomationChanged,
  newAutomationChanged,
  onRemoveAutomation,
  onUpdateAutomation,
} from "../automationsSlice";
import { Automation } from "../types/Automation";

type AutomationCardHeaderProps = {
  automation: Automation;
};

export default function AutomationCardHeader(props: AutomationCardHeaderProps) {
  const { automation } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { automationToRemove } = useAppSelector((state) => state.automations);

  const [showMenu, setShowMenu] = useState<HTMLElement | null>(null);

  const onToggleAutomationClick = async () => {
    setShowMenu(null);

    const newState = !automation.is_active;
    const updatedAutomation: Automation = {
      ...automation,
      is_active: newState,
    };

    await dispatch(onUpdateAutomation(updatedAutomation));

    const notification: ToastNotification = {
      message: `A automação selecionada foi ${newState ? "ativada" : "desativada"}.`,
      type: "success",
    };

    dispatch(toastCalled(notification));
  };

  const onEditClick = () => {
    dispatch(newAutomationChanged(automation));
    dispatch(editingAutomationChanged(true));
    setShowMenu(null);
  };

  const onDuplicateClick = () => {
    dispatch(automationToDuplicateIdChanged(automation.configuration_id));
    dispatch(editingAutomationChanged(false));
    setShowMenu(null);
  };

  const onCancelAutomationDeletion = () => {
    dispatch(automationToRemoveChanged(null));
  };

  const onConfirmAutomationDeletion = async () => {
    if (!automationToRemove) {
      throw new Error("Could not get automationToRemove");
    }

    dispatch(dialogLoaded(true));

    try {
      const notification: ToastNotification = {
        message: `A automação ${automationToRemove.name} foi removida.`,
        type: "success",
      };

      await dispatch(onRemoveAutomation(automationToRemove.configuration_id));
      dispatch(toastCalled(notification));
      dispatch(automationToRemoveChanged(null));

      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error, t("Houve um erro ao remover a automação. Tente novamente."));
    }
  };

  const onRemoveClick = () => {
    dispatch(automationToRemoveChanged(automation));
    dispatch(editingAutomationChanged(false));
    setShowMenu(null);
  };

  useEffect(() => {
    if (automationToRemove) {
      dispatch(
        dialogCalled({
          actions: [
            { onClick: onCancelAutomationDeletion, title: "Voltar" },
            { onClick: onConfirmAutomationDeletion, title: "Confirmar exclusão" },
          ],
          body: `Este procedimento é irreversível e resultará na exclusão do histórico de registro das conexões na
          seção de automação, criadas a partir desta.`,
          bodySecundary: "Para prosseguir mesmo assim, digite EXCLUIR no campo abaixo.",
          confirmationString: "EXCLUIR",
          title: "Exclusão da Automação",
          type: "confirmation",
        })
      );
    }
  }, [automationToRemove]);

  const isDeactivatedAutomation = !automation.is_active || automation.is_blocked;

  return (
    <Stack
      direction={{ md: "row", xs: "column-reverse" }}
      justifyContent="space-between"
      spacing={2}
      sx={{ maxWidth: "100%", mb: 2 }}
    >
      <Stack alignItems="center" direction="row" justifyContent={{ md: "flex-start", xs: "space-between" }} spacing={1}>
        <LqdTypography textstyle="h5Headline">{automation.name}</LqdTypography>
        <Box
          sx={{
            backgroundColor: !isDeactivatedAutomation ? "rgba(52, 199, 89, 1)" : "rgba(177, 177, 185, 1)",
            borderRadius: "32px",
            color: "white",
            fontSize: "12px",
            lineHeight: "22px",
            px: 1,
          }}
        >
          {!isDeactivatedAutomation ? "Ativa" : "Inativa"}
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: !isDeactivatedAutomation ? "rgba(52, 199, 89, 1)" : "rgba(177, 177, 185, 1)",
            borderRadius: "50%",
            display: { md: "none", xs: "flex" },
            height: "32px",
            width: "32px",
          }}
        >
          <LqdServerDatabaseIcon color="rgba(255, 255, 255, 1)" />
        </Stack>
        <Stack direction="row" spacing={1}>
          <LqdButton buttonsize="small" href={`/automations/${automation.configuration_id}`} type="outlineTertiary">
            {t("Ver Detalhes")}
          </LqdButton>
          <LqdIconButton
            buttonsize="small"
            onClick={(event) => setShowMenu(event.currentTarget)}
            round="true"
            type="outlinePrimary"
          >
            <LqdEllipsisIcon />
          </LqdIconButton>
          <Menu
            anchorEl={showMenu}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={() => setShowMenu(null)}
            open={Boolean(showMenu)}
            sx={{
              ".MuiList-root": { py: 0 },
              ".MuiMenuItem-root": { height: "45px" },
              ".MuiPaper-root": { borderRadius: "20px", minWidth: "175px" },
              color: "rgba(101, 110, 127, 1)",
              mt: 2,
              svg: { mr: 1 },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <LqdTooltip
              hidetooltip={automation.is_blocked ? "false" : "true"}
              titlebody={t(`Você não possui mais acesso a essa automação.
              Entre em contato com a Liquid para saber mais.`)}
              titleheader={t("Automação bloqueada")}
            >
              <>
                <MenuItem disabled={automation.is_blocked} onClick={onToggleAutomationClick}>
                  <LqdToggle active={automation.is_active} onChange={() => {}} />
                  {automation.is_active ? t("Desativar") : t("Ativar")}
                </MenuItem>
                <MenuItem disabled={automation.is_blocked} onClick={onEditClick}>
                  <LqdEditIcon color="rgba(101, 110, 127, 1)" />
                  {t("Editar")}
                </MenuItem>
                <MenuItem disabled={automation.is_blocked} onClick={onDuplicateClick}>
                  <LqdDuplicateIcon color="rgba(101, 110, 127, 1)" />
                  {t("Duplicar")}
                </MenuItem>
              </>
            </LqdTooltip>
            <MenuItem onClick={onRemoveClick}>
              <LqdTrashIcon color="rgba(101, 110, 127, 1)" />
              {t("Excluir")}
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </Stack>
  );
}
