import { User } from "../types/User";
import UserRaw from "../types/UserRaw";

export const getUserRaw = (user: User): UserRaw => ({
  blocked_tenant: user.blockedTenant,
  created_at: new Date(user.created).getTime(),
  fullname: user.name,
  groups: user.groups,
  is_active: user.active,
  is_pending: user.pending,
  notifications: user.notifications || false,
  user_role: user.profile,
  username: user.email,
});
