import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { dialogCalled, dialogErrorMessageChanged, dialogLoaded } from "../../../common/commonSlice";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { validateOpenCreditDocument } from "../../api/OpenCreditDocumentValidation";
import openCreditWelcomeSVG from "../../assets/openCreditWelcome.svg";

type OpenCreditStartStateProps = {
  onInit: () => void;
  passId: string;
};

export default function OpenCreditStartState(props: OpenCreditStartStateProps) {
  const { onInit, passId } = props;

  const { width } = useWindowSize();

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const checkCPFValidation = async (CPFValue: string) => {
    dispatch(dialogLoaded(true));

    try {
      const isCPFValidRaw = await validateOpenCreditDocument(CPFValue, passId);
      const isCPFValid = isCPFValidRaw.data;

      if (isCPFValid.valid) {
        dispatch(dialogCalled(null));
        return onInit();
      } else {
        return dispatch(dialogErrorMessageChanged("CPF incompatível com o documento da autorização. Tente novamente."));
      }
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dispatch(dialogLoaded(false));
    }
  };

  const handleStartValidation = () => {
    dispatch(
      dialogCalled({
        actions: [
          { title: "Voltar" },
          { onClick: (CPFValue) => checkCPFValidation(CPFValue! as string), title: "Iniciar validação" },
        ],
        body: "Precisamos validar o seu CPF para continuar.",
        title: "Insira seu CPF",
        type: "confirmation",
      })
    );
  };

  return (
    <Stack
      alignItems="center"
      direction={{ sm: "row", xs: "column-reverse" }}
      justifyContent="center"
      sx={{ boxSizing: "border-box", maxWidth: "1121px", mx: "auto", my: { sm: "auto", xs: 0 } }}
    >
      <Box>
        <LqdTypography
          sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
          textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
        >
          Valide sua Identidade com a segurança
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(101, 110, 127, 1)", display: "inline", ml: { lg: 1.5, xs: 1 } }}
          textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
        >
          Liquid
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
          textstyle={width === "xs" ? "h4Headline" : "h2Headline"}
        >
          .
        </LqdTypography>
        <LqdTypography
          sx={{ color: "rgba(56, 61, 70, 1)", pt: { lg: 3.75, xs: 2.5 } }}
          textstyle={width === "xs" ? "p1Paragraph" : "h5Headline"}
        >
          Siga os Passos e Complete a Verificação em Segundos.
        </LqdTypography>
        <Box sx={{ pt: 2.5 }}>
          <LqdTypography
            color="rgba(0, 0, 0, 1)"
            display="inline"
            textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}
          >
            Ao iniciar a verificação, você autoriza a Liquid a consultar seus dados no Sistema de Informações de Crédito
            (SCR BACEN). Saiba mais sobre a autorização
          </LqdTypography>
          <LqdTypography
            onClick={() => window.open("https://app.liquidpass.co/open-credit/terms-and-conditions/")}
            sx={{
              ":hover": { color: "rgba(33, 36, 42, 1)", cursor: "pointer" },
              color: "rgba(0, 0, 0, 1)",
              display: "inline",
              ml: 0.5,
              textDecorationLine: "underline",
            }}
            textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}
          >
            aqui
          </LqdTypography>
          <LqdTypography
            sx={{ color: "rgba(0, 0, 0, 1)", display: "inline" }}
            textstyle={width === "xs" ? "c1Caption" : "p1Paragraph"}
          >
            .
          </LqdTypography>
        </Box>
        <LqdButton onClick={handleStartValidation} sx={{ mb: "68px", mt: 2.5, width: "fit-content" }}>
          Autorizar e Iniciar
        </LqdButton>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          img: { maxWidth: { sm: "unset", xs: "233px" } },
          margin: "auto",
          pl: { sm: "70px", xs: 0 },
          py: { sm: 0, xs: 5 },
          width: { sm: "50%", xs: "auto" },
        }}
      >
        <Box component="img" src={openCreditWelcomeSVG} />
      </Box>
    </Stack>
  );
}
