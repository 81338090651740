import { Circle } from "@mui/icons-material";

export default function LqdCompletedStep() {
  return (
    <div style={{ height: "24px", position: "relative", width: "24px" }}>
      <Circle sx={{ color: "rgba(65, 70, 103, 0.08)" }} />
      <Circle
        sx={{
          color: "rgba(117, 123, 162, 1)",
          height: "8px",
          left: "8px",
          position: "absolute",
          top: "8px",
          width: "8px",
        }}
      />
    </div>
  );
}
