import { Box, InputBaseComponentProps, SxProps, TextField } from "@mui/material";
import IMask, { Masked } from "imask";
import {
  ChangeEvent,
  ChangeEventHandler,
  ElementType,
  FocusEvent,
  KeyboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import MaskedInput from "react-text-mask";
import { createNumberMask as createPercentageMask } from "text-mask-addons";
import { LqdTypography } from "..";
import { formatCurrency, isNumericMask, isStringifiedMask, unformatCurrency } from "./helpers/formatters";
import { LqdInputMaskType, MaskMap } from "./types";
import { percentageMaskOptions } from "./utils/percentageMask";

const maskMap: MaskMap = {
  // TODO: adicionar máscara de e-mail
  cnpj: { mask: "00.000.000/0000-00" },
  cpf: { mask: "000.000.000-00" },
  currency: null,
  date: {
    // TODO: adicionar validação de data válida/inválida
    blocks: {
      aaaa: { from: 1900, mask: IMask.MaskedRange, to: 9999 },
      dd: { from: 1, mask: IMask.MaskedRange, to: 31 },
      mm: { from: 1, mask: IMask.MaskedRange, to: 12 },
    },
    format: (date: Date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    mask: "00/00/0000",
    parse: (str: string) => {
      const [day, month, year] = str.split("/");
      return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    },
  },
  integer: { mask: Number },
  percentage: null,
  phone: { mask: "+00 00 00000-0000" },
  string: null,
};

type LqdInputProps = {
  alertMessage?: string;
  boxSize?: "normal" | "small";
  characterLimit?: number;
  className?: string;
  disabled?: boolean;
  fullBox?: boolean;
  label?: string;
  maskType?: LqdInputMaskType;
  onBlur?: (value: string) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onConfirm?: () => void;
  onConfirmValidation?: boolean;
  placeholderText?: string;
  setValue: (value: string) => void;
  showCharacterLimit?: boolean;
  sx?: SxProps;
  value: string;
};

const percentageMask = createPercentageMask(percentageMaskOptions);

const CustomMaskedInput = forwardRef<MaskedInput, LqdInputProps>((properties, ref) => {
  const { onBlur, onChange, ...rest } = properties;

  return (
    <MaskedInput
      {...rest}
      mask={percentageMask}
      onBlur={onBlur as unknown as (event: FocusEvent<HTMLInputElement, Element>) => void}
      onChange={onChange}
      ref={ref}
    />
  );
});

/** Componente genérico de Input da aplicação.
 * @param alertMessage Mensagem de alerta exibida abaixo do input.
 * @param boxSize (Normal/Small). Define o tamanho da fonte utilizada no input.
 * @param characterLimit Limite de caracteres do input.
 * @param className Classe do input (testes).
 * @param disabled Define se o input está desabilitado.
 * @param fullBox Define se o input deve conter bordas em toda a sua extensão.
 * @param label Texto exibido acima do input.
 * @param maskType Tipo de máscara a ser aplicada no input.
 * @param onBlur Função a ser executada ao sair do input.
 * @param onConfirm Função a ser executada ao pressionar a tecla Enter.
 * @param onConfirmValidation Validação para que a função onConfirm possa ser executada.
 * @param placeholderText Texto exibido no input quando ele está vazio.
 * @param sx Estilos customizados.
 * @param setValue Função que atualiza o valor do input.
 * @param showCharacterLimit Define se o contador de caracteres deve ser exibido.
 * @param value Valor do input.
 */
export default function LqdInput(props: LqdInputProps) {
  const {
    alertMessage,
    boxSize,
    characterLimit,
    className,
    disabled,
    fullBox,
    label,
    maskType,
    onBlur,
    onConfirm,
    onConfirmValidation,
    placeholderText,
    setValue,
    showCharacterLimit,
    sx,
    value,
  } = props;

  const maskRef = useRef<Masked | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [internalValue, setInternalValue] = useState(value);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let maskedValue = event.target.value;
      let unmaskedValue = maskedValue;

      if (maskRef.current) {
        maskRef.current.resolve(maskedValue || "");
        maskedValue = maskRef.current.value;
        unmaskedValue = maskRef.current.unmaskedValue;
      }

      if (maskType === "date") return setValue(maskedValue);

      if (maskType === "cpf" || maskType === "cnpj" || maskType === "phone") {
        if ([".", "-", "/", "_", " "].includes(maskedValue.slice(-1))) {
          unmaskedValue = unmaskedValue.slice(0, -1);
        }
      }

      if (maskType === "percentage") {
        const formattedValue = unmaskedValue.replace(",", ".").replace("%", "");
        const parsedValue = parseFloat(formattedValue);

        if (!isNaN(parsedValue)) {
          unmaskedValue = (((parsedValue / 100) * 10000000000000) / 10000000000000).toString();
        } else {
          unmaskedValue = "";
        }
      }

      setInternalValue(unmaskedValue);
      setValue(unmaskedValue);
    },
    [maskType, setValue]
  );

  const handleChangeCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(unformatCurrency(event.target.value));
    const formattedValue = formatCurrency(event.target.value);

    if (maskType === "currency") {
      const numberValue = inputValue / 100;
      setInternalValue(numberValue.toFixed(2));
      setValue(numberValue.toFixed(2));
    } else {
      setInternalValue(formattedValue);
      setValue(formattedValue);
    }
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onConfirm && onConfirmValidation) {
        onConfirm();
      }
    },
    [onConfirm, onConfirmValidation]
  );

  const handleBlur = (value: string) => {
    if (!onBlur) return;
    onBlur(value);
  };

  useEffect(() => {
    const maskConfig = maskMap[maskType || "string"];

    if (maskConfig && (isStringifiedMask(maskConfig) || isNumericMask(maskConfig))) {
      maskRef.current = IMask.createMask(maskConfig as Masked);
    } else {
      maskRef.current = null;
    }
  }, [maskType]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }

    if (maskRef.current) {
      maskRef.current.value = value;
    }

    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  const characterLimitErrorMessage =
    Boolean(characterLimit) && characterLimit !== undefined && value.length > characterLimit
      ? `Você ultrapassou o limite de ${characterLimit} caracteres. Abrevie para continuar.`
      : "";
  const anyAlert = Boolean(showCharacterLimit ? characterLimitErrorMessage || alertMessage : alertMessage);

  const getBorderColor = () => {
    switch (true) {
      case anyAlert:
        return "rgba(246, 61, 94, 1)";
      case value.length > 0:
        return "rgba(33, 36, 42, 1)";
      default:
        return "rgba(155, 162, 175, 1)";
    }
  };

  const borderStyle =
    fullBox === true
      ? { backgroundColor: "rgba(251, 251, 251, 1)", border: "1px solid rgba(227, 235, 235, 1)", borderRadius: 2 }
      : {
          ":hover": { borderBottom: `1px solid ${anyAlert ? "rgba(246, 61, 94, 1)" : "rgba(33, 36, 42, 1)"}` },
          borderBottom: `1px solid ${getBorderColor()}`,
        };

  const helperText = (
    <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
      <LqdTypography color="rgba(246, 61, 94, 1)" textstyle="c1Caption">
        {alertMessage || (showCharacterLimit ? characterLimitErrorMessage : null)}
      </LqdTypography>
      {characterLimit && showCharacterLimit ? (
        <LqdTypography
          color={characterLimitErrorMessage ? "rgba(246, 61, 94, 1)" : "rgba(155, 162, 175, 1)"}
          sx={{ opacity: value.length > 0 ? 1 : 0.5, textAlign: "right" }}
          textstyle="p2Paragraph"
        >
          {`${value.length}/${characterLimit} caracteres`}
        </LqdTypography>
      ) : null}
    </Box>
  );

  const inputStyles = {
    color: "rgba(33, 36, 42, 1)",
    fontSize: boxSize === "small" ? "14px" : "20px",
    pb: 1,
    pt: boxSize === "small" ? 1 : 0,
    px: 2,
    ...borderStyle,
    ...(sx || {}),
  };

  const getInputProperties = () => {
    switch (maskType) {
      case "currency":
        return {
          InputProps: { disableUnderline: true },
          inputProps: { sx: inputStyles },
          onChange: handleChangeCurrency,
          value: formatCurrency(value),
        };
      case "percentage": {
        let inputValue = value;

        const formattedValue = inputValue.replace(",", ".").replace("%", "");
        const parsedValue = parseFloat(formattedValue);

        if (!value) {
          inputValue = "";
        } else if (!isNaN(parsedValue)) {
          inputValue = (parsedValue * 100).toString().replace(".", ",") + "%";
        } else {
          inputValue = value;
        }

        return {
          InputProps: {
            disableUnderline: true,
            inputComponent: CustomMaskedInput as unknown as ElementType<InputBaseComponentProps> | undefined,
          },
          inputProps: {
            onChange: handleChange,
            onKeyDown: handleKeyDown,
            placeholder: placeholderText || "Digite o valor aqui",
            sx: inputStyles,
          },
          value: inputValue || "",
        };
      }

      default:
        return {
          InputProps: { disableUnderline: true },
          inputProps: { sx: inputStyles },
          inputRef: inputRef,
          onChange: handleChange,
          value: maskRef.current ? maskRef.current.value : value,
        };
    }
  };

  return (
    <>
      {label ? (
        <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ pb: 0.5 }} textstyle="p2Paragraph">
          {label}
        </LqdTypography>
      ) : null}
      <TextField
        autoFocus
        className={className}
        disabled={disabled}
        error={anyAlert}
        helperText={helperText}
        onBlur={(event) => handleBlur(event.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholderText || "Digite o valor aqui"}
        sx={{ "&.MuiFormControl-root": { border: "none" }, width: "100%" }}
        variant="standard"
        {...getInputProperties()}
      />
    </>
  );
}
