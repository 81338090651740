import { AxiosPromise } from "axios";
import { post } from "../../common/utils/apiUtils";
import { SignInResponse } from "../types/SignInResponse";
import SignInRequest from "../types/SignInRequest";

const signIn = (form: SignInRequest): AxiosPromise<SignInResponse> => {
  return post("/auth/login", form);
};

export default signIn;
