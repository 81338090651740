import { LqdButton, LqdCheckboxOffIcon, LqdCheckboxOnIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { createTenantModalStepGoTo, createTenantModalStepNext, editTenantModalStepGoTo } from "../../../adminSlice";

type TenantFormStep2Props = {
  checkedSegments: Array<string>;
  setCheckedSegments: (segments: Array<string>) => void;
};

export default function TenantFormStep2(props: TenantFormStep2Props) {
  const dispatch = useAppDispatch();
  const { createTenant, segments } = useAppSelector((state) => state.admin);
  const { checkedSegments, setCheckedSegments } = props;

  // Envia as modificações deste step para o parent, quando clicar no botão de avançar
  const handleSubmit = () => {
    dispatch(createTenant ? createTenantModalStepNext() : editTenantModalStepGoTo(4));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedSegments([...checkedSegments, event.target.name]);
    } else {
      setCheckedSegments(checkedSegments.filter((segment) => segment !== event.target.name));
    }
  };

  return (
    <Box sx={{ px: 20, width: { lg: "100vw", md: "60vw" } }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 7.5 }} textstyle="h4Headline">
        Quais são os segmentos?
      </LqdTypography>
      <Box sx={{ display: "flex", flexDirection: "column", height: "350px", overflow: "auto" }}>
        {Object.values(segments).map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedSegments.includes(option.segmentId)}
                checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={18} />}
                icon={<LqdCheckboxOffIcon color="rgba(33, 36, 42, 1)" size={18} />}
                name={option.segmentId}
                onChange={(event) => handleCheckboxChange(event)}
              />
            }
            key={option.segmentId}
            label={option.name}
            sx={{
              ".MuiTypography-root": { color: "rgba(33, 36, 42, 1)" },
              borderBottom: "1px solid rgba(212, 215, 220, 1)",
              p: 3,
            }}
          />
        ))}
      </Box>
      <Box sx={{ mt: 7.5, textAlign: "right" }}>
        {createTenant ? (
          <LqdButton onClick={() => dispatch(createTenantModalStepGoTo(5))} sx={{ mr: 1.5 }} type="outlineTertiary">
            Criar um novo segmento
          </LqdButton>
        ) : null}
        <LqdButton disabled={!checkedSegments.length} onClick={handleSubmit}>
          Avançar
        </LqdButton>
      </Box>
    </Box>
  );
}
