import { LqdButton, LqdDoneFilledIcon, LqdTypography } from "@/liquid-components/src";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { Box, Stack } from "@mui/material";
import { useAppSelector } from "../../../store";
import LqdSecureEnvironment from "./SecureEnvironment";
import SignatureHeader from "./SignatureHeader";

export default function ScheduleSuccess() {
  const { scheduleData } = useAppSelector((state) => state.signature);
  const replace_escaped_slashes = scheduleData.data.schedule_link.replace(/\\\//g, "/");
  const handleClick = () => {
    window.location.href = replace_escaped_slashes;
  };
  return (
    <>
      <SignatureHeader />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          height: "496px",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: "400px",
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdDoneFilledIcon color="rgba(52, 199, 89, 1)" size={45} />
        </Stack>
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "center" }}
          textAlign="center"
          textstyle="h3Headline"
        >
          Seu link para o agendamento está pronto!
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "center" }}>
          Clique no link abaixo e selecione a melhor data para o agendamento.
        </LqdTypography>
        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => handleClick()}
          sx={{ mt: 2, width: "100%" }}
          variant="contained"
        >
          Escolher uma data
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </>
  );
}
