import { Simulator, SimulatorTesterResultDBResponse } from "@simulatorBuilder/types/Simulator";
import { SimulatorVariableTypeTemplate } from "@simulatorBuilder/types/SimulatorVariableTypeTemplate";

type simulatorTesterInputVariablesUpdateProps = {
  simulator: Simulator;
  testerResult: SimulatorTesterResultDBResponse | null;
  variableTypes: Array<SimulatorVariableTypeTemplate>;
};

export const simulatorTesterInputVariablesUpdate = (props: simulatorTesterInputVariablesUpdateProps) => {
  const { simulator, testerResult, variableTypes } = props;

  const variables = simulator.variables.filter(
    (variable) => variable.is_input || variable.name === "date_now" || variable.name === "oldest_peer_date"
  );

  return variables.map((variable) => {
    let value = testerResult?.inputs.find((input) => input.id === variable.id)?.value || "";
    let name = variable.name;

    if (variable.name === "date_now") {
      name = variable.label;
      value = new Date().toLocaleDateString("pt-BR");
    }

    if (variable.name === "oldest_peer_date") {
      name = variable.label;
      value = "01/01/1995";
    }

    return {
      id: variable.id,
      label: name,
      placeholder: variableTypes.find((type) => variable.type === type.value)?.label || "",
      type: variable.type,
      value: value,
    };
  });
};
