import { LqdIconProps } from "../../types/IconProps";

export default function LqdRadioOnIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 18 18" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13Z"
        fill={color}
        vectorEffect="non-scaling-stroke"
      />
      <circle cx="9" cy="9" r="8.4" stroke={color} strokeWidth="1.2" />
    </svg>
  );
}
