import { LqdIconProps } from "../../types/IconProps";

export default function LqdCreatorIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.52159 20.0034H3.9967V15.4785C3.9967 15.0147 4.18087 14.5698 4.50892 14.242L15.2414 3.50948C15.5692 3.18099 16.0143 2.9964 16.4784 2.9964C16.9425 2.9964 17.3876 3.18099 17.7154 3.50948L20.4906 6.28464C20.8191 6.6125 21.0037 7.05755 21.0037 7.52165C21.0037 7.98576 20.8191 8.43081 20.4906 8.75867L9.7581 19.4911C9.42998 19.8188 8.98531 20.003 8.52159 20.0034Z"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M13.0005 5.9975L18.0026 10.9996"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
