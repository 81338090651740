import { LqdButton, LqdCheckboxOffIcon, LqdCheckboxOnIcon, LqdTypography } from "@/liquid-components/src";
import { Box, Checkbox, Divider } from "@mui/material";
import { t } from "i18next";
import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ObjectOf } from "../../common/types/ObjectOf";
import { Product } from "../../products/types/Product";
import { createGroupModalStepNext, editGroupFormStepChangedTo } from "../groupsSlice";
import { haveSameItems } from "../utils/haveSameItems";
import ProductRow from "./ProductRow";

type GroupFormStep2Props = {
  editing?: boolean;
  selectedProducts: ObjectOf<Product>;
  setSelectedProducts: (value: ObjectOf<Product>) => void;
};

export default function GroupFormStep2(props: GroupFormStep2Props) {
  const { editing, selectedProducts, setSelectedProducts } = props;

  const dispatch = useAppDispatch();

  const { groupBeingEdited } = useAppSelector((state) => state.groups);
  const tenantProducts = useAppSelector((state) => Object.values(state.products.tenantProducts));

  // Envia as alterações nos produtos do grupo pro parent
  const onToggleProduct = (product: Product, checked: boolean) => {
    const updatedGroups = { ...selectedProducts };
    if (checked) {
      updatedGroups[product.id] = product;
    } else {
      delete updatedGroups[product.id];
    }
    setSelectedProducts(updatedGroups);
  };

  let maySubmit = true;

  if (editing) {
    maySubmit =
      maySubmit &&
      !haveSameItems(
        Object.values(selectedProducts).map((product) => product.id),
        groupBeingEdited?.products || []
      );
  }

  // Avança de step se estiver no modo criação; vai pro último step se estiver no modo edição
  const handleSubmit = () => {
    if (editing) {
      dispatch(editGroupFormStepChangedTo(4));
    } else {
      dispatch(createGroupModalStepNext());
    }
  };

  return (
    <Box sx={{ width: { md: "80vw" } }}>
      <Box sx={{ pt: { lg: 7, md: 4 }, px: 5 }}>
        <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)", mb: 7 }} textstyle="h4Headline">
          {t("Escolha os produtos")}
        </LqdTypography>
        <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
          {tenantProducts.length ? (
            tenantProducts.map((product) => {
              const isChecked = Boolean(selectedProducts[product.id]);
              return (
                <Fragment key={product.id}>
                  <ProductRow
                    checkbox={
                      <Checkbox
                        checked={isChecked}
                        checkedIcon={<LqdCheckboxOnIcon color="rgba(33, 36, 42, 1)" size={20} />}
                        className={isChecked ? "is-checked" : "is-not-checked"}
                        icon={<LqdCheckboxOffIcon size={20} />}
                        onChange={({ target: { checked } }) => onToggleProduct(product, checked)}
                      />
                    }
                    product={product}
                  />
                  <Divider />
                </Fragment>
              );
            })
          ) : (
            <LqdTypography color="secondary" sx={{ py: 7, textAlign: "center" }} textstyle="h4Headline">
              {t("Não há produtos criados.")}
            </LqdTypography>
          )}
        </Box>
      </Box>
      <Box sx={{ mr: 5, mt: 5, textAlign: "right" }}>
        <LqdButton disabled={!maySubmit} onClick={handleSubmit}>
          {t("Avançar")}
        </LqdButton>
      </Box>
    </Box>
  );
}
