import { InfoOutlined } from "@mui/icons-material";
import { LqdTooltip } from "..";

type LqdInfoButtonProps = {
  info: string;
};

export default function LqdInfoButton(props: LqdInfoButtonProps) {
  const { info } = props;

  return (
    <LqdTooltip titlebody={info}>
      <InfoOutlined sx={{ color: "inherit", cursor: "pointer", fontSize: "16px" }} />
    </LqdTooltip>
  );
}
