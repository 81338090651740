import { LqdIconProps } from "../../types/IconProps";

export default function LqdRadioOffIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 18 18" width={size} xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8.4" stroke={color} strokeWidth="1.2" />
    </svg>
  );
}
