import { LqdButton, LqdCircularLoader, LqdDialog, LqdDialogActions, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled } from "../../commonSlice";
import { SmallDialogAction } from "./SmallDialog";
import SmallDialogHeader from "./SmallDialogHeader";

/**
 * Componente de SmallDialog para usos em que uma lista de itens é exibida. Por ser um componente reutilizável, contém algumas propriedades específicas.
 * Por exemplo, a propriedade itemList é um objeto que pode receber algumas propriedades específicas, como labelIcon, labelOne, labelTwo, valueOne e valueTwo.
 * Ele possui um "schema" padrão que deve ser respeitado ao chamar esse modal.
 * @param actions Lista de ações do Modal.
 * @param body Texto principal do Modal.
 * @param bodySecundary Texto secundário do Modal.
 * @param itemList Lista de itens a serem exibidos no Modal.
 * @param title Título do Modal.
 */
export default function SmallDialogList() {
  const dispatch = useAppDispatch();

  const { dialogContent, dialogLoading, dialogShowState } = useAppSelector((state) => state.common);

  const getButtonType = (index: number, actions: Array<SmallDialogAction>) =>
    actions.length === 1 || index !== 0 ? "filledPrimary" : "outlineTertiary";

  const handleCloseDialog = () => {
    dispatch(dialogCalled(null));
  };

  const handleConfirmClick = (action: SmallDialogAction) => {
    action.onClick ? action.onClick() : handleCloseDialog();
  };

  return (
    <LqdDialog onClose={handleCloseDialog} open={dialogShowState}>
      <SmallDialogHeader body={dialogContent.body} title={dialogContent.title} />
      {dialogContent.itemList ? (
        <Box sx={{ maxHeight: "210px", mt: 1, overflowY: "auto" }}>
          {!dialogContent.itemList.length ? (
            <Box sx={{ backgroundColor: "rgba(249, 249, 250, 1)", borderRadius: 4, p: 1.5, width: "92%" }}>
              <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="c1Caption">
                {dialogContent.emptyLabel}
              </LqdTypography>
            </Box>
          ) : (
            dialogContent.itemList.map((item, index) =>
              item.labelIcon ? (
                <Box
                  key={index}
                  sx={{
                    ":last-child": { mb: 0 },
                    backgroundColor: "rgba(249, 249, 250, 1)",
                    borderRadius: "16px",
                    mb: 1.5,
                    p: 1.5,
                  }}
                >
                  <Box sx={{ mb: 0.5 }}>
                    <LqdTypography color="rgba(56, 61, 70, 1)" component="span" textstyle="c1Caption">
                      {`${item.labelOne} `}
                    </LqdTypography>
                    <LqdTypography color="rgba(127, 135, 152, 1)" component="span" textstyle="c1Caption">
                      {item.valueOne}
                    </LqdTypography>
                  </Box>

                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    {item.labelIcon}
                    <LqdTypography
                      color="rgba(79, 85, 98, 1)"
                      component="span"
                      sx={{ ml: 0.5 }}
                      textstyle="p2Paragraph"
                    >
                      {item.valueTwo}
                    </LqdTypography>
                  </Box>
                </Box>
              ) : (
                <Box
                  key={index}
                  sx={{
                    ":last-child": { mb: 0 },
                    backgroundColor: "rgba(249, 249, 250, 1)",
                    borderRadius: "16px",
                    mb: 1.5,
                    p: 1.5,
                  }}
                >
                  <Box>
                    <LqdTypography color="rgba(56, 61, 70, 1)" component="span" textstyle="c1Caption">
                      {`${item.labelOne} `}
                    </LqdTypography>
                    <LqdTypography color="rgba(127, 135, 152, 1)" component="span" textstyle="c1Caption">
                      {item.valueOne}
                    </LqdTypography>
                  </Box>

                  <Box sx={{ display: "block", mt: 0.25 }}>
                    <LqdTypography color="rgba(56, 61, 70, 1)" component="span" textstyle="c1Caption">
                      {`${item.labelTwo} `}
                    </LqdTypography>
                    <LqdTypography color="rgba(127, 135, 152, 1)" component="span" textstyle="c1Caption">
                      {item.valueTwo}
                    </LqdTypography>
                  </Box>
                </Box>
              )
            )
          )}
        </Box>
      ) : null}
      {dialogContent.bodySecundary ? (
        <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ mt: 2.5 }} textstyle="p2Paragraph">
          {dialogContent.bodySecundary}
        </LqdTypography>
      ) : null}
      <LqdDialogActions sx={{ mt: 2.5 }}>
        {dialogContent.actions.map((action, index, actions) => {
          return (
            <LqdButton
              disabled={dialogLoading}
              key={action.title}
              onClick={() => handleConfirmClick(action)}
              type={getButtonType(index, actions)}
            >
              {dialogLoading && actions.length > 1 && index !== 0 ? (
                <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
              ) : (
                action.title
              )}
            </LqdButton>
          );
        })}
      </LqdDialogActions>
    </LqdDialog>
  );
}
