import { LqdIconProps } from "../../types/IconProps";

export default function LqdEllipsisTwoIcon(props: LqdIconProps) {
  const { color = "currentColor", size = 24 } = props;

  return (
    <svg fill="none" height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.49768 11.9971C6.49768 11.7219 6.27259 11.4968 5.99747 11.4998C5.72236 11.4998 5.49726 11.7249 5.49726 12.0001C5.49726 12.2752 5.72236 12.5003 5.99747 12.5003C6.27259 12.5003 6.49768 12.2752 6.49768 11.9971"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M12.5002 11.9971C12.5002 11.7219 12.2751 11.4968 12 11.4998C11.7249 11.4998 11.4998 11.7249 11.4998 12.0001C11.4998 12.2752 11.7249 12.5003 12 12.5003C12.2751 12.5003 12.5002 12.2752 12.5002 11.9971"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M18.5027 11.9971C18.5027 11.7219 18.2776 11.4968 18.0025 11.4998C17.7274 11.4998 17.5023 11.7249 17.5023 12.0001C17.5023 12.2752 17.7274 12.5003 18.0025 12.5003C18.2776 12.5003 18.5027 12.2752 18.5027 11.9971"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
