import {
  LqdCheckIcon,
  LqdCloseIcon,
  LqdIconButton,
  LqdLeftArrowIcon,
  LqdRightArrowIcon,
  LqdSelect,
  LqdTextField,
  LqdTypography,
} from "@/liquid-components/src";
import { SelectOption } from "@/liquid-components/src/components/Select/types/SelectOption";
import { Box, useTheme } from "@mui/material";
import { cnpj } from "cpf-cnpj-validator";
import validaPt from "pt-id";
import { useEffect, useState } from "react";
import { TenantCountry } from "../../types/TenantCountry";

export type Step2Form = {
  tenantCustomSegment: "" | string;
  tenantId: string;
  tenantSegment: "" | string;
};

type SignUpFormStep2Props = {
  defaultValues?: Step2Form;
  onGoBack: () => void;
  onSubmit: (form: Step2Form) => void;
  tenantCountry: "" | TenantCountry;
  tenantSegmentOptions: Array<SelectOption>;
};

export default function SignUpFormStep2(props: SignUpFormStep2Props) {
  const { defaultValues, onGoBack, onSubmit, tenantCountry, tenantSegmentOptions } = props;

  const theme = useTheme();

  const [CNPJError, setCNPJError] = useState(false);
  const [NIPCError, setNIPCError] = useState(false);
  const [tenantCustomSegment, setTenantCustomSegment] = useState(defaultValues?.tenantCustomSegment || "");
  const [tenantId, setTenantId] = useState(defaultValues?.tenantId || "");
  const [tenantSegment, setTenantSegment] = useState(defaultValues?.tenantSegment || "");

  useEffect(() => {
    if (!tenantId) {
      setCNPJError(false);
      setNIPCError(false);
    }
  }, [tenantId]);

  const maySubmit =
    (tenantCountry === "brazil" ? !CNPJError : !NIPCError) &&
    (tenantCustomSegment || tenantSegment === "Real-estate" || tenantSegment === "Accounting") &&
    tenantId;

  const handleCNPJBlur = () => {
    if (tenantId) {
      const validCNPJ = cnpj.isValid(tenantId);
      setCNPJError(!validCNPJ);
    }
  };

  const handleNIPCBlur = () => {
    if (tenantId) {
      const validNIPC = validaPt.nif.validate(tenantId);
      setNIPCError(!validNIPC);
    }
  };

  const getEndAdornment = () => {
    switch (true) {
      case tenantId && !CNPJError:
        return <LqdCheckIcon color={theme.palette.primary.main} />;
      case tenantId && CNPJError:
        return (
          <LqdIconButton buttonsize="small" onClick={() => setTenantId("")} round="true">
            <LqdCloseIcon color={theme.palette.error.main} />
          </LqdIconButton>
        );
      default:
        return null;
    }
  };

  const renderIdInput = () => {
    switch (tenantCountry) {
      case "brazil":
        return (
          <>
            <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block", mb: 1 }} textstyle="c1Caption">
              CNPJ
            </LqdTypography>
            <LqdTextField
              endAdornment={getEndAdornment()}
              error={tenantId ? CNPJError : false}
              label={
                !!tenantId && CNPJError ? (
                  <LqdTypography color="error" textstyle="c1Caption">
                    CNPJ inválido, Tente novamente
                  </LqdTypography>
                ) : null
              }
              mask="00.000.000/0000-00"
              onBlur={handleCNPJBlur}
              onChange={(event) => setTenantId(event.target.value)}
              placeholder="Digite"
              sx={{ borderColor: "rgba(212, 215, 220, 1)", color: "rgba(101, 110, 127, 1)" }}
              value={tenantId}
            />
          </>
        );
      case "portugal":
        return (
          <>
            <LqdTypography sx={{ display: "block" }} textstyle="c1Caption">
              NIPC
            </LqdTypography>
            <LqdTextField
              error={tenantId ? NIPCError : false}
              label={
                !!tenantId && NIPCError ? (
                  <LqdTypography color="error" textstyle="c1Caption">
                    Type a valid NIPC
                  </LqdTypography>
                ) : null
              }
              onBlur={handleNIPCBlur}
              onChange={(event) => setTenantId(event.target.value)}
              placeholder="Digite"
              value={tenantId}
            />
          </>
        );
    }
  };

  return (
    <form>
      {renderIdInput()}
      <LqdTypography color="rgba(155, 162, 175, 1)" sx={{ display: "block", mb: 1, mt: 1 }} textstyle="c1Caption">
        Segment
      </LqdTypography>
      <LqdSelect
        onChange={setTenantSegment}
        options={tenantSegmentOptions}
        placeholder="Escolha o segmento"
        value={tenantSegment}
      />
      {tenantSegment === "outro" ? (
        <>
          <LqdTypography textstyle="c1Caption">Describe your segment</LqdTypography>
          <LqdTextField
            onChange={(event) => setTenantCustomSegment(event.target.value)}
            placeholder="Digite"
            sx={{ mb: 2 }}
            value={tenantCustomSegment}
          />
        </>
      ) : null}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
        <LqdIconButton buttonsize="large" onClick={onGoBack} type="outlinePrimary">
          <LqdLeftArrowIcon />
        </LqdIconButton>
        <LqdIconButton disabled={!maySubmit} onClick={() => onSubmit({ tenantCustomSegment, tenantId, tenantSegment })}>
          <LqdRightArrowIcon />
        </LqdIconButton>
      </Box>
    </form>
  );
}
