import { Simulator } from "@simulatorBuilder/types/Simulator";

export const createDuplicateSimulatorRequestData = (simulatorData: Simulator) => {
  const requestData = {
    blocks: simulatorData.blocks,
    dashboard: {
      cards: simulatorData.dashboard.cards || [],
      highlight: simulatorData.dashboard.highlight || [],
      tables: simulatorData.dashboard.tables.map((table) => ({
        ...table,
        column_names: table.columns.map((column, index) => ({
          index: index,
          name: column.columnName,
        })),
        data: table.rows.flatMap((row) =>
          row.rowContent.map((cell) => ({
            column: cell.data?.column,
            label: cell.data?.label || "",
            line: cell.data?.line,
            name: cell.data?.name || "",
            type: cell.data?.type || "",
            value: cell.data?.value || "",
          }))
        ),
      })),
    },
    name: simulatorData.name || "Nome Padrão",
    tenant_code: simulatorData.tenant_code || "tenant_code_default",
    tenant_name: simulatorData.tenant_name || "tenant_name_default",
    variables: simulatorData.variables.map((variable) => ({
      ...variable,
      value: variable.value || "",
    })),
  };

  return requestData;
};
