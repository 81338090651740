import { LqdButton, LqdCloseIcon, LqdIconButton, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

type TermsAndConditionsProps = {
  agreed: boolean;
  onChange: (value: boolean) => void;
  onClose: () => void;
};

export default function TermsAndConditions(props: TermsAndConditionsProps) {
  const { agreed, onChange, onClose } = props;

  return (
    <Box className="lqd-terms-n-conditions" sx={{ color: "rgba(117, 123, 162, 1)" }}>
      <Box sx={{ pb: 8 }}>
        <LqdIconButton onClick={onClose} sx={{ float: "right" }} type="outlinePrimary">
          <LqdCloseIcon />
        </LqdIconButton>
      </Box>
      <LqdTypography sx={{ mb: 7 }} textstyle="h3Headline">
        Termos e condições
      </LqdTypography>
      <Box sx={{ maxHeight: "40vh", overflowY: "auto", pr: 10 }}>
        <LqdTypography color="inherit" sx={{ fontWeight: 600 }} textstyle="p2Paragraph">
          1. Autorização para Consulta Bureau de Crédito
        </LqdTypography>
        <LqdTypography color="inherit" textstyle="p2Paragraph">
          Autorizo que a empresa “Pass ID” - RISKNOW PROCESSAMENTO DE DADOS LTDA., inscrito no CNPJ/MF sob o nº
          27.638.356/0001-01, com sede na Avenida Engenheiro Luiz Carlos Berrini, 1140 - Andar 07 - Sala 707 - Cidade
          Monções - São Paulo - SP - CEP 04571-000, e-mail: compliance@risknow.ai consulte informações registradas em
          meu cadastro e nos dos meus clientes, relativas aos dados pessoais, profissionais e residenciais, e imóveis e
          outros ativos.
        </LqdTypography>
        <LqdTypography color="inherit" sx={{ fontWeight: 600, mt: 2 }} textstyle="p2Paragraph">
          2. Compartilhamento de Dados Cadastrais
          <LqdTypography color="inherit" textstyle="p2Paragraph">
            As informações acima descritas, as quais desde já autorizo o compartilhamento, deverão ser utilizadas
            exclusivamente para fins ligados a compra e/ou proposta de crédito imobiliário/ financiamento e poderão ser
            repassadas a Bancos e fundos de investimento ou incorporadora, loteadora, construtora ou contraparte
            vendedora onde desejo obter crédito ou realizar compra de imóvel, na busca por melhores ofertas.
          </LqdTypography>
        </LqdTypography>
        <LqdTypography color="inherit" sx={{ fontWeight: 600, mt: 2 }} textstyle="p2Paragraph">
          3. Monitoramento de Crédito
          <LqdTypography color="inherit" textstyle="p2Paragraph">
            Declaro ciência e autorizo ainda, pesquisas que poderão ser realizadas em meu CNPJ, à título de
            monitoramento da evolução e/ou alterações das condições de crédito, durante a existência da relação
            econômica/financeira entre as partes, sempre que for necessário, consultas a bureaus de Crédito, tais como
            SERASA Experian ou Serviço de Proteção.
          </LqdTypography>
        </LqdTypography>
      </Box>
      <Box sx={{ display: "flex", mt: 7 }}>
        <LqdToggle active={agreed} onChange={(value) => onChange(value)} />
        <LqdTypography sx={{ ml: 2 }} textstyle="p2Paragraph">
          Eu li e concordo com os Termos e Condições.
        </LqdTypography>
      </Box>
      <LqdButton disabled={!agreed} onClick={() => {}} sx={{ mt: 3, width: "100%" }}>
        {/* To be implemented */}
        Concluir
      </LqdButton>
    </Box>
  );
}
