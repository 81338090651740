import { AnalysisDetails } from "../types/AnalysisDetails/Analysis/AnalysisDetails";
import { AnalysisStepsResponseRaw } from "../types/AnalysisDetails/Analysis/AnalysisStepsResponseBody";
import { delayedGetSteps } from "./delayedGetSteps";

export const getNewAnalysisLastRunWithRetry = async (analysis: AnalysisDetails, lastRun: number): Promise<boolean> => {
  const GET_STEPS_RETRY_LIMIT = 40;
  let retryCount = 0;
  let newLastRun = lastRun;
  let rawSteps: AnalysisStepsResponseRaw | null;

  do {
    rawSteps = await delayedGetSteps(analysis.analysisId, retryCount === 0 ? 0 : 3000);
    if (rawSteps) newLastRun = rawSteps.last_run as number;
    retryCount++;
  } while (newLastRun === lastRun && retryCount <= GET_STEPS_RETRY_LIMIT);

  return newLastRun > lastRun;
};
