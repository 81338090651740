import { Circle, Path, StyleSheet, Svg, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  trustpadSVGContainer: {
    marginRight: "18px",
    maxHeight: "40px",
    maxWidth: "40px",
  },
});

export default function PDFTrustpadWarningSVG() {
  return (
    <View style={styles.trustpadSVGContainer}>
      <Svg viewBox="0 0 45 45">
        <Path d="M24 25.1199V21.3799" stroke="rgb(255, 181, 71)" strokeLineCap="round" strokeWidth="1.2" />
        <Path
          d="M23.999 28.125C23.861 28.125 23.749 28.237 23.75 28.375C23.75 28.513 23.862 28.625 24 28.625C24.138 28.625 24.25 28.513 24.25 28.375C24.25 28.237 24.138 28.125 23.999 28.125"
          stroke="rgb(255, 181, 71)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Path
          d="M26.0288 16.1392L33.6878 29.5432C34.5778 31.1012 33.4528 33.0402 31.6588 33.0402H16.3408C14.5458 33.0402 13.4208 31.1012 14.3118 29.5432L21.9708 16.1392C22.8678 14.5682 25.1318 14.5682 26.0288 16.1392Z"
          fillRule="evenodd"
          stroke="rgb(255, 181, 71)"
          strokeLineCap="round"
          strokeWidth="1.2"
        />
        <Circle cx="23.8" cy="23.8" r="20" stroke="rgb(255, 181, 71)" />
      </Svg>
    </View>
  );
}
