import CryptoJS from "crypto-js";
import * as Base64 from "js-base64";
import { LOGIN_SECRET } from "../../common/utils/apiUtils";

export const encrypt = (str: string) => {
  str = Math.random().toString(36).substring(2, 10) + str;
  const _strkey = Base64.decode(LOGIN_SECRET);
  _strkey.split(",");
  const text = CryptoJS.enc.Utf8.parse(str);
  const Key = CryptoJS.enc.Base64.parse(_strkey.split(",")[1]);
  const IV = CryptoJS.enc.Base64.parse(_strkey.split(",")[0]);
  const encryptedText = CryptoJS.AES.encrypt(text, Key, {
    iv: IV,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const b64 = encryptedText.toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  const eHex = e64.toLocaleString();
  return eHex.toUpperCase();
};

export const decrypt = (encryptedStr: string) => {
  const _strkey = Base64.decode(LOGIN_SECRET);
  const keyAndIV = _strkey.split(",");
  if (keyAndIV.length !== 2) return "";

  const Key = CryptoJS.enc.Base64.parse(keyAndIV[1]);
  const IV = CryptoJS.enc.Base64.parse(keyAndIV[0]);

  const encryptedBytes = CryptoJS.enc.Hex.parse(encryptedStr);
  const encryptedText = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedBytes,
  });

  const decrypted = CryptoJS.AES.decrypt(encryptedText, Key, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted);
  if (!decryptedStr) return "";

  return decryptedStr.substring(8);
};
