import { LqdTrashIcon } from "@/liquid-components/src";
import { Box, useTheme } from "@mui/material";
import { CSSProperties, useMemo, useState } from "react";
import { Position } from "react-flow-renderer";
import { getSmoothStepPath } from "react-flow-renderer/nocss";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { edgeRemoved } from "../../productBuilderSlice";
import { getEdgeColor } from "../../utils/getEdgeColor";
import "./custom-edge.css";

interface CustomEdgeProps {
  id: string;
  sourcePosition: Position | undefined;
  sourceX: number;
  sourceY: number;
  style?: CSSProperties;
  targetPosition: Position | undefined;
  targetX: number;
  targetY: number;
}

const FOREIGN_OBJECT_SIZE = 50;

export default function CustomEdge({
  id,
  sourcePosition,
  sourceX,
  sourceY,
  style = {},
  targetPosition,
  targetX,
  targetY,
}: CustomEdgeProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { edges, nodes } = useAppSelector((state) => state.productBuilder);
  const [showButtons, setShowButtons] = useState(false);

  const edgeColor = useMemo(() => getEdgeColor(id, nodes, edges), [id]);
  const foreignObjectYPos = useMemo(() => (targetY + sourceY) / 2 - 24, [sourceY, targetY]);
  const foreignObjectXPos = useMemo(() => (targetX + sourceX) / 2 - 24, [sourceX, targetX]);

  const onRemoveEdgeClick = () => {
    dispatch(edgeRemoved(id));
  };

  const edgePath = getSmoothStepPath({
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        className="react-flow__edge-path"
        d={edgePath}
        id={id}
        markerEnd={`url(#edge-marker-${edgeColor.split("#")[1]})`}
        strokeDasharray={showButtons ? "5,10" : undefined}
        style={{ ...style, stroke: edgeColor }}
      />
      <foreignObject
        className="foreign-object"
        height={FOREIGN_OBJECT_SIZE}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        requiredExtensions="http://www.w3.org/1999/xhtml"
        style={{
          overflow: "visible",
        }}
        width={FOREIGN_OBJECT_SIZE}
        x={foreignObjectXPos}
        y={foreignObjectYPos}
      >
        <div
          style={{
            alignItems: "center",
            display: showButtons ? "flex" : "none",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            className="edgebutton"
            onClick={onRemoveEdgeClick}
            sx={{
              ":hover": {
                boxShadow: "2px 2px 16px rgba(187, 187, 187, 1)",
                cursor: "pointer",
              },
              alignItems: "center",
              background: "rgba(254, 223, 216, 1)",
              borderRadius: "50%",
              display: "flex",
              height: `${FOREIGN_OBJECT_SIZE}px`,
              justifyContent: "center",
              width: `${FOREIGN_OBJECT_SIZE}px`,
            }}
          >
            <LqdTrashIcon color={theme.palette.error.main} />
          </Box>
        </div>
      </foreignObject>
    </>
  );
}
