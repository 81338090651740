import { Node } from "react-flow-renderer/dist/nocss/esm";
import NodeCategory from "../types/NodeCategory";
import NodeData, { ActionNodeData, ProviderNodeData, StartFlowNodeData } from "../types/NodeData";
import Proponent from "../types/Proponent";
import ProviderCondition from "../types/ProviderCondition";
import RawNode, { ActionRawNode, ProviderEvaluation, ProviderRawNode } from "../types/RawNode";

const getProviderConditions = (evaluations: Array<ProviderEvaluation>, valueType: string): Array<ProviderCondition> => {
  return evaluations.map((evaluation) => ({
    id: evaluation.id,
    operator: evaluation.condition,
    value: valueType === "boolean" ? evaluation.value : String(evaluation.value),
  }));
};

const getNodeCategoryAttributes = (
  type: "start_flow" | "providers" | "action",
  nodeCategories: Array<NodeCategory>
): NodeData => {
  const foundCategory = nodeCategories.find((nodeCategory) => nodeCategory.type === type);
  if (foundCategory) {
    return foundCategory.nodes[0];
  } else {
    throw new Error("Could not load node category data");
  }
};

export default function getRules(
  rawNodes: Array<RawNode>,
  nodeCategories: Array<NodeCategory>
): {
  nodes: Array<Node<NodeData>>;
  proponents: Array<Proponent>;
} {
  const startFlowNodeData = getNodeCategoryAttributes("start_flow", nodeCategories);
  const { color, subtitle, title } = startFlowNodeData;
  const proponents: Array<Proponent> = [];

  // Declara o nó de start_flow
  const startFlowNode: Node<StartFlowNodeData> = {
    data: {
      color,
      subtitle,
      title,
      type: "start_flow",
    },
    id: "node-start_flow",
    position: {
      x: 0,
      y: 0,
    },
    type: "liquidNode",
  };

  // Inicia a construção do array de nodes
  const nodes: Array<Node<NodeData>> = [];
  const defRawNodes = rawNodes || [];
  defRawNodes.forEach((rawNode) => {
    const nodeType = rawNode.node;
    // Se for um node de action, constrói e retorna um node de action
    if (nodeType === "action") {
      const actionRawNode = rawNode as ActionRawNode;
      const nodeData = getNodeCategoryAttributes("action", nodeCategories);
      const { color, subtitle, title } = nodeData;
      const node: Node<ActionNodeData> = {
        data: {
          action: actionRawNode.action,
          color,
          custom_data: {},
          subtitle,
          title,
          type: "action",
        },
        id: actionRawNode.id,
        position: {
          x: actionRawNode.display?.x || 0,
          y: actionRawNode.display?.y || 0,
        },
        type: "liquidNode",
      };
      if (actionRawNode.action === "warning") {
        node.data.custom_data.warningMessage = actionRawNode.warning_message || "";
      }
      nodes.push(node);
    }
    // Se for um node de provider, constrói e retorna um node de provider
    else if (nodeType === "provider") {
      const providerRawNode = rawNode as ProviderRawNode;
      const nodeData = getNodeCategoryAttributes("providers", nodeCategories);
      const { color, subtitle, title } = nodeData;

      const node: Node<ProviderNodeData> = {
        data: {
          color,
          custom_data: {
            conditions: getProviderConditions(providerRawNode.evaluations, providerRawNode.value_type),
            selectedKpi: providerRawNode.selected_kpi,
          },
          options: providerRawNode.options,
          provider: providerRawNode.provider,
          provider_id: providerRawNode.provider_id,
          subtitle,
          title,
          type: providerRawNode.type as "global" | "providers",
        },
        id: providerRawNode.id,
        position: {
          x: providerRawNode.display?.x || 0,
          y: providerRawNode.display?.y || 0,
        },
        type: "liquidNode",
      };
      node.data.options = { ...node.data.options };
      if (providerRawNode.simulation_type && node.data.options) {
        node.data.options.simulation_type = providerRawNode.simulation_type;
      }
      if (providerRawNode.inputs && node.data.options) {
        node.data.options.inputs = providerRawNode.inputs;
      }
      if (providerRawNode.simulator_id) {
        node.data.simulation_id = providerRawNode.simulator_id;
      }
      nodes.push(node);
    }
    // Se for um target user, não se trata de um node aqui no frontend. Adiciona os dados deste target user no proponents
    else if (nodeType === "target_user") {
      const proponent = {
        acceptMultiples: rawNode.multiple,
        id: rawNode.id,
        mandatory: rawNode.required,
        name: rawNode.title,
        type: rawNode.origin,
      };
      proponents.push(proponent);
      // Seta a posição do nó de start-flow
      startFlowNode.position.x = rawNode.display?.x || 0;
      startFlowNode.position.y = rawNode.display?.y || 0;
    }
    // Se for um tipo de desconhecido de node, faz throw de um erro
    else {
      throw new Error(`Error at parseRawNodes. Unknown nodeType: ${nodeType}`);
    }
  });

  // Insere o nó de start_flow nos nodes
  nodes.push(startFlowNode);

  return {
    nodes,
    proponents,
  };
}
