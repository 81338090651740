import { DefaultMessageResponse } from "@common/types/DefaultMessageResponse";
import { post } from "../../common/utils/apiUtils";

/**
 * Endpoint para recusar a validação através do SCR (Open Credit).
 * @param token Token.
 * @returns Mensagem de sucesso.
 * @remarks Endpoint PÚBLICO.
 */
export const refuseOpenCreditValidation = (token: string) => {
  return post<DefaultMessageResponse>("/open-credit/agreement/refuse", { token });
};
