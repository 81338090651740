import { LqdButton, LqdDownTwoIcon, LqdIconButton, LqdSelectMenuItem, LqdTypography } from "@/liquid-components/src";
import { Box, Select } from "@mui/material";
import { simulatorBuilderChanged } from "@simulatorBuilder/simulatorBuilderSlice";
import { SimulatorVariable } from "@simulatorBuilder/types/SimulatorVariable";
import { SimulatorVariableTypes } from "@simulatorBuilder/types/SimulatorVariableTypes";
import { Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";

type SimulatorVariableTypeFormProps = {
  onBack: () => void;
  onNext: () => void;
  selectedType: "" | SimulatorVariableTypes;
  setSelectedType: Dispatch<SetStateAction<"" | SimulatorVariableTypes>>;
  setVariableBeingEdited: Dispatch<SetStateAction<SimulatorVariable | null>>;
  variable: SimulatorVariable;
  variableIndex: null | number;
};

/** Renderiza a tela com input para edição de nome de variável do builder de simulador. */
export default function SimulatorVariableTypeForm(props: SimulatorVariableTypeFormProps) {
  const { onBack, onNext, selectedType, setSelectedType, setVariableBeingEdited, variable, variableIndex } = props;

  const { simulatorData, variableTypes } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const dispatch = useAppDispatch();

  const updateVariableType = () => {
    if (!selectedType) return;
    const newVariable: SimulatorVariable = { ...variable, type: selectedType };

    const findVariable = simulator.variables.find((vari) => vari.id === variable.id);

    let updatedSimulatorVariables;

    if (findVariable) {
      updatedSimulatorVariables = simulator.variables.map((vari, index) =>
        index === variableIndex ? newVariable : vari
      );
    } else {
      updatedSimulatorVariables = [...simulator.variables, newVariable];
    }

    dispatch(simulatorBuilderChanged({ key: "variables", value: updatedSimulatorVariables }));
    setVariableBeingEdited(newVariable);
    onNext();
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "874px" }}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
            Qual o tipo da variável?
          </LqdTypography>
        </Box>
        <>
          <Select
            displayEmpty
            IconComponent={(props) => (
              <LqdIconButton type="ghostPrimary" {...props}>
                <LqdDownTwoIcon />
              </LqdIconButton>
            )}
            MenuProps={{ sx: { borderRadius: "20px", mt: 1 } }}
            onChange={({ target: { value } }) => setSelectedType(value as SimulatorVariableTypes)}
            renderValue={() =>
              selectedType ? (
                <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1.5, ml: 2, padding: 0 }} textstyle="h5Headline">
                  {variableTypes.find((vari) => vari.value === selectedType)?.label}
                </LqdTypography>
              ) : (
                <LqdTypography
                  color="rgba(127, 135, 152, 1)"
                  sx={{ mb: 1.5, ml: 2, padding: 0 }}
                  textstyle="h5Headline"
                >
                  Selecione o tipo da variável
                </LqdTypography>
              )
            }
            sx={{
              "& .MuiSelect-select": {
                borderRadius: "20px",
                boxShadow: "0px 30px 40px -20px rgba(0, 0, 0, 0.2)",
              },
              "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
              "&.Mui-focused": { borderBottom: "2px solid rgba(0, 0, 0, 0)" },
              "&:after": {
                borderBottom: "2px solid rgba(33, 36, 42, 1)",
              },
              "&:before": {
                borderBottom: "2px solid rgba(212, 215, 220, 1)",
              },
              "&:hover": {
                borderBottom: "2px solid rgba(65, 70, 103, 1)",
              },
              "&:not(.Mui-disabled):hover::before": {
                borderBottom: "1px solid rgba(212, 215, 220, 1)",
              },
              alignItems: "flex-end",
              borderBottom: "2px solid rgba(0, 0, 0, 0)",
              height: "90px",
              mt: -2,
              width: "100%",
            }}
            value={selectedType}
            variant="standard"
          >
            {variableTypes.map((variableType) => (
              <LqdSelectMenuItem key={variableType.value} value={variableType.value}>
                <LqdTypography color="rgba(156, 163, 175, 1)" textstyle="p2Paragraph">
                  {variableType.label}
                </LqdTypography>
              </LqdSelectMenuItem>
            ))}
          </Select>
          <Box sx={{ float: "right", mt: 3 }}>
            <LqdButton onClick={() => onBack()} sx={{ mr: 3 }} type="outlineTertiary">
              Voltar
            </LqdButton>
            <LqdButton disabled={!selectedType} onClick={updateVariableType}>
              Continuar
            </LqdButton>
          </Box>
        </>
      </Box>
    </Box>
  );
}
