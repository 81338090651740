import { AxiosPromise } from "axios";
import { post } from "../../common/utils/apiUtils";
import { SimulatorParametersRaw } from "../types/SimulatorParametersRaw";

// Create Simulator Parameter (Superadmin Endpoint)
export const adminCreateSimulatorParameter = (
  productId: string,
  tenantCode: string,
  simulatorInfo: Omit<SimulatorParametersRaw, "product_id" | "tenant_code">
): AxiosPromise<SimulatorParametersRaw> => {
  return post(`/admin/simulation?product_id=${productId}&tenant_code=${tenantCode}`, simulatorInfo);
};
