import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";

export default function SimulatorParametrizationEmptyParameters() {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(191, 196, 205, 0.12)",
        borderRadius: "20px",
        display: "flex",
        height: "42px",
        justifyContent: "center",
        margin: "auto",
        mt: 3,
        width: "90%",
      }}
    >
      <LqdTypography color="rgba(56, 61, 70, 1)" textstyle="c1Caption">
        Não há parâmetros cadastrados neste produto.
      </LqdTypography>
    </Box>
  );
}
