import { SimulatorFunctionBlock } from "@simulatorBuilder/types/SimulatorFunctionBlock";

type AffectedBlocksProps = {
  block: SimulatorFunctionBlock | undefined;
  blocks: Array<SimulatorFunctionBlock> | undefined;
};

export default function affectedBlocksOnDelete(props: AffectedBlocksProps) {
  const { block, blocks } = props;

  const filteredBlocks = blocks?.filter((b) => b.block_id !== block?.block_id);

  return filteredBlocks
    ?.filter((currentBlock) =>
      currentBlock.functions.some((func) => {
        if (func.function_name === "jump") {
          return func.variables.block_id === block?.block_id;
        } else if (func.function_name === "jump_conditional") {
          return func.variables.block_true === block?.block_id || func.variables.block_false === block?.block_id;
        }
        return false;
      })
    )
    .map(({ name }) => ({
      labelOne: name,
      labelTwo: "",
      valueOne: "",
      valueTwo: "",
    }));
}
