import { LqdTypography } from "@/liquid-components/src";

export default function TermsAndConditionsOptionOneChapterThree() {
  return (
    <>
      <LqdTypography
        color="rgba(101, 110, 127, 1)"
        sx={{ fontWeight: 500, mt: 1 }}
        textAlign="justify"
        textstyle="p2Paragraph"
      >
        3. FUNCIONAMENTO DO LIQUID
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.1. Uma vez efetivado o Cadastro, o Usuário Decisor poderá ter acesso às informações dos Usuários Analisados ou
        de terceiros que tenha preenchido no sistema, a partir dos parâmetros de análise ou decisão que inserir no
        Liquid e que se ajustem às suas necessidades, os quais ficarão cadastrados para análises ou decisões posteriores
        (“Parâmetros”).
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.2. A Liquid não orienta, interfere ou analisa os Parâmetros podendo, entretanto, emitir sugestões, ficando a
        critério exclusivo do Usuário Decisor a escolha sobre aceitar ou rejeitar as sugestões feitas . A qualquer
        momento, mediante acesso na área logada do Liquid ou via atendimento automatizado, o Usuário Decisor poderá
        realizar a alteração dos Parâmetros, sendo a escolha/alteração dos Parâmetros de sua exclusiva responsabilidade.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.3. Após o cadastro dos Parâmetros pelo Usuário Decisor, eventual Usuário Analisado que deseje estabelecer
        relação comercial junto a um Usuário Decisor poderá realizar a inserção de seus próprios dados no Liquid. O
        Usuário Analisado receberá, neste ato, a possibilidade de acesso aos presentes Termos e Condições de Uso, bem
        como à Política, declarando-se ciente de que o uso da plataforma está submetido aos presentes Termos e Condições
        e à Política.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.4. Para tanto, o Liquid utilizará os dados inseridos pelo Usuário Analisado, capturará eventuais outros dados
        em plataformas de terceiros referentes a tal Usuário Analisado, conforme item 1.2, “(a)”, padronizará os dados
        coletados e os analisará, de acordo com os Parâmetros, emitindo para o Usuário Decisor o resultado da análise ou
        decisão realizada.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        3.4.1. O Liquid não foi desenvolvido para e não é responsável por gerar eventuais contratos ou operações entre
        os Usuários Decisores e os Usuários Analisados, nem poderá, sob nenhuma hipótese, ser responsabilizado neste
        sentido.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.5. O Liquid não realiza qualquer concessão ou rejeição ao Usuário Analisado, cabendo ao Usuário Decisor
        realizar tais ações, sempre que aplicável, podendo para isso, inclusive, valer-se de informações não constantes
        na análise realizada pelo Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 2 }} textAlign="justify" textstyle="p2Paragraph">
        3.5.1. O Liquid não realiza qualquer alteração nas informações captadas sobre ao Usuário Analisado, as quais são
        de responsabilidade do Usuário e/ou da plataforma em que foram captadas, o que for aplicável.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        O Usuário, seja Decisor ou Analisado, se declara ciente de que todas as análises serão realizadas de acordo com
        os Parâmetros próprios e/ou de outro Usuário Decisor com quem ele deseje estabelecer relação comercial, de modo
        que a Liquid não terá qualquer tipo de responsabilidade pelas análises ou decisões realizadas por meio do
        Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.6. As informações utilizadas pelo Liquid estão baseadas nos dados inseridos ou disponibilizados pelo Usuário
        Decisor ou Analisado e/ou informações advindas de terceiros, conforme item 1.2, “(b)”, de modo que a Liquid não
        tem nenhuma responsabilidade sobre a legitimidade dos dados, ou sobre a inserção de dados errôneos, inseridos
        incorretamente e/ou prestados por terceiros, assim como por decisões tomadas pelo Usuário ou por terceiros a
        partir dessas informações ou das análises de dados realizadas pelo Liquid.
      </LqdTypography>

      <LqdTypography color="rgba(101, 110, 127, 1)" sx={{ ml: 1 }} textAlign="justify" textstyle="p2Paragraph">
        3.7. A Liquid se reserva o direito de bloquear ou excluir, a qualquer momento, de forma imediata e sem
        comunicação prévia, os Usuários Decisores que apresentem comportamento ou atividades suspeitas e que, em razão
        disso, possam prejudicar ou afetar a Liquid, seus detentores ou seus Usuários, sejam eles outros Usuários
        Decisores ou Usuários Analisados. Tal bloqueio ou exclusão será comunicado posteriormente aos Usuários
        envolvidos na relação. A Liquid poderá tomar as medidas legais cabíveis para cessar qualquer atividade suspeita.
        A Liquid não assume qualquer responsabilidade por prejuízos que o Usuário Decisor venha a sofrer em razão do
        bloqueio ou exclusão.
      </LqdTypography>
    </>
  );
}
