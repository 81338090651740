import { AxiosPromise } from "axios";
import { put } from "../../common/utils/apiUtils";
import { SimulatorParametersRaw } from "../types/SimulatorParametersRaw";

// Update Simulator Parameters by Product (Superadmin Endpoint)
export const adminUpdateSimulatorParametersByProduct = (
  productId: string,
  tenantCode: string,
  simulatorInfo: Omit<SimulatorParametersRaw, "product_id" | "tenant_code"> & { original_bank_name: string }
): AxiosPromise<SimulatorParametersRaw> => {
  return put(`/admin/simulation/product?product_id=${productId}&tenant_code=${tenantCode}`, simulatorInfo);
};
