import { get } from "../../common/utils/apiUtils";
import { PluggyBookData } from "../types/AnalysisDetails/Pluggy/PluggyBookData";

/**
 * Endpoint que busca os dados da Pluggy para as contas selecionadas.
 * @param booksIdQuery Query com os ids das contas.
 * @returns Dados da Pluggy.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const getAnalysisPluggyBookData = (booksIdQuery: string) => {
  return get<PluggyBookData>(`/insights/pluggy/book?${booksIdQuery}`);
};
