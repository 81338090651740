import { StyleSheet, View } from "@react-pdf/renderer";
import { BasicSimulation } from "../../../../../../types/Simulation/Basic/BasicSimulation";
import { formatValueToCurrency } from "../../../../../../utils/analysisDetailsDataFormatter";
import PDFAllotmentAndBasicSimulatorInfoCard from "../PDFAllotmentAndBasicSimulatorInfoCard";

type PDFBasicSimulatorGeneralInfoContainerProps = {
  simulation: BasicSimulation;
};

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
});

export default function PDFBasicSimulatorGeneralInfoContainer(props: PDFBasicSimulatorGeneralInfoContainerProps) {
  const { simulation } = props;

  return (
    <View minPresenceAhead={40}>
      <View style={styles.generalInfoCardContainer}>
        <PDFAllotmentAndBasicSimulatorInfoCard
          title="Valor do bem"
          value={formatValueToCurrency(simulation.contract_value, "BRL", false)}
        />
        <PDFAllotmentAndBasicSimulatorInfoCard
          title={`Pagamento mensal\npretendido`}
          value={formatValueToCurrency(simulation.installment_value_desired, "BRL", false)}
        />
        <PDFAllotmentAndBasicSimulatorInfoCard
          title={`Valor da\nentrada`}
          value={formatValueToCurrency(simulation.down_payment, "BRL", false)}
        />
        <PDFAllotmentAndBasicSimulatorInfoCard
          title={`Composição das\nrendas`}
          value={formatValueToCurrency(simulation.total_monthly_income, "BRL", false)}
        />
      </View>
    </View>
  );
}
