import CreateUser from "../types/CreateUser";
import CreateUserRaw from "../types/CreateUserRaw";

export const getCreateUserRaw = (user: CreateUser): CreateUserRaw => ({
  blocked_tenant: user.blockedTenant,
  fullname: user.name,
  groups: user.groups,
  is_pending: user.pending,
  notifications: user.notifications || false,
  user_role: user.profile,
  username: user.email,
});
