import { capitalizeString } from "@common/utils/capitalize";
import { createElement } from "react";
import { AnalysisDetails } from "../../../types/AnalysisDetails/Analysis/AnalysisDetails";
import { PeerData } from "../../../types/AnalysisDetails/PeerData/PeerData";
import { ProviderData } from "../../../types/AnalysisDetails/Provider/ProviderData";
import PDFLiquidpassAddressesTable from "./Body/LiquidPass/PDFLiquidpassAddressesTable";
import PDFLiquidpassAssistancesContainer from "./Body/LiquidPass/PDFLiquidpassAssistancesContainer";
import PDFLiquidpassAssistancesTable from "./Body/LiquidPass/PDFLiquidpassAssistancesTable";
import PDFLiquidpassBlockedSerasa from "./Body/LiquidPass/PDFLiquidpassBlockedSerasa";
import PDFLiquidpassCPFShareHoldingsTable from "./Body/LiquidPass/PDFLiquidpassCPFShareHoldingsTable";
import PDFLiquidpassGeneralInfoContainer from "./Body/LiquidPass/PDFLiquidpassGeneralInfoContainer";
import PDFLiquidpassProfessionalDataTable from "./Body/LiquidPass/PDFLiquidpassProfessionalDataTable";
// import PDFLiquidpassVehiclesTable from "./Body/LiquidPass/PDFLiquidpassVehiclesTable";
import PDFLiquidpassWarningCard from "./Body/LiquidPass/PDFLiquidpassWarningCard";
import PDFOpenCreditActiveWalletTable from "./Body/OpenCredit/PDFOpenCreditActiveWalletTable";
import PDFOpenCreditCreditInfoContainer from "./Body/OpenCredit/PDFOpenCreditCreditInfoContainer";
import PDFOpenCreditEmptyStateContainer from "./Body/OpenCredit/PDFOpenCreditEmptyStateContainer";
import PDFOpenCreditFinalInfoContainer from "./Body/OpenCredit/PDFOpenCreditFinalInfoContainer";
import PDFOpenCreditGeneralInfoContainer from "./Body/OpenCredit/PDFOpenCreditGeneralInfoContainer";
import PDFOpenCreditModalityTableTable from "./Body/OpenCredit/PDFOpenCreditModalityTable";
import PDFNotFoundTable from "./Body/PDFNotFoundTable";
import PDFPeerModel from "./Body/PeerModel/PDFPeerModel";
import PDFCPFSerasaCheckTable from "./Body/Serasa/PDFCPFSerasaCheckTable";
import PDFCPFSerasaPaymentTable from "./Body/Serasa/PDFCPFSerasaPaymentTable";
import PDFCPFSerasaProtestsTable from "./Body/Serasa/PDFCPFSerasaProtestsTable";
import PDFCPFSerasaRestrictions from "./Body/Serasa/PDFCPFSerasaRestrictions";
import PDFCPFSerasaSPCTable from "./Body/Serasa/PDFCPFSerasaSPCTable";

type PDFCPFDashboardProps = {
  analysisDetails: AnalysisDetails;
  user: PeerData;
};

type TableProps<T> = {
  currentPage: number;
  lastPage: number;
  tableData: Array<T>;
  tableLength: number;
  tableOccurrences?: number;
  tableValue?: number;
};

export default function PDFCPFDashboard(props: PDFCPFDashboardProps) {
  const { analysisDetails, user } = props;

  // LIQUIDPASS DATA
  const liquidPass = analysisDetails.result.bureau?.[user.document].liquidpass as ProviderData["liquidpass"];
  const openCredit = analysisDetails.result.bureau?.[user.document].open_credit as
    | ProviderData["open_credit"]
    | undefined;

  const activePartnerships = liquidPass?.partnerships;

  const passId = analysisDetails.peers[user.document].passId;

  const renderOpenCredit = (openCreditData: ProviderData["open_credit"]) =>
    openCreditData?.base_data ? (
      <>
        <PDFOpenCreditGeneralInfoContainer provider={openCreditData} />
        {renderOpenCreditActiveWalletTable()}
        <PDFOpenCreditCreditInfoContainer provider={openCreditData} />
        {renderOpenCreditModalityTables()}
        <PDFOpenCreditFinalInfoContainer provider={openCreditData} />
      </>
    ) : (
      <PDFOpenCreditEmptyStateContainer passId={passId} />
    );

  const renderTable = <T,>(
    data: Array<T> | undefined | null,
    tableComponent: (props: TableProps<T>) => JSX.Element,
    notFoundTitle: string,
    value?: number,
    totalOccurrences?: number
  ) => {
    if (data && data.length > 0) {
      const pageSize = 16;
      const pageCount = Math.ceil(data.length / pageSize);
      const pages = [];

      for (let i = 0; i < pageCount; i += 1) {
        const startIndex = i * pageSize;
        const endIndex = startIndex + pageSize;
        const pageData = data.slice(startIndex, endIndex);

        pages.push(
          createElement(tableComponent, {
            currentPage: i + 1,
            key: i,
            lastPage: pageCount,
            tableData: pageData,
            tableLength: data.length,
            tableOccurrences: totalOccurrences,
            tableValue: value,
          })
        );
      }

      return pages;
    } else {
      return <PDFNotFoundTable notFoundTitle={notFoundTitle} />;
    }
  };

  const renderAddressesTable = () => {
    return renderTable(liquidPass?.addresses, PDFLiquidpassAddressesTable, "Não foram encontrados Endereços");
  };

  const renderProfessionalDataTable = () => {
    return renderTable(
      liquidPass?.professional_data,
      PDFLiquidpassProfessionalDataTable,
      "Não foram encontrados Dados Profissionais"
    );
  };

  // const renderVehiclesTable = () => {
  //   return renderTable(liquidPass?.vehicles, PDFLiquidpassVehiclesTable, "Não foram encontrados Veículos");
  // };

  const renderAssistancesTable = () => {
    return renderTable(liquidPass?.assistances, PDFLiquidpassAssistancesTable, "Não foram encontradas Assistências");
  };

  const renderShareHoldingsTable = () => {
    return renderTable(
      activePartnerships,
      PDFLiquidpassCPFShareHoldingsTable,
      "Não foram encontradas Empresas com Sociedade"
    );
  };

  const renderOpenCreditActiveWalletTable = () => {
    const activeWalletTotalValue = openCredit?.active_wallet;
    const activeWalletOverdueTotalValue = openCredit?.overdue_total_value;

    return renderTable(
      openCredit?.active_wallet_table,
      PDFOpenCreditActiveWalletTable,
      "Não foi encontrada uma Carteira Ativa",
      activeWalletTotalValue,
      activeWalletOverdueTotalValue
    );
  };

  const renderOpenCreditModalityTables = () => {
    return openCredit?.modality_tables.map((modalityTable) =>
      renderTable(
        modalityTable.rows.map((row) => ({ ...row, modalityName: modalityTable.modality })),
        PDFOpenCreditModalityTableTable,
        "Não foram encontrados Limites ou Outros Créditos",
        modalityTable.total_value
      )
    );
  };

  // SERASA DATA
  const serasaCompleto = analysisDetails.result.bureau?.[user.document]
    .serasa_completo as ProviderData["serasa_completo"];
  const serasaParcial = analysisDetails.result.bureau?.[user.document].serasa_parcial as ProviderData["serasa_parcial"];
  const hasSerasa = serasaCompleto || serasaParcial;

  const serasaSPC = serasaParcial?.spc_annotations;
  const spcTotalOccurrences = serasaSPC?.length;
  const spcTotalValue = serasaParcial?.spc_annotations_total_value;
  const mayRenderSPCAnnotations =
    serasaSPC && spcTotalOccurrences && spcTotalOccurrences > 0 && spcTotalValue && spcTotalValue > 0;

  const renderSerasaPaymentTable = () => {
    return renderTable(
      serasaParcial?.pending_payment,
      PDFCPFSerasaPaymentTable,
      "Não foram encontrados Pagamentos Pendentes",
      serasaParcial?.pending_payment_total_value as number
    );
  };

  const renderSerasaCheckTable = () => {
    return renderTable(
      serasaParcial?.check,
      PDFCPFSerasaCheckTable,
      "Não foram encontradas Restrições de Cheques sem Fundo",
      serasaParcial?.check_total_value as number
    );
  };

  const renderSerasaProtestsTable = () => {
    return renderTable(
      serasaParcial?.protest,
      PDFCPFSerasaProtestsTable,
      "Não foram encontrados Protestos",
      serasaParcial?.protest_total_value as number,
      serasaParcial?.protest_total_occurrences as number
    );
  };

  const renderSerasaSPCTable = () => {
    return renderTable(
      mayRenderSPCAnnotations ? serasaParcial?.spc_annotations?.filter((_spc) => _spc.value > 0) : [],
      PDFCPFSerasaSPCTable,
      "Não foram encontradas Anotações SPC",
      serasaParcial?.spc_annotations_total_value as number
    );
  };

  // PEER MODEL DATA
  const peerModel = analysisDetails.result.bureau?.[user.document].peer_model as ProviderData["peer_model"];
  const peerModelRating = peerModel?.rating;

  const renderPeerModel = () => {
    switch (true) {
      case !!peerModel?.exec_error:
        return (
          <PDFNotFoundTable
            notFoundTitle={`A consulta ao CPF ${user.document} foi
            bloqueada pelo usuário ${capitalizeString(user.fullName)}.`}
          />
        );
      case !!peerModelRating:
        return <PDFPeerModel rating={peerModelRating as string} />;
      default:
        return null;
    }
  };

  // PLUGGY DATA
  // const pluggyData = analysisDetails.result.bureau?.[user.document].pluggy as ProviderData["pluggy"];

  return (
    <>
      {liquidPass ? (
        <>
          <PDFLiquidpassGeneralInfoContainer provider={liquidPass} />
          {renderAddressesTable()}
          {renderProfessionalDataTable()}
          {/* {renderVehiclesTable()} */}
          <PDFLiquidpassWarningCard />
        </>
      ) : null}
      {hasSerasa && (serasaParcial?.exec_error || serasaCompleto?.exec_error) ? (
        <PDFLiquidpassBlockedSerasa user={user} />
      ) : null}
      {hasSerasa && !(serasaParcial?.exec_error || serasaCompleto?.exec_error) ? (
        <>
          <PDFCPFSerasaRestrictions serasaCompleto={serasaCompleto} serasaParcial={serasaParcial} />
          {serasaParcial ? renderSerasaPaymentTable() : null}
          {serasaParcial ? renderSerasaCheckTable() : null}
          {serasaParcial ? renderSerasaProtestsTable() : null}
          {serasaParcial ? renderSerasaSPCTable() : null}
        </>
      ) : null}
      {liquidPass ? (
        <>
          <PDFLiquidpassAssistancesContainer provider={liquidPass} />
          {renderAssistancesTable()}
          {renderShareHoldingsTable()}
        </>
      ) : null}

      {openCredit ? renderOpenCredit(openCredit) : null}

      {renderPeerModel()}
      {/* Pluggy Container
      <View minPresenceAhead={40}>
        <Text style={styles.tableWithoutLabelTitle}>Open finance</Text>
        <View style={styles.tableOuterContainer} minPresenceAhead={40}>
          <View style={styles.openFinanceHeaderContainer}>
            <Text style={styles.openFinanceTableTitle1}>Instituições conectadas</Text>
            <Text style={styles.openFinanceTableTitle2}>Autorização</Text>
          </View>
          <View style={styles.tableInnerContainer}>
            {openFinanceData.map((data, index) => {
              const containerStyle = index === 0 ? styles.openFinanceFirstTableRowContainer : styles.tableRowContainer;
              return (
                <View key={index} style={containerStyle}>
                  <View style={styles.openFinanceTableColumn1}>
                    <View style={styles.openFinanceSvgContainer}>
                      <Svg style={styles.bankSvgIcon}>
                        <Rect
                          x="0"
                          y="0"
                          width="40"
                          height="40"
                          rx="200"
                          fill="rgb(0, 73, 144)"
                        />
                        <Path
                          d="M100 239.027H118.658V159.008H100V239.027Z"
                          fill="rgb(255, 229, 18)"
                        />
                        <Path
                          d="M202.261 177.305C190.822 177.305 182.287 180.277 177.653 182.975L181.157 194.765C185.363 192.221 192.609 189.937 199.153 189.937C210.07 189.906 212.375 195.454 212.375 198.264V198.908C188.187 198.805 173.249 206.659 173.249 222.165C173.249 231.289 180.481 240.499 193.825 240.499C201.774 240.499 208.484 237.616 212.56 232.793H212.758L214.798 239.026H229.984V203.556C229.984 189.14 222.905 177.305 202.261 177.305ZM212.916 216.891C212.916 217.841 212.826 218.927 212.605 219.908C212.578 220.035 212.457 220.453 212.421 220.543C212.38 220.643 212.339 220.746 212.299 220.845C210.642 224.754 206.264 228.434 200.374 228.434C195.244 228.434 191.709 225.777 191.709 220.737C191.709 215.09 196.528 212.18 203.715 211.073C205.395 210.807 207.246 210.649 209.228 210.573C210.421 210.532 211.646 210.519 212.916 210.537V216.891Z"
                          fill="rgb(255, 229, 18)"
                        />
                        <Path
                          d="M281.526 179.07V214.22C281.526 215.634 281.351 216.895 280.9 218.03C279.405 221.638 275.726 225.722 269.664 225.722C262.049 225.722 258.712 220.124 258.712 210.766V179.03H240.499V214.099C240.499 218.791 241.067 222.782 242.197 226.069C243.323 229.357 244.863 232.081 246.822 234.212C248.781 236.355 251.132 237.918 253.866 238.904C256.599 239.891 259.626 240.386 262.922 240.386C265.796 240.386 268.336 240.035 270.556 239.332C272.772 238.639 274.686 237.792 276.289 236.801C277.897 235.824 279.243 234.752 280.329 233.595C281.414 232.446 282.27 231.419 282.878 230.51L285.224 239.026H300V179.07H281.526Z"
                          fill="rgb(255, 229, 18)"
                        />
                        <Path
                          d="M136.165 159.009V179.031H127.663V192.709H136.165V217.091C136.165 225.157 137.782 231.214 141.281 234.898C144.281 238.118 149.554 240.505 155.94 240.505C161.426 240.505 163.592 239.685 166.051 238.73V224.801C165.546 225.04 164.11 225.323 162.241 225.323C155.778 225.323 154.283 221.302 154.283 213.952V192.709H165.853V179.031H154.283V159.009H136.165Z"
                          fill="rgb(255, 229, 18)"
                        />
                        <Path
                          d="M274.634 159L263.389 175.168H276.404L292.261 159H274.634Z"
                          fill="rgb(255, 229, 18)"
                        />
                      </Svg>
                      <View style={styles.genericFlexColumnContainer}>
                        <Text style={styles.openFinanceTableColumn1Text1}>{data.bancoLabel}</Text>
                        <Text style={styles.openFinanceTableColumn1Text2}>{data.bancoName}</Text>
                      </View>
                    </View>
                  </View>
                  <Text style={styles.openFinanceTableColumn2}>{data.conta}</Text>
                  <Text style={styles.openFinanceTableColumn3}>{data.horario}</Text>
                  <Text style={styles.openFinanceTableColumn4}>{data.data}</Text>
                </View>
              )
            })}
          </View>
        </View>
      </View>
      <PDFPluggyGeneralInfo /> */}
    </>
  );
}
