import useWindowSize from "../../../common/hooks/useWindowSize";
import { VideoAskTokenData } from "../../types/VideoAskTokenData";
import VideoAskEmbedFooter from "./VideoAskEmbedFooter";
import VideoAskEmbedHeader from "./VideoAskEmbedHeader";

type VideoAskEmbedTokenDataProps = {
  tokenData: VideoAskTokenData | null;
  videoaskId: string | undefined;
};

export default function VideoAskEmbedTokenData(props: VideoAskEmbedTokenDataProps) {
  const { tokenData, videoaskId } = props;

  const { width } = useWindowSize();

  const currentDocument = tokenData?.documents.find((document) => document.videoask_id === videoaskId);

  return (
    <>
      <VideoAskEmbedHeader currentDocument={currentDocument} />
      <iframe
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        height={width === "xs" ? "450px" : "550px"}
        src={`https://www.videoask.com/${videoaskId}`}
        style={{
          borderRadius: 3,
          marginBottom: width === "xs" ? "25px" : "60px",
          marginTop: width === "xs" ? "25px" : "60px",
        }}
        width="100%"
      />
      <VideoAskEmbedFooter />
    </>
  );
}
