import { post } from "../../common/utils/apiUtils";
import { AIMessage } from "../types/AnalysisDetails/IA/AIMessage";

/**
 * Endpoint para enviar uma mensagem para a IA.
 * @param analysisId ID da conexão.
 * @param message Mensagem a ser enviada.
 * @returns Mensagem da IA.
 * @remarks Endpoint ADMIN.
 */
export const sendMessageToAI = (analysisId: string, message: string) =>
  post<AIMessage>("/ai/message", { analysis_id: analysisId, message });
