import { LqdTypography } from "@/liquid-components/src";
import { Box } from "@mui/material";
import LiquidAILogo from "../../../common/components/LiquidAILogo";
import { VideoAskTokenDocument } from "../../types/VideoAskTokenData";

type VideoAskEmbedHeaderProps = {
  currentDocument?: VideoAskTokenDocument | undefined;
};

export default function VideoAskEmbedHeader(props: VideoAskEmbedHeaderProps) {
  const { currentDocument } = props;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgba(33, 36, 42, 1)",
          display: "flex",
          height: "32px",
          justifyContent: "center",
          py: 2,
          width: "100%",
        }}
      >
        <LiquidAILogo color="rgba(189, 199, 215, 1)" height={36} width={80} />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "rgba(249, 249, 250, 1)",
          display: "flex",
          flexDirection: "column",
          height: "32px",
          justifyContent: "center",
          py: 1.5,
          width: "100%",
        }}
      >
        {currentDocument ? (
          <>
            <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
              Você está enviando:
            </LqdTypography>
            <LqdTypography color="rgba(0, 0, 0, 1)" textstyle="p1Paragraph">
              {currentDocument?.document_name}
            </LqdTypography>
          </>
        ) : (
          <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="p2Paragraph">
            Fluxo de envio de documentos Liquid AI
          </LqdTypography>
        )}
      </Box>
    </>
  );
}
