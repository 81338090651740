import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import getLoggedUserInfo from "../common/api/GetLoggedUserInfo";
import { TokenData } from "../common/hooks/useAuth";
import { ObjectOf } from "../common/types/ObjectOf";
import { updateGroupsOnUserCreated } from "../groups/groupsSlice";
import { TenantResponse } from "../superadmin/types/TenantResponse";
import createUser from "./api/createUser";
import { getCurrentTenant } from "./api/getCurrentTenant";
import getUsers from "./api/GetUsers";
import updateUser from "./api/updateUser";
import CreateUserRaw from "./types/CreateUserRaw";
import { CurrentUser } from "./types/CurrentUser";
import { User } from "./types/User";
import UserRaw from "./types/UserRaw";
import { getUser } from "./utils/getUser";

export interface UsersState {
  createUser: boolean;
  createUserCurrentStep: number;
  currentTenant: TenantResponse;
  currentUser: CurrentUser | null;
  editUserCurrentStep: number;
  users: ObjectOf<User>;
  userToEdit: User | null;
}

const initialState: UsersState = {
  createUser: false,
  createUserCurrentStep: 1,
  currentTenant: {
    autoArchive: { createdFrom: 1, finishedFrom: 1, status: false },
    businessActivity: "",
    code: "",
    contact: { email: "", name: "", phone: "" },
    country: "",
    createdAt: 0,
    document: { type: "", value: "" },
    inviteSent: false,
    isBlocked: false,
    lastUpdate: 0,
    mfaActive: false,
    newContractsPerMonth: "0",
    providers: [],
    segments: [],
    users: [],
  },
  currentUser: null,
  editUserCurrentStep: 1,
  userToEdit: null,
  users: {},
};

export const onLoadCurrentUser = createAsyncThunk("users/onLoadCurrentUser", async () => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const actualTenantCode = jwtDecode<TokenData>(accessToken!).sub.tenant_code;

    const currentUserRes = await getLoggedUserInfo(actualTenantCode);
    const currentUserBody = { ...currentUserRes.data, tenant_code: actualTenantCode };

    return currentUserBody;
  } catch (error) {
    const errorData = error as AxiosError;
    const data = errorData.response?.data;
    console.log("error: ", data);
  }
});

export const onLoadCurrentTenant = createAsyncThunk("users/onLoadCurrentTenant", async () => {
  try {
    const currentTenantRes = await getCurrentTenant();
    const tenantData = currentTenantRes.data;

    return tenantData;
  } catch (error) {
    const errorData = error as AxiosError;
    const data = errorData.response?.data;
    console.log("error: ", data);
  }
});

export const onLoadUsers = createAsyncThunk("users/onLoadUsers", async (_, thunk) => {
  try {
    const getUsersRes = await getUsers();
    const rawUsers = getUsersRes.data;

    const currentUserRole = (thunk.getState() as { users: { currentUser: User } }).users.currentUser?.userRole;
    let filteredUsers = rawUsers;

    if (currentUserRole !== "super") {
      filteredUsers = rawUsers.filter((user) => user.user_role !== "super");
    }

    const users = filteredUsers.map(getUser);

    return users;
  } catch (error) {
    const errorData = error as AxiosError;
    const data = errorData.response?.data;
    console.log("error: ", data);
  }
});

export const onCreateUser = createAsyncThunk("users/onCreateUser", async (createUserRaw: CreateUserRaw, thunk) => {
  try {
    const createUserReq = await createUser(createUserRaw);
    thunk.dispatch(updateGroupsOnUserCreated(createUserReq.data));
    // thunk.dispatch(createUserModalClosed());

    return createUserReq.data;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

export const onUpdateUser = createAsyncThunk("users/onUpdateUser", async (user: UserRaw) => {
  const updateUserRes = await updateUser(user);
  const updatedUserRaw = updateUserRes.data;
  updatedUserRaw["created_at"] = user.created_at / 1000;
  const updatedUser = getUser(updatedUserRaw);

  return updatedUser;
});

const usersSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(onLoadCurrentUser.fulfilled, (state, action) => {
        const { blocked_tenant, created_at, fullname, notifications, tenant_code, user_role, username } =
          action.payload!;
        state.currentUser = {
          blockedTenant: blocked_tenant,
          created: created_at,
          fullname,
          notifications,
          tenantCode: tenant_code,
          userRole: user_role,
          username,
        };
      })
      .addCase(onLoadCurrentTenant.fulfilled, (state, action) => {
        const {
          auto_archive,
          business_activity,
          code,
          contact,
          country,
          created_at,
          document,
          invite_sent,
          is_blocked,
          last_update,
          mfa_active,
          new_contracts_per_month,
          providers,
          segments,
        } = action.payload!;
        state.currentTenant = {
          autoArchive: {
            createdFrom: auto_archive?.created_from,
            finishedFrom: auto_archive?.finished_from,
            status: auto_archive?.status,
          },
          businessActivity: business_activity,
          code,
          contact,
          country,
          createdAt: created_at,
          document,
          inviteSent: invite_sent,
          isBlocked: is_blocked,
          lastUpdate: last_update,
          mfaActive: mfa_active,
          newContractsPerMonth: new_contracts_per_month,
          providers,
          segments,
          users: [],
        };
      })
      .addCase(onLoadUsers.fulfilled, (state, action) => {
        const users = action.payload!;

        state.users = {};

        users.forEach((user) => {
          state.users[user.email] = user;
        });
      })
      .addCase(onUpdateUser.fulfilled, (state, action) => {
        const user = action.payload!;
        state.users[user.email] = user;
      })
      .addCase(onCreateUser.fulfilled, (state, action) => {
        const user = getUser(action.payload);
        state.users[user.email] = user;
      }),
  initialState,
  name: "users",
  reducers: {
    createUserModalClosed(state) {
      state.createUser = false;
      state.createUserCurrentStep = 1;
      state.editUserCurrentStep = 1;
    },
    createUserModalOpened(state) {
      state.createUser = true;
      state.createUserCurrentStep = 1;
    },
    createUserModalStepChangedTo(state, action: PayloadAction<1 | 2 | 3 | 4>) {
      state.createUserCurrentStep = action.payload;
    },
    createUserModalStepNext(state) {
      state.createUserCurrentStep += 1;
    },
    createUserModalStepPrev(state) {
      if (state.createUserCurrentStep > 1) {
        state.createUserCurrentStep -= 1;
      }
    },
    editUserFormStepChangedTo(state, action: PayloadAction<number>) {
      state.editUserCurrentStep = action.payload;
    },
    editUserFormStepNext(state) {
      state.editUserCurrentStep = 4;
    },
    editUserFormStepPrev(state) {
      if (state.editUserCurrentStep > 1) {
        state.editUserCurrentStep -= 1;
      }
    },
    editUserModalClosed(state) {
      state.userToEdit = null;
      state.editUserCurrentStep = 1;
    },
    editUserModalOpened(state, action: PayloadAction<{ step: number; user: User }>) {
      const { step, user } = action.payload;
      state.userToEdit = user;
      state.editUserCurrentStep = step;
    },
    onCurrentUserCleared(state) {
      state.currentUser = null;
    },
  },
});

export const {
  createUserModalClosed,
  createUserModalOpened,
  createUserModalStepChangedTo,
  createUserModalStepNext,
  createUserModalStepPrev,
  editUserFormStepChangedTo,
  editUserFormStepNext,
  editUserFormStepPrev,
  editUserModalClosed,
  editUserModalOpened,
  onCurrentUserCleared,
} = usersSlice.actions;

export default usersSlice.reducer;
