import { LqdButton, LqdCircularLoader } from "@/liquid-components/src";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Node } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, toastCalled } from "../../../common/commonSlice";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import getRawNodes from "../../../productBuilder/utils/getRawNodes";
import getRules from "../../../productBuilder/utils/getRules";
import { editTemplateModalClosed } from "../../adminSlice";
import { onLoadTemplates, onUpdateTemplate } from "../../adminSliceThunks";
import { TemplateUpdate } from "../../types/TemplateUpdate";
import TemplateFormStep1 from "./TemplateCreationSteps/TemplateFormStep1";
import TemplateFormStep3 from "./TemplateCreationSteps/TemplateFormStep3";
import TemplateFormStep4 from "./TemplateCreationSteps/TemplateFormStep4";
import TemplateSummaryCards from "./TemplateCreationSteps/TemplateSummaryCards";

const formatNodes = (nodes: Array<Node>) =>
  nodes.map((node) => {
    const nodeCopy = JSON.parse(JSON.stringify(node)) as Node;
    if (nodeCopy.width) delete nodeCopy.width;
    if (nodeCopy.height) delete nodeCopy.height;
    return nodeCopy;
  });

/** Modal de edição de Templates.
 * Exibido no painel de admin.
 */
export default function EditTemplateForm() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { editTemplateModalStep, templateToEdit } = useAppSelector((state) => state.admin);
  const { simulatorKpis } = useAppSelector((state) => state.simulatorBuilder);
  const { edges, initialRules, nodeCategories, nodes, proponents } = useAppSelector((state) => state.productBuilder);

  const [checkedSegments, setCheckedSegments] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");

  const titleChanged = templateTitle !== templateToEdit?.name;
  const segmentsChanged = templateToEdit
    ? JSON.stringify([...checkedSegments].sort()) !== JSON.stringify([...templateToEdit.segments].sort())
    : false;
  const currentRules = { edges, nodes: formatNodes(nodes), proponents };
  const realInitialRules = nodeCategories.length
    ? getRules(initialRules, nodeCategories)
    : { nodes: [], proponents: [] };
  const rulesChanged =
    JSON.stringify(currentRules) !==
    JSON.stringify({
      edges: currentRules.edges,
      nodes: formatNodes(realInitialRules.nodes),
      proponents: realInitialRules.proponents,
    });
  const anythingChanged = titleChanged || segmentsChanged || rulesChanged;

  useEffect(() => {
    if (templateToEdit) {
      setTemplateTitle(templateToEdit.name);
      setCheckedSegments([...templateToEdit.segments]);
    } else {
      resetState();
    }
  }, [templateToEdit]);

  const resetState = () => {
    setTemplateTitle("");
    setCheckedSegments([]);
  };

  const onConfirmEditRulesClose = () => {
    dispatch(editTemplateModalClosed());
    dispatch(dialogCalled(null));
  };

  const handleGoBack = () => {
    // Caso esteja no step do builder, exibe manualmente o modal de confirmação de saída caso haja uma alteração
    if (editTemplateModalStep === 3) {
      if (rulesChanged) {
        dispatch(
          dialogCalled({
            actions: [{ title: "Voltar" }, { onClick: () => onConfirmEditRulesClose(), title: "Sair" }],
            body: t("Há uma edição em andamento, sair agora cancelará as alterações não salvas. Deseja continuar?"),
            title: t("Atenção"),
            type: "default",
          })
        );
      } else {
        dispatch(editTemplateModalClosed());
      }
    }
  };

  const handleEditTemplate = async () => {
    setLoading(true);
    if (!templateToEdit) {
      throw new Error("Could not get templateToEdit");
    }

    try {
      const updateTemplateForm: TemplateUpdate = {
        edges,
        is_active: templateToEdit.isActive,
        name: templateTitle,
        providers: templateToEdit.providers,
        rules: getRawNodes(nodes, edges, proponents, simulatorKpis),
        segments: checkedSegments,
      };

      await dispatch(onUpdateTemplate({ templateId: templateToEdit.templateId, updateTemplateForm }));
      await dispatch(onLoadTemplates());

      const notification: ToastNotification = {
        message: "Template atualizado com sucesso.",
        type: "success",
      };

      dispatch(toastCalled(notification));
      dispatch(editTemplateModalClosed());
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEditClick = () => {
    dispatch(editTemplateModalClosed());
    resetState();
  };

  const renderCurrentStep = () => {
    switch (editTemplateModalStep) {
      case 1:
        return <TemplateFormStep1 name={templateTitle} setName={setTemplateTitle} />;
      case 2:
        return <TemplateFormStep3 checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
      case 3:
        return (
          <TemplateFormStep4
            existingChanges={rulesChanged}
            isLoading={loading}
            onGoBack={handleGoBack}
            onSubmit={handleEditTemplate}
            templateCountry={templateToEdit?.country || ""}
            templateCurrency={templateToEdit?.currency || ""}
            templateSegments={checkedSegments}
            templateTitle={templateTitle}
          />
        );
      case 4:
        return templateToEdit ? (
          <Box sx={{ margin: "auto" }}>
            <TemplateSummaryCards
              checkedSegments={checkedSegments}
              country={templateToEdit.country}
              currency={templateToEdit.currency}
              title={templateTitle}
            />
            <Box sx={{ mt: 5, textAlign: "right" }}>
              <LqdButton disabled={loading} onClick={handleEditTemplate} sx={{ px: 2.5, py: 2 }}>
                {loading ? (
                  <LqdCircularLoader sx={{ color: "rgba(255, 255, 255, 1)" }} />
                ) : (
                  "Confirmar atualização de template"
                )}
              </LqdButton>
            </Box>
          </Box>
        ) : null;
    }
  };

  return (
    <FullscreenDialog
      action="edit"
      currentStep={editTemplateModalStep}
      existingChanges={anythingChanged}
      handleGoBack={handleGoBack}
      hideHeader={editTemplateModalStep === 3}
      onClose={handleCloseEditClick}
      open={Boolean(templateToEdit)}
      title="Edição de Template"
    >
      <Box
        sx={{
          alignItems: "center",
          display: editTemplateModalStep !== 3 ? "flex" : "block",
          height: "calc(100% - 96px)",
          justifyContent: "space-between",
          px: editTemplateModalStep !== 3 ? { sm: "15%", xs: 0 } : 0,
        }}
      >
        {renderCurrentStep()}
      </Box>
    </FullscreenDialog>
  );
}
