import { Circle, CircleOutlined } from "@mui/icons-material";
import { Box, SxProps, useTheme } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";

type RadioCardProps = {
  active?: boolean;
  children: ReactNode;
  disabled?: boolean;
  handleClick: MouseEventHandler;
  sx?: SxProps;
};

export default function RadioCard(props: RadioCardProps) {
  const { active, children, disabled, handleClick, sx } = props;

  const theme = useTheme();

  const getBorderColor = () => {
    switch (true) {
      case disabled:
        return "rgba(127, 135, 152, 0.24)";
      case active:
        return theme.palette.primary.main;
      default:
        return "rgba(127, 135, 152, 0.24)";
    }
  };

  return (
    <Box
      onClick={disabled ? () => {} : handleClick}
      sx={{
        ":hover": {
          border: !disabled && !active ? "2px solid rgba(191, 196, 205, 0.32)" : null,
        },
        backgroundColor: "rgba(65, 70, 103, 0.04)",
        borderColor: getBorderColor(),
        borderRadius: "4px",
        borderStyle: "solid",
        borderWidth: "2px",
        color: disabled ? theme.palette.grey[400] : null,
        cursor: "pointer",
        position: "relative",
        px: 5,
        py: 3,
        ...sx,
      }}
    >
      {children}
      <Box
        sx={{
          position: "absolute",
          right: "18px",
          top: "14px",
        }}
      >
        {active ? (
          <Box sx={{ height: "24px", position: "relative", width: "24px" }}>
            <CircleOutlined color={disabled ? "disabled" : "primary"} sx={{ left: 0, position: "absolute", top: 0 }} />
            <Circle
              color={disabled ? "disabled" : "primary"}
              sx={{
                fontSize: "10px",
                left: "7px",
                position: "absolute",
                top: "7px",
              }}
            />
          </Box>
        ) : (
          <CircleOutlined
            sx={{
              color: disabled ? "disabled" : "rgba(155, 162, 175, 1)",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
