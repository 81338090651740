import { useAppDispatch, useAppSelector } from "../../../store";
import { simulatorBuilderChanged } from "../simulatorBuilderSlice";
import { SimulatorResultCard } from "../types/SimulatorDashboard";
import { simulatorVariablesToResultCard } from "./transformSimulatorTypes";

type SimulatorResultCardsFormProps = {
  type: "cards" | "highlight";
};

export const useSimulatorResultHandler = (props: SimulatorResultCardsFormProps) => {
  const { type } = props;

  const dispatch = useAppDispatch();
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const getInitialContent = () => {
    return simulator.dashboard[type].length > 0
      ? simulator.dashboard[type].map((varType) => ({
          content: simulatorVariablesToResultCard(varType, varType.index),
          id: varType.index,
        }))
      : [{ content: null, id: 1 }];
  };

  const initialContent = getInitialContent();

  const updateDashboard = (newValue: Array<SimulatorResultCard>) => {
    const updatedDashboard = { ...simulator.dashboard, [type]: newValue };
    dispatch(simulatorBuilderChanged({ key: "dashboard", value: updatedDashboard }));
  };

  /**
   * Define qual variável será atualizada
   * @param contentName Nome da variável
   * @param index Índice do card a ser atualizado
   */
  const updateComponent = (contentName: string, index: number) => {
    const variable = simulator.variables.find((variable) => variable.name === contentName);
    if (!variable) return;

    const newValue =
      simulator.dashboard[type].length > 0
        ? [
            ...simulator.dashboard[type].map((varType) =>
              varType.index === index ? simulatorVariablesToResultCard(variable, index) : varType
            ),
          ]
        : [simulatorVariablesToResultCard(variable, 1)];

    updateDashboard(newValue);
  };

  const addNewComponent = () => {
    const parsedTest = initialContent.map((varType) =>
      varType.content
        ? simulatorVariablesToResultCard(varType.content, varType.id)
        : { index: varType.id, label: "", name: "", type: null, value: "" }
    );

    const newValue = simulatorVariablesToResultCard(
      { label: "", name: "", type: null, value: "" },
      initialContent[initialContent.length - 1].id + 1
    );

    updateDashboard([...parsedTest, newValue]);
  };

  const removeComponent = (index: number) => {
    if (simulator.dashboard[type].length === 1) {
      const newValue = { index: 1, label: "", name: "", type: null, value: "" };
      updateDashboard([newValue]);
      return;
    }

    const newValue = simulator.dashboard[type].filter((varType) => varType.index !== index);
    updateDashboard(newValue);
  };

  return { addNewComponent, removeComponent, updateComponent };
};
